<template>
  <div>
    <ui-header title="KycSettingHeader" />
    <ui-container>
      <ui-card icon="la la-institution" title="KYC flow">
        <tabs-group
          slot="body"
          :tab-index="tabIndex"
          :labels="labels"
          @change="tabChanged"
        >
          <div v-for="(item, index) in revision" :key="index">
            <kyc-settings
              v-if="labels[tabIndex] === item.label"
              :data="item"
              :editable="editEnabled"
              @change="changeSetting"
              @submit="saveChanges(index, $event)"
            />
          </div>
        </tabs-group>
      </ui-card>
      <!-- <ui-loader :size="4" center /> -->
    </ui-container>
  </div>
</template>

<script>
import KycSettings from '@src/components/partials/KycSettings'
import TabsGroup from '@src/components/partials/TabsGroup'

export default {
  components: {
    KycSettings,
    TabsGroup
  },

  data() {
    return {
      tabIndex: 0,
      revision: null,
      editEnabled: false,
      labels: null
    }
  },

  created() {
    const data = [
      {
        label: 'DocumentFrontForm',
        startUrl: 'https://kyc.ondato.com',
        redirectUrl: null,
        allowCamera: 'ww',
        pageTitle: 'ww',
        actionLogUrl: 'ww',
        actionLogLanguage: 'EN',
        pendingIdentificationEmailAddress: ['ww'],
        successfulIdentificationEmailAddress: 'w',
        formTypeId: 'Custom',
        formTypeIdOptions: [
          {
            key: 'Custom',
            value: 'Custom'
          },
          {
            key: 'Ondato',
            value: 'Ondato'
          }
        ]
      },
      {
        label: '2',
        startUrl: 'https://kyc.ondato.com',
        redirectUrl: null,
        allowCamera: 'wwgfghf',
        pageTitle: 'ww',
        actionLogUrl: 'wwghf',
        actionLogLanguage: 'EN',
        pendingIdentificationEmailAddress: ['ww'],
        successfulIdentificationEmailAddress: 'w',
        formTypeId: 'Custom',
        formTypeIdOptions: [
          {
            key: 'Custom',
            value: 'Custom'
          },
          {
            key: 'Ondato',
            value: 'Ondato'
          }
        ]
      }
    ]
    this.revision = data
    this.labels = ['DocumentFrontForm', '2']
  },

  methods: {
    tabChanged(value) {
      if (!this.editEnabled) this.tabIndex = value
      else {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription',
          type: 'warning',
          callback: () => {
            this.$set(this, 'editEnabled', false)
            this.tabIndex = value
          }
        })
      }
    },

    changeSetting(value) {
      this.$set(this, 'editEnabled', value)
    },

    saveChanges(value, event) {
      this.revision[value] = event
    }
  }
}
</script>
