var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mrz-list" },
    _vm._l(_vm.MRZ_FIELDS, function (fieldName) {
      return _c(
        "div",
        { key: fieldName, staticClass: "mrz-item" },
        [
          _vm.editEnabled
            ? _c("ui-input", {
                attrs: {
                  type: "text",
                  value: _vm.fieldValues[fieldName],
                  "one-line": "",
                },
                model: {
                  value: _vm.fieldValues[fieldName],
                  callback: function ($$v) {
                    _vm.$set(_vm.fieldValues, fieldName, $$v)
                  },
                  expression: "fieldValues[fieldName]",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.editEnabled && _vm.isMrzLineNotEmpty(fieldName)
            ? _c("div", { staticClass: "mrz-value" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.fieldValues[fieldName].toUpperCase()) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isSymbolCountVisible(fieldName)
            ? _c("div", { staticClass: "mrz-symbol-count" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.fieldValues[fieldName].length) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }