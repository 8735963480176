export const sessionKey = `oidc.user:${AuthorityUrl}:js`
export const historyKey = '_cachedHistory'
export const companyKey = '_cachedCompany'
export const settingsKey = '_cachedSettings'
export const hideLogsKey = '_cachedHideLogsKey'
export const sanctionsKey = '_cachedSanctionsKey'
export const sessionTimeNotifyKey = '_cachedSessionTimeKey'
export const kybKey = '_cachedKybKey'
export const stopListKey = '_cachedStopList'
export const registriesKey = '_cachedRegistries'

export function cachedData(key, value) {
  if (arguments.length > 1) {
    localStorage.setItem(key, JSON.stringify(value))
  } else {
    value = localStorage.getItem(key) || null
    if (value) {
      value = JSON.parse(value)
    } else {
      value = {}
    }
  }
  return value
}

export function cachedString(key, value) {
  if (arguments.length > 1) {
    localStorage.setItem(key, value)
  } else {
    value = localStorage.getItem(key) || null
  }

  return JSON.parse(value)
}

export function clearData() {
  const session = localStorage.getItem(sessionKey)
  localStorage.clear()
  localStorage.setItem(sessionKey, session)
}
