<template>
  <page-frame>
    <ui-card title="CompanyProcedures.Title">
      <ui-button
        v-if="!proceduresList"
        slot="tools"
        class="btn-link"
        @click="$router.push({ name: 'CreateProcedures' })"
      >
        {{ $t('CompanyProcedures.Buttons.Create') }}
        <i class="la la-plus" />
      </ui-button>
      <search-state
        v-if="!isReady"
        :loading="isLoading"
        :empty="isEmpty"
        :error="isError"
        :loading-message="$t('App.Loading')"
        :empty-message="$t('CompanyProcedures.EmptyState.Message')"
        :empty-sub-message="$t('CompanyProcedures.EmptyState.SubMessage')"
        :error-message="$t('Error.Fail')"
      />
      <div v-else>
        <procedure-item
          v-for="(item, index) in proceduresList"
          :key="`procedure_${index}`"
          :data="item"
          :current-id="currentId"
          :version-id="item.id"
          :is-current-procedure="currentProcedureVersionId === item.id"
          :label="label"
          @open-popup="handlePopup"
        />
      </div>
    </ui-card>
    <pop-up>
      <show-template :id="currentView" :version-id="versionId" />
    </pop-up>
  </page-frame>
</template>

<script>
import SearchState from '@src/components/partials/refactor/layouts/SearchState'
import PageFrame from '@src/components/partials/refactor/layouts/PageFrame'
import ProcedureItem from '@src/components/partials/refactor/partials/companyProcedures/ProcedureItem'
import ShowTemplate from '@src/components/pages/companies/ShowTemplate'
import Api from '@src/scripts/api'
import { STATUS_CODE } from '@src/scripts/enums'

export default {
  components: {
    SearchState,
    PageFrame,
    ProcedureItem,
    ShowTemplate
  },

  data() {
    return {
      currentView: null,
      currentId: null,
      isLoading: false,
      isReady: false,
      isError: false,
      isEmpty: false,
      proceduresList: null,
      currentProcedureVersionId: null,
      versionId: null,
      showEmpty: false
    }
  },

  computed: {
    label() {
      return {
        status: this.$t('CompanyProcedures.Labels.Status'),
        changeDate: this.$t('CompanyProcedures.Labels.ChangeDate'),
        view: this.$t('CompanyProcedures.Labels.View'),
        edit: this.$t('CompanyProcedures.Labels.Edit')
      }
    }
  },

  watch: {
    '$store.state.selectedCompany': {
      async handler() {
        await this.loadProcedure()
      },
      immediate: true
    }
  },

  async created() {
    await this.loadProcedure()
  },
  methods: {
    handlePopup(payload) {
      this.$openPopUp()
      this.currentView = payload.id
      this.versionId = payload.versionId
    },
    async loadProcedure() {
      this.isLoading = true

      try {
        const currentProcedure = await Api.get(`/identification-procedures`)
        if (!currentProcedure) {
          this.isEmpty = true
          this.isLoading = false

          return
        }
        this.currentProcedureVersionId = currentProcedure.versionId
        this.currentId = currentProcedure.id
        this.proceduresList = await Api.get(
          `/identification-procedures/${currentProcedure.id}/versions`
        )
        if (!this.proceduresList.length) {
          this.isEmpty = true
          this.isLoading = false

          return
        }
        this.isReady = true
      } catch (error) {
        if (error === STATUS_CODE.NOT_FOUND) {
          this.proceduresList = null
          this.isEmpty = true
          this.isReady = false
        } else {
          this.isError = true
        }
      }
      this.isLoading = false
    }
  }
}
</script>
