var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    { key: _vm.submitCount, on: { submit: _vm.saveChanges } },
    [
      _c("ui-widget", { staticClass: "checking-methods" }, [
        _c("div", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("CheckSettingMethodDescription")) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-row m--margin-top-30" },
          [
            _c("toggle-settings-box", {
              attrs: {
                title: "MRZ zone check",
                value: _vm.settings.mrzZone,
                description: "MRZ zone check description",
                disabled: !_vm.details.editEnabled,
              },
              on: { changeToggle: _vm.mrzZoneChange },
            }),
            _vm._v(" "),
            _c("toggle-settings-box", {
              attrs: {
                title: "Lost & Stolen database check",
                value: _vm.settings.database,
                description: "Lost & Stolen database check description",
                disabled: !_vm.details.editEnabled,
              },
              on: { changeToggle: _vm.databaseChange },
            }),
            _vm._v(" "),
            _c("toggle-settings-box", {
              attrs: {
                title: "Sanction list check",
                value: _vm.settings.sanction,
                description: "Sanction list check description",
                disabled: !_vm.details.editEnabled,
              },
              on: { changeToggle: _vm.sanctionChange },
            }),
            _vm._v(" "),
            _c("toggle-settings-box", {
              attrs: {
                title: "PEP list check",
                value: _vm.settings.pepList,
                description: "PEP list check description",
                disabled: !_vm.details.editEnabled,
              },
              on: { changeToggle: _vm.pepListChange },
            }),
            _vm._v(" "),
            _c("toggle-settings-box", {
              attrs: {
                title: "Liveness check",
                value: _vm.settings.liveNess,
                description: "Liveness check description",
                disabled: !_vm.details.editEnabled,
              },
              on: { changeToggle: _vm.liveNessChange },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tools-buttons" },
          [
            _c("edit-footer", {
              attrs: { "edit-enabled": _vm.details.editEnabled },
              on: { edit: _vm.enableDetailsEdit },
            }),
            _vm._v(" "),
            _vm.details.editEnabled
              ? _c(
                  "ui-button",
                  {
                    staticClass: "btn btn-metal m--margin-left-10",
                    attrs: { small: "" },
                    on: { click: _vm.clickCancel },
                  },
                  [
                    _c("i", { staticClass: "la la-close" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("AppCancel")) + " "),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }