<template>
  <search-state
    v-if="!isReady"
    :loading="isLoading"
    :empty="isEmpty"
    :error="isError"
    :loading-message="$t('App.Loading')"
    :empty-message="$t('CompanyProcedures.EmptyState.Message')"
    :empty-sub-message="$t('CompanyProcedures.EmptyState.SubMessage')"
    :error-message="$t('Error.Fail')"
  />
  <ProceduresForm v-else type="update" :model="model" />
</template>

<script>
import ProceduresForm from '@src/components/pages/companies/ProceduresForm'
import SearchState from '@src/components/partials/refactor/layouts/SearchState'
import Api from '@src/scripts/api'

export default {
  components: {
    ProceduresForm,
    SearchState
  },
  data: () => ({
    isLoading: false,
    isReady: false,
    isEmpty: false,
    isError: false,
    procedures: null,
    model: []
  }),
  async created() {
    this.isLoading = true
    try {
      const procedures = await Api.get(
        `/identification-procedures/${this.$route.query.id}?versionId=${this.$route.query.versionId}`
      )

      if (!procedures) {
        this.isEmpty = true
      }
      this.model = this.mapModel(procedures)
      this.isLoading = false
      this.isReady = true
    } catch (e) {
      this.isLoading = false
      this.isError = true
    }
  },
  methods: {
    mapModel(procedures) {
      return {
        acceptableDocuments: procedures.acceptableDocuments,
        importants: procedures.importants,
        selfieTypes: procedures.selfieTypes,
        comment: procedures.comment
      }
    }
  }
}
</script>
