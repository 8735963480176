<template>
  <div class="status-item">
    <h5>{{ $t(label) }}</h5>
    <span
      class="m-badge m-badge--wide status-value"
      :class="success ? 'm-badge--success' : 'm-badge--danger'"
    >
      {{ $t(value) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined },
    value: { type: String, default: undefined },
    success: Boolean
  }
}
</script>

<style scoped>
.status-item {
  display: flex;
  justify-content: space-between;
}

.status-item > * {
  margin-top: auto;
  margin-bottom: 0;
}

.status-item + .status-item {
  margin-top: 0.5rem;
}

.status-value {
  text-transform: uppercase;
}

@media (max-width: 450px) {
  .status-item {
    flex-direction: column;
  }

  .m-badge {
    line-height: 1.5rem;
  }

  .status-value {
    margin-top: 0.5rem;
  }
}
</style>
