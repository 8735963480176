<template>
  <div class="forgot-password-wrapper">
    <div class="forgot-password__logo">
      <img src="~@src/assets/images/ondato-logo.svg" />
    </div>
    <div v-if="!isSuccess" class="forgot-password">
      <div class="forgot-password__head">
        <h3 class="forgot-password__heading-title">
          {{ $t('AppForgotPassword') }}
        </h3>
      </div>
      <div class="forgot-password__form">
        <ui-input
          v-model="email"
          type="email"
          placeholder="DetailsEmail"
          :value="email"
          :error="error"
          @input="handleError"
        />
        <div class="forgot-password__actions">
          <ui-button
            class="btn-brand mx-auto"
            :disabled="isLoading || email === ''"
            @click="handleSubmit"
          >
            {{ $t('AppSubmit') }}
          </ui-button>
        </div>
      </div>
    </div>
    <div v-else>
      <i class="fas fa-check"></i>
      <p>{{ $t('AppForgotPasswordSuccess') }}</p>
    </div>
  </div>
</template>

<script>
import { API, STATUS_CODE } from '@src/scripts/enums'

export default {
  data: () => ({
    email: '',
    isLoading: false,
    error: undefined,
    isSuccess: false
  }),
  created() {
    this.isSuccess = false
  },
  methods: {
    handleSubmit() {
      if (this.email) {
        if (this.validateEmail(this.email)) {
          this.error = undefined
          this.isLoading = true

          fetch(`${BaseUrl.api}${API.FORGOT_PASSWORD}`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: this.email })
          })
            .then((data) => {
              this.isLoading = false
              if (data.status === STATUS_CODE.NO_DATA) {
                this.isSuccess = true
              }
              if (data.status === STATUS_CODE.NOT_FOUND) {
                this.error = this.$t('AppEmailDosNotExist')
              }
            })
            .catch(() => {
              this.error = this.$t('ErrorUnknownTitle')
              this.isLoading = false
            })
        } else {
          this.error = this.$t('AppInvalidEmail')
        }
      }
    },
    handleError(value) {
      if (this.validateEmail(value)) {
        this.error = undefined
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }
  }
}
</script>

<style scoped>
.forgot-password-wrapper {
  text-align: center;
  width: 100%;
  min-width: 24.61rem;
}

.forgot-password__logo,
.forgot-password,
.forgot-password__head {
  margin-bottom: 1.84rem;
}

.forgot-password__form {
  text-align: right;
}

.forgot-password__actions {
  margin-top: 1.84rem;
}

.fa-check {
  color: var(--success);
  border: 1px solid var(--success);
  border-radius: 50%;
  padding: 1rem;
  font-size: 2rem;
  margin: 0 1.85rem 1.84rem;
}

img {
  max-width: 15.38rem;
}
</style>
