<template>
  <ui-form v-if="poaData" class="poa-data" @submit="submit">
    <ui-card title="POATitle" icon="flaticon-interface-7">
      <details-box slot="body">
        <template v-for="(item, key) in poaData">
          <details-item
            :key="key"
            class="ui-input-touched"
            :class="getValidityClass(item.isValid)"
            :label="item.label"
            :value="inputValue(item)"
            :error="item.error"
            :editable="editEnabled"
            :disabled="disabled"
            :min-length="item.minLength"
            :max-length="item.maxLength"
            :one-line="item.oneLine"
            @change="(value) => valueChange(key, value)"
          />
        </template>
      </details-box>
      <edit-footer
        v-if="canModify"
        slot="footer"
        :edit-enabled="editEnabled"
        :disabled="disabled"
        @edit="enableEdit"
      >
        <ui-button
          v-show="true"
          type="button"
          class="btn-brand"
          :disabled="source === null"
          @click="() => downloadSource()"
        >
          {{ $t('POAViewSource') }}
        </ui-button>
      </edit-footer>
    </ui-card>
  </ui-form>
</template>

<script>
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import EditFooter from '@src/components/partials/EditFooter'
import { DefaultPOAData } from '@src/components/partials/Identifications/poaDefaultData'
import { downloadUrl } from '@src/scripts/helpers'

export default {
  components: {
    DetailsBox,
    DetailsItem,
    EditFooter
  },

  props: {
    data: { type: Object, default: undefined },
    source: { type: Object, default: null },
    editEnabled: Boolean,
    disabled: Boolean,
    canModify: Boolean
  },

  data() {
    return {
      poaData: null
    }
  },

  computed: {
    defaultPOADataModel() {
      return new DefaultPOAData()
    }
  },

  watch: {
    data() {
      this.setPOAData()
    }
  },

  created() {
    this.setPOAData()
  },

  methods: {
    downloadSource() {
      const { source } = this
      downloadUrl({
        fileUrl: source.file,
        fileName: `poa.${source.fileExtension}`
      })
    },

    valueChange(key, value) {
      const item = this.poaData[key]
      if (item.valueChange) item.valueChange(value)

      this.$set(item, 'value', value)
    },

    enableEdit() {
      this.$emit('edit')
    },

    getValidityClass(isValid) {
      if (isValid === true) {
        return 'valid-value'
      } else if (isValid === false) {
        return 'invalid-value'
      }
      return ''
    },

    setPOAData() {
      const { defaultPOADataModel, data } = this
      if (!data) {
        this.poaData = defaultPOADataModel
        this.$emit('validation', false)
        return
      }

      for (const key in defaultPOADataModel) {
        const originalItem = data[key]
        if (!originalItem) continue

        const dataItem = defaultPOADataModel[key]
        if (typeof originalItem === 'object') {
          dataItem.value = originalItem.value
          dataItem.isValid = originalItem.isValid
        } else {
          dataItem.value = originalItem
        }
      }

      this.poaData = defaultPOADataModel
      this.$emit('validation', this.validateAll())
    },

    inputValue(item) {
      if (!item.inputValue) return item.value
      return item.inputValue()
    },

    validateAll() {
      const { poaData, editEnabled } = this
      let isValid = true

      for (const key in poaData) {
        const item = poaData[key]
        if ((item.showIf && !item.showIf()) || !item.validations) continue

        if (editEnabled) this.$set(item, 'error', undefined)
        for (const validation of item.validations) {
          const error = validation(item, poaData)
          if (!error) continue

          if (editEnabled) this.$set(item, 'error', error)
          isValid = false
          break
        }
      }

      return isValid
    },

    submit() {
      if (!this.validateAll()) return

      const result = {}
      const { poaData } = this
      for (const key in poaData) {
        const item = poaData[key]
        if (item.showIf && !item.showIf()) continue

        if (!item.value) continue

        let value = item.value || undefined
        if (typeof value === 'string') value = value.toUpperCase()

        result[key] = value
      }

      this.$emit('save', result)
      this.$emit('validation', true)
    }
  }
}
</script>

<style scoped>
.poa-data {
  width: 100%;
}
</style>

<style>
.poa-data .details-value,
.poa-data .details-value input,
.poa-data .details-value textarea,
.poa-data .details-value button {
  text-transform: uppercase;
}

.invalid-value .details-label,
.invalid-value .dropdown-readonly {
  color: #f4516c;
}

.valid-value .details-label,
.valid-value .dropdown-readonly {
  color: #28a745;
}
</style>
