export function copy(text) {
  const copyTrigger = document.activeElement
  const input = document.createElement('input')
  input.value = text
  document.querySelector('body').appendChild(input)
  input.select()
  document.execCommand('copy')
  input.remove()
  copyTrigger?.focus?.()
}
