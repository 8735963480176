<template>
  <ProceduresForm type="create" :model="model" />
</template>

<script>
import ProceduresForm from '@src/components/pages/companies/ProceduresForm'

export default {
  components: {
    ProceduresForm
  },
  data: () => ({
    model: {
      selfieTypes: [],
      acceptableDocuments: [],
      importants: [],
      comment: ''
    }
  })
}
</script>
