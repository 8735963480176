const loggerInit = () => {
  // eslint-disable-next-line no-unused-vars
  function logTrace({ name, severityLevel, properties }) {
    // TODO: log trace
  }

  // eslint-disable-next-line no-unused-vars
  function logError({ name, exception, properties }) {
    // TODO: log error
  }

  return {
    logTrace,
    logError
  }
}

export const logger = loggerInit()
