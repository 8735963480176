<template>
  <div>
    <ui-header
      title="KybCompanySearch"
      :method="searchMethod"
      :method-get="true"
      :filters="filters"
      :disabled="isLoading || filters.searchText === ''"
      :required-filters="requiredFilters"
      disable-search-on-load
      :default-payload="false"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.searchText"
        :placeholder="
          filters.country === 'Lt'
            ? 'HeaderCompanyCodePlaceholderCeo'
            : 'HeaderCompanyCodePlaceholder'
        "
        icon="la-institution"
        wide
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.country"
        :options="selectCountryList"
        placeholder="DetailsCountry"
        wide
        :disabled="isLoading"
      />
    </ui-header>
    <ui-container v-if="clickedSearch">
      <ui-card
        icon="la la-institution"
        title="AppSearchResults"
        :count="pageSelectorData.total"
      >
        <template v-if="!isLoading">
          <ui-table
            :labels="tableLabels"
            class="hide-mobile"
            :data="list"
            :loading="isLoading"
            empty-text="DetailsKybNotFound"
          >
            <template v-if="list">
              <tr
                v-for="(row, index) in list"
                :key="index"
                class="m-table__row"
                :class="{
                  'status-pending': row.requestStatus === 'Pending',
                  'status-rejected': row.requestStatus === 'Rejected'
                }"
              >
                <td>{{ row.name }}</td>
                <td>{{ row.code }}</td>
                <td>{{ row.address ? row.address : '-' }}</td>
                <td>{{ row.employeeNumber ? row.employeeNumber : '-' }}</td>
                <td class="td-link">
                  <ui-button
                    type="button"
                    class="btn-brand"
                    @click="clickViewButton(row.code, filters.country)"
                  >
                    {{ $t('TableView') }}
                  </ui-button>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="list">
            <div
              v-for="(row, index) in list"
              :key="index"
              class="response-table show-mobile"
            >
              <responsive-table-tr
                title="DetailsCompanyName"
                :value="row.name"
              />
              <responsive-table-tr
                title="DetailsCompanyCode"
                :value="row.code"
              />
              <responsive-table-tr
                title="DetailsAddress"
                :value="row.address ? row.address : '-'"
              />
              <responsive-table-tr
                title="DetailsEmployeesTitle"
                :value="row.employeeNumber ? row.employeeNumber : '-'"
              />
              <div class="m--margin-top-10">
                <ui-button
                  type="button"
                  class="btn-brand big-link"
                  @click="clickViewButton(row.code, filters.country)"
                >
                  {{ $t('TableView') }}
                </ui-button>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('DetailsKybNotFound') }}
          </div>
        </template>
        <ui-loader v-else :size="4" center />
        <ui-page-selector
          :active-page="pageSelectorData.page"
          :items-count="pageSelectorData.total"
          :max-on-page="pageSelectorData.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
    </ui-container>
  </div>
</template>

<script>
import actions from '@src/store/actions'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import { PRODUCTS } from '@src/scripts/enums'
import Store from '@src/store'

export default {
  components: {
    ResponsiveTableTr
  },

  props: {
    changePage: { type: Function, default: undefined }
  },

  data() {
    return {
      searchMethod: '/kyb/companies',
      selectCountryList: [
        { key: 'Lt', value: 'Country.LT' },
        { key: 'Lv', value: 'Country.LV', disabled: true },
        { key: 'Ee', value: 'Country.EE', disabled: true }
      ],
      filters: {
        searchText: '',
        country: 'Lt',
        page: 1
      },
      pageSelectorData: {
        page: 1,
        pageSize: 30,
        total: 0
      },
      list: [],
      isLoading: false,
      clickedSearch: false,
      hasLvProduct: false,
      hasEeProduct: false,
      requiredFilters: ['searchText'],
      tableLabels: [
        'DetailsCompanyName',
        'DetailsCompanyCode',
        'DetailsAddress',
        'DetailsEmployeesTitle',
        ''
      ]
    }
  },

  watch: {
    '$store.state.selectedCompany': {
      handler() {
        const { selectedCompany } = Store.getters
        const currentCompany = this.$store.state.settings.companies.find(
          (company) => selectedCompany === company.id
        )

        this.selectCountryList.map((item) => {
          if (item.key === 'Lv') {
            item.disabled = !currentCompany.security.products.includes(
              PRODUCTS.BUSINESS_SCREENING_LV
            )
          }

          if (item.key === 'Ee') {
            item.disabled = !currentCompany.security.products.includes(
              PRODUCTS.BUSINESS_SCREENING_EE
            )
          }
        })
      },
      immediate: true
    }
  },

  methods: {
    clickViewButton(companyCode, countryCode) {
      this.$router.push({
        name: 'KybPageDetails',
        params: { companyCode, countryCode }
      })
    },

    pageChanged(value) {
      const { pageSelectorData } = this
      pageSelectorData.page = value
      this.$emit('changePage', value, this.filters)
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(response) {
      if (!response.data) return

      const { total, page, pageSize, items } = response.data
      this.list = items
      this.pageSelectorData = {
        page,
        pageSize,
        total: total ? total : 0
      }
      this.isLoading = false
      this.$store.dispatch(actions.updateKyb, response)
      if (!this.filters.searchText) this.clickedSearch = false
      else this.clickedSearch = true
    }
  }
}
</script>

<style scoped>
.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.italic-text {
  font-style: italic;
  color: red;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

@media all and (max-width: 992px) {
  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
