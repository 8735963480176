var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "kyb-details-page" },
            [
              !_vm.isReady
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm.revision
                ? _c(
                    "ui-card",
                    {
                      attrs: {
                        icon: "la la-institution",
                        title: _vm.revision.name,
                        "with-monitoring-toggle": _vm.countryCode === "Lt",
                        "monitoring-toggle-value": _vm.initialMonitoringState,
                        "monitoring-toggle-changed":
                          _vm.handleMonitoringToggleChange,
                        "company-code": _vm.companyCode,
                      },
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "btn-metal",
                          attrs: {
                            slot: "tools",
                            icon: "la la-download",
                            small: "",
                            disabled: !_vm.isReady,
                          },
                          on: { click: _vm.showStatements },
                          slot: "tools",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("AppStatements")) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "tabs-group",
                        {
                          attrs: {
                            slot: "body",
                            "tab-index": _vm.activeTabIndex,
                            labels: _vm.enabledTabLabels,
                          },
                          on: { change: _vm.tabChanged },
                          slot: "body",
                        },
                        [
                          _vm.tabDisabledList.includes(_vm.activeTab.key) &&
                          !_vm.isLt
                            ? _c("company-no-info")
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activeTab.key === "general"
                            ? _c("company-general-info", {
                                attrs: { data: _vm.generalInformation },
                              })
                            : _vm.activeTab.key === "financial" && _vm.isLt
                            ? _c("company-financial-info", {
                                attrs: { data: _vm.financialInformation },
                              })
                            : _vm.activeTab.key === "courts" && _vm.isLt
                            ? _c("company-courts", {
                                attrs: { "company-code": _vm.companyCode },
                              })
                            : _vm.activeTab.key === "transport" && _vm.isLt
                            ? _c("company-transport", {
                                attrs: { "company-code": _vm.companyCode },
                              })
                            : _vm.activeTab.key === "seizures" && _vm.isLt
                            ? _c("company-seizures", {
                                attrs: { "company-code": _vm.companyCode },
                              })
                            : _vm.activeTab.key === "relations" && _vm.isLt
                            ? _c("company-business-relations")
                            : _vm.activeTab.key === "ubo"
                            ? _c("ultimate-beneficial-owner", {
                                attrs: {
                                  "company-code": _vm.companyCode,
                                  "renew-time": _vm.titleValue,
                                  "search-message": _vm.searchMessage,
                                  "company-country-code": _vm.countryCode,
                                  "main-company-name": _vm.revision
                                    ? _vm.revision.name
                                    : undefined,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("span", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t(_vm.searchMessage)) + "\n    "
                    ),
                  ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            [
              _c("kyb-statements", {
                attrs: {
                  "company-code": _vm.companyCode,
                  "company-country-code": _vm.countryCode
                    ? _vm.countryCode.toUpperCase()
                    : null,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c("ui-loader", { attrs: { size: 4, center: "" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }