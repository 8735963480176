<template>
  <div class="billing-settings-page">
    <ui-header
      title="BillingInfoHeader"
      :method="searchMethod"
      :filters="filters"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    />
    <ui-container>
      <ui-form @submit="submitSave">
        <template v-if="!isLoading">
          <ui-card large>
            <div slot="body" class="flex-row align-baseline">
              <ui-widget class="flex-grow-2">
                <ui-radio
                  label="DetailsCreditCard"
                  name="method"
                  value="creditCard"
                  :error="errors.method"
                  bordered
                  @checkRadio="methodChange"
                >
                  <img slot="icon" src="~@src/assets/images/visa.png" />
                  <img slot="icon" src="~@src/assets/images/mastercard2.png" />
                  <img slot="icon" src="~@src/assets/images/maestrocard.png" />
                </ui-radio>
                <ui-input
                  id="cardNumber"
                  :value="cardDetails.cardNumber"
                  label="DetailsCardNumber"
                  class="m--margin-top-30"
                  :disabled="details.isLoading"
                  :min-length="16"
                  :max-length="19"
                  :error="errors.cardNumber"
                  multiline
                  placeholder="DetailsCardNumber"
                  @input="cardNumberChange"
                />
              </ui-widget>
              <ui-widget class="flex-grow-1">
                <div>
                  <ui-radio
                    label="DetailsPaypal"
                    name="method"
                    :error="errors.method"
                    value="paypal"
                    bordered
                    @checkRadio="methodChange"
                  >
                    <img slot="icon" src="~@src/assets/images/paypal.png" />
                  </ui-radio>
                  <expiration-date
                    :disabled="details.isLoading"
                    :month="cardDetails.month"
                    :year="cardDetails.year"
                    :error="errors.month || errors.year"
                    @changeMonth="monthChange"
                    @changeYear="yearChange"
                  />
                </div>
              </ui-widget>
              <ui-widget class="flex-grow-2">
                <information-box
                  header="BillingSecurityHeader"
                  description="BillingSecurityDescription"
                  icon="flaticon-lock"
                />
              </ui-widget>
            </div>
            <ui-button slot="footer" class="btn-brand" icon="la la-check">
              {{ $t('AppSave') }}
            </ui-button>
          </ui-card>
        </template>
      </ui-form>
      <template v-if="!isLoading">
        <ui-card title="Invoice history" icon="flaticon-list-2">
          <ui-table :labels="tableLabels" :data="model">
            <template v-if="model">
              <tr
                v-for="(row, index) in model"
                :key="index"
                class="m-table__row"
                :class="{
                  'status-pending': row.status === 'StatusDue',
                  'status-rejected': row.status === 'StatusOverdue'
                }"
              >
                <td>{{ row.invoice }}</td>
                <td>{{ row.amount }}</td>
                <td>{{ row.date }}</td>
                <td class="m--font-success">
                  {{ $t(row.status) }}
                </td>
                <td class="icon">
                  <i class="la la-file-excel-o" @click="exportExcel" />
                  <i class="la la-file-pdf-o" @click="exportPdf" />
                  <i class="la la-file-code-o" @click="exportCode" />
                </td>
              </tr>
            </template>
          </ui-table>
          <ui-page-selector
            :active-page="filters.page"
            :items-count="itemsCount"
            :max-on-page="filters.pageSize"
            @change="pageChanged"
          />
        </ui-card>
      </template>
      <ui-loader v-else :size="4" center />
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import InformationBox from '@src/components/partials/InformationBox'
import ExpirationDate from '@src/components/partials/ExpirationDate'
import {
  requiredValidator,
  minLengthValidator,
  validateForm
} from '@src/scripts/validators'

import IMask from 'imask'

export default {
  components: {
    InformationBox,
    ExpirationDate
  },

  data() {
    return {
      isLoading: false,
      details: {
        isLoading: false
      },
      itemsCount: 0,
      filters: {
        pageSize: 10,
        page: null
      },
      tableLabels: [
        'Invoice',
        'Amount',
        'Biling date',
        'TableStatus',
        'Export'
      ],
      cardDetails: { cardNumber: '', month: '', year: '', method: '' },
      errors: { cardNumber: null, month: null, year: null, method: null },
      searchMethod: '/users/search', //reikia pakeisti metoda
      itemsList: null
    }
  },

  computed: {
    model() {
      const { itemsList } = this
      if (!itemsList || !itemsList.length) return null
      const result = []

      for (const item of itemsList) {
        result.push({
          invoice: item.invoice,
          amount: item.amount,
          date: item.date,
          status: item.status
        })
      }
      return result
    }
  },

  created() {
    this.defaultValidations(this.cardDetails)
  },

  methods: {
    exportExcel() {},

    exportPdf() {},

    exportCode() {},

    pageChanged(value) {
      this.filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch() {
      this.isLoading = false
      // const { list, total } = data
      // this.itemsList = list
      // this.itemsCount = total
      this.itemsList = [
        {
          invoice: 'Services',
          amount: '$ 10 000',
          date: '2018-01-23',
          status: 'StatusDue'
        },
        {
          invoice: 'Services',
          amount: '$ 15 000',
          date: '2018-01-28',
          status: 'StatusPaid'
        },
        {
          invoice: 'Services',
          amount: '$ 15 000',
          date: '2018-01-28',
          status: 'StatusOverdue'
        }
      ]
      this.itemsCount = this.itemsList.length
    },

    cardNumberChange(value) {
      const { errors, cardDetails } = this
      errors.cardNumber = requiredValidator(value)
      if (!errors.cardNumber) errors.cardNumber = minLengthValidator(value, 19)
      if (value) {
        const element = document.querySelector('#cardNumber input')
        const maskOptions = {
          mask: '0000-0000-0000-0000'
        }
        const mask = new IMask(element, maskOptions)
        value = mask.value
      }
      this.$set(cardDetails, 'cardNumber', value)
    },

    monthChange(value) {
      this.errors.month = requiredValidator(value)
      this.$set(this.cardDetails, 'month', value)
    },

    yearChange(value) {
      this.errors.year = requiredValidator(value)
      this.$set(this.cardDetails, 'year', value)
    },

    methodChange(value) {
      this.errors.method = requiredValidator(value)
      this.$set(this.cardDetails, 'method', value)
    },

    async submitSave() {
      const { cardNumber, month, year, method } = this.cardDetails
      if (!validateForm(this.errors)) return
      this.details.isLoading = true
      try {
        await Api.post('/...', {
          cardNumber,
          month,
          year,
          method
        })
      } catch (error) {
        this.showError()
      }
      this.details.isLoading = false
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    defaultValidations(data) {
      this.cardNumberChange(data.cardNumber, true)
      this.monthChange(data.month, true)
      this.yearChange(data.year, true)
      this.methodChange(data.method, true)
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.no-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.icon i {
  font-size: 1.7rem;
}

.display-none {
  display: none;
}

.margin-top-30 {
  margin-top: 2.2rem;
}
</style>
