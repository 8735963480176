var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-widget", [
    _c("p", { staticClass: "align-center" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("CheckSettingCountriesDescription")) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("h5", { staticClass: "bold align-center" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t("CheckSettingCountriesSettings")) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "ui-form",
          { key: _vm.submitCount, on: { submit: _vm.saveChanges } },
          [
            _c("div", { staticClass: "row settings-titles" }, [
              _c("div", { staticClass: "flex-basis-48" }, [
                _c("div", { staticStyle: { "margin-right": "50px" } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-row flex-basis-50" }, [
                _c("div", { staticClass: "flex-basis-18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("CheckSettingPassport")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-basis-18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("CheckSettingId")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-basis-18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("CheckSettingDriving")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-basis-18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("CheckSettingResidence")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "flex-basis-18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("CheckSettingOther")) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.settings, function (regions, index) {
              return _c("country-settings-box", {
                key: index,
                attrs: { regions: regions, disabled: !_vm.details.editEnabled },
                on: {
                  passportChanged: function ($event) {
                    return _vm.passportChanged($event, index)
                  },
                  idChanged: function ($event) {
                    return _vm.idChanged($event, index)
                  },
                  drivingChanged: function ($event) {
                    return _vm.drivingChanged($event, index)
                  },
                  residenceChanged: function ($event) {
                    return _vm.residenceChanged($event, index)
                  },
                  otherChanged: function ($event) {
                    return _vm.otherChanged($event, index)
                  },
                },
              })
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tools-buttons" },
              [
                _c("edit-footer", {
                  attrs: { "edit-enabled": _vm.details.editEnabled },
                  on: { edit: _vm.enableDetailsEdit },
                }),
                _vm._v(" "),
                _vm.details.editEnabled
                  ? _c(
                      "ui-button",
                      {
                        staticClass: "btn btn-metal m--margin-left-10",
                        attrs: { small: "" },
                        on: { click: _vm.clickCancel },
                      },
                      [
                        _c("i", { staticClass: "la la-close" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(" " + _vm._s(_vm.$t("AppCancel")) + " "),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }