<template>
  <iframe class="high" :src="formUrl" />
</template>

<script>
import { ControllerType, iframeMediator } from '@ondato/iframe-mediator'

export default {
  props: {
    accessToken: { type: String, default: null },
    formIds: { type: Array, default: null }
  },

  data() {
    return {
      isReady: false,
      formUrl: null,
      language: localStorage.getItem('language') || 'en'
    }
  },

  mounted() {
    this.loadForm()
  },

  methods: {
    loadForm() {
      if (this.isReady) return

      const controller = iframeMediator({
        type: ControllerType.Parent,
        element: this.$el,
        allowedOrigin: BaseUrl.formKyb
      })

      controller.registerHandler('app-init', this.formReady)

      this.setUrl()
      this.isReady = true
    },

    setUrl() {
      this.formUrl = `${
        BaseUrl.formKyb
      }/applications/${FormId}/index.html?v=${Date.now()}`
    },

    formReady() {
      const { language, accessToken, formIds } = this

      return {
        language,
        mode: 'ReviewReadonly',
        accessToken: `Bearer ${accessToken}`,
        setup: {
          ids: formIds
        }
      }
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  border: none;
}

.high {
  min-height: 80vh;
}
</style>
