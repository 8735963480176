var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        { staticClass: "details" },
        [
          _c(
            "div",
            { staticClass: "actions" },
            [
              _c(
                "ui-button",
                {
                  staticClass: "btn-danger",
                  attrs: { disabled: _vm.disabled, small: "" },
                  on: { click: _vm.reject },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("App.Reject")) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "ui-button",
                {
                  staticClass: "btn-success",
                  attrs: { disabled: _vm.disabled, small: "" },
                  on: { click: _vm.approve },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("App.Approve")) + "\n    ")]
              ),
              _vm._v(" "),
              _c("ui-dropdown", {
                staticClass: "margin-right",
                attrs: {
                  options: _vm.exportOptions,
                  placeholder: "AppExport",
                  small: "",
                  metal: "",
                },
                on: { input: _vm.exportList },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "details-box",
            [
              _c("details-item", {
                attrs: {
                  label: _vm.$t("Kyb.Company.Status"),
                  value: _vm.$t("Kyb.ReviewStatuses." + _vm.data.status),
                },
              }),
              _vm._v(" "),
              _c("details-item", {
                attrs: {
                  label: _vm.$t("Kyb.Company.Date"),
                  value: _vm._f("date")(_vm.data.createdUtc),
                },
              }),
              _vm._v(" "),
              _c("details-item", {
                attrs: { label: _vm.$t("Kyb.Company.Id"), value: _vm.data.id },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "company-row" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.renderForm && !_vm.data.isIdvKybFlow
                  ? _c("client-form", {
                      staticClass: "client-form",
                      attrs: {
                        id: _vm.data.verificationFormId.replaceAll("-", ""),
                        type: _vm.registrationForm.type,
                        data: _vm.registrationForm.data,
                        setup: _vm.registrationForm.setup,
                        high: "",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.renderForm && _vm.data.isIdvKybFlow
                  ? _c("client-form-kyb", {
                      staticClass: "client-form",
                      attrs: {
                        "access-token": _vm.data.idvFormApiAccessToken,
                        "form-ids": _vm.data.idvFormIds,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column document-wrapper" }, [
              _c(
                "div",
                { staticClass: "document-container" },
                [_c("documents", { on: { canApprove: _vm.setDisable } })],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("activity-logs", {
            staticClass: "activity-logs",
            attrs: { data: _vm.activityLogsUi, loading: _vm.isLogsLoading },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }