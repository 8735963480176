var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui-tag-group" }, [
    _vm.label
      ? _c("span", { staticClass: "details-label" }, [
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.editable
      ? _c(
          "span",
          { staticClass: "display-flex-row" },
          [
            _vm._l(_vm.tags, function (tag, index) {
              return _c(
                "span",
                {
                  key: index,
                  staticClass: "ui-tag",
                  class: { "editable-tag": _vm.editable },
                },
                [
                  _vm._v("\n      " + _vm._s(tag) + "\n      "),
                  _c(
                    "span",
                    {
                      staticClass: "delete-tag",
                      on: {
                        click: function ($event) {
                          return _vm.removeTag(tag)
                        },
                      },
                    },
                    [_vm._v(" x ")]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tag-input" },
              [
                _c("ui-input", {
                  attrs: { placeholder: _vm.placeholder, value: _vm.newTag },
                  on: { change: _vm.changeInput },
                }),
              ],
              1
            ),
          ],
          2
        )
      : _c("span", { staticClass: "ui-tag" }, [
          _vm._v("\n    " + _vm._s(_vm.tags.join(", ")) + "\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }