<template>
  <textarea
    v-model="value"
    :disabled="disabled"
    :placeholder="placeholder"
    :cols="cols"
    :rows="rows"
    @input="$emit('input', $event.target.value)"
  ></textarea>
</template>
<script>
export default {
  name: 'ui-textarea',
  props: {
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    cols: { type: Number, default: 40 },
    rows: { type: Number, default: 5 },
    value: { type: String, default: '' }
  }
}
</script>

<style scoped>
textarea {
  padding: 0.538rem;
  border: 1px solid var(--light-border);
}
</style>
