<template>
  <box-with-header>
    <div v-if="!hideButtons" slot="header" class="header-box">
      <ui-dropdown
        v-if="declarationOptions"
        :options="declarationOptions"
        placeholder="AppDeclarations"
        class="margin-right"
        small
        light-border
        @input="exportDeclarations"
      />
      <ui-dropdown
        :options="exportOptions"
        placeholder="AppExport"
        small
        metal
        @input="exportList"
      />
    </div>
    <ui-container class="sanctions-details-page">
      <div
        v-if="hideButtons"
        class="loader-absolute"
        :class="{ 'white-bg': $route.params.export }"
      >
        <ui-loader :size="4" center />
      </div>
      <div v-if="isLoading" class="col-md-12 col-lg-12">
        <ui-loader :size="3" center />
      </div>
      <template v-else>
        <p class="searchedInText">
          {{ $t('SearchedIn') }} {{ $route.params.fuzzinessPercent }}%
        </p>
        <ui-card v-if="!sanctionData">
          <p>{{ $t('SanctionDataNotFound') }}</p>
        </ui-card>
        <template v-else>
          <ui-card title="KeyInformation" class="doc-export-block" multicolumn>
            <div slot="body-left">
              <div class="col-12">
                <details-box>
                  <details-item-simple-text
                    v-if="sanctionData.fullname"
                    label="DetailsName"
                    :value="sanctionData.fullname"
                    is-inner
                  />
                  <details-item-simple-text
                    v-if="sanctionData.dob"
                    label="DetailsDOB"
                    :value="sanctionData.dob"
                    is-inner
                  />
                  <details-item-with-tags
                    v-if="sanctionData.source_notes"
                    label="DetailsSources"
                    :values="sanctionData.source_notes"
                    no-style-tabs
                    ul-list
                    is-inner
                  />
                  <details-item-with-tags
                    v-if="sanctionData.types"
                    label="DetailsListingType"
                    :values="sanctionData.types"
                    no-style-tabs
                    ul-list
                    is-inner
                  />
                </details-box>
              </div>
            </div>
            <div slot="body-right">
              <div
                v-if="sanctionData.images && sanctionData.images.length > 0"
                class="col-12 media-export-block"
              >
                <img
                  :src="sanctionData.images[0].public_url"
                  alt=""
                  class="sanction-image"
                />
              </div>
            </div>
          </ui-card>
          <ui-card
            v-if="sanctionData.fields && sanctionData.fields.length > 0"
            class="doc-export-block"
            title="AdditionalInformation"
          >
            <div class="row">
              <details-box>
                <details-item-with-tags
                  v-if="sanctionData.aka && sanctionData.aka.length > 0"
                  label="AKA"
                  :values="sanctionData.aka"
                  no-style-tabs
                  is-inner
                />
                <details-item-with-tags
                  v-if="
                    sanctionData.associates &&
                    sanctionData.associates.length > 0
                  "
                  :label="$t('DetailsAssociates')"
                  :values="sanctionData.associates"
                  no-style-tabs
                  is-inner
                />
                <details-item-simple-text
                  v-for="(field, index) in sanctionData.fields"
                  :key="index"
                  :label="validFieldName(field.name)"
                  :value="field.value"
                  is-inner
                />
              </details-box>
            </div>
          </ui-card>
          <ui-card
            v-if="sanctionData.medias && sanctionData.medias.length > 0"
            title="AdverseMedia"
            class="add-export-block"
          >
            <div class="row">
              <details-box>
                <details-item-media-row
                  v-for="(media, index) in sanctionData.medias"
                  :key="index"
                  :value="media"
                  icon="far fa-newspaper"
                />
              </details-box>
            </div>
          </ui-card>
          <ui-card
            v-if="sanctionData.medias && sanctionData.medias.legth > 0"
            title="MediaSources"
            class="add-export-block"
            expandable
            multicolumn
          >
            <div slot="body-left">
              <template v-for="(media, index) in sanctionData.medias">
                <template v-if="!(index % 2)">
                  <div
                    :key="index"
                    class="col-12 details-item with-values inner-page-style"
                  >
                    <span class="details-label">
                      {{ $t('DataSource') }}
                    </span>
                    <div class="details-value">
                      <template v-if="media.pdf_url">
                        <a :href="media.pdf_url" target="_blank">
                          {{ $t('PDFDownload') }}
                        </a>
                        <span>
                          {{ domainName(media.url) }}
                        </span>
                      </template>
                      <template v-if="!media.pdf_url">
                        <span>
                          {{ domainName(media.url) }}
                        </span>
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </div>
            <div slot="body-right">
              <template v-for="(media, index) in sanctionData.medias">
                <template v-if="index % 2">
                  <div
                    :key="index"
                    class="col-12 details-item with-values inner-page-style"
                  >
                    <span class="details-label">
                      {{ $t('DataSource') }}
                    </span>
                    <div class="details-value">
                      <template v-if="media.pdf_url">
                        <a :href="media.pdf_url" target="_blank">
                          {{ $t('PDFDownload') }}
                        </a>
                        <span>
                          {{ domainName(media.url) }}
                        </span>
                      </template>
                      <template v-if="!media.pdf_url">
                        <span>
                          {{ domainName(media.url) }}
                        </span>
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </ui-card>
        </template>
      </template>
    </ui-container>
  </box-with-header>
</template>
<script>
import DetailsItemSimpleText from '@src/components/partials/DetailsItemSimpleText'
import DetailsItemWithTags from '@src/components/partials/DetailsItemWithTags'
import DetailsItemMediaRow from '@src/components/partials/DetailsItemMediaRow'
import BoxWithHeader from '@src/components/partials/BoxWithHeader'
import { exportPdf } from '@src/scripts/exportPdf'
import DetailsBox from '@src/components/partials/DetailsBox'
import { mapOptions } from '@src/scripts/helpers'

export default {
  components: {
    DetailsItemSimpleText,
    DetailsItemWithTags,
    DetailsItemMediaRow,
    BoxWithHeader,
    DetailsBox
  },

  data() {
    return {
      isReady: false,
      isLoading: false,
      sanctionData: null,
      hideButtons: false
    }
  },

  computed: {
    sanctionId() {
      return this.$route.params && this.$route.params.sanctionId
    },

    exportOptions() {
      return mapOptions(['PDF'])
    },

    declarationOptions() {
      return null
    }
  },

  mounted() {
    this.isLoading = true
    const { sanctions } = this.$store.state
    const { sanctionId } = this
    if (sanctions && sanctions.length > 0) {
      for (let x = 0; x < sanctions.length; x++) {
        if (sanctions[x].id === sanctionId) {
          this.sanctionData = sanctions[x]
        }
      }
    }
    if (this.$route.params.export) {
      this.hideButtons = true
      setTimeout(() => this.exportPdf(), 1000)
    }
    if (this.sanctionData && this.sanctionData.fields) {
      const filter = this.removeDuplicates(this.sanctionData.fields)
      this.$set(this.sanctionData, 'fields', filter)
    }
    this.isLoading = false
  },

  methods: {
    validFieldName(name) {
      if (name === 'Country') return 'DetailsCountry'
      return name
    },

    removeDuplicates(objectsArray) {
      let array = {}
      for (let item of objectsArray) {
        if (!array[item.name]) {
          array[item.name] = []
          array[item.name].push(item.value)
        } else {
          array[item.name].push(item.value)
        }
        for (let i = 0; i < array[item.name].length; i++) {
          const filt = []
          for (let name in array[item.name]) {
            if (filt.indexOf(array[item.name][name]) === -1)
              filt.push(array[item.name][name])
          }
          array[item.name] = filt
        }
      }
      const filter = this.getFilterArray(array)
      return filter
    },

    getFilterArray(array) {
      const filter = []
      for (let item in array) {
        if (array[item].length > 1) {
          for (let it in array[item]) {
            filter.push({
              name: item,
              value: array[item][it]
            })
          }
        } else {
          filter.push({
            name: item,
            value: array[item].join()
          })
        }
      }
      return filter
    },

    domainName(url) {
      const domainPattern = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i)
      const extractDomain = domainPattern[1]
      return extractDomain
    },

    exportList(value) {
      if (value === 'PDF') {
        this.exportPdf()
      } else if (value === 'CSV') {
        //
      } else if (value === 'XLS') {
        //
      }
    },

    exportDeclarations() {
      //
    },

    async exportPdf() {
      this.hideButtons = true
      const name = this.$store.getters.sessionData.profile.name
      const data = [
        // { selector: '.media-export-block',
        //   type: 'media' },
        { selector: '.searchedInText' },
        { selector: '.doc-export-block' },
        { selector: '.add-export-block' }
      ]
      const exportFile = new exportPdf(data, name, 'details')
      try {
        await exportFile.exportPdf()
        if (this.$route.params.export) {
          this.hideButtons = true
          setTimeout(() => window.close(), 1000)
        }
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      if (!this.$route.params.export) this.hideButtons = false
    }
  }
}
</script>

<style scoped>
.header-box {
  display: flex;
  flex-flow: row nowrap;
}

.margin-right {
  margin-right: 1rem;
}

.loader-absolute {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10;
  padding-top: 2.5rem;
  margin-top: -2.5rem;
  margin-left: -30px;
}

.loader-absolute.white-bg {
  background-color: #f2f3f8;
}

.details-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-label {
  padding: 0.85rem 1.15rem;
}

.details-item.with-values {
  width: 100%;
}

.details-item.with-values .details-label {
  max-width: 115px;
  box-sizing: border-box;
}

.details-item.with-values.inner-page-style .details-label {
  max-width: 180px;
}

.details-item.with-values .details-value {
  width: calc(100% - 115px);
  box-sizing: border-box;
  padding-top: 0.85rem;
}

.details-item.with-values.inner-page-style .details-value {
  width: calc(100% - 180px);
}

.sanction-image {
  margin-top: 15px;
  max-width: 100%;
  width: auto;
  height: auto;
}

@media (max-width: 1024px) {
  .loader-absolute {
    width: 100%;
  }
}
</style>
<style>
@media (max-width: 678px) {
  .details-box .details-item.with-values.inner-page-style .details-value {
    width: 100%;
    word-break: break-word;
  }

  .sanctions-details-page .details-box {
    width: 100%;
  }
}
</style>
