var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-input-icon m-input-icon--right",
      class: _vm.wide
        ? "m-input-icon--wide"
        : _vm.medium
        ? "m-input-icon--medium"
        : "m-input-icon--fixed",
    },
    [
      _c("ui-dropdown", {
        attrs: {
          placeholder: _vm.placeholder,
          options: _vm.options,
          "has-search": _vm.hasSearch,
          "key-value-dropdown": _vm.keyValueDropdown,
          value: _vm.value,
          light: "",
          disabled: _vm.disabled,
          all: _vm.all,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }