var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c("ui-toggle", {
            attrs: {
              label: _vm.$t("Kyb.Representative.ExternallyVerified"),
              value: _vm.data.isExternallyVerified,
              disabled: _vm.disabled,
              prevent: "",
            },
            on: { change: _vm.externallyVerifiedChange },
          }),
          _vm._v(" "),
          _vm.data.kycIdentificationId
            ? [
                _c(
                  "ui-link",
                  {
                    staticClass: "btn-brand",
                    attrs: {
                      to: {
                        name: "IdentificationDetails",
                        params: {
                          identificationId: _vm.data.kycIdentificationId,
                        },
                      },
                      target: "_blank",
                      small: "",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("App.Open")) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ui-button",
                  {
                    staticClass: "btn-silver",
                    attrs: { disabled: _vm.disabled, small: "" },
                    on: { click: _vm.exportPdf },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("App.Export")) + "\n      "
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "details-box",
        [
          _c("details-item", {
            attrs: {
              label: _vm.$t("Kyb.Representative.Id"),
              value: _vm.data.id,
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: _vm.$t("Kyb.Representative.Name"),
              value: _vm.data.registration.fullName,
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: _vm.$t("Kyb.Representative.Code"),
              value: _vm.data.registration.personalCode,
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: _vm.$t("Kyb.Representative.KycStatus"),
              value: _vm.$t("Kyb.RepresentativeStatuses." + _vm.status),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.data.kycIdentificationId
        ? _c("identification", {
            staticClass: "identification-box",
            attrs: {
              id: _vm.data.kycIdentificationId,
              "disable-actions": "",
              "full-size": "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }