var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("img", {
      staticClass: "icon",
      attrs: { src: require("@src/assets/images/icons/cloud-slash.svg") },
    }),
    _vm._v(" "),
    _c("p", [_vm._v(_vm._s(_vm.$t("NoData")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }