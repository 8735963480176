var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    { on: { submit: _vm.saveChanges } },
    [
      _c(
        "ui-widget",
        { staticClass: "col-md-6", attrs: { title: "" } },
        [
          _c("details-item", {
            attrs: {
              label: "KycStartUrl",
              value: _vm.revision.startUrl,
              editable: _vm.editEnabled,
            },
            on: { change: _vm.startUrlChanged },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "KycRedirectUrl",
              value: _vm.revision.redirectUrl,
              editable: _vm.editEnabled,
            },
            on: { change: _vm.redirectUrlChanged },
          }),
          _vm._v(" "),
          _vm.revision.label.includes("Document")
            ? _c("details-item", {
                attrs: {
                  label: "KycAllowCamera",
                  value: _vm.revision.allowCamera,
                  editable: _vm.editEnabled,
                },
                on: { change: _vm.allowCameraChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "KycPageTitle",
              value: _vm.revision.pageTitle,
              editable: _vm.editEnabled,
            },
            on: { change: _vm.pageTitleChanged },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "KycActionLogUrl",
              value: _vm.revision.actionLogUrl,
              editable: _vm.editEnabled,
            },
            on: { change: _vm.actionLogUrlChanged },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "KycActionLogLanguage",
              placeholder: "changeLang",
              value: _vm.revision.actionLogLanguage,
              editable: _vm.editEnabled,
              options: _vm.lanugageOptions,
            },
            on: { change: _vm.actionLogLanguageChanged },
          }),
          _vm._v(" "),
          _c("ui-tag-group", {
            attrs: {
              label: "KycPendingIdentificationEmailAddress",
              placeholder: "DetailsNewTag",
              editable: _vm.editEnabled,
              tags: _vm.revision.pendingIdentificationEmailAddress,
            },
            on: { removeTag: _vm.deleteEmail, addTag: _vm.addEmail },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "KycSuccessfulIdentificationEmailAddress",
              value: _vm.revision.successfulIdentificationEmailAddress,
              editable: _vm.editEnabled,
            },
            on: { change: _vm.successfulIdentificationEmailAddressChanged },
          }),
          _vm._v(" "),
          _vm.revision.label.includes("Form")
            ? _c("details-item", {
                attrs: {
                  label: "KycFormTypeId",
                  options: _vm.revision.formTypeIdOptions,
                  value: _vm.revision.formTypeId,
                  placeholder: "KycFormTypeId",
                  editable: _vm.editEnabled,
                },
                on: { change: _vm.formTypeIdChanged },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("edit-footer", {
            staticClass: "footer",
            attrs: { "edit-enabled": _vm.editEnabled },
            on: { edit: _vm.enableDetails },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }