var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "capture-box", class: { "has-image": _vm.value } },
    [
      _c(
        "div",
        { staticClass: "image-box", style: _vm.image },
        [
          !_vm.value
            ? _c(
                "ui-button",
                {
                  staticClass: "btn-brand",
                  attrs: { disabled: _vm.disabled },
                  on: { click: _vm.capturePhoto },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t(_vm.label)) + "\n    ")]
              )
            : _c(
                "ui-button",
                {
                  staticClass: "btn-outline-brand",
                  on: { click: _vm.resetPhoto },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("CallRecapture")) + "\n    "
                  ),
                ]
              ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.value && !_vm.disabled,
                expression: "value && !disabled",
              },
            ],
            staticClass: "mark mark-success",
          }),
          _vm._v(" "),
          _c("ui-loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.disabled,
                expression: "disabled",
              },
            ],
            attrs: { center: "" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }