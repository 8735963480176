<template>
  <div class="time-box">
    <span v-if="label" class="selector-label">{{ label }}</span>
    <div class="selector-group">
      <time-selector
        :value="selectedValue.from"
        :nullable="nullable"
        :disabled="disabled"
        @change="fromChange"
      />
      <time-selector
        :value="selectedValue.to"
        :nullable="nullable"
        :disabled="disabled"
        @change="toChange"
      />
    </div>
  </div>
</template>

<script>
import TimeSelector from '@src/components/partials/TimeSelector'

export default {
  components: {
    TimeSelector
  },

  props: {
    label: { type: String, default: undefined },
    value: { type: Object, default: undefined },
    nullable: Boolean,
    disabled: Boolean
  },

  computed: {
    selectedValue() {
      return {
        from: null,
        to: null,
        ...this.value
      }
    }
  },

  methods: {
    fromChange(value) {
      this.updateValue(value, 'from', 'to')
    },

    toChange(value) {
      this.updateValue(value, 'to', 'from')
    },

    updateValue(value, mainProperty, secondaryProperty) {
      if (!value) {
        this.$emit('change', null)
        return
      }

      const returnValue = { ...this.selectedValue }
      returnValue[mainProperty] = value

      if (!returnValue[secondaryProperty]) {
        returnValue[secondaryProperty] = {
          hours: 0,
          minutes: 0
        }
      }

      this.$emit('change', returnValue)
    }
  }
}
</script>

<style scoped>
.time-box,
.selector-group {
  display: flex;
  flex-wrap: wrap;
}

.time-box {
  justify-content: space-between;
  align-items: center;
}

.selector-group {
  display: flex;
  flex-wrap: wrap;
}

.time-selector:first-child {
  margin-right: 3rem;
}

.selector-label {
  margin: 0.5rem 1rem 0.5rem 0;
}
</style>
