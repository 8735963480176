var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    {
      key: _vm.submitCount,
      staticClass: "country-settings",
      on: { submit: _vm.saveChanges },
    },
    [
      _c("div", { staticClass: "checking-methods" }, [
        _c(
          "div",
          { staticClass: "flex-basic" },
          [
            _vm._l(_vm.settings, function (item, index) {
              return _c(
                "ui-card",
                { key: index, attrs: { dark: "", title: item.title } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-column" },
                    [
                      _c("p", [
                        _c("i", { staticClass: "icon", class: item.icon }),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        typeof item.document === "object"
                          ? _c(
                              "span",
                              _vm._l(item.document, function (doc, key) {
                                return _c(
                                  "p",
                                  {
                                    key: key,
                                    staticClass: "color-brand cursor",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDocument(doc.url)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "flaticon-camera" }),
                                    _vm._v(
                                      _vm._s(doc.title) + "\n              "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "p",
                              {
                                staticClass: "color-brand cursor",
                                on: {
                                  click: function ($event) {
                                    return _vm.openDocument(item.document)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "flaticon-camera" }),
                                _vm._v(
                                  _vm._s(_vm.$t("DetailsDocumentExample")) +
                                    "\n            "
                                ),
                              ]
                            ),
                        _vm._v(" "),
                        _c("p", [
                          _c("i", {
                            class: {
                              "la	la-check-circle": item.mrzZone,
                              "flaticon-cancel": !item.mrzZone,
                            },
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("CheckContainsMRZzone")) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("ui-toggle", {
                        attrs: {
                          value: item.enabled,
                          disabled: !_vm.details.editEnabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.toggleChange($event, index)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c("know-country", { staticClass: "flex-basic-70" }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tools-buttons" },
          [
            _c("edit-footer", {
              attrs: { "edit-enabled": _vm.details.editEnabled },
              on: { edit: _vm.enableDetailsEdit },
            }),
            _vm._v(" "),
            _vm.details.editEnabled
              ? _c(
                  "ui-button",
                  {
                    staticClass: "btn btn-metal m--margin-left-10",
                    attrs: { small: "" },
                    on: { click: _vm.clickCancel },
                  },
                  [
                    _c("i", { staticClass: "la la-close" }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("AppCancel")) + " "),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }