var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "seon-email" },
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("Seon.Email.Header")))]),
      _vm._v(" "),
      _vm.isReady
        ? _c(
            "div",
            [
              _vm.data
                ? [
                    _c("Group", [_c("h5", [_vm._v(_vm._s(_vm.data.email))])]),
                    _vm._v(" "),
                    _vm.appliedRules
                      ? _c(
                          "Group",
                          [
                            _c("h5", [
                              _vm._v(_vm._s(_vm.$t("Seon.Email.AppliedRules"))),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.appliedRules, function (item) {
                              return _c("GroupItem", {
                                key: item.id,
                                attrs: { name: item.id, value: item.name },
                              })
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "Group",
                      [
                        _c("GroupItem", {
                          attrs: {
                            name: _vm.$t("Seon.Email.Deliverable"),
                            value:
                              (_vm.data.deliverable && _vm.$t("App.Yes")) ||
                              _vm.$t("App.No"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.breachDetails
                      ? _c(
                          "Group",
                          [
                            _c("h5", [
                              _vm._v(
                                _vm._s(_vm.$t("Seon.Email.DataBreaches.Label"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.DataBreaches.Count"),
                                value: _vm.breachDetails.number_of_breaches,
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.DataBreaches.First"),
                                value:
                                  _vm.breachDetails.first_breach ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _vm._l(_vm.breachDetails.breaches, function (item) {
                              return _c("GroupItem", {
                                key: item.name,
                                attrs: { name: item.name, value: item.date },
                              })
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.onlineProfiles
                      ? _c(
                          "Group",
                          [
                            _c("h5", [
                              _vm._v(
                                _vm._s(_vm.$t("Seon.Email.OnlineProfiles"))
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.onlineProfiles, function (item) {
                              return _c("GroupItem", {
                                key: item.name,
                                attrs: {
                                  name: item.name,
                                  value:
                                    (item.value && _vm.$t("App.Yes")) ||
                                    _vm.$t("App.No"),
                                },
                              })
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.domain
                      ? _c(
                          "Group",
                          [
                            _c("h5", [
                              _vm._v(_vm._s(_vm.$t("Seon.Email.Domain.Label"))),
                            ]),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Registered"),
                                value:
                                  (_vm.domain.registered &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.CreationDate"),
                                value:
                                  (_vm.domain.created &&
                                    _vm.dateTimeHandler(_vm.domain.created)) ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.UpdateDate"),
                                value:
                                  (_vm.domain.updated &&
                                    _vm.dateTimeHandler(_vm.domain.updated)) ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.ExpireDate"),
                                value:
                                  (_vm.domain.expires &&
                                    _vm.dateTimeHandler(_vm.domain.expires)) ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Name"),
                                value:
                                  _vm.domain.registrar_name ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Owner"),
                                value:
                                  _vm.domain.registered_to ||
                                  _vm.$t("App.None"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Dmarc"),
                                value:
                                  (_vm.domain.dmarc_enforced &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Spf"),
                                value:
                                  (_vm.domain.spf_strict &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.ValidMx"),
                                value:
                                  (_vm.domain.valid_mx && _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.AcceptAll"),
                                value:
                                  (_vm.domain.accept_all &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.Tld"),
                                value:
                                  (_vm.domain.suspicious_tld &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                            _vm._v(" "),
                            _c("GroupItem", {
                              attrs: {
                                name: _vm.$t("Seon.Email.Domain.WebExists"),
                                value:
                                  (_vm.domain.website_exists &&
                                    _vm.$t("App.Yes")) ||
                                  _vm.$t("App.No"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _c("p", [_vm._v(_vm._s(_vm.$t("Seon.Email.Error")))]),
            ],
            2
          )
        : _c("ui-loader", { attrs: { center: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }