<template>
  <div class="start-session">
    <div class="start-session-button">
      <button class="btn btn-link" @click="startSession">
        <i class="far fa-address-card"></i>
        <p class="kyc-text">{{ $t('AppYourKYCLink') }}</p>
      </button>
    </div>
    <div v-if="openKycLink" class="kyc-link">
      <div class="close" @click="clickClose">x</div>
      <div>
        <p>{{ $t('YourCompanyIdentityVerificationLink') }}</p>
      </div>
      <div class="display-flex">
        <div class="link-text">
          <ui-loader v-if="!sessionLink" :size="1" center />
          {{ sessionLink }}
        </div>
        <ui-button
          class="btn btn-brand"
          :disabled="!sessionLink"
          small
          @click="copyIdentificationId"
        >
          {{ $t('AppCopy') }}
        </ui-button>
        <ui-button
          class="btn btn-outline-brand"
          :disabled="!sessionLink"
          small
          @click="openNewTab"
        >
          {{ $t('AppOpen') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { API } from '@src/scripts/enums'
import { copy } from '@src/scripts/clipboard'
import { notificationType } from '@src/components/notification'

export default {
  data() {
    return {
      sessionLink: null,
      isLoading: false,
      openKycLink: false
    }
  },

  computed: {
    selectedCompany() {
      const { selectedCompany } = this.$store.getters
      return selectedCompany
    }
  },

  watch: {
    selectedCompany() {
      this.sessionLink = null
      this.openKycLink = false
    }
  },

  methods: {
    async startSession() {
      this.openKycLink = !this.openKycLink
      if (!this.sessionLink) {
        this.isLoading = true
        try {
          const { redirectUrl } = await Api.post(
            API.IDENTIFICATIONS_START_SESSION,
            {
              flowData: { language: localStorage.getItem('language') || 'en' }
            }
          )
          this.sessionLink = redirectUrl
        } catch (error) {
          this.notifyError()
        }
        this.isLoading = false
      }
    },

    openNewTab() {
      if (this.sessionLink) {
        window.open(this.sessionLink)
        this.sessionLink = null
        this.openKycLink = false
      }
    },

    clickClose() {
      this.openKycLink = false
    },

    copyIdentificationId() {
      copy(this.sessionLink)
      this.sessionLink = null
      this.openKycLink = false
      this.$notify({
        title: 'AppCopied',
        type: notificationType.infoTitle
      })
    },

    notifyError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.start-session {
  text-align: center;
}

.start-session-button {
  box-shadow: 0 -5px 5px -5px #adadad;
  background-color: #fff;
}

.start-session-button button {
  margin: 0;
}

.kyc-text {
  color: #575962;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  margin-bottom: 0;
}

.btn-link:hover {
  text-decoration: unset;
}

.btn-link:focus {
  text-decoration: unset;
}

.btn-link:hover p,
.btn-link:hover i {
  color: var(--brand);
}

.fa-address-card {
  font-size: 1.75rem;
  color: #57596288;
}

.kyc-link {
  position: absolute;
  width: 40rem;
  background-color: #fff;
  box-shadow: 0 1px 15px 1px #adadad;
  padding: 1.5rem;
  left: 8.35rem;
  bottom: 0;
  text-align: left;
}

.kyc-link button {
  margin-left: 0.5rem;
}

.link-text {
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #adadad;
  border-radius: 0.125rem;
  white-space: nowrap;
  padding: 0.7rem;
  flex-grow: 1;
}

.kyc-link .close {
  margin-top: -1rem;
  margin-right: -0.75rem;
}

@media all and (max-width: 1026px) {
  .kyc-link {
    width: 35rem;
    left: 7.5rem;
  }
}

@media all and (max-width: 700px) {
  .kyc-link {
    width: 20rem;
  }

  .link-text {
    margin-bottom: 1rem;
  }

  .kyc-link .display-flex {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .kyc-link button {
    margin: 0;
    width: 48%;
  }
}
</style>
