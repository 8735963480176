<template>
  <div class="user-container">
    <div>
      <ui-header
        :method="searchMethod"
        :filters="filters"
        reset-form
        :disabled="isLoading"
        date-keep-format
        @startSearch="startSearch"
        @finishSearch="finishSearch"
      >
        <div class="filters-block">
          <div class="display-flex flex-flow-wrap">
            <ui-header-dropdown
              v-model="filters.date"
              :options="dateOptions"
              placeholder="HeaderDatePlaceHolder"
              wide
              :disabled="isLoading"
            />
          </div>
        </div>
        <div slot="right-side">
          <div class="export-btn" @click="exportData">Export</div>
        </div>
      </ui-header>
    </div>
    <div>
      <div v-if="isLoading" class="p-4">
        <ui-loader :size="4" center />
      </div>
      <div v-else>
        <div v-if="identifications.length">
          <dash-board-table :header-type="''">
            <template slot="table-header">
              <th scope="col">{{ $t('IdentificationDashboard.Time') }}</th>
              <th scope="col">
                {{
                  type === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                    ? $t('IdentificationDashboard.Company')
                    : $t('IdentificationDashboard.KycSpecialist')
                }}
              </th>
              <th scope="col" class="text-center">
                {{ $t('IdentificationDashboard.Identification') }}
              </th>
            </template>

            <template slot="table-body">
              <tr
                v-for="(identification, index) in identifications"
                :key="index"
              >
                <td>
                  {{ dateToString(identification.finishDeadlineUtc, true) }}
                </td>
                <td>
                  {{
                    type === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                      ? identification.companyName
                      : getUserName(identification)
                  }}
                </td>
                <td class="text-center">
                  <ui-button
                    class="btn-brand"
                    low
                    small
                    @click="handleClick(identification.identificationId)"
                  >
                    {{ $t('IdentificationDashboard.Open') }}
                  </ui-button>
                </td>
              </tr>
            </template>
          </dash-board-table>
          <ui-page-selector
            :active-page="filters.page"
            :items-count="itemsCount"
            :max-on-page="filters.pageSize"
            :disabled="isLoading"
            @change="pageChanged"
          />
        </div>
        <div v-else>
          <div class="d-flex align-items-center justify-content-center p-4">
            <h4>{{ $t('Kyb.Search.Empty') }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createDateFilters,
  dateToString,
  downloadBlob
} from '@src/scripts/helpers'
import DashBoardTable from '@src/components/pages/identification-dashboard/DashBoardTable'
import Api from '@src/scripts/api'
import {
  IDENTIFICATION_DASHBOARD_TABS,
  IDENTIFICATION_STATUS,
  API
} from '@src/scripts/enums'

const apiSettings = {
  limit: 1000,
  offset: 0
}

export default {
  components: { DashBoardTable },
  props: {
    userId: { type: String, default: null },
    userName: { type: String, default: null },
    type: {
      type: String,
      default: IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
    }
  },
  data: () => ({
    IDENTIFICATION_DASHBOARD_TABS,
    itemsCount: 0,
    searchMethod: API.REALTIME_IDENTIFICATIONS(
      apiSettings.limit,
      apiSettings.offset
    ),
    isLoading: false,
    filters: {
      date: null,
      page: null,
      pageSize: 100,
      userIds: null,
      statuses: null
    },
    dateOptions: null,
    identifications: []
  }),
  created() {
    this.dateOptions = createDateFilters()
    this.filters.date = this.dateOptions[0].key

    if (this.type === IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB) {
      this.filters.userIds = [this.userId]
      this.filters.statuses = [IDENTIFICATION_STATUS.FINISHED]
    } else if (this.type === IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB) {
      this.filters.applicationIds = [this.userId]
      this.filters.statuses = [IDENTIFICATION_STATUS.FINISHED]
    } else if (this.type === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB) {
      this.filters.applicationIds = []
      this.filters.userIds = [this.userId]
      this.filters.statuses = [IDENTIFICATION_STATUS.PENDING]
    }
  },
  methods: {
    dateToString,
    startSearch() {
      this.isLoading = true
    },
    finishSearch(data) {
      this.identifications = data
      this.isLoading = false
    },
    pageChanged(value) {
      this.filters.page = value
    },
    handleClick(id) {
      this.$closePopUp()
      this.$router.push({
        name: 'IdentificationDetails',
        params: { identificationId: id }
      })
    },
    getUserName(indent) {
      if (indent.assignedUserEmail) {
        return indent.assignedUserEmail
      } else if (indent.assignedUserFullName) {
        return indent.assignedUserFullName
      } else {
        return indent.assignedUserId
      }
    },
    async exportData() {
      this.isLoading = true
      try {
        const result = await Api.getBlobWithParams(
          API.REALTIME_EXPORT(apiSettings.limit, apiSettings.offset),
          this.filters
        )
        const { file } = result
        downloadBlob({
          file,
          fileName: `download.csv`
        })
      } catch (error) {
        this.showError()
      }
      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.export-btn {
  cursor: pointer;
  background: var(--white);
  color: var(--brand);
  font-size: 0.9rem;
  padding: 1.05rem;
  width: 7.615rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  text-transform: uppercase;
}
.user-container {
  min-width: 50rem;
  min-height: 30rem;
}
/deep/ .m-subheader-search .m-form {
  margin: 0;
}

/deep/ .m-subheader-search {
  padding: 0.769rem 1.769rem;
}
</style>
