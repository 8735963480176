<template>
  <div class="search-item">
    <strong>{{ label.date }}</strong>
    <span>{{ data.createdUtc | date }}</span>
    <strong>{{ label.legalEntity.name }}</strong>
    <span>{{ data.legalEntity.name }}</span>
    <strong class="center">{{ label.legalEntity.code }}</strong>
    <span class="center">{{ data.legalEntity.code }}</span>
    <strong class="center">{{ label.status }}</strong>
    <span class="center">{{ data.status }}</span>
    <strong class="center">{{ label.count }}</strong>
    <span class="center">
      {{ data.representativeCount }} / {{ data.verifiedRepresentativeCount }}
    </span>
    <div>
      <ui-link
        v-if="!noAction"
        :to="{
          name: 'KybIdentificationsDetails',
          query: { id: data.id }
        }"
        class="btn-brand big-link"
        small-text
      >
        {{ $t('TableView') }}
      </ui-link>
    </div>
  </div>
</template>

<script>
import { dateFilter } from '@src/scripts/helpers'

export default {
  filters: {
    date(value) {
      return dateFilter(value)
    }
  },

  props: {
    data: { type: Object, default: null },
    label: { type: Object, default: null },
    noAction: Boolean
  }
}
</script>

<style scoped>
.search-item {
  display: grid;
  grid-gap: 0.75rem;
  align-items: center;
}

.ui-button {
  width: 100%;
}

@media screen and (max-width: 799px) {
  .search-item {
    grid-template-columns: 0.6fr 1fr 7rem;
  }

  .search-item > strong {
    grid-column: 1;
  }

  .search-item > span {
    grid-column: 2;
  }

  .search-item > div {
    grid-column: 3;
    grid-row: 1 / 6;
  }

  .search-item + .search-item {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
}

@media screen and (min-width: 800px) {
  .search-item {
    grid-template-columns: 0.8fr 1fr 0.6fr 0.5fr 0.3fr 7rem;
  }

  .center {
    text-align: center;
  }

  .search-item > strong {
    margin-bottom: 0.75rem;
  }

  .search-item > span,
  .search-item > div {
    grid-row: 2;
  }

  .search-item + .search-item {
    margin-top: 0.75rem;
    border-top: 1px solid #eee;
  }

  .search-item + .search-item > strong {
    display: none;
  }
}
</style>
