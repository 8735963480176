var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "status-item" }, [
    _c("h5", [_vm._v(_vm._s(_vm.$t(_vm.label)))]),
    _vm._v(" "),
    _c(
      "span",
      {
        staticClass: "m-badge m-badge--wide status-value",
        class: _vm.success ? "m-badge--success" : "m-badge--danger",
      },
      [_vm._v("\n    " + _vm._s(_vm.$t(_vm.value)) + "\n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }