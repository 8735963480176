<template>
  <div class="monitoring-setup">
    <h5>{{ $t('KycMonitoringSetup') }}</h5>
    <div v-if="isLoading">
      <ui-loader center :size="2" />
    </div>
    <div v-else-if="messageShow">
      <div class="message">
        <p v-if="ignoredRegistries && ignoredRegistries.length > 0">
          {{ $t('AppIgnoredRegistries') }}: {{ ignoredRegistries }}
        </p>
        <p v-if="assignedRegistries && assignedRegistries.length > 0">
          {{ $t('AppAssignedRegistries') }}: {{ assignedRegistries }}
        </p>
        <p v-if="errorMessage">
          {{ $t(errorMessage) }}
        </p>
      </div>
      <ui-button class="btn-brand" small @click="cancel">OK</ui-button>
    </div>
    <ui-form v-else-if="!data.monitoringStatus" class="monitoring-setup-form">
      <div>
        <div
          v-for="(registry, key) in selectedRegistries"
          :key="key"
          class="dropdowns-wrapper"
        >
          <ui-dropdown
            :value="registry.registry"
            :title="registriesErrorMessage(registry.registry)"
            :options="registryOptions"
            placeholder="AppSelectRegistry"
            :disabled="isLoading"
            light-border
            has-search
            :error="errors[key].registry"
            @input="changeRegistry($event, key)"
          />
          <ui-dropdown
            v-if="registry.registry"
            :value="registry.interval"
            :options="intervalOptions"
            placeholder="AppInterval"
            :disabled="isLoading"
            light-border
            has-search
            :error="errors[key].interval"
            @input="changeInterval($event, key)"
          />
          <div class="delete-registries">
            <p
              v-if="selectedRegistries.length > 1"
              @click="deleteRegistries(key)"
            >
              {{ $t('AppRemove') }}
            </p>
          </div>
        </div>
      </div>
      <ui-button
        v-if="canAddRegisties"
        class="btn-link margin-bottom-2"
        @click="addAnotherRegistry"
      >
        <span class="text-color-green">
          + {{ $t('AppAddAnotherRegistry') }}
        </span>
      </ui-button>
      <div class="save-button">
        <ui-button type="submit" class="btn-brand" @click="submit">
          {{ $t('AppSave') }}
        </ui-button>
      </div>
    </ui-form>
    <ui-form v-else>
      <p class="message">{{ $t('WantDeleteUserMonitoringList') }}</p>
      <div>
        <ui-button type="submit" small class="btn-danger" @click="deleteUser">
          {{ $t('AppDelete') }}
        </ui-button>
        <ui-button class="btn-success" small @click="cancel">
          {{ $t('AppCancel') }}
        </ui-button>
      </div>
    </ui-form>
  </div>
</template>

<script>
import { mapOptions } from '@src/scripts/helpers'
import { requiredValidator } from '@src/scripts/validators'
import Api from '@src/scripts/api'
import {
  API,
  MONITORING_DOCUMENT_TYPES,
  MONITORING_CHANGE_STATUS
} from '@src/scripts/enums'
import { countriesIso3toIso2 } from '@src/scripts/countriesIso3toIso2'
import UiForm from '../ui/UiForm.vue'
import actions from '@src/store/actions'

export default {
  components: { UiForm },
  props: {
    data: { type: Object, default: null },
    isSingle: { type: Boolean, default: false }
  },

  data() {
    return {
      selectedRegistries: [
        {
          interval: null,
          registry: null
        }
      ],
      errors: [
        {
          interval: 'ErrorFieldRequired',
          registry: 'ErrorFieldRequired'
        }
      ],
      isLoading: true,
      intervalOptions: null,
      registryOptions: null,
      messageShow: false,
      ignoredRegistries: null,
      assignedRegistries: null,
      userData: null,
      registries: this.$store.getters.registries,
      errorMessage: null,
      registriesList: [],
      failedRegistries: {}
    }
  },

  computed: {
    canAddRegisties() {
      return (
        this.registryOptions?.filter((item) => item.disabled).length !==
          this.registryOptions?.length &&
        this.selectedRegistries[this.selectedRegistries.length - 1].interval
      )
    },

    selectedCompany() {
      const { selectedCompany } = this.$store.getters
      return selectedCompany
    }
  },

  watch: {
    async selectedCompany() {
      this.registries = await this.updateRegistriesList()
      if (this.registries) {
        const reg = this.registries.map((item) => item.registry)
        this.registryOptions = mapOptions([...new Set(reg)])
      } else {
        this.registries = null
        this.registryOptions = null
      }
    }
  },

  async created() {
    this.intervalOptions = mapOptions(
      ['Daily', 'Weekly', 'Monthly'],
      ['AppDaily', 'AppWeekly', 'AppMonthly']
    )
    if (!this.data.monitoringStatus) {
      console.log(this.data)
      this.userData = await this.getUserData()
      this.getRegistries()
    }
    this.isLoading = false
  },

  methods: {
    async getRegistries() {
      if (this.registries && this.registries.length > 1) {
        this.registries = this.$store.getters.registries
      } else {
        this.registries = await this.updateRegistriesList()
      }
      const reg = this.filterDocFieldsAndRegistries()
      this.registryOptions = mapOptions([...new Set(reg)])
      this.lockOptions()
    },

    async updateRegistriesList() {
      try {
        const { registries } = await Api.get(API.NATURAL_MONITORNG_REGISTRIES)
        this.$store.dispatch(actions.updateRegistriesList, registries)
        return registries
      } catch (error) {
        //
      }
    },

    filterDocFieldsAndRegistries() {
      this.registries.forEach((item) => {
        if (!this.failedRegistries[item.registry]) {
          Object.assign(this.failedRegistries, { [item.registry]: [] })
        }
        if (!item.country && !item.validationRules) {
          this.registriesList.push(item.registry)
        } else if (
          (!item.country && !this.registriesList.includes(item.registry)) ||
          item.country === this.userData.Country
        ) {
          item.validationRules?.forEach((rul) => {
            if (rul.isRequired && !this.userData[rul.name]) {
              this.failedRegistries[item.registry].push(rul.name)
            }
            if (
              rul.isRequired &&
              rul.requiredValues &&
              !rul.requiredValues.includes(this.userData[rul.name])
            ) {
              this.failedRegistries[item.registry].push(rul.name)
            }
            this.registriesList.push(item.registry)
          })
        }
      })
      if (this.registriesList.length) return this.registriesList
      else {
        this.messageShow = true
        this.errorMessage = 'YouHaveNoRegistriesEnabled'
      }
    },

    registriesErrorMessage(registries) {
      return this.failedRegistries[registries]?.length
    },

    async getUserData() {
      const { identificationId } = this.data
      if (identificationId) {
        try {
          const userData = await Api.get(
            API.IDENTIFICATION_DETAILS(identificationId)
          )
          const {
            documentDataFlaggedNew: {
              personCode,
              firstName,
              lastName,
              dateOfBirth,
              documentNumber,
              dateOfExpiration,
              country
            },
            documentType
          } = userData
          return {
            Country: countriesIso3toIso2[country.value],
            PersonalCode: personCode?.value,
            FirstName: firstName?.value,
            LastName: lastName?.value,
            Birthdate: dateOfBirth?.value,
            DocumentType: MONITORING_DOCUMENT_TYPES[documentType],
            DocumentNumber: documentNumber?.value,
            DocumentExpirationDate: dateOfExpiration?.value
          }
        } catch (error) {
          this.showError()
        }
      }
    },

    cancel() {
      this.$closePopUp()
    },

    async deleteUser() {
      this.$emit('requestStatus', MONITORING_CHANGE_STATUS.LOADING)
      try {
        await Api.del(API.NATURAL_MONITORING_PERSONS, {
          id: this.data.monitoringId
        })
        this.$closePopUp()
        this.$emit('change', false, this.data.index)
        this.$emit('requestStatus', MONITORING_CHANGE_STATUS.SUCCESS)
      } catch (error) {
        this.showError()
        this.$emit('requestStatus', MONITORING_CHANGE_STATUS.ERROR)
      }
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    addAnotherRegistry() {
      this.selectedRegistries.push({ interval: null, registry: null })
      this.errors.push({
        interval: 'ErrorFieldRequired',
        registry: 'ErrorFieldRequired'
      })
    },

    deleteRegistries(index) {
      const { selectedRegistries, errors } = this
      this.$delete(selectedRegistries, index)
      this.$delete(errors, index)
      this.lockOptions()
    },

    validateForm(errors) {
      for (let item in errors) {
        if (errors[item].registry) return false
        if (errors[item].interval) return false
      }
      return true
    },

    async submit() {
      if (!this.validateForm(this.errors)) return
      this.isLoading = true
      this.$emit('requestStatus', 'loading')
      try {
        const {
          PersonalCode,
          FirstName,
          LastName,
          Birthdate,
          DocumentNumber,
          DocumentExpirationDate,
          Country,
          DocumentType
        } = this.userData
        await Api.post(API.NATURAL_MONITORING_PERSONS, {
          identificationId: this.data.identificationId,
          assignedRegistry: this.selectedRegistries,
          Country,
          PersonalCode,
          FirstName,
          LastName,
          Birthdate,
          DocumentType,
          DocumentNumber,
          DocumentExpirationDate
        })

        this.$closePopUp()
        this.$emit('change', true, this.data.index)
        this.$emit('requestStatus', MONITORING_CHANGE_STATUS.SUCCESS)
      } catch (error) {
        this.$emit('requestStatus', MONITORING_CHANGE_STATUS.ERROR)
        this.showError()
      }
      this.isLoading = false
    },

    changeRegistry(value, index) {
      this.errors[index].registry = requiredValidator(value)
      this.$set(this.selectedRegistries[index], 'registry', value)

      this.lockOptions()
    },

    lockOptions() {
      this.registryOptions = this.registryOptions.map((item) => {
        const requeredFields = this.failedRegistries[item.value].map((item) => {
          return this.$t(`Details${item}`)
        })
        return this.failedRegistries[item.value].length
          ? {
              ...item,
              disabled: true,
              title: this.$t('CantChooseRegistry', {
                requeredFields: [...new Set(requeredFields)]
              })
            }
          : this.selectedRegistries.find(
              (selected) => selected.registry === item.value
            )
          ? { ...item, disabled: true }
          : { key: item.value, value: item.value }
      })
    },

    changeInterval(value, index) {
      this.errors[index].interval = requiredValidator(value)
      this.$set(this.selectedRegistries[index], 'interval', value)
    }
  }
}
</script>
<style scoped>
.monitoring-setup {
  min-width: 30rem;
  height: auto;
}

.content > div {
  overflow: hidden;
}

.monitoring-setup-form {
  margin-top: 1rem;
}

.monitoring-setup-form > div > div:not(:last-child) {
  border-bottom: 1px solid var(--light-br-gray);
  margin-block: 1rem;
  margin-bottom: 2rem;
}
.monitoring-setup-form > div > div:only-child {
  border-bottom: 0;
  margin-block: 1rem;
}

.monitoring-setup-form .dropdowns-wrapper > * {
  margin-bottom: 1rem;
}

.text-color-green {
  color: var(--success);
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.monitoring-setup-form .dropdowns-wrapper .delete-registries {
  display: flex;
  justify-content: right;
  margin: 1.25rem 0;
}

.delete-registries p {
  color: var(--danger);
  cursor: pointer;
  margin: 0;
}

.message {
  margin: 2rem 0;
}

.save-button {
  margin-bottom: 1rem;
  text-align: right;
  margin-top: 3rem;
}
</style>
