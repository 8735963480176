<template>
  <div :class="{ disabled }" class="ui-listed-checkbox">
    <input
      v-model="search"
      type="text"
      :placeholder="$t('CompanyProduct.CountryPlaceholder')"
    />
    <div class="ui-listed-checkbox-items">
      <div v-for="item in sortedItems" :key="item.value">
        <ui-new-checkbox
          v-model="values"
          :label="item.label"
          :value="item.value"
          :disabled="disabled"
        />
      </div>
      <p v-if="!sortedItems.length">
        {{ $t('CompanyProduct.CountryNotFound') }}
      </p>
    </div>
    <p>{{ values.length }} {{ $t('CompanyProduct.CountriesSelected') }}</p>
  </div>
</template>

<script>
export default {
  props: {
    listItems: { type: Array, default: null },
    searchLabel: { type: String, default: 'search' },
    disabled: Boolean,
    checkboxValues: { type: Array, default: null }
  },
  data: () => ({
    search: '',
    values: []
  }),
  computed: {
    sortedItems() {
      const searchTerm = this.search.toLowerCase()
      return this.listItems
        .slice(0)
        .filter((state) => state.label.toLowerCase().match(searchTerm))
        .sort(
          (a, b) =>
            a.label.toLowerCase().indexOf(searchTerm) -
            b.label.toLowerCase().indexOf(searchTerm)
        )
    }
  },
  watch: {
    checkboxValues(values) {
      this.values = values
    },
    values(value) {
      this.$emit('valueChange', value)
    }
  },
  created() {
    this.values = this.checkboxValues.slice(0)
  }
}
</script>

<style scoped>
.ui-listed-checkbox {
  width: 20rem;
}

.ui-listed-checkbox.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.ui-listed-checkbox-items {
  border: 1px solid var(--border-color);
  padding: 0.692rem;
  height: 130px;
  overflow: scroll;
}

input {
  border: 1px solid var(--border-color);
  padding: 0.769rem 0.692rem 0.769rem 0.692rem;
  border-radius: 0;
  width: 100%;
  font-size: 1rem;
  cursor: not-allowed;
}

input:focus {
  outline: 0;
}

input::placeholder {
  color: var(--placeholder);
}
</style>
