var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn
    ? _c(
        "div",
        { staticClass: "m-grid m-grid--hor m-grid--root m-page" },
        [
          _c("page-header"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body",
            },
            [
              _c("page-side-bar"),
              _vm._v(" "),
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm.showContent && !_vm.isLoading
                ? _c("router-view", {
                    staticClass: "m-grid__item m-grid__item--fluid m-wrapper",
                  })
                : _c("div", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("DetailsNoRoles")) + "\n    "
                    ),
                  ]),
              _vm._v(" "),
              _c("scroll-arrow"),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFooter ? _c("page-footer") : _vm._e(),
          _vm._v(" "),
          _c("app-notification"),
        ],
        1
      )
    : _c("landing-page")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }