<template>
  <div class="group">
    <ui-loader v-if="loading" center />
    <div v-else-if="error" class="error">
      <b>{{ $t('RegistryHubTransport.loadError') }}</b>
    </div>
    <div v-else>
      <b>{{ $t('RegistryHubTransport.currentVehicles') }}:</b>
      <div v-if="!hasCurrentVehicles" class="group">
        <b>{{ $t('RegistryHubTransport.noCurrentVehicles') }}</b>
      </div>
      <div
        v-for="(vehicle, index) in currentVehicles"
        :key="`vehicle-${index}`"
        class="group"
      >
        <div v-for="(value, key) in vehicle" :key="key">
          <span v-if="$te(`RegistryHubTransport.${key}`)">
            <b>{{ $t(`RegistryHubTransport.${key}`) }}:</b> {{ value }}
          </span>
        </div>
      </div>

      <b>{{ $t('RegistryHubTransport.historyValues') }}:</b>
      <div v-if="!hasHistoryValues">
        <b>{{ $t('RegistryHubTransport.noHistoryValues') }}</b>
      </div>
      <div
        v-for="(vehicle, index) in historyValues"
        :key="`history-${index}`"
        class="group"
      >
        <div v-for="(value, key) in vehicle" :key="key">
          <span v-if="$te(`RegistryHubTransport.${key}`)">
            <b>{{ $t(`RegistryHubTransport.${key}`) }}:</b> {{ value }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { dateToString } from '@src/scripts/helpers'

export default {
  props: {
    companyCode: { type: String, required: true }
  },
  data() {
    return {
      transport: null,
      loading: false,
      error: null
    }
  },
  computed: {
    currentVehicles() {
      return this.transport?.currentVehicles?.map(
        ({ firstRegistrationDate, ...vehicle }) => ({
          ...vehicle,
          firstRegistrationDate: dateToString(firstRegistrationDate)
        })
      )
    },
    hasCurrentVehicles() {
      return !!this.currentVehicles?.length
    },
    historyValues() {
      return this.transport?.historyValues
    },
    hasHistoryValues() {
      return !!this.historyValues?.length
    }
  },
  watch: {
    companyCode: {
      handler() {
        this.getTransportData()
      },
      immediate: true
    }
  },
  methods: {
    async getTransportData() {
      this.loading = true
      this.transport = null
      this.error = null
      try {
        const data = await Api.get(
          `/data-hub/companies/${this.companyCode}/transport`
        )

        this.transport = data?.data?.vehicles
      } catch (error) {
        this.error = error.message
        this.$notify({
          title: 'RegistryHubTransport.loadError',
          message: error.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.group {
  margin: 1rem 0 1rem 1rem;
}
.error {
  color: var(--danger);
}
</style>
