<template>
  <label
    class="m--font-brand m-checkbox--state-brand m-checkbox--margin-right-15 m-checkbox m-checkbox--square ui-checkbox"
    :class="{
      'm-checkbox--disabled': disabled,
      'm-checkbox--header-style': isheader
    }"
  >
    <input
      type="checkbox"
      :class="{ checked: value }"
      :disabled="disabled"
      :value="value"
      @input="valueChanged"
    />
    <div>{{ $t(label) }}</div>
    <span />
  </label>
</template>

<script>
export default {
  name: 'ui-checkbox',

  props: {
    label: { type: String, default: undefined },
    disabled: Boolean,
    value: Boolean,
    isheader: Boolean
  },

  methods: {
    valueChanged(event) {
      if (this.value === event.target.checked)
        event.target.checked = !event.target.checked
      this.$emit('input', event.target.checked)
    }
  }
}
</script>

<style scoped>
.ui-checkbox {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ui-checkbox > * {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.ui-checkbox > span {
  top: 0;
  bottom: 0;
  margin-top: 1px;
}

.ui-checkbox > div {
  padding-top: 2px;
  line-height: 18px;
}

.m-checkbox--margin-right-15 {
  margin-right: 15px;
}

.ui-checkbox > input:checked ~ span::after {
  display: none;
}

.ui-checkbox > input.checked ~ span::after {
  display: block;
}

.m-checkbox.m-checkbox--state-brand.m-checkbox--header-style > input ~ span {
  background: transparent;
}

.m-checkbox.m-checkbox--state-brand.m-checkbox--header-style
  > input:checked
  ~ span {
  border-color: #fff;
}
</style>
