<template>
  <div class="user-settings-page">
    <ui-form :key="submitCount" @submit="saveChanges">
      <box-with-header>
        <div slot="header" class="header-box">
          <div class="tools-buttons">
            <edit-header
              :edit-enabled="details.editEnabled"
              @edit="enableDetailsEdit"
            />
            <ui-button
              v-if="details.editEnabled"
              small
              class="btn btn-metal m--margin-left-10"
              @click="clickCancel"
            >
              <i class="la la-close" />
              <span> {{ $t('AppCancel') }} </span>
            </ui-button>
          </div>
        </div>
        <ui-header title="UserSettings" />
        <ui-container>
          <template v-if="!isLoading">
            <ui-card large multicolumn>
              <ui-widget slot="body-left" title="User">
                <details-item
                  label="DetailsFirstName"
                  :placeholder="placeholder.firstName"
                  :editable="details.editEnabled"
                  large
                  :error="user.errors.firstName"
                  :value="userDetails.firstName"
                  :max-length="50"
                  @change="firstNameChanged"
                />
                <details-item
                  label="DetailsLastName"
                  :placeholder="placeholder.lastName"
                  :editable="details.editEnabled"
                  large
                  :error="user.errors.lastName"
                  :value="userDetails.lastName"
                  :max-length="50"
                  @change="lastNameChanged"
                />
                <details-item
                  label="DetailsEmail"
                  :placeholder="placeholder.email"
                  :max-length="255"
                  :editable="details.editEnabled"
                  large
                  :error="user.errors.email"
                  :value="userDetails.email"
                  @change="emailChanged"
                />
              </ui-widget>
              <ui-widget
                v-if="details.editEnabled"
                slot="body-right"
                top-spacing
              >
                <details-item
                  type="password"
                  label="DetailsNewPassword"
                  :min-length="8"
                  :max-length="50"
                  :placeholder="placeholder.password"
                  :editable="details.editEnabled"
                  large
                  :error="user.errors.password"
                  :value="userDetails.password"
                  @change="passwordChanged"
                />
                <details-item
                  type="password"
                  label="DetailsRepeatNewPassword"
                  :min-length="8"
                  :max-length="50"
                  :placeholder="placeholder.repeatPassword"
                  :editable="details.editEnabled"
                  large
                  :error="user.errors.repeatPassword"
                  :value="userDetails.repeatPassword"
                  @change="repeatPasswordChanged"
                />
              </ui-widget>
            </ui-card>
            <ui-card large multicolumn>
              <ui-widget slot="body-left" title="Company information">
                <details-item
                  label="DetailsCompanyName"
                  :placeholder="placeholder.companyName"
                  :max-length="50"
                  :editable="details.editEnabled"
                  large
                  :error="company.errors.companyName"
                  :value="companyDetails.companyName"
                  @change="companyNameChanged"
                />
                <details-item
                  label="DetailsAdress"
                  :placeholder="placeholder.adressCity"
                  :max-length="250"
                  :editable="details.editEnabled"
                  large
                  :error="company.errors.adressCity"
                  :value="companyDetails.adressCity"
                  @change="companyAdressCityChanged"
                />
                <details-item
                  :placeholder="placeholder.adressStreet"
                  :max-length="250"
                  :editable="details.editEnabled"
                  large
                  :error="company.errors.adressStreet"
                  :value="companyDetails.adressStreet"
                  @change="companyAdressStreetChanged"
                />
                <details-item
                  label="DetailsZipCode"
                  :placeholder="placeholder.zip"
                  :max-length="20"
                  :editable="details.editEnabled"
                  large
                  :error="company.errors.zip"
                  :value="companyDetails.zip"
                  @change="companyZipChanged"
                />
                <details-item
                  label="DetailsCountry"
                  :placeholder="placeholder.country"
                  :options="countries"
                  :editable="details.editEnabled"
                  :value="companyDetails.country"
                  large
                  @change="selectCountry"
                />
              </ui-widget>
              <ui-widget
                slot="body-right"
                title="Billing address"
                class="padding"
              >
                <details-item
                  label="DetailsCompanyName"
                  :placeholder="placeholder.companyName"
                  :max-length="50"
                  :editable="details.editEnabled"
                  large
                  :error="billingCompany.errors.companyName"
                  :value="billingCompanyDetails.companyName"
                  @change="billingCompanyNameChanged"
                />
                <details-item
                  label="DetailsAdress"
                  :placeholder="placeholder.adressCity"
                  :max-length="250"
                  :editable="details.editEnabled"
                  large
                  :error="billingCompany.errors.adressCity"
                  :value="billingCompanyDetails.adressCity"
                  @change="billingCompanyAdressCityChanged"
                />
                <details-item
                  :placeholder="placeholder.adressStreet"
                  :max-length="250"
                  :editable="details.editEnabled"
                  large
                  :error="billingCompany.errors.adressStreet"
                  :value="billingCompanyDetails.adressStreet"
                  @change="billingCompanyAdressStreetChanged"
                />
                <details-item
                  label="DetailsZipCode"
                  :placeholder="placeholder.zip"
                  :max-length="20"
                  :editable="details.editEnabled"
                  large
                  :error="billingCompany.errors.zip"
                  :value="billingCompanyDetails.zip"
                  @change="billingCompanyZipChanged"
                />
                <details-item
                  label="DetailsCountry"
                  :placeholder="placeholder.country"
                  :options="countries"
                  :editable="details.editEnabled"
                  :value="billingCompanyDetails.country"
                  large
                  @change="billingSelectCountry"
                />
              </ui-widget>
            </ui-card>
          </template>
          <ui-loader v-else :size="4" center />
        </ui-container>
      </box-with-header>
    </ui-form>
  </div>
</template>

<script>
import DetailsItem from '@src/components/partials/DetailsItem'
import EditHeader from '@src/components/partials/EditHeader'
import BoxWithHeader from '@src/components/partials/BoxWithHeader'
import {
  requiredValidator,
  minLengthValidator,
  emailValidator,
  passwordValidator,
  validateForm
} from '@src/scripts/validators'

const placeholderDefaults = () => ({
  firstName: 'DetailsFirstName',
  lastName: 'DetailsLastName',
  email: 'DetailsEmail',
  password: 'DetailsNewPassword',
  repeatPassword: 'DetailsRepeatNewPassword',
  companyName: 'DetailsCompanyName',
  adressCity: 'DetailsCity',
  adressStreet: 'DetailsStreetAdress',
  zip: 'DetailsZipCode',
  country: 'Select'
})

const userDefaults = () => ({
  errors: {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    position: null,
    password: null,
    repeatPassword: null
  },
  details: null,
  defaults: null
})

const companyDefaults = () => ({
  errors: {
    companyName: null,
    adressCity: null,
    adressStreet: null,
    zip: null,
    country: null
  },
  details: null,
  defaults: null
})

const billingDefaults = () => ({
  errors: {
    companyName: null,
    adressCity: null,
    adressStreet: null,
    zip: null,
    country: null
  },
  details: null,
  defaults: null
})

export default {
  components: {
    DetailsItem,
    BoxWithHeader,
    EditHeader
  },

  data() {
    return {
      isLoading: false,
      details: {
        editEnabled: false
      },
      submitCount: 0,
      placeholder: placeholderDefaults(),
      user: userDefaults(),
      company: companyDefaults(),
      billingCompany: billingDefaults(),
      revision: null,
      countries: [
        {
          key: 'Afghanistan',
          value: 'Afghanistan'
        },
        {
          key: 'Lithuania',
          value: 'Lithuania'
        }
      ]
    }
  },

  computed: {
    userDetails() {
      const { details } = this.user
      if (!details) return
      return details
    },

    companyDetails() {
      const { details } = this.company
      if (!details) return
      return details
    },

    billingCompanyDetails() {
      const { details } = this.billingCompany
      if (!details) return
      return details
    }
  },

  created() {
    const {
      defaultValidations,
      getUserDetails,
      getCompanyDetails,
      getBillingCompanyDetails
    } = this
    this.isLoading = true
    this.user.details = getUserDetails()
    this.user.default = getUserDetails()
    this.company.details = getCompanyDetails()
    this.company.default = getCompanyDetails()
    this.billingCompany.details = getBillingCompanyDetails()
    this.billingCompany.default = getBillingCompanyDetails()
    const data = {
      user: this.user.details,
      company: this.company.details,
      billingCompany: this.billingCompany.details
    }
    if (this.user.details) {
      defaultValidations(data)
    }
    this.isLoading = false
  },

  methods: {
    saveChanges() {
      const { user, company, billingCompany } = this
      if (!this.details.editEnabled) {
        const data = {
          user: this.user.details,
          company: this.company.details,
          billingCompany: this.billingCompany.details
        }
        if (this.user.details) {
          this.defaultValidations(data)
        }
        this.submitCount++
      } else if (
        !validateForm(user.errors) ||
        !validateForm(company.errors) ||
        !validateForm(billingCompany.errors)
      )
        return
      this.isLoading = true
      user.default = Object.assign({}, this.user.details)
      company.default = Object.assign({}, this.company.details)
      billingCompany.default = Object.assign({}, this.billingCompany.details)
      this.details.editEnabled = false
      this.submitCount++
      this.isLoading = false
    },

    clickCancel() {
      this.user.details = Object.assign({}, this.user.default)
      this.company.details = Object.assign({}, this.company.default)
      this.billingCompany.details = Object.assign(
        {},
        this.billingCompany.default
      )
      this.details.editEnabled = false
    },

    getUserDetails() {
      return {
        userId: 'c93ba883-bff2-429f-8a0a-a6577146ed33',
        firstName: 'Jekaterina',
        lastName: 'Mys',
        email: 'jekaterina@ondato.com'
      }
    },
    getCompanyDetails() {
      return {
        companyName: 'Ondato',
        adressCity: 'Vilnius',
        adressStreet: null,
        zip: '1234',
        country: 'Lithuania'
      }
    },

    getBillingCompanyDetails() {
      return {
        companyName: 'ffg',
        adressCity: null,
        adressStreet: null,
        zip: null,
        country: null
      }
    },

    enableDetailsEdit() {
      this.details.editEnabled = true
    },

    firstNameChanged(value, freeze) {
      const {
        user: { errors, details }
      } = this
      errors.firstName = requiredValidator(value)
      if (!errors.firstName) errors.firstName = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'firstName', value)
    },

    lastNameChanged(value, freeze) {
      const {
        user: { errors, details }
      } = this
      errors.lastName = requiredValidator(value)
      if (!errors.lastName) errors.lastName = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'lastName', value)
    },

    emailChanged(value, freeze) {
      const {
        user: { errors, details }
      } = this
      errors.email = requiredValidator(value)
      if (!errors.email) errors.email = emailValidator(value)
      if (freeze) return
      this.$set(details, 'email', value)
    },

    passwordChanged(value) {
      const {
        user: { errors, details }
      } = this
      errors.password = minLengthValidator(value, 8)
      if (!errors.password) errors.password = passwordValidator(value)
      if (!errors.repeatPassword && details.repeatPassword !== value)
        errors.repeatPassword = 'ErrorCheckPassword'
      this.$set(details, 'password', value)
    },

    repeatPasswordChanged(value) {
      const {
        user: { errors, details }
      } = this
      errors.repeatPassword = minLengthValidator(value, 8)
      if (!errors.repeatPassword)
        errors.repeatPassword = passwordValidator(value)
      if (!errors.repeatPassword && details.password !== value)
        errors.repeatPassword = 'ErrorCheckPassword'
      this.$set(details, 'repeatPassword', value)
    },

    companyNameChanged(value, freeze) {
      const {
        company: { errors, details }
      } = this
      errors.companyName = requiredValidator(value)
      if (!errors.companyName) errors.companyName = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'companyName', value)
    },

    companyAdressCityChanged(value, freeze) {
      const {
        company: { errors, details }
      } = this
      errors.adressCity = requiredValidator(value)
      if (!errors.adressCity) errors.adressCity = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'adressCity', value)
    },

    companyAdressStreetChanged(value, freeze) {
      const {
        company: { errors, details }
      } = this
      errors.adressStreet = requiredValidator(value)
      if (!errors.adressStreet) errors.adressStreet = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'adressStreet', value)
    },

    companyZipChanged(value, freeze) {
      const {
        company: { errors, details }
      } = this
      errors.zip = requiredValidator(value)
      if (!errors.zip) errors.zip = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'zip', value)
    },

    selectCountry(value) {
      this.company.details.country = value
    },

    billingCompanyNameChanged(value, freeze) {
      const {
        billingCompany: { errors, details }
      } = this
      errors.companyName = requiredValidator(value)
      if (!errors.companyName) errors.companyName = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'CompanyName', value)
    },

    billingCompanyAdressCityChanged(value, freeze) {
      const {
        billingCompany: { errors, details }
      } = this
      errors.adressCity = requiredValidator(value)
      if (!errors.adressCity) errors.adressCity = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'adressCity', value)
    },

    billingCompanyAdressStreetChanged(value, freeze) {
      const {
        billingCompany: { errors, details }
      } = this
      errors.adressStreet = requiredValidator(value)
      if (!errors.adressStreet) errors.adressStreet = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'adressStreet', value)
    },

    billingCompanyZipChanged(value, freeze) {
      const {
        billingCompany: { errors, details }
      } = this
      errors.zip = requiredValidator(value)
      if (!errors.zip) errors.zip = minLengthValidator(value)
      if (freeze) return
      this.$set(details, 'zip', value)
    },

    billingSelectCountry(value) {
      this.billingCompany.details.country = value
    },

    defaultValidations(data) {
      this.firstNameChanged(data.user.firstName, true)
      this.lastNameChanged(data.user.lastName, true)
      this.emailChanged(data.user.email, true)
      this.companyNameChanged(data.company.companyName, true)
      this.companyAdressCityChanged(data.company.adressCity, true)
      this.companyAdressStreetChanged(data.company.adressStreet, true)
      this.companyZipChanged(data.company.zip, true)
      this.billingCompanyNameChanged(data.billingCompany.companyName, true)
      this.billingCompanyAdressCityChanged(data.billingCompany.adressCity, true)
      this.billingCompanyAdressStreetChanged(
        data.billingCompany.adressStreet,
        true
      )
      this.billingCompanyZipChanged(data.billingCompany.zip, true)
    }
  }
}
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid #ebedf2;
}

.tools-buttons {
  display: flex;
  flex-wrap: wrap;
}
</style>

<style>
.user-settings-page .details-item {
  margin: 1.15rem 0;
}
</style>
