<template>
  <div class="monitoring-list-page">
    <ui-header
      title="App.SubMenu.MonitoringList"
      :method="searchMethod"
      :filters="filters"
      :update-revision="updateRevision"
      :class="{ 'fixed-header': scrollDown }"
      reset-form
      method-get
      no-extra-payload
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div class="filters-block">
        <div class="display-flex flex-flow-wrap">
          <ui-header-dropdown
            v-model="filters.date"
            :options="options.dateOptions"
            placeholder="HeaderDatePlaceHolder"
            wide
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="fullName"
            type="text"
            placeholder="TableFullName"
            medium
            :disabled="isLoading"
            @input="handleNameFilter"
          />
          <ui-header-input
            v-model="filters.personalCode"
            type="text"
            placeholder="DetailsPersonCode"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="filters.country"
            all
            has-search
            class="countries-dropdown"
            key-value-dropdown
            :options="countriesOptions"
            medium
            placeholder="DetailsCountry"
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="filters.documentType"
            all
            :options="documentTypes"
            medium
            placeholder="DetailsDocumentType"
            :disabled="isLoading"
          />

          <ui-header-input
            v-model="filters.documentNumber"
            type="text"
            placeholder="DetailsDocumentNumber"
            medium
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.identificationId"
            type="text"
            placeholder="DetailsIdentificationId"
            medium
            :disabled="isLoading"
          />
        </div>
      </div>
    </ui-header>
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <template v-if="!isLoading && identifications">
          <ui-table
            :labels="listLabels"
            :data="identifications"
            class="export-table hide-mobile"
            empty-text="TableNoIdentifications"
          >
            <template>
              <tr
                v-for="(row, index) in identifications"
                :key="index"
                class="m-table__row"
              >
                <td
                  :title="row.referenceId"
                  class="cell-identification-id-desktop"
                >
                  <span
                    class="identification-id-desktop"
                    @click="copyIdentificationId(row.referenceId)"
                  >
                    {{ row.referenceId ? row.referenceId : '-' }}
                  </span>
                </td>
                <td>{{ row.addedAt | date(true) }}</td>
                <td :title="getFullName(row)">
                  {{ getFullName(row) }}
                </td>
                <td class="fixed-width" :title="row.personalCode">
                  {{ row.personalCode }}
                </td>
                <td class="fixed-width" :title="row.country">
                  {{ row.country }}
                </td>
                <td class="fixed-width" :title="row.documentType">
                  {{ $t(row.documentType) }}
                </td>
                <td class="fixed-width" :title="row.documentNumber">
                  {{ row.documentNumber }}
                </td>
                <td>
                  <ui-toggle
                    prevent
                    :value="true"
                    @change="changeMonitoring(row, index)"
                  />
                </td>
                <td>
                  <ui-link
                    v-if="row.referenceId"
                    :to="{
                      name: 'IdentificationDetails',
                      params: {
                        identificationId: row.referenceId.replaceAll('-', '')
                      }
                    }"
                    class="btn-brand"
                    small-text
                  >
                    {{ $t('TableView') }}
                  </ui-link>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="identifications && identifications.length > 0">
            <div
              v-for="(row, index) in identifications"
              :key="index"
              class="response-table show-mobile"
            >
              <responsive-table-tr
                title="TableIdentificationId"
                :value="row.identificationId"
              />
              <responsive-table-tr
                title="TableDate"
                :value="row.addedAt | date(true)"
              />
              <responsive-table-tr
                title="TableFullName"
                :value="getFullName(row)"
              />
              <responsive-table-tr
                title="DetailsPersonCode"
                :value="row.personalCode"
              />
              <responsive-table-tr title="DetailsCountry">
                {{ row.country }}
              </responsive-table-tr>
              <responsive-table-tr
                title="DetailsDocumentType"
                :value="row.documentType"
              />
              <responsive-table-tr
                title="DetailsDocumentNumber"
                :value="row.documentNumber"
              />

              <responsive-table-tr title="Monitoring">
                <ui-toggle
                  prevent
                  :value="true"
                  @change="changeMonitoring(row, index)"
                />
              </responsive-table-tr>
              <div class="m--margin-top-10 margin-1">
                <ui-link
                  v-if="row.referenceId"
                  :to="{
                    name: 'IdentificationDetails',
                    params: {
                      identificationId: row.referenceId.replaceAll('-', '')
                    }
                  }"
                  class="btn-brand big-link"
                  small-text
                >
                  {{ $t('TableView') }}
                </ui-link>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('TableNoIdentifications') }}
          </div>
        </template>
        <ui-loader v-if="isLoading" :size="4" center />
        <ui-page-selector
          :active-page="filters.page"
          :items-count="itemsCount"
          :max-on-page="filters.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
      <pop-up full-size>
        <monitoring-setup
          :data="monitoringData"
          @change="changeMonitoringStatus"
        />
      </pop-up>
    </ui-container>
  </div>
</template>

<script>
import {
  createDateFilters,
  dateToString,
  mapOptions
} from '@src/scripts/helpers'
import MonitoringSetup from '@src/components/partials/MonitoringSetup'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import { notificationType } from '@src/components/notification'
import { copy } from '@src/scripts/clipboard'
import { countriesIso3toIso2 } from '@src/scripts/countriesIso3toIso2'
import {
  MONITORING_DOCUMENT_TYPES,
  MONITORING_STATUS,
  API
} from '@src/scripts/enums'

const filters = () => ({
  date: null,
  page: null,
  pageSize: 100,
  fullname: null,
  personalCode: null,
  country: null,
  documentType: null,
  identificationId: null,
  documentNumber: null,
  monitoring: null
})

const options = () => ({
  dateOptions: null
})

export default {
  components: {
    ResponsiveTableTr,
    MonitoringSetup
  },

  filters: {
    date: dateToString
  },

  data() {
    return {
      scrollDown: false,
      lastScrollTop: 180,
      isReady: false,
      isLoading: true,
      fullName: '',
      identifications: null,
      itemsCount: 0,
      searchMethod: API.NATURAL_MONITORING_PERSONS,
      filters: filters(),
      options: options(),
      tableLabels: [
        'TableIdentificationId',
        'TableDate',
        'TableFullName',
        'DetailsPersonCode',
        'DetailsCountry',
        'DetailsDocumentType',
        'DetailsDocumentNumber',
        'Monitoring'
      ],
      monitoringData: {
        identificationId: null,
        monitoringStatus: null,
        index: null
      },
      updateRevision: false
    }
  },

  computed: {
    countriesOptions() {
      return mapOptions(this.nationalities)
    },

    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities.map((item) => countriesIso3toIso2[item])
    },

    documentTypes() {
      const { documentTypes } = this.$store.getters
      return mapOptions([
        ...documentTypes
          .filter((item) => item !== 'INTERNAL_PASSPORT')
          .map((item) => MONITORING_DOCUMENT_TYPES[item])
      ])
    },

    listLabels() {
      const list = [...this.tableLabels]
      list.push(null)
      return list
    }
  },

  async created() {
    const { createFilters } = this
    await createFilters()
    this.isReady = true
  },

  methods: {
    copyIdentificationId(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },

    changeMonitoring(row, index) {
      const { monitoringData } = this
      this.$set(monitoringData, 'monitoringStatus', true)
      this.$set(monitoringData, 'index', index)
      this.$set(monitoringData, 'identificationId', row.referenceId)
      this.$set(monitoringData, 'monitoringId', row.id)
      this.$openPopUp()
    },

    getMonitoringStatus(status) {
      return status === MONITORING_STATUS.IS_MONITORED
    },

    changeMonitoringStatus() {
      this.updateRevision = true
    },

    createFilters() {
      const { filters, options } = this
      options.dateOptions = createDateFilters()
      if (options.dateOptions.length) {
        filters.date = options.dateOptions[0].key
      }
    },

    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      const { items, total } = data
      this.identifications = items
      this.count = null
      this.itemsCount = total
      this.isLoading = false
      this.updateRevision = false
    },

    getFullName(item) {
      const { firstName, lastName } = item
      return [firstName, lastName].filter((value) => value).join(' ')
    },

    handleNameFilter(input) {
      this.filters.fullname = input
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.margin-right-3 {
  margin-right: 3rem;
}

.margin-1 {
  margin: 0 1rem;
}

.hidden-button {
  display: none;
}

.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.cell-identification-id-desktop {
  max-width: 12vw;
}

.identification-id-desktop {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-header {
  margin-bottom: 4rem;
}

.flex-flow-wrap {
  flex-flow: row wrap;
}

.all-search-inputs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
}

.all-search-inputs > * {
  display: inline-block;
  width: 200px;
}

.td-link {
  width: 18rem;
}

.td-link > * {
  margin-bottom: 0.5rem;
  min-width: 6rem;
}

.td-link > *:first-child {
  margin-right: 1rem;
}

.fixed-header .flex-flow-wrap {
  flex-flow: row nowrap;
}

.fixed-header .flex-flow-wrap > * {
  width: 210px;
}

.fullsearh-link {
  color: var(--white);
  margin-right: 15px;
  cursor: pointer;
}

.fullsearh-link:hover {
  text-decoration: underline;
}

.filter-small-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.fixed-header .filter-small-block {
  position: absolute;
  bottom: -2.75rem;
  left: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.fixed-header .fullsearh-link {
  left: 2rem;
  right: auto;
  color: var(--dark-br);
  bottom: 0.75rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

.fixed-header .all-search-inputs > *::placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-webkit-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-moz-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-ms-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-moz-placeholder {
  color: var(--dark-br);
}

.filters-block {
  width: 100%;
}

.dropdown-box > .dropdown-toggle.btn-light:hover::placeholder {
  color: #282a3c;
}

@media all and (max-width: 992px) {
  .flex-flow-wrap .filter-small-block {
    display: block;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
  }

  .filter-small-block > * {
    display: inline-block;
  }

  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }

  .all-search-inputs {
    flex-flow: row wrap;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    max-width: 45%;
    width: 45%;
  }
}

@media (max-width: 678px) {
  .all-search-inputs {
    flex-direction: column;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
<style>
.monitoring-list-page .filter-small-block .m-checkbox--state-brand {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
  width: 400px;
}

.header-content .filters-block .m-input-icon.m-input-icon--wide {
  width: 200px;
}

.fixed-header .m-subheader-search__title,
.fixed-header .ui-toggle--header {
  visibility: hidden;
}

.fixed-header .header-content {
  position: fixed;
  top: 3.75rem;
  z-index: 50;
  left: 110px;
  background: var(--white);
  text-align: right;
  flex-wrap: nowrap;
  width: calc(100% - 110px);
  padding: 1.75rem 80px 2rem 30px;
  height: auto;
  box-shadow: 2px 3px 3px 0 var(--light-br-h-gray);
}

.fixed-header .header-content .filters-list .flex-flow-wrap > * {
  width: 200px;
}

.fixed-header .header-content .filters-block {
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}

.fixed-header .header-content .filters-list {
  flex-wrap: nowrap;
}

.fixed-header .header-content .filters-list label {
  display: none;
}

.fixed-header .header-content .filters-list .ui-dropdown button,
.fixed-header .header-content .filters-list input {
  border-color: var(--dark-br);
  color: var(--dark-br);
  height: 42px;
}

.fixed-header .header-content .filters-list textarea {
  border-color: var(--dark-br);
  color: var(--dark-br);
}

.fixed-header .header-content .filters-list input::placeholder,
.fixed-header .header-content .filters-list textarea::placeholder {
  color: var(--dark-br);
}

.fixed-header .ui-button.m-subheader-search__submit-btn {
  border: 1px solid var(--brand);
  height: 42px;
}

@media (max-width: 1024px) {
  .fixed-header .header-content {
    visibility: hidden;
  }
}

@media (max-width: 992px) {
  .all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .monitoring-list-page .m-subheader-search .m-input-icon {
    margin-right: 0;
  }
}
</style>
