<template>
  <div class="content">
    <ui-header
      title="CompanyReportSearchTitle"
      :method="searchMethod"
      method-get
      reset-form
      no-extra-payload
      :filters="filters"
      :disabled="isLoading"
      :update-revision="needUpdate"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.CompanyName"
        class="company-name"
        type="text"
        placeholder="DetailsCompanyName"
        medium
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="countryCode"
        placeholder="DetailsCountries"
        :options="countries"
        medium
        has-search
        :disabled="isLoading"
        @input="handleCountrySelect"
      />
      <ui-header-dropdown
        v-model="filters.date"
        :options="companyReportDateOptions"
        placeholder="CompanyReportStartDate"
        wide
        :disabled="isLoading"
      />
    </ui-header>

    <company-reports-table
      :list-items="orderList"
      :loading="isLoading"
      :page="filters.Page"
      :page-size="filters.pageSize"
      :total="pageSelectorData.total"
      :page-changed="pageChanged"
      @listReloading="handleUpdate"
    />
  </div>
</template>

<script>
import {
  createCompanyReportDateOptions,
  mapOptions
} from '@src/scripts/helpers'
import CompanyReportsTable from '@src/components/pages/kyb/CompanyReportsTable'
import { countriesIso3toIso2 } from '@src/scripts/countriesIso3toIso2'

export default {
  components: { CompanyReportsTable },

  data() {
    return {
      searchMethod: '/company-report-orders',
      countryCode: '',
      filters: {
        date: null,
        pageSize: 100,
        Page: 1,
        CountryCode: '',
        CompanyName: ''
      },
      pageSelectorData: {
        count: 0
      },
      needUpdate: false,
      orderList: [],
      companyReportDateOptions: createCompanyReportDateOptions(),
      isLoading: false
    }
  },
  computed: {
    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities.map((item) => item).sort()
    },

    countries() {
      return mapOptions(this.nationalities)
    }
  },
  methods: {
    handleUpdate() {
      this.needUpdate = true
    },
    handleCountrySelect(values) {
      this.filters.CountryCode = countriesIso3toIso2[values]
    },
    startSearch() {
      this.isLoading = true
    },

    finishSearch(response) {
      this.isLoading = false
      this.pageSelectorData.total = response.totalCount
      this.orderList = response.orders ?? []
      this.needUpdate = false
    },
    pageChanged(value) {
      this.filters.Page = value ?? 1
      this.needUpdate = true
    }
  }
}
</script>

<style scoped>
/deep/ .m-input-icon.m-input-icon--right .form-control:hover::placeholder {
  color: #575962;
}
</style>
