<template>
  <div v-if="hasRules" class="content-box">
    <h5>{{ $t(title) }}</h5>
    <ui-button
      v-if="addButton"
      class="btn btn-danger"
      :disabled="isLoading"
      small
      low
      small-text
      @click="clickReprocess"
    >
      {{ $t('Reprocess') }}
      <span v-if="isLoading"> ({{ `${time} ${$t('Second')}` }}) </span>
    </ui-button>
    <rule-item
      v-for="(item, index) in data"
      :key="index"
      :name="item.name"
      :no-status="noStatus"
      :status="item.status"
      :reason="item.failReason"
      :reasons="item.failReasons"
      :action="item.action"
      :type="type"
    />
  </div>
</template>

<script>
import RuleItem from '@src/components/partials/RuleItem'

export default {
  components: {
    RuleItem
  },

  props: {
    title: { type: String, default: undefined },
    data: { type: Array, default: undefined },
    addButton: Boolean,
    noStatus: Boolean,
    type: { type: String, default: undefined }
  },

  data() {
    return {
      time: 60,
      isLoading: false
    }
  },

  computed: {
    hasRules() {
      return !!(this.data && this.data.length)
    }
  },

  methods: {
    clickReprocess() {
      this.isLoading = true
      this.$emit('reprocess')
      const timeLeft = setInterval(() => {
        this.time--
        if (this.time === 0) {
          clearInterval(timeLeft)
          this.isLoading = false
          this.time = 60
        }
      }, 1000)
    }
  }
}
</script>

<style scoped>
.content-box {
  position: relative;
}

button {
  position: absolute;
  top: 1.25rem;
  right: 2.25rem;
}

@media (max-width: 450px) {
  button {
    position: relative;
    top: 0;
    right: 0;
    margin-bottom: 0.5rem;
  }
}
</style>
