<template>
  <page-frame>
    <header-tabs
      slot="header"
      v-model="selectedTab"
      :labels="labels"
      :loading="!isReady || isLoading"
    />
    <ui-card>
      <div>
        <ui-loader v-if="!isReady" center />
        <company
          v-else-if="selectedTab === 0"
          :data="companyDetails"
          :loading="isLoading"
          @update="loadCompany"
        />
        <representative
          v-else
          :key="selectedRepresentative.id"
          :data="selectedRepresentative"
          :loading="isLoading"
          @update="updateRepresentatives"
        />
      </div>
    </ui-card>
  </page-frame>
</template>

<script>
import HeaderTabs from '@src/components/partials/refactor/layouts/HeaderTabs'
import PageFrame from '@src/components/partials/refactor/layouts/PageFrame'
import Company from '@src/components/partials/refactor/partials/kyb/Company'
import Representative from '@src/components/partials/refactor/partials/kyb/Representative'
import Api from '@src/scripts/api'
import { sleep } from '@src/scripts/helpers'
import { representativeStatuses } from '@src/scripts/enums'

export default {
  components: {
    HeaderTabs,
    PageFrame,
    Company,
    Representative
  },

  data() {
    return {
      isReady: false,
      isLoading: true,
      selectedTab: null,
      companyDetails: null,
      representatives: null,
      representativeIds: null
    }
  },

  computed: {
    labels() {
      const { isReady, representatives } = this
      if (!isReady) return []

      const labels = []
      if (representatives?.length) {
        representatives.forEach((item) => {
          labels.push({
            label: item.registration?.fullName,
            status:
              item.kycIdentificationStatus ===
                representativeStatuses.approved || item.isExternallyVerified
          })
        })
      }

      return [{ label: this.$t('Kyb.Company.TabName') }, ...labels]
    },

    selectedRepresentative() {
      const { selectedTab, representatives } = this
      return representatives?.[selectedTab - 1] ?? null
    }
  },

  async created() {
    await this.loadCompany()
    await this.loadRepresentatives()
  },

  beforeDestroy() {
    this.isReady = false
  },

  methods: {
    updateRepresentatives() {
      this.loadRepresentatives()
    },

    async loadCompany() {
      const { id } = this.$route.query
      if (!id) return false

      try {
        this.isLoading = true

        const result = await Api.get(`/kybidentifications/${id}`)
        if (!result) return false

        const { representatives, ...data } = result
        this.companyDetails = data
        this.representativeIds = representatives

        this.isReady = true
        this.isLoading = false

        return true
      } catch (error) {
        this.notifyError()
        this.isLoading = false

        return false
      }
    },

    async loadRepresentatives(retryDelay = 1000) {
      const { representativeIds } = this
      if (!representativeIds || !this.isReady) return
      this.isLoading = true

      try {
        const { representatives } = await Api.post(
          '/kybrepresentatives/search',
          {
            ids: representativeIds
          }
        )

        if (!representatives) this.notifyError()
        else {
          if (representatives?.length > 1) {
            representatives.sort(
              (
                { registration: { fullName: n1 } },
                { registration: { fullName: n2 } }
              ) => n1.localeCompare(n2)
            )
          }
          this.representatives = representatives
        }
      } catch (error) {
        await sleep(retryDelay)
        await this.loadRepresentatives(retryDelay * 2)
      }

      this.isLoading = false
    },

    notifyError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>
