var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.questions, function (question, index) {
      return _c("div", { key: index, staticClass: "row" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _c("div", {}, [
          _c("h5", [_vm._v(_vm._s(question.question))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(question.answer))]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-big" }, [
      _c("i", { staticClass: "flaticon-visible" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }