<template>
  <div class="m-content" :class="{ 'fixed-height': fixed }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ui-container',

  props: {
    fixed: Boolean
  }
}
</script>

<style scoped>
.fixed-height > * {
  height: 100%;
}

.fixed-height .m-content {
  position: relative;
}
</style>
