var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "ui-button btn m-btn",
      class: {
        "m-btn--icon": _vm.icon,
        "m-btn--wide m-btn--md": !_vm.small,
        "btn-sm": _vm.smallText,
      },
      attrs: { to: _vm.to, target: _vm.target },
    },
    [
      _c("span", [
        _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
        _vm._v(" "),
        _vm.hasContent ? _c("span", [_vm._t("default")], 2) : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }