var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.poaData
    ? _c(
        "ui-form",
        { staticClass: "poa-data", on: { submit: _vm.submit } },
        [
          _c(
            "ui-card",
            { attrs: { title: "POATitle", icon: "flaticon-interface-7" } },
            [
              _c(
                "details-box",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _vm._l(_vm.poaData, function (item, key) {
                    return [
                      _c("details-item", {
                        key: key,
                        staticClass: "ui-input-touched",
                        class: _vm.getValidityClass(item.isValid),
                        attrs: {
                          label: item.label,
                          value: _vm.inputValue(item),
                          error: item.error,
                          editable: _vm.editEnabled,
                          disabled: _vm.disabled,
                          "min-length": item.minLength,
                          "max-length": item.maxLength,
                          "one-line": item.oneLine,
                        },
                        on: {
                          change: function (value) {
                            return _vm.valueChange(key, value)
                          },
                        },
                      }),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.canModify
                ? _c(
                    "edit-footer",
                    {
                      attrs: {
                        slot: "footer",
                        "edit-enabled": _vm.editEnabled,
                        disabled: _vm.disabled,
                      },
                      on: { edit: _vm.enableEdit },
                      slot: "footer",
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: true,
                              expression: "true",
                            },
                          ],
                          staticClass: "btn-brand",
                          attrs: {
                            type: "button",
                            disabled: _vm.source === null,
                          },
                          on: {
                            click: function () {
                              return _vm.downloadSource()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("POAViewSource")) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }