var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sanction-notification" },
    [
      _c("ui-card", [
        _c("div", { staticClass: "display-column card-width" }, [
          _c("img", {
            staticClass: "margin-bottom-48",
            attrs: { src: require("@src/assets/images/icons/no-data.svg") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "display-column" }, [
            _c("h3", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("SanctionInOS.Title")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-2" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("SanctionInOS.Description")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "margin-bottom-48" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "btn-brand",
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openInNewTab(
                          "https://os.ondato.com/aml-screening"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("SanctionInOS.Button")) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("SanctionInOS.Support")) +
                  "\n          "
              ),
              _c(
                "a",
                {
                  staticClass: "btn-link",
                  attrs: { href: "mailto:support@ondato.com" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("SanctionInOS.SupportDesk")) +
                      "\n          "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }