function objectWithoutProperties(obj, exclude) {
  var target = {}
  for (var k in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, k) &&
      exclude.indexOf(k) === -1
    )
      target[k] = obj[k]
  return target
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group" },
    [
      _vm.loading
        ? _c("ui-loader", { attrs: { center: "" } })
        : _vm.error
        ? _c("div", { staticClass: "error" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("RegistryHubCourts.loadError")))]),
          ])
        : _vm.noCourts
        ? _c("div", [
            _c("b", [_vm._v(_vm._s(_vm.$t("RegistryHubCourts.noCourts")))]),
          ])
        : _vm.courts
        ? _c(
            "div",
            [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("RegistryHubCourts.courts")) + ":"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group" },
                _vm._l(_vm.counts, function (value, key) {
                  return _c("div", { key: key }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("RegistryHubCourts." + key)) + ":"),
                    ]),
                    _vm._v(" " + _vm._s(value) + "\n      "),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _vm._l(_vm.plaintiff, function (ref, index) {
                var caseParties = ref.caseParties
                var rest = objectWithoutProperties(ref, ["caseParties"])
                var item = rest
                return _c("div", { key: index }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("RegistryHubCourts.plaintiff")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "group" },
                    [
                      _vm._l(item, function (value, key) {
                        return _c("div", { key: key }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$t("RegistryHubCourts." + key)) + ":"
                            ),
                          ]),
                          _vm._v(" " + _vm._s(value) + "\n        "),
                        ])
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("RegistryHubCourts.caseParties")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(caseParties, function (party, partyIndex) {
                        return _c(
                          "div",
                          { key: partyIndex, staticClass: "group" },
                          _vm._l(party, function (value, key) {
                            return _c("div", { key: key }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("RegistryHubCourts." + key)) +
                                    ":"
                                ),
                              ]),
                              _vm._v(" " + _vm._s(value) + "\n          "),
                            ])
                          }),
                          0
                        )
                      }),
                    ],
                    2
                  ),
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.defendant, function (ref, index) {
                var caseParties = ref.caseParties
                var rest = objectWithoutProperties(ref, ["caseParties"])
                var item = rest
                return _c("div", { key: index, staticClass: "group" }, [
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("RegistryHubCourts.defendant")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "group" },
                    [
                      _vm._l(item, function (value, key) {
                        return _c("div", { key: key }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$t("RegistryHubCourts." + key)) + ":"
                            ),
                          ]),
                          _vm._v(" " + _vm._s(value) + "\n        "),
                        ])
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("RegistryHubCourts.caseParties")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(caseParties, function (party, partyIndex) {
                        return _c(
                          "div",
                          { key: partyIndex, staticClass: "group" },
                          _vm._l(party, function (value, key) {
                            return _c("div", { key: key }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("RegistryHubCourts." + key)) +
                                    ":"
                                ),
                              ]),
                              _vm._v(" " + _vm._s(value) + "\n          "),
                            ])
                          }),
                          0
                        )
                      }),
                    ],
                    2
                  ),
                ])
              }),
              _vm._v(" "),
              _vm._l(_vm.thirdParty, function (ref, index) {
                var caseParties = ref.caseParties
                var rest = objectWithoutProperties(ref, ["caseParties"])
                var item = rest
                return _c("div", { key: index, staticClass: "group" }, [
                  _c("b", [
                    _vm._v(
                      _vm._s(_vm.$t("RegistryHubCourts.thirdParty")) + ":"
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "group" },
                    [
                      _vm._l(item, function (value, key) {
                        return _c("div", { key: key }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$t("RegistryHubCourts." + key)) + ":"
                            ),
                          ]),
                          _vm._v(" " + _vm._s(value) + "\n        "),
                        ])
                      }),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("RegistryHubCourts.caseParties")) + ":"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(caseParties, function (party, partyIndex) {
                        return _c(
                          "div",
                          { key: partyIndex, staticClass: "group" },
                          _vm._l(party, function (value, key) {
                            return _c("div", { key: key }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("RegistryHubCourts." + key)) +
                                    ":"
                                ),
                              ]),
                              _vm._v(" " + _vm._s(value) + "\n          "),
                            ])
                          }),
                          0
                        )
                      }),
                    ],
                    2
                  ),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }