<template>
  <div class="details-item">
    <span class="details-label">
      {{ $t(label) }}
    </span>
    <div class="details-value" :class="classList">
      <ui-dropdown
        v-if="options && editable"
        :placeholder="placeholder"
        :value="value"
        :has-search="hasSearch"
        :error="error"
        :options="options"
        :key-value-dropdown="keyValueDropdown"
        :disabled="disabled || !editable"
        @input="valueChanged"
      />
      <ui-date-picker
        v-else-if="datePicker && editable"
        :error="error"
        :placeholder="placeholder"
        :value="value"
        @input="valueChanged"
      />
      <ui-input
        v-else-if="editable"
        :placeholder="$t(placeholder)"
        :one-line="oneLine"
        :disabled="disabled"
        :min-length="minLength"
        :max-length="maxLength"
        :type="type"
        :value="value"
        :error="error"
        @change="valueChanged"
        @input="$emit('input', $event)"
      />
      <a
        v-else-if="hyperlink"
        v-show="!editable"
        class="dropdown-readonly"
        :href="hyperlinkUrl"
        target="_blank"
      >
        {{ value }}
      </a>
      <span v-else-if="!hidden" class="dropdown-readonly">
        {{ itemText }}
        <slot />
      </span>
      <hidden-details v-else :value="itemText" />
    </div>
  </div>
</template>

<script>
import HiddenDetails from '@src/components/partials/HiddenDetails'

export default {
  components: {
    HiddenDetails
  },

  props: {
    label: { type: String, default: undefined },
    type: { type: String, default: undefined },
    value: { type: [String, Number, Boolean], default: undefined },
    placeholder: { type: String, default: undefined },
    error: { type: String, default: undefined },
    minLength: { type: Number, default: undefined },
    maxLength: { type: Number, default: undefined },
    options: { type: Array, default: undefined },
    editable: Boolean,
    disabled: Boolean,
    large: Boolean,
    hyperlink: Boolean,
    datePicker: Boolean,
    keyValueDropdown: Boolean,
    hasSearch: Boolean,
    translate: Boolean,
    oneLine: Boolean,
    hidden: Boolean
  },

  computed: {
    classList() {
      const { options, large, editable } = this
      const list = []

      if (!options) list.push('text-value')
      if (large) list.push('large-input')
      if (editable) list.push('details-value-width')

      return list
    },

    hyperlinkUrl() {
      const { value } = this
      if (!value) return
      const url = value.trim()
      if (url.startsWith('http')) return url
      return `http://${url}`
    },

    itemText() {
      const { translate, editable, value } = this
      return (translate && !editable && this.$t(value)) || value
    }
  },

  methods: {
    valueChanged(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 1.05rem;
  flex-flow: row wrap;
}

.ui-input {
  margin-bottom: 0;
}

.details-value-width {
  width: 50%;
}

.large-input {
  width: 70%;
}

.details-label {
  padding: 0.85rem 1.15rem;
  max-width: 50%;
}

.dropdown-readonly {
  font-family: sans-serif;
  font-size: 110%;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  padding: 0.85rem 1.15rem;
  text-align: right;
}

@media (max-width: 568px) {
  .details-item {
    flex-direction: column;
  }

  .details-item > .details-label,
  .details-item .details-value,
  .dropdown-readonly {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0.25rem 0.5rem;
  }
}
</style>

<style>
.details-item .details-value input:not(.el-input__inner),
.details-item .details-value textarea,
.details-item .details-value span.btn-text {
  font-family: sans-serif;
  font-size: 1.1539rem;
  font-weight: 500;
  color: #575962;
  padding-top: 0.7rem;
  padding-bottom: 1rem;
}

.details-item .details-value textarea::placeholder {
  font-family: sans-serif;
  font-size: 1.1539rem;
  font-weight: 500;
  color: #575962;
}

.details-item .ui-dropdown > .btn {
  width: 100%;
  background-color: transparent;
  border: 1px solid #ebedf2;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

.details-box .details-item .form-control {
  text-align: left;
}

.details-box .details-item .details-value .ui-input,
.details-box .details-item .details-value .ui-input input,
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
