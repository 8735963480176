<template>
  <div>
    <div class="comments-title">{{ $t('VideoCallComments.Comments') }}</div>
    <div
      v-for="comment in comments"
      :key="`comment-${comment.createdAt}`"
      class="comment"
    >
      <div class="comment-header">
        <div class="comment-date">{{ comment.createdAt | date(true) }}</div>
        <div class="comment-author">{{ comment.email }}</div>
      </div>
      <div class="comment-content">{{ comment.comment }}</div>
    </div>
  </div>
</template>

<script>
import { dateToString } from '@src/scripts/helpers'

export default {
  filters: {
    date: dateToString
  },
  props: {
    comments: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>
.comments-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 16px;
}

.comment {
  margin-bottom: 20px;
}

.comment .comment-header {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment .comment-header .comment-author {
  text-align: right;
}

.comment .comment-content {
  white-space: pre-wrap;
}
</style>
