var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "buttons-wrapper",
      attrs: { slot: "header" },
      slot: "header",
    },
    [
      _c(
        "ui-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.editEnabled,
              expression: "!editEnabled",
            },
          ],
          staticClass: "btn-brand",
          attrs: { type: "button", icon: "flaticon-edit-1", small: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("edit")
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("AppEdit")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "ui-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.editEnabled,
              expression: "editEnabled",
            },
          ],
          staticClass: "btn-brand",
          attrs: { icon: "la la-check", disabled: _vm.disabled, small: "" },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("AppSave")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }