<template>
  <div
    class="m-portlet m-portlet--responsive-mobile"
    :class="{
      'm-portlet--head-lg': large,
      'm-portlet--brandalt m-portlet--head-solid-bg': dark
    }"
  >
    <div v-if="title" class="m-portlet__head slider__head">
      <button
        class="btn-expand btn-expand-expanded ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm btn-left"
        @click="preSlide"
      >
        <i class="fas fa-caret-left" />
      </button>
      <button
        class="btn-expand ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm btn-right"
        @click="nextSlide"
      >
        <i class="fas fa-caret-right" />
      </button>
      <div class="m-portlet__head-wrapper header-tools">
        <div class="m-portlet__head-caption">
          <div v-if="title" class="m-portlet__head-title">
            <div class="text-center">
              <h4>{{ sliderTitle }} {{ currentSlide }}/{{ getSlideCount }}</h4>
              <h3 class="m-portlet__head-text">
                <div v-if="hasAutoCapture" class="header-container">
                  <span>{{ $t(title) }}</span>
                  <div
                    class="m-badge m-badge--wide status-value m-badge--brand"
                  >
                    {{ $t('AppAutoCapture') }}
                  </div>
                </div>
                <span v-else>{{ $t(title) }}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <div class="m-portlet__body">
        <slot :currentSlide="currentSlide" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'

export default {
  name: 'ui-card-slider',
  props: {
    title: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    slideCount: { type: Number, default: undefined },
    large: Boolean,
    dark: Boolean,
    sliderTitle: { type: String, required: false, default: undefined },
    items: { type: Array, default: undefined }
  },

  data() {
    return {
      hasAutoCapture: false,
      currentSlide: 1,
      getSlideCount: null
    }
  },
  computed: {
    ...mapGetters([getters.userPages])
  },
  watch: {
    currentSlide() {
      this.hasAutoCapture =
        this.items[this.currentSlide - 1]?.autoCaptureConfidenceRate !==
        undefined
    }
  },
  mounted() {
    this.hasAutoCapture =
      this.items[this.currentSlide - 1]?.autoCaptureConfidenceRate !== undefined
    this.getSlideCount = this.slideCount
  },
  methods: {
    nextSlide() {
      if (this.currentSlide === this.getSlideCount) {
        this.currentSlide = 1
        return
      }
      this.currentSlide += 1
    },
    preSlide() {
      if (this.currentSlide === 1) {
        this.currentSlide = 1
        return
      }
      this.currentSlide -= 1
    }
  }
}
</script>

<style scoped>
.m-portlet__head-title {
  justify-content: center;
}

.btn {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}

.header-container > span {
  margin-right: 0.8rem;
}

.btn-left {
  left: 1rem;
}

.btn-right {
  right: 1rem;
}

.m-portlet__head.slider__head {
  flex-direction: column;
  padding: 1rem 2.2rem;
  position: relative;
}

.m-portlet__head-wrapper.header-tools {
  justify-content: center;
  width: 100%;
}

.slider__tools {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
  align-items: center;
  justify-content: center;
}

.slider__tools h4 {
  margin-bottom: 0;
}

.subheader {
  font-size: 0.9rem;
  line-height: 1.5;
  border-left: 1px solid #e2e5ec;
  color: #898b96;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn.m-btn--wide.btn-sm.btn-expand {
  padding: 0.5rem 0.8rem;
  font-size: 0.6rem;
}

.monitoring-toggle {
  margin-right: 2rem;
}

@media (max-width: 568px) {
  .subheader {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.m-portlet__head-wrapper.header-tools .m-portlet__head-caption {
  justify-content: center;
}

@media (max-width: 768px) {
  .m-portlet.m-portlet--responsive-mobile
    .m-portlet__head
    .m-portlet__head-wrapper.header-tools {
    width: 100%;
  }
}
</style>
