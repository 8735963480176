var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loader-component" }, [
    !_vm.response
      ? _c("div", { staticClass: "loader-content" }, [
          _c(
            "div",
            { staticClass: "ui-loader-wrapper" },
            [_c("ui-loader", { attrs: { size: 4, center: "" } })],
            1
          ),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t(_vm.description)))]),
        ])
      : _c("div", { staticClass: "response" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("h5", [_vm._v(_vm._s(_vm.$t(_vm.response)))]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "fa fa-check green-icon" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }