var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "identification-page" },
    [
      _c(
        "ui-header",
        {
          class: { "fixed-header": _vm.scrollDown },
          attrs: {
            title: "IdentificationsTitle",
            method: _vm.searchMethod,
            filters: _vm.filters,
            "reset-form": "",
            disabled: _vm.isLoading || _vm.isDownloading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("div", { staticClass: "filters-block" }, [
            _c(
              "div",
              { staticClass: "display-flex flex-flow-wrap" },
              [
                _c("ui-header-dropdown", {
                  attrs: {
                    options: _vm.options.dateOptions,
                    placeholder: "HeaderDatePlaceHolder",
                    wide: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "date", $$v)
                    },
                    expression: "filters.date",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "email",
                    placeholder: "HeaderEmailPlaceholder",
                    icon: "la-envelope",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "email", $$v)
                    },
                    expression: "filters.email",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  attrs: {
                    options: _vm.options.reviewStatusOptions,
                    all: "",
                    medium: "",
                    placeholder: "HeaderReviewStatusPlaceholder",
                    disabled: _vm.isLoading || !_vm.options.reviewStatusOptions,
                  },
                  model: {
                    value: _vm.filters.reviewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "reviewStatus", $$v)
                    },
                    expression: "filters.reviewStatus",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  attrs: {
                    all: "ALL_FINISHED",
                    options: _vm.options.requestStatusOptions,
                    medium: "",
                    placeholder: "HeaderRequestStatusPlaceholder",
                    disabled:
                      _vm.isLoading || !_vm.options.requestStatusOptions,
                  },
                  model: {
                    value: _vm.filters.requestStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "requestStatus", $$v)
                    },
                    expression: "filters.requestStatus",
                  },
                }),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-header-input", {
                      attrs: {
                        type: "text",
                        placeholder: "TableFullName",
                        medium: "",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.fullname,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "fullname", $$v)
                        },
                        expression: "filters.fullname",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-header-input", {
                      attrs: {
                        type: "email",
                        placeholder: "DetailsPersonCode",
                        medium: "",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.personalCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "personalCode", $$v)
                        },
                        expression: "filters.personalCode",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-header-dropdown", {
                      staticClass: "countries-dropdown",
                      attrs: {
                        all: "",
                        "has-search": "",
                        "key-value-dropdown": "",
                        options: _vm.countriesOptions,
                        medium: "",
                        placeholder: "DetailsCountry",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "country", $$v)
                        },
                        expression: "filters.country",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch && _vm.stateOptionList
                  ? _c("ui-header-dropdown", {
                      staticClass: "countries-dropdown",
                      attrs: {
                        all: "",
                        "key-value-dropdown": "",
                        options: _vm.stateOptionList,
                        medium: "",
                        placeholder: "DetailsState",
                        disabled:
                          _vm.isLoading || !_vm.options.requestStatusOptions,
                      },
                      model: {
                        value: _vm.filters.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "state", $$v)
                        },
                        expression: "filters.state",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-header-dropdown", {
                      attrs: {
                        all: "",
                        options: _vm.documentTypes,
                        medium: "",
                        placeholder: "DetailsDocumentType",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.documentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "documentType", $$v)
                        },
                        expression: "filters.documentType",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-multi-header-dropdown", {
                      attrs: {
                        placeholder: _vm.$t("DetailsRejectionReason"),
                        options: _vm.rejectionReasons,
                        medium: "",
                        "has-search": "",
                        disabled: _vm.isLoading,
                      },
                      on: {
                        dropdownMultiSelected: _vm.handleRejectionReasonsSelect,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.openFullSearch
                  ? _c("ui-header-input", {
                      attrs: {
                        type: "text",
                        placeholder: "DetailsIdentificationId",
                        medium: "",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.identificationId,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "identificationId", $$v)
                        },
                        expression: "filters.identificationId",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filter-small-block",
                    class: _vm.openFullSearch && "open-fullsearh-link",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fullsearh-link",
                        on: {
                          click: function ($event) {
                            _vm.openFullSearch
                              ? (_vm.openFullSearch = false)
                              : (_vm.openFullSearch = true)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t(_vm.fullSearchText)) +
                            "\n            "
                        ),
                        _c("i", {
                          staticClass: "la",
                          class: _vm.openFullSearch
                            ? "la-angle-up"
                            : "la-angle-down",
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { title: "AppSearchResults", count: _vm.itemsCount } },
            [
              _c("div", { attrs: { slot: "tools" }, slot: "tools" }, [
                _c(
                  "div",
                  { staticClass: "display-flex" },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "btn-brand margin-right-3 hidden-button",
                        attrs: { small: "" },
                        on: { click: _vm.clickAllIdentification },
                      },
                      [_vm._v("\n            View all\n          ")]
                    ),
                    _vm._v(" "),
                    _vm.isDownloading
                      ? _c("ui-loader", {
                          staticClass: "mr-1",
                          attrs: { size: 1, center: "" },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ui-dropdown", {
                      staticClass: "margin-right",
                      attrs: {
                        options: _vm.exportOptions,
                        placeholder: "AppExport",
                        disabled: _vm.isDownloading,
                        small: "",
                        "light-border": "",
                      },
                      on: { input: _vm.exportList },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              !_vm.isLoading && _vm.identifications
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "export-table hide-mobile",
                        attrs: {
                          labels: _vm.listLabels,
                          data: _vm.identifications,
                          "empty-text": "TableNoIdentifications",
                        },
                      },
                      [
                        _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              staticClass: "m-table__row",
                              class: _vm.classList(row),
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "cell-identification-id-desktop fixed-width",
                                  attrs: { title: row.token },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "identification-id-desktop",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyIdentificationId(
                                            row.token
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.token) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm._f("date")(row.created, true)) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.companyName },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.companyName) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width full-name",
                                  attrs: { title: _vm.getFullName(row) },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.getFullName(row)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.email },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.email) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "review-status" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(row.reviewStatus)) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "m--font-success" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(row.requestStatus)) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "m--font-success" },
                                [
                                  !_vm.hideToggle(
                                    row.requestStatus,
                                    row.reviewStatus
                                  )
                                    ? _c("ui-toggle", {
                                        attrs: {
                                          prevent: "",
                                          value: _vm.getMonitoringStatus(
                                            row.monitoringData.monitoringStatus
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeMonitoring(
                                              row,
                                              index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.hasThirdPartyExport
                                ? _c("td", { staticClass: "m--font-success" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          (row.exportStatus &&
                                            _vm.$t(
                                              row.exportStatus.thirdPartyExport
                                            )) ||
                                            "-"
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "td-link" },
                                [
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId: row.token,
                                          },
                                        },
                                        "small-text": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("TableView")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-metal",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId: row.token,
                                            export: true,
                                          },
                                        },
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("AppExport")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.identifications && _vm.identifications.length > 0
                      ? _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                              class: _vm.classList(row),
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableIdentificationId",
                                  value: row.token,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableDate",
                                  value: _vm._f("date")(row.finished, true),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableCompanyName",
                                  value: row.companyName,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                staticClass: "full-name",
                                attrs: {
                                  title: "TableFullName",
                                  value: _vm.getFullName(row),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableEmail",
                                  value: row.email,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                staticClass: "review-status",
                                attrs: {
                                  title: "TableReviewStatus",
                                  value: row.reviewStatus,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableRequestStatus",
                                  value: row.requestStatus,
                                },
                              }),
                              _vm._v(" "),
                              !_vm.hideToggle(
                                row.requestStatus,
                                row.reviewStatus
                              )
                                ? _c(
                                    "responsive-table-tr",
                                    { attrs: { title: "Monitoring" } },
                                    [
                                      _c("ui-toggle", {
                                        attrs: {
                                          prevent: "",
                                          value: _vm.getMonitoringStatus(
                                            row.monitoringData.monitoringStatus
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeMonitoring(
                                              row,
                                              index
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasThirdPartyExport
                                ? _c("responsive-table-tr", {
                                    attrs: {
                                      title: "Export Status",
                                      value:
                                        (row.exportStatus &&
                                          row.exportStatus.thirdPartyExport) ||
                                        "-",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10 margin-1" },
                                [
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-brand big-link",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId: row.token,
                                          },
                                        },
                                        "small-text": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("TableView")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-metal big-link",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId: row.token,
                                          },
                                        },
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("AppExport")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("TableNoIdentifications")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm._e(),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.filters.page,
                  "items-count": _vm.itemsCount,
                  "max-on-page": _vm.filters.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            { attrs: { "overflow-unset": "", "full-size": "" } },
            [
              _c("monitoring-setup", {
                attrs: { data: _vm.monitoringData },
                on: { change: _vm.changeMonitoringStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }