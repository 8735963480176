<template>
  <div
    class="ui-date-picker m-form__group"
    :class="{
      'has-error': error,
      'ui-touched': istouched,
      'ui-untouched': !istouched
    }"
  >
    <div class="input-box">
      <el-date-picker
        class="date-picker"
        type="date"
        :placeholder="placeholder"
        value-format="yyyy-MM-dd"
        :value="value"
        :clearable="true"
        @input="inputDate"
        @blur="lostFocus"
      />
    </div>
    <span
      class="error-message"
      :class="{ 'error-offset': label && !multiline }"
    >
      {{ $t(error) }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'ui-date-picker',

  props: {
    value: { type: [String, Number], default: undefined },
    label: { type: String, default: undefined },
    error: { type: String, default: undefined },
    placeholder: { type: String, default: undefined }
  },

  data() {
    return {
      istouched: false,
      passwordType: null
    }
  },

  methods: {
    lostFocus() {
      if (!this.istouched) {
        this.istouched = true
      }
      this.$emit('blur')
    },

    inputDate(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.ui-date-picker {
  flex-direction: column;
}

.input-box {
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ui-date-picker,
.input-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-date-picker.no-border.form-group.m-form__group
  > .input-box
  input.form-control {
  border-bottom: none;
}

.type-password .form-control {
  padding-right: 3.5rem;
}

.password-show {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 3rem;
  display: flex;
}

.password-show:hover {
  cursor: pointer;
  background-color: var(--light);
}

.password-show:active {
  opacity: 0.8;
}

.password-show > i {
  font-size: 1.75rem;
  margin: auto;
  color: var(--gray);
}

.error-message {
  text-align: left;
}

@media (min-width: 992px) {
  .col-form-label.col-lg-5 {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .error-offset {
    margin-left: 41.66667%;
  }
}
</style>
