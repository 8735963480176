<template>
  <div class="sanction-notification">
    <ui-card>
      <div class="display-column card-width">
        <img
          :src="require('@src/assets/images/icons/no-data.svg')"
          class="margin-bottom-48"
        />
        <div class="display-column">
          <h3>
            {{ $t('SanctionInOS.Title') }}
          </h3>
          <p class="mt-2">
            {{ $t('SanctionInOS.Description') }}
          </p>
          <div class="margin-bottom-48">
            <ui-button
              class="btn-brand"
              small
              @click="openInNewTab('https://os.ondato.com/aml-screening')"
            >
              {{ $t('SanctionInOS.Button') }}
            </ui-button>
          </div>
          <p>
            {{ $t('SanctionInOS.Support') }}
            <a href="mailto:support@ondato.com" class="btn-link">
              {{ $t('SanctionInOS.SupportDesk') }}
            </a>
          </p>
        </div>
      </div>
    </ui-card>
  </div>
</template>

<script>
export default {
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer')
    }
  }
}
</script>

<style scoped>
.sanction-notification {
  max-width: 550px;
  text-align: center;
  margin: auto;
}

.card-width {
  width: 380px;
  margin: 20px auto 0;
}

.display-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
}

.margin-bottom-48 {
  margin-bottom: 48px;
}
</style>
