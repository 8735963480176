var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "response-table" }, [
    _c("table", { staticClass: "table" }, [
      _c("thead", { class: _vm.headerType }, [_vm._t("table-header")], 2),
      _vm._v(" "),
      _c("tbody", [_vm._t("table-body")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }