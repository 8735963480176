<template>
  <transition name="slide" mode="out-in">
    <div
      v-if="data.isVisible"
      class="pop-up"
      :class="{ 'full-size': fullSize }"
    >
      <div class="pop-up-bg">
        <div id="blah" />
      </div>
      <div class="pop-up-content">
        <div class="content" :class="{ 'no-padding': noPadding }">
          <template v-if="popUpTitle">
            <div
              class="d-flex justify-content-between align-items-center pop-up-header"
            >
              <div>{{ popUpTitle }}</div>
              <a class="close close-title" @click="onClose" />
            </div>
          </template>
          <template v-else><a class="close" @click="onClose" /></template>
          <div
            :class="{
              'overflow-hidden': overflowHidden,
              'overflow-unset': overflowUnset
            }"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { popUpData } from '@src/components/popup'

export default {
  props: {
    fullSize: Boolean,
    borderColor: { type: String, default: undefined },
    overflowHidden: Boolean,
    popUpTitle: { type: String, default: undefined },
    noPadding: Boolean,
    overflowUnset: Boolean
  },

  data() {
    return {
      data: popUpData
    }
  },

  updated() {
    if (this.data.background) {
      document.getElementById('blah').appendChild(this.data.background)
    }
  },

  methods: {
    onClose() {
      this.$closePopUp()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
/deep/ #app {
  overflow: hidden;
}

.pop-up {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
}

.pop-up-bg,
.pop-up-content {
  position: absolute;
}

.pop-up-bg {
  height: 100%;
  width: 100%;
  filter: blur(0.5rem);
}

.pop-up-content {
  background-color: var(--transparent-bg);
  height: 100%;
  width: 100%;
  display: flex;
}

.content {
  background-color: var(--white);
  border: 1px solid var(--dark-br);
  border-radius: 5px;
  padding: 1rem 1.5rem 1.5rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
  max-height: 48rem;
}

.no-padding.content {
  padding: 0;
}

.pop-up-header {
  padding: 1.154rem 1.769rem;
}

.content .close.close-title {
  margin: 0;
}

.full-size .content {
  max-width: unset;
  max-height: unset;
}

.content > .overflow-hidden {
  overflow: hidden;
}

.content > .overflow-unset {
  overflow: unset;
}

.content .close {
  min-height: 1.5rem;
  width: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
  margin-left: auto;
}

.content > div {
  overflow: auto;
}

.content .close::before,
.content .close::after {
  content: '';
  position: absolute;
  background-color: var(--input-font);
  width: 1px;
  height: 100%;
  left: 50%;
}

.content .close::before {
  transform: rotateZ(45deg);
}

.content .close::after {
  transform: rotateZ(-45deg);
}

.slide-enter-active,
.slide-leave-active,
.slide-enter-active .pop-up-bg,
.slide-leave-active .pop-up-bg {
  transition-duration: 0.3s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}

.slide-enter {
  transform: translateX(100%);
  opacity: 0;
}

.slide-leave-active {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-enter-active .pop-up-bg {
  transform: translateX(0);
}

.slide-enter .pop-up-bg {
  transform: translateX(-100%);
}

.slide-leave-active .pop-up-bg {
  transform: translateX(100%);
}

.pop-up-title {
  font-weight: bold;
  font-size: 16px;
  color: #575962;
}
</style>
