import AppNotification from '@src/components/notification/AppNotification'

let itemId = 0
let repeatNotifySound
let notificationSound

export const notificationType = Object.freeze({
  error: 'error',
  info: 'info',
  warning: 'warning',
  infoWithSound: 'info-with-sound',
  timeLeft: 'time-left',
  infoTitle: 'info-title'
})

export const notificationData = {
  notificatios: [],
  removeNotification
}

export const notificationDataHistory = {
  notificatios: [],
  notificationEvent
}

export default {
  install: (Vue) => {
    Vue.component('app-notification', AppNotification)
    Vue.prototype.$notify = getNotification

    loadSound()
  }
}

export function getNotification({
  title,
  message,
  messageData,
  type = notificationType.error,
  callback,
  cancelCallback,
  repeatSound
}) {
  const notification = {
    id: itemId,
    type,
    title,
    message,
    messageData,
    callback,
    cancelCallback,
    repeatSound,
    time: new Date()
  }

  if (type === notificationType.warning) {
    if (notificationData.notificatios.length > 0) {
      notificationData.notificatios.splice(0, 1)
    }
  }
  notificationData.notificatios.splice(0, 0, notification)
  if (notificationData.notificatios.length > 5) {
    notificationData.notificatios.splice(5, 1)
  }

  notificationDataHistory.notificatios.unshift(notification)
  autoRemove(notification)
  itemId++
  playSound(notification)

  return () => {
    removeNotification(notification)
  }
}

function autoRemove(item) {
  if (item.type === notificationType.timeLeft) return null
  if (!item.repeatSound) setTimeout(() => removeNotification(item), 3000)
}

function removeNotification(item) {
  const { notificatios } = notificationData
  const itemIndex = notificatios.indexOf(item)
  if (itemIndex !== -1) {
    notificatios.splice(itemIndex, 1)
  }
  clearInterval(repeatNotifySound)
}

function notificationEvent(item, event) {
  const { notificatios } = notificationDataHistory
  const itemIndex = notificatios.indexOf(item)
  if (itemIndex !== -1) {
    Object.assign(notificatios[itemIndex], { event })
  }
}

function loadSound() {
  const audioElement = new Audio(`/${Hash}/static/audio/info.mp3`)
  if (!audioElement) return

  audioElement.oncanplay = () => {
    notificationSound = audioElement
  }
}

function playSound(notification) {
  if (
    notification.type !== notificationType.infoWithSound ||
    !notificationSound
  )
    return

  if (!notification.repeatSound) {
    return notificationSound.play()
  }

  playRepeatedly(notification)
}

function playRepeatedly(notification) {
  if (!notification) return
  document.addEventListener('mouseover', stop, false)

  play()
  const interval = setInterval(play, 1500)

  function play() {
    if (notification.repeatSound) {
      notificationSound.pause()
      notificationSound.currentTime = 0

      notificationSound.play()
      notification.repeatSound--
    } else {
      stop()
    }
  }

  function stop() {
    document.removeEventListener('mouseover', stop, false)
    clearInterval(interval)
    removeNotification(notification)
  }
}
