var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-portlet m-portlet--responsive-mobile",
      class: {
        "m-portlet--head-lg": _vm.large,
        "m-portlet--brandalt m-portlet--head-solid-bg": _vm.dark,
      },
    },
    [
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "m-portlet__head",
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c("div", { staticClass: "m-portlet__head-wrapper" }, [
                _c("div", { staticClass: "m-portlet__head-caption" }, [
                  _vm.title
                    ? _c(
                        "div",
                        { staticClass: "m-portlet__head-title" },
                        [
                          _vm.icon
                            ? _c(
                                "span",
                                { staticClass: "m-portlet__head-icon" },
                                [_c("i", { class: _vm.icon })]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.expandable
                            ? [
                                _vm.bodyExpanded
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-expand btn-expand-expanded ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm",
                                        on: { click: _vm.toggleExpandButton },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-caret-up",
                                        }),
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-expand ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm",
                                        on: { click: _vm.toggleExpandButton },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-caret-down",
                                        }),
                                      ]
                                    ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h3", { staticClass: "m-portlet__head-text" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t(_vm.title)) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.count
                            ? _c("span", { staticClass: "subheader" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.count) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _vm.hasTools
                ? _c(
                    "div",
                    { staticClass: "m-portlet__head-tools" },
                    [
                      _vm.withMonitoringToggle && _vm.isMonitoringAvailable
                        ? _c("ui-toggle", {
                            staticClass: "monitoring-toggle",
                            attrs: {
                              label: _vm.$t("Monitoring"),
                              value: _vm.isMonitoring,
                            },
                            on: { change: _vm.monitoringChanged },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("tools"),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.expandable && !_vm.bodyExpanded
        ? _c("div", { staticClass: "m-portlet__body" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("ClickToExpandAndSeeMoreData")))]),
          ])
        : [
            _vm.hasDefaultContent && !_vm.multicolumn
              ? _c(
                  "div",
                  { staticClass: "m-portlet__body" },
                  [_vm._t("default")],
                  2
                )
              : _c(
                  "div",
                  {
                    staticClass: "m-portlet__body m-portlet__body--no-padding",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row m-row--no-padding m-row--col-separator-xl",
                      },
                      [
                        !_vm.multicolumn
                          ? _c(
                              "div",
                              { staticClass: "col-xl-12" },
                              [_vm._t("body")],
                              2
                            )
                          : [
                              _c(
                                "div",
                                { staticClass: "col-xl-6" },
                                [_vm._t("body-left")],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-xl-6" },
                                [_vm._t("body-right")],
                                2
                              ),
                            ],
                      ],
                      2
                    ),
                  ]
                ),
            _vm._v(" "),
            _vm.hasFooter
              ? _c(
                  "div",
                  { staticClass: "m-portlet__foot" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }