import { pageTypeEnum } from '@src/scripts/enums'
import { getters as kyb } from '@src/store/kyb'

export const methods = {
  isLoggedIn,
  sessionData,
  settingsData,
  user,
  companies,
  documentTypes,
  nationalities,
  rejectionReasons,
  searchHistory,
  selectedCompany,
  userPages,
  havePermission,
  canModifyVerification,
  canOverrideIdentification,
  inProgress,
  hideLogs,
  sessionTimeNotify,
  SanctionsSearch,
  KybCompanyList,
  StopList,
  registries,
  ...kyb
}

export default Object.keys(methods).reduce(
  (result, item) => ({
    ...result,
    [item]: item
  }),
  {}
)

function isLoggedIn({ session }) {
  return !!(session && session.access_token)
}

function sessionData({ session }) {
  return session
}

function settingsData({ settings }) {
  return settings || []
}

function user({ settings }) {
  return (settings && settings.user) || {}
}

function companies({ settings }) {
  return (settings && settings.companies) || []
}

function documentTypes({ settings }) {
  return (settings && settings.documentTypes) || []
}

function nationalities({ settings }) {
  return (settings && settings.nationalities) || []
}

function rejectionReasons({ settings }) {
  return (settings && settings.rejectionReasons) || []
}

function registries({ registries }) {
  return registries || []
}

function searchHistory({ history }) {
  return (key) => {
    return (history && history[key]) || undefined
  }
}

function canModifyVerification({ selectedCompany, settings }) {
  if (
    !selectedCompany ||
    !settings ||
    !settings.companies ||
    !settings.companies.length
  )
    return false
  const details = settings.companies.find((c) => c.id === selectedCompany)
  if (!details || !details.security || !details.security.permissions)
    return false
  return details.security.permissions.includes('ModifyIdentification')
}

function canOverrideIdentification({ selectedCompany, settings }) {
  if (
    !selectedCompany ||
    !settings ||
    !settings.companies ||
    !settings.companies.length
  )
    return false
  const details = settings.companies.find((c) => c.id === selectedCompany)
  if (!details || !details.security || !details.security.permissions)
    return false
  return details.security.permissions.includes('OverrideIdentification')
}

function selectedCompany({ selectedCompany, settings }) {
  return selectedCompany || settings?.user?.defaultCompanyId
}

function userPages(state, { companies, selectedCompany }) {
  const company = companies.find((item) => item.id === selectedCompany)
  return company?.security?.pages
}

function havePermission(state, { userPages }) {
  return (name) => userPages?.includes(pageTypeEnum[name]) ?? false
}

function inProgress({ inProgress }) {
  return inProgress || false
}

function hideLogs({ hideLogs }) {
  return hideLogs
}

function sessionTimeNotify({ sessionTimeNotify }) {
  return sessionTimeNotify
}

function SanctionsSearch({ sanctions }) {
  return sanctions
}

function KybCompanyList({ kybList }) {
  return kybList
}

function StopList({ stopList }) {
  return stopList
}
