<template>
  <ui-form v-if="documentData" class="document-data" @submit="submit">
    <ui-card title="DetailsDocument" icon="flaticon-avatar">
      <div v-if="hasNfc" slot="tools">
        <div class="m-portlet__head-caption">
          <div class="nfc-brand m-portlet__head-title">
            <h3 class="m-portlet__head-text">NFC</h3>
            <img
              class="icon"
              :src="require('@src/assets/images/icons/NFC.svg')"
            />
          </div>
        </div>
      </div>
      <div v-if="hasQr" slot="tools">
        <div class="m-portlet__head-caption">
          <div class="nfc-brand m-portlet__head-title">
            <h3 class="m-portlet__head-text">QR</h3>
            <img
              class="icon"
              :src="require('@src/assets/images/icons/QR.svg')"
            />
          </div>
        </div>
      </div>
      <details-box slot="body">
        <template v-for="(item, key) in documentData">
          <details-item
            v-if="!hiddenIf(item)"
            :key="key"
            class="ui-input-touched"
            :class="getValidityClass(item.isValid)"
            :label="item.label"
            :value="inputValue(item)"
            :error="item.error"
            :editable="editEnabled"
            :disabled="disabled"
            :min-length="item.minLength"
            :max-length="item.maxLength"
            :one-line="item.oneLine"
            :key-value-dropdown="item.keyValueDropdown"
            :options="optionList(item)"
            :has-search="item.hasSearch"
            :translate="item.translate"
            :placeholder="item.placeholder"
            :date-picker="item.datePicker"
            @change="(value) => valueChange(key, value)"
          />
        </template>
        <Mrz
          v-if="hasMrzRule"
          :values="mrzFieldValues"
          :edit-enabled="editEnabled"
          :identification="identification"
        />
      </details-box>
      <edit-footer
        v-if="canModify"
        slot="footer"
        :edit-enabled="editEnabled"
        :disabled="disabled"
        @edit="enableEdit"
      />
    </ui-card>
  </ui-form>
</template>

<script>
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import EditFooter from '@src/components/partials/EditFooter'
import { mapOptions } from '@src/scripts/helpers'
import { canStates, usaStates, mexStates } from '@src/scripts/states'
import { DefaultDocumentData } from '@src/components/partials/Identifications/defaultData'
import Mrz from '../MrzData/Mrz'

export const MRZ_FIELDS = {
  MRZ_1: 'mrzLine1',
  MRZ_2: 'mrzLine2',
  MRZ_3: 'mrzLine3'
}

export default {
  components: {
    DetailsBox,
    DetailsItem,
    EditFooter,
    Mrz
  },

  props: {
    data: { type: Object, default: undefined },
    identification: { type: Object, default: undefined },
    editEnabled: Boolean,
    disabled: Boolean,
    canModify: Boolean,
    hasNfc: Boolean,
    hasQr: Boolean
  },

  data() {
    const initializedMrzFields = Object.values(MRZ_FIELDS).reduce(
      (accumulator, fieldKey) => {
        accumulator[fieldKey] = this.data?.[fieldKey]?.value ?? ''
        return accumulator
      },
      {}
    )

    const hasMrzRule = this.identification?.rules?.find(
      (rule) => rule.name === 'MRZ_VERIFIED'
    )

    return {
      hasMrzRule,
      documentData: null,
      countryWithStateList: ['USA', 'CAN', 'MEX'],
      mrzFieldValues: initializedMrzFields
    }
  },

  computed: {
    genderOptions() {
      return mapOptions(['M', 'F', 'X'])
    },

    nationalityOptions() {
      const { nationalities } = this.$store.getters
      return mapOptions(['XXX', ...nationalities])
    },

    countryOptions() {
      const { nationalities } = this.$store.getters
      return mapOptions(['XXX', ...nationalities])
    },

    documentOptions() {
      const { documentTypes } = this.$store.getters
      return mapOptions(documentTypes)
    },

    defaultDocumentDataModel() {
      const {
        genderOptions,
        nationalityOptions,
        countryOptions,
        documentOptions,
        showStateIf,
        showAddressIf,
        showCategoryIf,
        inputStateValue,
        valueCountryChange,
        valueDocumentTypeChange,
        stateOptionList
      } = this

      return new DefaultDocumentData({
        genderOptions,
        nationalityOptions,
        countryOptions,
        documentOptions,
        showStateIf,
        showAddressIf,
        showCategoryIf,
        inputStateValue,
        valueCountryChange,
        valueDocumentTypeChange,
        stateOptionList
      })
    }
  },

  watch: {
    data() {
      this.setDocumentData()
    }
  },

  created() {
    this.setDocumentData()
  },

  methods: {
    valueChange(key, value) {
      const item = this.documentData[key]
      if (item.valueChange) item.valueChange(value)

      this.$set(item, 'value', value)
    },

    enableEdit() {
      this.$emit('edit')
    },

    getValidityClass(isValid) {
      if (isValid === true) {
        return 'valid-value'
      } else if (isValid === false) {
        return 'invalid-value'
      }
      return ''
    },

    setDocumentData() {
      const { defaultDocumentDataModel, data } = this
      if (!data) {
        this.documentData = defaultDocumentDataModel
        this.$emit('validation', false)
        return
      }

      for (const key in defaultDocumentDataModel) {
        const originalItem = data[key]
        if (!originalItem) continue

        const dataItem = defaultDocumentDataModel[key]
        if (typeof originalItem === 'object') {
          dataItem.value = originalItem.value
          dataItem.isValid = originalItem.isValid
        } else {
          dataItem.value = originalItem
        }

        if (dataItem.datePicker && dataItem.value) {
          dataItem.value = dataItem.value.split('T')[0]
        }
      }

      this.documentData = defaultDocumentDataModel
      this.$emit('validation', this.validateAll())
    },

    hiddenIf(item) {
      if (!item.showIf) return false
      return !item.showIf()
    },

    showStateIf() {
      const { country, documentType } = this.documentData
      if (country.value === 'MEX')
        return documentType.value === 'DRIVER_LICENSE'
      return ['USA', 'CAN'].includes(country.value)
    },

    showAddressIf() {
      const {
        country: { value: country },
        documentType: { value: documentType }
      } = this.documentData

      if (['ROU', 'DEU'].includes(country)) {
        return true
      }

      if (
        ['BGR', 'CZE', 'MDV', 'ITA', 'FRA', 'ESP', 'HRV'].includes(country) &&
        documentType === 'ID_CARD'
      ) {
        return true
      }

      if (
        ['JAM', 'CZE', 'SVK', 'POL'].includes(country) &&
        ['DRIVER_LICENSE', 'ID_CARD'].includes(documentType)
      ) {
        return true
      }

      return false
    },

    showCategoryIf() {
      const { documentType } = this.documentData
      return documentType.value === 'DRIVER_LICENSE'
    },

    inputValue(item) {
      if (!item.inputValue) return item.value
      return item.inputValue()
    },

    inputStateValue() {
      const { country, state } = this.documentData
      if (!state.value) return

      return `${country.value}_${state.value}`
    },

    optionList(item) {
      if (!item.options) return

      if (typeof item.options === 'function') return item.options()
      else return item.options
    },

    stateOptionList() {
      const { countryWithStateList } = this
      const { country } = this.documentData
      if (!countryWithStateList.includes(country.value)) return

      let stateList
      if (country.value === 'USA') stateList = usaStates
      else if (country.value === 'CAN') stateList = canStates
      else if (country.value === 'MEX') stateList = mexStates

      return stateList?.map((item) => ({
        key: item,
        value: `${country.value}_${item}`
      }))
    },

    valueCountryChange(countryCode) {
      const { state, country, address } = this.documentData
      if (country.value === countryCode) return

      if (address.value) {
        this.$set(address, 'value', undefined)
      }

      if (state.value) {
        this.$set(state, 'value', undefined)
      }
    },

    valueDocumentTypeChange() {
      const { address } = this.documentData
      if (address.value) {
        this.$set(address, 'value', undefined)
      }
    },

    validateAll() {
      const { documentData, editEnabled } = this
      let isValid = true

      for (const key in documentData) {
        const item = documentData[key]
        if ((item.showIf && !item.showIf()) || !item.validations) continue

        if (editEnabled) this.$set(item, 'error', undefined)
        for (const validation of item.validations) {
          const error = validation(item, documentData)
          if (!error) continue

          if (editEnabled) this.$set(item, 'error', error)
          isValid = false
          break
        }
      }

      return isValid
    },

    submit() {
      if (!this.validateAll()) return

      const result = {}
      const { documentData } = this
      for (const key in documentData) {
        const item = documentData[key]
        if (item.showIf && !item.showIf()) continue

        if (!item.value) continue

        let value = item.value || undefined
        if (typeof value === 'string') value = value.toUpperCase()

        result[key] = value
      }

      if (this.hasMrzRule) {
        result.mrzLine1 = this.mrzFieldValues.mrzLine1
        result.mrzLine2 = this.mrzFieldValues.mrzLine2
        result.mrzLine3 = this.mrzFieldValues.mrzLine3
      }

      this.$emit('save', result)
      this.$emit('validation', true)
    }
  }
}
</script>

<style scoped>
.document-data {
  width: 100%;
}
</style>

<style>
.document-data .details-value,
.document-data .details-value input,
.document-data .details-value textarea,
.document-data .details-value button {
  text-transform: uppercase;
}

.invalid-value .details-label,
.invalid-value .dropdown-readonly {
  color: #f4516c;
}

.valid-value .details-label,
.valid-value .dropdown-readonly {
  color: #28a745;
}
.nfc-brand {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nfc-brand img {
  margin-left: 5px;
}
</style>
