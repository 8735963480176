var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "SanctionsTitle",
            method: _vm.searchMethod,
            filters: _vm.filters,
            "required-filters": _vm.requiredFilters,
            disabled: _vm.isLoading || _vm.filters.searchTerm === "",
            "disable-search-on-load": !_vm.$route.query.searchTerm,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            attrs: {
              placeholder: "HeaderNameSurnameLegalNamePlaceholder",
              icon: "la-bank",
              wide: "",
              disabled: _vm.isLoading || _vm.showNotification,
            },
            model: {
              value: _vm.filters.searchTerm,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "searchTerm", $$v)
              },
              expression: "filters.searchTerm",
            },
          }),
          _vm._v(" "),
          _c("ui-header-input", {
            attrs: {
              placeholder: "HeaderDOBPlaceholder",
              medium: "",
              disabled: _vm.isLoading || _vm.showNotification,
            },
            model: {
              value: _vm.filters.birthYear,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "birthYear", $$v)
              },
              expression: "filters.birthYear",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.options,
              small: "",
              placeholder: "HeaderFuzzynessPlaceholder",
              disabled: _vm.showNotification,
            },
            model: {
              value: _vm.filters.fuzziness,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "fuzziness", $$v)
              },
              expression: "filters.fuzziness",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _vm.showNotification
            ? _c("sanction-notification")
            : _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.isLoading
                    ? _c(
                        "div",
                        { staticClass: "col-md-12 col-lg-12" },
                        [_c("ui-loader", { attrs: { size: 3, center: "" } })],
                        1
                      )
                    : [
                        _vm.clickedSearch
                          ? _c(
                              "div",
                              { staticClass: "details-sanctions-search-title" },
                              [
                                _c("div", { staticClass: "doc-export-block" }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("SanctionSearchTopBarTitle")
                                      ) +
                                        ' "' +
                                        _vm._s(_vm.searchName) +
                                        '"'
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("SanctionSearchTopBarMatches")
                                        ) +
                                        ":\n              "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.itemsCount)),
                                    ]),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("SanctionSearchTopBarSorted")
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("SearchedIn")) +
                                        "\n              " +
                                        _vm._s(
                                          _vm.finalFuzzinessPercent ||
                                            _vm.filters.fuzziness * 100
                                        ) +
                                        "%\n            "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "button-max-height" },
                                  [
                                    _c("ui-dropdown", {
                                      staticClass: "margin-right",
                                      attrs: {
                                        options: _vm.exportOptions,
                                        placeholder: "AppExport",
                                        small: "",
                                        metal: "",
                                      },
                                      on: { input: _vm.exportList },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-12 santions-list" },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              _vm._l(_vm.sanctions, function (sanction) {
                                return _c(
                                  "div",
                                  {
                                    key: sanction.id,
                                    staticClass:
                                      "col-md-6 col-lg-6 col-xl-3 santions-list-item",
                                  },
                                  [
                                    _c(
                                      "ui-card",
                                      {
                                        attrs: { title: sanction.entity_type },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            _c("details-item-full-width", {
                                              attrs: {
                                                label: sanction.fullname,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            sanction.types &&
                                            sanction.types.length > 0
                                              ? _c("details-item-with-tags", {
                                                  attrs: {
                                                    label: "DetailsMatched",
                                                    values: sanction.types,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            sanction.match_types &&
                                            sanction.match_types.length > 0
                                              ? _c("details-item-with-tags", {
                                                  attrs: {
                                                    label: "DetailsRelevance",
                                                    values:
                                                      sanction.match_types,
                                                    "no-style-tabs": "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        sanction.associates &&
                                        sanction.associates.length > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "row" },
                                              [
                                                _c("details-item-with-tags", {
                                                  attrs: {
                                                    label: "DetailsAssociates",
                                                    values: sanction.associates,
                                                    "no-style-tabs": "",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            sanction.countries &&
                                            sanction.countries.length > 0
                                              ? _c("details-item-with-tags", {
                                                  attrs: {
                                                    label: "DetailsCountries",
                                                    values: sanction.countries,
                                                    "no-style-tabs": "",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "row" },
                                          [
                                            sanction.dob
                                              ? _c("details-item-simple-text", {
                                                  attrs: {
                                                    label: "DetailsDOB",
                                                    value: sanction.dob,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "absolute-button" },
                                          [
                                            _c(
                                              "ui-link",
                                              {
                                                staticClass:
                                                  "ui-button btn m-btn btn-brand big-link m-btn--wide m-btn--md m-btn--mt15",
                                                attrs: {
                                                  to: {
                                                    name: "SanctionDetails",
                                                    params: {
                                                      sanctionId: sanction.id,
                                                      fuzzinessPercent:
                                                        _vm.finalFuzzinessPercent,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.$t("Details")) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "ui-link",
                                              {
                                                staticClass:
                                                  "ui-button btn m-btn btn-metal big-link m-btn--wide m-btn--md m-btn--mt15",
                                                attrs: {
                                                  to: {
                                                    name: "SanctionDetails",
                                                    params: {
                                                      sanctionId: sanction.id,
                                                      fuzzinessPercent:
                                                        _vm.finalFuzzinessPercent,
                                                      export: true,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("AppExport")
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.itemsCount === 0 &&
                            _vm.searchName &&
                            _vm.searchName.length > 0
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-center doc-export-block2",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t("PersonNotFoundSanction")
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ],
                ],
                2
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }