import mutations from '@src/store/mutations'
import { actions as kyb } from '@src/store/kyb'

export const methods = {
  updateSession,
  updateSettings,
  updateHistory,
  updateCompany,
  updateSantions,
  updateProgress,
  updateHideLogs,
  updateSessionTimeNotify,
  updateKyb,
  updateStopList,
  updateRegistriesList,
  ...kyb
}

export default Object.keys(methods).reduce(
  (result, item) => ({
    ...result,
    [item]: item
  }),
  {}
)

function updateSession({ commit }) {
  commit(mutations.changeSession)
}

function updateSettings({ commit }, newValue) {
  commit(mutations.changeSettings, newValue || {})
}

function updateRegistriesList({ commit }, newValue) {
  commit(mutations.changeRegistriesList, newValue || {})
}

function updateHistory({ commit, state }, newValue) {
  if (!newValue || !newValue.key || !newValue.value) return
  const newHistory = {
    ...state.history,
    [newValue.key]: newValue.value
  }
  commit(mutations.changeHistory, newHistory)
}

function updateCompany({ commit }, newValue) {
  commit(mutations.changeCompany, newValue)
}

function updateSantions({ commit }, newValue) {
  commit(mutations.changeSanctions, newValue)
}

function updateProgress({ commit }, newValue) {
  commit(mutations.changeProgress, newValue)
}

function updateHideLogs({ commit }, newValue) {
  commit(mutations.updateHideLogs, newValue)
}

function updateSessionTimeNotify({ commit }, newValue) {
  commit(mutations.updateSessionTimeNotify, newValue)
}

function updateKyb({ commit }, newValue) {
  commit(mutations.changeKyb, newValue)
}

function updateStopList({ commit }, newValue) {
  commit(mutations.changeStopList, newValue)
}
