<template>
  <div class="buttons-wrapper">
    <div>
      <ui-button
        v-show="editEnabled"
        class="btn-brand"
        icon="la la-check"
        :disabled="disabled"
      >
        {{ $t('AppSave') }}
      </ui-button>
      <ui-button
        v-show="!editEnabled"
        type="button"
        class="btn-brand"
        icon="flaticon-edit-1"
        @click="$emit('edit')"
      >
        {{ $t('AppEdit') }}
      </ui-button>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editEnabled: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
.buttons-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media (max-width: 568px) {
  .buttons-wrapper {
    gap: 10px;
    text-align: center;
    justify-content: center;
  }
}
</style>
