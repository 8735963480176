var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-drop-down",
      class: { narrow: _vm.narrow, center: _vm.center },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "col-form-label" }, [
            _vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "value-box" }, [
        _c(
          "select",
          {
            ref: "dropDown",
            attrs: { disabled: _vm.disabled },
            domProps: { value: _vm.value },
            on: { input: _vm.valueSelected },
          },
          [
            _c(
              "option",
              {
                attrs: { hidden: !_vm.nullable, selected: "" },
                domProps: { value: undefined },
              },
              [_vm._v("-")]
            ),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        ),
        _vm._v(" "),
        _c("span", { staticClass: "drop-down-label" }, [
          _vm._v("\n      " + _vm._s(_vm.labelTranslation) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }