<template>
  <div v-if="!isLoading" class="statements">
    <template
      v-if="
        companyCountryCode === uboLoadMoreListIso2.EE ||
        companyCountryCode === uboLoadMoreListIso2.ES ||
        companyCountryCode === uboLoadMoreListIso2.LV
      "
    >
      <span class="export-count">
        {{ $t('KybNumberOfDownloads') }}
        {{ shareholdersDownloadCount }}
      </span>

      <ui-widget title="KybBusinessRegistryTitle">
        <div class="export-item">
          <span>{{ $t('KybBusinessRegistryExtract') }}</span>
          <span>
            <ui-button
              class="btn-brand button-width"
              small
              low
              @click="downloadPdf"
            >
              {{ $t('AppExport') }}
            </ui-button>
          </span>
        </div>
      </ui-widget>
    </template>
    <template v-else>
      <span v-if="companyReportCount" class="export-count">
        {{ $t('CompanyStatementsCount') }}: {{ companyReportCount }}
      </span>
      <span
        v-if="
          financialReportCount && $route.name !== 'LegalEntityMonitoringEvents'
        "
        class="export-count"
      >
        {{ $t('FinancialStatementsCount') }}: {{ financialReportCount }}
      </span>
      <span
        v-if="
          scorifyStatementsCount &&
          $route.name !== 'LegalEntityMonitoringEvents'
        "
        class="export-count"
      >
        {{ $t('ScorifyStatementsCount') }}: {{ scorifyStatementsCount }}
      </span>
      <ui-widget
        v-if="enabledRegistryItems.length > 0"
        title="KybBusinessRegistry"
      >
        <div
          v-for="(item, index) in enabledRegistryItems"
          :key="index"
          class="export-item"
          :class="{ 'export-item--has-date': item.lastDownloadDate }"
        >
          <span>{{ $t(item.title) }}</span>
          <span>
            <ui-button
              class="btn-brand button-width"
              small
              low
              @click="handleDownload(item)"
            >
              {{ $t(item.buttonTitle) }}
            </ui-button>
            <div v-if="item.lastDownloadDate" class="export-item__date">
              {{ `${$t('DashboardViewed')} ${item.lastDownloadDate}` }}
            </div>
          </span>
        </div>
      </ui-widget>
      <ui-widget
        v-if="
          hasStatementAccess && $route.name !== 'LegalEntityMonitoringEvents'
        "
        title="KybFinancialStatements"
      >
        <div
          v-for="(item, index) in statements"
          :key="index"
          class="export-item"
        >
          <span>{{ $t(item.title) }}</span>
          <span v-if="item.buttonTitle !== 'AppExport'">
            <ui-button
              class="btn-brand button-width"
              small
              low
              @click="LoginKyb(item.url)"
            >
              {{ $t(item.buttonTitle) }}
            </ui-button>
          </span>
          <span v-else class="icon-block">
            <i
              class="flaticon-file-pdf-file-format-symbol"
              @click="exportStatement(item, 'pdf', `${item.url}/pdf`)"
            />
            <i
              class="flaticon-file-xlsx-file-format-extension"
              @click="exportStatement(item, 'xlsx', `${item.url}/xlsx`)"
            />
          </span>
        </div>
      </ui-widget>
      <ui-widget v-if="!noOtherDocuments" title="KybOtherDocuments">
        <div v-if="hasScorifyAccess" class="export-item">
          <span>{{ $t('KybExportScorifyStatement') }}</span>
          <span>
            <ui-button
              class="btn-brand button-width"
              small
              low
              @click="downloadScorify"
            >
              {{ $t('AppExport') }}
            </ui-button>
          </span>
        </div>
        <div
          v-for="(item, index) in extraStatements"
          :key="index"
          class="export-item"
        >
          <span>{{ $t(item.title) }}</span>
          <span v-if="item.buttonTitle !== 'AppExport'">
            <ui-button
              class="btn-brand button-width"
              small
              low
              @click="LoginKyb(item.url)"
            >
              {{ $t(item.buttonTitle) }}
            </ui-button>
          </span>
          <span v-else class="icon-block">
            <i
              class="flaticon-file-pdf-file-format-symbol"
              @click="exportStatement(item, 'pdf', `${item.url}/pdf`)"
            />
            <i
              class="flaticon-file-xlsx-file-format-extension"
              @click="exportStatement(item, 'xlsx', `${item.url}/xlsx`)"
            />
          </span>
        </div>
      </ui-widget>
    </template>
  </div>
  <div v-else class="loader">
    <ui-loader :size="3" center />
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import getters from '@src/store/getters'
import {
  downloadBlob,
  replaceAll,
  base64ToPdfDownload
} from '@src/scripts/helpers'
import { mapGetters } from 'vuex'
import { pageTypeEnum, uboLoadMoreListIso2 } from '@src/scripts/enums'
import { dateToString } from '@src/scripts/helpers'
import { JADIS_STATUS } from '@src/scripts/constants'

export default {
  props: {
    companyCountryCode: { type: String, default: undefined },
    companyCode: { type: String, default: undefined, required: true },
    noOtherDocuments: { type: Boolean, default: undefined, required: false }
  },

  data() {
    return {
      uboLoadMoreListIso2,
      isLoading: false,
      companyReportCount: null,
      financialReportCount: null,
      scorifyStatementsCount: null,
      lastDownloadDate: null,
      statements: null,
      isProd: IsProduction,
      shareholdersDownloadCount: 0
    }
  },

  computed: {
    ...mapGetters([getters.selectedCompany, getters.userPages]),
    hasStatementAccess() {
      return this.userPages?.includes(pageTypeEnum.kybJarFinancialReport)
    },
    hasScorifyAccess() {
      return this.userPages?.includes(pageTypeEnum.kybScorify)
    },
    registryItems() {
      const code = this.companyCode.trim()
      const { selectedCompany: companyId } = this

      return [
        {
          disabled: !this.userPages?.includes(pageTypeEnum.kybJarCompanyReport),
          title: 'ExportJar',
          buttonTitle: 'AppExport',
          fileName: `${code}-JAR-statement`,
          url: '/kyb/jar-company-report',
          type: 'html',
          body: { companyId, code }
        },
        {
          disabled: !this.userPages?.includes(pageTypeEnum.kybJadis),
          title: 'ExportJadis',
          fileName: `${code}-JADIS-statement`,
          url: '/kyb/jadis',
          type: 'pdf',
          body: { companyCode: code },
          buttonTitle: 'AppExport',
          lastDownloadDate: this.lastDownloadDate
        },
        {
          disabled: !this.userPages?.includes(
            pageTypeEnum.kybJangisFinancialReport
          ),
          title: 'ExportJangis',
          fileName: `${code}-JANGIS-statement`,
          url: '/kyb/jangis',
          type: 'pdf',
          body: { companyCode: code, countryCode: this.companyCountryCode },
          buttonTitle: 'AppExport',
          lastDownloadDate: this.lastDownloadDate
        }
      ]
    },
    extraStatements() {
      const kybTitles = [
        'KybReviewOfExplanatoryNote',
        'KybReviewOfAuditReport',
        'KybReviewOfAuditorReport',
        'KybAnnualReportReview',
        'KybReviewOfTheStatementOfChangesInEquity',
        'KybPersonalSearchByCode'
      ]
      return kybTitles.map((title) => ({
        title: title,
        url: 'http://www.registrucentras.lt/savitarna/',
        buttonTitle: 'AppLogin'
      }))
    },
    enabledRegistryItems() {
      return this.registryItems.filter(({ disabled }) => !disabled)
    }
  },

  async created() {
    if (this.hasStatementAccess) {
      if (this.companyCountryCode === uboLoadMoreListIso2.LT) {
        this.isLoading = true
        this.statements = await this.getStatements(
          this.selectedCompany,
          this.companyCode.trim()
        )
        this.isLoading = false
      } else {
        await this.getUsage()
      }
    }
  },

  methods: {
    LoginKyb(url) {
      window.open(url)
    },

    async getUsage() {
      try {
        this.isLoading = true
        const res = await Api.get('/kyb/usage', {
          Country: this.companyCountryCode
        })
        this.shareholdersDownloadCount = res.usage.shareholders
        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    },

    async downloadPdf() {
      try {
        this.isLoading = true
        const data = await Api.getBaseUrlBlob(
          `/kyb/shareholders-pdf-report?Country=${this.companyCountryCode}&CompanyCode=${this.companyCode}`
        )
        downloadBlob({ file: data, fileName: 'report.pdf' })
        await this.getUsage()
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.showError()
      }
    },

    handleDownload(item) {
      if (item.buttonTitle === 'AppExport') {
        return item.title === 'ExportJadis'
          ? this.exportJadisStatement(item, item.type, item.url)
          : this.exportStatement(item, item.type, item.url)
      }
      return this.LoginKyb(item.url)
    },

    async downloadScorify() {
      try {
        const { error, details } = await Api.post('/kyb/scorify', {
          code: this.companyCode
        })
        if (!error) {
          window.open(details && details.responsePdfUrl)
        }
      } catch (error) {
        return null
      }
    },

    handleError(type) {
      const errObj = { title: 'ErrorUnknownTitle' }

      switch (type) {
        case JADIS_STATUS.notFound:
          errObj.message = 'ErrorJadisNotFound'
          break
        case JADIS_STATUS.providerError:
          errObj.message = 'ErrorJadisProviderError'
          break
        default:
          errObj.message = 'ErrorUnknownDescription'
      }
      this.$notify(errObj)
    },

    async exportStatement(details, type, url) {
      this.isLoading = true
      try {
        const result = await Api.getBlobWithParams(url, details.body)
        const { file } = result
        downloadBlob({
          file,
          fileName: `${details.fileName}.${type}`
        })
      } catch (error) {
        this.showError()
      }
      this.isLoading = false
    },

    async exportJadisStatement(details, type, url) {
      this.isLoading = true
      try {
        const data = await Api.post(url, details.body)

        if (data.status !== JADIS_STATUS.success) {
          this.handleError(data.status)
          this.isLoading = false
          return
        }

        const { file } = data
        base64ToPdfDownload(file, `${details.fileName}.${type}`)
      } catch (error) {
        this.showError()
      }
      this.isLoading = false
    },

    async getStatements(companyId, companyCode) {
      try {
        const { error, data } = await Api.post(
          '/kyb/jar-financial-report-list',
          {
            companyId,
            companyCode
          }
        )
        if (error) {
          this.showError()
          return null
        }

        this.lastDownloadDate = data.lastDownloadDate
          ? dateToString(data.lastDownloadDate, true)
          : null

        if (data.currentMonthReportCount) {
          this.companyReportCount = data.currentMonthReportCount.company
          this.financialReportCount = data.currentMonthReportCount.financial
          this.scorifyStatementsCount = data.currentMonthReportCount.scorify
        }
        return this.mapFinancialStatements(companyCode, data)
      } catch (error) {
        this.showError()
        return null
      }
    },

    mapFinancialStatements(companyCode, data) {
      const { reports, link } = data

      return reports.map((item) => {
        const title = `${item.year}, ${item.category} ${item.type}`
        const normalizedTitle = replaceAll(title, ' ', '-')
        return {
          title: title,
          buttonTitle: 'AppExport',
          fileName: `${companyCode}-${normalizedTitle}`,
          url: link,
          body: { code: item.number }
        }
      })
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.export-item {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.export-item > span {
  margin-right: 1rem;
  margin-top: auto;
  margin-bottom: auto;
}

.export-item--has-date span:first-of-type {
  margin-top: 0;
  margin-bottom: 0;
  flex: 3;
}

.export-item--has-date span:last-of-type {
  flex: 1;
}

.export-item__date {
  color: var(--brand);
  font-size: 0.68rem;
}

.export-count {
  font-weight: 600;
  display: block;
}

.button-width {
  min-width: 100px;
}

.loader {
  width: 250px;
  height: 250px;
  padding: 1rem;
}

.statements .m-widget1 {
  padding-bottom: 0;
}

.icon-block {
  display: flex;
  flex-flow: row nowrap;
}

.icon-block > *:first-child {
  margin-right: 0.5rem;
}

.icon-block i::before {
  font-size: 1.75rem;
  color: var(--brand);
  cursor: pointer;
}
</style>
