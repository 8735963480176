var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.values
    ? _c("tr", { staticClass: "chart-data-item-mobile" }, [
        _c(
          "td",
          [
            _c("div", { staticClass: "position-relative" }, [
              _c("b", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
              _vm._v(" "),
              _vm.description
                ? _c("i", { staticClass: "indicator-info la la-info-circle" }, [
                    _c("div", { staticClass: "indicator-info-details" }, [
                      _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t(_vm.description)) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm._l(_vm.labels, function (label, index) {
              return _c("div", { key: index }, [
                _c("span", [_vm._v(_vm._s(label) + ": ")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.values[index]))]),
              ])
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }