<template>
  <div class="box-with-header" :class="{ 'fixed-height': fixed }">
    <div v-if="!noHeader" class="sticky-header">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    fixed: Boolean,
    noHeader: Boolean
  }
}
</script>

<style scoped>
.fixed-height .m-content {
  height: calc(100% - 15px);
}

.sticky-header {
  display: flex;
  padding: 1rem;
  width: 100%;
  background: var(--white);
  top: 90px;
  position: sticky;
  z-index: 1;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.1);
}

.fixed-height .sticky-header {
  min-height: 66px;
}

.sticky-header > * {
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .sticky-header {
    top: 60px;
  }

  .fixed-height .m-content {
    height: calc(100% - 69px);
  }
}
</style>

<style>
.box-with-header.fixed-height .m-content > * {
  height: 100%;
}

@media screen and (max-width: 1025px) and (min-width: 767px) {
  .m-grid__item.m-grid__item--fluid {
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0;
    min-width: 0;
  }

  .m-grid.m-grid--ver-desktop.m-grid--desktop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
</style>
