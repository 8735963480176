<template>
  <div>
    <div v-if="isNoMrzFound && !editEnabled">{{ $t('Mrz.FailedToRead') }}</div>
    <mrz-lines-list
      :edit-enabled="editEnabled"
      :field-values="fieldValues"
      :action-in-progress="false"
      :document-updating="false"
      :mrz-lines-count="mrzLinesCount"
    />
  </div>
</template>

<script>
export const MRZ_FIELDS = {
  MRZ_1: 'mrzLine1',
  MRZ_2: 'mrzLine2',
  MRZ_3: 'mrzLine3'
}

import MrzLinesList from './MrzLinesList'

export default {
  components: {
    MrzLinesList
  },
  props: {
    currentIdentification: { type: Object, default: null },
    documentUpdating: { type: Boolean, default: false },
    editEnabled: { type: Boolean, default: false },
    fieldValues: { type: Object, default: null },
    actionInProgress: { type: Boolean, default: false }
  },

  data() {
    return {
      MRZ_FIELDS,
      originalFieldValues: null,
      document: null,
      initialDocument: null
    }
  },

  computed: {
    mrzLinesCount() {
      return {
        [MRZ_FIELDS.MRZ_1]: this.fieldValues[MRZ_FIELDS.MRZ_1]?.length,
        [MRZ_FIELDS.MRZ_2]: this.fieldValues[MRZ_FIELDS.MRZ_2]?.length,
        [MRZ_FIELDS.MRZ_3]: this.fieldValues[MRZ_FIELDS.MRZ_3]?.length
      }
    },

    isNoMrzFound() {
      return !Object.values(this.mrzLinesCount).some((value) => value > 0)
    }
  }
}
</script>
