<template>
  <li
    class="m-nav__item m-topbar__user-profile m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    :class="{ 'dropdown-open': isOpened }"
  >
    <a class="m-nav__link m-dropdown__toggle" @click="open">
      <span
        v-if="notOpened || newEvent"
        :class="{ 'm-badge--danger': newEvent }"
        class="m-nav__link-badge m-badge m-badge--dot-small"
      />
      <span class="m-nav__link-icon" :class="{ 'm-topbar__usericon': dark }">
        <span class="icon-wrapper">
          <i :class="icon" />
          <div
            v-if="notificationDataHistory && notificationDataHistory.length > 0"
            class="notifications-count"
          >
            {{ notificationDataHistory.length }}
          </div>
        </span>
      </span>
    </a>
    <div
      id="m-dropdown__wrapper"
      class="m-dropdown__wrapper"
      :class="{ 'is-opened-dropdown': isOpened }"
    >
      <span class="m-dropdown__arrow" :style="styles" />
      <div class="m-dropdown__inner">
        <div class="m--align-center">
          <div
            v-if="notificationDataHistory && notificationDataHistory.length > 0"
            class="notification-block"
          >
            <div class="remove-all">
              <span @click="removeAllNotification">
                {{ $t('RemoveAll') }}
              </span>
            </div>
            <div
              v-for="item in notificationDataHistory"
              :key="item.id"
              class="m-nav__item content"
              :class="item.type"
            >
              <a class="close" @click="removeNotification(item)" />
              <strong>{{ $t(getTitle(item.title)) }}</strong>
              <span class="small-text">
                {{ item.time | date(true) }}
              </span>
              <p>
                {{ getMessage(item) }}
                <strong class="display-block">
                  {{ $t(getEvent(item)) }}
                </strong>
                <a class="location-text" :href="item.location" target="_blank">
                  {{ item.location }}
                </a>
              </p>
            </div>
          </div>
          <p v-else class="margin-top-1">
            {{ $t('NoLogs') }}
          </p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { notificationDataHistory } from '@src/components/notification'
import { dateToString } from '@src/scripts/helpers'
export default {
  filters: {
    date: dateToString
  },

  props: {
    icon: { type: String, default: undefined },
    index: { type: Number, default: undefined },
    dark: Boolean,
    notOpened: Boolean,
    newEvent: Boolean
  },

  data() {
    return {
      isOpened: false,
      notificationDataHistory: notificationDataHistory.notificatios
    }
  },

  computed: {
    styles() {
      const { index } = this
      if (index) {
        const rightMargin = 52 * index + 4.5
        return { right: `${rightMargin}px` }
      }
      return null
    }
  },

  methods: {
    getTitle(title) {
      if (title === 'warningSaveChanges') {
        return `NotificationsBar${title}`
      } else return title
    },

    getMessage(item) {
      if (item.message === 'warningSaveChangesMessage') {
        return this.$t(`NotificationsBar${item.message}`)
      } else if (typeof item.message === 'object') {
        return item.message
          .map((item) => this.$t(item, item.messageData))
          .join('; ')
      } else return this.$t(item.message, item.messageData)
    },

    getEvent(item) {
      if (item.title && item.title !== 'warningSaveChanges' && item.event) {
        return item.event
      } else return null
    },

    clickOutside(e) {
      this.close(e)
    },

    open() {
      document.body.addEventListener('click', this.clickOutside)
    },

    close(e) {
      if (
        e.target.className.indexOf(
          'ui-button btn m-btn dropdown-toggle btn-sm btn-low btn-brand'
        ) !== -1 ||
        e.target.className.indexOf('btn-content') !== -1 ||
        (e.target.className.indexOf('btn-text') !== -1 &&
          e.target.className.indexOf('btn-text-box') === -1)
      ) {
        this.isOpened = true
      } else if (
        document.querySelector('.is-opened-dropdown') &&
        this.isOpened
      ) {
        document.querySelectorAll('#m-dropdown__wrapper').forEach((item) => {
          item.classList.remove('is-opened-dropdown')
        })
        this.isOpened = false
        document.body.removeEventListener('click', this.clickOutside)
      } else {
        this.isOpened = true
      }
    },

    removeNotification(item) {
      const { notificatios } = notificationDataHistory
      const itemIndex = notificatios.indexOf(item)
      if (itemIndex !== -1) {
        notificatios.splice(itemIndex, 1)
      }
    },

    removeAllNotification() {
      const { notificatios } = notificationDataHistory
      notificatios.splice(0, notificatios.length)
    }
  }
}
</script>

<style scoped>
.m-nav__link-icon {
  position: relative;
}

.icon-wrapper {
  border: 2px solid var(--brand);
  border-radius: 50%;
  color: var(--brand);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  font-size: 1.4rem;
  position: relative;
}

.icon-wrapper:hover {
  background-color: var(--brand);
  color: var(--white);
}

.notification-block {
  margin-top: 2rem;
}

.notification-block > div.content {
  padding: 1rem;
  word-break: break-word;
}

.notification-block > div:not(:last-child) {
  border-bottom: 1px solid var(--border-bottom);
}

.notification-block p {
  padding: 0;
  margin: 0;
}

.remove-all {
  background-color: var(--white);
  text-align: right;
  padding: 0.25rem 0.75rem 0.25rem 0.25rem;
  color: var(--danger);
  position: fixed;
  top: 0.75rem;
  width: 100%;
  z-index: 10;
}

.remove-all > span {
  cursor: pointer;
}

.remove-all > span:hover {
  text-decoration: underline;
}

.display-block {
  display: block;
}

.margin-top-1 {
  margin-top: 1rem;
}

.notifications-count {
  background-color: var(--brand);
  color: var(--white);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -0.5rem;
  font-size: 0.65rem;
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  box-shadow: 0 0 3px 1px rgba(69, 65, 78, 0.3);
}

/* .icon-wrapper:hover ~ .notifications-count {
  border: 2px solid var(--white);
} */

.content {
  position: relative;
}

.content .close {
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  width: 1rem;
  height: 1rem;
}

.content .close::before,
.content .close::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: var(--input-font);
}

.content .close::before {
  transform: rotateZ(45deg);
}

.content .close::after {
  transform: rotateZ(-45deg);
}

.m-nav__item.info {
  background-color: #e7f9ff;
}

.m-nav__item.info-with-sound {
  background-color: #e7f9ff;
}

.m-nav__item.error {
  background-color: #ffddd5;
}

.m-nav__item.time-left {
  background-color: #ffddd5;
}

.m-nav__item.warning {
  background-color: #fff;
}

.small-text {
  font-size: 0.75rem;
  display: block;
}

.location-text {
  font-size: 0.625rem;
  display: block;
}

#m-dropdown__wrapper.is-opened-dropdown {
  display: block;
}

.m-nav__item:hover {
  cursor: pointer;
}

.m-dropdown__wrapper {
  z-index: 101;
  cursor: default;
}

.m-nav__item.dropdown-open > .m-dropdown__wrapper {
  display: none;
  -webkit-animation: m-dropdown-fade-in 0.3s ease 1,
    m-dropdown-move-up 0.3s ease-out 1;
  animation: m-dropdown-fade-in 0.3s ease 1, m-dropdown-move-up 0.3s ease-out 1;
}

.m-dropdown__wrapper .m-dropdown__arrow {
  left: auto;
  right: 4.5px;
  z-index: 20;
}

.m-dropdown__wrapper .m-dropdown__body li {
  cursor: initial;
}

@media (max-width: 678px) {
  .m-dropdown__wrapper .m-dropdown__arrow {
    display: none;
  }
}
</style>

<style>
.m-nav__item .m-dropdown__wrapper .m-dropdown__inner {
  max-height: 400px;
  overflow: auto;
}
</style>
