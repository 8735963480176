<template>
  <div class="stoplist-page">
    <ui-header
      title="Stoplist.Title"
      :method="searchMethod"
      :method-get="true"
      :filters="filters"
      :class="{ 'fixed-header': scrollDown }"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div class="filters-block">
        <div class="all-search-inputs">
          <ui-header-input
            v-model="filters.firstName"
            type="text"
            placeholder="TableFirstName"
            medium
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.lastName"
            type="text"
            placeholder="TableLastName"
            medium
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.personalCode"
            type="email"
            placeholder="DetailsPersonCode"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="filters.country"
            all
            has-search
            class="countries-dropdown"
            key-value-dropdown
            :options="countriesOptions"
            medium
            placeholder="DetailsCountry"
          />
        </div>
      </div>
    </ui-header>
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <template v-if="!isLoading && stoplists">
          <ui-table
            :labels="tableLabels"
            :data="stoplists"
            class="export-table hide-mobile"
            empty-text="TableNoStoplists"
          >
            <template>
              <tr
                v-for="(row, index) in stoplists"
                :key="index"
                class="m-table__row"
                :class="classList(row)"
              >
                <td class="fixed-width" :title="getFullName(row)">
                  {{ getFullName(row) }}
                </td>
                <td class="fixed-width" :title="row.createdUtc">
                  {{ getFormattedDate(row.createdUtc) }}
                </td>
                <td class="fixed-width" :title="row.reportUserEmail">
                  {{ row.reportUserEmail }}
                </td>
                <td class="td-link">
                  <ui-button
                    class="btn-metal big-link"
                    small-text
                    target="_blank"
                    @click="clickRemove(row.id)"
                  >
                    {{ $t('Stoplist.Remove') }}
                  </ui-button>
                  <ui-link
                    :to="{
                      name: 'IdentificationDetails',
                      params: {
                        identificationId:
                          row.referenceId && row.referenceId.replaceAll('-', '')
                      }
                    }"
                    class="btn-brand"
                    small-text
                  >
                    {{ $t('TableView') }}
                  </ui-link>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="stoplists && stoplists.length > 0">
            <div
              v-for="(row, index) in stoplists"
              :key="index"
              class="response-table show-mobile"
              :class="classList(row)"
            >
              <responsive-table-tr
                title="TableFullName"
                :value="getFullName(row)"
              />
              <responsive-table-tr
                title="TableDateAdded"
                :value="getFormattedDate(row.createdUtc)"
              />
              <responsive-table-tr
                title="TableAddedBy"
                :value="row.reportUserEmail"
              />
              <div class="m--margin-top-10 remove-button-container">
                <ui-button
                  class="btn-metal big-link"
                  small-text
                  target="_blank"
                  @click="clickRemove(row.id)"
                >
                  {{ $t('Stoplist.Remove') }}
                </ui-button>
                <ui-link
                  :to="{
                    name: 'IdentificationDetails',
                    params: {
                      identificationId:
                        row.referenceId && row.referenceId.replaceAll('-', '')
                    }
                  }"
                  class="btn-brand"
                  small-text
                >
                  {{ $t('TableView') }}
                </ui-link>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('TableNoIdentifications') }}
          </div>
        </template>
        <ui-loader v-else :size="4" center />
        <ui-page-selector
          :active-page="filters.page"
          :items-count="itemsCount"
          :max-on-page="filters.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { mapOptions, downloadBlob } from '@src/scripts/helpers'
import { exportPdf } from '@src/scripts/exportPdf'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import { notificationType } from '@src/components/notification'
import { copy } from '@src/scripts/clipboard'
import UiButton from '../ui/UiButton.vue'
import actions from '@src/store/actions'
import { kycRequestStatuses } from '@src/scripts/enums'
import { dateToStringGet } from '@src/scripts/helpers'

const filters = () => ({
  firstName: null,
  lastName: null,
  personalCode: null,
  country: null
})

const options = () => ({
  dateOptions: null,
  reviewStatusOptions: null,
  requestStatusOptions: null
})

export default {
  components: {
    ResponsiveTableTr,
    UiButton
  },

  data() {
    return {
      openFullSearch: false,
      scrollDown: false,
      lastScrollTop: 180,
      isReady: false,
      isLoading: true,
      isDownloading: false,
      stoplists: null,
      identifications: null,
      itemsCount: 0,
      searchMethod: '/stop-list/natural-entities',
      filters: filters(),
      options: options(),
      tableLabels: ['TableFullName', 'TableDateAdded', 'TableAddedBy', null],
      itemsHistoryCount: null,
      isOldTitle: true,
      interval: null,
      newIdentificationsCount: null,
      oldTitle: DefaultTitle
    }
  },

  computed: {
    countriesOptions() {
      return mapOptions(this.nationalities)
    },

    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities
    },

    fullSearchText() {
      if (this.openFullSearch) return 'HideDetailedSearchText'
      else return 'DetailedSearchText'
    },

    documentTypes() {
      const { documentTypes } = this.$store.getters
      return mapOptions(documentTypes)
    },

    isSuperAdmin() {
      return true
    }
  },

  watch: {
    itemsCount(newValue) {
      this.newIdentificationsCount = newValue - this.itemsHistoryCount
      if (this.itemsHistoryCount !== null && this.newIdentificationsCount > 0) {
        this.$notify({
          type: 'info-with-sound',
          message: 'InfoNewIdentification',
          messageData: { messageData: this.newIdentificationsCount }
        })
        clearInterval(this.interval)
        this.interval = setInterval(this.changeTitle, 700)
        document.addEventListener('mouseover', this.clearInt)
      }
      this.itemsHistoryCount = newValue
    },

    filters: {
      deep: true,
      handler() {
        this.itemsHistoryCount = null
      }
    }
  },

  created() {
    this.isReady = true
  },

  methods: {
    async clickRemove(id) {
      try {
        await Api.del(`/stop-list/natural-entities/${id}`)
        this.$notify({
          type: notificationType.info,
          title: 'Deleted'
        })

        const updatedList = this.stoplists.filter(
          (stoplist) => stoplist.id !== id
        )
        this.stoplists = updatedList
        this.$store.dispatch(actions.updateStopList, updatedList)
      } catch (error) {
        return null
      }
    },

    copyIdentificationId(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },

    classList(item) {
      const { isSuperAdmin } = this
      const list = []

      if (item.requestStatus === kycRequestStatuses.pending) {
        list.push('status-pending')
      } else if (item.requestStatus === kycRequestStatuses.rejected) {
        list.push('status-rejected')
      }

      if (isSuperAdmin && this.isTimeOver(item.finished, item.crossChecked)) {
        list.push('not-in-time')
      } else if (!item.crossChecked) {
        list.push('not-checked')
      }

      return list
    },

    changeTitle() {
      const newTitle = `${this.$t('InfoNewIdentification', {
        messageData: this.newIdentificationsCount
      })}`
      document.title = this.isOldTitle ? this.oldTitle : newTitle
      this.isOldTitle = !this.isOldTitle
    },

    clearInt() {
      clearInterval(this.interval)
      document.title = this.oldTitle
      document.removeEventListener('mouseover', this.clearInt)
    },

    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      this.stoplists = data.data
      this.count = null
      this.itemsCount = data.totalCount
      this.isLoading = false
      this.$store.dispatch(actions.updateStopList, data.data)
    },

    getFullName(item) {
      const { firstName, lastName } = item
      return [firstName, lastName].filter((value) => value).join(' ')
    },

    getFormattedDate(date) {
      return dateToStringGet(date, true)
    },

    createPayload(filters) {
      return {
        companyId: this.companyId,
        personalCode: filters.personalCode || undefined,
        country: filters.country || undefined,
        firstName: filters.firstName || undefined
      }
    },

    isTimeOver(finishedDate, crossCheckedDate) {
      if (finishedDate && crossCheckedDate) {
        const firstDate = new Date(finishedDate)
        const secondDate = new Date(crossCheckedDate)
        if (secondDate < firstDate) {
          secondDate.setDate(secondDate.getDate() + 1)
        }

        const diff = secondDate - firstDate
        const diffMinutes = Math.floor(diff / 1000 / 60)
        if (diffMinutes > 3) return true
        else return false
      }
    },

    async exportList(value) {
      if (value === 'PDF') {
        this.exportPdfFormat()
      } else if (value === 'CSV') {
        await this.exportCsvFormat()
      } else if (value === 'XLS') {
        await this.exportCsvFormat()
      }
    },

    async exportPdfFormat() {
      try {
        const name = this.$store.getters.sessionData.profile.name
        const data = {
          tableLabels: [
            'TableFullName',
            'TableDateAdded',
            'TableAddedBy'
          ].map((item) => this.$t(item)),
          tableRows: this.stoplists.map((item) => [
            this.getFullName(item),
            item.createdUtc || '',
            item.reportUserEmail || ''
          ])
        }
        const exportFile = new exportPdf(data, name)
        await exportFile.exportTable()
      } catch (error) {
        this.showError()
      }
    },

    async exportCsvFormat() {
      const { filters, isReady, createPayload } = this
      if (!isReady) return

      try {
        this.isDownloading = true
        const payload = createPayload(filters)
        const blob = await Api.getBlobWithParams(
          '/identifications/download/csv',
          payload
        )
        downloadBlob(blob)
      } catch (error) {
        this.showError()
      }
      this.isDownloading = false
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.header-content {
  display: flex;
  align-content: center;
}

.countries-dropdown textarea {
  max-height: 42px;
}

.remove-button-container {
  margin: 0 1rem;
}

.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.cell-identification-id-desktop {
  max-width: 12vw;
}

.identification-id-desktop {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-header {
  margin-bottom: 4rem;
}

.all-search-inputs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}

.all-search-inputs > * {
  display: inline-block;
  width: 200px;
}

/* .all-search-inputs .m-input-icon {
  margin-bottom: 0;
} */

.all-search-inputs textarea.m-input {
  max-height: 42px;
}

.td-link {
  width: 18rem;
}

.td-link > * {
  margin-bottom: 0.5rem;
  min-width: 6rem;
}

.td-link > *:first-child {
  margin-right: 1rem;
}

.fullsearh-link {
  color: var(--white);
  margin-right: 15px;
  cursor: pointer;
}

.fullsearh-link:hover {
  text-decoration: underline;
}

.filter-small-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.fixed-header .filter-small-block {
  position: absolute;
  bottom: -2.75rem;
  left: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.fixed-header .fullsearh-link {
  left: 2rem;
  right: auto;
  color: var(--dark-br);
  bottom: 0.75rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.show-mobile {
  display: none;
}

.fixed-header .all-search-inputs > *::placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-webkit-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-moz-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-ms-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-moz-placeholder {
  color: var(--dark-br);
}

.filters-block {
  width: 100%;
}

@media all and (max-width: 992px) {
  .filter-small-block > * {
    display: inline-block;
  }

  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }

  .all-search-inputs {
    flex-flow: row wrap;
  }

  .all-search-inputs > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    max-width: 45%;
    width: 45%;
  }
}

@media (max-width: 678px) {
  .all-search-inputs {
    flex-direction: column;
  }

  .all-search-inputs > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
<style>
.identification-page .filter-small-block .m-checkbox--state-brand {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
  width: 400px;
}

.header-content .filters-block .m-input-icon.m-input-icon--wide {
  width: 200px;
}

.fixed-header .m-subheader-search__title,
.fixed-header .ui-toggle--header {
  visibility: hidden;
}

.fixed-header .header-content {
  position: fixed;
  top: 3.75rem;
  z-index: 50;
  left: 110px;
  background: var(--white);
  text-align: right;
  flex-wrap: nowrap;
  width: calc(100% - 110px);
  padding: 1.75rem 80px 2rem 30px;
  height: auto;
  box-shadow: 2px 3px 3px 0 var(--light-br-h-gray);
  align-items: center;
}

.fixed-header .header-content .filters-block {
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}

.fixed-header .header-content .filters-list {
  flex-wrap: nowrap;
}

.fixed-header .header-content .filters-list label {
  display: none;
}

.fixed-header .header-content .filters-list .ui-dropdown button,
.fixed-header .header-content .filters-list input {
  border-color: var(--dark-br);
  color: var(--dark-br);
  height: 42px;
}

.fixed-header .header-content .filters-list textarea {
  border-color: var(--dark-br);
  color: var(--dark-br);
}

.fixed-header .header-content .filters-list input::placeholder,
.fixed-header .header-content .filters-list textarea::placeholder {
  color: var(--dark-br);
}

.fixed-header .ui-button.m-subheader-search__submit-btn {
  border: 1px solid var(--brand);
  height: 42px;
}

@media (max-width: 1024px) {
  .fixed-header .header-content {
    visibility: hidden;
  }
}

@media (max-width: 992px) {
  .all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .identification-page .m-subheader-search .m-input-icon {
    margin-right: 0;
  }
}
</style>

<style>
.identification-page .not-in-time td.review-status,
.identification-page .not-in-time .review-status .tr-value {
  font-style: italic;
  color: red;
}

.identification-page .not-checked td.review-status,
.identification-page .not-checked .review-status .tr-value {
  font-weight: 700;
}
</style>
