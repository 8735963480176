<template>
  <ui-form class="form-container" @submit="createAgentSettings">
    <div v-if="isLoading" class="loader">
      <ui-loader :size="4" center />
    </div>

    <template v-else>
      <h4>{{ $t('AgentLink.Manage.Title') }}</h4>

      <div class="block input-block">
        <ui-input
          type="agent"
          label="AgentLink.Manage.AgentName"
          multiline
          one-line
          :value="form.agent"
          :error="errors.agent"
          :disabled="isLoading"
          @change="agentChanged"
        />
        <ui-input
          type="email"
          multiline
          one-line
          label="AgentLink.Manage.AgentEmail"
          :value="form.email"
          :error="errors.email"
          :disabled="isLoading"
          :max-length="255"
          @change="emailChanged"
        />
      </div>

      <div class="block button-block">
        <ui-button
          class="btn-brand col-lg-12 mx-auto"
          :disabled="isLoading"
          :loading="isLoading"
          small
        >
          {{ $t('AgentLink.Manage.Save') }}
        </ui-button>
      </div>
    </template>
  </ui-form>
</template>

<script>
import Api from '@src/scripts/api'
import {
  requiredValidator,
  emailValidator,
  validateForm
} from '@src/scripts/validators'

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      isLoading: false,
      inviteActive: true,
      form: {},
      errors: {
        email: 'ErrorFieldRequired',
        agent: 'ErrorFieldRequired'
      }
    }
  },

  created() {
    this.form.email = this.item.email ?? undefined
    this.form.agent = this.item.agent ?? undefined

    if (this.form.email) {
      this.emailChanged(this.form.email)
    }
    if (this.form.agent) {
      this.agentChanged(this.form.agent)
    }
  },

  methods: {
    emailChanged(value) {
      const { form, errors } = this
      errors.email = requiredValidator(value)
      if (!errors.email) errors.email = emailValidator(value)
      this.$set(form, 'email', value)
    },

    agentChanged(value) {
      const { form, errors } = this
      errors.agent = requiredValidator(value)
      this.$set(form, 'agent', value)
    },

    async createAgentSettings() {
      const { errors } = this
      if (!validateForm(errors)) return
      this.isLoading = true

      try {
        const {
          form: { email, agent }
        } = this

        const url = this.item
          ? `/agent-settings/${this.item.id}`
          : '/agent-settings'
        const method = this.item ? 'put' : 'post'
        await Api[method](url, {
          agentEmail: email.trim(),
          agentName: agent.trim()
        })
        this.$emit('updated', false)
      } catch (error) {
        this.showError()
      } finally {
        this.isLoading = false
      }
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 100%;
  width: 400px;
}

.loader {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container h2 {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.form-container p {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.value {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

p.label {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.input-block /deep/ label {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 4px;
}

.input-block /deep/ .ui-input {
  margin-bottom: 24px;
}

.input-block /deep/ textarea::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #cacaca;
}

.block {
  margin-bottom: 16px;
}

.radio-block /deep/ label .m-radio {
  font-size: 12px;
  line-height: 18px;
  color: #000;
}

.radio-block /deep/ label {
  padding: 8px 0 0 0;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}

.block /deep/ .ui-dropdown {
  max-width: 257px;
}

.block /deep/ .ui-dropdown textarea {
  border: 1px solid #9595aa;
  border-radius: 5px;
}

.button-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block /deep/ .dropdown-box > .dropdown-menu {
  max-height: 200px;
  border: 1px solid #9595aa;
}
</style>
