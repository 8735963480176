var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group" },
    [
      _vm.loading
        ? _c("ui-loader", { attrs: { center: "" } })
        : _vm.error
        ? _c("div", { staticClass: "error" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("RegistryHubTransport.loadError")))]),
          ])
        : _c(
            "div",
            [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("RegistryHubTransport.currentVehicles")) + ":"
                ),
              ]),
              _vm._v(" "),
              !_vm.hasCurrentVehicles
                ? _c("div", { staticClass: "group" }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.$t("RegistryHubTransport.noCurrentVehicles"))
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.currentVehicles, function (vehicle, index) {
                return _c(
                  "div",
                  { key: "vehicle-" + index, staticClass: "group" },
                  _vm._l(vehicle, function (value, key) {
                    return _c("div", { key: key }, [
                      _vm.$te("RegistryHubTransport." + key)
                        ? _c("span", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("RegistryHubTransport." + key)) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" " + _vm._s(value) + "\n        "),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              }),
              _vm._v(" "),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("RegistryHubTransport.historyValues")) + ":"
                ),
              ]),
              _vm._v(" "),
              !_vm.hasHistoryValues
                ? _c("div", [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.$t("RegistryHubTransport.noHistoryValues"))
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.historyValues, function (vehicle, index) {
                return _c(
                  "div",
                  { key: "history-" + index, staticClass: "group" },
                  _vm._l(vehicle, function (value, key) {
                    return _c("div", { key: key }, [
                      _vm.$te("RegistryHubTransport." + key)
                        ? _c("span", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.$t("RegistryHubTransport." + key)) +
                                  ":"
                              ),
                            ]),
                            _vm._v(" " + _vm._s(value) + "\n        "),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }