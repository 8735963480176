var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-container",
    [
      _c("ui-card", [
        _c("h2", [_vm._v(_vm._s(_vm.getTitle))]),
        _vm._v(" "),
        _c("div", { staticClass: "row ui-form-row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("CompanyProduct.Form.SelfieSettings.Title"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("h5", [
              _vm._v(
                _vm._s(_vm.$t("CompanyProduct.Form.SelfieSettings.BlockLabel"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "checkbox-list" },
              _vm._l(_vm.documentSelfieSettingsSchema, function (
                selfieSetting,
                index
              ) {
                return _c(
                  "li",
                  { key: "selfie-setting-" + index },
                  [
                    _c("ui-new-checkbox", {
                      attrs: {
                        label: selfieSetting.label,
                        value: selfieSetting.value,
                      },
                      model: {
                        value: _vm.documentSelfieSettings,
                        callback: function ($$v) {
                          _vm.documentSelfieSettings = $$v
                        },
                        expression: "documentSelfieSettings",
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row ui-form-row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t("CompanyProduct.Form.DocumentType.Title"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("h5", [
              _vm._v(
                _vm._s(_vm.$t("CompanyProduct.Form.DocumentType.BlockLabel"))
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "checkbox-list" },
              _vm._l(_vm.documentTypesSchema, function (documentType, index) {
                return _c(
                  "li",
                  { key: "document-type-" + index },
                  [
                    _c("ui-new-checkbox", {
                      attrs: {
                        label: documentType.label,
                        value: documentType.value,
                      },
                      model: {
                        value: _vm.documentTypeModel,
                        callback: function ($$v) {
                          _vm.documentTypeModel = $$v
                        },
                        expression: "documentTypeModel",
                      },
                    }),
                    _vm._v(" "),
                    documentType.children
                      ? _c(
                          "ul",
                          _vm._l(documentType.children, function (
                            child,
                            childIndex
                          ) {
                            return _c(
                              "li",
                              { key: "document-type-child" + childIndex },
                              [
                                _vm.documentTypeModel.includes(
                                  _vm.DOCUMENT_TYPES.LOCAL_PASSPORT
                                )
                                  ? _c("ui-new-checkbox", {
                                      attrs: {
                                        label: child.label,
                                        value: child.value,
                                      },
                                      model: {
                                        value: _vm.documentRules[child.group],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.documentRules,
                                            child.group,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "documentRules[child.group]",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                child.rules.length
                                  ? _c(
                                      "ul",
                                      _vm._l(child.rules, function (
                                        childRules,
                                        childRuleIndex
                                      ) {
                                        return _c(
                                          "li",
                                          {
                                            key:
                                              "document-type-child-rule" +
                                              childRuleIndex,
                                          },
                                          [
                                            _vm.documentRules[
                                              child.group
                                            ].includes(child.value)
                                              ? _c("ui-new-checkbox", {
                                                  attrs: {
                                                    label: childRules.label,
                                                    value: childRules.value,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.documentChildren[
                                                        childRules.group
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.documentChildren,
                                                        childRules.group,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "documentChildren[childRules.group]",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            childRules.value ===
                                            _vm.APPLICATION_RULE
                                              .ADDITIONAL_COMMENTS
                                              ? _c("ul", [
                                                  _vm.documentChildren[
                                                    childRules.group
                                                  ].includes(
                                                    _vm.APPLICATION_RULE
                                                      .ADDITIONAL_COMMENTS
                                                  )
                                                    ? _c(
                                                        "li",
                                                        [
                                                          _c("ui-textarea", {
                                                            attrs: {
                                                              placeholder: _vm.$t(
                                                                childRules.placeholder
                                                              ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .additionalInfo[
                                                                  childRules
                                                                    .model
                                                                ],
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.additionalInfo,
                                                                  childRules.model,
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "additionalInfo[childRules.model]",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    documentType.rules.length
                      ? _c(
                          "ul",
                          _vm._l(documentType.rules, function (
                            rule,
                            ruleIndex
                          ) {
                            return _c(
                              "li",
                              { key: "document-rules-" + ruleIndex },
                              [
                                _vm.shouldDisplay(
                                  documentType.value,
                                  _vm.documentTypeModel
                                )
                                  ? _c("ui-new-checkbox", {
                                      attrs: {
                                        disabled: rule.isDisabled,
                                        label: rule.label,
                                        value: rule.value,
                                      },
                                      model: {
                                        value: _vm.documentRules[rule.group],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.documentRules,
                                            rule.group,
                                            $$v
                                          )
                                        },
                                        expression: "documentRules[rule.group]",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                rule.value ===
                                _vm.APPLICATION_RULE
                                  .ONLY_SPECIFIC_COUNTIES_ACCEPTED
                                  ? _c("ul", [
                                      _vm.shouldDisplay(
                                        _vm.APPLICATION_RULE
                                          .ONLY_SPECIFIC_COUNTIES_ACCEPTED,
                                        _vm.documentRules[rule.group]
                                      )
                                        ? _c(
                                            "li",
                                            [
                                              _c("listed-checkbox", {
                                                staticClass: "listed-checkbox",
                                                attrs: {
                                                  "checkbox-values":
                                                    rule.values,
                                                  "list-items": _vm.countryList,
                                                },
                                                on: {
                                                  valueChange: rule.method,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                rule.value ===
                                _vm.APPLICATION_RULE.ADDITIONAL_COMMENTS
                                  ? _c("ul", [
                                      _vm.shouldDisplay(
                                        _vm.APPLICATION_RULE
                                          .ADDITIONAL_COMMENTS,
                                        _vm.documentRules[rule.group]
                                      )
                                        ? _c(
                                            "li",
                                            [
                                              _c("ui-textarea", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    rule.placeholder
                                                  ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.additionalInfo[
                                                      rule.model
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.additionalInfo,
                                                      rule.model,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "additionalInfo[rule.model]",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row ui-form-row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("CompanyProduct.Form.SelectApplication.Title"))
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("h5", [
              _vm._v(
                _vm._s(
                  _vm.$t("CompanyProduct.Form.SelectApplication.BlockLabel")
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "checkbox-list" },
              _vm._l(_vm.documentImportantsSchema, function (
                documentImportant,
                index
              ) {
                return _c(
                  "li",
                  { key: "document-important-" + index },
                  [
                    _c("ui-new-checkbox", {
                      attrs: {
                        label: documentImportant.label,
                        value: documentImportant.value,
                      },
                      model: {
                        value: _vm.documentImportants,
                        callback: function ($$v) {
                          _vm.documentImportants = $$v
                        },
                        expression: "documentImportants",
                      },
                    }),
                    _vm._v(" "),
                    documentImportant.value ===
                    _vm.IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES
                      ? _c("ul", [
                          _c(
                            "li",
                            [
                              _vm.shouldDisplay(
                                _vm.IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES,
                                _vm.documentImportants
                              )
                                ? _c("listed-checkbox", {
                                    staticClass:
                                      "listed-checkbox listed-checkbox-importants",
                                    attrs: {
                                      "checkbox-values":
                                        _vm.prohibitedNationalities,
                                      "list-items": _vm.countryList,
                                    },
                                    on: {
                                      valueChange:
                                        _vm.setProhibitedNationalities,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    documentImportant.value ===
                    _vm.IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES
                      ? _c("ul", [
                          _c(
                            "li",
                            [
                              _vm.shouldDisplay(
                                _vm.IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES,
                                _vm.documentImportants
                              )
                                ? _c("listed-checkbox", {
                                    staticClass:
                                      "listed-checkbox listed-checkbox-importants",
                                    attrs: {
                                      "checkbox-values":
                                        _vm.prohibitedCountries,
                                      "list-items": _vm.countryList,
                                    },
                                    on: {
                                      valueChange: _vm.setProhibitedCountries,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    documentImportant.value ===
                    _vm.IMPORTANT_RULE
                      .ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
                      ? _c("ul", [
                          _c(
                            "li",
                            [
                              _vm.shouldDisplay(
                                _vm.IMPORTANT_RULE
                                  .ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED,
                                _vm.documentImportants
                              )
                                ? _c("ui-textarea", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "CompanyProduct.Form.SelectApplication.ChosenLabels.WriteDownFieldsToBeChecked"
                                      ),
                                    },
                                    model: {
                                      value: _vm.fieldsToCheck,
                                      callback: function ($$v) {
                                        _vm.fieldsToCheck = $$v
                                      },
                                      expression: "fieldsToCheck",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row ui-form-row" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("h4", [
              _vm._v(_vm._s(_vm.$t("CompanyProduct.Form.OtherComments.Title"))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("h5", [
              _vm._v(
                _vm._s(_vm.$t("CompanyProduct.Form.OtherComments.BlockLabel"))
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("ul", { staticClass: "checkbox-list" }, [
                _c(
                  "li",
                  [
                    _c("ui-textarea", {
                      attrs: {
                        placeholder: _vm.$t(
                          "CompanyProduct.Form.OtherComments.WriteDownFieldsToBeChecked"
                        ),
                      },
                      model: {
                        value: _vm.model.comment,
                        callback: function ($$v) {
                          _vm.$set(_vm.model, "comment", $$v)
                        },
                        expression: "model.comment",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 text-right" },
            [
              _c(
                "ui-button",
                {
                  staticClass: "btn-outline-brand border-radius-0",
                  attrs: { disabled: _vm.isLoading },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "CompanyProcedures" })
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("AppCancel")) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "ui-button",
                {
                  staticClass: "btn-brand border-radius-0",
                  attrs: { loading: _vm.isLoading, disabled: _vm.isLoading },
                  on: { click: _vm.handleSubmit },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("CompanyProduct.UpdateProcedures")) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }