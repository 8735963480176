<template>
  <box-with-header>
    <!--
    <div slot="header">
      <ui-button class="btn-danger" @click="removeUser">
        {{ $t('DetailsRemove') }}
      </ui-button>
    </div>
    -->
    <ui-container>
      <div v-if="revision" class="row">
        <div class="col-lg-4">
          <ui-form @submit="updateDetails">
            <ui-card title="Details" icon="flaticon-user" dark>
              <div slot="body">
                <details-box>
                  <details-item
                    label="DetailsFirstName"
                    :value="userDetails.firstName"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.firstName"
                    :min-length="2"
                    :max-length="50"
                    @change="firstNameChanged"
                  />
                  <details-item
                    label="DetailsLastName"
                    :value="userDetails.lastName"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.lastName"
                    :min-length="2"
                    :max-length="50"
                    @change="lastNameChanged"
                  />
                  <details-item
                    label="DetailsEmail"
                    :value="userDetails.email"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.email"
                    :max-length="255"
                    @change="emailChanged"
                  />
                  <details-item
                    label="DetailsPhoneNumber"
                    :value="userDetails.phone"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.phone"
                    :max-length="22"
                    @change="phoneChanged"
                  />
                  <details-item
                    label="DetailsPosition"
                    :value="userDetails.position"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.position"
                    :max-length="50"
                    :min-length="2"
                    @change="positionChanged"
                  />
                  <details-rule-item
                    name="DetailsUserStatus"
                    :status="getStatus(userDetails.userEnabled)"
                  >
                    <ui-toggle
                      v-if="details.editEnabled"
                      :value="userDetails.userEnabled"
                      :disabled="details.isLoading"
                      @change="userEnabledChanged"
                    />
                  </details-rule-item>
                  <details-item
                    v-if="details.editEnabled"
                    label="DetailsNewPassword"
                    type="password"
                    :value="userDetails.password"
                    :editable="details.editEnabled"
                    :disabled="details.isLoading"
                    :error="details.errors.password"
                    :min-length="8"
                    :max-length="50"
                    @input="passwordChanged"
                  />
                  <details-rule-item
                    name="DetailsEmailConfirmed"
                    :status="getStatus(userDetails.emailConfirmed)"
                  />
                  <!-- <table-rule-item name="DetailsTwoFactor" :status="userDetails.twoFactorEnabled"/> -->
                </details-box>
              </div>
              <edit-footer
                slot="footer"
                :edit-enabled="details.editEnabled"
                @edit="enableDetailsEdit"
              />
            </ui-card>
          </ui-form>
        </div>
        <div class="col-lg-4">
          <ui-form @submit="updateRoles">
            <ui-card title="DetailsRoles">
              <editable-list
                slot="body"
                add-text="DetailsAddRole"
                empty-text="DetailsNoRoles"
                :items="userRoles"
                :options="roles.options"
                has-search
                :edit-enabled="roles.editEnabled"
                :disabled="roles.isLoading"
              />
              <edit-footer
                slot="footer"
                :disabled="roles.isLoading"
                :edit-enabled="roles.editEnabled"
                @edit="enableRolesEdit"
              />
            </ui-card>
          </ui-form>
          <!-- <ui-form @submit="updatePermissions">
            <ui-card title="DetailsPermissions" v-if="!editPermissionsEnabled">
              <edit-footer slot="footer" @edit="enablePermissionsEdit" :edit-enabled="editPermissionsEnabled"/>
            </ui-card>
          </ui-form> -->
        </div>
        <div class="col-lg-4">
          <ui-form @submit="updateCompanies">
            <ui-card title="DetailsCompanies">
              <edit-header
                slot="tools"
                :disabled="companies.isLoading"
                :edit-enabled="companies.editEnabled"
                @edit="enableCompaniesEdit"
              />
              <editable-list
                slot="body"
                add-text="DetailsAddCompany"
                multi-select
                all
                empty-text="DetailsNoCompanies"
                :items="userCompanies"
                :options="companies.options"
                has-search
                :edit-enabled="companies.editEnabled"
                :disabled="companies.isLoading"
                @deleteAll="deleteAll"
              />
            </ui-card>
          </ui-form>
        </div>
      </div>
      <span v-else-if="isReady">
        {{ $t('DetailsAdminUserNotFound') }}
      </span>
      <ui-loader v-else :size="4" center />
    </ui-container>
  </box-with-header>
</template>

<script>
import BoxWithHeader from '@src/components/partials/BoxWithHeader'
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import DetailsRuleItem from '@src/components/partials/DetailsRuleItem'
import EditFooter from '@src/components/partials/EditFooter'
import EditHeader from '@src/components/partials/EditHeader'
import EditableList from '@src/components/partials/EditableList'
import Api from '@src/scripts/api'
import { statusTypesEnum } from '@src/scripts/enums'
import actions from '@src/store/actions'
import {
  requiredValidator,
  minLengthValidator,
  emailValidator,
  phoneNumberValidator,
  passwordValidator,
  validateForm
} from '@src/scripts/validators'

const detailsInit = () => ({
  isLoading: false,
  editEnabled: false,
  options: null
})

export default {
  components: {
    DetailsBox,
    DetailsItem,
    DetailsRuleItem,
    EditFooter,
    EditHeader,
    EditableList,
    BoxWithHeader
  },

  data() {
    return {
      isReady: false,
      isLoading: false,
      details: {
        isLoading: false,
        editEnabled: false,
        errors: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          position: null,
          password: null
        }
      },
      roles: detailsInit(),
      companies: detailsInit(),
      revision: null,
      isRemoved: false,
      count: 0
    }
  },

  computed: {
    userId() {
      return this.$route.params && this.$route.params.userId
    },

    userDetails() {
      const { revision } = this
      if (!revision) return
      const { id, companies, roles, ...data } = revision
      return data
    },

    userRoles() {
      const { revision } = this
      if (!revision) return
      return revision.roles
    },

    userCompanies() {
      const { revision } = this
      if (!revision) return
      return revision.companies
    }
  },

  async created() {
    const { getUserDetails, getSettings, defaultValidations } = this
    this.revision = await getUserDetails()
    if (this.revision) {
      const roles = this.revision.roles.map((role) => {
        const value =
          role.value.indexOf('Role') >= 0 ? role.value : `${role.value}Role`
        return {
          key: role.key,
          value: value
        }
      })
      this.$set(this.revision, 'roles', roles)
      getSettings()
      defaultValidations(this.revision)
    }
    this.isReady = true
  },

  methods: {
    deleteAll() {
      this.revision.companies = []
    },

    getStatus(status) {
      if (status) return statusTypesEnum.succeeded
      else if (!status) return statusTypesEnum.failed
      else return statusTypesEnum.unavailable
    },

    firstNameChanged(value, freeze) {
      const {
        revision,
        details: { errors }
      } = this
      errors.firstName = requiredValidator(value)
      if (!errors.firstName) errors.firstName = minLengthValidator(value, 2)
      if (freeze) return
      this.$set(revision, 'firstName', value)
    },

    lastNameChanged(value, freeze) {
      const {
        revision,
        details: { errors }
      } = this
      errors.lastName = requiredValidator(value)
      if (!errors.lastName) errors.lastName = minLengthValidator(value, 2)
      if (freeze) return
      this.$set(revision, 'lastName', value)
    },

    emailChanged(value, freeze) {
      const {
        revision,
        details: { errors }
      } = this
      errors.email = requiredValidator(value)
      if (!errors.email) errors.email = emailValidator(value)
      if (freeze) return
      this.$set(revision, 'email', value)
    },

    phoneChanged(value, freeze) {
      const {
        revision,
        details: { errors }
      } = this
      errors.phone = phoneNumberValidator(value)
      if (freeze) return
      this.$set(revision, 'phone', value)
    },

    positionChanged(value, freeze) {
      const {
        revision,
        details: { errors }
      } = this
      errors.position = minLengthValidator(value, 2)
      if (freeze) return
      this.$set(revision, 'position', value)
    },

    userEnabledChanged(value) {
      this.revision.userEnabled = value
    },

    passwordChanged(value) {
      const {
        revision,
        details: { errors }
      } = this
      errors.password = minLengthValidator(value, 8)
      if (!errors.password) errors.password = passwordValidator(value)
      this.$set(revision, 'password', value)
    },

    defaultValidations(data) {
      this.firstNameChanged(data.firstName, true)
      this.lastNameChanged(data.lastName, true)
      this.emailChanged(data.email, true)
      this.phoneChanged(data.phone, true)
      this.positionChanged(data.position, true)
    },

    enableDetailsEdit() {
      this.details.editEnabled = true
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
    },

    async updateDetails() {
      const {
        details: { errors }
      } = this
      if (!validateForm(errors)) return false
      const { userId, userDetails } = this
      const {
        emailConfirmed,
        twoFactorEnabled,
        password,
        ...details
      } = userDetails
      this.details.isLoading = true
      try {
        await Api.post('/users/update-details', {
          password: password || undefined,
          ...details,
          userId
        })
        this.details.editEnabled = false
        if (password) {
          this.$set(userDetails, 'password', null)
        }
      } catch (error) {
        this.showError()
      }
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
      this.details.isLoading = false
      return true
    },

    enableRolesEdit() {
      this.roles.editEnabled = true
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
    },

    async updateRoles() {
      const { userId, userRoles: roles } = this
      this.roles.isLoading = true
      try {
        await Api.post('/users/update-roles', {
          userId,
          roles: roles.map((item) => item.key)
        })
        this.roles.editEnabled = false
      } catch (error) {
        this.showError()
      }
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
      this.roles.isLoading = false
    },

    enableCompaniesEdit() {
      this.companies.editEnabled = true
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
    },

    async updateCompanies() {
      const { userId, userCompanies: companies } = this
      this.companies.isLoading = true
      try {
        await Api.post('/users/update-companies', {
          userId,
          companies: companies.map((item) => item.key)
        })
        const response = await Api.get('/settings/app')
        this.$store.dispatch(actions.updateSettings, response)

        this.companies.editEnabled = false
      } catch (error) {
        this.showError()
      }
      this.$store.dispatch(
        actions.updateProgress,
        this.companies.editEnabled ||
          this.details.editEnabled ||
          this.roles.editEnabled
      )
      this.companies.isLoading = false
    },

    async getUserDetails() {
      const { userId } = this
      try {
        return await Api.get(`/users/details/${userId}`)
      } catch (error) {
        this.showError()
        return null
      }
    },

    async getSettings() {
      try {
        const { roles, companies } = await Api.get('/users/settings')
        this.roles.options = roles.map((role) => {
          const value =
            role.value.indexOf('Role') >= 0 ? role.value : `${role.value}Role`
          return {
            key: role.key,
            value: value
          }
        })
        this.companies.options = companies
      } catch (error) {
        this.showError()
        return null
      }
    },

    async updateUser(method, payload) {
      try {
        await Api.post(method, payload)
      } catch (error) {
        this.showError()
      }
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    removeUser() {
      this.$notify({
        type: 'warning',
        title: `${this.$t('RemoveUserTitle')} ${
          this.userDetails.firstName || ''
        } ${this.userDetails.lastName || ''}?`,
        callback: this.removeUserCallback
      })
    },

    async removeUserCallback() {
      const { userId } = this
      try {
        await Api.post('/users/delete', userId)
        this.$router.go(-1)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    }
  }
}
</script>
