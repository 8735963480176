var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-card",
    { attrs: { title: "AppSearchResults" } },
    [
      _c("div", { attrs: { slot: "tools" }, slot: "tools" }, [
        _c(
          "div",
          { staticClass: "display-flex" },
          [
            _vm.isDownloading
              ? _c("ui-loader", {
                  staticClass: "mr-1",
                  attrs: { size: 1, center: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("ui-dropdown", {
              staticClass: "margin-right",
              attrs: {
                options: _vm.exportOptions,
                placeholder: "AppExport",
                disabled: _vm.isDownloading,
                small: "",
                "light-border": "",
              },
              on: { input: _vm.exportList },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      !_vm.ready
        ? _c("search-state", {
            attrs: {
              loading: _vm.loading,
              empty: _vm.empty,
              error: _vm.error,
              "loading-message": _vm.$t("App.Loading"),
              "empty-message": _vm.$t("Kyb.Search.Empty"),
              "error-message": _vm.$t("Error.Fail"),
            },
          })
        : _c(
            "div",
            _vm._l(_vm.identifications, function (item, index) {
              return _c("search-item", {
                key: "d_" + index,
                attrs: { data: item, label: _vm.label },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }