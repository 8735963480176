var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin",
      attrs: { id: "landing-page" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside login-form",
        },
        [
          _c("div", { staticClass: "m-stack m-stack--hor m-stack--desktop" }, [
            _c("div", { staticClass: "m-stack__item m-stack__item--fluid" }, [
              _c("div", { staticClass: "m-login__wrapper" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "m-login__signin" }, [
                  _c("div", { staticClass: "m-login__head" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "m-form__heading-title",
                        staticStyle: {
                          "text-align": "center",
                          color: "#575962",
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("LandingTitle")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-login__form m-form" }, [
                    _c(
                      "div",
                      { staticClass: "m-login__form-action" },
                      [
                        _c("ui-dropdown", {
                          staticClass: "btn-small",
                          attrs: {
                            value: _vm.$currentLang,
                            "light-border": "",
                            options: _vm.languageOptions,
                          },
                          on: { input: _vm.languageValueChanged },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "m-login__form-action" },
                      [
                        _c(
                          "ui-button",
                          {
                            staticClass:
                              "btn-brand col-lg-6 mx-auto btn-new-brand",
                            attrs: { disabled: _vm.isLoading },
                            on: { click: _vm.redirectToLogin },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("AppLogin")) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-link",
                            on: { click: _vm.handleClick },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("AppForgotPassword")) +
                                "\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-login__content m-grid-item--center login-background",
          style: { backgroundImage: "url('" + _vm.image + "')" },
        },
        [
          _c("div", { staticClass: "login_logo_bot" }),
          _vm._v(" "),
          _c("div", { staticClass: "m-grid__item login-description" }, [
            _c("h3", { staticClass: "login-header" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("LandingDescriptionTitle")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "login-subheader" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("LandingDescription")) + "\n      "
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("pop-up", [_c("forgot-password")], 1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "m-login__logo" }, [
      _c("img", { attrs: { src: require("@src/assets/images/logo.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }