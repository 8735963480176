<template>
  <div
    id="landing-page"
    class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-grid--tablet-and-mobile m-grid--hor-tablet-and-mobile m-login m-login--1 m-login--signin"
  >
    <div
      class="m-grid__item m-grid__item--order-tablet-and-mobile-2 m-login__aside login-form"
    >
      <div class="m-stack m-stack--hor m-stack--desktop">
        <div class="m-stack__item m-stack__item--fluid">
          <div class="m-login__wrapper">
            <div class="m-login__logo">
              <img src="~@src/assets/images/logo.png" />
            </div>
            <div class="m-login__signin">
              <div class="m-login__head">
                <h3
                  class="m-form__heading-title"
                  style="text-align: center; color: #575962"
                >
                  {{ $t('LandingTitle') }}
                </h3>
              </div>
              <div class="m-login__form m-form">
                <div class="m-login__form-action">
                  <ui-dropdown
                    :value="$currentLang"
                    light-border
                    class="btn-small"
                    :options="languageOptions"
                    @input="languageValueChanged"
                  />
                </div>
                <div class="m-login__form-action">
                  <ui-button
                    class="btn-brand col-lg-6 mx-auto btn-new-brand"
                    :disabled="isLoading"
                    @click="redirectToLogin"
                  >
                    {{ $t('AppLogin') }}
                  </ui-button>
                  <div class="btn-link" @click="handleClick">
                    {{ $t('AppForgotPassword') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--center m-grid--hor m-grid__item--order-tablet-and-mobile-1 m-login__content m-grid-item--center login-background"
      :style="{ backgroundImage: `url('${image}')` }"
    >
      <div class="login_logo_bot" />
      <div class="m-grid__item login-description">
        <h3 class="login-header">
          {{ $t('LandingDescriptionTitle') }}
        </h3>
        <p class="login-subheader">
          {{ $t('LandingDescription') }}
        </p>
      </div>
    </div>
    <pop-up>
      <forgot-password />
    </pop-up>
  </div>
</template>

<script>
import { signIn } from '@src/scripts/identity'
import PopUp from '@src/components/popup/PopUp'
import ForgotPassword from '@src/components/pages/ForgotPassword'
import bgImage from '@src/assets/images/spec-bg.png'

export default {
  components: {
    PopUp,
    ForgotPassword
  },
  data() {
    return {
      hasError: false,
      image: bgImage,
      isLoading: false,
      userDetails: {
        email: null,
        password: null
      }
    }
  },

  computed: {
    languageOptions() {
      const options = [
        {
          key: 'lt',
          value: 'LanguageLt'
        },
        {
          key: 'en',
          value: 'LanguageEn'
        },
        {
          key: 'ua',
          value: 'LanguageUa'
        }
      ]
      return options
    }
  },

  created() {
    const { isLoggedIn } = this.$store.getters
    if (isLoggedIn) {
      this.$router.replace({ name: 'App' })
    }
  },

  methods: {
    languageValueChanged(value) {
      this.$changeLang(value)
      sessionStorage.setItem('currentLang', value)
    },

    handleClick() {
      this.$openPopUp()
    },

    redirectToLogin() {
      signIn()
    }
  }
}
</script>

<style scoped>
.btn-new-brand {
  color: #2b2b2b;
  background: #c6f77f;
  border: none;
}

#landing-page .btn-new-brand:active {
  color: #2b2b2b;
  background: #c6f77f;
  border: none;
}

.login-header {
  font-size: 48px;
  line-height: 48px;
  color: #2b2b2b;
  letter-spacing: -0.02em;
}

.login-description {
  text-align: center;
}

.login-subheader {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.005em;
}

#landing-page .m-stack {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

#landing-page .m-login__wrapper .m-login__signin .m-login__form-action .btn {
  padding: 1.2rem 0;
  min-width: 14rem;
  max-width: 14rem;
}

@media screen and (max-width: 1024px) {
  .login_logo_bot {
    display: none;
  }

  .login-form {
    max-width: 35rem;
  }
}

@media screen and (min-width: 1201px) {
  .login-description {
    padding: 7%;
  }
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
  .m-grid.m-grid--ver-desktop.m-grid--desktop {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
</style>
<style>
.m-login.m-login--1
  .m-login__wrapper
  .m-login__form
  .m-login__form-action
  .ui-dropdown.btn-small
  button.ui-button {
  width: 14rem;
  padding: 1rem 0;
  margin-top: -1.1rem;
}

.m-login__signin .dropdown-box.ui-dropdown.btn-small .dropdown-menu {
  min-width: 14rem;
  left: 50%;
  transform: translateX(-50%);
}

.btn-link {
  margin-top: 1rem;
  cursor: pointer;
  color: var(--brand-v2);
}

.m-login__signin .ui-dropdown.btn-small ul li a.dropdown-item {
  padding: 0.2rem 1rem;
  font-size: 1rem;
}
</style>
