<template>
  <div
    class="expiration-date m--margin-top-30"
    :class="{
      'has-error': error,
      'ui-touched': istouched,
      'ui-untouched': !istouched
    }"
  >
    <p class="col-form-label">
      {{ $t('DetailsExpirationDate') }}
    </p>
    <div class="display-inline">
      <ui-input
        class="date"
        :value="month"
        small
        :disabled="isLoading"
        :min-length="2"
        :max-length="2"
        placeholder="MM"
        @input="changeMonth"
        @blur="lostFocus"
      />
      <span class="padding"> / </span>
      <ui-input
        class="date"
        :value="year"
        small
        :disabled="isLoading"
        :min-length="2"
        :max-length="2"
        placeholder="YY"
        @input="changeYear"
        @blur="lostFocus"
      />
    </div>
    <span class="error-message">
      {{ $t(error) }}
    </span>
  </div>
</template>

<script>
import IMask from 'imask'

export default {
  props: {
    isLoading: Boolean,
    month: { type: String, default: undefined },
    year: { type: String, default: undefined },
    error: { type: String, default: undefined }
  },

  data() {
    return {
      monthOptions: [
        { key: '10', value: '10' },
        { key: '11', value: '11' }
      ],
      yearOptions: [
        { key: '2018', value: '2018' },
        { key: '2019', value: '2019' }
      ],
      istouched: false
    }
  },

  methods: {
    lostFocus() {
      if (!this.istouched) {
        this.istouched = true
      }
    },

    changeMonth(value) {
      if (value) {
        const element = document.querySelector('.date input')
        const maskOptions = {
          mask: '00'
        }
        const mask = new IMask(element, maskOptions)
        this.$emit('changeMonth', mask.value)
      }
    },

    changeYear(value) {
      if (value) {
        const element = document.querySelector('.date input')
        const maskOptions = {
          mask: '00'
        }
        const mask = new IMask(element, maskOptions)
        this.$emit('changeYear', mask.value)
      }
    }
  }
}
</script>

<style scoped>
.display-inline > div {
  display: inline-block;
}

.padding {
  padding: 0 1.15rem;
}
</style>

<style>
.expiration-date .ui-input {
  width: 5.38rem;
}
</style>
