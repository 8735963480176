var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "display-flex-div" }, [
    _vm.title
      ? _c("span", { staticClass: "tr-title" }, [
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.title)) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.value
      ? _c("span", { staticClass: "tr-value" }, [
          _vm._v("\n    " + _vm._s(_vm.$t(_vm.value)) + "\n  "),
        ])
      : _c("span", { staticClass: "tr-value" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }