var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "identification-page" },
    [
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { title: "AppSearchResults", count: _vm.itemsCount } },
            [
              _c(
                "ui-button",
                {
                  staticClass: "btn-link",
                  attrs: { slot: "tools" },
                  on: { click: _vm.clickCreate },
                  slot: "tools",
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("AgentLink.List.AddLink")) +
                      "\n        "
                  ),
                  _c("i", { staticClass: "la la-plus" }),
                ]
              ),
              _vm._v(" "),
              !_vm.isLoading && _vm.items
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "export-table",
                        attrs: {
                          labels: _vm.listLabels,
                          data: _vm.items,
                          "empty-text": "AgentLink.List.NoItems",
                        },
                      },
                      [
                        _vm._l(_vm.items, function (row, index) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "m-table__row" },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.id },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyIdentificationId(
                                            row.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.id) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.email) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { attrs: { title: row.agent } },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: row.agentUrl,
                                        target: "_blank",
                                        rel: "noreferrer",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.agentUrl) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: {
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickCopy(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("AgentLink.List.Copy")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "td-link" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-outline-brand",
                                      attrs: {
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickEdit(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("AgentLink.List.Edit")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: {
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickRemove(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("AgentLink.List.Delete")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        { attrs: { "full-size": "" } },
        [
          _vm.popUpType === "DELETE_CONFIRM"
            ? [
                _c("div", [
                  _c("h4", [_vm._v(_vm._s(_vm.$t("AgentLink.Delete.Title")))]),
                  _vm._v(" "),
                  _c("div", { staticClass: "popup-text" }, [
                    _vm._v(_vm._s(_vm.$t("AgentLink.Delete.Description"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "buttons-group" },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "col-lg-5 btn-outline-brand",
                          attrs: { small: "" },
                          on: { click: _vm.confirmDelete },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("AppConfirm")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ui-button",
                        {
                          staticClass: "col-lg-5 btn-brand",
                          attrs: { metal: "", small: "" },
                          on: { click: _vm.abortPopUp },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("AppCancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm.popUpType === "EDIT"
            ? [
                _c("new-agent-link", {
                  attrs: { item: _vm.currentItem },
                  on: { updated: _vm.updated },
                }),
              ]
            : _vm.popUpType === "create"
            ? [_c("new-agent-link", { on: { updated: _vm.updated } })]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }