var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-state" },
    [
      _vm.loading
        ? [
            _c("ui-loader", { attrs: { size: 4 } }),
            _vm._v(" "),
            _vm.loadingMessage
              ? _c("p", [_vm._v(_vm._s(_vm.loadingMessage))])
              : _vm._e(),
          ]
        : _vm.empty
        ? [
            _c("i", { staticClass: "fa fa-file" }),
            _vm._v(" "),
            _vm.emptyMessage
              ? _c("div", { staticClass: "empty-message" }, [
                  _vm._v(_vm._s(_vm.emptyMessage)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.emptySubMessage
              ? _c("div", { staticClass: "sub-message" }, [
                  _vm._v("\n      " + _vm._s(_vm.emptySubMessage) + "\n    "),
                ])
              : _vm._e(),
          ]
        : _vm.error
        ? [
            _c("i", { staticClass: "fa fa-exclamation-circle" }),
            _vm._v(" "),
            _vm.errorMessage
              ? _c("p", [_vm._v(_vm._s(_vm.errorMessage))])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }