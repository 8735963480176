var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-list-timeline__item rule-item",
      class: { "icon-top": _vm.reasons },
    },
    [
      _c("i", { staticClass: "rule-icon", class: _vm.statusIcon }),
      _vm._v(" "),
      _c("span", { staticClass: "hover-text" }, [
        _vm._v(_vm._s(_vm.$t(_vm.statusIconText))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "m-list-timeline__text" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.statusName))),
        _vm.reasons ? _c("span", [_vm._v(":")]) : _vm._e(),
        _vm._v(" "),
        _vm.reason
          ? _c("span", { staticClass: "m-list-timeline__text reason-text" }, [
              _vm._v("\n      " + _vm._s(_vm.reasonText) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.reasons
          ? _c(
              "ul",
              { staticClass: "m-list-timeline__text reason-text" },
              _vm._l(_vm.reasons, function (item, key) {
                return _c("li", { key: key }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("REASON_" + item)) + "\n      "
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.action
        ? _c(
            "ui-button",
            {
              staticClass: "btn btn-info",
              attrs: { small: "", low: "", "small-text": "" },
              on: { click: _vm.action },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("Identification.Rules.Actions.Details")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }