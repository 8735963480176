<template>
  <div class="ui-toggle" :class="{ 'ui-toggle--header': isheader }">
    <span class="m-switch m-switch--icon">
      <label>
        <input
          type="checkbox"
          :checked="value"
          :disabled="disabled"
          hidden
          @click="click"
        />
        <span />
      </label>
      <span v-if="label" class="toggle-label">
        {{ $t(label) }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ui-toggle',

  props: {
    label: { type: String, default: undefined },
    value: Boolean,
    disabled: Boolean,
    isheader: Boolean,
    prevent: Boolean
  },

  methods: {
    click(event) {
      if (this.prevent) event.preventDefault()
      this.$emit('change', event.target.checked)
    }
  }
}
</script>

<style scoped>
.ui-toggle .m-switch {
  display: flex;
}

label {
  margin-bottom: 0;
}

.toggle-label {
  margin: auto 1rem;
}

.m-switch input:empty ~ span::after {
  background-color: var(--danger);
}

.m-switch input:checked ~ span::after {
  background-color: var(--success);
}

.ui-toggle--header {
  position: absolute;
  top: 40px;
  right: 30px;
}

.ui-toggle--header .toggle-label {
  color: var(--white);
}

@media (max-width: 678px) {
  .ui-toggle--header {
    position: relative;
    top: 1rem;
    right: 0;
  }
}
</style>
