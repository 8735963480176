<template>
  <div>
    <div v-if="isLoading">
      <ui-loader center />
    </div>
    <template v-if="!isLoading">
      <div v-if="sanctions.length" class="row">
        <div
          v-for="sanction in sanctions"
          :key="sanction.id"
          class="col-md-6 col-lg-6 col-xl-4 santions-list-item"
        >
          <ui-card :title="sanction.entity_type">
            <div class="row">
              <details-item-full-width :label="sanction.fullname" />
            </div>
            <div class="row">
              <details-item-with-tags
                v-if="sanction.types && sanction.types.length"
                label="DetailsMatched"
                :values="sanction.types"
              />
            </div>
            <div class="row">
              <details-item-with-tags
                v-if="sanction.match_types && sanction.match_types.length"
                label="DetailsRelevance"
                :values="sanction.match_types"
                no-style-tabs
              />
            </div>
            <div
              v-if="sanction.associates && sanction.associates.length"
              class="row"
            >
              <details-item-with-tags
                label="DetailsAssociates"
                :values="sanction.associates"
                no-style-tabs
              />
            </div>
            <div class="row">
              <details-item-with-tags
                v-if="sanction.countries && sanction.countries.length"
                label="DetailsCountries"
                :values="sanction.countries"
                no-style-tabs
              />
            </div>
            <div class="row">
              <details-item-simple-text
                v-if="sanction.dob"
                label="DetailsDOB"
                :value="sanction.dob"
              />
            </div>
            <div class="absolute-button" @click="handleRedirect()">
              <ui-link
                :to="{
                  name: 'SanctionDetails',
                  params: {
                    sanctionId: sanction.id,
                    fuzzinessPercent: finalFuzzinessPercent
                  }
                }"
                class="ui-button btn m-btn btn-brand big-link m-btn--wide m-btn--md m-btn--mt15"
              >
                {{ $t('Details') }}
              </ui-link>
              <ui-link
                :to="{
                  name: 'SanctionDetails',
                  params: {
                    sanctionId: sanction.id,
                    fuzzinessPercent: finalFuzzinessPercent,
                    export: true
                  }
                }"
                class="ui-button btn m-btn btn-metal big-link m-btn--wide m-btn--md m-btn--mt15"
                target="_blank"
              >
                {{ $t('AppExport') }}
              </ui-link>
            </div>
          </ui-card>
        </div>
      </div>
      <div v-else class="no-results table-message col-12">
        <div class="text-center">
          {{ $t('TableNoData') }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import DetailsItemWithTags from '@src/components/partials/DetailsItemWithTags'
import DetailsItemFullWidth from '@src/components/partials/DetailsItemFullWidth'
import DetailsItemSimpleText from '@src/components/partials/DetailsItemSimpleText'
import actions from '@src/store/actions'
import { API } from '@src/scripts/enums'

export default {
  components: {
    DetailsItemWithTags,
    DetailsItemFullWidth,
    DetailsItemSimpleText
  },
  props: {
    id: { type: [String, Number], default: null, require: true }
  },

  data: () => ({
    sanctions: null,
    finalFuzzinessPercent: 0,
    isLoading: false
  }),

  async created() {
    this.sanctions = null
    this.isLoading = true
    try {
      const res = await Api.get(API.AML_DETAILS(this.id))
      if (res.data) {
        this.sanctions = this.sanctionsDataMapping(res.data.hits)
        this.finalFuzzinessPercent = res.data.filters.fuzziness
      } else {
        this.sanctions = []
      }
      this.isLoading = false
    } catch (e) {
      this.isLoading = false
    }
  },
  methods: {
    sanctionsDataMapping(sanctions) {
      const cleanSanctions = []
      if (sanctions.length) {
        for (let x = 0; x < sanctions.length; x++) {
          const santion = this.mappedData(sanctions[x])
          cleanSanctions.push(santion)
        }
      }
      this.$store.dispatch(actions.updateSantions, cleanSanctions)
      return cleanSanctions
    },

    handleRedirect() {
      this.$closePopUp()
    },

    associates(aka) {
      let associates = []
      if (aka && aka.length) {
        for (let x = 0; x < aka.length; x++) {
          associates.push(aka[x].name)
        }
      }
      return associates
    },

    mappedData(santion) {
      const countries = this.cleanCountries(
        (santion.doc.fields ?? []).filter((field) => field.name === 'Country')
      )
      const dob = this.dateOfBirthLong(
        (santion.doc.fields ?? []).filter(
          (field) => field.tag === 'date_of_birth'
        )
      )
      const source_notes = this.sourceNotes(
        santion.doc.sources,
        santion.doc.source_notes
      )
      const associates = this.associates(santion.doc.associates)
      const aka = this.associates(santion.doc.aka)
      const images = this.images(santion.doc)
      return {
        id: santion.doc.id,
        match_types: santion.match_types,
        entity_type: santion.doc.entity_type,
        countries,
        fullname: santion.doc.name,
        types: santion.doc.types,
        dob: dob,
        assets: santion.doc.assets,
        medias: santion.doc.media,
        source_notes: source_notes,
        associates: associates || undefined,
        aka: aka || undefined,
        fields: santion.doc.fields,
        images
      }
    },

    images(doc) {
      let images = []
      if (doc.assets) {
        images = doc.assets.filter((field) => field.type === 'picture')
      }
      return images
    },

    cleanCountries(countries) {
      let cleanCountries = []
      if (countries.length) {
        for (let z = 0; z < countries.length; z++) {
          cleanCountries.push(countries[z].value)
        }
      }
      return cleanCountries
    },

    calculateAge(birthday) {
      let newDate = new Date(birthday)
      let ageDifMs = Date.now() - newDate.getTime()
      let ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },

    sourceNotes(sources, source_notes) {
      let sourceNotes = []
      if (sources.length) {
        for (let x = 0; x < sources.length; x++) {
          if (source_notes[sources[x]]) {
            sourceNotes.push(source_notes[sources[x]].name)
          }
        }
      }
      return sourceNotes
    },

    dateOfBirthLong(dateOfBirth) {
      let age = ''
      let dob = ''
      if (dateOfBirth.length) {
        age = this.calculateAge(dateOfBirth[0].value)
        dob =
          dateOfBirth[0].value +
          ' (' +
          this.$t('age') +
          ': ' +
          age +
          ' ' +
          this.$t('YearsOld') +
          ')'
      }
      return dob
    }
  }
}
</script>

<style scoped>
.no-results {
  background: #fefbf6;
  padding: 1rem 2rem;
  font-size: 1.3rem;
}

.details-sanctions-search-title h4,
.details-sanctions-search-title p {
  margin: 0;
}

.santions-list .m-portlet {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  padding-bottom: 4.2rem;
}

.santions-list .m-portlet .absolute-button {
  position: absolute;
  bottom: 2.2rem;
  left: 2.2rem;
}

.santions-list .santions-list-item {
  margin-bottom: 2.2rem;
}

.text-center {
  text-align: center;
}

@media (max-width: 568px) {
  .santions-list .m-portlet .absolute-button {
    position: relative;
    bottom: -1rem;
    left: 0;
    text-align: center;
  }

  .santions-list,
  .santions-list .santions-list-item {
    padding-left: 0;
    padding-right: 0;
  }
}

.santions-list .m-portlet .m-portlet__body {
  padding: 2.2rem;
}

@media (max-width: 568px) {
  .santions-list .m-portlet .m-portlet__body {
    padding: 1rem;
  }
}

@media (max-width: 410px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
