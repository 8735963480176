var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "box-with-header",
    [
      _c(
        "ui-container",
        [
          _vm.revision
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-4" },
                  [
                    _c(
                      "ui-form",
                      { on: { submit: _vm.updateDetails } },
                      [
                        _c(
                          "ui-card",
                          {
                            attrs: {
                              title: "Details",
                              icon: "flaticon-user",
                              dark: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "body" }, slot: "body" },
                              [
                                _c(
                                  "details-box",
                                  [
                                    _c("details-item", {
                                      attrs: {
                                        label: "DetailsFirstName",
                                        value: _vm.userDetails.firstName,
                                        editable: _vm.details.editEnabled,
                                        disabled: _vm.details.isLoading,
                                        error: _vm.details.errors.firstName,
                                        "min-length": 2,
                                        "max-length": 50,
                                      },
                                      on: { change: _vm.firstNameChanged },
                                    }),
                                    _vm._v(" "),
                                    _c("details-item", {
                                      attrs: {
                                        label: "DetailsLastName",
                                        value: _vm.userDetails.lastName,
                                        editable: _vm.details.editEnabled,
                                        disabled: _vm.details.isLoading,
                                        error: _vm.details.errors.lastName,
                                        "min-length": 2,
                                        "max-length": 50,
                                      },
                                      on: { change: _vm.lastNameChanged },
                                    }),
                                    _vm._v(" "),
                                    _c("details-item", {
                                      attrs: {
                                        label: "DetailsEmail",
                                        value: _vm.userDetails.email,
                                        editable: _vm.details.editEnabled,
                                        disabled: _vm.details.isLoading,
                                        error: _vm.details.errors.email,
                                        "max-length": 255,
                                      },
                                      on: { change: _vm.emailChanged },
                                    }),
                                    _vm._v(" "),
                                    _c("details-item", {
                                      attrs: {
                                        label: "DetailsPhoneNumber",
                                        value: _vm.userDetails.phone,
                                        editable: _vm.details.editEnabled,
                                        disabled: _vm.details.isLoading,
                                        error: _vm.details.errors.phone,
                                        "max-length": 22,
                                      },
                                      on: { change: _vm.phoneChanged },
                                    }),
                                    _vm._v(" "),
                                    _c("details-item", {
                                      attrs: {
                                        label: "DetailsPosition",
                                        value: _vm.userDetails.position,
                                        editable: _vm.details.editEnabled,
                                        disabled: _vm.details.isLoading,
                                        error: _vm.details.errors.position,
                                        "max-length": 50,
                                        "min-length": 2,
                                      },
                                      on: { change: _vm.positionChanged },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "details-rule-item",
                                      {
                                        attrs: {
                                          name: "DetailsUserStatus",
                                          status: _vm.getStatus(
                                            _vm.userDetails.userEnabled
                                          ),
                                        },
                                      },
                                      [
                                        _vm.details.editEnabled
                                          ? _c("ui-toggle", {
                                              attrs: {
                                                value:
                                                  _vm.userDetails.userEnabled,
                                                disabled: _vm.details.isLoading,
                                              },
                                              on: {
                                                change: _vm.userEnabledChanged,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.details.editEnabled
                                      ? _c("details-item", {
                                          attrs: {
                                            label: "DetailsNewPassword",
                                            type: "password",
                                            value: _vm.userDetails.password,
                                            editable: _vm.details.editEnabled,
                                            disabled: _vm.details.isLoading,
                                            error: _vm.details.errors.password,
                                            "min-length": 8,
                                            "max-length": 50,
                                          },
                                          on: { input: _vm.passwordChanged },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("details-rule-item", {
                                      attrs: {
                                        name: "DetailsEmailConfirmed",
                                        status: _vm.getStatus(
                                          _vm.userDetails.emailConfirmed
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("edit-footer", {
                              attrs: {
                                slot: "footer",
                                "edit-enabled": _vm.details.editEnabled,
                              },
                              on: { edit: _vm.enableDetailsEdit },
                              slot: "footer",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4" },
                  [
                    _c(
                      "ui-form",
                      { on: { submit: _vm.updateRoles } },
                      [
                        _c(
                          "ui-card",
                          { attrs: { title: "DetailsRoles" } },
                          [
                            _c("editable-list", {
                              attrs: {
                                slot: "body",
                                "add-text": "DetailsAddRole",
                                "empty-text": "DetailsNoRoles",
                                items: _vm.userRoles,
                                options: _vm.roles.options,
                                "has-search": "",
                                "edit-enabled": _vm.roles.editEnabled,
                                disabled: _vm.roles.isLoading,
                              },
                              slot: "body",
                            }),
                            _vm._v(" "),
                            _c("edit-footer", {
                              attrs: {
                                slot: "footer",
                                disabled: _vm.roles.isLoading,
                                "edit-enabled": _vm.roles.editEnabled,
                              },
                              on: { edit: _vm.enableRolesEdit },
                              slot: "footer",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-lg-4" },
                  [
                    _c(
                      "ui-form",
                      { on: { submit: _vm.updateCompanies } },
                      [
                        _c(
                          "ui-card",
                          { attrs: { title: "DetailsCompanies" } },
                          [
                            _c("edit-header", {
                              attrs: {
                                slot: "tools",
                                disabled: _vm.companies.isLoading,
                                "edit-enabled": _vm.companies.editEnabled,
                              },
                              on: { edit: _vm.enableCompaniesEdit },
                              slot: "tools",
                            }),
                            _vm._v(" "),
                            _c("editable-list", {
                              attrs: {
                                slot: "body",
                                "add-text": "DetailsAddCompany",
                                "multi-select": "",
                                all: "",
                                "empty-text": "DetailsNoCompanies",
                                items: _vm.userCompanies,
                                options: _vm.companies.options,
                                "has-search": "",
                                "edit-enabled": _vm.companies.editEnabled,
                                disabled: _vm.companies.isLoading,
                              },
                              on: { deleteAll: _vm.deleteAll },
                              slot: "body",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm.isReady
            ? _c("span", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("DetailsAdminUserNotFound")) +
                    "\n    "
                ),
              ])
            : _c("ui-loader", { attrs: { size: 4, center: "" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }