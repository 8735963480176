<template>
  <p>Git Hash: {{ gitHash }}</p>
</template>
<script>
export default {
  data() {
    return {
      // eslint-disable-next-line no-undef
      gitHash: GitHash
    }
  }
}
</script>
<style scoped>
p {
  margin: 0;
  font-weight: bold;
  padding: 1em;
}
</style>
