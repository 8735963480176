var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.documentData
    ? _c(
        "ui-form",
        { staticClass: "document-data", on: { submit: _vm.submit } },
        [
          _c(
            "ui-card",
            { attrs: { title: "DetailsDocument", icon: "flaticon-avatar" } },
            [
              _vm.hasNfc
                ? _c("div", { attrs: { slot: "tools" }, slot: "tools" }, [
                    _c("div", { staticClass: "m-portlet__head-caption" }, [
                      _c(
                        "div",
                        { staticClass: "nfc-brand m-portlet__head-title" },
                        [
                          _c("h3", { staticClass: "m-portlet__head-text" }, [
                            _vm._v("NFC"),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@src/assets/images/icons/NFC.svg"),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasQr
                ? _c("div", { attrs: { slot: "tools" }, slot: "tools" }, [
                    _c("div", { staticClass: "m-portlet__head-caption" }, [
                      _c(
                        "div",
                        { staticClass: "nfc-brand m-portlet__head-title" },
                        [
                          _c("h3", { staticClass: "m-portlet__head-text" }, [
                            _vm._v("QR"),
                          ]),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("@src/assets/images/icons/QR.svg"),
                            },
                          }),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "details-box",
                { attrs: { slot: "body" }, slot: "body" },
                [
                  _vm._l(_vm.documentData, function (item, key) {
                    return [
                      !_vm.hiddenIf(item)
                        ? _c("details-item", {
                            key: key,
                            staticClass: "ui-input-touched",
                            class: _vm.getValidityClass(item.isValid),
                            attrs: {
                              label: item.label,
                              value: _vm.inputValue(item),
                              error: item.error,
                              editable: _vm.editEnabled,
                              disabled: _vm.disabled,
                              "min-length": item.minLength,
                              "max-length": item.maxLength,
                              "one-line": item.oneLine,
                              "key-value-dropdown": item.keyValueDropdown,
                              options: _vm.optionList(item),
                              "has-search": item.hasSearch,
                              translate: item.translate,
                              placeholder: item.placeholder,
                              "date-picker": item.datePicker,
                            },
                            on: {
                              change: function (value) {
                                return _vm.valueChange(key, value)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.hasMrzRule
                    ? _c("Mrz", {
                        attrs: {
                          values: _vm.mrzFieldValues,
                          "edit-enabled": _vm.editEnabled,
                          identification: _vm.identification,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _vm.canModify
                ? _c("edit-footer", {
                    attrs: {
                      slot: "footer",
                      "edit-enabled": _vm.editEnabled,
                      disabled: _vm.disabled,
                    },
                    on: { edit: _vm.enableEdit },
                    slot: "footer",
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }