var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "CompanyReportSearchTitle",
            method: _vm.searchMethod,
            "method-get": "",
            "reset-form": "",
            "no-extra-payload": "",
            filters: _vm.filters,
            disabled: _vm.isLoading,
            "update-revision": _vm.needUpdate,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            staticClass: "company-name",
            attrs: {
              type: "text",
              placeholder: "DetailsCompanyName",
              medium: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.CompanyName,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "CompanyName", $$v)
              },
              expression: "filters.CompanyName",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              placeholder: "DetailsCountries",
              options: _vm.countries,
              medium: "",
              "has-search": "",
              disabled: _vm.isLoading,
            },
            on: { input: _vm.handleCountrySelect },
            model: {
              value: _vm.countryCode,
              callback: function ($$v) {
                _vm.countryCode = $$v
              },
              expression: "countryCode",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.companyReportDateOptions,
              placeholder: "CompanyReportStartDate",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.date,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "date", $$v)
              },
              expression: "filters.date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("company-reports-table", {
        attrs: {
          "list-items": _vm.orderList,
          loading: _vm.isLoading,
          page: _vm.filters.Page,
          "page-size": _vm.filters.pageSize,
          total: _vm.pageSelectorData.total,
          "page-changed": _vm.pageChanged,
        },
        on: { listReloading: _vm.handleUpdate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }