<template>
  <div v-if="src" class="results-block identification-photo">
    <div class="media-box">
      <video ref="videoBox" preload muted controls :src="src" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: { type: String, default: '' }
  }
}
</script>

<style scoped>
.identification-photo video {
  max-width: 100%;
  max-height: 30rem;
  transition: transform 0.3s ease-in-out;
}

.media-box {
  position: relative;
}
</style>
