function objectWithoutProperties(obj, exclude) {
  var target = {}
  for (var k in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, k) &&
      exclude.indexOf(k) === -1
    )
      target[k] = obj[k]
  return target
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "group" },
    [
      _vm.loading
        ? _c("ui-loader", { attrs: { center: "" } })
        : _vm.error
        ? _c("div", { staticClass: "error" }, [
            _c("b", [_vm._v(_vm._s(_vm.$t("RegistryHubSeizures.loadError")))]),
          ])
        : _c(
            "div",
            [
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("RegistryHubSeizures.seizuresSummary")) + ":"
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group" },
                _vm._l(_vm.seizuresSummary, function (value, key) {
                  return _c("div", { key: key }, [
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.$t("RegistryHubSeizures." + key)) + ":"
                      ),
                    ]),
                    _vm._v(" " + _vm._s(value) + "\n      "),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.$t("RegistryHubSeizures.seizuresDetails")) + ":"
                ),
              ]),
              _vm._v(" "),
              !_vm.hasSeizures
                ? _c("div", { staticClass: "group" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("RegistryHubSeizures.noSeizures"))),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.seizuresDetails, function (ref, index) {
                var isActive = ref.isActive
                var rest = objectWithoutProperties(ref, ["isActive"])
                var detail = rest
                return _c(
                  "div",
                  { key: index, staticClass: "group" },
                  [
                    _vm._l(detail, function (value, key) {
                      return _c("div", { key: key }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("RegistryHubSeizures." + key)) + ":"
                          ),
                        ]),
                        _vm._v(" " + _vm._s(value) + "\n      "),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("RegistryHubSeizures.isActive")) + ":"
                        ),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            isActive
                              ? _vm.$t("RegistryHubSeizures.isActiveYes")
                              : _vm.$t("RegistryHubSeizures.isActiveNo")
                          ) +
                          "\n      "
                      ),
                    ]),
                  ],
                  2
                )
              }),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }