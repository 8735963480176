<template>
  <div class="kyb-message">{{ $t(title) }} - {{ $t(message) }}</div>
</template>

<script>
export default {
  name: 'ui-message',

  props: {
    title: { type: String, default: undefined },
    message: { type: String, default: undefined }
  }
}
</script>

<style scoped>
.kyb-message {
  border-left: 0.8rem solid var(--brand);
  background-color: var(--light-br-gray);
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.25rem 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
