var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "KybCompanySearch",
            method: _vm.searchMethod,
            "method-get": true,
            filters: _vm.filters,
            disabled: _vm.isLoading || _vm.filters.searchText === "",
            "required-filters": _vm.requiredFilters,
            "disable-search-on-load": "",
            "default-payload": false,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            attrs: {
              placeholder:
                _vm.filters.country === "Lt"
                  ? "HeaderCompanyCodePlaceholderCeo"
                  : "HeaderCompanyCodePlaceholder",
              icon: "la-institution",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.searchText,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "searchText", $$v)
              },
              expression: "filters.searchText",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.selectCountryList,
              placeholder: "DetailsCountry",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.country,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "country", $$v)
              },
              expression: "filters.country",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.clickedSearch
        ? _c(
            "ui-container",
            [
              _c(
                "ui-card",
                {
                  attrs: {
                    icon: "la la-institution",
                    title: "AppSearchResults",
                    count: _vm.pageSelectorData.total,
                  },
                },
                [
                  !_vm.isLoading
                    ? [
                        _c(
                          "ui-table",
                          {
                            staticClass: "hide-mobile",
                            attrs: {
                              labels: _vm.tableLabels,
                              data: _vm.list,
                              loading: _vm.isLoading,
                              "empty-text": "DetailsKybNotFound",
                            },
                          },
                          [
                            _vm.list
                              ? _vm._l(_vm.list, function (row, index) {
                                  return _c(
                                    "tr",
                                    {
                                      key: index,
                                      staticClass: "m-table__row",
                                      class: {
                                        "status-pending":
                                          row.requestStatus === "Pending",
                                        "status-rejected":
                                          row.requestStatus === "Rejected",
                                      },
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(row.name))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(row.code))]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            row.address ? row.address : "-"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            row.employeeNumber
                                              ? row.employeeNumber
                                              : "-"
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "td-link" },
                                        [
                                          _c(
                                            "ui-button",
                                            {
                                              staticClass: "btn-brand",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickViewButton(
                                                    row.code,
                                                    _vm.filters.country
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.$t("TableView")) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                })
                              : _vm._e(),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.list
                          ? _vm._l(_vm.list, function (row, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "response-table show-mobile",
                                },
                                [
                                  _c("responsive-table-tr", {
                                    attrs: {
                                      title: "DetailsCompanyName",
                                      value: row.name,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("responsive-table-tr", {
                                    attrs: {
                                      title: "DetailsCompanyCode",
                                      value: row.code,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("responsive-table-tr", {
                                    attrs: {
                                      title: "DetailsAddress",
                                      value: row.address ? row.address : "-",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("responsive-table-tr", {
                                    attrs: {
                                      title: "DetailsEmployeesTitle",
                                      value: row.employeeNumber
                                        ? row.employeeNumber
                                        : "-",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "m--margin-top-10" },
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn-brand big-link",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickViewButton(
                                                row.code,
                                                _vm.filters.country
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            })
                          : _c(
                              "div",
                              { staticClass: "table-message show-mobile" },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("DetailsKybNotFound")) +
                                    "\n        "
                                ),
                              ]
                            ),
                      ]
                    : _c("ui-loader", { attrs: { size: 4, center: "" } }),
                  _vm._v(" "),
                  _c("ui-page-selector", {
                    attrs: {
                      "active-page": _vm.pageSelectorData.page,
                      "items-count": _vm.pageSelectorData.total,
                      "max-on-page": _vm.pageSelectorData.pageSize,
                      disabled: _vm.isLoading,
                    },
                    on: { change: _vm.pageChanged },
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }