import Vue from 'vue'
import Vuex from 'vuex'
import { methods as actions } from '@src/store/actions'
import { methods as getters } from '@src/store/getters'
import { methods as mutations } from '@src/store/mutations'
import {
  cachedData,
  cachedString,
  sessionKey,
  historyKey,
  companyKey,
  settingsKey,
  hideLogsKey,
  sanctionsKey,
  sessionTimeNotifyKey,
  kybKey,
  stopListKey,
  registriesKey
} from '@src/store/cache'
import { state as kyb } from '@src/store/kyb'

Vue.use(Vuex)

const state = {
  session: cachedData(sessionKey),
  resources: {},
  browserData: null,
  settings: cachedData(settingsKey),
  history: cachedData(historyKey),
  selectedCompany: cachedString(companyKey),
  sanctions: cachedData(sanctionsKey),
  inProgress: false,
  hideLogs: cachedString(hideLogsKey),
  sessionTimeNotify: cachedString(sessionTimeNotifyKey),
  kybList: cachedData(kybKey),
  stopList: cachedData(stopListKey),
  registries: cachedData(registriesKey),
  ...kyb
}

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})

export default store
