<template>
  <div class="ui-tag-group">
    <span v-if="label" class="details-label">
      {{ $t(label) }}
    </span>
    <span v-if="editable" class="display-flex-row">
      <span
        v-for="(tag, index) in tags"
        :key="index"
        class="ui-tag"
        :class="{ 'editable-tag': editable }"
      >
        {{ tag }}
        <span class="delete-tag" @click="removeTag(tag)"> x </span>
      </span>
      <div class="tag-input">
        <ui-input
          :placeholder="placeholder"
          :value="newTag"
          @change="changeInput"
        />
      </div>
    </span>
    <span v-else class="ui-tag">
      {{ tags.join(', ') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ui-tag-group',

  props: {
    tags: { type: Array, default: undefined },
    label: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    editable: Boolean
  },

  data() {
    return {
      newTag: null
    }
  },

  methods: {
    removeTag(tag) {
      this.$emit('removeTag', tag)
    },

    changeInput(value) {
      this.$emit('addTag', value)
      this.newTag = null
    }
  }
}
</script>

<style scoped>
.details-label {
  padding: 0.85rem 0;
}

.ui-tag-group {
  padding: 0.2rem 1.05rem 0.2rem 2.2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.display-flex-row {
  display: flex;
  flex-wrap: wrap;
}

.tag-input {
  width: 15rem;
  margin: 0.5rem 0 0.5rem 0;
}

.ui-tag {
  font-family: sans-serif;
  font-size: 110%;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  padding: 0.85rem 1rem 0.85rem 1rem;
  text-align: end;
}

.ui-tag.editable-tag {
  border: 1px solid var(--brand);
  border-radius: 3px;
  margin: 0.5rem 1rem 0.5rem 0;
  height: calc(2.95rem + 3px);
  width: auto;
}

.delete-tag {
  color: var(--brand);
  margin-left: 1rem;
  padding: 1px 5px;
  cursor: pointer;
}

.delete-tag:hover {
  background-color: var(--brand);
  border-radius: 50%;
  color: var(--white);
}
</style>
