<template>
  <iframe :class="{ high }" :src="formUrl" />
</template>

<script>
import EventController, { eventTypes } from '@src/scripts/eventController'

export default {
  props: {
    setup: { type: Object, default: null },
    data: { type: Object, default: null },
    type: { type: String, default: null },
    id: { type: String, default: null },
    high: Boolean,
    edit: Boolean
  },

  data() {
    return {
      isReady: false,
      formEvents: null,
      formUrl: null,
      language: localStorage.getItem('language') || 'en'
    }
  },

  mounted() {
    this.loadForm()
  },

  methods: {
    loadForm() {
      if (this.isReady || !this.type) return

      this.formEvents = new EventController({
        target: this.$el,
        allowedOrigin: BaseUrl.form
      })

      this.formEvents.addListener(eventTypes.formReady, this.formReady)
      this.setUrl()
      this.isReady = true
    },

    setUrl() {
      const title = this.type.toLowerCase()
      this.formUrl = `${BaseUrl.form}/forms/${title}/index.html?v=${Date.now()}`
    },

    formReady() {
      const { formEvents, setup, data, id, language } = this
      formEvents.triggerSuccess(eventTypes.formLoad, {
        settings: setup,
        customData: data,
        identificationId: id,
        language,
        reviewRequired: true,
        editDisabled: !this.edit,
        styles: {
          default: `${BaseUrl.form}/customs/ondato/styles.css`
        }
      })
    }
  }
}
</script>

<style scoped>
iframe {
  width: 100%;
  border: none;
}

.high {
  min-height: 80vh;
}
</style>
