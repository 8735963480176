var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row regional-table" }, [
    _c("div", { staticClass: "flex-row flex-basis-50" }, [
      _c(
        "div",
        { staticClass: "country-desc flex-basis-40" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.regions.title))]),
          _vm._v(" "),
          _vm._l(_vm.regions.description, function (description, key) {
            return _c("span", { key: key }, [
              _vm._v("\n        " + _vm._s(description) + "\n      "),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-basis-40 align-center" }, [
        _c("img", {
          attrs: { src: require("@src/assets/images/" + _vm.regions.imgSrc) },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-row flex-basis-50" }, [
      _c(
        "div",
        { staticClass: "flex-basis-18" },
        [
          _c("div", { staticClass: "mobile-switch title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("CheckSettingPassport")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ui-toggle", {
            attrs: { value: _vm.regions.passport, disabled: _vm.disabled },
            on: { change: _vm.passportChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-basis-18" },
        [
          _c("div", { staticClass: "mobile-switch title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("CheckSettingId")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ui-toggle", {
            attrs: { value: _vm.regions.id, disabled: _vm.disabled },
            on: { change: _vm.idChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-basis-18" },
        [
          _c("div", { staticClass: "mobile-switch title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("CheckSettingDriving")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ui-toggle", {
            attrs: { value: _vm.regions.driving, disabled: _vm.disabled },
            on: { change: _vm.drivingChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-basis-18" },
        [
          _c("div", { staticClass: "mobile-switch title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("CheckSettingResidence")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ui-toggle", {
            attrs: { value: _vm.regions.residence, disabled: _vm.disabled },
            on: { change: _vm.residenceChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-basis-18" },
        [
          _c("div", { staticClass: "mobile-switch title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("CheckSettingOther")) + "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("ui-toggle", {
            attrs: { value: _vm.regions.other, disabled: _vm.disabled },
            on: { change: _vm.otherChanged },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }