<template>
  <div v-if="isLoggedIn" class="m-grid m-grid--hor m-grid--root m-page">
    <page-header />
    <div
      class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body"
    >
      <page-side-bar />
      <ui-loader v-if="isLoading" :size="4" center />
      <router-view
        v-else-if="showContent && !isLoading"
        class="m-grid__item m-grid__item--fluid m-wrapper"
      />
      <div v-else>
        {{ $t('DetailsNoRoles') }}
      </div>
      <scroll-arrow />
    </div>
    <page-footer v-if="showFooter" />
    <app-notification />
  </div>
  <landing-page v-else />
</template>

<script>
import PageHeader from '@src/components/partials/PageHeader'
import PageSideBar from '@src/components/partials/PageSideBar'
import PageFooter from '@src/components/partials/PageFooter'
import ScrollArrow from '@src/components/partials/ScrollArrow'
import LandingPage from '@src/components/pages/LandingPage'
import getters from '@src/store/getters'
import actions from '@src/store/actions'
import Api from '@src/scripts/api'
import { mapGetters } from 'vuex'
import { pageTypeEnum } from '@src/scripts/enums'

export default {
  components: {
    PageHeader,
    PageSideBar,
    PageFooter,
    ScrollArrow,
    LandingPage
  },

  data() {
    return {
      isLoading: true,
      nowTime: null,
      clockTime: null,
      timeToRefreshSession: null,
      notification: null
    }
  },

  computed: {
    ...mapGetters([
      getters.isLoggedIn,
      getters.user,
      getters.havePermission,
      getters.settingsData,
      getters.sessionData,
      getters.selectedCompany,
      getters.companies
    ]),

    defaultPage() {
      return this.user && this.user.defaultPage
    },

    pageName() {
      return this.$route.name
    },

    showContent() {
      const { havePermission, pageName } = this
      return havePermission(pageName)
    },

    showFooter() {
      return !this.$route.meta.hideFooter
    },

    sessionTime() {
      if (this.parseJwt(this.sessionData.access_token))
        return this.parseJwt(this.sessionData.access_token).exp
      else return null
    }
  },

  watch: {
    $route(page) {
      this.checkRoute(page.name)
    }
  },

  async created() {
    const { isLoggedIn, pageName, updateUserSettings, checkRoute } = this
    if (isLoggedIn) {
      // if (Object.keys(this.settingsData).length > 0) this.isLoading = false
      await updateUserSettings()
      checkRoute(pageName)
    }
    this.nowTime = new Date() / 1000
    const timeToNotify = (this.sessionTime - this.nowTime) * 1000
    // const timeToNotify = 2000
    // const timeToRefreshSession = 1000
    if (this.$store.getters.sessionTimeNotify !== 'clear') {
      this.notification = setTimeout(() => {
        this.nowTime = new Date() / 1000
        this.timeToRefreshSession = this.sessionTime - this.nowTime + 300
        this.$notify({
          type: 'time-left',
          message: 'finishSessionErrorText',
          cancelCallback: this.callback,
          messageData: {
            messageData: this.secondsToTime(this.timeToRefreshSession)
          }
        })
        this.refreshDate()
      }, timeToNotify)
    }
    this.isLoading = false
  },

  destroyed() {
    clearInterval(this.clockTime)
    clearTimeout(this.notification)
  },

  methods: {
    callback() {
      clearTimeout(this.notification)
      this.$store.dispatch(actions.updateSessionTimeNotify, 'clear')
    },

    refreshDate() {
      this.clockTime = setInterval(() => {
        this.nowTime = new Date() / 1000
        this.timeToRefreshSession = this.sessionTime - this.nowTime + 300
        if (document.querySelector('.notification .time-left .message'))
          document.querySelector(
            '.notification .time-left .message'
          ).innerHTML = this.$t('finishSessionErrorText', {
            messageData: this.secondsToTime(this.timeToRefreshSession)
          })
      }, 1000)
    },

    secondsToTime(secs) {
      if (secs < 1) {
        clearInterval(this.clockTime)
        setTimeout(() => {
          this.$store.dispatch(actions.updateSession)
        }, 1000)
        return '0:0'
      }
      secs = Math.round(secs)
      const divisor_for_minutes = secs % (60 * 60)
      const minutes = Math.floor(divisor_for_minutes / 60)
      const divisor_for_seconds = divisor_for_minutes % 60
      const seconds = Math.ceil(divisor_for_seconds)
      return `${minutes}:${seconds}  `
    },

    parseJwt(token) {
      if (token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )

        return JSON.parse(jsonPayload)
      }
    },

    checkRoute(routeName) {
      if (routeName === 'App') {
        const { defaultPage } = this
        if (defaultPage) {
          this.$router.replace({
            name: pageTypeEnum[defaultPage.replace(/_/g, '')]
          })
        }
      }
    },

    async updateUserSettings() {
      try {
        const response = await Api.get('/settings/app')

        this.$store.dispatch(actions.updateSettings, response)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    }
  }
}
</script>
