var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.mediaMap, function (ref) {
      var key = ref[0]
      var items = ref[1]
      return _c("ui-card-slider", {
        key: key,
        staticClass: "text-uppercase media-export-block",
        attrs: {
          title: key,
          "slide-count": items.length,
          "slider-title": _vm.$t("AppAttempts"),
          icon: "flaticon-user",
          "has-auto-capture": items.find(function (item) {
            return item.autoCaptureConfidenceRate
          }),
          items: items,
          dark: "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var currentSlide = ref.currentSlide
                return [
                  _c(
                    "div",
                    { staticClass: "slider-wrapper" },
                    _vm._l(items, function (item, index) {
                      return _c("ui-card-slider-item", { key: index }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: currentSlide === index + 1,
                                expression: "currentSlide === index + 1",
                              },
                            ],
                            staticClass: "slide-info",
                          },
                          [
                            item.fileType === _vm.MEDIA_TYPES.FACE_VIDEO
                              ? _c("video-box", { attrs: { src: item.file } })
                              : _c("photo-box", {
                                  attrs: {
                                    data: item,
                                    token: _vm.identificationId,
                                  },
                                }),
                          ],
                          1
                        ),
                      ])
                    }),
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }