<template>
  <div class="tabs-group">
    <div class="tabs-list">
      <a
        v-for="(tab, index) in labels"
        :key="index"
        class="tab-item"
        :class="[{ active: index === tabIndex }, indicator(index)]"
        @click="changeTab(index)"
      >
        <span>
          {{ tab === Object(tab) ? $t(tab.label) : $t(tab) }}
          <img
            v-if="tab.icon"
            class="tab-icon"
            :src="require(`@src/assets/images/icons/${tab.icon}`)"
          />
        </span>
      </a>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabIndex: { type: Number, default: 0 },
    labels: { type: Array, default: undefined },
    indicators: { type: Array, default: undefined }
  },

  methods: {
    changeTab(value) {
      if (this.tabIndex === value) return
      this.$emit('change', value)
    },

    indicator(index) {
      const { indicators } = this
      if (!indicators || indicators.length <= index) return

      const value = indicators[index]
      if (typeof value !== 'boolean') return

      if (value) return 'success'
      else return 'failed'
    }
  }
}
</script>

<style scoped>
.tabs-group .tabs-list {
  display: flex;
  min-height: 3rem;
}

.tab-item {
  display: flex;
  width: 100%;
  max-width: 15rem;
  background-color: var(--white);
  position: relative;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 1px 3px;
  transition: all 0.2s ease;
}

.tab-item > span {
  margin: auto;
  padding: 0.5rem;
}

.tab-item.success {
  border-bottom-color: var(--green);
}

.tab-item.failed {
  border-bottom-color: var(--red);
}

.tab-item.active {
  border-color: var(--brand);
}

.tab-item:hover {
  background-color: var(--light-gray);
  cursor: pointer;
}

.tabs-content {
  position: relative;
}

.tab-icon {
  width: 1.07rem;
}

@media (max-width: 568px) {
  .tabs-list {
    flex-direction: column;
  }

  .tab-item {
    max-width: 100%;
  }
}

.tab-item::before {
  content: '';
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  z-index: 1;
}
</style>
