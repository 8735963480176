<template>
  <div class="batch-list-modal">
    <h2>{{ $t('KybMonitoringBatchUploadTitle') }}</h2>
    <p class="fixed-width">
      <b>{{ $t('KybMonitoringBatchUploadStep1') }}</b>
    </p>
    <div class="batch-list-radio-container">
      <ui-radio
        :label="$t('KybMonitoringBatchUploadAdd')"
        :value="true"
        name="batchUploadDeleteRadio"
        checked
        @checkRadio="handleToggleChange"
      ></ui-radio>
      <ui-radio
        :label="$t('KybMonitoringBatchUploadRemove')"
        :value="false"
        name="batchUploadDeleteRadio"
        @checkRadio="handleToggleChange"
      >
      </ui-radio>
    </div>
    <p class="fixed-width">
      <b>{{ $t('KybMonitoringBatchUploadStep2') }}</b>
    </p>
    <ui-input
      class="codes fixed-width"
      :placeholder="'10000000 \n20000000 \n30000000'"
      :rows="10"
      :value="companyCodesStr"
      multiline
      multiline-overflow
      @input="handleInputChange"
    />
    <ui-button
      class="btn-brand submit-btn"
      :disabled="!companyCodesArr.length || isLoading"
      @click="
        () => {
          handleSubmit(companyCodesArr)
        }
      "
    >
      {{ buttonTitleResolved }}
    </ui-button>
    <loader
      v-if="isLoading"
      title="KybCompaniesAreBeingUploaded"
      description="KybNotifiedWhenCompaniesWillBeAdded"
      :response="response"
    />
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import Loader from '@src/components/partials/Loader.vue'
import { API } from '@src/scripts/enums'
import { BATCH_IMPORT_CODES_SEPARATION_REGEXP } from '@src/scripts/constants'

export default {
  components: {
    Loader
  },

  data() {
    return {
      isLoading: false,
      isActionAdd: true,
      companyCodesStr: '',
      companyCodesArr: [],
      response: null
    }
  },
  computed: {
    buttonTitleResolved() {
      if (this.isLoading) {
        return this.$t('App.Loading')
      }
      if (!this.isActionAdd) {
        return this.$t('KybMonitoringBatchUploadRemove')
      }

      return this.$t('KybMonitoringBatchUploadAdd')
    }
  },
  methods: {
    handleToggleChange(val) {
      this.isActionAdd = val === 'true'
    },

    handleInputChange(val) {
      this.companyCodesArr = val
        .split(BATCH_IMPORT_CODES_SEPARATION_REGEXP)
        .map((item) => item.trim())
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    closePopUpAndUpdatePage() {
      this.isLoading = false
      this.$emit('updateCompanies')
      this.$closePopUp()
    },

    async handleSubmit(companyCodes) {
      this.isLoading = true

      try {
        const action = this.isActionAdd ? 'post' : 'del'
        await Api[action](API.MONITORING_COMPANIES, {
          companyCodes,
          country: 'Lt'
        })

        this.response = this.isActionAdd
          ? 'AppSuccessfullyAdded'
          : 'AppSuccessfullyDeleted'

        setTimeout(() => this.closePopUpAndUpdatePage(), 1000)
      } catch (error) {
        this.isLoading = false
        this.showError()
        return null
      }
    }
  }
}
</script>

<style scoped>
.batch-list-modal {
  text-align: center;
  position: relative;
}

.batch-list-radio-container,
div.codes {
  position: relative;
  width: 180px;
  margin: 0 auto;
}

.fixed-width {
  margin: 1rem 5rem;
}

.submit-btn {
  margin-top: 1rem;
}
</style>
