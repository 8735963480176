<template>
  <div>
    <ui-header title="SecureMeetingsSetup" disable-search-on-load />
    <ui-container>
      <ui-loader v-if="isLoading" center />
      <div v-if="redirectUrl" class="grid">
        <sharable-meeting
          :redirect-url="redirectUrl"
          :is-saving="isUrlSaving"
          :last-save="lastUrlAction"
          @redirectUrlUpdate="onRedirectUrlUpdate"
        />
        <sharable-meeting-whitelist
          :whitelist="whitelistState"
          :last-save="lastWhitelistAction"
          @add="whitelistItemAdd"
          @update="whitelistItemUpdate"
          @remove="whitelistItemRemove"
        />
      </div>
    </ui-container>
  </div>
</template>

<script>
import SharableMeeting from '@src/components/pages/meetings/SharableMeeting'
import Api from '@src/scripts/api'
import SharableMeetingWhitelist from '@src/components/pages/meetings/SharableMeetingWhitelist'
import { dateToString } from '@src/scripts/helpers'

export default {
  components: { SharableMeetingWhitelist, SharableMeeting },
  data() {
    return {
      lastUrlAction: null,
      lastWhitelistAction: null,
      isLoading: true,
      redirectUrl: null,
      whitelist: null,
      whitelistPendingChanges: {},
      redirectUrlPending: null,
      isUrlSaving: false
    }
  },
  computed: {
    whitelistState() {
      return this.whitelist?.map((item) => ({
        ...item,
        isSaving: !!this.whitelistPendingChanges[item.id]
      }))
    }
  },
  mounted() {
    this.fetchMeetings()
  },
  methods: {
    updateLastUrlAction() {
      this.lastUrlAction = dateToString(new Date(), true)
    },
    updateLastWhitelistAction() {
      this.lastWhitelistAction = dateToString(new Date(), true)
    },
    async whitelistItemAdd(personalCode) {
      try {
        const { id } = await Api.post('/securemeetings/person-whitelist', {
          personalCode
        })
        this.whitelist.push({ id, personalCode })
        this.updateLastWhitelistAction()
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    whitelistDelayedChange(id, callback) {
      clearTimeout(this.whitelistPendingChanges[id])
      this.$set(
        this.whitelistPendingChanges,
        id,
        setTimeout(() => {
          try {
            callback()
            this.$set(this.whitelistPendingChanges, id, null)
            this.updateLastWhitelistAction()
          } catch (error) {
            this.$notify({
              title: 'ErrorUnknownTitle',
              message: 'ErrorUnknownDescription'
            })
          }
        }, 1000)
      )
    },
    whitelistItemUpdate({ id, personalCode }) {
      this.whitelist = this.whitelist.map((item) => {
        if (item.id === id) {
          return { id, personalCode }
        }
        return item
      })
      this.whitelistDelayedChange(id, async () => {
        await Api.put(`/securemeetings/person-whitelist/${id}`, {
          personalCode
        })
      })
    },
    async whitelistItemRemove(id) {
      try {
        await Api.del(`/securemeetings/person-whitelist/${id}`)
        this.whitelist = this.whitelist.filter((item) => item.id !== id)
        this.updateLastWhitelistAction()
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    onRedirectUrlUpdate(redirectUrl) {
      this.isUrlSaving = true
      clearTimeout(this.redirectUrlPending)
      this.redirectUrlPending = setTimeout(() => {
        this.saveMeetingUrl(redirectUrl)
        this.isUrlSaving = false
      }, 1000)
      this.redirectUrl = redirectUrl
    },
    async fetchMeetings() {
      this.isLoading = true
      try {
        const meetings = await Api.get('/securemeetings/details')
        this.redirectUrl = meetings.redirectUrl
        this.whitelist = meetings.whitelist
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
      this.isLoading = false
    },
    async saveMeetingUrl(redirectUrl) {
      try {
        await Api.put('/securemeetings/redirect-url', { redirectUrl })
        this.updateLastUrlAction()
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    }
  }
}
</script>

<style>
.grid {
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
}

@media all and (min-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
