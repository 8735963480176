<template>
  <div>
    <ui-header
      title="KybCompanySearch"
      :method="searchMethod"
      method-get
      no-extra-payload
      :filters="filters"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.CompanyCode"
        placeholder="HeaderCompanyCodePlaceholder"
        icon="la-institution"
        wide
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.date"
        :options="monitoringEventsDateOptions"
        placeholder="HeaderDatePlaceHolder"
        wide
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.ChangeType"
        :options="[
          { key: '', value: 'DetailsChangeType' },
          { key: 'CompanyName', value: 'Kyb.Search.CompanyName' },
          { key: 'CompanyStatus', value: 'DetailsCompanyStatus' },
          { key: 'VatNumber', value: 'DetailsVatNumber' },
          { key: 'Ceo', value: 'DetailsCompanyHead' },
          { key: 'Shareholder', value: 'Shareholder' },
          { key: 'Address', value: 'DetailsAddress' }
        ]"
        placeholder="DetailsChangeType"
        wide
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.ReviewStatus"
        :options="[
          { key: '', value: 'KybMonitoringAllChanges' },
          { key: 'Reviewed', value: 'KybMonitoringViewedChanges' },
          { key: 'NotReviewed', value: 'KybMonitoringNotViewedChanges' }
        ]"
        placeholder="KybMonitoringAllChanges"
        wide
        :disabled="isLoading"
      />
    </ui-header>
    <ui-container>
      <ui-card icon="la la-institution">
        <template v-if="!isLoading">
          <ui-table
            :labels="tableLabels"
            class="hide-mobile"
            :data="events"
            :loading="isLoading"
            empty-text="AppNoEventsFound"
          >
            <template v-if="events">
              <tr
                v-for="(row, index) in events"
                :key="index"
                class="m-table__row"
                :class="{
                  'status-pending': row.requestStatus === 'Pending',
                  'status-rejected': row.requestStatus === 'Rejected'
                }"
              >
                <td>{{ row.companyName }}</td>
                <td>{{ row.companyCode }}</td>
                <td>{{ row.changeType }}</td>
                <td>{{ row.previousValue }}</td>
                <td>{{ row.newValue }}</td>
                <td>{{ row.localDate }}</td>
                <td>
                  <ui-toggle
                    :value="row.isReviewed"
                    :disabled="row.isReviewed"
                    @change="
                      (value) =>
                        toggleIsViewed(value, row.companyCode, row.eventId)
                    "
                  />
                </td>
                <td>
                  <ui-button
                    slot="tools"
                    class="btn-metal"
                    icon="la la-download"
                    small
                    @click="showStatements(row.companyCode)"
                  >
                    {{ $t('AppStatements') }}
                  </ui-button>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="events">
            <div
              v-for="(row, index) in events"
              :key="index"
              class="response-table show-mobile"
            >
              <responsive-table-tr
                title="DetailsCompanyName"
                :value="row.companyName"
              />
              <responsive-table-tr
                title="DetailsCompanyCode"
                :value="row.companyCode"
              />
              <responsive-table-tr
                title="DetailsChangeType"
                :value="row.changeType"
              />
              <responsive-table-tr
                title="AppPreviosValue"
                :value="row.previousValue"
              />
              <responsive-table-tr title="AppNewValue" :value="row.newValue" />
              <responsive-table-tr
                title="DetailsChangeDate"
                :value="row.localDate"
              />
              <div class="m--margin-top-10">
                <ui-toggle
                  :value="row.isReviewed"
                  :disabled="row.isReviewed"
                  @change="
                    (value) =>
                      toggleIsViewed(value, row.companyCode, row.eventId)
                  "
                />
              </div>
              <div class="m--margin-top-10">
                <ui-button
                  slot="tools"
                  class="btn-metal"
                  icon="la la-download"
                  small
                  @click="
                    () => {
                      showStatements(row.companyCode)
                    }
                  "
                >
                  {{ $t('AppStatements') }}
                </ui-button>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('AppNoEventsFound') }}
          </div>
        </template>
        <ui-loader v-else :size="4" center />
        <ui-page-selector
          :active-page="pageSelectorData.page"
          :items-count="pageSelectorData.count"
          :max-on-page="pageSelectorData.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
      <pop-up>
        <kyb-statements :company-code="companyCode" no-other-documents />
      </pop-up>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import {
  dateToString,
  createMonitoringEventsDateOptions
} from '@src/scripts/helpers'
import { API } from '@src/scripts/enums'

import KybStatements from '@src/components/partials/KybStatements'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'

export default {
  components: {
    ResponsiveTableTr,
    KybStatements
  },

  props: {
    changePage: { type: Function, default: undefined }
  },

  data() {
    return {
      searchMethod: API.MONITORING_EVENTS,
      filters: {
        CompanyCode: null,
        date: null,
        ChangeType: null,
        ReviewStatus: null,
        page: 1
      },
      monitoringEventsDateOptions: createMonitoringEventsDateOptions(),
      pageSelectorData: {
        page: 1,
        pageSize: 30,
        count: 0
      },
      events: [],
      companyCode: 0,
      isLoading: false,
      tableLabels: [
        'DetailsCompanyName',
        'DetailsCompanyCode',
        'DetailsChangeType',
        'AppPreviosValue',
        'AppNewValue',
        'TableChangeDate',
        'AppViewed',
        ''
      ]
    }
  },

  methods: {
    startSearch() {
      this.isLoading = true
    },

    finishSearch(response) {
      if (!response.events) return

      const { count, page, pageSize, events } = response
      this.events = events.map((item) => ({
        ...item,
        localDate: dateToString(item.changeDate)
      }))
      this.pageSelectorData = {
        page,
        pageSize,
        count
      }
      this.isLoading = false
    },

    clickViewButton(companyCode) {
      this.$router.push({
        name: 'KybPageDetails',
        params: { companyCode, countryCode: 'Lt' }
      })
    },

    pageChanged(value) {
      this.pageSelectorData.page = value
      this.$emit('changePage', value, this.filters)
    },

    async postEventAsReviewed(eventId) {
      try {
        await Api.patch(`${API.MONITORING_EVENTS}/${eventId}/reviewed`)
      } catch (error) {
        return null
      }
    },

    toggleIsViewed(value, code, eventId) {
      this.events = this.events.map((rev) => {
        if (rev.companyCode === code && rev.eventId === eventId) {
          return {
            ...rev,
            isReviewed: value
          }
        }

        return rev
      })

      if (value) {
        this.postEventAsReviewed(eventId)
      }
    },

    showStatements(companyCode) {
      this.companyCode = companyCode.toString()
      this.$openPopUp()
    }
  }
}
</script>

<style scoped>
.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.italic-text {
  font-style: italic;
  color: red;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

@media all and (max-width: 992px) {
  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
