<template>
  <div />
</template>

<script>
import { handleSignIn } from '@src/scripts/identity'

export default {
  async created() {
    try {
      await handleSignIn()
    } catch (error) {
      // empty
    } finally {
      this.$router.replace('Landing')
    }
  }
}
</script>
