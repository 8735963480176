var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-selector" },
    [
      _c(
        "ui-drop-down",
        {
          attrs: {
            value: _vm.comparedValues.hours,
            disabled: _vm.disabled,
            nullable: _vm.nullable,
            narrow: "",
            center: "",
          },
          on: { input: _vm.hoursChanged },
        },
        _vm._l(_vm.hours, function (item) {
          return _c(
            "option",
            { key: item.key, domProps: { value: item.key } },
            [_vm._v("\n      " + _vm._s(item.value) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("span", { staticClass: "separator" }, [_vm._v(":")]),
      _vm._v(" "),
      _c(
        "ui-drop-down",
        {
          attrs: {
            value: _vm.comparedValues.minutes,
            disabled: _vm.disabled,
            nullable: _vm.nullable,
            narrow: "",
            center: "",
          },
          on: { input: _vm.munutesChanged },
        },
        _vm._l(_vm.minutes, function (item) {
          return _c(
            "option",
            { key: item.key, domProps: { value: item.key } },
            [_vm._v("\n      " + _vm._s(item.value) + "\n    ")]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }