<template>
  <div class="document-item-container">
    <div class="document-item-body">
      <div class="document-info">
        <img class="icon" :src="require('@src/assets/images/file.svg')" />
        <div
          v-if="hasFile"
          class="title download-title"
          @click="handleDownload"
        >
          {{ title }}
        </div>
        <div v-else class="title">{{ title }}</div>

        <span class="delete-btn" @click="handleDelete">{{
          $t('DocumentDelete')
        }}</span>
      </div>
      <div class="document-date">{{ dateToString(createdUtc, true) }}</div>
    </div>
    <div class="document-body-actions">
      <template v-if="hasFile">
        <div class="document-status-container">
          <div class="document-status-title">{{ $t('DocumentStatus') }}:</div>

          <div v-if="status === 'Validated'" class="document-status-value">
            <i class="rule-icon flaticon-interface-7 m--font-success"></i>
            {{ $t('DocumentStatusValid') }}
          </div>

          <div v-if="status === 'Invalidated'" class="document-status-value">
            <i class="rule-icon la la-times-circle m--font-danger"></i>
            {{ $t('DocumentStatusInvalid') }}
          </div>

          <div
            v-if="status === 'NeedReview'"
            class="document-status-value document-status-need-review"
          >
            {{ $t('DocumentStatusDefault') }}
          </div>
          <div class="document-date">
            {{ dateToString(lastActionUtc, true) }}
          </div>
        </div>
        <div class="dropdown-container">
          <ui-dropdown
            v-model="reviewStatus"
            :disabled="isLoading"
            :placeholder="$t('DocumentReview')"
            :options="[
              { value: $t('DocumentDataIsCorrect'), key: 'Validated' },
              { value: $t('DocumentDataIsNotCorrect'), key: 'Invalidated' }
            ]"
            small
            @input="handleStatusChange"
          />
        </div>
      </template>
      <ui-button
        v-else
        class="btn-brand document-button"
        @click="$emit('openDocumentModal', true)"
      >
        {{ $t('DocumentUpload') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { dateToString, downloadBlob } from '@src/scripts/helpers'
import Api from '@src/scripts/api'

export default {
  props: {
    id: { type: String, default: undefined },
    applicationId: { type: String, default: undefined },
    createdUtc: { type: String, default: undefined },
    hasFile: { type: Boolean, default: undefined },
    identificationId: { type: String, default: undefined },
    lastActionUtc: { type: String, default: undefined },
    title: { type: String, default: undefined },
    status: { type: String, default: undefined },
    purpose: { type: String, default: undefined }
  },
  data: () => ({
    reviewStatus: '',
    isLoading: false
  }),
  methods: {
    async handleDownload() {
      try {
        const blob = await Api.getNewBlob(`/kyb-documents/${this.id}/file`)

        downloadBlob({
          file: blob,
          fileName: `${this.title}.${blob.type.split('/')[1]}`
        })
      } catch {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    dateToString,
    handleDelete() {
      Api.del(`/kyb-documents/${this.id}`)
        .then(() => this.$emit('reloadDocument', true))
        .catch(() => {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
        })
    },
    async handleStatusChange(value) {
      try {
        this.isLoading = true
        await Api.patch(`/kyb-documents/${this.id}`, {
          documentStatus: value
        })

        this.$emit('reloadDocument', true)
        this.isLoading = false
      } catch {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    }
  }
}
</script>

<style scoped>
.document-item-body {
  width: 230px;
  margin-right: 2rem;
}
.title {
  font-size: 18px;
  line-height: 21px;
  text-decoration: underline;
  color: #000;
  padding-right: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.delete-btn {
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #a9a8bd;
  cursor: pointer;
}
.document-date {
  color: #c8cad9;
  font-size: 14px;
  line-height: 16px;
}
.document-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 5px;
}

.download-title {
  cursor: pointer;
}

.document-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 13px 0;
}

img {
  padding-right: 8px;
}

.document-body-actions {
  display: flex;
}

.document-status-title {
  font-size: 12px;
  line-height: 14px;
  color: #000;
}
.document-status-value {
  font-size: 16px;
  line-height: 19px;
  color: #625f57;
}

.document-status-container {
  width: 230px;
}

.document-body-actions /deep/ .dropdown-box,
.document-button {
  height: 48px;
  width: 160px;
  white-space: nowrap;
}

.document-body-actions /deep/ .dropdown-menu {
  left: -100px;
}

.document-body-actions /deep/ .dropdown-box > .dropdown-toggle .btn-content {
  width: 100%;
}
.document-body-actions /deep/ .dropdown-box > .dropdown-toggle {
  margin-left: auto;
  background: #31c1a9;
  color: #fff;
  width: 100%;
}

.document-status-need-review {
  color: var(--danger);
  font-weight: bold;
}
</style>
