var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    class: { high: _vm.high },
    attrs: { src: _vm.formUrl },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }