<template>
  <tr v-if="values">
    <th class="position-relative">
      {{ $t(title) }}
      <i v-if="description" class="indicator-info la la-info-circle">
        <div class="indicator-info-details">
          <span>{{ $t(title) }}</span>
          <p>
            {{ $t(description) }}
          </p>
        </div>
      </i>
    </th>
    <td v-for="(value, index) of values" :key="index">
      {{ value }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    selector: { type: String, default: undefined },
    data: { type: Array, default: undefined }
  },

  computed: {
    values() {
      const { data, selector } = this
      if (!data || !data.length || !selector) return

      const valueList = data.map((item) => {
        return item[selector] || null
      })

      const validValue = valueList.find((item) => item !== null)
      return validValue ? valueList : null
    }
  }
}
</script>

<style scoped>
.indicator-info {
  font-size: 1.1rem;
  vertical-align: baseline;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
}

.indicator-info-details {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 25rem;
  display: none;
  -webkit-box-shadow: 0 3px 10px var(--shadow);
  box-shadow: 0 3px 10px var(--shadow);
  background-color: var(--white);
  color: var(--dark-font);
  padding: 2.2rem;
  border-left: 0.4rem solid var(--brand);
}

.indicator-info-details > p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.indicator-info:hover {
  color: var(--brand);
  box-shadow: 0 2px 6px var(--brand);
}

.indicator-info:hover .indicator-info-details {
  display: block;
}
</style>
