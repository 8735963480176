<template>
  <router-link
    :class="{
      'm-btn--icon': icon,
      'm-btn--wide m-btn--md': !small,
      'btn-sm': smallText
    }"
    class="ui-button btn m-btn"
    :to="to"
    :target="target"
  >
    <span>
      <i v-if="icon" :class="icon" />
      <span v-if="hasContent">
        <slot />
      </span>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'ui-link',

  props: {
    to: { type: Object, default: undefined },
    icon: { type: String, default: undefined },
    small: Boolean,
    smallText: Boolean,
    target: { type: String, default: '_self' }
  },

  computed: {
    hasContent() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>
.ui-button {
  white-space: normal;
}

.btn:disabled {
  cursor: not-allowed;
}

.btn.btn-link {
  padding: 0;
  background-color: transparent;
  color: var(--dark);
  font-weight: 300;
  text-decoration: none;
}

.btn.m-subheader-search__submit-btn {
  padding: 1.05rem 1.5rem;
}
</style>
