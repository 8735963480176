<template>
  <div class="app-notification">
    <transition-group name="show" tag="div" class="notification-box">
      <div
        v-for="item in data.notificatios"
        :key="item.id"
        class="notification"
      >
        <div class="content" :class="item.type">
          <a class="close" @click="remove(item)" />
          <span class="title">
            {{ $t(item.title) }}
          </span>
          <ul
            v-if="
              typeof item.message === 'object' ||
              typeof item.message === 'array'
            "
            class="message"
          >
            <li v-for="(message, index) in item.message" :key="index">
              {{ $t(message, item.messageData) }};
            </li>
          </ul>
          <span v-else class="message">
            {{ $t(item.message, item.messageData) }}
          </span>
          <div
            v-if="item.type === notificationType.warning"
            class="warning-buttons"
          >
            <ui-button class="btn-brand" low small @click="clickCancel(item)">
              {{ $t('RemoveCancel') }}
            </ui-button>
            <ui-button class="btn-metal" low small @click="clickOk(item)">
              {{ $t('RemoveOk') }}
            </ui-button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {
  notificationData,
  notificationType,
  notificationDataHistory
} from '@src/components/notification'

export default {
  data() {
    return {
      notificationType,
      data: notificationData
    }
  },

  methods: {
    remove(item) {
      const {
        data: { removeNotification }
      } = this
      if (item.cancelCallback) item.cancelCallback()
      removeNotification(item)
    },

    clickOk(item) {
      this.remove(item)
      item.callback()
      notificationDataHistory.notificationEvent(item, 'RemoveOk')
    },

    clickCancel(item) {
      if (item.cancelCallback) item.cancelCallback()
      this.remove(item)
      notificationDataHistory.notificationEvent(item, 'RemoveCancel')
    }
  }
}
</script>

<style scoped>
.app-notification {
  position: fixed;
  top: 12rem;
  right: 0;
  max-width: 25rem;
  z-index: 100;
}

.notification-box {
  display: flex;
  flex-direction: column;
}

.notification {
  margin: 0.5rem 1rem 0.5rem auto;
  max-height: 10rem;
}

.content {
  padding: 1rem 3rem 1rem 2rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  min-width: 15rem;
  position: relative;
}

.content.info {
  background-color: #e7f9ff;
  border-color: #d9f6ff;
}

.content.info-title {
  background-color: #e7f9ff;
  border-color: #d9f6ff;
  text-align: center;
}

.content.info-with-sound {
  background-color: #e7f9ff;
  border-color: #d9f6ff;
}

.content.error {
  background-color: #ffddd5;
  border-color: #ffc9bd;
}

.content.time-left {
  background-color: #ffddd5;
  border-color: #ffc9bd;
}

.content.warning {
  /* background-color: rgb(255, 251, 229); */
  background-color: #fff;

  /* border-color: rgb(255, 245, 194); */
  border: 0;
  box-shadow: 0 0 1rem 1px #ccc;
}

.warning-buttons > :first-child {
  margin-right: 1rem;
}

.warning-buttons > * {
  margin-top: 1rem;
  width: 6rem;
}

.content .title,
.content .message {
  display: block;
}

.message > li {
  position: relative;
  left: -1em;
}

.content .title {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.content.info-title .title {
  margin-bottom: 0;
}

.content .close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}

.content .close::before,
.content .close::after {
  content: '';
  position: absolute;
  left: 50%;
  width: 1px;
  height: 100%;
  background-color: var(--input-font);
}

.content .close::before {
  transform: rotateZ(45deg);
}

.content .close::after {
  transform: rotateZ(-45deg);
}

.show-enter-active,
.show-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, transform, max-height, margin;
}

.show-enter {
  transform: translateX(100%);
  opacity: 0;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.show-leave-to {
  transform: translateX(100%);
  opacity: 0;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
}
</style>
