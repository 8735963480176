var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "m-nav__item m-topbar__user-profile m-dropdown m-dropdown--small m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light",
      class: { "dropdown-open": _vm.isOpened },
    },
    [
      _c(
        "a",
        {
          staticClass: "m-nav__link m-dropdown__toggle",
          on: { click: _vm.open },
        },
        [
          _vm.notOpened || _vm.newEvent
            ? _c("span", {
                staticClass: "m-nav__link-badge m-badge m-badge--dot-small",
                class: { "m-badge--danger": _vm.newEvent },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "m-nav__link-icon",
              class: { "m-topbar__usericon": _vm.dark },
            },
            [
              _c("span", { staticClass: "icon-wrapper" }, [
                _c("i", { class: _vm.icon }),
                _vm._v(" "),
                _vm.notificationDataHistory &&
                _vm.notificationDataHistory.length > 0
                  ? _c("div", { staticClass: "notifications-count" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.notificationDataHistory.length) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "m-dropdown__wrapper",
          class: { "is-opened-dropdown": _vm.isOpened },
          attrs: { id: "m-dropdown__wrapper" },
        },
        [
          _c("span", { staticClass: "m-dropdown__arrow", style: _vm.styles }),
          _vm._v(" "),
          _c("div", { staticClass: "m-dropdown__inner" }, [
            _c("div", { staticClass: "m--align-center" }, [
              _vm.notificationDataHistory &&
              _vm.notificationDataHistory.length > 0
                ? _c(
                    "div",
                    { staticClass: "notification-block" },
                    [
                      _c("div", { staticClass: "remove-all" }, [
                        _c(
                          "span",
                          { on: { click: _vm.removeAllNotification } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("RemoveAll")) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.notificationDataHistory, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "m-nav__item content",
                            class: item.type,
                          },
                          [
                            _c("a", {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.removeNotification(item)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t(_vm.getTitle(item.title)))),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "small-text" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("date")(item.time, true)) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.getMessage(item)) +
                                  "\n              "
                              ),
                              _c("strong", { staticClass: "display-block" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t(_vm.getEvent(item))) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "location-text",
                                  attrs: {
                                    href: item.location,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.location) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _c("p", { staticClass: "margin-top-1" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.$t("NoLogs")) + "\n        "
                    ),
                  ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }