<template>
  <ui-container>
    <ui-card title="CompanyReports">
      <ui-button
        slot="tools"
        class="btn-link tools-div"
        @click="handleAddReportClick"
      >
        <span>{{ $t('AddNewReport') }}</span>
        <i class="la la-plus" />
      </ui-button>
      <ui-table :loading="loading" :labels="tableLabels" :data="listItems">
        <company-report-column
          v-for="(item, index) in listItems"
          :key="index"
          :item="item"
        />
      </ui-table>
      <ui-page-selector
        :active-page="page"
        :items-count="total"
        :max-on-page="pageSize"
        :disabled="loading"
        @change="pageChanged"
      />
    </ui-card>
    <pop-up full-size>
      <company-report-create-form @listUpdateEvent="handleReload" />
    </pop-up>
  </ui-container>
</template>

<script>
import CompanyReportCreateForm from '@src/components/pages/kyb/CompanyReportCreateForm'
import CompanyReportColumn from '@src/components/pages/kyb/CompanyReportColumn'

export default {
  components: { CompanyReportColumn, CompanyReportCreateForm },
  props: {
    listItems: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    page: { type: Number, default: 0 },
    total: { type: Number, default: 0 },
    pageSize: { type: Number, default: 0 },
    pageChanged: { type: Function, default: undefined }
  },
  data: () => ({
    tableLabels: [
      'CompanyReportTable.Headers.Id',
      'CompanyReportTable.Headers.AddedBy',
      'CompanyReportTable.Headers.Country',
      'CompanyReportTable.Headers.StartDate',
      'CompanyReportTable.Headers.TimePeriod',
      'CompanyReportTable.Headers.CompanyName',
      'CompanyReportTable.Headers.Status'
    ],
    filters: {
      date: null,
      pageSize: 100,
      Page: 1
    }
  }),
  methods: {
    handleAddReportClick() {
      this.$openPopUp()
    },
    handleReload() {
      this.$emit('listReloading')
    }
  }
}
</script>

<style scoped>
h3 {
  color: #575962;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

/deep/ .pop-up-header {
  padding: 0;
}

.tools-div /deep/ .btn-text {
  display: flex;
  align-items: center;
}

.tools-div /deep/ .btn-text span {
  margin-right: 5px;
}

/deep/ .pop-up-header div {
  font-family: 'metropolisbold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
</style>
