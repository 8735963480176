import { requiredValidator, minLengthValidator } from '@src/scripts/validators'

export const DefaultPOAData = () => {
  const configuration = {
    firstName: {
      label: 'POAFirstName',
      minLength: 2,
      oneLine: true,
      validations: [required, minLength]
    },
    lastName: {
      label: 'POALastName',
      minLength: 2,
      oneLine: true,
      validations: [required, minLength]
    },
    street: {
      label: 'POAStreet',
      minLength: 2,
      oneLine: true,
      validations: [required, minLength]
    },
    city: {
      label: 'POACity',
      minLength: 2,
      oneLine: true,
      validations: [required, minLength]
    },
    parish: {
      label: 'POAParish',
      minLength: 2,
      oneLine: true,
      validations: [minLength]
    }
  }

  return configuration
}

function required(item) {
  return requiredValidator(item.value)
}

function minLength(item) {
  return minLengthValidator(item.value, item.minLength)
}
