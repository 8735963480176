<template>
  <div class="m-stack__item m-brand">
    <div class="m-stack m-stack--ver m-stack--general">
      <!-- <div class="m-stack__item m-stack__item--middle m-brand__logo">
        <router-link class="m-brand__logo-wrapper" :to="{name: 'App'}">
          <img src="~@src/assets/images/logo.png">
        </router-link>
      </div> -->
      <sub-menu />
      <div class="m-stack__item m-stack__item--middle m-brand__tools">
        <a
          class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
          @click="$emit('lefttoggleclick', $event)"
        >
          <span />
        </a>
        <!-- <a class="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
          @click="$emit('righttoggleclick', $event)">
          <span />
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import SubMenu from '@src/components/partials/header/SubMenu'
export default {
  components: {
    SubMenu
  }
}
</script>

<style scoped>
.m-stack__item.m-stack__item--middle.m-brand__tools {
  width: 2rem;
}

.m-brand {
  height: 60px;
  z-index: 0;
  padding-left: 0;
}
</style>
