var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upload-photo-box" }, [
    !_vm.photo
      ? _c("div", { staticClass: "border-box" }, [
          _c("i", { staticClass: "la la-upload upload-icon" }),
          _vm._v(" "),
          _vm.description
            ? _c("p", { staticClass: "description-text" }, [
                _vm._v("\n      " + _vm._s(_vm.$t(_vm.description)) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            staticClass: "input-file",
            attrs: { type: "file", accept: ".jpg, .jpeg, .png, .gif" },
            on: { change: _vm.addPhoto },
          }),
        ])
      : _c("div", [
          _c(
            "div",
            {
              staticClass: "photo-box",
              style: { "background-image": "url(" + _vm.photo + ")" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "delete-button",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("deletePhoto")
                    },
                  },
                },
                [_vm._v("x")]
              ),
            ]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }