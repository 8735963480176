<template>
  <div slot="header" class="buttons-wrapper">
    <ui-button
      v-show="!editEnabled"
      type="button"
      class="btn-brand"
      icon="flaticon-edit-1"
      small
      @click="$emit('edit')"
    >
      {{ $t('AppEdit') }}
    </ui-button>
    <ui-button
      v-show="editEnabled"
      class="btn-brand"
      icon="la la-check"
      :disabled="disabled"
      small
    >
      {{ $t('AppSave') }}
    </ui-button>
  </div>
</template>

<script>
export default {
  props: {
    editEnabled: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
@media (max-width: 568px) {
  .buttons-wrapper {
    width: 100%;
    text-align: center;
  }
}
</style>
