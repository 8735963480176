var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-stack__item m-brand" }, [
    _c(
      "div",
      { staticClass: "m-stack m-stack--ver m-stack--general" },
      [
        _c("sub-menu"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "m-stack__item m-stack__item--middle m-brand__tools" },
          [
            _c(
              "a",
              {
                staticClass:
                  "m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block",
                on: {
                  click: function ($event) {
                    return _vm.$emit("lefttoggleclick", $event)
                  },
                },
              },
              [_c("span")]
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }