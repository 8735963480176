var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisible,
          expression: "isVisible",
        },
      ],
      staticClass: "m-scroll-top",
      on: { click: _vm.onClick },
    },
    [_c("i", { staticClass: "la la-arrow-up" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }