var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-listed-checkbox", class: { disabled: _vm.disabled } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.search,
            expression: "search",
          },
        ],
        attrs: {
          type: "text",
          placeholder: _vm.$t("CompanyProduct.CountryPlaceholder"),
        },
        domProps: { value: _vm.search },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.search = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ui-listed-checkbox-items" },
        [
          _vm._l(_vm.sortedItems, function (item) {
            return _c(
              "div",
              { key: item.value },
              [
                _c("ui-new-checkbox", {
                  attrs: {
                    label: item.label,
                    value: item.value,
                    disabled: _vm.disabled,
                  },
                  model: {
                    value: _vm.values,
                    callback: function ($$v) {
                      _vm.values = $$v
                    },
                    expression: "values",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" "),
          !_vm.sortedItems.length
            ? _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("CompanyProduct.CountryNotFound")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          _vm._s(_vm.values.length) +
            " " +
            _vm._s(_vm.$t("CompanyProduct.CountriesSelected"))
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }