var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "m-grid__item m-header" }, [
    _c(
      "div",
      {
        staticClass:
          "m-container m-container--fluid m-container--full-height hide-mobile",
      },
      [
        _c("div", { staticClass: "m-stack m-stack--ver m-stack--desktop" }, [
          _c(
            "div",
            { staticClass: "m-stack__item m-stack__item--fluid m-header-head" },
            [_c("sub-menu"), _vm._v(" "), _c("info-menu")],
            1
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "show-mobile" },
      [
        _c("mobile-menu", { on: { lefttoggleclick: _vm.openMenu } }),
        _vm._v(" "),
        _c("transition", { attrs: { name: "show" } }, [
          _vm.menuIsOpened
            ? _c(
                "div",
                { staticClass: "mobile-menu show-mobile" },
                [
                  _c("a", { staticClass: "close", on: { click: _vm.onClose } }),
                  _vm._v(" "),
                  _c("info-menu", {
                    on: {
                      clickButton: function ($event) {
                        _vm.menuIsOpened = false
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }