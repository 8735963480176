var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "has-error": _vm.error,
        "ui-touched": _vm.istouched,
        "ui-untouched": !_vm.istouched,
      },
    },
    [
      _c(
        "label",
        {
          staticClass: "flex-row options flex-align",
          class: { "ui-bordered": _vm.bordered, "no-padding": _vm.noPadding },
        },
        [
          _c(
            "div",
            { staticClass: "m-radio m-radio--brand m-radio--check-bold" },
            [
              _c("input", {
                attrs: { type: "radio", name: _vm.name },
                domProps: { value: _vm.value, checked: _vm.checked },
                on: {
                  input: function ($event) {
                    return _vm.$emit("checkRadio", $event.target.value)
                  },
                  blur: _vm.lostFocus,
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "checkmark" }),
              _vm._v("\n      " + _vm._s(_vm.label) + "\n    "),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "icon" }, [_vm._t("icon")], 2),
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "error-message" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.error)) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }