var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "ui-widget",
        {
          attrs: { title: "KybTimeTitle", "title-value": _vm.data.titleValue },
        },
        [
          _vm.messageValue
            ? _c("ui-message", {
                attrs: { title: "KybCompanyValue", message: _vm.messageValue },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.chartData
            ? _c(
                "ui-widget",
                {
                  staticClass: "vertical-group",
                  attrs: { title: "KybTurnoverTitle" },
                },
                [
                  _c("line-bar-chart", {
                    attrs: { type: _vm.type, data: _vm.chartData },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tableData
            ? _c(
                "ui-widget",
                { attrs: { title: "KybFinancialIndicatorsTitle" } },
                [
                  _c(
                    "ui-table",
                    {
                      staticClass: "table-overflow hide-mobile",
                      attrs: { labels: _vm.tableLabels, data: _vm.tableData },
                    },
                    [
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableCurrentSolvencyRatio",
                          data: _vm.tableData,
                          selector: "l1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableCriticalCoatingFactor",
                          data: _vm.tableData,
                          selector: "l2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TablePropertyProfitability",
                          data: _vm.tableData,
                          selector: "p4",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableProfitabilityBeforeTax",
                          data: _vm.tableData,
                          selector: "p1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableStockTurnover",
                          data: _vm.tableData,
                          selector: "e3",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableTurnoverReceivables",
                          data: _vm.tableData,
                          selector: "e1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableTurnoverAmountsDue",
                          data: _vm.tableData,
                          selector: "e2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableEquityPropertyRatio",
                          data: _vm.tableData,
                          selector: "f2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableNetProfitability",
                          description: "TableNetProfitabilityDescription",
                          data: _vm.tableData,
                          selector: "p3",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableTotalProfitability",
                          description: "TableTotalProfitabilityDescription",
                          data: _vm.tableData,
                          selector: "p2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableEbit",
                          description: "TableEbitDescription",
                          data: _vm.tableData,
                          selector: "ebit",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableEbitMargin",
                          description: "TableEbitMarginDescription",
                          data: _vm.tableData,
                          selector: "ebitMargin",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableEbitda",
                          description: "TableEbitdaDescription",
                          data: _vm.tableData,
                          selector: "ebitda",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableEbitdaMargin",
                          description: "TableEbitdaMarginDescription",
                          data: _vm.tableData,
                          selector: "ebitdaMargin",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableDebtEquityRatio",
                          description: "TableDebtEquityRatioDescription",
                          data: _vm.tableData,
                          selector: "debtProperty",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableWorkingCapitalRequirement",
                          description:
                            "TableWorkingCapitalRequirementDescription",
                          data: _vm.tableData,
                          selector: "workingCapitalRequirement",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableSalesPerEmployee",
                          description: "TableSalesPerEmployeeDescription",
                          data: _vm.tableData,
                          selector: "salesPerEmployee",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableProfitPerEmployee",
                          description: "TableProfitPerEmployeeDescription",
                          data: _vm.tableData,
                          selector: "profitPerEmployee",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item", {
                        attrs: {
                          title: "TableChangeInSales",
                          data: _vm.tableData,
                          selector: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ui-table",
                    {
                      staticClass: "table-overflow show-mobile",
                      attrs: { data: _vm.tableData },
                    },
                    [
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableCurrentSolvencyRatio",
                          data: _vm.tableData,
                          selector: "l1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableCriticalCoatingFactor",
                          data: _vm.tableData,
                          selector: "l2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TablePropertyProfitability",
                          data: _vm.tableData,
                          selector: "p4",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableProfitabilityBeforeTax",
                          data: _vm.tableData,
                          selector: "p1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableStockTurnover",
                          data: _vm.tableData,
                          selector: "e3",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableTurnoverReceivables",
                          data: _vm.tableData,
                          selector: "e1",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableTurnoverAmountsDue",
                          data: _vm.tableData,
                          selector: "e2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableEquityPropertyRatio",
                          data: _vm.tableData,
                          selector: "f2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableNetProfitability",
                          description: "TableNetProfitabilityDescription",
                          data: _vm.tableData,
                          selector: "p3",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableTotalProfitability",
                          description: "TableTotalProfitabilityDescription",
                          data: _vm.tableData,
                          selector: "p2",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableEbit",
                          description: "TableEbitDescription",
                          data: _vm.tableData,
                          selector: "ebit",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableEbitMargin",
                          description: "TableEbitMarginDescription",
                          data: _vm.tableData,
                          selector: "ebitMargin",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableEbitda",
                          description: "TableEbitdaDescription",
                          data: _vm.tableData,
                          selector: "ebitda",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableEbitdaMargin",
                          description: "TableEbitdaMarginDescription",
                          data: _vm.tableData,
                          selector: "ebitdaMargin",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableDebtEquityRatio",
                          description: "TableDebtEquityRatioDescription",
                          data: _vm.tableData,
                          selector: "debtProperty",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableWorkingCapitalRequirement",
                          description:
                            "TableWorkingCapitalRequirementDescription",
                          data: _vm.tableData,
                          selector: "workingCapitalRequirement",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableSalesPerEmployee",
                          description: "TableSalesPerEmployeeDescription",
                          data: _vm.tableData,
                          selector: "salesPerEmployee",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableProfitPerEmployee",
                          description: "TableProfitPerEmployeeDescription",
                          data: _vm.tableData,
                          selector: "profitPerEmployee",
                        },
                      }),
                      _vm._v(" "),
                      _c("chart-data-item-mobile", {
                        attrs: {
                          labels: _vm.mobileTableLabels,
                          title: "TableChangeInSales",
                          data: _vm.tableData,
                          selector: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c("company-no-info")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }