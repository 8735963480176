var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "m-grid__item m-footer" }, [
    _c(
      "div",
      {
        staticClass:
          "m-container m-container--fluid m-container--full-height m-page__container",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last",
              },
              [
                _c("span", { staticClass: "m-footer__copyright" }, [
                  _vm._v(" " + _vm._s(_vm.year) + " © Ondato "),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }