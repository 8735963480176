<template>
  <div class="card-slide">
    <transition name="slide">
      <slot />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ui-card-slider-item'
}
</script>
