<template>
  <a
    :download="name"
    :href="item"
    :title="$t(compress ? 'ButtonCompress' : 'ButtonOriginal')"
  >
    <ui-button
      :class="compress ? 'btn-brand' : 'btn-metal'"
      icon="la la-download"
      :small="small"
      :low="low"
      :small-text="smallText"
    >
      <slot />
    </ui-button>
  </a>
</template>
<script>
import { compressImage } from '@src/scripts/helpers'

export default {
  name: 'ui-save-button',

  props: {
    small: Boolean,
    smallText: Boolean,
    low: Boolean,
    compress: Boolean,
    name: { type: String, default: undefined },
    href: { type: String, default: undefined }
  },

  data() {
    return {
      item: null
    }
  },

  watch: {
    href() {
      this.setItem()
    }
  },

  created() {
    this.setItem()
  },

  methods: {
    async setItem() {
      const { compress, href } = this
      if (!compress) this.item = href
      else this.item = await compressImage(href)
    }
  }
}
</script>
