<template>
  <div class="m-list-timeline m-list-timeline--skin-light">
    <div
      class="m-list-timeline__items"
      :class="{ 'single-item': items.length < 2 }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="m-list-timeline__item"
      >
        <span class="m-list-timeline__badge" />
        <template v-for="({ type, content }, key) in item">
          <span v-if="type === 'time'" :key="key" class="m-list-timeline__time">
            {{ content }}
          </span>
          <span v-else :key="key" class="m-list-timeline__text">
            {{ content }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true }
  }
}
</script>

<style scoped>
.m-list-timeline__items.single-item::before {
  display: none;
}

@media (max-width: 568px) {
  .m-list-timeline__items .m-list-timeline__item {
    table-layout: auto;
  }

  .m-list-timeline__item .m-list-timeline__text,
  .m-list-timeline__item .m-list-timeline__time {
    display: block;
    order: 1;
    width: 100%;
  }

  .m-list-timeline__item .m-list-timeline__time {
    display: block;
    text-align: left;
    padding: 0;
  }
}
</style>
