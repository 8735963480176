var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-subheader-search",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.search($event)
        },
      },
    },
    [
      _c("h2", { staticClass: "m-subheader-search__title" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.title)) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.description
        ? _c("p", { staticClass: "color-white" }, [
            _vm._v("\n    " + _vm._s(_vm.$t(_vm.description)) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.autoRefresh
        ? _c("ui-toggle", {
            attrs: {
              isheader: "",
              value: _vm.refreshSettings.isAuto,
              disabled: _vm.disabledToggle,
              label: "AutoRefresh",
            },
            on: { change: _vm.autoRefreshChanged },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasSearhContent
        ? _c("ui-form", { staticClass: "m-form", on: { submit: _vm.search } }, [
            _c(
              "div",
              {
                staticClass:
                  "m-grid m-grid--ver-desktop m-grid--desktop header-content",
              },
              [
                _c(
                  "div",
                  { staticClass: "m-grid__item filters-list" },
                  [_vm._t("default")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "m-grid__item" },
                  [
                    _c(
                      "ui-button",
                      {
                        staticClass: "m-subheader-search__submit-btn",
                        attrs: { type: "submit", disabled: _vm.disabled },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("AppSearch")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "m-grid__item ml-auto" },
                  [_vm._t("right-side")],
                  2
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }