<template>
  <div
    class="dropdown-box ui-dropdown"
    :class="{ 'inline-block': multiSelect }"
    @keyup="keyup"
    @keydown.enter="clickEnter"
  >
    <span v-if="hasSearch" class="dropdown-box ui-dropdown">
      <ui-input
        class="btn dropdown-toggle input-dropdown-toggle"
        one-line
        :class="{
          'btn-light': light,
          'btn-light-border': lightBorder,
          'btn-danger': danger,
          'btn-brand': brand,
          'btn-metal': metal
        }"
        :disabled="disabled"
        :small="small"
        :error="error"
        :small-text="smallText"
        :low="low"
        :type="headerMultiSelect ? 'header-multi-select' : 'input'"
        :text-overflow-ellipses="headerMultiSelect"
        :placeholder="selectedValue"
        :value="headerMultiSelect ? multiValue : dropDownvalue"
        @input="changeInput"
        @click="open"
      />
    </span>
    <ui-button
      v-else
      class="dropdown-toggle"
      :class="{
        'btn-light': light,
        'btn-light-border': lightBorder,
        'btn-danger': danger,
        'btn-brand': brand,
        'btn-metal': metal
      }"
      type="button"
      :disabled="disabled"
      :low="low"
      :small="small"
      :small-text="smallText"
      @click="open"
    >
      {{ $t(selectedValue) }}
    </ui-button>
    <ul
      id="dropdown-menu"
      class="dropdown-menu inner"
      :class="{ 'left-align': left, 'is-opened': isOpened }"
    >
      <li
        v-if="all && searchOptions && searchOptions.length"
        :class="{ selected: !selectedIndex && !multiSelect }"
      >
        <a class="dropdown-item" @click="multiSelect ? selectAll() : select()">
          <span class="bs-ok-default check-mark" />
          <span class="text">
            {{ this.$t(dropdownAllText) }}
          </span>
        </a>
      </li>
      <li
        v-for="(item, index) in searchOptions"
        :key="index"
        :class="{
          selected: selectedIndex == index || selectSome.includes(item.key),
          'disabled-item': item.disabled,
          'pointer-events': !item.title
        }"
      >
        <div v-if="item.title" class="li-title">
          {{ item.title }}
        </div>
        <a v-if="item.type === 'date'" class="dropdown-item dropdown-date">
          <span>
            {{ item.value }}
          </span>
          <el-date-picker
            type="daterange"
            value-format="yyyy-MM-dd"
            :editable="false"
            :clearable="false"
            @input="select"
          />
        </a>
        <a
          v-else-if="multiSelect"
          class="dropdown-item"
          @click="select(item.key, item.value)"
        >
          <span class="bs-ok-default check-mark" />
          <i v-if="item.icon" :class="item.icon" />
          <span class="text" :class="{ 'small-text': smallText }">
            {{ item.value }}
          </span>
        </a>
        <a
          v-else-if="keyValueDropdown"
          class="dropdown-item"
          @click="select(item.key, item.value)"
          @keyup.enter="select(item.key, item.value)"
        >
          <span class="bs-ok-default check-mark" />
          <i v-if="item.icon" :class="item.icon" />
          <span class="text" :class="{ 'small-text': smallText }">
            {{ $t(item.value) }} ({{ item.key }})
          </span>
        </a>
        <a v-else class="dropdown-item" @click="select(item.key, item.value)">
          <span class="bs-ok-default check-mark" />
          <i v-if="item.icon" :class="item.icon" />
          <span class="text" :class="{ 'small-text': smallText }">
            {{ item.value }}
          </span>
          <i v-if="item.disabled" class="fa fa-lock" />
        </a>
      </li>
      <li v-if="!searchOptions || !searchOptions.length">
        <span class="empty-message">
          {{ $t('TableNoOptions') }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { isArrayEquals } from '@src/scripts/helpers'

export default {
  name: 'ui-dropdown',

  props: {
    placeholder: { type: String, default: undefined },
    value: { type: [String, Number, Array], default: undefined },
    options: { type: Array, default: undefined },
    disabled: Boolean,
    light: Boolean,
    lightBorder: Boolean,
    danger: Boolean,
    metal: Boolean,
    small: Boolean,
    left: Boolean,
    all: { type: [Boolean, String], default: undefined },
    smallText: Boolean,
    brand: Boolean,
    hasSearch: Boolean,
    multiSelect: Boolean,
    low: Boolean,
    keyValueDropdown: Boolean,
    error: { type: String, default: undefined },
    getItemsOnSelect: Boolean,
    headerMultiSelect: Boolean,
    multiValuesTranslationEnd: { type: String, default: undefined }
  },

  data() {
    return {
      isOpened: false,
      dropDownvalue: null,
      searchOptions: null,
      isClick: false,
      valueText: null,
      selectSome: [],
      countryIndex: false
    }
  },

  computed: {
    dropdownAllText() {
      if (this.all && typeof this.all === 'string') {
        return this.all
      } else if (this.all) return 'DropdownAll'
      else return null
    },

    multiValue() {
      const { multiValuesTranslationEnd } = this
      if (multiValuesTranslationEnd) {
        return this.selectSome
          .map((item) => this.$t(item + multiValuesTranslationEnd))
          .toString()
      } else return this.selectSome.map((item) => this.$t(item)).toString()
    },

    selectedIndex() {
      const { searchOptions, value } = this
      if (!searchOptions || !value) return null

      for (const index in searchOptions) {
        const item = searchOptions[index].key
        if (item instanceof Array && value instanceof Array) {
          if (isArrayEquals(value, item)) return index
        } else if (item === value) return index
      }
      return null
    },

    translatedOptions() {
      const { options } = this
      if (!options) return
      return options.map((item) => {
        return {
          key: item.key,
          value: this.$t(item.value),
          type: item.type || undefined,
          disabled: item.disabled,
          title: item.title
        }
      })
    },

    selectedValue() {
      const {
        translatedOptions,
        value,
        placeholder,
        selectedIndex,
        hasSearch,
        searchOptions
      } = this
      if (!translatedOptions || !selectedIndex) {
        if (value instanceof Array) return value.join(' - ')
        return value || (hasSearch && placeholder) || this.$t(placeholder)
      } else if (searchOptions)
        return this.$t(searchOptions[selectedIndex].value)
      else return translatedOptions[selectedIndex].value
    }
  },

  watch: {
    selectedIndex() {
      setTimeout(() => this.dropdownMenuPosition(), 0)
    }
  },

  created() {
    this.searchOptions = this.options
  },

  methods: {
    clickEnter(event) {
      event.preventDefault()
      if (
        this.searchOptions[this.countryIndex] &&
        this.searchOptions[this.countryIndex].key &&
        this.searchOptions[this.countryIndex].value
      )
        this.select(
          this.searchOptions[this.countryIndex].key,
          this.searchOptions[this.countryIndex].value
        )
      else event.stopPropagation()
    },

    dropdownMenuPosition() {
      const div = this.$el
      const openDiv = div.querySelector('.is-opened')
      if (openDiv) {
        const item = openDiv.querySelector('li.selected')
        if (item) {
          const itemHeight = item.offsetHeight
          const itemPositionY = item.offsetTop
          const height =
            itemPositionY + itemHeight < openDiv.offsetHeight
              ? 0
              : itemPositionY - openDiv.offsetHeight + itemHeight
          openDiv.scrollTo(0, height)
        }
      }
    },

    keyup(event) {
      if (this.keyValueDropdown) {
        if (event.key === 'ArrowDown') {
          this.countryIndex =
            this.searchOptions.length > 1 && this.selectedIndex
              ? this.selectedIndex * 1 + 1
              : 0
          if (this.countryIndex < this.searchOptions.length)
            this.select(
              this.searchOptions[this.countryIndex].key,
              this.searchOptions[this.countryIndex].value,
              true
            )
        } else if (event.key === 'ArrowUp') {
          this.countryIndex =
            this.searchOptions.length > 1 ? this.selectedIndex * 1 - 1 : 0
          if (
            this.countryIndex >= 0 &&
            this.countryIndex < this.searchOptions.length
          )
            this.select(
              this.searchOptions[this.countryIndex].key,
              this.searchOptions[this.countryIndex].value,
              true
            )
        }
      }
    },

    select(key, value, dontClose) {
      this.dropDownvalue = this.$t(value)
      if (!this.multiSelect) {
        this.$emit('input', key, value)
        if (!dontClose) {
          this.close(event)
        }
      } else {
        if (this.getItemsOnSelect) {
          this.$emit('dropdownSelected', this.selectSome)
          this.$emit('getItemsOnSelect', key, value)
        }
        document.body.removeEventListener('click', this.clickOutside)
        if (this.selectSome.includes(key))
          this.selectSome.splice(
            this.selectSome.findIndex((item) => item === key),
            1
          )
        else this.selectSome.push(key)
        document.body.addEventListener('click', this.clickOutsideSelectSome)
      }
    },

    clickOutsideSelectSome(event) {
      if (
        !event.target.className.includes('dropdown') &&
        !event.target.className.includes('text')
      ) {
        this.close()
        document.body.removeEventListener('click', this.clickOutsideSelectSome)
      }
    },

    selectAll() {
      this.$emit('selectAll')
      this.close()
    },

    open() {
      if (!this.dropDownvalue) this.searchOptions = this.translatedOptions
      document.body.addEventListener('click', this.clickOutside)
      setTimeout(() => this.dropdownMenuPosition(), 0)
    },

    close(event) {
      if (
        event &&
        event.target &&
        event.target.className &&
        event.target.className.includes('el-date-editor')
      ) {
        this.isOpened = true
      } else if (this.$el.querySelector('.is-opened') && this.isOpened) {
        this.$el.querySelectorAll('#dropdown-menu').forEach((item) => {
          item.classList.remove('is-opened')
        })
        this.isOpened = false
        document.body.removeEventListener('click', this.clickOutside)
      } else {
        this.isOpened = true
      }
      this.dropDownvalue = null
      if (this.multiSelect) {
        if (!this.getItemsOnSelect) {
          this.selectSome.map((item) => {
            const index = this.options.findIndex((el) => el.key === item)
            const value = this.options[index].value
            this.$emit('input', item, value)
          })
        }
        if (!this.headerMultiSelect) {
          this.selectSome = []
        }
      }
    },

    clickOutside(event) {
      this.close(event)
    },

    changeInput(value) {
      this.isOpened = true
      const input = value.toLocaleLowerCase().replace(/\s/g, '')
      const array = this.translatedOptions.filter(
        (item) =>
          item.value.toLocaleLowerCase().replace(' ', '').includes(input) ||
          item.key.toLocaleLowerCase().replace(' ', '').includes(input)
      )
      this.searchOptions = array.length > 0 ? array : ''
      this.dropDownvalue = value
    }
  }
}
</script>

<style scoped>
.dropdown-item i,
.dropdown-item i + span {
  vertical-align: middle;
}

.ui-dropdown {
  position: relative;
  height: 100%;
}

.dropdown-menu.left-align {
  left: auto;
  right: 0;
}

.dropdown-box > .dropdown-toggle {
  position: relative;
  display: flex;
  padding-right: 2.25rem;
  height: 100%;
  align-items: center;
}

.dropdown-box > .dropdown-toggle.m-btn--wide {
  padding-left: 1.25rem;
  padding-right: 2.75rem;
}

.dropdown-box > .dropdown-toggle:not(.input-dropdown-toggle)::after {
  position: absolute;
  bottom: 50%;
  right: 1.5rem;
}

.dropdown-box > .dropdown-toggle.input-dropdown-toggle::after {
  position: absolute;
  bottom: 50%;
  right: 1.5rem;
}

.dropdown-box > .dropdown-toggle.m-btn--wide::after {
  right: 1.5rem;
}

.dropdown-box > .dropdown-toggle.btn-light::after {
  right: 1.5rem;
}

.dropdown-box > .dropdown-toggle.btn-light {
  background-color: transparent;
  color: var(--white);
  font-size: 0.9rem;
  line-height: 1.25;
  width: 100%;
}

.btn-light-border {
  border: 1px solid var(--light-bg-h-gray);
  background-color: transparent;
  color: var(--gray);
  font-size: 1rem;
  line-height: 1.25;
  width: 100%;
}

.dropdown-box > .dropdown-toggle.btn-light:hover {
  background-color: var(--light-bg-h-gray);
  border-color: var(--light-br-h-gray);
}

.dropdown-box > .dropdown-menu {
  max-height: 400px;
  min-width: 100%;
  overflow: auto;
  position: absolute;
  top: 105%;
  display: none;
}

.dropdown-menu.is-opened {
  display: block;
}

.dropdown-box > .dropdown-menu a {
  padding-right: 3rem;
}

.dropdown-box > .dropdown-menu > li {
  z-index: 1;
}

.dropdown-box > .dropdown-menu > li:hover {
  cursor: pointer;
  position: relative;
}

.dropdown-box > .dropdown-menu .dropdown-item {
  position: relative;
}

.dropdown-box > .dropdown-menu > .selected > .dropdown-item {
  background: rgb(36, 77, 80);
  color: #fff;
}

.dropdown-box > .dropdown-menu > .selected .check-mark {
  font-family: 'LineAwesome', sans-serif;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.85rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.15rem;
  display: inline-block;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.dropdown-box > .dropdown-menu > .selected .check-mark::before {
  content: '';
}

.dropdown-box > .dropdown-menu > .selected:hover > .dropdown-item {
  opacity: 0.9;
}

.dropdown-box .empty-message {
  display: block;
  padding: 0.7rem 1.2rem;
  color: var(--danger);
}

.ui-input + .ui-dropdown > .ui-input {
  border-left: 1px solid var(--white);
}

.ui-input + .ui-dropdown > .ui-input::after {
  left: 32%;
}

.fixed-button {
  position: fixed;
}

.ui-dropdown.inline-block button {
  display: inline;
}

.small-text {
  font-size: 0.875rem;
}

.li-title {
  display: none;
  position: absolute;
  left: 0;
  top: -0.5rem;
  background: #eee;
  border: 1px solid #ccc;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  z-index: 1500;
}

.dropdown-menu li:hover > .li-title {
  display: block;
}

@media (max-width: 568px) {
  .dropdown-menu .dropdown-item .text {
    white-space: normal;
  }
}
</style>

<style>
.dropdown-box > .dropdown-toggle input {
  text-align: left;
}

.dropdown-date {
  position: relative;
}

.dropdown-date > .el-date-editor {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.dropdown-date > .el-date-editor > * {
  visibility: hidden;
}

.dropdown-date:hover > .el-date-editor input {
  background-color: transparent;
  cursor: pointer;
}

.dropdown-box .ui-input input:hover::placeholder {
  color: var(--dark);
}

.dropdown-box .ui-input.btn {
  padding: 0;
  border: 0;
}

.disabled-item.pointer-events {
  pointer-events: none;
}

.disabled-item {
  cursor: not-allowed;
  opacity: 0.7;
  position: relative;
}

.disabled-item .fa-lock {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-box > .dropdown-menu .selected.disabled-item span.check-mark {
  display: none;
}
</style>
