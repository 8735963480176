var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "details-item with-values",
      class: { "inner-page-style": _vm.isInner },
    },
    [
      _c("span", { staticClass: "details-label" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "details-value" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.value)) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }