<template>
  <div
    class="m-header-menu m-header-menu--skin-light m-header-menu--submenu-skin-light sub-menu"
  >
    <ul v-if="subMenu" class="m-menu__nav m-menu__nav--submenu-arrow">
      <li
        v-for="(item, index) in subMenu"
        :key="index"
        class="m-menu__item"
        :class="{ 'm-menu__item--active': item.to.name === $route.name }"
      >
        <router-link class="m-menu__link" :to="item.to">
          <span class="m-menu__item-here" />
          <span class="m-menu__link-text">
            {{ $t(item.label) }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { pageTypeEnum, PRODUCTS } from '@src/scripts/enums'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'

export default {
  computed: {
    ...mapGetters([getters.user, getters.selectedCompany, getters.companies]),

    userPages() {
      const selectedCompanyId =
        this.selectedCompany || this.user.defaultCompanyId
      const company = this.companies.find(
        (item) => item.id === selectedCompanyId
      )
      return company && company.security && company.security.pages
    },

    hasMonitoringProduct() {
      const selectedCompanyId =
        this.selectedCompany || this.user.defaultCompanyId

      const currentCompany = this.companies.find(
        (company) => selectedCompanyId === company.id
      )
      return currentCompany.security.products.includes(
        PRODUCTS.NATURAL_ENTITY_MONITORING
      )
    },

    adminSubMenu() {
      const { userPages } = this
      if (!userPages) return null
      const menu = []
      if (userPages.includes(pageTypeEnum.adminUsers))
        menu.push({ label: 'AppAdminUsers', to: { name: 'AdminUsers' } })
      if (userPages.includes(pageTypeEnum.companyProcedures)) {
        menu.push({
          label: 'App.SubMenu.Companies',
          to: { name: 'CompanyProcedures' }
        })
      }
      if (userPages.includes(pageTypeEnum.agentUrls)) {
        menu.push({
          label: 'App.SubMenu.AgentUrls',
          to: { name: 'AgentUrls' }
        })
      }
      return menu
    },

    identificationsSubMenu() {
      const { userPages } = this
      if (!userPages) return null
      const menu = []
      if (userPages.includes(pageTypeEnum.identifications)) {
        menu.push({ label: 'App.SubMenu.Kyc', to: { name: 'Identifications' } })
      }
      if (userPages.includes(pageTypeEnum.kybIdentifications)) {
        menu.push({
          label: 'App.SubMenu.Kyb',
          to: { name: 'KybIdentifications' }
        })
      }
      if (userPages.includes(pageTypeEnum.webhookHistory)) {
        menu.push({
          label: 'App.SubMenu.Webhooks',
          to: { name: 'WebhookHistory' }
        })
      }
      if (userPages.includes(pageTypeEnum.stopList)) {
        menu.push({
          label: 'App.SubMenu.Stoplist',
          to: { name: 'StopList' }
        })
      }

      if (
        userPages.includes(pageTypeEnum.naturalEntityMonitoringEvents) &&
        this.hasMonitoringProduct
      ) {
        menu.push({
          label: 'App.SubMenu.MonitoringEvents',
          to: { name: 'naturalEntityMonitoringEvents' }
        })
      }
      if (
        userPages.includes(pageTypeEnum.naturalEntityMonitoringList) &&
        this.hasMonitoringProduct
      ) {
        menu.push({
          label: 'App.SubMenu.MonitoringList',
          to: { name: 'naturalEntityMonitoringList' }
        })
      }

      return menu
    },

    callSubMenu() {
      const { userPages } = this
      if (!userPages || !userPages.includes(pageTypeEnum.callSettings)) {
        return null
      }

      return [
        { label: 'AppVideoCall', to: { name: 'VideoCall' } },
        { label: 'AppCallSettings', to: { name: 'CallSettings' } }
      ]
    },

    IdentificationDashboardSubMenu() {
      const { userPages } = this
      if (!userPages) return null

      return [
        {
          label: 'IdentificationDashboard.DashboardCurrentIdent',
          to: { name: 'CurrentIdentifications' }
        },
        {
          label: 'IdentificationDashboard.DashboardFinishedIdent',
          to: { name: 'FinishedIdentifications' }
        }
      ]
    },

    settingsSubMenu() {
      const { userPages } = this
      if (!userPages) return null
      const menu = [
        { label: 'AppUserSettings', to: { name: 'UserSettings' } },
        { label: 'AppBillingSettings', to: { name: 'BillingSettings' } },
        { label: 'AppCheckSettings', to: { name: 'CheckSettings' } },
        { label: 'AppKycSettings', to: { name: 'KycSettings' } },
        {
          label: 'AppIdentitySettings',
          to: { name: 'IdentificationSettings' }
        }
      ]
      return menu
    },

    Psd2SubMenu() {
      const menu = [
        { label: 'AppMyApps', to: { name: 'PsdMyApps' } },
        { label: 'AppDashboard', to: { name: 'PsdDashboard' } },
        { label: 'AppDocuments', to: { name: 'PsdDocuments' } }
      ]
      return menu
    },

    kybSubMenu() {
      const menu = [
        { label: 'KybCompanySearch', to: { name: 'KybCompanyList' } },
        {
          label: 'KybMonitoringEvents',
          to: { name: 'LegalEntityMonitoringEvents' }
        },
        {
          label: 'KybMonitoringSetup',
          to: { name: 'LegalEntityMonitoringSetup' }
        },
        {
          label: 'CompanyReports',
          to: { name: 'CompanyReportsPage' }
        }
      ]
      return menu
    },

    subMenu() {
      const {
        $route,
        adminSubMenu,
        settingsSubMenu,
        Psd2SubMenu,
        identificationsSubMenu,
        kybSubMenu,
        callSubMenu,
        IdentificationDashboardSubMenu
      } = this

      if ($route) {
        const routeName =
          $route.matched && $route.matched.length > 1 && $route.matched[1].name
        if (routeName === 'Admin' || routeName === 'AgentUrls') {
          return adminSubMenu
        } else if (routeName === 'Settings') {
          return settingsSubMenu
        } else if (routeName === 'Psd2') {
          return Psd2SubMenu
        } else if (routeName === 'IdentificationDashboard') {
          return IdentificationDashboardSubMenu
        } else if (routeName === 'Call') {
          return callSubMenu
        } else if (
          routeName === 'identificationsSearch' ||
          routeName === 'Webhook' ||
          routeName === 'StopList' ||
          routeName === 'CompanyProcedures' ||
          routeName === 'naturalEntityMonitoringEvents' ||
          routeName === 'naturalEntityMonitoringList'
        ) {
          return identificationsSubMenu
        } else if (routeName === 'Kyb') {
          return kybSubMenu
        }
      }
      return null
    }
  }
}
</script>

<style scoped>
@media (max-width: 1400px) {
  .m-menu__nav.m-menu__nav--submenu-arrow {
    display: flex;
    justify-content: center;
    max-height: 60px;
    flex-flow: column wrap;
    margin-top: 1rem;
  }

  .m-menu__nav.m-menu__nav--submenu-arrow > * {
    display: block;
    height: auto;
  }

  .m-header-menu .m-menu__nav .m-menu__item .m-menu__link .m-menu__link-text {
    font-size: 13px;
  }
}

@media (max-width: 1025px) {
  .m-menu__nav.m-menu__nav--submenu-arrow {
    margin-top: 0;
  }
}
</style>

<style>
@media (max-width: 1400px) {
  .sub-menu
    .m-menu__nav
    .m-menu__item.m-menu__item--active
    .m-menu__link
    .m-menu__item-here {
    display: none;
  }
}
</style>
