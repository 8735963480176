import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'

const _language = 'en'
const _version = new Date().getTime()

export let i18n = null

export default {
  install: (Vue) => {
    Vue.use(VueI18n)

    i18n = new VueI18n({
      locale: getLanguage(),
      fallbackLocale: _language,
      silentTranslationWarn: true
    })

    Vue.prototype.$changeLang = loadTranslations
    Vue.mixin({
      computed: {
        $currentLang: () => i18n?.locale
      }
    })
    loadTranslations(i18n.locale)
  }
}

async function loadTranslations(lang) {
  const translations = await getTranslations(lang)
  if (translations) {
    translations.Enum = await getTranslations(lang, 'enum/')
    translations.Country = await getTranslations(lang, 'countries/')
  }
  i18n.setLocaleMessage(lang, translations)
  i18n.locale = lang
  localStorage.setItem('language', lang)
  locale.use(await getTranslations(lang, 'date/'))
}

function getTranslations(lang, path) {
  path = path || ''
  return new Promise((resolve) => {
    try {
      fetch(`/public/translations/${path}${lang}.json?v=${_version}`).then(
        (result) => {
          resolve(result.json())
        }
      )
    } catch (error) {
      resolve({})
    }
  })
}

function getLanguage() {
  return localStorage.getItem('language') || _language
}
