var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "reset-password" }, [
    _c("img", {
      attrs: { src: require("@src/assets/images/ondato-logo.svg") },
    }),
    _vm._v(" "),
    _c("h1", [_vm._v(_vm._s(_vm.$t("AppResetPassword")))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reset-password__input" },
      [
        _c("ui-input", {
          attrs: {
            disabled: _vm.isLoading,
            type: "password",
            placeholder: "AppPassword",
          },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "reset-password__input" },
      [
        _c("ui-input", {
          attrs: {
            disabled: _vm.isLoading,
            type: "password",
            placeholder: "AppRepeatPassword",
          },
          model: {
            value: _vm.rePassword,
            callback: function ($$v) {
              _vm.rePassword = $$v
            },
            expression: "rePassword",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "error-box" }, [
      _c(
        "ul",
        _vm._l(_vm.errors, function (error, index) {
          return _c("li", { key: index }, [
            _vm._v("\n        " + _vm._s(_vm.$t(error)) + "\n      "),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "button-group" },
      [
        _c(
          "ui-button",
          {
            staticClass: "btn btn-brand",
            attrs: { disabled: _vm.isLoading },
            on: { click: _vm.handleSubmit },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("AppSubmit")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }