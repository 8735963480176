var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    { staticClass: "form-container", on: { submit: _vm.createAgentSettings } },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loader" },
            [_c("ui-loader", { attrs: { size: 4, center: "" } })],
            1
          )
        : [
            _c("h4", [_vm._v(_vm._s(_vm.$t("AgentLink.Manage.Title")))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block input-block" },
              [
                _c("ui-input", {
                  attrs: {
                    type: "agent",
                    label: "AgentLink.Manage.AgentName",
                    multiline: "",
                    "one-line": "",
                    value: _vm.form.agent,
                    error: _vm.errors.agent,
                    disabled: _vm.isLoading,
                  },
                  on: { change: _vm.agentChanged },
                }),
                _vm._v(" "),
                _c("ui-input", {
                  attrs: {
                    type: "email",
                    multiline: "",
                    "one-line": "",
                    label: "AgentLink.Manage.AgentEmail",
                    value: _vm.form.email,
                    error: _vm.errors.email,
                    disabled: _vm.isLoading,
                    "max-length": 255,
                  },
                  on: { change: _vm.emailChanged },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block button-block" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "btn-brand col-lg-12 mx-auto",
                    attrs: {
                      disabled: _vm.isLoading,
                      loading: _vm.isLoading,
                      small: "",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("AgentLink.Manage.Save")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }