var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shared-database-page" },
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "SharedDatabaseHeader",
            method: _vm.searchMethod,
            description: _vm.description,
            filters: _vm.filters,
            disabled: _vm.isLoading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            attrs: {
              placeholder: "HeaderDatabasePlaceholder",
              medium: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.database,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "database", $$v)
              },
              expression: "filters.database",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.databaseList
        ? _c("ui-container", [
            _c("div", { staticClass: "flex-row" }, [
              _c(
                "div",
                { staticClass: "flex-column flex-grow" },
                [
                  _c(
                    "ui-card",
                    {
                      attrs: {
                        title: "Person document information",
                        icon: "flaticon-user",
                        dark: "",
                      },
                    },
                    [
                      _vm.userDetails
                        ? _c(
                            "details-box",
                            [
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.firstName,
                                  label: "DetailsFirstName",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.lastName,
                                  label: "DetailsLastName",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.code,
                                  label: "DetailsPersonCode",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.type,
                                  label: "DetailsDocumentType",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.number,
                                  label: "DetailsDocumentNumber",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.userDetails.expireDate,
                                  label: "DetailsExpireDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("TableNoData")) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-column flex-grow" },
                [
                  _c(
                    "ui-card",
                    {
                      attrs: {
                        title: "Source of information",
                        icon: "flaticon-route",
                        dark: "",
                      },
                    },
                    [
                      _vm.courceList
                        ? _c(
                            "details-box",
                            [
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.date,
                                  label: "DetailsPublishDate",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.publisher,
                                  label: "DetailsPublisher",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.number,
                                  label: "DetailPublisherRegNumber",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.cource,
                                  label: "DetailsSourceIdentification",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.type,
                                  label: "DetailsTypeIdentification",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value: _vm.courceList.id,
                                  label: "DetailsUniqueID",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("TableNoData")) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-column flex-grow" },
                [
                  _c(
                    "ui-card",
                    {
                      attrs: {
                        title: "Ondato verifications",
                        icon: "flaticon-interface-7",
                        dark: "",
                      },
                    },
                    [
                      _vm.verificationsList
                        ? _c(
                            "details-box",
                            [
                              _c("details-item", {
                                attrs: {
                                  value:
                                    _vm.verificationsList.sanction.item +
                                    " (" +
                                    _vm.verificationsList.sanction.data +
                                    ")",
                                  label: "DetailsSanctionsListCheck",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value:
                                    _vm.verificationsList.pep.item +
                                    " (" +
                                    _vm.verificationsList.pep.data +
                                    ")",
                                  label: "DetailsPEPCheck",
                                },
                              }),
                              _vm._v(" "),
                              _c("details-item", {
                                attrs: {
                                  value:
                                    _vm.verificationsList.lostStolen.item +
                                    " (" +
                                    _vm.verificationsList.lostStolen.data +
                                    ")",
                                  label: "DetailsLostStolenCheck",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("TableNoData")) +
                                "\n          "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "justify-center" },
              [
                _c("ui-button", { staticClass: "btn-brand" }, [
                  _c("i", { staticClass: "la la-download" }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("TableDownload")) + "\n      "
                  ),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }