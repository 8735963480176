<template>
  <ui-widget v-if="data" title="KybTimeTitle" :title-value="data.titleValue">
    <ui-message
      v-if="messageValue"
      title="KybCompanyValue"
      :message="messageValue"
    />
    <ui-widget v-if="chartData" class="vertical-group" title="KybTurnoverTitle">
      <line-bar-chart :type="type" :data="chartData" />
    </ui-widget>
    <ui-widget v-if="tableData" title="KybFinancialIndicatorsTitle">
      <ui-table
        :labels="tableLabels"
        :data="tableData"
        class="table-overflow hide-mobile"
      >
        <chart-data-item
          title="TableCurrentSolvencyRatio"
          :data="tableData"
          selector="l1"
        />
        <chart-data-item
          title="TableCriticalCoatingFactor"
          :data="tableData"
          selector="l2"
        />
        <chart-data-item
          title="TablePropertyProfitability"
          :data="tableData"
          selector="p4"
        />
        <chart-data-item
          title="TableProfitabilityBeforeTax"
          :data="tableData"
          selector="p1"
        />
        <chart-data-item
          title="TableStockTurnover"
          :data="tableData"
          selector="e3"
        />
        <chart-data-item
          title="TableTurnoverReceivables"
          :data="tableData"
          selector="e1"
        />
        <chart-data-item
          title="TableTurnoverAmountsDue"
          :data="tableData"
          selector="e2"
        />
        <chart-data-item
          title="TableEquityPropertyRatio"
          :data="tableData"
          selector="f2"
        />
        <chart-data-item
          title="TableNetProfitability"
          description="TableNetProfitabilityDescription"
          :data="tableData"
          selector="p3"
        />
        <chart-data-item
          title="TableTotalProfitability"
          description="TableTotalProfitabilityDescription"
          :data="tableData"
          selector="p2"
        />
        <chart-data-item
          title="TableEbit"
          description="TableEbitDescription"
          :data="tableData"
          selector="ebit"
        />
        <chart-data-item
          title="TableEbitMargin"
          description="TableEbitMarginDescription"
          :data="tableData"
          selector="ebitMargin"
        />
        <chart-data-item
          title="TableEbitda"
          description="TableEbitdaDescription"
          :data="tableData"
          selector="ebitda"
        />
        <chart-data-item
          title="TableEbitdaMargin"
          description="TableEbitdaMarginDescription"
          :data="tableData"
          selector="ebitdaMargin"
        />
        <chart-data-item
          title="TableDebtEquityRatio"
          description="TableDebtEquityRatioDescription"
          :data="tableData"
          selector="debtProperty"
        />
        <chart-data-item
          title="TableWorkingCapitalRequirement"
          description="TableWorkingCapitalRequirementDescription"
          :data="tableData"
          selector="workingCapitalRequirement"
        />
        <chart-data-item
          title="TableSalesPerEmployee"
          description="TableSalesPerEmployeeDescription"
          :data="tableData"
          selector="salesPerEmployee"
        />
        <chart-data-item
          title="TableProfitPerEmployee"
          description="TableProfitPerEmployeeDescription"
          :data="tableData"
          selector="profitPerEmployee"
        />
        <chart-data-item
          title="TableChangeInSales"
          :data="tableData"
          selector=""
        />
      </ui-table>
      <ui-table :data="tableData" class="table-overflow show-mobile">
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableCurrentSolvencyRatio"
          :data="tableData"
          selector="l1"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableCriticalCoatingFactor"
          :data="tableData"
          selector="l2"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TablePropertyProfitability"
          :data="tableData"
          selector="p4"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableProfitabilityBeforeTax"
          :data="tableData"
          selector="p1"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableStockTurnover"
          :data="tableData"
          selector="e3"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableTurnoverReceivables"
          :data="tableData"
          selector="e1"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableTurnoverAmountsDue"
          :data="tableData"
          selector="e2"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableEquityPropertyRatio"
          :data="tableData"
          selector="f2"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableNetProfitability"
          description="TableNetProfitabilityDescription"
          :data="tableData"
          selector="p3"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableTotalProfitability"
          description="TableTotalProfitabilityDescription"
          :data="tableData"
          selector="p2"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableEbit"
          description="TableEbitDescription"
          :data="tableData"
          selector="ebit"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableEbitMargin"
          description="TableEbitMarginDescription"
          :data="tableData"
          selector="ebitMargin"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableEbitda"
          description="TableEbitdaDescription"
          :data="tableData"
          selector="ebitda"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableEbitdaMargin"
          description="TableEbitdaMarginDescription"
          :data="tableData"
          selector="ebitdaMargin"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableDebtEquityRatio"
          description="TableDebtEquityRatioDescription"
          :data="tableData"
          selector="debtProperty"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableWorkingCapitalRequirement"
          description="TableWorkingCapitalRequirementDescription"
          :data="tableData"
          selector="workingCapitalRequirement"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableSalesPerEmployee"
          description="TableSalesPerEmployeeDescription"
          :data="tableData"
          selector="salesPerEmployee"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableProfitPerEmployee"
          description="TableProfitPerEmployeeDescription"
          :data="tableData"
          selector="profitPerEmployee"
        />
        <chart-data-item-mobile
          :labels="mobileTableLabels"
          title="TableChangeInSales"
          :data="tableData"
          selector=""
        />
      </ui-table>
    </ui-widget>
  </ui-widget>
  <company-no-info v-else />
</template>

<script>
import ChartDataItem from '@src/components/partials/ChartDataItem'
import ChartDataItemMobile from '@src/components/partials/ChartDataItemMobile'
import LineBarChart from '@src/components/partials/LineBarChart'
import { widgetTypeEnum } from '@src/scripts/enums'
import CompanyNoInfo from '@src/components/partials/CompanyNoInfo'

export default {
  components: {
    CompanyNoInfo,
    ChartDataItem,
    LineBarChart,
    ChartDataItemMobile
  },

  props: {
    data: { type: Object, default: Object }
  },

  data() {
    return {
      type: widgetTypeEnum.barChart
    }
  },

  computed: {
    messageValue() {
      const { data } = this
      if (!data || !data.companyValue) return
      return `${data.companyValue} \u20AC`
    },

    tableData() {
      const {
        data: { indicators }
      } = this
      if (!indicators || !indicators.length) return
      return indicators
    },

    chartData() {
      const { tableData } = this
      if (!tableData || tableData.length < 2) return

      const labels = []
      const values = []
      tableData.forEach((item) => {
        labels.push(item.year)
        values.push(item.turnover)
      })

      return {
        datasets: [
          {
            label: 'ChartRange',
            values
          }
        ],
        labels
      }
    },

    tableLabels() {
      const { tableData } = this
      if (!tableData) return

      const labels = tableData.map((item) => {
        return item.year
      })

      return [null, ...labels]
    },

    mobileTableLabels() {
      const { tableData } = this
      if (!tableData) return

      const labels = tableData.map((item) => {
        return item.year
      })

      return [...labels]
    }
  }
}
</script>

<style scoped>
.show-mobile {
  display: none;
}

@media (max-width: 568px) {
  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none;
  }
}
</style>
