var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editable-list" },
    [
      _c(
        "div",
        { staticClass: "display-flex" },
        [
          _vm.editEnabled
            ? _c("ui-dropdown", {
                staticClass: "dropdown-margin",
                attrs: {
                  all: _vm.all,
                  "has-search": _vm.hasSearch,
                  "multi-select": _vm.multiSelect,
                  placeholder: _vm.addLabel,
                  options: _vm.availableOptions,
                  "get-items-on-select": _vm.getItemsOnSelect,
                  disabled: _vm.disabled,
                },
                on: {
                  input: _vm.addItem,
                  selectAll: _vm.selectAll,
                  deleteAll: _vm.deleteAll,
                  getItemsOnSelect: _vm.addItem,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.multiSelect && _vm.editEnabled
            ? _c(
                "ui-button",
                {
                  staticClass: "delete-button btn btn-danger",
                  attrs: { type: "button" },
                  on: { click: _vm.deleteAll },
                },
                [
                  _c("i", { staticClass: "la la-close" }),
                  _vm._v(" " + _vm._s(_vm.$t("RemoveAllCompanies")) + "\n    "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, index) {
        return _c("editable-list-item", {
          key: index,
          attrs: {
            item: item,
            "edit-enabled": _vm.editEnabled,
            disabled: _vm.disabled,
          },
          on: { remove: _vm.removeItem },
        })
      }),
      _vm._v(" "),
      !_vm.items || !_vm.items.length
        ? _c("span", { staticClass: "error-message" }, [
            _vm._v("\n    " + _vm._s(_vm.$t(_vm.emptyMessage)) + "\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }