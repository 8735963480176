var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", [_c("ui-loader", { attrs: { size: 4, center: "" } })], 1)
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "ui-card",
            { attrs: { title: "Dashboard.Call.RealTime.Title" } },
            [
              _c("details-item-orange", {
                attrs: {
                  label: "Dashboard.Call.RealTime.Waiting",
                  value: _vm.videoCallsWaitingInQueue,
                },
              }),
              _vm._v(" "),
              _c("details-item-orange", {
                attrs: {
                  label: "Dashboard.Call.RealTime.LongestWaiting",
                  value: _vm.longestTimeUserWaitingInVideoCallQueue,
                },
              }),
              _vm._v(" "),
              _vm.showNumberOfFormsBeingFilled
                ? _c("details-item-orange", {
                    attrs: {
                      label: "Dashboard.Call.RealTime.ActiveForms",
                      value: _vm.numberOfFormsBeingFilled,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }