var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isReady
    ? _c("search-state", {
        attrs: {
          loading: _vm.isLoading,
          empty: _vm.isEmpty,
          error: _vm.isError,
          "loading-message": _vm.$t("App.Loading"),
          "empty-message": _vm.$t("CompanyProcedures.EmptyState.Message"),
          "empty-sub-message": _vm.$t(
            "CompanyProcedures.EmptyState.SubMessage"
          ),
          "error-message": _vm.$t("Error.Fail"),
        },
      })
    : _c("ProceduresForm", { attrs: { type: "update", model: _vm.model } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }