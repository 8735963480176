<template>
  <div class="m-subheader-search">
    <h2 class="m-subheader-search__title">
      {{ $t(title) }}
    </h2>
    <p v-if="description" class="color-white">
      {{ $t(description) }}
    </p>
    <ui-form v-if="hasSearhContent" class="m-form" @submit="search">
      <div class="header-content display-flex">
        <div class="m-grid__item filters-list">
          <slot />
        </div>
        <div>
          <ui-button
            type="submit"
            class="m-subheader-search__submit-btn"
            :disabled="disabled"
          >
            {{ $t('AppSearch') }}
          </ui-button>
        </div>
      </div>
    </ui-form>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import actions from '@src/store/actions'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'
export default {
  name: 'ui-header-get',

  props: {
    title: { type: String, default: undefined },
    method: { type: String, default: undefined },
    filters: { type: String, default: undefined },
    description: { type: String, default: undefined },
    requiredFilters: { type: Array, default: undefined },
    disabled: Boolean,
    disableSearchOnLoad: Boolean
  },

  data() {
    return {
      isReady: false,
      isUpdating: false,
      isLoading: false,
      refreshSettings: {
        isAuto: false,
        interval: 300000,
        timer: null
      }
    }
  },

  computed: {
    ...mapGetters([getters.selectedCompany, getters.searchHistory]),

    pageName() {
      return this.$route.name
    },

    hasSearhContent() {
      return !!this.$slots.default
    },

    searchFilters() {
      const { searchHistory, pageName } = this
      return searchHistory(pageName)
    },

    mappedFilters() {
      return this.searchFilters || this.filters
    }
  },

  watch: {
    selectedCompany() {
      this.resetPage()
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    search() {
      this.resetPage()
      this.prepareSearch()
    },

    prepareSearch() {
      const { pageName, filters, loadData } = this
      this.$store.dispatch(actions.updateHistory, {
        key: pageName,
        value: filters
      })

      loadData()
    },

    resetPage() {
      this.isUpdating = true
      this.$nextTick(() => {
        this.isUpdating = false
      })
    },

    async loadData() {
      if (this.isLoading) return
      this.isLoading = true

      const { method, getData } = this
      this.$emit('startSearch')
      const payload = this.filters
      const result = await getData(method, payload)
      this.$emit('finishSearch', result || {})

      this.isLoading = false
    },

    async getData(method, payload) {
      try {
        return await Api.get(`${method}/${payload}`)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
        return null
      }
    }
  }
}
</script>

<style scoped>
.display-flex > * {
  margin-bottom: 0.5rem;
}

.filters-list {
  display: flex;
  flex-wrap: wrap;
}

.header-content {
  flex-wrap: wrap;
}

.header-content button {
  height: 40px;
}

.color-white {
  color: #fff;
  margin-top: 1.5rem;
}
</style>
