var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-subheader-search" }, [
    _c("h2", { staticClass: "m-subheader-search__title" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
    ]),
    _vm._v(" "),
    _vm.description
      ? _c("p", { staticClass: "description" }, [
          _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.hasContent
      ? _c("div", { staticClass: "content" }, [_vm._t("default")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }