var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "documents-container" },
    [
      _c(
        "div",
        { staticClass: "documents-header" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$t("DocumentTitle")))]),
          _vm._v(" "),
          _c(
            "ui-button",
            { staticClass: "btn-brand", on: { click: _vm.handleFile } },
            [_vm._v("\n      " + _vm._s(_vm.$t("DocumentAddFile")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "documents-body",
          on: { reloadDocument: _vm.handleReload },
        },
        [
          _vm.isLoading
            ? _c(
                "div",
                [_c("ui-loader", { attrs: { size: 2.5, center: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isLoading
            ? _c(
                "div",
                _vm._l(_vm.documentList, function (documentItem, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("DocumentItem", {
                        attrs: {
                          id: documentItem.id,
                          "application-id": documentItem.applicationId,
                          "created-utc": documentItem.createdUtc,
                          "has-file": documentItem.hasFile,
                          "identification-id": documentItem.identificationId,
                          "last-action-utc": documentItem.lastActionUtc,
                          title: documentItem.title,
                          status: documentItem.status,
                          purpose: documentItem.purpose,
                        },
                        on: {
                          openDocumentModal: function ($event) {
                            return _vm.openDocumentModal(documentItem.id)
                          },
                          reloadDocument: _vm.handleReload,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.documentList.length
            ? _c("div", { staticClass: "warning" }, [
                _c("div", { staticClass: "warning-message" }, [
                  _vm._v(_vm._s(_vm.$t("DocumentErrorEmpty"))),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        {
          attrs: { "pop-up-title": _vm.$t("DocumentModalTitle") },
          on: { close: _vm.handleReload },
        },
        [
          _c("DocumentModal", {
            attrs: { id: _vm.$route.query.id, "modal-props": _vm.modalProps },
            on: { reloadDocument: _vm.handleReload },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }