var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        download: _vm.name,
        href: _vm.item,
        title: _vm.$t(_vm.compress ? "ButtonCompress" : "ButtonOriginal"),
      },
    },
    [
      _c(
        "ui-button",
        {
          class: _vm.compress ? "btn-brand" : "btn-metal",
          attrs: {
            icon: "la la-download",
            small: _vm.small,
            low: _vm.low,
            "small-text": _vm.smallText,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }