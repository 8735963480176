import { searchStatuses } from '@src/scripts/enums'
import Api from '@src/scripts/api'

const mutationTypes = Object.freeze({
  setKybSearchReady: 'setKybSearchReady',
  setKybSearchLoading: 'setKybSearchLoading',
  setKybSearchError: 'setKybSearchError',
  setKybSearchPage: 'setKybSearchPage',
  setKybSearchIdentifications: 'setKybSearchIdentifications',
  setKybSearchPayload: 'setKybSearchPayload'
})

const actionTypes = Object.freeze({
  searchKybIdentifications: 'searchKybIdentifications'
})

export const state = {
  kyb: {
    search: {
      state: searchStatuses.ready,
      payload: null,
      identifications: null,
      page: '0',
      limit: 100,
      total: 0
    }
  }
}

export const getters = {}

export const mutations = {
  [mutationTypes.setKybSearchReady]: setKybSearchReady,
  [mutationTypes.setKybSearchLoading]: setKybSearchLoading,
  [mutationTypes.setKybSearchError]: setKybSearchError,
  [mutationTypes.setKybSearchPage]: setKybSearchPage,
  [mutationTypes.setKybSearchIdentifications]: setKybSearchIdentifications,
  [mutationTypes.setKybSearchPayload]: setKybSearchPayload
}

export const actions = {
  [actionTypes.searchKybIdentifications]: searchKybIdentifications
}

function setKybSearchReady(state) {
  const { search } = state.kyb

  if (search.state === searchStatuses.ready) return
  search.state = searchStatuses.ready
}

function setKybSearchLoading(state) {
  const { search } = state.kyb

  if (search.state === searchStatuses.loading) return
  search.state = searchStatuses.loading
}

function setKybSearchError(state) {
  const { search } = state.kyb

  if (search.state === searchStatuses.error) return
  search.state = searchStatuses.error
}

function setKybSearchPage(state, value) {
  const { search } = state.kyb

  if (search.page === value) return
  search.page = value || '0'
}

function setKybSearchIdentifications(state, data) {
  const { search } = state.kyb

  const identifications = data.identifications?.length
    ? data.identifications
    : null

  search.identifications = identifications
  search.total = data.total || 0
}

function setKybSearchPayload(state, value) {
  const { search } = state.kyb

  search.payload = value
}

async function searchKybIdentifications({ commit }, value) {
  try {
    const result = await Api.post('/kybidentifications/search', value)
    commit(mutationTypes.setKybSearchIdentifications, result)
    commit(mutationTypes.setKybSearchReady)
  } catch (error) {
    commit(mutationTypes.setKybSearchError)
  }
}
