<template>
  <div class="display-flex">
    <ui-widget
      title="KybTimeTitle"
      :title-value="data.titleValue ? data.titleValue : '-'"
    >
      <details-item
        label="DetailsCompanyCode"
        :value="data.code ? data.code : '-'"
      />
      <details-item
        label="DetailsCompanyStatus"
        :value="data.status ? data.status : '-'"
      />
      <details-item
        label="DetailsVatNumber"
        :value="data.vatNumber ? data.vatNumber : '-'"
      />
      <details-item
        v-if="data.manager"
        label="DetailsCompanyHead"
        :value="data.manager ? data.manager : '-'"
      >
        <div>
          <router-link
            :to="{ name: 'SanctionsSearch', query: sanctionsSearchQuery }"
            target="_blank"
            @click.native="checkNoSanctionSearchPermission"
          >
            {{ $t('DetailsScreenSanctions') }}
            <i class="fas fa-external-link-square-alt"></i>
          </router-link>
        </div>
      </details-item>
      <details-item
        label="DetailsAddress"
        :value="data.address ? data.address : '-'"
      />
      <details-item
        label="DetailsPhoneNumber"
        :value="data.phone ? data.phone : '-'"
      />
      <details-item
        label="DetailsEmail"
        :value="data.email ? data.email : '-'"
      />
      <details-item
        label="DetailsWeb"
        :value="data.url ? data.url : '-'"
        :hyperlink="!!data.url"
      />
      <details-item
        label="DetailsCompanyIndustry"
        :value="data.industry ? data.industry : '-'"
      />
      <details-item
        v-if="data.initialDate || data.added"
        label="KybStartedMonitoring"
        :value="data.initialDate || localDate"
      />
    </ui-widget>
    <div class="right-block">
      <ui-widget class="vertical-group" title="KybAddressTitle">
        <company-address-map :address="data.address" />
      </ui-widget>
      <ui-widget
        v-if="
          data.numberOfEmployees ||
          data.numberOfEmployeesBefore20200301 ||
          averageSalary
        "
        title="KybHumanResourcesTitle"
      >
        <resources-card
          v-if="data.numberOfEmployees"
          icon="la la-user"
          title="DetailsEmployeesTitle"
          :description="data.numberOfEmployees"
        />
        <resources-card
          v-if="data.numberOfEmployeesBefore20200301"
          icon="la la-user"
          title="DetailsEmployees20200301"
          :description="data.numberOfEmployeesBefore20200301"
        />
        <resources-card
          v-if="averageSalary"
          icon="la la-eur"
          title="DetailsAverageSalaryTitle"
          :description="averageSalary"
        />
      </ui-widget>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { notificationType } from '@src/components/notification'
import DetailsItem from '@src/components/partials/DetailsItem'
import ResourcesCard from '@src/components/partials/ResourcesCard'
import CompanyAddressMap from '@src/components/partials/CompanyAddressMap'
import { pageTypeEnum } from '@src/scripts/enums'
import getters from '@src/store/getters'
import { dateToString } from '@src/scripts/helpers'

export default {
  components: {
    CompanyAddressMap,
    DetailsItem,
    ResourcesCard
  },

  props: {
    data: { type: Object, default: Object }
  },
  computed: {
    ...mapGetters([getters.havePermission]),
    localDate() {
      return dateToString(this.data.added, true)
    },
    averageSalary() {
      const {
        data: { averageSalary }
      } = this
      if (!averageSalary) return null
      return `${averageSalary} \u20AC`
    },
    sanctionsSearchQuery() {
      return { searchTerm: this.data.manager?.split(',')[0] }
    },
    hasSanctionSearchAccess() {
      return this.havePermission(pageTypeEnum.sanctionsScreening)
    }
  },
  methods: {
    checkNoSanctionSearchPermission(event) {
      if (!this.hasSanctionSearchAccess) {
        event.preventDefault()
        event.stopPropagation()
        this.$notify({
          title: 'DetailsScreenSanctionsPermissionRequired',
          message: 'DetailsScreenSanctionsPermissionRequest',
          type: notificationType.info
        })
      }
    }
  }
}
</script>

<style scoped>
.right-block {
  max-width: 40rem;
}

.display-flex > * {
  width: 50%;
}

@media (max-width: 992px) {
  .display-flex {
    flex-direction: column;
  }

  .display-flex > * {
    width: 100%;
  }

  .details-item {
    padding: 0.2rem 0.25rem;
  }
}
</style>
