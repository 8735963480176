var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-toggle", class: { "ui-toggle--header": _vm.isheader } },
    [
      _c("span", { staticClass: "m-switch m-switch--icon" }, [
        _c("label", [
          _c("input", {
            attrs: { type: "checkbox", disabled: _vm.disabled, hidden: "" },
            domProps: { checked: _vm.value },
            on: { click: _vm.click },
          }),
          _vm._v(" "),
          _c("span"),
        ]),
        _vm._v(" "),
        _vm.label
          ? _c("span", { staticClass: "toggle-label" }, [
              _vm._v("\n      " + _vm._s(_vm.$t(_vm.label)) + "\n    "),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }