import { dateToString } from '@src/scripts/helpers'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const phoneNumberRegex = /^[0-9 -/)(+.]{1,}$/
const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^ &*-]).{8,}$/

export function requiredValidator(value) {
  if (!value || value.trim().length === 0) {
    return 'ErrorFieldRequired'
  }
  return null
}

export function minLengthValidator(value, minLength) {
  if (!minLength) minLength = 3
  value = (value && value.trim()) || ''
  if (value.length > 0 && value.length < minLength) {
    return 'ErrorFieldMinLength'
  }
  return null
}

export function maxLengthValidator(value, maxLength) {
  if (!maxLength) maxLength = 50
  value = (value && value.trim()) || ''
  if (value.length > maxLength) {
    return 'ErrorFieldMaxLength'
  }
  return null
}

export function phoneNumberValidator(value) {
  value = (value && value.trim()) || ''
  if (value.length > 0 && (value.length < 7 || !phoneNumberRegex.test(value))) {
    return 'ErrorFieldPhoneNumber'
  }
  return null
}

export function passwordValidator(value) {
  if (value && !passwordRegex.test(value.trim())) {
    return 'ErrorFieldPassword'
  }
  return null
}

export function emailValidator(value) {
  if (value && !emailRegex.test(value.trim())) {
    return 'ErrorFieldEmail'
  }
  return null
}

export function integerValidator(value) {
  if (value) {
    value = value.trim()
    if (!/^[0-9-]*$/.test(value)) {
      return 'Wrong integer number'
    }
    const number = parseInt(value)
    if (!Number.isInteger(number)) {
      return 'Wrong number'
    }
  }
  return null
}

// export function floatValidator(value) {
//   if (value) {
//     value = value.trim()
//     if (!/^[0-9,.-]*$/.test(value)) {
//       return 'Wrong float number'
//     }
//     value = replaceAll(value, ',', '.')
//     const number = parseFloat(value)
//     if (Number.isNaN(number) || (value.match(/\./g) || []).length > 1) {
//       return 'Wrong number'
//     }
//   }
//   return null
// }

// export function minMaxValueValidator(value, minValue, maxValue) {
//   const number = parseFloat(replaceAll(value, ',', '.'))
//   if ((minValue || minValue === 0) && number < minValue) {
//     return `Minimum value: ${minValue}`
//   }
//   if (maxValue && number > maxValue) {
//     return `Maximum value: ${maxValue}`
//   }
//   return null
// }

export function selectValidator(value) {
  if (!value || (typeof value === 'string' && value.trim().length === 0)) {
    return 'ErrorFieldRequired'
  }
  return null
}

export function dateValidator(value) {
  value = (value && value.trim()) || ''
  if (!value) return null
  const dateValues = value.split('-')
  if (dateValues.length !== 3) return 'ErrorFieldBadDateFormat'
  if (value.includes('T')) value = `${value}Z`
  const date = new Date(value)
  if (date.getUTCFullYear() !== parseInt(dateValues[0]))
    return 'ErrorFieldBadDateFormat'
  if (date.getUTCFullYear() < 1869) return 'ErrorFieldBadDate'
  if (date.getUTCMonth() + 1 !== parseInt(dateValues[1]))
    return 'ErrorFieldBadDateFormat'
  if (date.getUTCDate() !== parseInt(dateValues[2]))
    return 'ErrorFieldBadDateFormat'
  return null
}

export function includesValidator(options, value, errorMessage) {
  if (value && (!options || !options.includes(value.toUpperCase()))) {
    return errorMessage || 'ErrorNotInList'
  }
  return null
}

export function validateForm(errors) {
  for (const key in errors) {
    if (errors[key]) {
      return false
    }
  }
  return true
}

export function birthdayValidator(value) {
  const dateNow = dateToString(new Date())
  if (value && dateNow < value) {
    return 'ErrorFieldBadDate'
  }
  return null
}
