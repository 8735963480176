<template>
  <div>
    <div class="document-modal-body">
      <div v-if="isLoading"><ui-loader :size="2.5" center /></div>
      <div v-if="!isLoading && !addFileStep">
        <p>{{ $t('DocumentStepOne') }}</p>
        <ui-input
          v-model="value"
          :error="error"
          class="fixed-width"
          :value="value"
        />
      </div>

      <div v-if="!isLoading && addFileStep">
        <p>{{ $t('DocumentStepTwo') }}</p>

        <label
          v-if="!file"
          class="custom-file-upload"
          @change="handleFileUpload"
        >
          <span>{{ $t('DocumentSelectFile') }}</span>
          <input
            ref="file"
            type="file"
            accept=".jpg, .jpeg, .png, .gif, .doc, .pdf"
            @change="handleFileUpload"
          />
        </label>
        <div v-if="file">
          <div class="file-name">
            <img class="icon" :src="require('@src/assets/images/doc.svg')" />
            <span>
              {{ file.name }}
            </span>
          </div>
          <div class="document-file-status">
            {{ $t('DocumentStatus') }}: {{ $t('DocumentStatusDone') }}
          </div>
        </div>
      </div>
    </div>

    <div class="document-modal-footer">
      <ui-button
        v-if="!addFileStep"
        :disabled="isLoading || !value"
        class="btn-brand mx-auto"
        @click="handleSubmit"
      >
        {{ $t('DocumentContinue') }}
      </ui-button>

      <ui-button
        v-if="addFileStep"
        :disabled="!file || isLoading"
        class="btn-brand mx-auto"
        @click="handleFileSubmit"
      >
        {{ $t('DocumentFinish') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'

export default {
  props: {
    id: { type: String, default: undefined },
    modalProps: { type: Object, default: null }
  },
  data: () => ({
    value: '',
    error: '',
    documentId: '',
    file: '',
    isLoading: false,
    addFileStep: false
  }),
  created() {
    if (this.modalProps) {
      this.documentId = this.modalProps.documentId
      this.addFileStep = this.modalProps.addFileStep
    }
  },
  methods: {
    handleFileUpload() {
      if (this.$refs.file) {
        this.file = this.$refs.file.files[0]
      }
    },
    async handleFileSubmit() {
      this.isLoading = true
      const document = new FormData()
      document.append('file', this.file, this.file.name)

      try {
        await Api.putFormData(
          `/kyb-documents/${this.documentId}/file`,
          document
        )
        this.$emit('reloadDocument', true)
        this.$closePopUp()
        this.addFileStep = false
        this.isLoading = false
      } catch {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
        this.$emit('reloadDocument', true)
        this.$closePopUp()
      }
    },
    async handleSubmit() {
      this.error = !this.value ? this.$t('ErrorFieldRequired') : ''

      if (this.value) {
        try {
          this.isLoading = true
          const data = await Api.post('/kyb-documents', {
            identificationId: this.id,
            purpose: 'ExternalRegistry',
            title: this.value
          })

          this.documentId = data.id

          this.addFileStep = true
          this.isLoading = false
        } catch (e) {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
          this.$emit('reloadDocument', true)
          this.$closePopUp()
          this.isLoading = false
        }
      }
    }
  }
}
</script>

<style>
.document-modal-body {
  width: 320px;
  margin: 30px 0 80px;
}

.btn-brand {
  background-color: var(--brand);
  border-color: var(--brand);
}

.file-name {
  display: flex;
  text-align: left;
  font-size: 18px;
  line-height: 21px;
}

.file-name span {
  word-break: break-word;
}

.file-name img {
  padding-right: 8px;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.custom-file-upload span {
  display: block;
  background: #ff5024;
  color: #fff;
  padding: 13px 0;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2px;
  width: 135px;
  cursor: pointer;
}

.custom-file-upload input {
  display: none;
}

.document-modal-footer {
  text-align: right;
}

.document-file-status {
  padding-top: 5px;
}
</style>
