<template>
  <div>
    <ui-header title="CheckSettingHeader" />
    <ui-container>
      <ui-card>
        <div>
          <h3>{{ $t('CheckSettingBeforeHeader') }}</h3>
        </div>
        <div class="flex-row">
          <div class="flex-basic-70">
            {{ $t('CheckSettingBeforeDescription') }}
          </div>
          <div class="flex-basic-30">
            <question-box :questions="questions" />
          </div>
        </div>
      </ui-card>
      <ui-card title="CheckSettingMethodHeader">
        <checking-methods />
      </ui-card>
      <ui-card title="CheckSettingCountriesTitle">
        <regional-settings />
      </ui-card>
      <ui-card title="CheckDetailedCountrySettingsTitle">
        <country-settings />
      </ui-card>
    </ui-container>
  </div>
</template>

<script>
import QuestionBox from '@src/components/partials/QuestionBox'
import RegionalSettings from '@src/components/partials/RegionalSettings'
import CheckingMethods from '@src/components/partials/CheckingMethods'
import CountrySettings from '@src/components/partials/CountrySettings'

export default {
  components: {
    QuestionBox,
    RegionalSettings,
    CheckingMethods,
    CountrySettings
  },
  data() {
    return {
      questions: [
        {
          question: 'What is MRZ zone?',
          answer: 'MRZ check (or Machiene ..'
        },
        {
          question: 'What is image spoofing?',
          answer: 'Spoofing is...'
        },
        {
          question: 'Legal terms',
          answer: 'Terms of use ..'
        }
      ]
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2.5rem;
}

.flex-basic-70 {
  flex-basis: 70%;
  padding-right: 1.5rem;
}

.flex-basic-30 {
  flex-basis: 30%;
}

.bold {
  font-family: 'metropolisbold', sans-serif;
}

@media (max-width: 800px) {
  .flex-basic-30 {
    flex-basis: 100%;
    margin-top: 2.2rem;
  }

  .flex-basic-70 {
    flex-basis: 100%;
  }
}
</style>
