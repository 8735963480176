var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item" }, [
    _vm.editEnabled
      ? _c(
          "a",
          {
            class: { "item-disabled": _vm.disabled },
            on: {
              click: function ($event) {
                return _vm.$emit("remove", _vm.item.key)
              },
            },
          },
          [_vm._v("\n    -\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.$t(_vm.item.value)))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }