<template>
  <div
    v-if="data"
    class="results-block identification-photo"
    :class="{ 'image-local': local }"
  >
    <div
      class="image-box"
      :style="imageStyle"
      @mousemove="zoomPosition"
      @touchmove="zoomPosition"
    >
      <div class="large-image" :style="zoomStyle" />
    </div>
    <div v-if="!local" class="result-item">
      <span v-if="data.fileExtension" class="image-type">
        .{{ data.fileExtension }}
      </span>
      <span v-if="data.created" class="result-time">
        {{ data.created | date }}
      </span>
    </div>
    <span v-if="!local" class="buttons">
      <ui-button
        class="btn-metal"
        icon="la la-rotate-right"
        small
        low
        @click="rotateImage"
      />
      <ui-button
        class="btn-metal"
        icon="la la-search-plus"
        small
        low
        @click="openImage"
      />
      <ui-save-button small low :name="fileName" :href="rotatedImage" />
      <ui-save-button
        v-if="canCompress"
        small
        low
        compress
        :name="fileName"
        :href="rotatedImage"
      />
    </span>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { dateToString, blobToDataUrl, rotateImage } from '@src/scripts/helpers'

export default {
  filters: {
    date(value) {
      if (!value) return null
      return dateToString(value, true)
    }
  },

  props: {
    data: { type: [Object, String], default: undefined },
    token: { type: String, default: undefined },
    local: Boolean
  },

  data() {
    return {
      rotation: 0,
      image: null,
      rotatedImage: null,
      zoomRate: 2.5,
      aspectRatio: null,
      position: {
        x: 0,
        y: 0
      }
    }
  },

  computed: {
    imageStyle() {
      const { rotation, image } = this
      return {
        backgroundImage: `url(${image})`,
        transform: `rotate(${rotation}deg)`
      }
    },

    zoomStyle() {
      const { image, zoomRate, aspectRatio, rotation, position } = this
      if (!aspectRatio) return

      const widthMultiplier = aspectRatio < 1 ? aspectRatio : 1
      const heightMultiplier = aspectRatio > 1 ? aspectRatio : 1

      const width = 100 * zoomRate * widthMultiplier
      const height = (100 * zoomRate) / heightMultiplier

      const backgroundPosition = this.getBackgroundPosition(rotation, position)

      return {
        backgroundImage: `url(${image})`,
        backgroundColor: 'pink',
        backgroundSize: `${width}% ${height}%`,
        backgroundPositionX: `${backgroundPosition.x}%`,
        backgroundPositionY: `${backgroundPosition.y}%`
      }
    },

    canCompress() {
      const { data } = this
      let extension = data.fileExtension && data.fileExtension.toLowerCase()
      return extension === 'jpeg' || extension === 'jpg'
    },

    fileName() {
      const { token, data } = this

      let extension =
        (data.fileExtension && data.fileExtension.toLowerCase()) || 'png'
      if (extension === 'jpeg') extension = 'jpg'

      return `${token}${data.fileType}.${extension}`
    }
  },

  watch: {
    image(value) {
      if (!value) return

      const image = new Image()
      image.src = value
      image.onload = () => {
        this.aspectRatio = image.width / image.height
      }
    }
  },

  async created() {
    const { data, local } = this
    if (!data) return

    if (local) {
      this.image = this.rotatedImage = this.data
      return
    }

    const { file } = data
    if (!file) return
    const { getImage } = this
    const blob = await getImage(file)
    if (blob) {
      this.image = this.rotatedImage = await blobToDataUrl(blob)
    }
  },

  methods: {
    getBackgroundPosition(rotation, position) {
      const angle = rotation % 360

      if (angle === 0) {
        return { ...position }
      } else if (angle === 90) {
        return {
          x: position.y,
          y: 100 - position.x
        }
      } else if (angle === 180) {
        return {
          x: 100 - position.x,
          y: 100 - position.y
        }
      } else if (angle === 270) {
        return {
          x: 100 - position.y,
          y: position.x
        }
      }
    },

    async rotateImage() {
      this.rotation += 90
      this.rotatedImage = await rotateImage(this.rotatedImage)
    },

    openImage() {
      const { rotatedImage } = this
      const image = new Image()
      image.src = rotatedImage
      const popup = window.open()
      popup.document.write(image.outerHTML)
    },

    zoomPosition(event) {
      if (event.target.className !== 'image-box') return

      const { clientWidth, clientHeight } = event.target
      const { position } = this

      position.x = (event.layerX * 100) / clientWidth
      position.y = (event.layerY * 100) / clientHeight
    },

    async getImage(url) {
      try {
        return await Api.getBlob(url)
      } catch (error) {
        return null
      }
    }
  }
}
</script>

<style scoped>
.mrz-div {
  word-break: break-all;
}

.image-box,
.large-image {
  background-repeat: no-repeat;
}

.image-box {
  background-position: center;
  background-size: contain;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-out;
}

.large-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: visibility 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

.image-box:hover .large-image {
  visibility: visible;
  opacity: 1;
}

.image-type {
  text-transform: uppercase;
}

.image-local {
  width: 100%;
  height: 100%;
}

.image-local .image-box {
  height: 100%;
  padding-bottom: 0;
}
</style>
