var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "box-with-header",
    { staticClass: "call-settings" },
    [
      _vm.isReady
        ? _c(
            "div",
            {
              staticClass: "header-box",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "btn-brand",
                  attrs: { small: "" },
                  on: { click: _vm.saveSettings },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("Call.Settings.Save")) + "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _vm.isReady
            ? [
                _c(
                  "ui-card",
                  {
                    attrs: {
                      title: "Call.Settings.WorkingHours.Title",
                      multicolumn: "",
                    },
                  },
                  [
                    _c(
                      "ui-widget",
                      {
                        staticClass: "default-time",
                        attrs: {
                          slot: "body-left",
                          title: "Call.Settings.WorkingHours.Daily",
                        },
                        slot: "body-left",
                      },
                      [
                        _vm.defaultTime
                          ? _vm._l(_vm.defaultTime, function (
                              value,
                              key,
                              index
                            ) {
                              return _c("time-box", {
                                key: index,
                                attrs: {
                                  label: _vm.$t(
                                    "Call.Settings.WorkingHours." +
                                      _vm.dayNames[index]
                                  ),
                                  value: value,
                                  nullable: "",
                                },
                                on: {
                                  change: function (time) {
                                    return _vm.defaultTimeChanged(key, time)
                                  },
                                },
                              })
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "ui-widget",
                      {
                        attrs: {
                          slot: "body-right",
                          title: "Call.Settings.WorkingHours.Exceptions",
                        },
                        slot: "body-right",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "exception-input" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                "value-format": "yyyy-MM-dd",
                                editable: false,
                                clearable: false,
                                value: _vm.exception.date,
                              },
                              on: { input: _vm.exceptionDateChange },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "checkbox" },
                              [
                                _c("ui-checkbox", {
                                  attrs: {
                                    label:
                                      "Call.Settings.WorkingHours.WorkingDay",
                                    value: _vm.exception.isWorkingDay,
                                  },
                                  on: {
                                    input: _vm.exceptionWorkingFlagChanged,
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("time-box", {
                              attrs: {
                                value: _vm.exception.time,
                                disabled: !_vm.exception.isWorkingDay,
                              },
                              on: { change: _vm.exceptionTimeChanged },
                            }),
                            _vm._v(" "),
                            _c(
                              "ui-button",
                              {
                                staticClass: "btn-brand",
                                attrs: { small: "" },
                                on: { click: _vm.addException },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("Call.Settings.WorkingHours.Add")
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "exception-list" },
                          _vm._l(_vm.orderedExceptions, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.key,
                                staticClass: "exception-item",
                                class: { expired: item.expired },
                              },
                              [
                                _c("span", [
                                  _c("strong", [_vm._v(_vm._s(item.key))]),
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.exceptionValue(item.value)) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "ui-button",
                                  {
                                    staticClass: "btn-metal",
                                    attrs: { small: "" },
                                    on: {
                                      click: function () {
                                        return _vm.removeException(item.key)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t(
                                            "Call.Settings.WorkingHours.Remove"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "ui-card",
                  {
                    attrs: {
                      title: "Call.Settings.WorkingHours.Message.Title",
                      multicolumn: "",
                    },
                  },
                  [
                    _c(
                      "ui-widget",
                      {
                        staticClass: "default-time",
                        attrs: { slot: "body-left" },
                        slot: "body-left",
                      },
                      [
                        _c(
                          "ui-drop-down",
                          {
                            attrs: {
                              label: _vm.$t(
                                "Call.Settings.WorkingHours.Message.Language"
                              ),
                              "value-description": "",
                              narrow: "",
                            },
                            on: { input: _vm.addMessage },
                          },
                          _vm._l(_vm.availableLanguages, function (item) {
                            return _c(
                              "option",
                              { key: item.key, domProps: { value: item.key } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.value) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "ui-widget",
                      { attrs: { slot: "body-right" }, slot: "body-right" },
                      _vm._l(_vm.messageList, function (value, key, index) {
                        return _c(
                          "div",
                          { key: key, staticClass: "message-box" },
                          [
                            _c("ui-input", {
                              attrs: {
                                label: "Language." + key,
                                placeholder:
                                  "Call.Settings.WorkingHours.Message.Placeholder",
                                value: value,
                                "max-length": 256,
                                rows: 3,
                                multiline: "",
                              },
                              on: {
                                input: function (val) {
                                  return _vm.messageChanged(key, val)
                                },
                              },
                            }),
                            _vm._v(" "),
                            index > 0
                              ? _c("button", {
                                  staticClass: "message-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function () {
                                      return _vm.removeMessage(key)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            : _c("ui-card", [_c("ui-loader", { attrs: { center: "" } })], 1),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }