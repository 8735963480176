<template>
  <iframe :id="iframeId" :src="relationGraphUrl" class="iframe" />
</template>

<script>
import Store from '@src/store'
export default {
  data() {
    return {
      // iframeId is used for full-screen implementation
      // it must match the value in "spiderweb" in"src/Components/NetworkGraph.jsx"
      iframeId: 'iframe-spiderweb'
    }
  },
  computed: {
    companyCode() {
      return this.$route.params?.companyCode
    },
    language() {
      return this.$currentLang
    },
    relationGraphUrl() {
      return `/spiderweb/index.html?code=${this.companyCode}`
    }
  },
  watch: {
    language() {
      this.fireLangChange()
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    getData() {
      const { selectedCompany } = Store.getters
      const { session } = Store.state
      const frame = window.frames[this.iframeId]
      frame.addEventListener('load', () => {
        frame.contentWindow.postMessage(
          {
            call: 'sendValue',
            value: {
              origin: BaseUrl.api,
              token: session.access_token,
              company: selectedCompany
            }
          },
          '*'
        )
      })
    },

    fireLangChange() {
      this.$el.contentDocument.dispatchEvent(
        new CustomEvent('languageChange', { detail: this.language })
      )
    }
  }
}
</script>

<style scoped>
.iframe {
  height: 90vh;
  width: 100%;
  border: 0;
  background: var(--light);
}
</style>
