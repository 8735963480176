<template>
  <div>
    <button class="m-aside-left-close m-aside-left-close--skin-light">
      <i class="la la-close" />
    </button>
    <div class="m-grid__item m-aside-left m-aside-left--skin-light">
      <div class="m-brand m-brand--skin-light">
        <router-link class="m-brand__logo" :to="{ name: 'App' }">
          <img src="~@src/assets/images/logo_light.png" />
        </router-link>
      </div>
      <div
        class="m-aside-menu m-aside-menu--skin-light m-aside-menu--submenu-skin-light"
      >
        <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
          <li
            v-for="(item, index) in navigation"
            :key="index"
            class="m-menu__item"
          >
            <router-link :to="item.to" class="m-menu__link">
              <i :class="item.icon" />
              <span class="m-menu__link-text">
                {{ $t(item.label) }}
              </span>
            </router-link>
          </li>
        </ul>
        <start-session v-if="canStartSession" />
      </div>
    </div>
    <div class="m-aside-left-overlay" />
  </div>
</template>

<script>
import { pageTypeEnum } from '@src/scripts/enums'
import Api from '@src/scripts/api'
import { API } from '@src/scripts/enums'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import StartSession from '@src/components/partials/StartSession'

export default {
  components: {
    StartSession
  },

  data() {
    return {
      canStartSession: false
    }
  },

  computed: {
    ...mapGetters([getters.userPages, getters.selectedCompany]),

    navigation() {
      const { userPages } = this
      if (!userPages) return null
      const menu = []

      if (userPages.includes(pageTypeEnum.admin))
        menu.push({
          to: { name: 'Admin' },
          icon: 'flaticon-network',
          label: 'AppAdmin'
        })
      if (userPages.includes(pageTypeEnum.dashboard))
        menu.push({
          to: { name: 'Dashboard' },
          icon: 'flaticon-pie-chart',
          label: 'AppDashboard'
        })
      if (userPages.includes(pageTypeEnum.identifications))
        menu.push({
          to: { name: 'Identifications' },
          icon: 'flaticon-user',
          label: 'AppIdentity'
        })
      if (userPages.includes(pageTypeEnum.secureMeetings))
        menu.push({
          to: { name: 'SecureMeetings' },
          icon: 'flaticon-user',
          label: 'AppSecureMeetings'
        })
      if (userPages.includes(pageTypeEnum.sanctions))
        menu.push({
          to: { name: 'Sanctions' },
          icon: 'flaticon-lifebuoy',
          label: 'AppSanction'
        })
      // {to: {name: 'SharedDatabase'}, icon: 'flaticon-folder-1', label: 'AppDatabase'},
      // {to: {name: 'Settings'}, icon: 'flaticon-settings', label: 'AppSettings'}
      // {to: {name: ''}, icon: 'flaticon-lifebuoy', label: 'Support'},
      // {to: {name: ''}, icon: 'flaticon-network', label: 'Developers portal'}
      if (userPages.includes(pageTypeEnum.manualCheck))
        menu.push({
          to: { name: 'ManualCheck' },
          icon: 'flaticon-edit',
          label: 'AppManualCheck'
        })
      if (userPages.includes(pageTypeEnum.videoCall))
        menu.push({
          to: { name: 'Call' },
          icon: 'flaticon-lifebuoy',
          label: 'AppVideoCall'
        })
      if (userPages.includes(pageTypeEnum.kyb))
        menu.push({
          to: { name: 'Kyb' },
          icon: 'flaticon-lifebuoy',
          label: 'AppKyb'
        })
      // menu.push({ to: { name: 'Psd2' }, icon: 'flaticon-lifebuoy', label: 'AppPsd2' })
      return menu
    }
  },

  watch: {
    selectedCompany() {
      this.getKycSetting()
    }
  },

  created() {
    this.getKycSetting()
  },

  methods: {
    async getKycSetting() {
      const { isKycEnabled } = await Api.get(API.SETTINGS_KYC)
      this.canStartSession = isKycEnabled
    }
  }
}
</script>

<style scoped>
@media (min-width: 1025px) {
  .m-aside-menu {
    height: calc(100% - 90px);
    overflow: auto;
  }
}
@media (max-width: 1026px) {
  .m-aside-menu {
    height: calc(100% - 60px);
    overflow: auto;
  }
}

.m-brand {
  background-color: var(--brand);
}

.m-aside-left {
  overflow: unset;
}

.m-aside-left-overlay {
  display: none;
}

.m-aside-left--on .m-aside-left-overlay {
  display: block;
}

.m-aside-menu {
  padding: 0 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
