<template>
  <div>
    <ui-container>
      <identification-header>
        <template slot="left-content">
          <div
            class="card-header-tabs-btn"
            :class="[
              activeTab === IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                ? 'active'
                : ''
            ]"
            @click="tabChange(IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB)"
          >
            {{ $t('IdentificationDashboard.ActiveUsers') }}
          </div>
          <div
            class="card-header-tabs-btn"
            :class="[
              activeTab === IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
                ? 'active'
                : ''
            ]"
            @click="tabChange(IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB)"
          >
            {{ $t('IdentificationDashboard.ByCompany') }}
          </div>
        </template>
      </identification-header>
      <ui-card class="card-body">
        <ui-loader v-if="isLoading" :size="4" center />
        <template v-else>
          <div class="reload-btn">
            <ui-button class="btn-brand" low small @click="fetchData">
              {{ $t('IdentificationDashboard.ReloadList') }}
            </ui-button>
          </div>
          <div v-if="activeTab === IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB">
            <div class="row">
              <div class="col-6">
                <div class="row mb-4 align-items-start no-gutters">
                  <div class="col-6">
                    <ui-input
                      v-model="userFilterModel"
                      :placeholder="'Id'"
                      :error="userFilterError"
                      type="text"
                    />
                  </div>
                  <div class="col-3">
                    <ui-dropdown
                      v-model="userDateModel"
                      :options="dateOptions"
                      class="mx-2 w-100"
                      small
                    />
                  </div>
                  <div class="col-3">
                    <ui-button
                      class="ml-3 btn-brand filter-button"
                      @click="handleUserFilter('users')"
                    >
                      {{ $t('IdentificationDashboard.Filter') }}
                    </ui-button>
                  </div>
                </div>
              </div>
            </div>
            <dash-board-table
              v-if="Object.keys(identifications.byEmail).length"
            >
              <template slot="table-header">
                <th scope="col">{{ $t('IdentificationDashboard.Email') }}</th>
                <th scope="col">
                  {{ $t('IdentificationDashboard.IdentificationCount') }}
                </th>
                <th scope="col" class="text-center">
                  {{ $t('IdentificationDashboard.Summary') }}
                </th>
              </template>

              <template slot="table-body">
                <tr
                  v-for="(identification, index) in identifications.byEmail"
                  :key="index"
                >
                  <td>
                    {{ index === 'undefined' ? $t('ApprovedInBO') : index }}
                  </td>
                  <td>{{ identification.length }}</td>
                  <td class="text-center">
                    <ui-button
                      v-if="index !== 'undefined'"
                      class="btn-brand"
                      low
                      small
                      @click="
                        openSummery(identification[0].assignedUserId, index)
                      "
                    >
                      {{ $t('IdentificationDashboard.Open') }}
                    </ui-button>
                  </td>
                </tr>
              </template>
            </dash-board-table>
            <div v-else class="p-4 text-center">
              <h4>
                {{ $t('CompanyProduct.CountryNotFound') }}
              </h4>
            </div>
          </div>
          <div
            v-if="activeTab === IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB"
          >
            <div class="row">
              <div class="col-6">
                <div class="row mb-4 align-items-start no-gutters">
                  <div class="col-6">
                    <ui-input
                      v-model="companyFilterModel"
                      :placeholder="'Id'"
                      :error="companyError"
                      type="text"
                    />
                  </div>
                  <div class="col-3">
                    <ui-dropdown
                      v-model="companyDateModel"
                      :options="dateOptions"
                      class="mx-2 w-100"
                      small
                    />
                  </div>
                  <div class="col-3">
                    <ui-button
                      class="ml-3 btn-brand filter-button"
                      @click="handleUserFilter('company')"
                    >
                      {{ $t('IdentificationDashboard.Filter') }}
                    </ui-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <dash-board-table
                  v-if="Object.keys(identifications.byCompany).length"
                >
                  <template slot="table-header">
                    <th scope="col">
                      {{ $t('IdentificationDashboard.Company') }}
                    </th>
                    <th scope="col">
                      {{ $t('IdentificationDashboard.IdentificationCount') }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ $t('IdentificationDashboard.Summary') }}
                    </th>
                  </template>

                  <template slot="table-body">
                    <tr
                      v-for="(identification,
                      index) in identifications.byCompany"
                      :key="index"
                    >
                      <td>
                        {{ index }}
                      </td>
                      <td>{{ identification.length }}</td>
                      <td class="text-center">
                        <ui-button
                          class="btn-brand"
                          low
                          small
                          @click="
                            openSummery(identification[0].applicationId, index)
                          "
                        >
                          {{ $t('IdentificationDashboard.Open') }}
                        </ui-button>
                      </td>
                    </tr>
                  </template>
                </dash-board-table>
                <div v-else class="p-4 text-center">
                  <h4>
                    {{ $t('CompanyProduct.CountryNotFound') }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ui-card>
    </ui-container>
    <pop-up
      :pop-up-title="`${$t(
        'IdentificationDashboard.IdLogs'
      )} ${userIdentificationsFullName}`"
      no-padding
      full-size
    >
      <user-identifications
        :user-id="userIdentificationsId"
        :user-name="userIdentificationsFullName"
        :type="
          activeTab === IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
            ? IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
            : IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
        "
      />
    </pop-up>
  </div>
</template>

<script>
import {
  IDENTIFICATION_DASHBOARD_TABS,
  IDENTIFICATION_STATUS,
  API
} from '@src/scripts/enums'
import DashBoardTable from '@src/components/pages/identification-dashboard/DashBoardTable'
import IdentificationHeader from '@src/components/pages/identification-dashboard/IdentificationHeader'
import UserIdentifications from '@src/components/pages/identification-dashboard/UserIdentifications'
import Api from '@src/scripts/api'
import { createDateFilters, dateToString } from '@src/scripts/helpers'

const apiSettings = {
  limit: 1000,
  offset: 0
}

export default {
  components: {
    DashBoardTable,
    IdentificationHeader,
    UserIdentifications
  },
  data: () => ({
    IDENTIFICATION_DASHBOARD_TABS,
    isLoading: false,
    userIdentificationsId: null,
    userIdentificationsFullName: null,
    activeSpecialists: null,
    identifications: [],
    activeTab: IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB,
    userFilterModel: '',
    userFilterError: '',
    userDateModel: null,
    dateOptions: [],
    companyFilterModel: '',
    companyDateModel: null,
    companyError: ''
  }),
  created() {
    this.fetchData()
    this.dateOptions = createDateFilters()
    this.userDateModel = this.dateOptions[0].key
    this.companyDateModel = this.dateOptions[0].key
  },
  methods: {
    tabChange(tabId) {
      this.activeTab = tabId !== this.activeTab ? tabId : this.activeTab
    },
    async fetchData() {
      this.isLoading = true
      try {
        this.identifications = await this.getIdentifications()

        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    },
    openSummery(id, fullName) {
      this.userIdentificationsId = id
      this.userIdentificationsFullName = fullName
      this.$openPopUp()
    },
    async getIdentifications() {
      const identifications = await Api.post(
        API.REALTIME_IDENTIFICATIONS(apiSettings.limit, apiSettings.offset),
        {
          statuses: [IDENTIFICATION_STATUS.FINISHED]
        }
      )

      const byCompany = this.groupResults(identifications, 'companyName')
      const byEmail = this.groupResults(identifications, 'assignedUserEmail')

      return { byEmail, byCompany }
    },
    groupResults(results, type) {
      return results.reduce(
        (groups, item) => ({
          ...groups,
          [item[type]]: [...(groups[item[type]] || []), item]
        }),
        {}
      )
    },
    isGuide(stringToTest) {
      return new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
        'i'
      ).test(stringToTest)
    },

    async handleUserFilter(type) {
      const payload = {}

      const filterModel =
        type === 'users' ? this.userFilterModel : this.companyFilterModel
      const dateModel =
        type === 'users' ? this.userDateModel : this.companyDateModel
      let formError = type === 'users' ? this.error : this.companyError

      formError = ''

      if (filterModel !== '') {
        payload.userIds = [filterModel]

        if (this.isGuide(filterModel)) {
          formError = 'InvalidIdFormat'
        }
      }

      if (dateModel.length) {
        payload.from = this.handleHours(dateModel[0], 0)
        payload.to = this.handleHours(dateModel[1], 24)
      }

      payload.status = [IDENTIFICATION_STATUS.FINISHED]

      if (!formError) {
        this.isLoading = true
        try {
          const identifications = await Api.post(
            API.REALTIME_IDENTIFICATIONS(apiSettings.limit, apiSettings.offset),
            payload
          )

          if (type === 'users') {
            this.identifications.byEmail = this.groupResults(
              identifications,
              'assignedUserEmail'
            )
          } else {
            this.identifications.byCompany = this.groupResults(
              identifications,
              'companyName'
            )
          }

          this.isLoading = false
        } catch {
          this.isLoading = false
        }
      }
    },

    handleHours(value, hours) {
      if (value.match(/^\d{4}[-]\d{2}[-]\d{2}$/)) {
        const date = new Date(`${value}T00:00:00`)

        if (hours) {
          date.setHours(hours)
        }
        return dateToString(date, true)
      }
    },
    getUserName(indent) {
      if (indent.assignedUserEmail) {
        return indent.assignedUserEmail
      } else if (indent.assignedUserFullName) {
        return indent.assignedUserFullName
      } else {
        return indent.assignedUserId
      }
    }
  }
}
</script>

<style scoped>
/deep/ .dropdown-toggle {
  width: 100%;
}

.filter-button {
  padding: 0.85rem 2rem;
}

.card-header-tabs-btn {
  padding: 0.846rem;
  font-size: 1.077rem;
  color: var(--gray);
  background: #ebebeb;
  border: 1px solid #c4c4c4;
  text-align: center;
  cursor: pointer;
  min-width: 194px;
}

.card-header-tabs-btn.active {
  background: var(--white);
  border-color: var(--brand);
  color: var(--brand);
}

.reload-btn {
  text-align: right;
  margin-bottom: 1rem;
}

.table-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.462rem;
}

.card-body {
  padding: 1.846rem 1.538rem;
}

/deep/ .m-portlet .m-portlet__body {
  padding: 0;
}
</style>
