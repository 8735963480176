<template>
  <ui-card dark title="Know your country">
    <div class="flex-row justify-space-between">
      <div class="flex-column">
        <p><i class="icon fa fa-user-friends" /></p>
        <h4>Population</h4>
        <h4>2.872 million (2016)</h4>
      </div>
      <div class="flex-column">
        <p><i class="icon fa fa-clipboard-list" /></p>
        <h4>Compliance lists</h4>
        <h5>FATF AML deficient list:</h5>
        <details-rule-item
          :name="deficientList ? 'YES' : 'NO'"
          :status="deficientList"
        />
        <h5>Country related sanctions:</h5>
        <details-rule-item
          :name="sanctions ? 'YES' : 'NO'"
          :status="sanctions"
        />
      </div>
      <div class="flex-column">
        <p><i class="icon fa fa-asterisk" /></p>
        <h4 class="m--font-warning">Medium risk:</h4>
        <p>Non - Compliance with FATF 40 + 9 Recommendations</p>
        <p>Corruption Index (Transparency International & W.G.I.)</p>
        <p>Failed States Index (Political Issues)(Average Score)</p>
        <ui-link :to="{ name: 'AdminCompanyDetails' }" small small-text>
          {{ $t('ShowMore') }}
        </ui-link>
      </div>
    </div>
  </ui-card>
</template>

<script>
import DetailsRuleItem from '@src/components/partials/RuleItem'
export default {
  components: {
    DetailsRuleItem
  },

  data() {
    return {
      sanctions: true,
      deficientList: false
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.15rem;
}

.justify-space-between {
  width: 100%;
  justify-content: space-between;
}

.icon {
  font-size: 3.69rem;
  color: #ccc;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.785rem;
  width: 30%;
}

@media (max-width: 801px) {
  .flex-column {
    width: 100%;
    padding: 1.54rem 0.785rem;
  }
}
</style>
