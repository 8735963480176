<template>
  <div class="m--margin-left-10 icon-bade-block">
    <i class="m--font-success" :class="icon" />
    <span class="icon-bade-block__subtitle">
      {{ $t(header) }}
    </span>
    <span class="icon-bade-block__desc">
      {{ $t(description) }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    header: { type: String, default: undefined },
    description: { type: String, default: undefined },
    icon: { type: String, default: undefined }
  }
}
</script>

<style scoped>
.icon-bade-block i {
  font-size: 3rem;
}
</style>
