<template>
  <div
    class="details-item with-values"
    :class="{ 'inner-page-style': isInner }"
  >
    <span class="details-label">
      {{ $t(label) }}
    </span>
    <div class="details-value">
      {{ $t(value) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined },
    value: { type: String, default: undefined },
    isInner: Boolean
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-break: auto;
}

.details-label {
  padding: 0.85rem 1.15rem;
}

.details-item.with-values {
  width: 100%;
}

.details-item.with-values .details-label {
  max-width: 115px;
  box-sizing: border-box;
}

.details-item.with-values.inner-page-style .details-label {
  max-width: 180px;
}

.details-item.with-values .details-value {
  width: calc(100% - 115px);
  box-sizing: border-box;
  padding-top: 0.85rem;
}

.details-item.with-values.inner-page-style .details-value {
  width: calc(100% - 180px);
}
</style>

<style>
@media (max-width: 568px) {
  .details-item.with-values {
    flex-direction: column;
  }

  .row .details-item.with-values .details-label,
  .row .details-item.with-values .details-value {
    width: 100%;
    margin: 0;
    padding: 0 0.5rem;
  }

  .row .details-item.with-values .details-value {
    font-weight: bold;
    padding-bottom: 0.25rem;
  }
}
</style>
