<template>
  <div
    :class="{
      'has-error': error,
      'ui-touched': istouched,
      'ui-untouched': !istouched
    }"
  >
    <label
      class="flex-row options flex-align"
      :class="{ 'ui-bordered': bordered, 'no-padding': noPadding }"
    >
      <div class="m-radio m-radio--brand m-radio--check-bold">
        <input
          type="radio"
          :name="name"
          :value="value"
          :checked="checked"
          @input="$emit('checkRadio', $event.target.value)"
          @blur="lostFocus"
        />
        <span class="checkmark" />
        {{ label }}
      </div>
      <div class="icon">
        <slot name="icon" />
      </div>
    </label>
    <span class="error-message">
      {{ $t(error) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ui-radio',

  props: {
    label: { type: String, default: undefined },
    value: { type: String, default: undefined },
    error: { type: String, default: undefined },
    name: { type: String, default: undefined },
    bordered: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    checked: { type: Boolean, default: false }
  },

  data() {
    return {
      istouched: false
    }
  },

  methods: {
    lostFocus() {
      if (!this.istouched) {
        this.istouched = true
      }
    }
  }
}
</script>

<style scoped>
.icon img {
  width: auto;
  height: 2.3rem;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icon-bade-block i {
  font-size: 3rem;
}

.flex-align {
  align-items: center;
}

.align-baseline {
  align-content: baseline;
}

.ui-bordered {
  border: 1px solid #ebedf2;
  border-radius: 0.46rem;
}

.options {
  padding: 1.15rem 1.54rem;
}

.no-padding {
  padding: 0;
}

.flex-row .m-radio {
  margin: 0;
}
</style>
