import Store from '@src/store'
import { signOut } from '@src/scripts/identity'

const requestHeaders = () => {
  const { selectedCompany } = Store.getters
  const { session } = Store.state
  const headers = {}

  if (session && session.access_token) {
    headers.Authorization = `Bearer ${session.access_token}`
  }
  if (selectedCompany) {
    headers['x-company-id'] = selectedCompany
  }

  return headers
}

function get(method, headers = {}) {
  const headerResolved = {
    ...headers,
    ...(headers.searchText && {
      searchText: encodeURIComponent(headers.searchText)
    }),
    ...(headers.fullname && {
      fullname: encodeURIComponent(headers.fullname)
    }),
    ...(headers.fullName && {
      fullName: encodeURIComponent(headers.fullName)
    })
  }

  const url = Object.entries(headers)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')

  return fetch(`${fullUrl(method)}${url && '?' + url}`, {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      ...headerResolved
    }
  })
    .then(convertResponse)
    .catch((err) => {
      console.log(err)
    })
}

function post(method, body) {
  return fetch(fullUrl(method), {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function put(method, body) {
  return fetch(fullUrl(method), {
    method: 'PUT',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...requestHeaders()
    },
    body: JSON.stringify(body)
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function patch(method, body) {
  return fetch(fullUrl(method), {
    method: 'PATCH',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function del(method, body) {
  return fetch(fullUrl(method), {
    method: 'DELETE',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function postFormData(method, body) {
  return fetch(fullUrl(method), {
    method: 'POST',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: requestHeaders(),
    body
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function putFormData(method, body) {
  return fetch(fullUrl(method), {
    method: 'PUT',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    headers: requestHeaders(),
    body
  })
    .then(convertResponse)
    .catch(errorHandler)
}

function getNewBlob(method) {
  return fetch(fullUrl(method), {
    method: 'GET',
    credentials: 'include',
    headers: requestHeaders()
  })
    .then(handleBlob)
    .catch(errorHandler)
}

function getBlob(method) {
  return fetch(method, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include'
    // headers: requestHeaders()
  })
    .then(handleBlob)
    .catch(errorHandler)
}

function getBaseUrlBlob(method, headers) {
  return fetch(fullUrl(method), {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      ...headers
    }
  })
    .then(handleBlob)
    .catch(errorHandler)
}

function getBlobWithParams(method, body) {
  return fetch(fullUrl(method), {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...requestHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then((response) => handleBlob(response, true))
    .catch(errorHandler)
}

async function handleVideoStreamResponse(response) {
  if (response.status === 200) {
    const contentDisposition = response.headers.get('content-disposition')
    const result = {}
    const parts = contentDisposition.split(';')
    result.fileName = parts[1].split('=')[1]
    result.file = await response.blob()
    return result
  } else if (response.status === 401) {
    if (Store.state.session.access_token) {
      signOut()
    }
    throw response.status
  } else {
    throw response.status
  }
}

function getVideoStream(method, headers) {
  return fetch(fullUrl(method), {
    method: 'GET',
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'include',
    responseType: 'blob',
    headers: {
      ...requestHeaders(),
      ...headers
    }
  })
    .then(handleVideoStreamResponse)
    .catch(errorHandler)
}

function convertResponse(response) {
  if (response.status === 200 || response.status === 201) {
    const contentType = response.headers.get('content-type')
    if (!contentType) {
      return {}
    }
    return response.json()
  } else if (response.status === 204) {
    return null
  } else if (response.status === 401) {
    if (Store.state.session.access_token) {
      signOut()
    }
    throw response.status
  } else {
    throw response.status
  }
}

async function handleBlob(response, getName) {
  if (response.status === 200) {
    if (getName) {
      // const contentDisposition = response.headers.get('content-disposition')
      // console.log('response.headers', response.headers)
      // console.warn('contentDisposition', contentDisposition)
      // console.log(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i.test(contentDisposition))
      // console.log(/filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i.test(contentDisposition))
      // console.warn('//')
      const result = {}
      result.file = await response.blob()
      result.fileName = 'report.csv'
      return result
    } else {
      return response.blob()
    }
  } else {
    throw response.status
  }
}

// function sendBeacon(method, body) {
//   const headers = {
//     ...requestHeaders(),
//     // 'Content-Type': 'application/json'
//   }

//   delete headers.Authorization
//   delete headers['x-company-id']

//   fetch(fullUrl(method), {
//     method: 'POST',
//     // cache: 'no-cache',
//     mode: 'cors',
//     // credentials: 'include',
//     keepalive: true,
//     headers
//     // body: JSON.stringify(body)
//   })

//   // let headers = {
//   //   mode: 'cors',
//   //   type: 'application/json'
//   // }
//   // const blob = new Blob([JSON.stringify(body)], headers)

//   // navigator.sendBeacon(fullUrl(method), blob)
// }

// function postSync(method, body) {
//   return fetch(fullUrl(method), {
//     method: 'POST',
//     cache: 'no-cache',
//     mode: 'cors',
//     credentials: 'include',
//     headers: {
//       ...requestHeaders(),
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(body)
//   }).then(convertResponse)
//     .catch(errorHandler)
// }

function errorHandler(response) {
  throw response
}

function fullUrl(path) {
  return `${BaseUrl.api}${path}`
}

export default {
  get,
  post,
  put,
  del,
  patch,
  postFormData,
  putFormData,
  getNewBlob,
  getBlob,
  getBlobWithParams,
  getBaseUrlBlob,
  getVideoStream
  // sendBeacon,
  // postSync
}
