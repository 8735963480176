var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stoplist-page" },
    [
      _c(
        "ui-header",
        {
          class: { "fixed-header": _vm.scrollDown },
          attrs: {
            title: "Stoplist.Title",
            method: _vm.searchMethod,
            "method-get": true,
            filters: _vm.filters,
            disabled: _vm.isLoading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("div", { staticClass: "filters-block" }, [
            _c(
              "div",
              { staticClass: "all-search-inputs" },
              [
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "TableFirstName",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.firstName,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "firstName", $$v)
                    },
                    expression: "filters.firstName",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "TableLastName",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.lastName,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "lastName", $$v)
                    },
                    expression: "filters.lastName",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "email",
                    placeholder: "DetailsPersonCode",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.personalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "personalCode", $$v)
                    },
                    expression: "filters.personalCode",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  staticClass: "countries-dropdown",
                  attrs: {
                    all: "",
                    "has-search": "",
                    "key-value-dropdown": "",
                    options: _vm.countriesOptions,
                    medium: "",
                    placeholder: "DetailsCountry",
                  },
                  model: {
                    value: _vm.filters.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "country", $$v)
                    },
                    expression: "filters.country",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { title: "AppSearchResults", count: _vm.itemsCount } },
            [
              !_vm.isLoading && _vm.stoplists
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "export-table hide-mobile",
                        attrs: {
                          labels: _vm.tableLabels,
                          data: _vm.stoplists,
                          "empty-text": "TableNoStoplists",
                        },
                      },
                      [
                        _vm._l(_vm.stoplists, function (row, index) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              staticClass: "m-table__row",
                              class: _vm.classList(row),
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: _vm.getFullName(row) },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.getFullName(row)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.createdUtc },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getFormattedDate(row.createdUtc)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.reportUserEmail },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.reportUserEmail) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "td-link" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-metal big-link",
                                      attrs: {
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickRemove(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("Stoplist.Remove")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId:
                                              row.referenceId &&
                                              row.referenceId.replaceAll(
                                                "-",
                                                ""
                                              ),
                                          },
                                        },
                                        "small-text": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("TableView")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.stoplists && _vm.stoplists.length > 0
                      ? _vm._l(_vm.stoplists, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                              class: _vm.classList(row),
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableFullName",
                                  value: _vm.getFullName(row),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableDateAdded",
                                  value: _vm.getFormattedDate(row.createdUtc),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableAddedBy",
                                  value: row.reportUserEmail,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "m--margin-top-10 remove-button-container",
                                },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-metal big-link",
                                      attrs: {
                                        "small-text": "",
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickRemove(row.id)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("Stoplist.Remove")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ui-link",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: {
                                        to: {
                                          name: "IdentificationDetails",
                                          params: {
                                            identificationId:
                                              row.referenceId &&
                                              row.referenceId.replaceAll(
                                                "-",
                                                ""
                                              ),
                                          },
                                        },
                                        "small-text": "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("TableView")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("TableNoIdentifications")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _c("ui-loader", { attrs: { size: 4, center: "" } }),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.filters.page,
                  "items-count": _vm.itemsCount,
                  "max-on-page": _vm.filters.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }