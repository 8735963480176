<template>
  <div class="details-block">
    <div>{{ $t(label) }}:</div>
    <div class="orange-small-text">
      {{ value }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined }
  }
}
</script>

<style scoped>
.orange-small-text {
  color: var(--brand);
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  padding: 0.5rem 1rem;
  min-height: 4rem;
  white-space: nowrap;
}

.details-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.dashboard-page .m-portlet__body > .details-block:not(:last-child) {
  border-bottom: 1px solid #ebedf2;
}
</style>
