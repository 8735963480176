import { render, staticRenderFns } from "./EditHeader.vue?vue&type=template&id=53ed2716&scoped=true"
import script from "./EditHeader.vue?vue&type=script&lang=js"
export * from "./EditHeader.vue?vue&type=script&lang=js"
import style0 from "./EditHeader.vue?vue&type=style&index=0&id=53ed2716&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed2716",
  null
  
)

export default component.exports