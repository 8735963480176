<template>
  <ui-card class="flex-row toggle-settings-box">
    <div>
      <h5>{{ title }}</h5>
      <p>{{ description }}</p>
    </div>
    <ui-toggle :value="value" :disabled="disabled" @change="changeToggle" />
  </ui-card>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    value: Boolean,
    disabled: Boolean
  },

  methods: {
    changeToggle(value) {
      this.$emit('changeToggle', value)
    }
  }
}
</script>

<style>
@media (max-width: 500px) {
  .flex-row .toggle-settings-box .m-portlet__body {
    flex-direction: column;
    align-items: center;
  }
}
</style>
