<template>
  <div class="m-subheader-search" @keydown.enter="search">
    <h2 class="m-subheader-search__title">
      {{ $t(title) }}
    </h2>
    <p v-if="description" class="color-white">
      {{ $t(description) }}
    </p>
    <ui-toggle
      v-if="autoRefresh"
      isheader
      :value="refreshSettings.isAuto"
      :disabled="disabledToggle"
      label="AutoRefresh"
      @change="autoRefreshChanged"
    />
    <ui-form v-if="hasSearhContent" class="m-form" @submit="search">
      <div class="m-grid m-grid--ver-desktop m-grid--desktop header-content">
        <div class="m-grid__item filters-list">
          <slot />
        </div>
        <div class="m-grid__item">
          <ui-button
            type="submit"
            class="m-subheader-search__submit-btn"
            :disabled="disabled"
          >
            {{ $t('AppSearch') }}
          </ui-button>
        </div>

        <div class="m-grid__item ml-auto">
          <slot name="right-side" />
        </div>
      </div>
    </ui-form>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import actions from '@src/store/actions'
import getters from '@src/store/getters'
import { dateToString } from '@src/scripts/helpers'
import { mapGetters } from 'vuex'

export default {
  name: 'ui-header',

  props: {
    resetForm: Boolean,
    title: { type: String, default: undefined },
    method: { type: String, default: undefined },
    filters: { type: Object, default: undefined },
    description: { type: String, default: undefined },
    requiredFilters: { type: Array, default: undefined },
    refreshInterval: { type: Number, default: undefined },
    disabled: Boolean,
    autoRefresh: Boolean,
    disableSearchOnLoad: Boolean,
    hasBody: Boolean,
    companyChange: Boolean,
    methodGet: { type: Boolean, required: false },
    defaultPayload: { type: Boolean, default: true },
    noExtraPayload: { type: Boolean, required: false },
    updateRevision: Boolean,
    dateKeepFormat: { type: Boolean, default: false }
  },

  data() {
    return {
      isReady: false,
      isUpdating: false,
      isLoading: false,
      disabledToggle: false,
      refreshSettings: {
        isAuto: false,
        interval: 300000,
        timer: null
      }
    }
  },

  computed: {
    ...mapGetters([getters.selectedCompany, getters.searchHistory]),

    pageName() {
      return this.$route.name
    },

    hasSearhContent() {
      return !!(this.$slots.default || this.hasBody)
    },

    searchFilters() {
      const { searchHistory, pageName } = this
      return searchHistory(pageName)
    },

    mappedFilters() {
      const { hasSearhContent, filters, searchFilters } = this
      return (hasSearhContent && searchFilters) || filters
    },

    latestDate() {
      const now = new Date()
      return dateToString(now)
    },

    isDateLatest() {
      const { filters, latestDate } = this
      if (!filters?.date?.length) return false

      if (filters.date[1] === '0') return true

      if (filters.date[0] !== latestDate || filters.date[1] !== latestDate) {
        return false
      }

      return true
    }
  },

  watch: {
    selectedCompany() {
      if (this.companyChange) this.loadData()
      else this.resetPage()
    },

    'filters.page'() {
      if (!this.isUpdating) {
        this.prepareSearch()
      }
      this.isUpdating = false
    },

    autoRefresh(value) {
      const { isDateLatest } = this

      if (value && isDateLatest) {
        this.disabledToggle = false
        this.enableRefresh()
      } else {
        this.disabledToggle = true
        this.disableRefresh()
      }
    },

    isDateLatest(value) {
      if (value) {
        this.disabledToggle = false
        this.enableRefresh()
      } else {
        this.disabledToggle = true
        this.disableRefresh()
      }
    },

    updateRevision(newValue) {
      if (newValue) this.loadData()
    }
  },

  mounted() {
    const { refreshSettings, refreshInterval } = this
    if (refreshInterval) {
      refreshSettings.interval = refreshInterval
    }

    const isFilterschanged = this.setFilters()
    if (this.disableSearchOnLoad && !isFilterschanged) return
    this.loadData()

    if (this.autoRefresh && this.isDateLatest) {
      this.enableRefresh()
    } else {
      this.disabledToggle = true
    }
  },

  created() {
    if (this.disableSearchOnLoad || this.resetForm) {
      this.resetFormData()
    }
  },

  beforeDestroy() {
    this.disableRefresh()
  },

  methods: {
    enableRefresh() {
      this.$emit('toggleRefresh', true)
      const { refreshSettings, loadData } = this
      if (!refreshSettings.isAuto) {
        refreshSettings.isAuto = true
        refreshSettings.timer = setInterval(loadData, refreshSettings.interval)
      }
    },

    disableRefresh() {
      this.$emit('toggleRefresh', false)
      const { refreshSettings } = this
      if (refreshSettings.isAuto) {
        refreshSettings.isAuto = false
        clearInterval(refreshSettings.timer)
        refreshSettings.timer = null
      }
    },

    autoRefreshChanged(value) {
      const { enableRefresh, disableRefresh } = this
      if (value) enableRefresh()
      else disableRefresh()
    },

    setFilters() {
      const { searchFilters, filters, requiredFilters } = this
      if (searchFilters && filters) {
        const err = []
        for (const item in searchFilters) {
          filters[item] = searchFilters[item]
        }
        for (const item in searchFilters) {
          if (searchFilters[item] !== filters[item]) err.push('error')
        }
        if (
          err.length === 0 &&
          this.pageName &&
          this.$store.getters[this.pageName]
        ) {
          this.isUpdating = true
          const result = this.$store.getters[this.pageName]
          this.$emit('finishSearch', result || {})
          return false
        }
      }
      const array = []
      if (requiredFilters) {
        requiredFilters.forEach((item) => {
          if (!filters[item] || filters[item].length < 1) array.push('error')
        })
      }
      if (requiredFilters && array.length <= 0) return true
      else return false
    },

    search() {
      window.scrollTo(0, 0)
      this.resetPage()
      this.prepareSearch()
    },

    prepareSearch() {
      const { pageName, filters, loadData } = this
      this.$store.dispatch(actions.updateHistory, {
        key: pageName,
        value: filters
      })
      loadData()
    },

    resetFormData() {
      const { pageName, filters } = this
      this.$store.dispatch(actions.updateHistory, {
        key: pageName,
        value: filters
      })
    },

    resetPage() {
      this.isUpdating = true
      this.$set(this.filters, 'page', undefined)
      this.$nextTick(() => {
        this.isUpdating = false
      })
    },

    createPayload(filters, companyId) {
      const userEnabled =
        filters.status === 'Active'
          ? true
          : filters.status === 'Disabled'
          ? false
          : undefined
      const emailConfirmed =
        filters.emailStatus === 'Confirmed'
          ? true
          : filters.emailStatus === 'NotConfirmed'
          ? false
          : undefined

      const tempPayload = this.formatPayload(filters)

      if (!this.defaultPayload) {
        return {
          ...tempPayload,
          companyId
        }
      }

      return {
        ...tempPayload,
        companyId,
        userEnabled,
        emailConfirmed
      }
    },

    formatPayload(filters) {
      const tempPayload = {}
      for (const key in filters) {
        if (filters[key] === null || filters[key] === undefined) continue
        if (typeof filters[key] === 'string') {
          const value = filters[key].trim()
          if (value) {
            tempPayload[key] = value
          }
        } else if (key === 'date') {
          const date = this.mappedDate(filters.date)
          tempPayload.from = date.from
          tempPayload.to = date.to
        } else {
          tempPayload[key] = filters[key]
        }
      }
      return tempPayload
    },

    mappedDate(date) {
      if (!date) return

      const from = this.handleHours(date[0], 0)
      const to = this.handleHours(date[1], 24)

      return { from, to }
    },

    handleHours(value, hours) {
      if (value.match(/^\d{4}[-]\d{2}[-]\d{2}$/)) {
        const date = new Date(`${value}T00:00:00`)

        if (hours) {
          date.setHours(hours)
        }
        if (this.dateKeepFormat) {
          return dateToString(date, true)
        } else {
          return date.toISOString()
        }
      } else {
        const difference = parseInt(value)
        const now = new Date()

        if (difference) {
          now.setHours(now.getHours() + difference)
        }

        return now.toISOString()
      }
    },

    validRequiredFields(filters) {
      const { requiredFilters } = this
      if (!filters || !requiredFilters || !requiredFilters.length) return true

      for (const required of requiredFilters) {
        const value = filters[required]
        if (!value) {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorFillAllRequiredFieldsDescription'
          })
          return false
        }
      }
      return true
    },

    async loadData() {
      const { mappedFilters, validRequiredFields } = this
      if (this.isLoading || !validRequiredFields(mappedFilters)) return
      this.isLoading = true

      const { method, selectedCompany, getData, createPayload } = this
      const payload = createPayload(mappedFilters, selectedCompany)
      this.$emit('startSearch')
      const { userEnabled, emailConfirmed, ...restPayload } = payload
      const payloadResolved = this.noExtraPayload ? restPayload : payload
      const result = await getData(method, payloadResolved)
      this.$emit('finishSearch', result || {})

      this.isLoading = false
    },

    async getData(method, payload) {
      try {
        delete payload.allCompanies

        if (this.methodGet) {
          return await Api.get(method, payload)
        }

        return await Api.post(method, payload)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
        this.disabledToggle = true
        this.disableRefresh()
        return null
      }
    }
  }
}
</script>

<style scoped>
.filters-list {
  display: flex;
  flex-wrap: wrap;
}

.header-content {
  flex-wrap: wrap;
}

.color-white {
  color: #fff;
  margin-top: 1.5rem;
}

@media (max-width: 678px) {
  .filters-list {
    flex-direction: column;
  }
}
</style>

<style>
.m-subheader-search .m-checkbox.m-checkbox--state-brand > span::after {
  border: solid #fff;
}

@media (max-width: 678px) {
  .m-form .header-content .filters-list .m-input-icon {
    width: 100%;
  }
}
</style>
