<template>
  <div class="capture-box" :class="{ 'has-image': value }">
    <div class="image-box" :style="image">
      <ui-button
        v-if="!value"
        class="btn-brand"
        :disabled="disabled"
        @click="capturePhoto"
      >
        {{ $t(label) }}
      </ui-button>
      <ui-button v-else class="btn-outline-brand" @click="resetPhoto">
        {{ $t('CallRecapture') }}
      </ui-button>
      <div v-show="value && !disabled" class="mark mark-success" />
      <ui-loader v-show="disabled" center />
    </div>
  </div>
</template>

<script>
import { snapPhoto } from '@src/scripts/helpers'

export default {
  props: {
    label: { type: String, default: undefined },
    selector: { type: String, default: undefined },
    value: { type: String, default: undefined },
    error: { type: String, default: undefined },
    disabled: Boolean
  },

  computed: {
    image() {
      return { backgroundImage: `url(${this.value})` }
    }
  },

  methods: {
    capturePhoto() {
      const video = document.querySelector(this.selector)
      const snap = snapPhoto(video)
      if (!snap) return
      this.$emit('change', snap)
    },

    resetPhoto() {
      this.$emit('change', null)
    }
  }
}
</script>

<style scoped>
.capture-box,
.image-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.capture-box {
  width: 100%;
}

.image-box {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.ui-button {
  margin: auto auto 2rem;
}

.has-image .image-box {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 0 1rem var(--shadow);
}

.mark {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  top: 2rem;
  right: 4rem;
  position: absolute;
}

.image-box .ui-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mark.mark-success {
  background: var(--success);
}

.mark.mark-success::before {
  content: '';
  height: 1.5rem;
  border-right: 3px solid var(--white);
  position: absolute;
  width: 0.75rem;
  border-bottom: 3px solid var(--white);
  top: 1rem;
  left: 1.75rem;
  transform: rotate(45deg);
}
</style>
