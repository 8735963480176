<template>
  <page-frame>
    <list-header slot="header" :title="$t('Kyb.Search.Title')">
      <search />
    </list-header>
    <identifications />
  </page-frame>
</template>

<script>
import PageFrame from '@src/components/partials/refactor/layouts/PageFrame'
import ListHeader from '@src/components/partials/refactor/layouts/ListHeader'
import Search from '@src/components/partials/refactor/partials/kyb/Search'
import Identifications from '@src/components/partials/refactor/partials/kyb/Identifications'

export default {
  components: {
    PageFrame,
    ListHeader,
    Search,
    Identifications
  }
}
</script>
