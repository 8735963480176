var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hidden-details" }, [
    _c("span", { staticClass: "show-button", on: { mouseover: _vm.onHover } }, [
      _vm._v("\n    " + _vm._s(_vm.$t("HiddenDetails.ShowButton")) + "\n    "),
      _c("i", { staticClass: "far fa-eye" }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-box", style: _vm.position }, [
      _c("div", { staticClass: "message-box" }, [
        _vm._v("\n      " + _vm._s(_vm.value) + "\n      "),
        _c("div", { staticClass: "message-arrow" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }