var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comment-input" },
    [
      _c("ui-input", {
        attrs: {
          multiline: "",
          "multiline-overflow": "",
          type: "text",
          "max-length": _vm.maxLength,
          disabled: _vm.loading,
          rows: 5,
          placeholder: _vm.$t("VideoCallComments.Placeholder"),
        },
        model: {
          value: _vm.comment,
          callback: function ($$v) {
            _vm.comment = $$v
          },
          expression: "comment",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "comment-input-footer" }, [
        _c("div", [
          _vm._v(
            _vm._s(
              _vm.$t("VideoCallComments.CharLeft", { left: _vm.leftChars })
            )
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "ui-button",
              {
                staticClass: "btn-brand submit-btn",
                attrs: { disabled: _vm.isValid || _vm.loading },
                on: { click: _vm.handleSubmit },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("VideoCallComments.Save")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }