var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "m-portlet m-portlet--responsive-mobile",
      class: {
        "m-portlet--head-lg": _vm.large,
        "m-portlet--brandalt m-portlet--head-solid-bg": _vm.dark,
      },
    },
    [
      _vm.title
        ? _c("div", { staticClass: "m-portlet__head slider__head" }, [
            _c(
              "button",
              {
                staticClass:
                  "btn-expand btn-expand-expanded ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm btn-left",
                on: { click: _vm.preSlide },
              },
              [_c("i", { staticClass: "fas fa-caret-left" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "btn-expand ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm btn-right",
                on: { click: _vm.nextSlide },
              },
              [_c("i", { staticClass: "fas fa-caret-right" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "m-portlet__head-wrapper header-tools" }, [
              _c("div", { staticClass: "m-portlet__head-caption" }, [
                _vm.title
                  ? _c("div", { staticClass: "m-portlet__head-title" }, [
                      _c("div", { staticClass: "text-center" }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(_vm.sliderTitle) +
                              " " +
                              _vm._s(_vm.currentSlide) +
                              "/" +
                              _vm._s(_vm.getSlideCount)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("h3", { staticClass: "m-portlet__head-text" }, [
                          _vm.hasAutoCapture
                            ? _c("div", { staticClass: "header-container" }, [
                                _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "m-badge m-badge--wide status-value m-badge--brand",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.$t("AppAutoCapture")) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ])
                            : _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      [
        _c(
          "div",
          { staticClass: "m-portlet__body" },
          [_vm._t("default", null, { currentSlide: _vm.currentSlide })],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }