import { uboLoadMoreList } from '@src/scripts/enums'
import ISOCountryCodes from './../assets/iso3.json'
import JSZip from 'jszip'

// import locale from 'element-ui'

export function toPascalCase(key) {
  return key.charAt(0).toUpperCase() + key.substring(1)
}

export function uniqueKey() {
  return Math.random().toString(36).substr(2, 5)
}

export function isArrayEquals(first, second) {
  first = first || []
  second = second || []
  if (first.length !== second.length) return false
  for (const index in first) {
    if (first[index] !== second[index]) return false
  }
  return true
}

export function blobToDataUrl(blob) {
  if (!blob) return null
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

export function createDateFilters(options) {
  options = options || {}
  const dateOptions = []
  const now = new Date()

  addTodayOption(dateOptions, now)
  addYesterdayOption(dateOptions, now)
  add7DaysOption(dateOptions, now)
  addThis30DaysOption(dateOptions, now)
  addThisMonthOption(dateOptions, now)
  addLastMonthOption(dateOptions, now)

  if (options.lastHour) {
    dateOptions.unshift({ key: ['-1', '0'], value: 'HeaderDateLastHour' })
  }

  dateOptions.push({ type: 'date', value: 'HeaderDateCustom' })
  return dateOptions
}

export function dateToString(value, withTime) {
  if (!value) return null
  const date = value instanceof Date ? value : new Date(value + 'Z')
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = (month > 9 && month) || '0' + month
  day = (day > 9 && day) || '0' + day
  if (withTime) {
    let hour = date.getHours()
    let minutes = date.getMinutes()
    hour = (hour > 9 && hour) || '0' + hour
    minutes = (minutes > 9 && minutes) || '0' + minutes
    return `${year}-${month}-${day} ${hour}:${minutes}`
  }
  return `${year}-${month}-${day}`
}

export function dateWithoutTime(value) {
  if (!value) return null
  return value.split('T')[0]
}

export function dateToStringGet(value, withTime) {
  if (!value) return null
  const date = value instanceof Date ? value : new Date(value + 'Z')
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = (month > 9 && month) || '0' + month
  day = (day > 9 && day) || '0' + day
  if (withTime) {
    let hour = date.getHours()
    let minutes = date.getMinutes()
    hour = (hour > 9 && hour) || '0' + hour
    minutes = (minutes > 9 && minutes) || '0' + minutes
    return `${year}-${month}-${day} ${hour}:${minutes}`
  }
  return `${year}/${month}/${day}`
}

export function replaceAll(target, search, replacement) {
  return target.replace(new RegExp(escapeRegExp(search), 'g'), replacement)
}

export function parseBase64Object(request) {
  if (!request) return
  try {
    return JSON.parse(atob(request))
  } catch (error) {
    return null
  }
}

export function setDefaults(filters, defaults) {
  if (!filters || !defaults) return
  for (const key in defaults) {
    filters[key] = defaults[key]
  }
}

export function mapOptions(list, values) {
  if (values) {
    return (
      list &&
      list.map((item, index) => {
        return {
          key: item,
          value: values[index]
        }
      })
    )
  } else {
    return (
      list &&
      list.map((item) => {
        return {
          key: item,
          value: item
        }
      })
    )
  }
}

export function dataUrlToBlob(data) {
  if (!data) return null

  const dataContent = data.split(',')
  const contentType = dataContent[0].match(/:(.*?);/)[1]
  const b64Data = dataContent[1]
  const sliceSize = 512

  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export function base64ToPdfDownload(data, fileName) {
  const binary = atob(data.replace(/\s/g, ''))
  const len = binary.length
  const buffer = new ArrayBuffer(len)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i)
  }
  const blob = new Blob([view], { type: 'application/pdf' })

  const windowUrl = window.URL || window.webkitURL

  const fileUrl = windowUrl.createObjectURL(blob)
  downloadUrl({ fileUrl, fileName })
}

export function snapPhoto(remoteVideo) {
  if (!remoteVideo) return null

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const snap = {
    width: remoteVideo.videoWidth,
    height: remoteVideo.videoHeight
  }

  canvas.width = snap.width
  canvas.height = snap.height

  context.drawImage(remoteVideo, 0, 0, snap.width, snap.height)
  return canvas.toDataURL('image/jpeg')
}

export function downloadBlob({ file, fileName }) {
  if (file && fileName) {
    const windowUrl = window.URL || window.webkitURL
    const fileUrl = windowUrl.createObjectURL(file)
    downloadUrl({ fileUrl, fileName })
  }
}

export async function downloadBlobs(files, createFileName, zipFileName) {
  if (files.length > 1) {
    const zip = new JSZip()
    files.forEach((file, index) => {
      zip.file(createFileName(index), file)
    })

    const zipContent = await zip.generateAsync({ type: 'blob' })

    downloadBlob({
      file: zipContent,
      fileName: zipFileName
    })

    return
  }

  return downloadBlob({ file: files[0], fileName: createFileName() })
}

export function downloadUrl({ fileUrl, fileName }) {
  if (!fileUrl) return

  const save = document.createElement('a')
  save.href = fileUrl
  save.target = '_blank'
  save.download = fileName

  const evt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false
  })
  save.dispatchEvent(evt)

  const windowUrl = window.URL || window.webkitURL
  windowUrl.revokeObjectURL(save.href)
}

export function rotateImage(url) {
  return new Promise((resolve) => {
    const image = new Image()
    image.src = url
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.width = image.height
      canvas.height = image.width

      context.rotate((90 * Math.PI) / 180)
      context.drawImage(image, 0, -image.height, image.width, image.height)

      resolve(canvas.toDataURL('image/jpeg'))
    }
  })
}

export function compressImage(url) {
  return new Promise((resolve) => {
    const image = new Image()
    image.src = url
    image.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.width = image.width
      canvas.height = image.height

      context.drawImage(image, 0, 0, image.width, image.height)
      resolve(canvas.toDataURL('image/jpeg', 0.5))
    }
  })
}

export function dateFilter(value) {
  if (!value) return value

  value = value.endsWith('Z') ? value : `${value}Z`
  const date = new Date(value)

  return date.toLocaleDateString('lt-LT', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
}

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1')
}

function addTodayOption(dateOptions, now) {
  const date = dateToString(now)
  dateOptions.push({ value: 'HeaderDateToday', key: [date, date] })
}

function addYesterdayOption(dateOptions, now) {
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
  const dateValue = dateToString(date)
  dateOptions.push({
    value: 'HeaderDateYesterday',
    key: [dateValue, dateValue]
  })
}

function add7DaysOption(dateOptions, now) {
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6)
  const firstDate = dateToString(date)
  const secondDate = dateToString(now)
  dateOptions.push({ value: 'HeaderDate7Days', key: [firstDate, secondDate] })
}

function addThis30DaysOption(dateOptions, now) {
  const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 29)
  const firstDate = dateToString(date)
  const secondDate = dateToString(now)
  dateOptions.push({ value: 'HeaderDate30Days', key: [firstDate, secondDate] })
}

function addThisMonthOption(dateOptions, now) {
  const date = new Date(now.getFullYear(), now.getMonth(), 1)
  const firstDate = dateToString(date)
  const secondDate = dateToString(now)
  dateOptions.push({
    value: 'HeaderDateThisMonth',
    key: [firstDate, secondDate]
  })
}

function addLastMonthOption(dateOptions, now) {
  let date = new Date(now.getFullYear(), now.getMonth() - 1, 1)
  const firstDate = dateToString(date)
  date = new Date(now.getFullYear(), now.getMonth(), 0)
  const secondDate = dateToString(date)
  dateOptions.push({
    value: 'HeaderDateLastMonth',
    key: [firstDate, secondDate]
  })
}

export function deepClone(object) {
  if (!object) return null

  return JSON.parse(JSON.stringify(object))
}

export function sleep(millis) {
  return new Promise((resolve) => setTimeout(resolve, millis))
}

export function getOwnersMapped({
  beneficialOwners = [],
  companyName,
  companyCode
}) {
  return beneficialOwners.map(
    ({
      firstName,
      lastName,
      code,
      relationshipType,
      dateRegisteredFrom,
      countryCode,
      countryName,
      ownershipPercent
    }) => ({
      companyName,
      companyCode,
      firstName,
      lastName,
      code,
      ...(relationshipType && { relTypeEn: relationshipType }),
      dateRegisteredFrom,
      countryCode,
      countryName,
      ownershipPercent
    })
  )
}

export function getCountryCodeResolved(countryCode) {
  return countryCode === uboLoadMoreList.LAT ? uboLoadMoreList.LVA : countryCode
}

export const getFirstLevelOwnersMapped = (data) => ({
  ...data,
  levels: data.levels.map((level) => ({
    ...level,
    owners: level.owners.map(({ relTypeLt, countryNameLt, ...rest }) => ({
      ...rest
    }))
  }))
})

export function getCountryISOCode(code) {
  const iso2Codes = { ...ISOCountryCodes }
  const iso3Codes = Object.entries(ISOCountryCodes).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: key
    }),
    {}
  )
  return iso2Codes[code] || iso3Codes[code]
}

export function createCompanyReportDateOptions() {
  const dateOptions = []
  const now = new Date()

  addTodayOption(dateOptions, now)
  dateOptions.push({ type: 'date', value: 'HeaderDateCustom' })

  return dateOptions
}

export function createMonitoringSetupDateOptions() {
  const dateOptions = []
  const now = new Date()

  addTodayOption(dateOptions, now)
  dateOptions.push({ type: 'date', value: 'HeaderDateCustom' })

  return dateOptions
}

export function createMonitoringEventsDateOptions(options) {
  options = options || {}
  const dateOptions = []
  const now = new Date()

  addYesterdayOption(dateOptions, now)
  add7DaysOption(dateOptions, now)
  addThis30DaysOption(dateOptions, now)
  addThisMonthOption(dateOptions, now)
  addLastMonthOption(dateOptions, now)

  if (options.lastHour) {
    dateOptions.unshift({ key: ['-1', '0'], value: 'HeaderDateLastHour' })
  }

  dateOptions.push({ type: 'date', value: 'HeaderDateCustom' })
  return dateOptions
}
