<template>
  <div class="response-table">
    <table class="table">
      <thead :class="headerType">
        <slot name="table-header" />
      </thead>
      <tbody>
        <slot name="table-body" />
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    headerType: { type: String, default: 'thead-light' }
  }
}
</script>

<style scoped>
.response-table {
  max-height: 40rem;
  overflow: auto;
}
</style>
