<template>
  <tr class="m-table__row">
    <td class="fixed-width id-column td" :title="item.id">
      {{ item.orderId }}
    </td>
    <td class="fixed-width td" :title="item.addedByEmail">
      {{ item.addedByEmail ? item.addedByEmail : '-' }}
    </td>
    <td class="fixed-width td" :title="item.countryCode">
      {{ item.countryCode ? $t(`Country.${item.countryCode}`) : '-' }}
    </td>
    <td class="fixed-width td" :title="item.startDate">
      {{ dateToString(item.createdAt, true) }}
    </td>
    <td class="fixed-width td" :title="item.deliveryTerm">
      {{ item.deliveryTerm ? mapDeliveryDate(item.deliveryTerm) : '-' }}
    </td>
    <td class="fixed-width td" :title="item.companyName">
      {{ item.companyName ? item.companyName : '-' }}
    </td>
    <td class="fixed-width td" :title="item.status">
      <ui-button
        v-if="item.status === 'Finished' || item.status === 'Updated'"
        small
        :disabled="isLoading"
        class="btn btn-brand"
        @click="handleDownload(item.orderId)"
      >
        {{ $t('CompanyReportTable.Status.Download') }}
      </ui-button>
      <template v-else>
        <span :class="{ 'error-class': hasErrorStatus(item.status) }">
          {{ handleStatus(item.status) }}
        </span>
      </template>
    </td>
  </tr>
</template>

<script>
import { dateToString, downloadBlob } from '@src/scripts/helpers'
import Api from '@src/scripts/api'

export default {
  props: { item: { type: Object, default: undefined } },
  data: () => ({ isLoading: false }),
  methods: {
    dateToString,
    hasErrorStatus(status) {
      return (
        status === 'Canceled' || status === 'Error' || status === 'Rejected'
      )
    },
    async handleDownload(id) {
      this.isLoading = true
      try {
        const blob = await Api.getNewBlob(
          `/company-report-orders/${id}/file?language=En&Type=pdf`
        )

        downloadBlob({
          file: blob,
          fileName: `${id}.${blob.type.split('/')[1]}`
        })

        this.isLoading = false
      } catch {
        this.isLoading = false
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    mapDeliveryDate(status) {
      switch (status) {
        case 'Normal':
          return this.$t('CompanyReportTable.CompanyReportDelivery.Normal')
        case 'Fast':
          return this.$t('CompanyReportTable.CompanyReportDelivery.Fast')
        case 'Fastest':
          return this.$t('CompanyReportTable.CompanyReportDelivery.Fastest')
        default:
          console.log(`Unknown status ${status}`)
          return status
      }
    },
    handleStatus(status) {
      switch (status) {
        case 'Pending':
          return this.$t('CompanyReportTable.Status.Pending')
        case 'InProgress':
          return this.$t('CompanyReportTable.Status.InProgress')
        case 'Canceled':
          return this.$t('CompanyReportTable.Status.Canceled')
        case 'Finished':
          return this.$t('CompanyReportTable.Status.Finished')
        case 'Updated':
          return this.$t('CompanyReportTable.Status.Updated')
        case 'Error':
          return this.$t('CompanyReportTable.Status.Error')
        case 'Rejected':
          return this.$t('CompanyReportTable.Status.Rejected')
        default:
          console.log(`Unknown status ${status}`)
          return status
      }
    }
  }
}
</script>

<style scoped>
.error-class {
  color: #f00;
}

.id-column {
  max-width: 123px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.td {
  font-size: 11px;
}
</style>
