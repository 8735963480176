var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        {
          staticClass: "results-block identification-photo",
          class: { "image-local": _vm.local },
        },
        [
          _c(
            "div",
            {
              staticClass: "image-box",
              style: _vm.imageStyle,
              on: { mousemove: _vm.zoomPosition, touchmove: _vm.zoomPosition },
            },
            [_c("div", { staticClass: "large-image", style: _vm.zoomStyle })]
          ),
          _vm._v(" "),
          !_vm.local
            ? _c("div", { staticClass: "result-item" }, [
                _vm.data.fileExtension
                  ? _c("span", { staticClass: "image-type" }, [
                      _vm._v(
                        "\n      ." + _vm._s(_vm.data.fileExtension) + "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.data.created
                  ? _c("span", { staticClass: "result-time" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm._f("date")(_vm.data.created)) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.local
            ? _c(
                "span",
                { staticClass: "buttons" },
                [
                  _c("ui-button", {
                    staticClass: "btn-metal",
                    attrs: { icon: "la la-rotate-right", small: "", low: "" },
                    on: { click: _vm.rotateImage },
                  }),
                  _vm._v(" "),
                  _c("ui-button", {
                    staticClass: "btn-metal",
                    attrs: { icon: "la la-search-plus", small: "", low: "" },
                    on: { click: _vm.openImage },
                  }),
                  _vm._v(" "),
                  _c("ui-save-button", {
                    attrs: {
                      small: "",
                      low: "",
                      name: _vm.fileName,
                      href: _vm.rotatedImage,
                    },
                  }),
                  _vm._v(" "),
                  _vm.canCompress
                    ? _c("ui-save-button", {
                        attrs: {
                          small: "",
                          low: "",
                          compress: "",
                          name: _vm.fileName,
                          href: _vm.rotatedImage,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }