<template>
  <button
    class="ui-button btn m-btn"
    :class="{
      'm-btn--icon': icon,
      'm-btn--wide m-btn--md': !small,
      'btn-sm': smallText,
      'btn-low': low
    }"
    :type="type"
    :disabled="disabled"
    @click="onClick"
  >
    <div class="btn-content">
      <div v-if="loading" class="loader">
        <ui-loader :size="1" />
      </div>
      <span>
        <i v-if="icon" :class="icon" />
        <span v-if="hasContent" class="btn-text">
          <slot />
        </span>
      </span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ui-button',

  props: {
    type: { type: String, default: undefined },
    icon: { type: String, default: undefined },
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
    smallText: Boolean,
    low: Boolean
  },

  computed: {
    hasContent() {
      return !!this.$slots.default
    }
  },

  methods: {
    onClick(event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style scoped>
.ui-button {
  white-space: normal;
}

.btn:disabled {
  cursor: not-allowed;
}

.btn.btn-link {
  padding: 0;
  background-color: transparent;
  color: var(--dark);
  font-weight: 300;
  text-decoration: none;
}

.btn.m-subheader-search__submit-btn {
  padding: 1.05rem 2rem;
}

.btn.btn-link i[class*=' la-'] {
  vertical-align: bottom;
}

.btn-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.btn.m-subheader-search__submit-btn .btn-content {
  flex-direction: unset;
}

.loader {
  margin: auto 1rem auto -2rem;
}

.btn-low {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
