var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "KybCompanySearch",
            method: _vm.searchMethod,
            "method-get": "",
            "no-extra-payload": "",
            filters: _vm.filters,
            disabled: _vm.isLoading,
            "auto-refresh": false,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c(
            "ui-button",
            {
              staticClass: "btn multiple-entries-btn",
              attrs: { small: "" },
              on: { click: _vm.showImportModal },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("KybMonitoringBatchUploadManageList")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c("ui-header-input", {
            attrs: {
              placeholder: "HeaderCompanyCodePlaceholder",
              icon: "la-institution",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.CompanyCode,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "CompanyCode", $$v)
              },
              expression: "filters.CompanyCode",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.monitoringSetupDateOptions,
              placeholder: "KybMonitoringAllPeriod",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.date,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "date", $$v)
              },
              expression: "filters.date",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { icon: "la la-institution" } },
            [
              !_vm.isLoading
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "hide-mobile",
                        attrs: {
                          labels: _vm.tableLabels,
                          data: _vm.companies,
                          loading: _vm.isLoading,
                          "empty-text": "DetailsKybNotFound",
                        },
                      },
                      [
                        _vm.companies
                          ? _vm._l(_vm.companies, function (row, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass: "m-table__row",
                                  class: {
                                    "status-pending":
                                      row.requestStatus === "Pending",
                                    "status-rejected":
                                      row.requestStatus === "Rejected",
                                  },
                                },
                                [
                                  _c("td", [_vm._v(_vm._s(row.name))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.code))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.localDate))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "td-link" },
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn-brand",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickViewButton(
                                                row.code
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "td-link" },
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn-brand",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.undoMonitoring(
                                                row.code
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("KybUndoMonitoring")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.companies
                      ? _vm._l(_vm.companies, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsCompanyName",
                                  value: row.name,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsCompanyCode",
                                  value: row.code,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "KybStartedMonitoring",
                                  value: row.localDate,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand big-link",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickViewButton(row.code)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("TableView")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.undoMonitoring(row.code)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("KybUndoMonitoring")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("DetailsKybNotFound")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _c("ui-loader", { attrs: { size: 4, center: "" } }),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.pageSelectorData.page,
                  "items-count": _vm.pageSelectorData.count,
                  "max-on-page": _vm.pageSelectorData.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            [
              _c("batch-list-upload", {
                on: { updateCompanies: _vm.updateCompanies },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }