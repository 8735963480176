import 'element-ui/lib/theme-chalk/date-picker.css'
import '@src/styles/styles.css'
import '@src/styles/style.css'
import '@src/styles/custom.css'
import '@src/styles/app.css'

import Vue from 'vue'
import { DatePicker } from 'element-ui'

import Resources, { i18n } from '@src/scripts/resources'
import { router } from '@src/scripts/router'
import Store from '@src/store'

import PopUp from '@src/components/popup'
import AppNotification from '@src/components/notification'
import UiElements from '@src/components/ui'
import App from '@src/App.vue'
import ClickOutside from '@src/scripts/clickOutside'

Vue.directive(ClickOutside.name, ClickOutside)

Vue.use(DatePicker)
Vue.use(PopUp)
Vue.use(AppNotification)
Vue.use(UiElements)
Vue.use(Resources)

new Vue({
  el: '#app',
  components: { App },
  template: '<app/>',
  router: router,
  store: Store,
  i18n
})
