<template>
  <div class="display-flex-div">
    <span v-if="title" class="tr-title">
      {{ $t(title) }}
    </span>
    <span v-if="value" class="tr-value">
      {{ $t(value) }}
    </span>
    <span v-else class="tr-value">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    value: { type: [String, Number, Boolean], default: undefined }
  }
}
</script>

<style scoped>
.display-flex-div {
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid rgb(235, 237, 242);
  width: 100%;
}

.display-flex-div > * {
  width: 50%;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.5rem;
  border: 0;
  word-break: break-word;
}

.tr-title {
  font-weight: bold;
}

@media (max-width: 568px) {
  .display-flex-div > * {
    width: 100%;
  }
}
</style>

<style>
.italic-text .tr-value {
  font-style: italic;
  color: red;
}
</style>
