<template>
  <div
    class="m-input-icon m-input-icon--right"
    :class="
      wide
        ? 'm-input-icon--wide'
        : medium
        ? 'm-input-icon--medium'
        : 'm-input-icon--fixed'
    "
  >
    <ui-dropdown
      :placeholder="placeholder"
      :options="options"
      :has-search="hasSearch"
      :key-value-dropdown="keyValueDropdown"
      :value="value"
      light
      :disabled="disabled"
      :all="all"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ui-header-dropdown',

  props: {
    value: { type: [String, Number, Array], default: undefined },
    options: { type: Array, default: undefined },
    placeholder: { type: String, default: undefined },
    wide: Boolean,
    medium: Boolean,
    disabled: Boolean,
    all: { type: [Boolean, String], default: undefined },
    hasSearch: Boolean,
    keyValueDropdown: Boolean
  }
}
</script>

<style scoped>
.m-input-icon {
  height: 42px;
}
</style>
