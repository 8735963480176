<template>
  <ui-card title="SharableMeetingWhitelist">
    <div
      v-for="{ id, personalCode, isSaving } in whitelist"
      :key="id"
      class="item"
    >
      <ui-loader v-if="isSaving" center :size="1" />
      <button v-else class="remove" @click="remove(id)">&minus;</button>
      <ui-input
        :id="id"
        :value="personalCode"
        @input="onInput({ id, personalCode: $event })"
      />
    </div>
    <div v-if="lastSave" class="last-save">
      {{ $t('SharableMeetingSavedAt', { time: lastSave }) }}
    </div>
    <form
      slot="footer"
      ref="newPersonalCodeForm"
      class="add-new-form"
      @submit.prevent="addNew"
    >
      <ui-input v-model="newPersonalCode" />
      <ui-button class="btn-metal" type="submit">
        {{ $t('SharableMeetingWhitelistAddNew') }}
      </ui-button>
    </form>
  </ui-card>
</template>

<script>
export default {
  props: {
    whitelist: { type: Array, required: true },
    lastSave: { type: String, default: null }
  },
  data() {
    return { newPersonalCode: '' }
  },
  methods: {
    remove(id) {
      this.$emit('remove', id)
    },
    addNew() {
      if (this.newPersonalCode) {
        this.$emit('add', this.newPersonalCode)
        this.newPersonalCode = ''
      } else {
        this.$refs.newPersonalCodeForm.querySelector('.form-control').focus()
      }
    },
    onInput({ id, personalCode }) {
      this.$emit('update', { id, personalCode })
    }
  }
}
</script>

<style scoped>
.item {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1.5rem 1fr;
  align-items: center;
}
.item + .item {
  margin-top: 0.5rem;
}
.remove {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: block;
  background-color: var(--danger);
  color: var(--white);
  text-align: center;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  border: 0;
}
.remove:hover,
.remove:focus {
  opacity: 0.9;
}
.add-new-form {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr auto;
}
.last-save {
  margin-top: 1rem;
  margin-left: 2.5rem;
}
</style>
