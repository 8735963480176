<template>
  <div>
    <div v-for="(question, index) in questions" :key="index" class="row">
      <div class="icon-big">
        <i class="flaticon-visible" />
      </div>
      <div class="">
        <h5>{{ question.question }}</h5>
        <p>{{ question.answer }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    questions: { type: Array, default: undefined }
  }
}
</script>

<style scoped>
.icon-big {
  margin-right: 1.5rem;
  margin-left: 1rem;
}
</style>
