<template>
  <div class="m-subheader-search">
    <h2 class="m-subheader-search__title">
      {{ title }}
    </h2>
    <p v-if="description" class="description">
      {{ description }}
    </p>
    <div v-if="hasContent" class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    description: { type: String, default: null }
  },

  computed: {
    hasContent() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>
.description {
  color: #fff;
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

.content {
  margin-top: 1.5rem;
}
</style>
