var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ui-header", {
        attrs: { title: "SecureMeetingsSetup", "disable-search-on-load": "" },
      }),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _vm.isLoading ? _c("ui-loader", { attrs: { center: "" } }) : _vm._e(),
          _vm._v(" "),
          _vm.redirectUrl
            ? _c(
                "div",
                { staticClass: "grid" },
                [
                  _c("sharable-meeting", {
                    attrs: {
                      "redirect-url": _vm.redirectUrl,
                      "is-saving": _vm.isUrlSaving,
                      "last-save": _vm.lastUrlAction,
                    },
                    on: { redirectUrlUpdate: _vm.onRedirectUrlUpdate },
                  }),
                  _vm._v(" "),
                  _c("sharable-meeting-whitelist", {
                    attrs: {
                      whitelist: _vm.whitelistState,
                      "last-save": _vm.lastWhitelistAction,
                    },
                    on: {
                      add: _vm.whitelistItemAdd,
                      update: _vm.whitelistItemUpdate,
                      remove: _vm.whitelistItemRemove,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }