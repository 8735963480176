var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "template-container" },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { size: 4, center: "" } })
        : _c("div", [
            _c("h2", [
              _vm._v(
                _vm._s(_vm.$t("DetailsCompanyName")) +
                  " " +
                  _vm._s(_vm.getCompanyName.name)
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.data.acceptableDocuments.length
                ? _c("h3", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("CompanyProduct.Form.DocumentType.Title")
                        ) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.data.acceptableDocuments, function (
                  document,
                  index
                ) {
                  return _c("li", { key: "document-" + index }, [
                    _c("div", { staticClass: "item" }, [
                      _c("div", { staticClass: "check-wrap" }, [
                        _c("i", { staticClass: "fas fa-check" }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "CompanyProduct.Form.DocumentType.ChosenLabels." +
                                  document.documentType
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      [
                        _vm._l(document.items, function (item, itemIndex) {
                          return _c("li", { key: "item-" + itemIndex }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.countryIso2) +
                                "\n\n              "
                            ),
                            _c(
                              "ul",
                              { staticClass: "sub-ul" },
                              _vm._l(item.applicableRules, function (
                                rule,
                                ruleIndex
                              ) {
                                return _c(
                                  "li",
                                  {
                                    key: "iso2-" + ruleIndex,
                                    staticClass: "sub-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "CompanyProduct.Form.DocumentType.ChosenLabels." +
                                              rule
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            item.additionalInfo &&
                            item.additionalInfo.comment !== ""
                              ? _c("p", { staticClass: "local-comment" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.trimText(
                                          item.additionalInfo.comment
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        }),
                        _vm._v(" "),
                        _vm._l(document.applicableRules, function (
                          rule,
                          ruleIndex
                        ) {
                          return _c("li", { key: "rule-" + ruleIndex }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CompanyProduct.Form.DocumentType.ChosenLabels." +
                                      rule
                                  )
                                ) +
                                "\n\n              "
                            ),
                            rule ===
                            _vm.APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED
                              ? _c(
                                  "div",
                                  { staticClass: "country-list" },
                                  _vm._l(document.countriesIso2, function (
                                    countries,
                                    countiesIndex
                                  ) {
                                    return _c(
                                      "strong",
                                      {
                                        key: "iso2-" + countiesIndex,
                                        staticClass: "country-item",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("Country." + countries)
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    document.additionalInfo && document.additionalInfo.comment
                      ? _c(
                          "p",
                          { staticClass: "additional-registration-rule mr-3" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.trimText(document.additionalInfo.comment)
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("CompanyProduct.Form.SelfieSettings.Title")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c(
                  "li",
                  _vm._l(_vm.data.selfieTypes, function (type) {
                    return _c("div", { key: type, staticClass: "item" }, [
                      _c("div", { staticClass: "check-wrap" }, [
                        _c("i", { staticClass: "fas fa-check" }),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "CompanyProduct.Form.SelfieSettings.ChosenLabels." +
                                  type
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.data.importants.length
              ? _c("div", [
                  _c("h3", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("CompanyProduct.Form.SelectApplication.Title")
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.data.importants, function (importants, index) {
                      return _c("li", { key: "rule-" + index }, [
                        _c("div", { staticClass: "item" }, [
                          _c("div", { staticClass: "check-wrap" }, [
                            _c("i", { staticClass: "fas fa-check" }),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "CompanyProduct.Form.SelectApplication.ChosenLabels." +
                                      importants.rule
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        importants.rule ===
                        _vm.IMPORTANT_RULE
                          .ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "white-space-pre additional-registration-rule",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      importants.additionalRegistrationValidationRule
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        importants.rule ===
                          _vm.IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES &&
                        importants.countriesIso2.length
                          ? _c(
                              "div",
                              { staticClass: "country-list" },
                              _vm._l(importants.countriesIso2, function (
                                countries,
                                countiesIndex
                              ) {
                                return _c(
                                  "strong",
                                  {
                                    key: "iso2-" + countiesIndex,
                                    staticClass: "country-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("Country." + countries)) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        importants.rule ===
                          _vm.IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES &&
                        importants.countriesIso2.length
                          ? _c(
                              "div",
                              { staticClass: "country-list" },
                              _vm._l(importants.countriesIso2, function (
                                countries,
                                countiesIndex
                              ) {
                                return _c(
                                  "strong",
                                  {
                                    key: "iso2-" + countiesIndex,
                                    staticClass: "country-item",
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("Country." + countries)) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.comment
              ? _c("div", [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("CompanyProduct.Comment")))]),
                  _vm._v(" "),
                  _c("p", { staticClass: "white-space-pre" }, [
                    _vm._v(_vm._s(_vm.data.comment)),
                  ]),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }