<template>
  <div v-if="labels" class="header-tabs">
    <div
      v-for="(item, index) in labels"
      :key="index"
      class="tab-item"
      :class="{ active: selected === index, 'has-status': item.status != null }"
      @click="onChange(index)"
    >
      {{ item.label }}
      <span
        v-if="item.status != null"
        :class="item.status ? 'success' : 'failed'"
      />
    </div>
    <div v-if="loading" class="tabs-loader">
      <ui-loader :size="1.5" center />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: { type: Number, default: null },
    labels: { type: Array, default: null },
    loading: Boolean
  },

  created() {
    if (this.selected == null) this.onChange(0)
  },

  methods: {
    onChange(index) {
      if (this.selected === index) return
      this.$emit('change', index)
    }
  }
}
</script>

<style scoped>
.header-tabs {
  display: flex;
  background-color: #fff;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}

.header-tabs::before,
.header-tabs::after {
  content: '';
  position: sticky;
  top: 0;
  bottom: 0;
  min-width: 2rem;
  z-index: 1;
  pointer-events: none;
}

.header-tabs::before {
  background-image: linear-gradient(
    to right,
    #fff 0,
    #fff 20%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  left: 0;
}

.header-tabs::after {
  background-image: linear-gradient(
    to left,
    #fff 0,
    #fff 20%,
    rgba(0, 0, 0, 0.0001) 100%
  );
  right: 0;
}

.header-tabs > div + div {
  margin-left: 1rem;
}

.tab-item {
  padding: 1rem 1.5rem;
  position: relative;
}

.has-status {
  padding-right: 3.5rem;
}

.tabs-loader {
  display: flex;
  padding: 0 1.5rem;
  min-height: 3.5rem;
}

.tab-item,
.tabs-loader {
  border-bottom: 1px solid grey;
}

.active {
  color: var(--brand);
  border-bottom-color: var(--brand);
}

.tab-item:hover {
  cursor: pointer;
  opacity: 0.7;
}

.success,
.failed {
  position: absolute;
  top: 50%;
  right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transform: translateY(-50%);
}

.success {
  background-color: var(--success);
}

.failed {
  background-color: var(--danger);
}

.success::before,
.failed::before,
.failed::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
}

.failed::before,
.failed::after {
  width: 2px;
  border-left: 2px solid #fff;
}

.success::before {
  width: 35%;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

.failed::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.failed::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>
