<template>
  <div v-show="!disabled" class="dataTables_wrapper">
    <div class="dataTables_paginate paging_simple_numbers">
      <ul class="pagination">
        <li
          v-show="numberOfPages > 1"
          class="paginate_button page-item previous"
          :class="{ disabled: selectedPage === 1 }"
        >
          <a class="page-link" @click="changePage(selectedPage - 1)">
            <i class="la la-angle-left" />
          </a>
        </li>
        <span v-for="index in numberOfPages" :key="index">
          <li
            v-if="pagesLength(index)"
            class="paginate_button page-item"
            :class="{ active: index === selectedPage }"
          >
            <a class="page-link" @click="changePage(index)">
              {{ index }}
            </a>
          </li>
        </span>
        <li
          v-show="numberOfPages > 1"
          class="paginate_button page-item next"
          :class="{ disabled: selectedPage === numberOfPages }"
        >
          <a class="page-link" @click="changePage(selectedPage + 1)">
            <i class="la la-angle-right" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-page-selector',

  props: {
    activePage: { type: Number, default: undefined },
    itemsCount: { type: Number, default: undefined },
    maxOnPage: { type: Number, default: undefined },
    pageCount: { type: Number, default: undefined },
    disabled: Boolean
  },

  computed: {
    selectedPage() {
      const { activePage } = this
      return (activePage && activePage) || 1
    },

    numberOfPages() {
      const { itemsCount, maxOnPage, pageCount } = this
      if (pageCount) return pageCount
      if (!itemsCount || !maxOnPage) return 0
      if (itemsCount % maxOnPage !== 0) {
        return ((itemsCount / maxOnPage) >> 0) + 1
      } else {
        return itemsCount / maxOnPage
      }
    },

    firstPage() {
      const { selectedPage, numberOfPages } = this
      if (numberOfPages > 10 && selectedPage > 5) {
        if (selectedPage + 5 > numberOfPages) return numberOfPages - 9
        else return selectedPage - 5
      }
      return 0
    }
  },

  methods: {
    changePage(newPageIndex) {
      const { activePage } = this
      if (activePage < 2 && newPageIndex < 2) return
      if (activePage !== newPageIndex) {
        if (newPageIndex < 2) newPageIndex = null
        this.$emit('change', newPageIndex)
      }
    },

    pagesLength(index) {
      return this.firstPage < index && index < this.firstPage + 10
    }
  }
}
</script>

<style scoped>
.paginate_button.disabled > .page-link {
  pointer-events: none;
}
</style>
