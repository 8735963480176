var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.values
    ? _c(
        "tr",
        [
          _c("th", { staticClass: "position-relative" }, [
            _vm._v("\n    " + _vm._s(_vm.$t(_vm.title)) + "\n    "),
            _vm.description
              ? _c("i", { staticClass: "indicator-info la la-info-circle" }, [
                  _c("div", { staticClass: "indicator-info-details" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(_vm.description)) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm._l(_vm.values, function (value, index) {
            return _c("td", { key: index }, [
              _vm._v("\n    " + _vm._s(value) + "\n  "),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }