var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "start-session" }, [
    _c("div", { staticClass: "start-session-button" }, [
      _c(
        "button",
        { staticClass: "btn btn-link", on: { click: _vm.startSession } },
        [
          _c("i", { staticClass: "far fa-address-card" }),
          _vm._v(" "),
          _c("p", { staticClass: "kyc-text" }, [
            _vm._v(_vm._s(_vm.$t("AppYourKYCLink"))),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _vm.openKycLink
      ? _c("div", { staticClass: "kyc-link" }, [
          _c("div", { staticClass: "close", on: { click: _vm.clickClose } }, [
            _vm._v("x"),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("YourCompanyIdentityVerificationLink"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "display-flex" },
            [
              _c(
                "div",
                { staticClass: "link-text" },
                [
                  !_vm.sessionLink
                    ? _c("ui-loader", { attrs: { size: 1, center: "" } })
                    : _vm._e(),
                  _vm._v("\n        " + _vm._s(_vm.sessionLink) + "\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "ui-button",
                {
                  staticClass: "btn btn-brand",
                  attrs: { disabled: !_vm.sessionLink, small: "" },
                  on: { click: _vm.copyIdentificationId },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("AppCopy")) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                "ui-button",
                {
                  staticClass: "btn btn-outline-brand",
                  attrs: { disabled: !_vm.sessionLink, small: "" },
                  on: { click: _vm.openNewTab },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("AppOpen")) + "\n      ")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }