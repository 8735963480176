<template>
  <div>
    <ui-card-slider
      v-for="[key, items] in mediaMap"
      :key="key"
      v-slot="{ currentSlide }"
      :title="key"
      :slide-count="items.length"
      :slider-title="$t('AppAttempts')"
      class="text-uppercase media-export-block"
      icon="flaticon-user"
      :has-auto-capture="items.find((item) => item.autoCaptureConfidenceRate)"
      :items="items"
      dark
    >
      <div class="slider-wrapper">
        <ui-card-slider-item v-for="(item, index) in items" :key="index">
          <div v-show="currentSlide === index + 1" class="slide-info">
            <video-box
              v-if="item.fileType === MEDIA_TYPES.FACE_VIDEO"
              :src="item.file"
            />
            <photo-box v-else :data="item" :token="identificationId" />
          </div>
        </ui-card-slider-item>
      </div>
    </ui-card-slider>
  </div>
</template>

<script>
import VideoBox from '@src/components/partials/VideoBox'
import PhotoBox from '@src/components/partials/PhotoBox'
import { MEDIA_TYPES } from '@src/scripts/constants'

export default {
  components: {
    VideoBox,
    PhotoBox
  },
  props: {
    identificationId: { type: [String, Number], default: '' },
    media: { type: Array, default: undefined }
  },
  data: () => ({
    mediaMap: [],
    MEDIA_TYPES
  }),

  created() {
    this.filterMedia()
  },

  methods: {
    filterMedia() {
      const mediaMap = this.filterItemsByType('fileType', this.media)
      const documentPhotos = mediaMap.get(MEDIA_TYPES.DOCUMENT_PHOTO)
      mediaMap.delete(MEDIA_TYPES.DOCUMENT_PHOTO)

      if (documentPhotos) {
        const documentTypeMap = this.filterItemsByType(
          'givenDocumentSideType',
          documentPhotos
        )

        if (documentTypeMap.get('FRONT')) {
          mediaMap.set(
            `${this.$t(MEDIA_TYPES.DOCUMENT_PHOTO)} FRONT`,
            documentTypeMap.get('FRONT')
          )
        }

        if (documentTypeMap.get('BACK')) {
          mediaMap.set(
            `${this.$t(MEDIA_TYPES.DOCUMENT_PHOTO)} BACK`,
            documentTypeMap.get('BACK')
          )
        }
      }
      this.mediaMap = mediaMap
    },

    filterItemsByType(type, items) {
      return items.reduce(
        (entryMap, e) =>
          entryMap.set(e[type], [...(entryMap.get(e[type]) || []), e]),
        new Map()
      )
    }
  }
}
</script>

<style scoped>
.slider-wrapper {
  position: relative;
}

.slide-info {
  width: 100%;
  background: var(--white);
  height: 100%;
}
</style>
