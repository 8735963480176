<template>
  <ui-form :key="submitCount" class="country-settings" @submit="saveChanges">
    <div class="checking-methods">
      <div class="flex-basic">
        <ui-card
          v-for="(item, index) in settings"
          :key="index"
          dark
          :title="item.title"
        >
          <div class="flex-column">
            <p><i class="icon" :class="item.icon" /></p>
            <div>
              <span v-if="typeof item.document === 'object'">
                <p
                  v-for="(doc, key) in item.document"
                  :key="key"
                  class="color-brand cursor"
                  @click="openDocument(doc.url)"
                >
                  <i class="flaticon-camera" />{{ doc.title }}
                </p>
              </span>
              <p
                v-else
                class="color-brand cursor"
                @click="openDocument(item.document)"
              >
                <i class="flaticon-camera" />{{ $t('DetailsDocumentExample') }}
              </p>
              <p>
                <i
                  :class="{
                    'la	la-check-circle': item.mrzZone,
                    'flaticon-cancel': !item.mrzZone
                  }"
                />{{ $t('CheckContainsMRZzone') }}
              </p>
            </div>
            <ui-toggle
              :value="item.enabled"
              :disabled="!details.editEnabled"
              @change="toggleChange($event, index)"
            />
          </div>
        </ui-card>
        <know-country class="flex-basic-70" />
      </div>
      <div class="tools-buttons">
        <edit-footer
          :edit-enabled="details.editEnabled"
          @edit="enableDetailsEdit"
        />
        <ui-button
          v-if="details.editEnabled"
          small
          class="btn btn-metal m--margin-left-10"
          @click="clickCancel"
        >
          <i class="la la-close" />
          <span> {{ $t('AppCancel') }} </span>
        </ui-button>
      </div>
    </div>
  </ui-form>
</template>

<script>
import EditFooter from '@src/components/partials/EditFooter'
import KnowCountry from '@src/components/partials/KnowCountry'

export default {
  components: {
    EditFooter,
    KnowCountry
  },

  data() {
    return {
      details: {
        editEnabled: false
      },
      settings: null,
      defaultSettings: null,
      submitCount: 0
    }
  },

  created() {
    this.settings = this.getSettings()
    this.defaultSettings = this.getSettings()
  },

  methods: {
    toggleChange(value, key) {
      this.$set(this.settings[key], 'enabled', value)
    },

    openDocument(url) {
      const image = new Image()
      image.src = url
      const popup = window.open()
      popup.document.write(image.outerHTML)
    },

    enableDetailsEdit() {
      this.details.editEnabled = true
    },

    clickCancel() {
      const { settings } = this
      for (let item in settings) {
        settings[item] = Object.assign({}, this.defaultSettings[item])
      }
      this.details.editEnabled = false
    },

    saveChanges() {
      const { defaultSettings } = this
      if (!this.details.editEnabled) {
        this.submitCount++
      } else {
        for (let item in defaultSettings) {
          defaultSettings[item] = Object.assign({}, this.settings[item])
        }
        this.details.editEnabled = false
      }
    },

    getSettings() {
      return {
        passport: {
          title: 'International passport check',
          icon: 'fa fa-passport',
          document: '/dist/images/africa.png',
          mrzZone: true,
          enabled: true
        },
        id: {
          title: 'National ID card',
          icon: 'fa fa-address-card',
          document: '/dist/images/asia.png',
          mrzZone: true,
          enabled: false
        },
        drivers: {
          title: 'Drivers licence',
          icon: 'fa fa-car',
          document: '/dist/images/europe.png',
          mrzZone: false,
          enabled: false
        },
        residence: {
          title: 'Residence permit',
          icon: 'fa fa-home',
          document: '/dist/images/africa.png',
          mrzZone: true,
          enabled: true
        },
        other: {
          title: 'Other documents',
          icon: 'fa fa-file',
          document: [
            { title: "Seaman's book", url: '/dist/images/asia.png' },
            { title: 'Emergency Passport', url: '/dist/images/europe.png' },
            { title: 'Other document types', url: '/dist/images/africa.png' }
          ],
          mrzZone: true,
          enabled: true
        }
      }
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.15rem;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  width: 100%;
  justify-content: space-between;
}

.flex-row .ui-toggle {
  display: inline-block;
}

.flex-basic {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-basic > div:not(:last-child) {
  flex-basis: 23%;
  margin-right: 0.15rem;
}

.tools-buttons {
  display: flex;
  flex-wrap: wrap;
  height: 3.38rem;
  justify-content: flex-end;
  margin-top: 1.15rem;
}

.icon {
  font-size: 3.7rem;
  color: #ccc;
}

.flaticon-camera,
.la-check-circle,
.flaticon-cancel {
  font-size: 1.3rem;
  margin-right: 5px;
}

.cursor {
  cursor: pointer;
}

.flaticon-cancel {
  font-size: 1.1rem;
  margin-right: 0.38rem;
}

.header-border {
  border-top: 1px solid #ebedf2;
  padding: 2.3rem 0;
}

.flex-basic-70 {
  flex-basis: 74.4%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.785rem;
  width: 100%;
}

.color-brand {
  color: var(--brand);
}

@media (max-width: 990px) {
  .flex-basic > div:not(:last-child) {
    flex-basis: 100%;
  }

  .flex-basic-70 {
    flex-basis: 100%;
  }
}
</style>
