var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "header-form",
        { on: { submit: _vm.submit } },
        [
          _c(
            "template",
            { slot: "inputs" },
            [
              _c("ui-header-dropdown", {
                attrs: {
                  options: _vm.options.dates,
                  placeholder: _vm.$t("Kyb.Search.Date"),
                  disabled: _vm.isLoading,
                  wide: "",
                },
                model: {
                  value: _vm.filters.date,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.filters,
                      "date",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "filters.date",
                },
              }),
              _vm._v(" "),
              _c("ui-header-input", {
                attrs: {
                  placeholder: _vm.$t("Kyb.Search.CompanyName"),
                  icon: "la-book",
                  disabled: _vm.isLoading,
                  medium: "",
                },
                model: {
                  value: _vm.filters.companyName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.filters,
                      "companyName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "filters.companyName",
                },
              }),
              _vm._v(" "),
              _c("ui-header-input", {
                attrs: {
                  placeholder: _vm.$t("Kyb.Search.CompanyCode"),
                  icon: "la-industry",
                  disabled: _vm.isLoading,
                  medium: "",
                },
                model: {
                  value: _vm.filters.companyCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.filters,
                      "companyCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "filters.companyCode",
                },
              }),
              _vm._v(" "),
              _c("ui-header-dropdown", {
                attrs: {
                  options: _vm.options.reviewStatuses,
                  placeholder: _vm.$t("Kyb.Search.ReviewStatus"),
                  disabled: _vm.isLoading,
                },
                model: {
                  value: _vm.filters.reviewStatus,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.filters,
                      "reviewStatus",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "filters.reviewStatus",
                },
              }),
              _vm._v(" "),
              _c("ui-header-input", {
                attrs: {
                  placeholder: _vm.$t("Kyb.Search.IdentificationId"),
                  icon: "la-tag",
                  disabled: _vm.isLoading,
                  wide: "",
                },
                model: {
                  value: _vm.filters.identificationId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.filters,
                      "identificationId",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "filters.identificationId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "ui-button",
            {
              staticClass: "m-subheader-search__submit-btn",
              attrs: {
                slot: "actions",
                type: "submit",
                disabled: _vm.isLoading,
              },
              slot: "actions",
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("App.Search")) + "\n  ")]
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }