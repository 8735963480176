<template>
  <div class="webhooks-page">
    <ui-header-get
      title="WebhooksTitle"
      :filters="filters.date"
      :method="searchMethod"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div class="header-date-picker">
        <el-date-picker
          :value="filters.date"
          type="date"
          value-format="yyyy/MM/dd"
          :clearable="false"
          @input="changeDate"
        />
      </div>
    </ui-header-get>
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <!-- <ui-button slot="tools" class="btn-link" :disabled="isDownloading" @click="exportList">
          {{ $t('AppExport') }}
          <i class="la la-download" />
        </ui-button> -->
        <div slot="tools">
          <ui-dropdown
            :options="exportOptions"
            placeholder="AppExport"
            class="margin-right"
            small
            light-border
            @input="exportList"
          />
        </div>
        <template v-if="!isLoading">
          <ui-table
            :labels="tableLabels"
            :data="identifications"
            class="export-table hide-mobile"
            :loading="isLoading"
            empty-text="TableNoWebhooksHistory"
          >
            <template v-if="identifications">
              <tr
                v-for="(row, index) in identifications"
                :key="index"
                class="m-table__row"
              >
                <td class="word-break">
                  {{ row.id }}
                </td>
                <td class="word-break">
                  {{ row.identification }}
                </td>
                <td class="medium-td">
                  {{ row.event }}
                </td>
                <td class="medium-td">
                  {{ row.created | date(true) }}
                </td>
                <td class="medium-td">
                  {{ row.sent | date(true) }}
                </td>
                <td>
                  {{ row.retries }}
                </td>
                <td>
                  {{ row.status }}
                </td>
                <td class="ellipsis-td">
                  {{ row.url }}
                </td>
                <td>
                  {{ row.finished }}
                </td>
              </tr>
            </template>
          </ui-table>
          <p v-if="warningText" class="warning-text">
            {{ $t(warningText) }}
          </p>
          <template v-if="identifications && identifications.length > 0">
            <div
              v-for="(row, index) in identifications"
              :key="index"
              class="response-table show-mobile"
            >
              <responsive-table-tr title="WebhooksId" :value="row.id" />
              <responsive-table-tr
                title="WebhooksIdentification"
                :value="row.identification"
              />
              <responsive-table-tr title="WebhooksEvent" :value="row.event" />
              <responsive-table-tr
                title="WebhooksCreated"
                :value="row.created | date(true)"
              />
              <responsive-table-tr
                title="WebhooksSent"
                :value="row.sent | date(true)"
              />
              <responsive-table-tr
                title="WebhooksRetries"
                :value="row.retries"
              />
              <responsive-table-tr title="WebhooksStatus" :value="row.status" />
              <responsive-table-tr title="WebhooksUrl" :value="row.url" />
              <responsive-table-tr
                title="WebhooksFinished"
                :value="row.finished"
              />
            </div>
          </template>
          <div
            v-if="!identifications || identifications.length === 0"
            class="show-mobile"
          >
            {{ $t('TableNoWebhooksHistory') }}
          </div>
          <p v-if="warningText" class="show-mobile warning-text">
            {{ $t(warningText) }}
          </p>
        </template>
        <ui-loader v-else :size="4" center />
      </ui-card>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import {
  dateToString,
  mapOptions,
  downloadBlob,
  dateToStringGet
} from '@src/scripts/helpers'
import { exportPdf } from '@src/scripts/exportPdf'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'

const filters = () => ({
  date: null,
  page: null,
  pageSize: 100
})

export default {
  components: {
    ResponsiveTableTr
  },

  filters: {
    date: dateToString
  },

  data() {
    return {
      isReady: false,
      warningText: null,
      isLoading: false,
      isDownloading: false,
      identifications: null,
      itemsCount: 0,
      searchMethod: '/identifications/webhooks',
      filters: filters(),
      tableLabels: [
        'WebhooksId',
        'WebhooksIdentification',
        'WebhooksEvent',
        'WebhooksCreated',
        'WebhooksSent',
        'WebhooksRetries',
        'WebhooksStatus',
        'WebhooksUrl',
        'WebhooksFinished'
      ]
    }
  },

  computed: {
    exportOptions() {
      return mapOptions(['CSV'])
    },

    ...mapGetters([getters.selectedCompany, getters.searchHistory]),

    companyId() {
      const { selectedCompany } = this
      return selectedCompany || null
    },

    pageName() {
      return this.$route.name
    }
  },

  // async created() {
  //   const { createFilters } = this
  //   await createFilters()
  //   this.isReady = true
  // },

  created() {
    if (this.searchHistory(this.pageName))
      this.$set(this.filters, 'date', this.searchHistory(this.pageName))
    else this.$set(this.filters, 'date', dateToStringGet(new Date()))
  },

  methods: {
    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      this.isLoading = false
      if (data.error) return
      const { result } = data.data
      if (result) {
        if (result.list && result.list.length >= 100) {
          this.identifications = result.list.slice(0, 100)
          this.warningText = 'WebhookHistoryMore100'
        } else {
          this.identifications = result.list
          this.warningText = null
        }
        this.itemsCount = result.list.length
      }
    },

    changeDate(value) {
      this.$set(this.filters, 'date', value)
    },

    createPayload(filters) {
      return {
        companyId: this.companyId,
        from: filters.date || undefined
      }
    },

    async exportList(value) {
      if (value === 'PDF') {
        this.exportPdfFormat()
      } else if (value === 'CSV') {
        await this.exportCsvFormat()
      } else if (value === 'XLS') {
        await this.exportCsvFormat()
      }
    },

    async exportPdfFormat() {
      try {
        const name = this.$store.getters.sessionData.profile.name
        this.tableRows = this.identifications.map((item) => [
          item.identification || '',
          item.id || '',
          dateToString(item.created) || '',
          item.event || '',
          dateToString(item.sent) || '',
          item.retries || '0',
          item.status || '',
          item.url || '',
          item.finished || ''
        ])
        const data = {
          tableLabels: this.tableLabels.map((item) => this.$t(item)),
          tableRows: this.tableRows,
          filters: {
            TableDate: this.filters.date
          },
          pageOrientation: 'landscape'
        }
        const exportFile = new exportPdf(data, name)
        await exportFile.exportTable()
      } catch (error) {
        this.showError()
      }
    },

    async exportCsvFormat() {
      const { filters } = this
      if (!filters.date) return

      try {
        this.isDownloading = true
        // const payload = createPayload(filters)
        const blob = await Api.getBlobWithParams(
          `/identifications/webhooks/${filters.date}/download/csv`
        )
        downloadBlob(blob)
      } catch (error) {
        this.showError()
      }
      this.isDownloading = false
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.warning-text {
  padding-left: 0.5rem;
}

.margin-right {
  margin-right: 1rem;
}

.header-date-picker {
  position: relative;
  margin-right: 1rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.show-mobile {
  display: none;
}

@media all and (max-width: 992px) {
  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }
}
</style>
<style>
.webhooks-page .export-table table {
  table-layout: auto;
}

/* .webhooks-page .export-table table td.small-td {
  width: 50px;
  padding: 1.2rem .5rem;
} */

.webhooks-page .export-table table td.medium-td {
  width: 150px;
  padding: 0.5rem 1rem;
}

.webhooks-page .export-table table td.ellipsis-td {
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.5rem 1rem;
}

.webhooks-page .export-table table td.word-break {
  min-width: 100px;
  word-break: break-word;
  padding: 0.5rem 1rem;
}

.header-date-picker .el-input__inner {
  background-color: transparent;
  color: var(--white);
  font-size: 0.9rem;
  line-height: 1.25;
  width: 100%;
  position: relative;
}

.header-date-picker .el-input__inner .el-range-input {
  background-color: transparent;
}

@media (max-width: 1250px) {
  .webhooks-page .export-table table td.word-break {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .webhooks-page .export-table table {
    table-layout: fixed;
  }
}
</style>
