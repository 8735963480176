var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-item media-item" }, [
    _c("span", { staticClass: "details-icon" }, [
      _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.value
      ? _c("div", { staticClass: "details-value" }, [
          _c("h4", [
            _c("a", { attrs: { href: _vm.value.url, target: "_blank" } }, [
              _vm._v("\n        " + _vm._s(_vm.value.title) + "\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.value.snippet))]),
          _vm._v(" "),
          _c("span", { staticClass: "p-date" }, [
            _c("strong", [_vm._v(_vm._s(_vm.$t("Published")))]),
            _vm._v(
              "\n      " + _vm._s(_vm.normalDate(_vm.value.date)) + "\n    "
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }