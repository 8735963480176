import {
  cachedData,
  historyKey,
  sessionKey,
  cachedString,
  companyKey,
  clearData,
  settingsKey,
  hideLogsKey,
  sanctionsKey,
  sessionTimeNotifyKey,
  kybKey,
  stopListKey,
  registriesKey
} from '@src/store/cache'
import { mutations as kyb } from '@src/store/kyb'

export const methods = {
  changeSession,
  changeSettings,
  changeHistory,
  changeCompany,
  changeSanctions,
  changeProgress,
  updateHideLogs,
  updateSessionTimeNotify,
  changeKyb,
  changeStopList,
  changeRegistriesList,
  ...kyb
}

export default Object.keys(methods).reduce(
  (result, item) => ({
    ...result,
    [item]: item
  }),
  {}
)

function changeSession(state) {
  clearStorage(state)
  const payload = cachedData(sessionKey)
  state.session = payload
}

function changeSanctions(state, payload) {
  cachedData(sanctionsKey, payload)
  state.sanctions = payload
}

function changeSettings(state, payload) {
  cachedData(settingsKey, payload)
  state.settings = payload
}

function changeRegistriesList(state, payload) {
  cachedData(registriesKey, payload)
  state.registries = payload
}

function changeHistory(state, payload) {
  cachedData(historyKey, payload)
  state.history = payload
}

function changeCompany(state, payload) {
  cachedString(companyKey, payload || '')
  state.selectedCompany = payload
}

function clearStorage(state) {
  clearData()
  if (state) {
    state.history = {}
    state.selectedCompany = null
  }
}

function changeProgress(state, payload) {
  state.inProgress = payload
}

function updateHideLogs(state, payload) {
  cachedData(hideLogsKey, payload)
  state.hideLogs = payload
}

function updateSessionTimeNotify(state, payload) {
  cachedData(sessionTimeNotifyKey, payload)
  state.sessionTime = payload
}

function changeKyb(state, payload) {
  cachedData(kybKey, payload)
  state.kybList = payload
}

function changeStopList(state, payload) {
  cachedData(stopListKey, payload)
  state.stopList = payload
}
