var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-card", { attrs: { title: "SharableMeetingUrl" } }, [
    _c(
      "div",
      { staticClass: "url-item" },
      [
        _vm.isSaving
          ? _c("ui-loader", { attrs: { size: 1, center: "" } })
          : _c("div"),
        _vm._v(" "),
        _c("ui-input", {
          attrs: { value: _vm.redirectUrl },
          on: { input: _vm.onInput },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.lastSave
      ? _c("div", { staticClass: "last-save" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("SharableMeetingSavedAt", { time: _vm.lastSave })) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }