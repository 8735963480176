var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "document-item-container" }, [
    _c("div", { staticClass: "document-item-body" }, [
      _c("div", { staticClass: "document-info" }, [
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@src/assets/images/file.svg") },
        }),
        _vm._v(" "),
        _vm.hasFile
          ? _c(
              "div",
              {
                staticClass: "title download-title",
                on: { click: _vm.handleDownload },
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            )
          : _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "delete-btn", on: { click: _vm.handleDelete } },
          [_vm._v(_vm._s(_vm.$t("DocumentDelete")))]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "document-date" }, [
        _vm._v(_vm._s(_vm.dateToString(_vm.createdUtc, true))),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "document-body-actions" },
      [
        _vm.hasFile
          ? [
              _c("div", { staticClass: "document-status-container" }, [
                _c("div", { staticClass: "document-status-title" }, [
                  _vm._v(_vm._s(_vm.$t("DocumentStatus")) + ":"),
                ]),
                _vm._v(" "),
                _vm.status === "Validated"
                  ? _c("div", { staticClass: "document-status-value" }, [
                      _c("i", {
                        staticClass:
                          "rule-icon flaticon-interface-7 m--font-success",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("DocumentStatusValid")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.status === "Invalidated"
                  ? _c("div", { staticClass: "document-status-value" }, [
                      _c("i", {
                        staticClass:
                          "rule-icon la la-times-circle m--font-danger",
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("DocumentStatusInvalid")) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.status === "NeedReview"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "document-status-value document-status-need-review",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("DocumentStatusDefault")) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "document-date" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.dateToString(_vm.lastActionUtc, true)) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-container" },
                [
                  _c("ui-dropdown", {
                    attrs: {
                      disabled: _vm.isLoading,
                      placeholder: _vm.$t("DocumentReview"),
                      options: [
                        {
                          value: _vm.$t("DocumentDataIsCorrect"),
                          key: "Validated",
                        },
                        {
                          value: _vm.$t("DocumentDataIsNotCorrect"),
                          key: "Invalidated",
                        },
                      ],
                      small: "",
                    },
                    on: { input: _vm.handleStatusChange },
                    model: {
                      value: _vm.reviewStatus,
                      callback: function ($$v) {
                        _vm.reviewStatus = $$v
                      },
                      expression: "reviewStatus",
                    },
                  }),
                ],
                1
              ),
            ]
          : _c(
              "ui-button",
              {
                staticClass: "btn-brand document-button",
                on: {
                  click: function ($event) {
                    return _vm.$emit("openDocumentModal", true)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("DocumentUpload")) + "\n    ")]
            ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }