<template>
  <header class="m-grid__item m-header">
    <div
      class="m-container m-container--fluid m-container--full-height hide-mobile"
    >
      <div class="m-stack m-stack--ver m-stack--desktop">
        <!-- <mobile-menu @lefttoggleclick="leftToggleClick" @righttoggleclick="rightToggleClick"/> -->

        <div class="m-stack__item m-stack__item--fluid m-header-head">
          <!-- <button class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light">
            <i class="la la-close" />
          </button> -->

          <sub-menu />
          <info-menu />
        </div>
      </div>
    </div>
    <div class="show-mobile">
      <mobile-menu @lefttoggleclick="openMenu" />
      <transition name="show">
        <div v-if="menuIsOpened" class="mobile-menu show-mobile">
          <a class="close" @click="onClose" />
          <info-menu @clickButton="menuIsOpened = false" />
        </div>
      </transition>
    </div>
  </header>
</template>

<script>
import SubMenu from '@src/components/partials/header/SubMenu'
import InfoMenu from '@src/components/partials/header/InfoMenu'
import MobileMenu from '@src/components/partials/header/MobileMenu'

export default {
  components: {
    SubMenu,
    InfoMenu,
    MobileMenu
  },

  data() {
    return {
      menuIsOpened: false
    }
  },

  watch: {
    '$route.name'() {
      this.menuIsOpened = false
    }
  },

  methods: {
    openMenu() {
      if (this.menuIsOpened) {
        this.menuIsOpened = false
        document.removeEventListener('click', this.clickOutside)
      } else {
        this.menuIsOpened = true
        document.addEventListener('click', this.clickOutside)
      }
    },

    onClose() {
      this.menuIsOpened = false
    },

    leftToggleClick() {
      const app = document.getElementById('app')
      if (app) {
        app.classList.add('m-aside-left--on')
      }
    },

    rightToggleClick() {
      const app = document.getElementById('app')
      if (app) {
        app.classList.add('m-aside-header-menu-mobile--on')
      }
    }
  }
}
</script>

<style scoped>
.close {
  top: -0.5rem;
  position: absolute;
  min-height: 1rem;
  width: 1rem;
  right: 1rem;
}

.close::before,
.close::after {
  content: '';
  position: absolute;
  background-color: var(--input-font);
  width: 1px;
  height: 100%;
  left: 50%;
}

.close::before {
  transform: rotateZ(45deg);
}

.close::after {
  transform: rotateZ(-45deg);
}

.show-enter-active,
.show-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-property: opacity, margin-top;
}

.show-enter {
  opacity: 0;
  margin-top: -200px;
}

.show-leave-to {
  opacity: 0;
  margin-top: -200px;
}

.show-mobile {
  display: none;
  position: relative;
}

@media (max-width: 678px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
    text-align: center;
  }

  .mobile-burger {
    position: relative;
    height: 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  .mobile-burger > div {
    height: 2px;
    width: 2rem;
    background: var(--brand);
  }

  .mobile-menu {
    position: absolute;
    width: 100%;
    padding-right: 1rem;
    padding-bottom: 2rem;
    top: 0;
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 2rem;
    box-shadow: 0 0 6px 0 var(--dark);
  }

  .mobile-menu > * {
    margin-top: 1rem;
  }
}
</style>
