<template>
  <ui-widget>
    <p class="align-center">
      {{ $t('CheckSettingCountriesDescription') }}
    </p>
    <h5 class="bold align-center">
      {{ $t('CheckSettingCountriesSettings') }}
    </h5>
    <div>
      <ui-form :key="submitCount" @submit="saveChanges">
        <div class="row settings-titles">
          <div class="flex-basis-48">
            <div style="margin-right: 50px" />
          </div>
          <div class="flex-row flex-basis-50">
            <div class="flex-basis-18">
              {{ $t('CheckSettingPassport') }}
            </div>
            <div class="flex-basis-18">
              {{ $t('CheckSettingId') }}
            </div>
            <div class="flex-basis-18">
              {{ $t('CheckSettingDriving') }}
            </div>
            <div class="flex-basis-18">
              {{ $t('CheckSettingResidence') }}
            </div>
            <div class="flex-basis-18">
              {{ $t('CheckSettingOther') }}
            </div>
          </div>
        </div>
        <country-settings-box
          v-for="(regions, index) in settings"
          :key="index"
          :regions="regions"
          :disabled="!details.editEnabled"
          @passportChanged="passportChanged($event, index)"
          @idChanged="idChanged($event, index)"
          @drivingChanged="drivingChanged($event, index)"
          @residenceChanged="residenceChanged($event, index)"
          @otherChanged="otherChanged($event, index)"
        />
        <div class="tools-buttons">
          <edit-footer
            :edit-enabled="details.editEnabled"
            @edit="enableDetailsEdit"
          />
          <ui-button
            v-if="details.editEnabled"
            small
            class="btn btn-metal m--margin-left-10"
            @click="clickCancel"
          >
            <i class="la la-close" />
            <span> {{ $t('AppCancel') }} </span>
          </ui-button>
        </div>
      </ui-form>
    </div>
  </ui-widget>
</template>

<script>
import EditFooter from '@src/components/partials/EditFooter'
import CountrySettingsBox from '@src/components/partials/CountrySettingsBox'

export default {
  components: {
    EditFooter,
    CountrySettingsBox
  },

  data() {
    return {
      details: {
        editEnabled: false
      },
      settings: null,
      defaultSettings: null,
      submitCount: 0
    }
  },

  created() {
    this.settings = Object.assign({}, this.getSettings())
    this.defaultSettings = Object.assign({}, this.getSettings())
  },

  methods: {
    getSettings() {
      return {
        Africa: {
          title: 'Africa',
          description: [
            '233 countries included',
            '3 countries with custom settings'
          ],
          imgSrc: 'africa.png'
        },
        LatinAmerica: {
          title: 'Latin America',
          description: ['78 countries included'],
          imgSrc: 'south-america.png'
        },
        NorthernAmerica: {
          title: 'Northern America',
          description: ['43 countries included'],
          imgSrc: 'north-america.png'
        },
        Asia: {
          title: 'Asia',
          description: ['3 countries included'],
          imgSrc: 'asia.png'
        },
        Europe: {
          title: 'Europe',
          description: ['133 countries included'],
          imgSrc: 'europe.png'
        },
        AustraliaOceania: {
          title: 'Australia & Oceania',
          description: ['33 countries included'],
          imgSrc: 'australia.png'
        }
      }
    },

    passportChanged(value, key) {
      this.$set(this.settings[key], 'passport', value)
    },

    idChanged(value, key) {
      this.$set(this.settings[key], 'id', value)
    },

    drivingChanged(value, key) {
      this.$set(this.settings[key], 'driving', value)
    },

    residenceChanged(value, key) {
      this.$set(this.settings[key], 'residence', value)
    },

    otherChanged(value, key) {
      this.$set(this.settings[key], 'other', value)
    },

    enableDetailsEdit() {
      this.details.editEnabled = true
    },

    saveChanges() {
      const { defaultSettings } = this
      if (!this.details.editEnabled) {
        this.submitCount++
      } else {
        for (let country in defaultSettings) {
          defaultSettings[country] = Object.assign({}, this.settings[country])
        }
        this.details.editEnabled = false
      }
    },

    clickCancel() {
      const { settings } = this
      for (let country in settings) {
        settings[country] = Object.assign({}, this.defaultSettings[country])
      }
      this.details.editEnabled = false
    }
  }
}
</script>

<style scoped>
.tools-buttons {
  display: flex;
  flex-wrap: wrap;
  height: 3.4rem;
  justify-content: flex-end;
  margin-top: 1.15rem;
}

.tools-buttons .ui-button {
  height: 3.4rem;
}

.flex-row {
  display: flex;
  flex-wrap: nowrap;
}

.flex-basis-48 {
  flex-basis: 48.5%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-18 {
  flex-basis: 18%;
  padding-right: 1rem;
  text-align: center;
}

@media (max-width: 500px) {
  .align-center {
    text-align: center;
  }
}
</style>
