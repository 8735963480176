var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-container",
        [
          _c(
            "identification-header",
            [
              _c("template", { slot: "left-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-header-tabs-btn",
                    class: [
                      _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                        ? "active"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.tabChange(
                          _vm.IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("IdentificationDashboard.ActiveUsers")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "card-header-tabs-btn",
                    class: [
                      _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS
                        .PENDING_IDENTIFICATIONS_TAB
                        ? "active"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.tabChange(
                          _vm.IDENTIFICATION_DASHBOARD_TABS
                            .PENDING_IDENTIFICATIONS_TAB
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "IdentificationDashboard.PendingIdentifications"
                          )
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("template", { slot: "right-content" }, [
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("span", [_vm._v(_vm._s(_vm.activeSpecialistsCount))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("IdentificationDashboard.TotalActiveSpecialists")
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex align-items-center" }, [
                  _c("span", [_vm._v(_vm._s(_vm.pendingIdentifications))]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "IdentificationDashboard.TotalPendingIdentifications"
                          )
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ui-card",
            { staticClass: "card-body" },
            [
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : [
                    _c(
                      "div",
                      { staticClass: "reload-btn" },
                      [
                        _c(
                          "ui-button",
                          {
                            staticClass: "btn-brand",
                            attrs: { low: "", small: "" },
                            on: { click: _vm.reloadList },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("IdentificationDashboard.ReloadList")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.activeTab ===
                    _vm.IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-5" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex mb-4 align-items-start",
                                  },
                                  [
                                    _c("ui-input", {
                                      attrs: {
                                        placeholder: "id",
                                        error: _vm.error,
                                        type: "text",
                                      },
                                      model: {
                                        value: _vm.userFilterModel,
                                        callback: function ($$v) {
                                          _vm.userFilterModel = $$v
                                        },
                                        expression: "userFilterModel",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "ui-button",
                                      {
                                        staticClass: "ml-3 btn-brand",
                                        on: { click: _vm.handleFilter },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "IdentificationDashboard.Filter"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.activeSpecialists.length
                              ? _c(
                                  "dash-board-table",
                                  [
                                    _c("template", { slot: "table-header" }, [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "IdentificationDashboard.Email"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "IdentificationDashboard.LoginTime"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "IdentificationDashboard.Summary"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "table-body" },
                                      _vm._l(_vm.activeSpecialists, function (
                                        activeSpecialist
                                      ) {
                                        return _c(
                                          "tr",
                                          { key: activeSpecialist.userId },
                                          [
                                            _c("td", [
                                              _c("span", {
                                                staticClass: "green-dot",
                                              }),
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    activeSpecialist.email
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dateToString(
                                                    activeSpecialist.createdUtc,
                                                    true
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                _c(
                                                  "ui-button",
                                                  {
                                                    staticClass: "btn-brand",
                                                    attrs: {
                                                      low: "",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openSummery(
                                                          activeSpecialist.userId,
                                                          activeSpecialist.email
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "IdentificationDashboard.Open"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", { staticClass: "p-4" }, [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "IdentificationDashboard.NoActiveUsersFound"
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeTab ===
                    _vm.IDENTIFICATION_DASHBOARD_TABS
                      .PENDING_IDENTIFICATIONS_TAB
                      ? _c("div", [
                          _vm.identifications.length
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 col-md-8" },
                                  [
                                    _c("h4", { staticClass: "table-title" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "IdentificationDashboard.CurrentPendingIdentificationsStatus"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "dash-board-table",
                                      [
                                        _c(
                                          "template",
                                          { slot: "table-header" },
                                          [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.IdentID"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Company"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Name"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Status"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.TimeToDeadline"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "template",
                                          { slot: "table-body" },
                                          _vm._l(_vm.identifications, function (
                                            identification,
                                            index
                                          ) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "copy-btn",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copyIdentificationId(
                                                          identification.identificationId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "IdentificationDashboard.Copy"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    identification.companyName
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    identification.assignedUserEmail
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(identification.status)
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class:
                                                        _vm.getDeadLine(
                                                          identification.finishDeadlineUtc +
                                                            "Z"
                                                        ) <= 0
                                                          ? "expired"
                                                          : "",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            Math.round(
                                                              _vm.getDeadLine(
                                                                identification.finishDeadlineUtc +
                                                                  "Z"
                                                              )
                                                            )
                                                          ) +
                                                          "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "IdentificationDashboard.Sec"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ])
                                          }),
                                          0
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "col-12 col-md-4" },
                                  [
                                    _c("h4", { staticClass: "table-title" }, [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "IdentificationDashboard.CurrentIdentificationsGroupedByCompanies"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "dash-board-table",
                                      [
                                        _c(
                                          "template",
                                          { slot: "table-header" },
                                          [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Company"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.IdentificationCount"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "template",
                                          { slot: "table-body" },
                                          _vm._l(
                                            _vm.identificationsByCompany,
                                            function (company, index) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(_vm._s(index)),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(company.length)
                                                  ),
                                                ]),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _c("div", { staticClass: "p-4 text-center" }, [
                                _c("h4", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "IdentificationDashboard.NoPendingIdentificationsFound"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            {
              attrs: {
                "pop-up-title":
                  _vm.$t("IdentificationDashboard.IdLogs") +
                  " " +
                  _vm.userIdentificationsFullName,
                "no-padding": "",
                "full-size": "",
              },
            },
            [
              _c("user-identifications", {
                attrs: {
                  "user-id": _vm.userIdentificationsId,
                  "user-name": _vm.userIdentificationsFullName,
                  type:
                    _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB ||
                    _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS
                        .PENDING_IDENTIFICATIONS_TAB
                      ? _vm.IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                      : _vm.IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }