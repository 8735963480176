var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "AdminUsersTitle",
            method: _vm.searchMethod,
            "disable-search-on-load": "",
            filters: _vm.filters,
            disabled: _vm.isLoading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            attrs: {
              type: "email",
              placeholder: "HeaderEmailPlaceholder",
              icon: "la-envelope",
              medium: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.email,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "email", $$v)
              },
              expression: "filters.email",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.options.statusOptions,
              placeholder: "HeaderStatusPlaceHolder",
              all: "",
              small: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.status,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "status", $$v)
              },
              expression: "filters.status",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.options.emailStatusOptions,
              all: "",
              medium: "",
              placeholder: "HeaderEmailStatusPlaceholder",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.emailStatus,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "emailStatus", $$v)
              },
              expression: "filters.emailStatus",
            },
          }),
          _vm._v(" "),
          _c("ui-header-input", {
            attrs: {
              placeholder: "TableFullName",
              medium: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.fullName,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "fullName", $$v)
              },
              expression: "filters.fullName",
            },
          }),
          _vm._v(" "),
          _vm.isSuperAdmin
            ? _c("ui-multi-header-dropdown", {
                attrs: {
                  options: _vm.options.roles,
                  medium: "",
                  "has-search": "",
                  "multi-values-translation-end": "Role",
                  placeholder: "HeaderRolePlaceholder",
                  disabled: _vm.isLoading,
                },
                on: { dropdownMultiSelected: _vm.handleRoleSelect },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            {
              staticClass: "user-page",
              attrs: { title: "AppSearchResults", count: _vm.usersCount },
            },
            [
              _c(
                "ui-button",
                {
                  staticClass: "btn-link tools-div",
                  attrs: { slot: "tools", disabled: !_vm.companyId },
                  on: { click: _vm.openUserForm },
                  slot: "tools",
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("AdminAddUser")) + "\n        "
                  ),
                  _c("i", { staticClass: "la la-plus" }),
                ]
              ),
              _vm._v(" "),
              !_vm.isLoading
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "hide-mobile",
                        attrs: { labels: _vm.tableLabels, data: _vm.model },
                      },
                      _vm._l(_vm.model, function (row, index) {
                        return _c(
                          "tr",
                          {
                            key: index,
                            staticClass: "m-table__row",
                            class: {
                              "status-pending": row.status === "StatusDisabled",
                            },
                          },
                          [
                            _c("td", { staticClass: "fixed-small" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(index + 1) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(row.email))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(row.fullName))]),
                            _vm._v(" "),
                            _c("td", { staticClass: "m--font-success" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t(row.status)) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.$t(row.emailStatus)))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "ui-link",
                                  {
                                    staticClass: "btn-brand",
                                    attrs: {
                                      to: {
                                        name: "AdminUserDetails",
                                        params: { userId: row.userId },
                                      },
                                      "small-text": "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("TableView")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                : _c("ui-loader", { attrs: { size: 4, center: "" } }),
              _vm._v(" "),
              _vm.model
                ? _vm._l(_vm.model, function (row, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "response-table show-mobile",
                        class: {
                          "status-pending": row.status === "StatusDisabled",
                        },
                      },
                      [
                        _c("responsive-table-tr", {
                          attrs: { title: "TableEmail", value: row.email },
                        }),
                        _vm._v(" "),
                        _c("responsive-table-tr", {
                          attrs: {
                            title: "TableFullName",
                            value: row.fullName,
                          },
                        }),
                        _vm._v(" "),
                        _c("responsive-table-tr", {
                          attrs: { title: "TableStatus", value: row.status },
                        }),
                        _vm._v(" "),
                        _c("responsive-table-tr", {
                          attrs: {
                            title: "TableEmailStatus",
                            value: row.emailStatus,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "m--margin-top-10" },
                          [
                            _c(
                              "ui-link",
                              {
                                staticClass: "btn-brand big-link",
                                attrs: {
                                  to: {
                                    name: "AdminUserDetails",
                                    params: { userId: row.userId },
                                  },
                                  "small-text": "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("TableView")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.filters.page,
                  "items-count": _vm.usersCount,
                  "max-on-page": _vm.filters.pageSize,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        [
          _c("new-user", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.companyId,
                expression: "companyId",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }