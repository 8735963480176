var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-card", { attrs: { dark: "", title: "Know your country" } }, [
    _c("div", { staticClass: "flex-row justify-space-between" }, [
      _c("div", { staticClass: "flex-column" }, [
        _c("p", [_c("i", { staticClass: "icon fa fa-user-friends" })]),
        _vm._v(" "),
        _c("h4", [_vm._v("Population")]),
        _vm._v(" "),
        _c("h4", [_vm._v("2.872 million (2016)")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-column" },
        [
          _c("p", [_c("i", { staticClass: "icon fa fa-clipboard-list" })]),
          _vm._v(" "),
          _c("h4", [_vm._v("Compliance lists")]),
          _vm._v(" "),
          _c("h5", [_vm._v("FATF AML deficient list:")]),
          _vm._v(" "),
          _c("details-rule-item", {
            attrs: {
              name: _vm.deficientList ? "YES" : "NO",
              status: _vm.deficientList,
            },
          }),
          _vm._v(" "),
          _c("h5", [_vm._v("Country related sanctions:")]),
          _vm._v(" "),
          _c("details-rule-item", {
            attrs: {
              name: _vm.sanctions ? "YES" : "NO",
              status: _vm.sanctions,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-column" },
        [
          _c("p", [_c("i", { staticClass: "icon fa fa-asterisk" })]),
          _vm._v(" "),
          _c("h4", { staticClass: "m--font-warning" }, [
            _vm._v("Medium risk:"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Non - Compliance with FATF 40 + 9 Recommendations"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Corruption Index (Transparency International & W.G.I.)"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Failed States Index (Political Issues)(Average Score)"),
          ]),
          _vm._v(" "),
          _c(
            "ui-link",
            {
              attrs: {
                to: { name: "AdminCompanyDetails" },
                small: "",
                "small-text": "",
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("ShowMore")) + "\n      ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }