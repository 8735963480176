<template>
  <div class="identification-page">
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <ui-button slot="tools" class="btn-link" @click="clickCreate">
          {{ $t('AgentLink.List.AddLink') }}
          <i class="la la-plus" />
        </ui-button>

        <template v-if="!isLoading && items">
          <ui-table
            :labels="listLabels"
            :data="items"
            class="export-table"
            empty-text="AgentLink.List.NoItems"
          >
            <template>
              <tr
                v-for="(row, index) in items"
                :key="index"
                class="m-table__row"
              >
                <td :title="row.id" class="fixed-width">
                  <span @click="copyIdentificationId(row.id)">
                    {{ row.id }}
                  </span>
                </td>
                <td>
                  {{ row.email }}
                </td>
                <td :title="row.agent">
                  <a :href="row.agentUrl" target="_blank" rel="noreferrer">
                    {{ row.agentUrl }}
                  </a>
                  <ui-button
                    class="btn-brand"
                    small-text
                    target="_blank"
                    @click="clickCopy(row)"
                  >
                    {{ $t('AgentLink.List.Copy') }}
                  </ui-button>
                </td>
                <td class="td-link">
                  <ui-button
                    class="btn-outline-brand"
                    small-text
                    target="_blank"
                    @click="clickEdit(row)"
                  >
                    {{ $t('AgentLink.List.Edit') }}
                  </ui-button>
                  <ui-button
                    class="btn-brand"
                    small-text
                    target="_blank"
                    @click="clickRemove(row.id)"
                  >
                    {{ $t('AgentLink.List.Delete') }}
                  </ui-button>
                </td>
              </tr>
            </template>
          </ui-table>
        </template>
        <ui-loader v-if="isLoading" :size="4" center />
      </ui-card>
    </ui-container>

    <pop-up full-size>
      <template v-if="popUpType === 'DELETE_CONFIRM'">
        <div>
          <h4>{{ $t('AgentLink.Delete.Title') }}</h4>
          <div class="popup-text">{{ $t('AgentLink.Delete.Description') }}</div>
          <div class="buttons-group">
            <ui-button
              class="col-lg-5 btn-outline-brand"
              small
              @click="confirmDelete"
            >
              {{ $t('AppConfirm') }}
            </ui-button>
            <ui-button
              metal
              small
              class="col-lg-5 btn-brand"
              @click="abortPopUp"
            >
              {{ $t('AppCancel') }}
            </ui-button>
          </div>
        </div>
      </template>
      <template v-else-if="popUpType === 'EDIT'">
        <new-agent-link :item="currentItem" @updated="updated" />
      </template>
      <template v-else-if="popUpType === 'create'">
        <new-agent-link @updated="updated" />
      </template>
    </pop-up>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import NewAgentLink from './NewAgentLink'
import { notificationType } from '@src/components/notification'
import { copy } from '@src/scripts/clipboard'

export default {
  components: {
    NewAgentLink
  },

  data() {
    return {
      popUpType: null,
      deleteId: null,
      currentItem: null,
      listLabels: ['No', 'E-mail', 'Link', 'Actions'],
      isLoading: false,
      items: [],
      itemsCount: 0
    }
  },

  async created() {
    await this.loadProcedure()
  },

  methods: {
    async loadProcedure() {
      this.isLoading = true

      try {
        const agentSettings = await Api.get(`/agent-settings`)

        this.items = agentSettings?.values ?? []
        this.itemsCount = this.items.length
      } catch (error) {
        this.showError(error)
      } finally {
        this.isLoading = false
      }
    },

    updated() {
      this.$closePopUp()
      this.loadProcedure()
    },

    async confirmDelete() {
      try {
        await Api.del(`/agent-settings/${this.deleteId}`)
      } finally {
        this.$closePopUp()
        this.loadProcedure()
      }
    },

    abortPopUp() {
      this.$closePopUp()
    },

    clickCopy(row) {
      copy(row.agentUrl)
      this.$notify({
        title: 'CopiedToClipboard',
        message: row.agentUrl,
        type: notificationType.info
      })
    },

    clickCreate() {
      this.popUpType = 'create'
      this.$openPopUp()
    },

    clickEdit(item) {
      this.currentItem = item
      this.popUpType = 'EDIT'
      this.$openPopUp()
    },

    clickRemove(id) {
      this.deleteId = id
      this.popUpType = 'DELETE_CONFIRM'
      this.$openPopUp()
    }
  }
}
</script>

<style scoped>
.popup-text {
  margin-bottom: 15px;
}

.td-link {
  width: 18rem;
}

.td-link > * {
  margin-bottom: 0.5rem;
  min-width: 6rem;
}

.td-link > *:first-child {
  margin-right: 1rem;
}
</style>
