var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header-box",
        {
          attrs: {
            title: "AppManualCheck",
            filters: _vm.filters,
            "disable-search-on-load": "",
          },
        },
        [
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.documentTypes,
              medium: "",
              placeholder: "DetailsDocumentType",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.documentType,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "documentType", $$v)
              },
              expression: "filters.documentType",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            staticClass: "countries-dropdown",
            attrs: {
              "has-search": "",
              "key-value-dropdown": "",
              options: _vm.countriesOptions,
              medium: "",
              placeholder: "DetailsCountry",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.country,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "country", $$v)
              },
              expression: "filters.country",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "ui-button",
                {
                  staticClass: "m-subheader-search__submit-btn",
                  attrs: { disabled: _vm.disabledProceed },
                  on: { click: _vm.clickProceed },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("AppProceed")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isLoading && !_vm.requestResult
        ? _c(
            "ui-container",
            [
              _vm.clickedProceed
                ? _c(
                    "ui-card",
                    {
                      attrs: {
                        multicolumn: !_vm.isPassport,
                        title: "UploadDocumentPhoto",
                      },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "tools" }, slot: "tools" },
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass: "btn-brand",
                              attrs: { disabled: _vm.disabledVerify },
                              on: { click: _vm.clickVerify },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("AppVerify")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isPassport
                        ? _c(
                            "div",
                            { attrs: { slot: "body-left" }, slot: "body-left" },
                            [
                              _c("p", { staticClass: "photo-title" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("APPFRONT")) +
                                    "\n        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("upload-photo-box", {
                                attrs: {
                                  description: "UploadFrontSideDocument",
                                  photo: _vm.filters.frontSide,
                                },
                                on: {
                                  addPhoto: _vm.addFrontSide,
                                  deletePhoto: function ($event) {
                                    _vm.filters.frontSide = null
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          attrs: {
                            slot: _vm.isPassport ? "body" : "body-right",
                          },
                          slot: _vm.isPassport ? "body" : "body-right",
                        },
                        [
                          _c("p", { staticClass: "photo-title" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("APPBACK")) +
                                "\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("upload-photo-box", {
                            attrs: {
                              description: "UploadBackSideDocument",
                              photo: _vm.filters.backSide,
                            },
                            on: {
                              addPhoto: _vm.addBackSide,
                              deletePhoto: function ($event) {
                                _vm.filters.backSide = null
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : !_vm.isLoading && _vm.requestResult
        ? _c("ui-container", { staticClass: "align-center" }, [
            _c(
              "div",
              [
                _vm.successMessage
                  ? _c(
                      "ui-card",
                      [
                        _c("p", { staticClass: "success-text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(_vm.successMessage)) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ui-button",
                          {
                            staticClass: "btn-brand",
                            on: { click: _vm.viewIdentification },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("TableView")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm.errorMessage
                  ? _c(
                      "ui-card",
                      [
                        _c("p", { staticClass: "error-text" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(_vm.errorMessage)) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ui-button",
                          {
                            staticClass: "btn-danger",
                            on: { click: _vm.clickBack },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("AppGoBack")) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _c(
            "ui-container",
            [_c("ui-loader", { attrs: { size: 4, center: "" } })],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }