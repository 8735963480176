import { runPreflight } from 'twilio-video'
import { logger } from '@src/scripts/logger'

const testTimeout = 5000

export const checkConnection = async (accessToken, defaultProperties) => {
  let preflightTest = null
  let testTimer = null

  const logTrace = (name, extraProperties) => {
    logger.logTrace({
      name,
      properties: {
        ...defaultProperties,
        ...extraProperties
      }
    })

    console.log(name, extraProperties)
  }

  const logError = (name, exception, extraProperties) => {
    logger.logError({
      name,
      exception,
      properties: {
        ...defaultProperties,
        ...extraProperties
      }
    })

    console.log(name, defaultProperties, exception, extraProperties)
  }

  try {
    await new Promise((resolve, reject) => {
      preflightTest = runPreflight(accessToken, {
        duration: testTimeout,
        logLevel: 'debug'
      })

      testTimer = setTimeout(() => {
        preflightTest.stop()
        reject(new Error('ManualTimeout'))
      }, testTimeout + 3000)

      preflightTest.on('progress', (progress) => {
        logTrace('VideoCall.ConnectionTest.Progress', {
          progress
        })
      })

      preflightTest.on('sample', (sample) => {
        logTrace('VideoCall.ConnectionTest.Sample', {
          sample
        })
      })

      preflightTest.on('warning', (name, warning) => {
        logTrace('VideoCall.ConnectionTest.Warning', {
          warningName: name,
          warning
        })
      })

      preflightTest.on('failed', (error) => {
        reject(error)
      })

      preflightTest.on('completed', (report) => {
        logTrace('VideoCall.ConnectionTest.Completed', {
          report,
          duration: {
            total: report.testTiming.duration,
            connect: report.networkTiming.connect?.duration,
            media: report.networkTiming.media?.duration
          }
        })
        resolve()
      })
    })

    logTrace('VideoCall.ConnectionTest.Successful')

    return true
  } catch (error) {
    logError('VideoCall.ConnectionTest.Error', error)

    return false
  } finally {
    clearTimeout(testTimer)
  }
}
