<template>
  <div class="monitoring-events-page">
    <ui-header
      title="App.SubMenu.MonitoringEvents"
      :method="searchMethod"
      :filters="filters"
      :class="{ 'fixed-header': scrollDown }"
      reset-form
      method-get
      no-extra-payload
      :disabled="isLoading"
      :update-revision="updateRevision"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div class="filters-block">
        <div class="display-flex flex-flow-wrap">
          <ui-header-dropdown
            v-model="filters.date"
            :options="options.dateOptions"
            placeholder="HeaderDatePlaceHolder"
            wide
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.fullName"
            type="text"
            placeholder="TableFullName"
            medium
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.personalCode"
            type="text"
            placeholder="DetailsPersonCode"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="filters.eventType"
            all
            has-search
            :options="options.eventsOptions"
            placeholder="DetailsEventType"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="viewFilter"
            all
            :options="options.viewedOptions"
            placeholder="AppViewed"
            medium
            :disabled="isLoading"
            @input="viewFilterChange"
          />
        </div>
      </div>
    </ui-header>
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <template v-if="!isLoading && identifications">
          <ui-table
            :labels="listLabels"
            :data="identifications"
            class="export-table hide-mobile monitoring-table"
            empty-text="TableNoIdentifications"
          >
            <template>
              <tr
                v-for="(row, index) in identifications"
                :key="index"
                class="m-table__row"
              >
                <td class="td-fullname" :title="getFullName(row)">
                  <p>{{ row.firstName }}</p>
                  <p>{{ row.lastName }}</p>
                </td>
                <td class="fixed-width" :title="row.personalCode">
                  {{ row.personalCode }}
                </td>
                <td>{{ row.changeDate | date(true) }}</td>
                <td class="fixed-width" :title="row.eventType">
                  {{ $t(`EventType.${row.eventType}`) }}
                </td>
                <td class="fixed-width" :title="row.previousValue">
                  {{ row.previousValue }}
                </td>
                <td class="fixed-width" :title="row.currentValue">
                  {{ row.currentValue }}
                </td>
                <td class="fixed-width" :title="row.viewed">
                  <ui-toggle
                    prevent
                    :value="row.viewed"
                    :disabled="row.viewed"
                    @change="changeViewed(row.id, row.viewed)"
                  />
                </td>
                <td
                  :title="row.personReferenceId"
                  class="cell-identification-id-desktop"
                >
                  <span
                    class="identification-id-desktop"
                    @click="copyIdentificationId(row.personReferenceId)"
                  >
                    {{ row.personReferenceId }}
                  </span>
                </td>
                <td class="td-link">
                  <ui-button
                    class="btn btn-brand"
                    small
                    @click="stopMonitoring(row)"
                  >
                    {{ $t('AppStopMonitoring') }}
                  </ui-button>
                  <ui-link
                    v-if="row.personReferenceId"
                    :to="{
                      name: 'IdentificationDetails',
                      params: {
                        identificationId: row.personReferenceId.replaceAll(
                          '-',
                          ''
                        )
                      }
                    }"
                    class="btn-brand"
                    small-text
                  >
                    {{ $t('TableView') }}
                  </ui-link>

                  <ui-button
                    v-if="
                      row.eventType === 'OndatoMatchCount' ||
                      row.eventType === 'ExternalMatchCount'
                    "
                    class="btn btn-brand"
                    small
                    @click="checkResults(row)"
                  >
                    {{ $t('AppCheckResults') }}
                  </ui-button>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="identifications && identifications.length > 0">
            <div
              v-for="(row, index) in identifications"
              :key="index"
              class="response-table show-mobile"
            >
              <responsive-table-tr
                title="TableFullName"
                :value="getFullName(row)"
              />
              <responsive-table-tr
                title="DetailsPersonCode"
                :value="row.personalCode"
              />
              <responsive-table-tr
                title="TableChangeDate"
                :value="row.changeDate | date(true)"
              />
              <responsive-table-tr
                class="fixed-width"
                title="DetailsEventType"
                :value="`EventType.${row.eventType}`"
              />
              <responsive-table-tr
                class="fixed-width"
                title="AppPreviosValue"
                :value="row.previousValue"
              />
              <responsive-table-tr
                class="fixed-width"
                title="AppNewValue"
                :value="row.currentValue"
              />
              <responsive-table-tr class="fixed-width" title="AppViewed">
                <ui-toggle
                  prevent
                  :value="row.viewed"
                  :disabled="row.viewed"
                  @change="changeViewed(row.id, row.viewed)"
                />
              </responsive-table-tr>
              <responsive-table-tr
                title="TableIdentificationId"
                :value="row.personReferenceId"
              />
              <div class="m--margin-top-10 margin-1">
                <ui-button class="btn btn-brand" @click="stopMonitoring(row)">
                  {{ $t('AppStopMonitoring') }}
                </ui-button>
                <ui-link
                  v-if="row.personReferenceId"
                  :to="{
                    name: 'IdentificationDetails',
                    params: {
                      identificationId: row.personReferenceId.replaceAll(
                        '-',
                        ''
                      )
                    }
                  }"
                  class="btn-brand big-link"
                  small-text
                >
                  {{ $t('TableView') }}
                </ui-link>
                <ui-button
                  v-if="
                    row.eventType === 'OndatoMatchCount' ||
                    row.eventType === 'ExternalMatchCount'
                  "
                  class="btn btn-brand"
                  small
                  @click="checkResults(row)"
                >
                  {{ $t('AppCheckResults') }}
                </ui-button>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('TableNoIdentifications') }}
          </div>
        </template>
        <ui-loader v-if="isLoading" :size="4" center />
        <ui-page-selector
          :active-page="filters.page"
          :items-count="itemsCount"
          :max-on-page="filters.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
      <pop-up>
        <monitoring-setup
          v-if="popUpType === 'monitoring'"
          :data="monitoringData"
          @change="updateData"
        />

        <check-results v-if="popUpType === 'results'" :id="resultId" />
      </pop-up>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { API } from '@src/scripts/enums'
import MonitoringSetup from '@src/components/partials/MonitoringSetup'

import {
  createDateFilters,
  dateToString,
  mapOptions
} from '@src/scripts/helpers'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import { notificationType } from '@src/components/notification'
import { copy } from '@src/scripts/clipboard'
import CheckResults from '@src/components/pages/identifications/CheckResults'

const filters = () => ({
  date: null,
  fullName: null,
  personalCode: null,
  page: null,
  pageSize: 100,
  viewed: null,
  eventType: null
})

const options = () => ({
  dateOptions: null,
  viewedOptions: mapOptions(['App.Reviewed', 'App.NotReviewed']),
  eventsOptions: mapOptions(
    [
      'Name',
      'Surname',
      'DocumentState',
      'OndatoMatchCount',
      'Death',
      'LostAndStolen',
      'ExternalMatchCount',
      'DriversLicenseValidity',
      'DriversLicenseStatus',
      'DriversLicenseExpiration'
    ],
    [
      'EventType.Name',
      'EventType.Surname',
      'EventType.DocumentState',
      'EventType.OndatoMatchCount',
      'EventType.Death',
      'EventType.LostAndStolen',
      'EventType.ExternalMatchCount',
      'EventType.DriversLicenseValidity',
      'EventType.DriversLicenseStatus',
      'EventType.DriversLicenseExpiration'
    ]
  )
})

export default {
  components: {
    CheckResults,
    ResponsiveTableTr,
    MonitoringSetup
  },

  filters: {
    date: dateToString
  },

  data() {
    return {
      resultId: null,
      popUpType: null,
      scrollDown: false,
      lastScrollTop: 180,
      isReady: false,
      isLoading: true,
      viewFilter: false,
      identifications: null,
      itemsCount: 0,
      searchMethod: API.NATURAL_MONITORNG_EVENTS,
      filters: filters(),
      options: options(),
      tableLabels: [
        'TableFullName',
        'DetailsPersonCode',
        'TableChangeDate',
        'DetailsEventType',
        'AppPreviosValue',
        'AppNewValue',
        'AppViewed',
        'TableIdentificationId'
      ],
      monitoringData: {
        identificationId: null,
        monitoringStatus: null
      },
      updateRevision: false
    }
  },

  computed: {
    listLabels() {
      const list = [...this.tableLabels]
      list.push(null)
      return list
    }
  },

  async created() {
    const { createFilters } = this
    await createFilters()
    this.isReady = true
  },

  methods: {
    updateData() {
      this.updateRevision = true
    },

    copyIdentificationId(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },

    checkResults(row) {
      this.popUpType = 'results'
      this.resultId = row.id
      this.$openPopUp()
    },

    stopMonitoring(row) {
      const { monitoringData } = this
      this.$set(monitoringData, 'monitoringStatus', true)
      this.$set(monitoringData, 'identificationId', row.personReferenceId)
      this.$set(monitoringData, 'monitoringId', row.personId)
      this.popUpType = 'monitoring'
      this.$openPopUp()
    },

    createFilters() {
      const { filters, options } = this
      options.dateOptions = createDateFilters()
      if (options.dateOptions.length) {
        filters.date = options.dateOptions[0].key
      }
    },

    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      const { items, total } = data
      this.identifications = items
      this.count = null
      this.itemsCount = total
      this.isLoading = false
      this.updateRevision = false
    },

    getFullName(item) {
      const { firstName, lastName } = item
      return [firstName, lastName].filter((value) => value).join(' ')
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    viewFilterChange(value) {
      switch (value) {
        case 'App.Reviewed':
          this.filters.viewed = true
          break
        case 'App.NotReviewed':
          this.filters.viewed = false
          break
        default:
          this.filters.viewed = null
      }
    },

    async changeViewed(id) {
      try {
        await Api.patch(`${API.NATURAL_MONITORNG_EVENTS}/${id}/reviewed`)
        this.identifications = this.identifications.map((rev) => {
          if (rev.id === id) {
            return {
              ...rev,
              viewed: true
            }
          }
          return rev
        })
      } catch (error) {
        console.log(error)
        this.showError()
      }
    }
  }
}
</script>

<style scoped>
.monitoring-table tr td {
  padding: 0.5rem 1.2rem;
}

.btn-brand {
  width: 100%;
  font-size: 1rem;
}

/deep/ .content {
  width: 900px;
  max-width: 900px;
}

.td-fullname > p {
  margin: 0;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-1 {
  margin: 0 1rem;
}

.hidden-button {
  display: none;
}

.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.cell-identification-id-desktop {
  max-width: 12vw;
}

.identification-id-desktop {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-header {
  margin-bottom: 4rem;
}

.flex-flow-wrap {
  flex-flow: row wrap;
}

.all-search-inputs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin-top: 1rem;
}

.all-search-inputs > * {
  display: inline-block;
  width: 200px;
}

.td-link {
  width: 18rem;
}

.td-link > * {
  margin-bottom: 0.5rem;
  min-width: 6rem;
}

.td-link > *:first-child {
  margin-right: 1rem;
}

.fixed-header .flex-flow-wrap {
  flex-flow: row nowrap;
}

.fixed-header .flex-flow-wrap > * {
  width: 210px;
}

.fullsearh-link {
  color: var(--white);
  margin-right: 15px;
  cursor: pointer;
}

.fullsearh-link:hover {
  text-decoration: underline;
}

.filter-small-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.fixed-header .filter-small-block {
  position: absolute;
  bottom: -2.75rem;
  left: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.fixed-header .fullsearh-link {
  left: 2rem;
  right: auto;
  color: var(--dark-br);
  bottom: 0.75rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

.fixed-header .all-search-inputs > *::placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-webkit-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-moz-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-ms-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-moz-placeholder {
  color: var(--dark-br);
}

.filters-block {
  width: 100%;
}

.dropdown-box > .dropdown-toggle.btn-light:hover::placeholder {
  color: #282a3c;
}

@media all and (max-width: 992px) {
  .btn-brand {
    width: auto;
    font-size: 1rem;
  }

  .flex-flow-wrap .filter-small-block {
    display: block;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
  }

  .filter-small-block > * {
    display: inline-block;
  }

  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }

  .all-search-inputs {
    flex-flow: row wrap;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    max-width: 45%;
    width: 45%;
  }
}

@media (max-width: 678px) {
  .all-search-inputs {
    flex-direction: column;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
<style>
.identification-page .filter-small-block .m-checkbox--state-brand {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
  width: 400px;
}

.header-content .filters-block .m-input-icon.m-input-icon--wide {
  width: 200px;
}

.fixed-header .m-subheader-search__title,
.fixed-header .ui-toggle--header {
  visibility: hidden;
}

.fixed-header .header-content {
  position: fixed;
  top: 3.75rem;
  z-index: 50;
  left: 110px;
  background: var(--white);
  text-align: right;
  flex-wrap: nowrap;
  width: calc(100% - 110px);
  padding: 1.75rem 80px 2rem 30px;
  height: auto;
  box-shadow: 2px 3px 3px 0 var(--light-br-h-gray);
}

.fixed-header .header-content .filters-list .flex-flow-wrap > * {
  width: 200px;
}

.fixed-header .header-content .filters-block {
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}

.fixed-header .header-content .filters-list {
  flex-wrap: nowrap;
}

.fixed-header .header-content .filters-list label {
  display: none;
}

.fixed-header .header-content .filters-list .ui-dropdown button,
.fixed-header .header-content .filters-list input {
  border-color: var(--dark-br);
  color: var(--dark-br);
  height: 42px;
}

.fixed-header .header-content .filters-list textarea {
  border-color: var(--dark-br);
  color: var(--dark-br);
}

.fixed-header .header-content .filters-list input::placeholder,
.fixed-header .header-content .filters-list textarea::placeholder {
  color: var(--dark-br);
}

.fixed-header .ui-button.m-subheader-search__submit-btn {
  border: 1px solid var(--brand);
  height: 42px;
}

@media (max-width: 1024px) {
  .fixed-header .header-content {
    visibility: hidden;
  }
}

@media (max-width: 992px) {
  .all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .identification-page .m-subheader-search .m-input-icon {
    margin-right: 0;
  }
}
</style>

<style>
@media (max-width: 1350px) {
  .monitoring-table.ui-table td.fixed-width[data-v-0b96ff8f] {
    max-width: 10rem;
  }
}

@media (max-width: 1100px) {
  .monitoring-table.ui-table td.fixed-width[data-v-0b96ff8f] {
    max-width: 7.5rem;
  }
}
</style>
