var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-frame",
    [
      _c("header-tabs", {
        attrs: {
          slot: "header",
          labels: _vm.labels,
          loading: !_vm.isReady || _vm.isLoading,
        },
        slot: "header",
        model: {
          value: _vm.selectedTab,
          callback: function ($$v) {
            _vm.selectedTab = $$v
          },
          expression: "selectedTab",
        },
      }),
      _vm._v(" "),
      _c("ui-card", [
        _c(
          "div",
          [
            !_vm.isReady
              ? _c("ui-loader", { attrs: { center: "" } })
              : _vm.selectedTab === 0
              ? _c("company", {
                  attrs: { data: _vm.companyDetails, loading: _vm.isLoading },
                  on: { update: _vm.loadCompany },
                })
              : _c("representative", {
                  key: _vm.selectedRepresentative.id,
                  attrs: {
                    data: _vm.selectedRepresentative,
                    loading: _vm.isLoading,
                  },
                  on: { update: _vm.updateRepresentatives },
                }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }