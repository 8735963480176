import {
  requiredValidator,
  minLengthValidator,
  maxLengthValidator,
  dateValidator,
  includesValidator
} from '@src/scripts/validators'
import dayjs from 'dayjs'

export const DefaultDocumentData = (options) => {
  options = options || {}

  const configuration = {
    firstName: {
      label: 'DetailsFirstName',
      minLength: 1,
      maxLength: 60,
      oneLine: true,
      validations: [required, minLength, maxLength]
    },
    lastName: {
      label: 'DetailsLastName',
      minLength: 1,
      maxLength: 60,
      oneLine: true,
      validations: [required, minLength, maxLength]
    },
    gender: {
      label: 'DetailsGender',
      placeholder: 'DetailsGender',
      keyValueDropdown: true,
      translate: true,
      options: options.genderOptions
    },
    dateOfBirth: {
      label: 'DetailsBirthdate',
      placeholder: 'yyyy-mm-dd',
      maxLength: 10,
      datePicker: true,
      validations: [required, date, birthday]
    },
    country: {
      label: 'DetailsCountry',
      keyValueDropdown: true,
      hasSearch: true,
      translate: true,
      options: options.countryOptions,
      oneLine: true,
      maxLength: 3,
      valueChange: options.valueCountryChange,
      validations: [required],
      errorName: 'ErrorUnknownCountry'
    },
    state: {
      label: 'DetailsState',
      keyValueDropdown: true,
      hasSearch: true,
      translate: true,
      showIf: options.showStateIf,
      inputValue: options.inputStateValue,
      options: options.stateOptionList,
      validations: [requiredState]
    },
    address: {
      label: 'DetailsAddress',
      hasSearch: true,
      showIf: options.showAddressIf
    },
    nationality: {
      label: 'DetailsNationality',
      keyValueDropdown: true,
      hasSearch: true,
      translate: true,
      options: options.nationalityOptions,
      oneLine: true,
      maxLength: 3,
      validations: [required, includes],
      errorName: 'ErrorUnknownNationality',
      notRequiredIfCountry: ['USA'],
      notRequiredIfDocument: ['DRIVER_LICENSE']
    },
    personCode: {
      label: 'DetailsPersonCode',
      minLength: 5,
      maxLength: 20,
      oneLine: true,
      validations: [minLength, maxLength]
    },
    documentNumber: {
      label: 'DetailsDocumentNumber',
      minLength: 3,
      maxLength: 20,
      oneLine: true,
      validations: [required, minLength, maxLength]
    },
    documentType: {
      label: 'DetailsDocumentType',
      placeholder: 'DetailsDocumentType',
      translate: true,
      options: options.documentOptions,
      valueChange: options.valueDocumentTypeChange,
      validations: [required]
    },
    category: {
      label: 'DocumentData.Category',
      minLength: 1,
      maxLength: 50,
      oneLine: true,
      showIf: options.showCategoryIf
    },
    dateOfIssue: {
      label: 'DetailsDateOfIssue',
      placeholder: 'yyyy-mm-dd',
      maxLength: 10,
      datePicker: true,
      validations: [date, dateOfIssue]
    },
    dateOfExpiration: {
      label: 'DetailsExpireDate',
      placeholder: 'yyyy-mm-dd',
      maxLength: 10,
      datePicker: true,
      validations: [date, dateOfExpiration]
    }
  }

  if (IsProduction) {
    delete configuration.category
  }

  return configuration
}

function required(item, all) {
  if (
    (item.requiredIfDocument &&
      !item.requiredIfDocument.includes(all.documentType.value)) ||
    item.notRequiredIfDocument?.includes(all.documentType.value) ||
    item.notRequiredIfCountry?.includes(all.country.value)
  ) {
    return null
  }

  return requiredValidator(item.value)
}

function requiredState(item, all) {
  const documentType = all.documentType.value
  const country = all.country.value

  if (
    (['ID_CARD', 'DRIVER_LICENSE'].includes(documentType) &&
      ['USA', 'CAN'].includes(country)) ||
    (documentType === 'DRIVER_LICENSE' && country === 'MEX')
  ) {
    return requiredValidator(item.value)
  }

  return null
}

function minLength(item) {
  return minLengthValidator(item.value, item.minLength)
}

function maxLength(item) {
  return maxLengthValidator(item.value, item.maxLength)
}

function date(item) {
  return dateValidator(item.value)
}

function birthday(item, all) {
  const dateOfIssue = all.dateOfIssue.value
  if (dayjs(dateOfIssue, 'YYYY-MM-DD').isValid()) {
    if (
      dayjs(item.value, 'YYYY-MM-DD').isAfter(dayjs(dateOfIssue, 'YYYY-MM-DD'))
    ) {
      return 'ErrorFieldBadDate'
    }

    return null
  }

  if (dayjs(item.value, 'YYYY-MM-DD').isAfter(dayjs())) {
    return 'ErrorFieldBadDate'
  }

  return null
}

function includes(item) {
  const options = item.options?.map((item) => item.key) ?? []
  return includesValidator(options, item.value, item.errorName)
}

function dateOfIssue(item, all) {
  const dateOfBirth = all.dateOfBirth.value
  const dateOfExpiration = all.dateOfExpiration.value
  const value = item.value

  if (!dayjs(value, 'YYYY-MM-DD').isValid()) {
    return null
  }

  if (dayjs(dateOfBirth, 'YYYY-MM-DD').isValid()) {
    if (dayjs(value, 'YYYY-MM-DD').isBefore(dayjs(dateOfBirth))) {
      return 'ErrorFieldBadDate'
    }
  }

  if (dayjs(dateOfExpiration, 'YYYY-MM-DD').isValid()) {
    if (dayjs(value, 'YYYY-MM-DD').isAfter(dayjs(dateOfExpiration))) {
      return 'ErrorFieldBadDate'
    }
  }

  if (dayjs(value, 'YYYY-MM-DD').isAfter(dayjs())) {
    return 'ErrorFieldBadDate'
  }

  return null
}

function dateOfExpiration(item, all) {
  const value = item.value
  const dateOfIssue = all.dateOfIssue.value
  const dateOfBirth = all.dateOfBirth.value

  if (!dayjs(value, 'YYYY-MM-DD').isValid()) {
    return null
  }

  if (dayjs(dateOfIssue, 'YYYY-MM-DD').isValid()) {
    if (dayjs(value, 'YYYY-MM-DD').isBefore(dayjs(dateOfIssue))) {
      return 'ErrorFieldBadDate'
    }
  }

  if (dayjs(dateOfBirth, 'YYYY-MM-DD').isValid()) {
    if (dayjs(value, 'YYYY-MM-DD').isBefore(dayjs(dateOfBirth))) {
      return 'ErrorFieldBadDate'
    }
  }

  return null
}
