export default {
  name: 'click-outside',
  priority: 700,

  bind(el, binding, vnode) {
    vnode.event = (event) => close(vnode, event)
    el.addEventListener('click', stopPropagation)
    document.body.addEventListener('click', vnode.event)
  },

  unbind(el, binding, vnode) {
    el.removeEventListener('click', stopPropagation)
    document.body.removeEventListener('click', vnode.event)
  }
}

function stopPropagation(event) {
  event.stopPropagation()
}

function close(vnode, event) {
  const handler = vnode.data && vnode.data.on && vnode.data.on.close
  if (handler) {
    handler.fns(event)
  }
}
