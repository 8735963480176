var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c("div", [_c("ui-loader", { attrs: { size: 2.5, center: "" } })], 1)
      : _vm.data
      ? _c(
          "div",
          {
            staticClass: "activity-details",
            class: { "single-item": _vm.data.length === 1 },
          },
          _vm._l(_vm.data, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "logs-item" },
              _vm._l(item, function (content, key) {
                return _c("span", { key: key }, [
                  _vm._v("\n        " + _vm._s(content) + "\n      "),
                ])
              }),
              0
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }