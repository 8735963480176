<template>
  <div class="row align-items-center line-bar-chart">
    <canvas :id="_uid" class="chart-area" />
  </div>
</template>

<script>
import Chart from 'chart.js'
import { chartTypeEnum } from '@src/scripts/enums'

const barLayoutDefaults = () => ({
  padding: {
    left: 5,
    right: 5,
    top: 5,
    bottom: 5
  }
})

const barTooltipsDefaults = () => ({
  mode: 'index',
  intersect: false,
  backgroundColor: 'rgba(215,215,215,0.9)',
  titleFontColor: '#000',
  bodyFontColor: '#000'
})

const barScalesDefaults = () => ({
  xAxes: [
    {
      ticks: {
        beginAtZero: true
      }
    }
  ],
  yAxes: [
    {
      ticks: {
        beginAtZero: true
      }
    }
  ]
})

export default {
  props: {
    type: { type: String, default: undefined },
    data: { type: Object, default: undefined },
    hideBottomLabel: Boolean
  },

  data() {
    return {
      chart: null,
      colorPalette: {
        background: [
          'rgb(52,191,163)',
          'rgb(54,163,247)',
          'rgb(244,81,108)',
          'rgb(225,112,85)',
          'rgb(253,203,110)'
        ],
        border: [
          'rgba(52,191,163,0.4)',
          'rgba(54,163,247,0.4)',
          'rgba(244,81,108,0.4)',
          'rgba(225,112,85,0.4)',
          'rgba(253,203,110,0.4)'
        ]
      }
    }
  },

  computed: {
    chartData() {
      const { colorPalette, data } = this
      const dataModel = {
        datasets: [],
        labels: []
      }
      let maxCount = 0
      for (const index in data.datasets) {
        maxCount = data.datasets[index].values.length
        dataModel.datasets.push({
          label: data.datasets[index].label
            ? this.$t(data.datasets[index].label)
            : null,
          data: data.datasets[index].values,
          backgroundColor: colorPalette.background[index],
          hoverBackgroundColor: colorPalette.background[index],
          hoverBorderColor: colorPalette.border[index],
          hoverBorderWidth: 8
        })
      }
      for (let i = 0; i < maxCount; i++) {
        const label = data.labels[i]
        dataModel.labels.push((label && this.$t(label)) || '--')
      }
      return dataModel
    },

    chartOptions() {
      const {
        data: { chartType },
        hideBottomLabel
      } = this
      const options = {
        responsive: true,
        aspectRatio: 4,
        legend: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        layout: barLayoutDefaults(),
        tooltips: barTooltipsDefaults(),
        scales: barScalesDefaults()
      }
      if (chartType === chartTypeEnum.stacked) {
        options.scales.xAxes[0].stacked = true
        options.scales.yAxes[0].stacked = true
      }
      if (hideBottomLabel) {
        options.scales.xAxes[0].display = false
      }
      return options
    }
  },

  watch: {
    chartData() {
      if (this.chart) {
        this.chart.data = this.chartData
        this.chart.update()
      }
    }
  },

  mounted() {
    this.createChart()
  },

  methods: {
    createChart() {
      const { _uid } = this
      const chart = document.getElementById(_uid)
      if (chart) {
        const { type, chartData, chartOptions } = this
        const chartContext = chart.getContext('2d')
        this.chart = new Chart(chartContext, {
          type,
          data: chartData,
          options: chartOptions
        })
      }
    }
  }
}
</script>

<style scoped>
.chart-area {
  max-height: 400px;
  max-width: 1600px;
  margin: auto;
}
</style>
