var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ui-header", { attrs: { title: "KycSettingHeader" } }),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { icon: "la la-institution", title: "KYC flow" } },
            [
              _c(
                "tabs-group",
                {
                  attrs: {
                    slot: "body",
                    "tab-index": _vm.tabIndex,
                    labels: _vm.labels,
                  },
                  on: { change: _vm.tabChanged },
                  slot: "body",
                },
                _vm._l(_vm.revision, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _vm.labels[_vm.tabIndex] === item.label
                        ? _c("kyc-settings", {
                            attrs: { data: item, editable: _vm.editEnabled },
                            on: {
                              change: _vm.changeSetting,
                              submit: function ($event) {
                                return _vm.saveChanges(index, $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }