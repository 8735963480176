var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "display-flex" },
    [
      _c(
        "ui-widget",
        {
          attrs: {
            title: "KybTimeTitle",
            "title-value": _vm.data.titleValue ? _vm.data.titleValue : "-",
          },
        },
        [
          _c("details-item", {
            attrs: {
              label: "DetailsCompanyCode",
              value: _vm.data.code ? _vm.data.code : "-",
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsCompanyStatus",
              value: _vm.data.status ? _vm.data.status : "-",
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsVatNumber",
              value: _vm.data.vatNumber ? _vm.data.vatNumber : "-",
            },
          }),
          _vm._v(" "),
          _vm.data.manager
            ? _c(
                "details-item",
                {
                  attrs: {
                    label: "DetailsCompanyHead",
                    value: _vm.data.manager ? _vm.data.manager : "-",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "SanctionsSearch",
                              query: _vm.sanctionsSearchQuery,
                            },
                            target: "_blank",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.checkNoSanctionSearchPermission($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("DetailsScreenSanctions")) +
                              "\n          "
                          ),
                          _c("i", {
                            staticClass: "fas fa-external-link-square-alt",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsAddress",
              value: _vm.data.address ? _vm.data.address : "-",
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsPhoneNumber",
              value: _vm.data.phone ? _vm.data.phone : "-",
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsEmail",
              value: _vm.data.email ? _vm.data.email : "-",
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsWeb",
              value: _vm.data.url ? _vm.data.url : "-",
              hyperlink: !!_vm.data.url,
            },
          }),
          _vm._v(" "),
          _c("details-item", {
            attrs: {
              label: "DetailsCompanyIndustry",
              value: _vm.data.industry ? _vm.data.industry : "-",
            },
          }),
          _vm._v(" "),
          _vm.data.initialDate || _vm.data.added
            ? _c("details-item", {
                attrs: {
                  label: "KybStartedMonitoring",
                  value: _vm.data.initialDate || _vm.localDate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-block" },
        [
          _c(
            "ui-widget",
            {
              staticClass: "vertical-group",
              attrs: { title: "KybAddressTitle" },
            },
            [
              _c("company-address-map", {
                attrs: { address: _vm.data.address },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.data.numberOfEmployees ||
          _vm.data.numberOfEmployeesBefore20200301 ||
          _vm.averageSalary
            ? _c(
                "ui-widget",
                { attrs: { title: "KybHumanResourcesTitle" } },
                [
                  _vm.data.numberOfEmployees
                    ? _c("resources-card", {
                        attrs: {
                          icon: "la la-user",
                          title: "DetailsEmployeesTitle",
                          description: _vm.data.numberOfEmployees,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.numberOfEmployeesBefore20200301
                    ? _c("resources-card", {
                        attrs: {
                          icon: "la la-user",
                          title: "DetailsEmployees20200301",
                          description: _vm.data.numberOfEmployeesBefore20200301,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.averageSalary
                    ? _c("resources-card", {
                        attrs: {
                          icon: "la la-eur",
                          title: "DetailsAverageSalaryTitle",
                          description: _vm.averageSalary,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }