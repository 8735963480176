<template>
  <div class="group">
    <ui-loader v-if="loading" center />
    <div v-else-if="error" class="error">
      <b>{{ $t('RegistryHubCourts.loadError') }}</b>
    </div>
    <div v-else-if="noCourts">
      <b>{{ $t('RegistryHubCourts.noCourts') }}</b>
    </div>
    <div v-else-if="courts">
      <b>{{ $t('RegistryHubCourts.courts') }}:</b>
      <div class="group">
        <div v-for="(value, key) in counts" :key="key">
          <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
        </div>
      </div>
      <div v-for="({ caseParties, ...item }, index) in plaintiff" :key="index">
        <b>{{ $t('RegistryHubCourts.plaintiff') }}:</b>
        <div class="group">
          <div v-for="(value, key) in item" :key="key">
            <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
          </div>
          <b>{{ $t('RegistryHubCourts.caseParties') }}:</b>
          <div
            v-for="(party, partyIndex) in caseParties"
            :key="partyIndex"
            class="group"
          >
            <div v-for="(value, key) in party" :key="key">
              <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="({ caseParties, ...item }, index) in defendant"
        :key="index"
        class="group"
      >
        <b>{{ $t('RegistryHubCourts.defendant') }}:</b>
        <div class="group">
          <div v-for="(value, key) in item" :key="key">
            <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
          </div>
          <b>{{ $t('RegistryHubCourts.caseParties') }}:</b>
          <div
            v-for="(party, partyIndex) in caseParties"
            :key="partyIndex"
            class="group"
          >
            <div v-for="(value, key) in party" :key="key">
              <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="({ caseParties, ...item }, index) in thirdParty"
        :key="index"
        class="group"
      >
        <b>{{ $t('RegistryHubCourts.thirdParty') }}:</b>
        <div class="group">
          <div v-for="(value, key) in item" :key="key">
            <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
          </div>
          <b>{{ $t('RegistryHubCourts.caseParties') }}:</b>
          <div
            v-for="(party, partyIndex) in caseParties"
            :key="partyIndex"
            class="group"
          >
            <div v-for="(value, key) in party" :key="key">
              <b>{{ $t(`RegistryHubCourts.${key}`) }}:</b> {{ value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { dateToString } from '@src/scripts/helpers'

export default {
  props: {
    companyCode: { type: String, required: true }
  },
  data() {
    return {
      courts: null,
      loading: false,
      error: null
    }
  },
  computed: {
    counts() {
      return this.courts?.counts
    },
    plaintiff() {
      return this.courts?.plaintiff?.map(this.mapCourtData)
    },
    defendant() {
      return this.courts?.defendant?.map(this.mapCourtData)
    },
    thirdParty() {
      return this.courts?.thirdParty?.map(this.mapCourtData)
    },
    noCourts() {
      return (
        !this.plaintiff?.length &&
        !this.defendant?.length &&
        !this.thirdParty?.length
      )
    }
  },
  watch: {
    companyCode: {
      handler() {
        this.getCourtData()
      },
      immediate: true
    }
  },
  methods: {
    mapCourtData({ courtDate, firstHearing, lastHearing, ...court }) {
      return {
        ...court,
        courtDate: dateToString(courtDate),
        firstHearing: dateToString(firstHearing),
        lastHearing: dateToString(lastHearing)
      }
    },
    async getCourtData() {
      this.loading = true
      this.courts = null
      this.error = null
      try {
        const data = await Api.get(
          `/data-hub/companies/${this.companyCode}/courts`
        )
        this.courts = data?.data?.courts
      } catch (error) {
        this.error = error.message
        this.$notify({
          title: 'RegistryHubCourts.loadError',
          message: error.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.group {
  margin: 1rem 0 1rem 1rem;
}
.error {
  color: var(--danger);
}
</style>
