var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "details-item",
      class: { "with-values": _vm.values, "inner-page-style": _vm.isInner },
    },
    [
      _c("span", { staticClass: "details-label" }, [
        _vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.values
        ? _c(
            "div",
            {
              staticClass: "details-value",
              class: { "no-style-tags": _vm.noStyleTabs },
            },
            [
              _vm.ulList
                ? _c(
                    "ul",
                    { staticClass: "list-in-details" },
                    _vm._l(_vm.values, function (value, index) {
                      return _c("li", { key: index }, [
                        _vm._v(
                          "\n        " + _vm._s(_vm.$t(value)) + "\n      "
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.ulList
                ? _vm._l(_vm.values, function (value, index) {
                    return _c("span", { key: index, staticClass: "tag" }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t(value)) + "\n        "
                      ),
                      _vm.noStyleTabs &&
                      index != Object.keys(_vm.values).length - 1
                        ? _c("span", [_vm._v("\n          ,\n        ")])
                        : _vm._e(),
                    ])
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }