<template>
  <header-form v-if="isReady" @submit="submit">
    <template slot="inputs">
      <ui-header-dropdown
        v-model.trim="filters.date"
        :options="options.dates"
        :placeholder="$t('Kyb.Search.Date')"
        :disabled="isLoading"
        wide
      />
      <ui-header-input
        v-model.trim="filters.companyName"
        :placeholder="$t('Kyb.Search.CompanyName')"
        icon="la-book"
        :disabled="isLoading"
        medium
      />
      <ui-header-input
        v-model.trim="filters.companyCode"
        :placeholder="$t('Kyb.Search.CompanyCode')"
        icon="la-industry"
        :disabled="isLoading"
        medium
      />
      <ui-header-dropdown
        v-model.trim="filters.reviewStatus"
        :options="options.reviewStatuses"
        :placeholder="$t('Kyb.Search.ReviewStatus')"
        :disabled="isLoading"
      />
      <ui-header-input
        v-model.trim="filters.identificationId"
        :placeholder="$t('Kyb.Search.IdentificationId')"
        icon="la-tag"
        :disabled="isLoading"
        wide
      />
    </template>
    <ui-button
      slot="actions"
      type="submit"
      class="m-subheader-search__submit-btn"
      :disabled="isLoading"
    >
      {{ $t('App.Search') }}
    </ui-button>
  </header-form>
</template>

<script>
import HeaderForm from '@src/components/partials/refactor/layouts/HeaderForm'
import mutations from '@src/store/mutations'
import actions from '@src/store/actions'
import { kybReviewStatuses, searchStatuses } from '@src/scripts/enums'
import { mapState, mapMutations, mapActions } from 'vuex'
import { createDateFilters } from '@src/scripts/helpers'

export default {
  components: {
    HeaderForm
  },

  data() {
    return {
      isReady: false,
      filters: {
        date: null,
        companyName: null,
        companyCode: null,
        reviewStatus: null,
        identificationId: null
      },
      options: {
        dates: [],
        reviewStatuses: []
      }
    }
  },

  computed: mapState({
    isLoading: (state) => state.kyb.search.state === searchStatuses.loading,
    page: (state) => state.kyb.search.page,
    limit: (state) => state.kyb.search.limit
  }),

  watch: {
    page: function () {
      this.search()
    },

    isLoading: function (value) {
      if (!value) return

      if (!this.isReady) this.isReady = true
      this.search()
    }
  },

  created() {
    this.setOptions()
    this.setDefaults()
    this.setData()
    this.setKybSearchLoading()
  },

  methods: {
    ...mapActions([actions.searchKybIdentifications]),
    ...mapMutations([
      mutations.setKybSearchLoading,
      mutations.setKybSearchPage,
      mutations.setKybSearchPayload
    ]),

    setOptions() {
      const { options } = this
      options.dates = createDateFilters()

      options.reviewStatuses = Object.values(kybReviewStatuses).map(
        (value) => ({
          key: value,
          value: this.$t(`Kyb.ReviewStatuses.${value}`)
        })
      )
    },

    setDefaults() {
      const { filters, options } = this

      if (options.dates.length) {
        filters.date = options.dates[0].key
      }

      if (options.reviewStatuses.length) {
        filters.reviewStatus = options.reviewStatuses[0].key
      }
    },

    setData() {
      const { filters } = this
      const { from, to, name, code, status, id, page } = this.$route.query ?? {}

      if (from && to) filters.date = [from, to]
      if (name) filters.companyName = name
      if (code) filters.companyCode = code
      if (status) filters.reviewStatus = status
      if (id) filters.identificationId = id

      this.setKybSearchPage(page)
      this.setKybSearchPayload(filters)
    },

    search() {
      if (!this.isReady) return

      const body = this.createBody()
      this.setKybSearchPayload(this.filters)
      this.searchKybIdentifications(body)
    },

    submit() {
      const oldQuery = this.$route.query ?? {}
      const newQuery = this.createQuery()

      if (JSON.stringify(oldQuery) === JSON.stringify(newQuery)) {
        this.setKybSearchLoading()
      } else {
        this.$router.push({ query: newQuery })
      }
    },

    createBody() {
      const { filters, page, limit } = this

      const body = {
        limit,
        offset: +page * limit,
        orderBy: 'CreatedUtc',
        orderDirection: 'Desc',
        createdUtc: {
          from: new Date(`${filters.date?.[0]}T00:00`).toISOString(),
          to: new Date(`${filters.date?.[1]}T24:00`).toISOString()
        }
      }

      if (filters.companyName || filters.companyCode) {
        body.legalEntity = {}

        if (filters.companyName) {
          body.legalEntity.names = [filters.companyName]
        }
        if (filters.companyCode) {
          body.legalEntity.codes = [filters.companyCode]
        }
      }

      if (
        filters.reviewStatus &&
        filters.reviewStatus !== kybReviewStatuses.all
      ) {
        body.statuses = [filters.reviewStatus]
      }

      if (filters.identificationId) {
        body.ids = [filters.identificationId]
      }

      return body
    },

    createQuery() {
      const { filters, options, page } = this
      const query = {}

      if (
        filters.date[0] !== options.dates[0].key[0] ||
        filters.date[1] !== options.dates[0].key[1]
      ) {
        query.from = filters.date[0]
        query.to = filters.date[1]
      }

      if (filters.companyName) {
        query.name = filters.companyName
      }

      if (filters.companyCode) {
        query.code = filters.companyCode
      }

      if (
        filters.reviewStatus &&
        filters.reviewStatus !== kybReviewStatuses.all
      ) {
        query.status = filters.reviewStatus
      }

      if (filters.identificationId) {
        query.id = filters.identificationId
      }

      if (page && page !== '0') {
        query.page = page
      }

      return query
    }
  }
}
</script>
