var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-notification" },
    [
      _c(
        "transition-group",
        {
          staticClass: "notification-box",
          attrs: { name: "show", tag: "div" },
        },
        _vm._l(_vm.data.notificatios, function (item) {
          return _c("div", { key: item.id, staticClass: "notification" }, [
            _c("div", { staticClass: "content", class: item.type }, [
              _c("a", {
                staticClass: "close",
                on: {
                  click: function ($event) {
                    return _vm.remove(item)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "title" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t(item.title)) + "\n        "
                ),
              ]),
              _vm._v(" "),
              typeof item.message === "object" ||
              typeof item.message === "array"
                ? _c(
                    "ul",
                    { staticClass: "message" },
                    _vm._l(item.message, function (message, index) {
                      return _c("li", { key: index }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t(message, item.messageData)) +
                            ";\n          "
                        ),
                      ])
                    }),
                    0
                  )
                : _c("span", { staticClass: "message" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t(item.message, item.messageData)) +
                        "\n        "
                    ),
                  ]),
              _vm._v(" "),
              item.type === _vm.notificationType.warning
                ? _c(
                    "div",
                    { staticClass: "warning-buttons" },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "btn-brand",
                          attrs: { low: "", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickCancel(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("RemoveCancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ui-button",
                        {
                          staticClass: "btn-metal",
                          attrs: { low: "", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickOk(item)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("RemoveOk")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }