var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "m-header-menu m-header-menu--skin-light m-header-menu--submenu-skin-light sub-menu",
    },
    [
      _vm.subMenu
        ? _c(
            "ul",
            { staticClass: "m-menu__nav m-menu__nav--submenu-arrow" },
            _vm._l(_vm.subMenu, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "m-menu__item",
                  class: {
                    "m-menu__item--active": item.to.name === _vm.$route.name,
                  },
                },
                [
                  _c(
                    "router-link",
                    { staticClass: "m-menu__link", attrs: { to: item.to } },
                    [
                      _c("span", { staticClass: "m-menu__item-here" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "m-menu__link-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(item.label)) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }