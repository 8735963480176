var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "m-grid__item m-aside-left m-aside-left--skin-light" },
      [
        _c(
          "div",
          { staticClass: "m-brand m-brand--skin-light" },
          [
            _c(
              "router-link",
              { staticClass: "m-brand__logo", attrs: { to: { name: "App" } } },
              [
                _c("img", {
                  attrs: { src: require("@src/assets/images/logo_light.png") },
                }),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "m-aside-menu m-aside-menu--skin-light m-aside-menu--submenu-skin-light",
          },
          [
            _c(
              "ul",
              {
                staticClass: "m-menu__nav m-menu__nav--dropdown-submenu-arrow",
              },
              _vm._l(_vm.navigation, function (item, index) {
                return _c(
                  "li",
                  { key: index, staticClass: "m-menu__item" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "m-menu__link", attrs: { to: item.to } },
                      [
                        _c("i", { class: item.icon }),
                        _vm._v(" "),
                        _c("span", { staticClass: "m-menu__link-text" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t(item.label)) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm.canStartSession ? _c("start-session") : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "m-aside-left-overlay" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      { staticClass: "m-aside-left-close m-aside-left-close--skin-light" },
      [_c("i", { staticClass: "la la-close" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }