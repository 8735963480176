<template>
  <div class="seon-ip">
    <h4>{{ $t('Seon.Ip.Header') }}</h4>
    <div v-if="isReady">
      <template v-if="data">
        <Group>
          <h5>{{ data.ip }}</h5>
        </Group>
        <Group v-if="appliedRules">
          <h5>{{ $t('Seon.Ip.AppliedRules') }}</h5>
          <GroupItem
            v-for="item in appliedRules"
            :key="item.id"
            :name="item.id"
            :value="item.name"
          />
        </Group>
        <Group>
          <GroupItem
            :name="$t('Seon.Ip.Location')"
            :value="`${data.city} (${data.state_prov})`"
          />
          <GroupItem
            :name="$t('Seon.Ip.Country')"
            :value="$t(`Country.${data.country}`)"
          />
          <GroupItem :name="$t('Seon.Ip.IpType')" :value="data.type" />
          <GroupItem :name="$t('Seon.Ip.Isp')" :value="data.isp_name" />
          <GroupItem :name="$t('Seon.Ip.OpenPorts')" :value="openPorts" />
        </Group>
        <Group>
          <GroupItem
            :name="$t('Seon.Ip.Tor')"
            :value="(data.tor && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Ip.Vpn')"
            :value="(data.vpn && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Ip.PublicProxy')"
            :value="(data.public_proxy && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Ip.WebProxy')"
            :value="(data.web_proxy && $t('App.Yes')) || $t('App.No')"
          />
        </Group>
        <Group>
          <GroupItem
            :name="$t('Seon.Ip.SpamStopList')"
            :value="data.spam_number"
          />
          <template v-if="spamUrls">
            <GroupItem
              v-for="(url, index) in spamUrls"
              :key="url"
              :name="(!index && $t('Seon.Ip.SpamUrl')) || null"
              :value="url"
            />
          </template>
          <GroupItem
            v-else
            :name="$t('Seon.Ip.SpamUrl')"
            :value="$t('App.None')"
          />
        </Group>
      </template>
      <p v-else>{{ $t('Seon.Ip.Error') }}</p>
    </div>
    <ui-loader v-else center />
  </div>
</template>

<script>
import Group from '@src/components/partials/refactor/layouts/Group'
import GroupItem from '@src/components/partials/refactor/layouts/GroupItem'
import Api from '@src/scripts/api'

export default {
  components: {
    Group,
    GroupItem
  },

  props: {
    fileId: { type: String, default: null }
  },

  data() {
    return {
      isReady: false,
      data: null
    }
  },

  computed: {
    appliedRules() {
      return (
        (this.data?.applied_rules?.length && this.data.applied_rules) || null
      )
    },

    openPorts() {
      return (
        (this.data?.open_ports?.length && this.data.open_ports.join(', ')) ||
        this.$t('App.None')
      )
    },

    spamUrls() {
      return (this.data?.spam_urls?.length && this.data.spam_urls) || null
    }
  },

  async mounted() {
    await this.loadData()
  },

  methods: {
    async loadData() {
      const { fileId } = this
      const path = `/data-hub/seon/files/${fileId}/ip-details`

      try {
        this.data = await Api.get(path)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      this.isReady = true
    }
  }
}
</script>

<style scoped>
.seon-ip {
  width: 90vw;
  max-height: 85vh;
  max-width: 50rem;
}

h5 {
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
}
</style>
