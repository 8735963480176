var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    { staticClass: "new-user", on: { submit: _vm.createUser } },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("AdminNewUser")))]),
      _vm._v(" "),
      _c("ui-input", {
        staticClass: "email-input",
        attrs: {
          type: "email",
          placeholder: "AdminEmail",
          value: _vm.form.email,
          error: _vm.errors.email,
          disabled: _vm.isLoading,
          "max-length": 255,
        },
        on: { change: _vm.emailChanged },
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "show" } }, [
        !_vm.inviteActive
          ? _c(
              "div",
              { staticClass: "show-content" },
              [
                _c("ui-input", {
                  attrs: {
                    type: "password",
                    placeholder: "AppPassword",
                    value: _vm.form.password,
                    error: _vm.errors.password,
                    disabled: _vm.isLoading,
                    "min-length": 8,
                    "max-length": 20,
                  },
                  on: { input: _vm.passwordChanged },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("ui-toggle", {
        attrs: { label: "AdminSendInvitation", value: _vm.inviteActive },
        on: { change: _vm.modeChanged },
      }),
      _vm._v(" "),
      _c(
        "ui-button",
        {
          staticClass: "btn-brand",
          attrs: { disabled: _vm.isLoading, loading: _vm.isLoading, small: "" },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("AdminCreateUser")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }