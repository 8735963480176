<template>
  <ui-container class="video-call-page">
    <ui-card title="CallTitle" :multicolumn="isReady">
      <ui-widget v-if="isReady" slot="body-left" no-spacing>
        <ui-toggle
          :label="isAvailable ? 'Online' : 'offline'"
          :value="isAvailable"
          :disabled="isCallAccepted"
          @change="workerStatusChange"
        />
      </ui-widget>
      <ui-widget v-if="isReady" slot="body-right" no-spacing>
        <div class="call-count">
          <span> {{ $t('CallTotalRequests') }}: {{ callsCount }} </span>
        </div>
      </ui-widget>
      <ui-loader v-else center />
    </ui-card>
    <ui-card v-show="hasReservation" multicolumn>
      <ui-widget slot="body-left" no-spacing>
        <template v-if="isCallAccepted">
          <template v-if="testCompleted">
            <tabs-group
              v-if="isCallAccepted"
              :tab-index="cameraTabIndex"
              :labels="cameraTabLabels"
              :indicators="indicators"
              @change="cameraTabChanged"
            >
              <div class="camera-box" :class="{ 'tab-hidden': isCameraHidden }">
                <div id="remote-camera" class="remote-camera" />
                <div
                  v-if="room"
                  v-show="cameraTabIndex === 0"
                  id="local-camera"
                  class="local-camera"
                />
              </div>
              <photo-capture-box
                v-show="cameraTabIndex === 1"
                :value="media.face"
                label="CallCaptureFace"
                :disabled="media.isFaceLoading"
                :selector="remoteSelector"
                @change="faceChanged"
              />
              <photo-capture-box
                v-show="cameraTabIndex === 2"
                :value="media.documentFront"
                :label="buttonLabels.front"
                :disabled="media.isDocumentFrontLoading"
                :selector="remoteSelector"
                @change="documentFrontChanged"
              />
              <photo-capture-box
                v-show="cameraTabIndex === 3"
                :value="media.documentBack"
                :label="buttonLabels.back"
                :disabled="media.isDocumentBackLoading"
                :selector="remoteSelector"
                @change="documentBackChanged"
              />
            </tabs-group>
            <div>
              <ui-button
                class="btn-outline-brand"
                small
                bordered
                @click="finishCall"
              >
                {{ $t('CallEnd') }}
              </ui-button>
              <ui-button
                class="btn-brand"
                small
                :disabled="!hasPhotos || verifyLoading"
                @click="comparePhotos"
              >
                {{ $t('CallProcessPhoto') }}
              </ui-button>
              <ui-dropdown
                :value="documentType"
                placeholder="Document type"
                :options="options"
                @input="documentChanged"
              />
              <ui-button
                v-if="isReconnectRequired"
                class="btn-info"
                small
                @click="reconnectAgent"
              >
                {{ $t('CallReconnect') }}
              </ui-button>
            </div>
          </template>
          <ui-loader v-else center />
        </template>
        <div v-else>
          <ui-button small class="btn-brand" @click="acceptCall">
            {{ $t('CallAccept') }}
          </ui-button>
        </div>
        <div v-if="ids" class="mt-4">
          <div v-if="ids.identificationId" class="d-flex">
            <div class="pr-1">Id:</div>
            <div
              class="btn-link m-0"
              @click="handleClick(ids.identificationId)"
            >
              {{ ids.identificationId }}
            </div>
          </div>
          <div v-if="ids.taskId" class="d-flex">
            <div class="pr-1">Call Id:</div>
            <div class="btn-link m-0" @click="handleClick(ids.taskId)">
              {{ ids.taskId }}
            </div>
          </div>
        </div>
      </ui-widget>
      <ui-widget
        v-show="isConnected && isCallAccepted"
        slot="body-right"
        no-spacing
      >
        <tabs-group
          class="details-tab-group"
          :tab-index="detailsTabIndex"
          :labels="detailsTabLabels"
          @change="detailsTabChanged"
        >
          <iframe
            ref="form"
            :class="{ 'tab-hidden': detailsTabIndex !== 0 }"
            :src="formSource"
          />
          <div v-show="detailsTabIndex === 1" class="validation-content">
            <div v-if="faceMatchScore" class="content-box m-widget1">
              <details-box class="rules-box">
                <h5>{{ $t('FaceMatchEvaluation') }}</h5>
                <details-item
                  label="SCORES_PERSON_TO_DOCUMENT"
                  :value="faceMatchScore"
                />
              </details-box>
            </div>
            <rule-list
              v-if="rules"
              class="m-widget1"
              title="Validation rules"
              :data="rules"
            />
            <h5 v-else class="m-widget1">Not validated</h5>
          </div>
          <div v-show="detailsTabIndex === 2" class="comments-content">
            <comments :comments="comments" />
          </div>
        </tabs-group>
        <div v-if="ids && ids.identificationId" style="height: 160px">
          <hr />
          <comment-input
            :identification-id="ids.identificationId"
            @created="onCommentCreated"
          />
        </div>
      </ui-widget>
    </ui-card>
  </ui-container>
</template>

<script>
import { v4 } from 'uuid'
import TwilioVideo from 'twilio-video'

import PhotoCaptureBox from '@src/components/partials/PhotoCaptureBox'
import RuleList from '@src/components/partials/RuleList'
import TabsGroup from '@src/components/partials/TabsGroup'
import Api from '@src/scripts/api'
import EventController, { eventTypes } from '@src/scripts/eventController'
import { dataUrlToBlob } from '@src/scripts/helpers'
import { callAgentStatusTypesEnum } from '@src/scripts/enums'
import { notificationType } from '@src/components/notification'
import MediaSettings from '@src/scripts/mediaSettings'
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import { copy } from '@src/scripts/clipboard'
import { checkConnection } from '@src/scripts/videoCallUtils'
import { logger } from '@src/scripts/logger'

import Comments from '@src/components/partials/comments/Comments'
import CommentInput from '@src/components/partials/comments/CommentInput'

const emptyMedia = () => {
  return {
    face: null,
    documentFront: null,
    documentBack: null,
    isFaceLoading: false,
    isDocumentFrontLoading: false,
    isDocumentBackLoading: false
  }
}

export default {
  components: {
    DetailsBox,
    DetailsItem,
    PhotoCaptureBox,
    RuleList,
    TabsGroup,
    Comments,
    CommentInput
  },

  data() {
    return {
      isReady: false,
      isAvailable: false,
      isCallAccepted: false,
      isPhotosValidated: false,
      isConnected: false,
      isEditing: false,
      isReconnectRequired: false,
      hasReservation: false,
      testCompleted: false,

      comments: [],

      timeoutCount: 0,
      callsCount: '-',

      worker: null,
      reservation: null,
      room: null,
      callDetails: null,
      formEvents: null,
      logData: null,

      twilioTaskComplete: null,

      remoteSelector: '.remote-camera > video',
      documentType: 'PASSPORT',
      options: [
        { key: 'PASSPORT', value: 'Passport' },
        { key: 'ID_CARD', value: 'ID card' }
      ],
      media: emptyMedia(),
      rules: null,
      faceMatchScore: null,

      cameraTabIndex: 0,
      detailsTabIndex: 0,
      detailsTabLabels: ['Form', 'Validations', 'Comments'],
      formData: null,
      notificationTimer: null,

      // -- camera --

      cameraSettings: null,
      isCallActive: false,

      verifyLoading: false
    }
  },

  computed: {
    formSource() {
      const { isConnected, callDetails } = this
      if (!isConnected || !callDetails || !callDetails.formType) return null

      const type = callDetails.formType.toLowerCase()
      return `${BaseUrl.form}/forms/${type}/index.html?v=${Date.now()}`
    },

    hasPhotos() {
      const { media } = this
      let hasPhoto = true

      for (const key in media) {
        const value = media[key]

        if (typeof value === 'boolean') {
          if (value) {
            hasPhoto = false
            break
          }
        } else if (!value) {
          hasPhoto = false
          break
        }
      }

      return hasPhoto
    },

    cameraTabLabels() {
      const { documentType } = this
      if (documentType === 'PASSPORT')
        return ['Live', 'Face', 'Passport cover', 'Passport main page']
      return ['Live', 'Face', 'ID front', 'ID back']
    },

    isCameraHidden() {
      const { cameraTabIndex, media } = this

      if (cameraTabIndex === 1 && media.face) return true
      if (cameraTabIndex === 2 && media.documentFront) return true
      if (cameraTabIndex === 3 && media.documentBack) return true
      return false
    },

    indicators() {
      const { media, isConnected } = this
      if (!isConnected) return
      const list = [null]

      list.push(!!media.face)
      list.push(!!media.documentFront)
      list.push(!!media.documentBack)

      return list
    },

    buttonLabels() {
      const { documentType } = this
      if (documentType === 'PASSPORT') {
        return {
          front: 'Capture passport cover',
          back: 'Capture passport main page'
        }
      }

      return {
        front: 'Capture ID front',
        back: 'Capture ID back'
      }
    },

    ids() {
      const { callDetails, reservation, room } = this

      const identificationId = callDetails?.identificationId
      const taskId = reservation?.task?.sid ?? room?.name

      if (!taskId && !identificationId) {
        return null
      }

      return {
        identificationId,
        taskId
      }
    }
  },

  async mounted() {
    const settings = await MediaSettings()
    this.cameraSettings = settings
    if (!this.isMediaAvailable(settings)) return

    const sessionTask = this.getRouterAccessToken()
    await this.loadTaskRouter()

    const session = await sessionTask
    if (!session) return

    this.startAcceptCalls(session)

    // make offline on destroy
    window.addEventListener('beforeunload', this.beforeUnload, false)
    window.addEventListener('error', this.unhandledError, false)
  },

  async beforeDestroy() {
    await this.makeOffline()
    await this.endCall()
    this.setDefaults()

    window.removeEventListener('beforeunload', this.beforeUnload, false)
    window.removeEventListener('error', this.unhandledError, false)
    this.worker = null
  },

  methods: {
    handleClick(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },

    beforeUnload(event) {
      this.endCall()
      this.setDefaults()

      if (!this.isAvailable) return

      event.returnValue = 'Changes that you made may not be saved'
      this.makeOffline()
    },

    unhandledError(message, url, lineNo, columnNo, error) {
      logger.logError({
        name: 'VideoCall.UnhandledError',
        exception: error,
        properties: {
          workerId: this.worker?.sid,
          ...this.logData,
          message,
          url,
          lineNo,
          columnNo
        }
      })
    },

    isMediaAvailable(settings) {
      if (!settings.hasCamera && !settings.hasMicrophone)
        return !alert("You don't have camera and microphone")
      if (!settings.hasCamera) return !alert("You don't have camera")
      if (!settings.hasMicrophone) return !alert("You don't have microphone")

      if (!settings.hasCameraPermissions && !settings.hasMicrophonePermissions)
        return !alert("You don't have camera and microphone permissions")
      if (!settings.hasCameraPermissions)
        return !alert("You don't have camera permissions")
      if (!settings.hasMicrophonePermissions)
        return !alert("You don't have microphone permissions")

      if (!settings.hasAllowedConstraint)
        return !alert(
          "Can't access camera. \nMaybe camera in use by another program"
        )

      return true
    },

    documentChanged(value) {
      this.documentType = value
      ;(this.media.documentFront = null), (this.media.documentBack = null)
    },

    cameraTabChanged(value) {
      this.cameraTabIndex = value
    },

    detailsTabChanged(value) {
      this.detailsTabIndex = value
    },

    registerEvents() {
      this.formEvents = new EventController({
        target: this.$refs.form,
        allowedOrigin: BaseUrl.form
      })

      this.formEvents.addListener(eventTypes.formReady, this.formReady)
      this.formEvents.addListener(eventTypes.formUpdate, this.formUpdate)
      this.formEvents.addListener(eventTypes.formEditState, this.formEditState)
    },

    clearEvents() {
      if (!this.formEvents) return

      this.formEvents.clearListeners()
      this.formEvents = null
    },

    loadTaskRouter() {
      return new Promise((resolve) => {
        const source =
          'https://media.twiliocdn.com/taskrouter/js/v1.20/taskrouter.min.js'
        const script = document.createElement('script')
        script.setAttribute('src', source)
        this.$el.appendChild(script)

        this.delay(resolve)
      })
    },

    async workerStatusChange(isAvailable) {
      this.isAvailable = isAvailable
      const method = isAvailable ? this.makeAvailable : this.makeOffline

      if (isAvailable) {
        this.timeoutCount = 0
      }

      if (!(await method())) {
        this.isAvailable = !isAvailable
      }
    },

    async acceptCall() {
      this.testCompleted = false
      this.isCallAccepted = true
      this.isPhotosValidated = false
      this.timeoutCount = 0

      const { worker, ids } = this
      const session = await this.getCallAccessToken(worker.sid)
      if (!session) return

      this.logData = {
        taskId: ids.taskId,
        workerId: worker.sid,
        corelationId: v4()
      }

      logger.logTrace({
        name: 'VideoCall.Started',
        properties: this.logData
      })

      checkConnection(session.accessToken, this.logData)

      this.testCompleted = true

      this.reservation.accept((error, details) =>
        this.reservationAccept(error, details, session.accessToken)
      )
    },

    reservationDeleted() {
      this.setDefaults()
    },

    callEndConfirmation(data) {
      this.$notify({
        type: notificationType.warning,
        ...data
      })
    },

    finishCall() {
      if (this.isEditing) {
        this.callEndConfirmation({
          title: 'EndCall.Form.Title',
          message: 'EndCall.Form.Description',
          callback: this.finishCallPerformPhotos
        })
      } else {
        this.finishCallPerformPhotos()
      }
    },

    finishCallPerformPhotos() {
      if (this.isConnected && !this.isPhotosValidated) {
        this.callEndConfirmation({
          title: 'EndCall.Photo.Title',
          message: 'EndCall.Photo.Description',
          callback: this.finishCallSuccess
        })
      } else {
        this.finishCallSuccess()
      }
    },

    async finishCallSuccess() {
      if (this.isPhotosValidated) {
        if (this.formData) {
          const isUpdated = await this.updateCustomData(this.formData)
          if (!isUpdated) return
        }
        const isCompleted = await this.completeCall()
        if (!isCompleted) return
      }

      this.setCallsCount()
      this.endCall()
    },

    async comparePhotos() {
      const {
        callDetails: { identificationId }
      } = this
      this.verifyLoading = true

      try {
        const {
          data: { result }
        } = await Api.post(
          `/call/identification/${identificationId}/verify`,
          {}
        )

        this.faceMatchScore = result?.faceMatchScore
        const rules = result?.rules
        if (rules) {
          this.isPhotosValidated = true
          this.rules = this.mapRules(rules)
          this.detailsTabIndex = 1

          let hasError = false
          for (const key in rules) {
            if (rules[key] === 'FAIL') {
              hasError = true
              break
            }
          }

          if (!hasError) {
            this.$notify({
              title: 'SuccessfulIdentification',
              type: notificationType.info
            })
            return
          }
        }

        this.$notify({
          title: 'FailedIdentification'
        })
      } catch (error) {
        this.showError()
      } finally {
        this.verifyLoading = false
      }
    },

    mapRules(rules) {
      const list = []

      for (const key in rules) {
        list.push({
          name: key,
          status: rules[key]
        })
      }

      return (list.length && list) || null
    },

    startAcceptCalls({ accessToken }) {
      try {
        const twilioWorker = new Twilio.TaskRouter.Worker(accessToken, false)

        twilioWorker.on('ready', this.workerReady)
        twilioWorker.on('error', this.showError)
        twilioWorker.on('reservation.created', this.reservationCreated)
        twilioWorker.on('reservation.canceled', this.reservationDeleted)
        twilioWorker.on('reservation.timeout', this.reservationTimeout)
        twilioWorker.on('activity.update', this.activityUpdate)

        this.twilioTaskComplete = (sid) => {
          twilioWorker.completeTask(sid)
        }
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'No permissions for video call'
        })
      }
    },

    reservationTimeout(reservation) {
      if (reservation.reservationStatus === 'timeout') {
        this.setDefaults()
        if (this.timeoutCount < 4) {
          this.timeoutCount++
          this.makeAvailable()
        }
      }
    },

    activityUpdate(worker) {
      this.isAvailable = worker.available
    },

    workerReady(details) {
      this.worker = details
      this.isReady = true

      if (details.activityName === 'Available') {
        this.isAvailable = true
      }

      this.setCallsCount()
    },

    reservationCreated(reservation) {
      this.reservation = reservation
      this.hasReservation = true

      this.createCallNotification()
    },

    createCallNotification() {
      this.$notify({
        type: 'info-with-sound',
        title: 'InfoNewCall',
        repeatSound: 5
      })

      if (this.notificationTimer) return
      this.notificationTimer = setInterval(this.startCallNotification, 1000)
      document.addEventListener('mouseover', this.clearCallNotification, false)
    },

    startCallNotification() {
      const message = this.$t('InfoNewCall') || ''
      document.title = document.title === message ? DefaultTitle : message
    },

    clearCallNotification() {
      clearInterval(this.notificationTimer)
      this.notificationTimer = null
      document.removeEventListener(
        'mouseover',
        this.clearCallNotification,
        false
      )
      document.title = DefaultTitle
    },

    async reservationAccept(error, details, accessToken) {
      if (error) {
        logger.logError({
          name: 'VideoCall.Reservation.Error',
          exception: error,
          properties: this.logData
        })

        this.showError()
        return
      }

      logger.logTrace({
        name: 'VideoCall.Reservation.Accepted',
        properties: this.logData
      })

      if (!(await this.makeUnavailable())) {
        logger.logError({
          name: 'VideoCall.WorkerStatusChange.Error',
          properties: this.logData
        })

        return
      }

      this.isCallActive = true

      if (details) {
        setTimeout(() => {
          this.twilioTaskComplete(details.taskSid)
          this.reservation = null
        }, 3000)
      }

      this.connect(details.taskSid, accessToken)
    },

    async makeAvailable() {
      return await this.updateWorkerStatus(callAgentStatusTypesEnum.available)
    },

    async makeUnavailable() {
      return await this.updateWorkerStatus(callAgentStatusTypesEnum.unavailable)
    },

    async makeOffline() {
      return await this.updateWorkerStatus(callAgentStatusTypesEnum.offline)
    },

    async setCallsCount() {
      const { worker } = this
      if (!worker) return

      await worker.workspace.tasks.fetch((_, tasks) => {
        const pendingTasks = tasks.data.filter((item) => {
          return item.assignmentStatus === 'pending'
        })

        this.callsCount = pendingTasks.length || 0
      })
    },

    async connect(roomName, accessToken) {
      const conectionSettings = {
        name: roomName,
        audio: false,
        video: false
      }

      logger.logTrace({
        name: 'VideoCall.Room.Creation',
        properties: this.logData
      })

      const room = await this.getRoom(accessToken, conectionSettings)
      if (!room) return

      await this.connectSuccess(room)
    },

    async getRoom(token, conectionSettings) {
      try {
        return await TwilioVideo.connect(token, conectionSettings)
      } catch (error) {
        return null
      }
    },

    async publishMedia(participant) {
      const tracks = await this.cameraSettings.getMediaTracks()

      const localTracks = await TwilioVideo.createLocalTracks({
        tracks: [tracks.videoTrack, tracks.audioTrack]
      })

      for (const mediaTrack of localTracks) {
        participant.publishTrack(mediaTrack)
      }
    },

    createNotification(title, type) {
      let notification = null

      return {
        isVisible: () => notification !== null,
        show: () => {
          notification = this.$notify({
            type,
            title
          })
        },
        hide: () => {
          notification()
        }
      }
    },

    initNotifications(room) {
      const reconnectNotification = this.createNotification(
        'RoomReconnecting',
        'time-left'
      )
      const reconnectedNotification = this.createNotification(
        'RoomReconnected',
        'info'
      )
      const disconnectNotification = this.createNotification(
        'RoomDisconnected',
        'error'
      )
      const participantReconnectNotification = this.createNotification(
        'RemoteParticipantReconnecting',
        'time-left'
      )
      const participantReconnectedNotification = this.createNotification(
        'RemoteParticipantReconnected',
        'info'
      )
      const participantDisconnectedNotication = this.createNotification(
        'RemoteParticipantDisconected',
        'error'
      )

      room.on('reconnecting', () => {
        this.isReconnectRequired = true

        console.log('reconnecting')
        logger.logTrace({
          name: 'VideoCall.Room.Reconnecting',
          properties: this.logData
        })

        reconnectNotification.show()
      })
      room.on('reconnected', () => {
        console.log('reconnected')
        logger.logTrace({
          name: 'VideoCall.Room.Reconnected',
          properties: this.logData
        })

        reconnectNotification.hide()
        reconnectedNotification.show()
      })
      room.on('disconnected', () => {
        console.log('disconnected')
        logger.logTrace({
          name: 'VideoCall.Room.Disconnected',
          properties: this.logData
        })

        if (reconnectNotification.isVisible()) {
          reconnectNotification.hide()
          disconnectNotification.show()
        }
      })
      room.on('participantReconnecting', () => {
        this.isReconnectRequired = true

        console.log('participantReconnecting')
        logger.logTrace({
          name: 'VideoCall.Participant.Reconnecting',
          properties: this.logData
        })

        participantReconnectNotification.show()
      })
      room.on('participantReconnected', () => {
        console.log('participantReconnected')
        logger.logTrace({
          name: 'VideoCall.Participant.Reconnected',
          properties: this.logData
        })

        participantReconnectNotification.hide()
        participantReconnectedNotification.show()
      })
      room.on('participantDisconnected', () => {
        console.log('participantDisconnected')
        logger.logTrace({
          name: 'VideoCall.Participant.Disconnected',
          properties: this.logData
        })

        if (participantReconnectNotification.isVisible()) {
          participantReconnectNotification.hide()
          participantDisconnectedNotication.show()
        }
      })
    },

    async connectSuccess(room) {
      const { localParticipant } = room
      this.room = room

      this.initNotifications(room)

      room.participants.forEach(this.participantConnected)
      room.on('participantConnected', this.participantConnected)
      room.on('participantDisconnected', this.participantDisconnected)
      room.on('disconnected', this.disconnected)
      room.on('error', (error) => {
        logger.logError({
          name: 'VideoCall.Room.Error',
          exception: error,
          properties: this.logData
        })
      })

      await this.publishMedia(localParticipant)
    },

    async reconnectAgent() {
      this.isReconnectRequired = false

      const { localParticipant } = this.room
      await this.publishMedia(localParticipant)

      await this.participantconnectSuccess()
    },

    async participantconnectSuccess() {
      await this.addLocalVideo()

      this.callDetails = await this.getCallDetails(this.room.name)
      if (this.callDetails.formType) {
        if (!this.formEvents) {
          this.registerEvents()
        }
      } else {
        this.detailsTabIndex = 1
      }
    },

    async participantConnected(participant) {
      logger.logTrace({
        name: 'VideoCall.Participant.Connected',
        properties: this.logData
      })

      console.log('participantConnected', this.room.sid)

      participant.on('trackSubscribed', this.trackSubscribed)
      participant.on('trackUnsubscribed', this.trackUnsubscribed)
      participant.on('error', (error) => {
        logger.logError({
          name: 'VideoCall.Participant.Error',
          exception: error,
          properties: this.logData
        })
      })

      participant.tracks.forEach((publication) => {
        if (publication.isSubscribed) {
          this.trackSubscribed(publication.track)
        }
      })

      await this.participantconnectSuccess()
    },

    participantDisconnected() {
      logger.logTrace({
        name: 'VideoCall.Participant.Disconnected',
        properties: this.logData
      })

      console.log('participantDisconnected')
      this.endCall()
    },

    async disconnected(room) {
      logger.logTrace({
        name: 'VideoCall.Room.Disconnected',
        properties: this.logData
      })

      console.log('disconnected')

      room.localParticipant.tracks.forEach((publication) => {
        const attachedElements = publication.track.detach()
        attachedElements.forEach((element) => element.remove())
      })

      await this.makeAvailable()
      this.setDefaults()
    },

    trackSubscribed(track) {
      logger.logTrace({
        name: 'VideoCall.Track.Subscribed',
        properties: this.logData
      })

      console.log('trackSubscribed', !!track)

      const remoteCamera = document.getElementById('remote-camera')
      const videoElement = track.attach()

      if (!remoteCamera) {
        this.endCall()
        track.detach().forEach((element) => element.remove())
        return
      }

      remoteCamera.appendChild(videoElement)

      if (track.kind === 'video') {
        if (track.isStarted) {
          this.isConnected = true
        } else {
          track.once('started', () => {
            this.isConnected = true
          })
        }
      }
    },

    trackUnsubscribed(track) {
      logger.logTrace({
        name: 'VideoCall.Track.Unsubscribed',
        properties: this.logData
      })

      console.log('trackUnsubscribed', !!track)
      track.detach().forEach((element) => element.remove())
    },

    async addLocalVideo() {
      const { cameraSettings } = this
      const { videoTrack, audioTrack } = await cameraSettings.getMediaTracks()

      audioTrack?.stop()

      const stream = new MediaStream()
      stream.addTrack(videoTrack)

      const localCamera = document.getElementById('local-camera')
      if (!localCamera) return

      const video = document.createElement('video')
      localCamera.appendChild(video)
      video.srcObject = stream
      video.autoplay = true
    },

    removeLocalTrack() {
      const localCamera = document.getElementById('local-camera')
      if (!localCamera) return

      const video = localCamera.querySelector('video')
      if (!video) return

      video.srcObject?.getTracks().forEach((track) => track.stop())
      video.srcObject = null
    },

    async endCall() {
      await this.closeRoom()
    },

    async closeRoom() {
      const { room } = this
      if (!room) return
      room.disconnect()
      await this.closeCallRoom(room.sid)
    },

    setDefaults() {
      this.room = null
      this.removeLocalTrack()
      this.cameraSettings.stopMediaTracks()

      this.isCallAccepted = false
      this.isPhotosValidated = false
      this.isConnected = false
      this.isEditing = false
      this.isReconnectRequired = false
      this.hasReservation = false
      this.isCallActive = false

      this.reservation = null
      this.callDetails = null
      this.clearEvents()

      this.media = emptyMedia()
      this.documentType = 'PASSPORT'

      this.rules = null
      this.cameraTabIndex = 0
      this.detailsTabIndex = 0
      this.formData = null
      this.logData = null

      this.comments = []
    },

    formReady() {
      const { callDetails } = this
      console.log('callDetails', callDetails)
      if (!callDetails || !callDetails.formType) return

      this.formEvents.triggerSuccess(eventTypes.formLoad, {
        language: 'en',
        reviewRequired: true,
        ...callDetails.formData
      })
    },

    formUpdate({ data }) {
      this.formData = data
      this.formEvents.triggerSuccess(eventTypes.formUpdateStatus)
    },

    formEditState({ data }) {
      this.isEditing = !!data.state
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    delay(resolve) {
      setTimeout(() => {
        if (!window.Twilio) this.delay(resolve)
        else resolve()
      }, 500)
    },

    async updateWorkerStatus(status) {
      try {
        await Api.post('/call/task/worker/status', {
          status
        })
        return true
      } catch (error) {
        this.showError()
        return false
      }
    },

    async getRouterAccessToken() {
      try {
        const data = { ...(await Api.get('/call/task/worker/token')) }
        if (!data.workerSid || !data.taskRouterJwt) {
          this.showError()
          return null
        }

        return { accessToken: data.taskRouterJwt }
      } catch (error) {
        this.showError()
        return null
      }
    },

    async getCallAccessToken() {
      try {
        const token = await Api.get('/call/video/token')
        return { accessToken: token }
      } catch (error) {
        this.showError()
        return null
      }
    },

    async getCallDetails(taskId) {
      try {
        const data = await Api.get(`/call/task/${taskId}/identification`)
        return data
      } catch (error) {
        this.showError()
        return null
      }
    },

    async closeCallRoom(roomId) {
      try {
        await Api.post(`/call/room/${roomId}/complete`, {})
        return true
      } catch (error) {
        return false
      }
    },

    async completeCall() {
      const {
        callDetails: { identificationId }
      } = this
      try {
        await Api.post(`/call/identification/${identificationId}/complete`, {})
        return true
      } catch (error) {
        this.showError()
        return { error: true }
      }
    },

    async updateCustomData(data) {
      const {
        callDetails: { identificationId }
      } = this
      try {
        await Api.post(
          `/call/identification/${identificationId}/custom-data`,
          data
        )
        return true
      } catch (error) {
        this.showError()
        return { error: true }
      }
    },

    async faceChanged(value) {
      const { media, callDetails } = this
      media.face = value
      if (!value) return

      media.isFaceLoading = true
      await this.uploadPhoto({
        method: 'face',
        fileUrl: value,
        identificationId: callDetails.identificationId
      })
      media.isFaceLoading = false
    },

    async documentFrontChanged(value) {
      const { media, callDetails } = this
      media.documentFront = value
      if (!value) return

      media.isDocumentFrontLoading = true
      await this.uploadPhoto({
        method: 'document',
        fileUrl: media.documentFront,
        identificationId: callDetails.identificationId,
        sideType: 'FRONT',
        documentType: this.documentType
      })
      media.isDocumentFrontLoading = false
    },

    async documentBackChanged(value) {
      const { media, callDetails } = this
      media.documentBack = value
      if (!value) return

      media.isDocumentBackLoading = true
      await this.uploadPhoto({
        method: 'document',
        fileUrl: media.documentBack,
        identificationId: callDetails.identificationId,
        sideType: 'BACK',
        documentType: this.documentType
      })
      media.isDocumentBackLoading = false
    },

    async uploadPhoto({
      method,
      fileUrl,
      identificationId,
      sideType,
      documentType
    }) {
      try {
        const file = dataUrlToBlob(fileUrl)
        const request = new FormData()
        request.append('identificationId', identificationId)
        request.append('file', file, 'file.jpg')
        if (sideType) {
          request.append('sideType', sideType)
        }
        if (documentType) {
          request.append('documentType', documentType)
        }
        const {
          error,
          data: { result }
        } = await Api.postFormData(`/call/media/${method}`, request)
        if (error || !result || !result.success) {
          return (
            (result && result.error) || (error && error.type) || 'ServiceError'
          )
        }
      } catch (error) {
        this.showError()
        return 'ServiceError'
      }
    },

    onCommentCreated(comment) {
      this.comments.unshift(comment)
    }
  }
}
</script>

<style scoped>
.camera-box {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.remote-camera {
  box-shadow: 0 0 2rem var(--shadow);
}

.local-camera {
  width: 30%;
  right: 1rem;
  top: 1rem;
  position: absolute;
  box-shadow: 0 0 2rem var(--shadow);
}

.call-count {
  font-weight: 700;
  display: flex;
  height: 100%;
}

.call-count > span {
  margin: auto 0;
}

.photo-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.error-box {
  display: block;
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tabs-group {
  margin-bottom: 2rem;
}

.tabs-group > :first-child {
  margin-bottom: 2rem;
}

.details-tab-group {
  margin-bottom: 0;
  height: calc(100% - 160px);
  display: flex;
  flex-direction: column;
}

.details-tab-group > :last-child {
  height: 100%;
}

.validation-content {
  position: absolute;
  top: 0;
}

.comments-content {
  position: absolute;
  top: 0;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
}

.tab-hidden {
  visibility: hidden;
}
</style>

<style>
.video-call-page .camera-box video {
  height: 100%;
  width: 100%;
  max-height: 50rem;
  display: block;
}

.video-call-page .ui-dropdown {
  max-width: 20rem;
  display: inline-block;
}

.video-call-page .ui-dropdown > .btn {
  width: 100%;
  background-color: transparent;
  border: 1px solid #ebedf2;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;
}

.video-call-page .m-widget1,
.video-call-page .m-widget1__item {
  height: 100%;
}

.video-call-page iframe {
  width: 100%;
  height: 100%;
  border: none;
  min-height: 30rem;
}

.video-call-page .image-local {
  position: absolute;
  top: 0;
}
</style>
