import Oidc from 'oidc-client'
import Store from '@src/store'
import actions from '@src/store/actions'

const config = {
  authority: AuthorityUrl,
  client_id: 'js',
  redirect_uri: `${window.location.origin}/login`,
  response_type: 'id_token token',
  scope: 'openid profile bo_api',
  //automaticSilentRenew: true,
  post_logout_redirect_uri: `${window.location.origin}`,
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage })
}

const userManager = new Oidc.UserManager(config)

export function signIn() {
  userManager.signinRedirect()
}

export function signOut() {
  userManager.signoutRedirect()
  Store.dispatch(actions.updateSession)
}

export function handleSignIn() {
  return new Promise((resolve, reject) => {
    userManager
      .signinRedirectCallback()
      .then(() => {
        Store.dispatch(actions.updateSession)
        resolve()
      })
      .catch(() => {
        reject()
      })
  })
}
