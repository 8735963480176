<template>
  <div class="identification-page">
    <ui-header
      title="IdentificationsTitle"
      :method="searchMethod"
      :filters="filters"
      :class="{ 'fixed-header': scrollDown }"
      reset-form
      :disabled="isLoading || isDownloading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div class="filters-block">
        <div class="display-flex flex-flow-wrap">
          <ui-header-dropdown
            v-model="filters.date"
            :options="options.dateOptions"
            placeholder="HeaderDatePlaceHolder"
            wide
            :disabled="isLoading"
          />
          <ui-header-input
            v-model="filters.email"
            type="email"
            placeholder="HeaderEmailPlaceholder"
            icon="la-envelope"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-model="filters.reviewStatus"
            :options="options.reviewStatusOptions"
            all
            medium
            placeholder="HeaderReviewStatusPlaceholder"
            :disabled="isLoading || !options.reviewStatusOptions"
          />
          <ui-header-dropdown
            v-model="filters.requestStatus"
            all="ALL_FINISHED"
            :options="options.requestStatusOptions"
            medium
            placeholder="HeaderRequestStatusPlaceholder"
            :disabled="isLoading || !options.requestStatusOptions"
          />
          <ui-header-input
            v-if="openFullSearch"
            v-model="filters.fullname"
            type="text"
            placeholder="TableFullName"
            medium
            :disabled="isLoading"
          />
          <ui-header-input
            v-if="openFullSearch"
            v-model="filters.personalCode"
            type="email"
            placeholder="DetailsPersonCode"
            medium
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-if="openFullSearch"
            v-model="filters.country"
            all
            has-search
            class="countries-dropdown"
            key-value-dropdown
            :options="countriesOptions"
            medium
            placeholder="DetailsCountry"
            :disabled="isLoading"
          />
          <ui-header-dropdown
            v-if="openFullSearch && stateOptionList"
            v-model="filters.state"
            all
            class="countries-dropdown"
            key-value-dropdown
            :options="stateOptionList"
            medium
            placeholder="DetailsState"
            :disabled="isLoading || !options.requestStatusOptions"
          />

          <ui-header-dropdown
            v-if="openFullSearch"
            v-model="filters.documentType"
            all
            :options="documentTypes"
            medium
            placeholder="DetailsDocumentType"
            :disabled="isLoading"
          />
          <ui-multi-header-dropdown
            v-if="openFullSearch"
            :placeholder="$t('DetailsRejectionReason')"
            :options="rejectionReasons"
            medium
            has-search
            :disabled="isLoading"
            @dropdownMultiSelected="handleRejectionReasonsSelect"
          />
          <ui-header-input
            v-if="openFullSearch"
            v-model="filters.identificationId"
            type="text"
            placeholder="DetailsIdentificationId"
            medium
            :disabled="isLoading"
          />
          <div
            :class="openFullSearch && 'open-fullsearh-link'"
            class="filter-small-block"
          >
            <div
              class="fullsearh-link"
              @click="
                openFullSearch
                  ? (openFullSearch = false)
                  : (openFullSearch = true)
              "
            >
              {{ $t(fullSearchText) }}
              <i
                class="la"
                :class="openFullSearch ? 'la-angle-up' : 'la-angle-down'"
              />
            </div>
          </div>
        </div>
      </div>
    </ui-header>
    <ui-container>
      <ui-card title="AppSearchResults" :count="itemsCount">
        <div slot="tools">
          <div class="display-flex">
            <ui-button
              class="btn-brand margin-right-3 hidden-button"
              small
              @click="clickAllIdentification"
            >
              View all
            </ui-button>

            <ui-loader v-if="isDownloading" class="mr-1" :size="1" center />
            <ui-dropdown
              :options="exportOptions"
              placeholder="AppExport"
              class="margin-right"
              :disabled="isDownloading"
              small
              light-border
              @input="exportList"
            />
          </div>
        </div>
        <template v-if="!isLoading && identifications">
          <ui-table
            :labels="listLabels"
            :data="identifications"
            class="export-table hide-mobile"
            empty-text="TableNoIdentifications"
          >
            <template>
              <tr
                v-for="(row, index) in identifications"
                :key="index"
                class="m-table__row"
                :class="classList(row)"
              >
                <td
                  :title="row.token"
                  class="cell-identification-id-desktop fixed-width"
                >
                  <span
                    class="identification-id-desktop"
                    @click="copyIdentificationId(row.token)"
                  >
                    {{ row.token }}
                  </span>
                </td>
                <td>
                  {{ row.created | date(true) }}
                </td>
                <td class="fixed-width" :title="row.companyName">
                  {{ row.companyName }}
                </td>
                <td class="fixed-width full-name" :title="getFullName(row)">
                  {{ getFullName(row) }}
                </td>
                <td class="fixed-width" :title="row.email">
                  {{ row.email }}
                </td>
                <td class="review-status">
                  {{ $t(row.reviewStatus) }}
                </td>
                <td class="m--font-success">
                  {{ $t(row.requestStatus) }}
                </td>
                <td class="m--font-success">
                  <ui-toggle
                    v-if="!hideToggle(row.requestStatus, row.reviewStatus)"
                    prevent
                    :value="
                      getMonitoringStatus(row.monitoringData.monitoringStatus)
                    "
                    @change="changeMonitoring(row, index)"
                  />
                </td>
                <td v-if="hasThirdPartyExport" class="m--font-success">
                  {{
                    (row.exportStatus &&
                      $t(row.exportStatus.thirdPartyExport)) ||
                    '-'
                  }}
                </td>
                <td class="td-link">
                  <ui-link
                    :to="{
                      name: 'IdentificationDetails',
                      params: { identificationId: row.token }
                    }"
                    class="btn-brand"
                    small-text
                  >
                    {{ $t('TableView') }}
                  </ui-link>
                  <ui-link
                    :to="{
                      name: 'IdentificationDetails',
                      params: { identificationId: row.token, export: true }
                    }"
                    class="btn-metal"
                    small-text
                    target="_blank"
                  >
                    {{ $t('AppExport') }}
                  </ui-link>
                </td>
              </tr>
            </template>
          </ui-table>
          <template v-if="identifications && identifications.length > 0">
            <div
              v-for="(row, index) in identifications"
              :key="index"
              class="response-table show-mobile"
              :class="classList(row)"
            >
              <responsive-table-tr
                title="TableIdentificationId"
                :value="row.token"
              />
              <responsive-table-tr
                title="TableDate"
                :value="row.finished | date(true)"
              />
              <responsive-table-tr
                title="TableCompanyName"
                :value="row.companyName"
              />
              <responsive-table-tr
                title="TableFullName"
                class="full-name"
                :value="getFullName(row)"
              />
              <responsive-table-tr title="TableEmail" :value="row.email" />
              <responsive-table-tr
                class="review-status"
                title="TableReviewStatus"
                :value="row.reviewStatus"
              />
              <responsive-table-tr
                title="TableRequestStatus"
                :value="row.requestStatus"
              />
              <responsive-table-tr
                v-if="!hideToggle(row.requestStatus, row.reviewStatus)"
                title="Monitoring"
              >
                <ui-toggle
                  prevent
                  :value="
                    getMonitoringStatus(row.monitoringData.monitoringStatus)
                  "
                  @change="changeMonitoring(row, index)"
                />
              </responsive-table-tr>
              <responsive-table-tr
                v-if="hasThirdPartyExport"
                title="Export Status"
                :value="
                  (row.exportStatus && row.exportStatus.thirdPartyExport) || '-'
                "
              />
              <div class="m--margin-top-10 margin-1">
                <ui-link
                  :to="{
                    name: 'IdentificationDetails',
                    params: { identificationId: row.token }
                  }"
                  class="btn-brand big-link"
                  small-text
                >
                  {{ $t('TableView') }}
                </ui-link>
                <ui-link
                  :to="{
                    name: 'IdentificationDetails',
                    params: { identificationId: row.token }
                  }"
                  class="btn-metal big-link"
                  small-text
                  target="_blank"
                >
                  {{ $t('AppExport') }}
                </ui-link>
              </div>
            </div>
          </template>
          <div v-else class="table-message show-mobile">
            {{ $t('TableNoIdentifications') }}
          </div>
        </template>
        <ui-loader v-if="isLoading" :size="4" center />
        <ui-page-selector
          :active-page="filters.page"
          :items-count="itemsCount"
          :max-on-page="filters.pageSize"
          :disabled="isLoading"
          @change="pageChanged"
        />
      </ui-card>
      <pop-up overflow-unset full-size>
        <monitoring-setup
          :data="monitoringData"
          @change="changeMonitoringStatus"
        />
      </pop-up>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import {
  createDateFilters,
  dateToString,
  mapOptions,
  downloadBlob
} from '@src/scripts/helpers'
import { exportPdf } from '@src/scripts/exportPdf'
import getters from '@src/store/getters'
import { mapGetters } from 'vuex'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import MonitoringSetup from '@src/components/partials/MonitoringSetup'
import { notificationType } from '@src/components/notification'
import { copy } from '@src/scripts/clipboard'
import { canStates, usaStates, mexStates } from '@src/scripts/states'
import {
  PRODUCTS,
  MONITORING_STATUS,
  REQUEST_STATUS,
  REVIEW_STATUS,
  COUNTRIES_WITH_STATE,
  API
} from '@src/scripts/enums'
import Store from '@src/store'

const filters = () => ({
  date: null,
  email: null,
  reviewStatus: null,
  requestStatus: null,
  page: null,
  allCompanies: true,
  pageSize: 100,
  fullname: null,
  personalCode: null,
  country: null,
  state: null,
  documentType: null,
  identificationId: null,
  rejectionReasons: null
})

const options = () => ({
  dateOptions: null,
  reviewStatusOptions: null,
  requestStatusOptions: null
})

export default {
  components: {
    ResponsiveTableTr,
    MonitoringSetup
  },

  filters: {
    date: dateToString
  },

  data() {
    return {
      openFullSearch: false,
      scrollDown: false,
      lastScrollTop: 180,
      isReady: false,
      isLoading: true,
      isDownloading: false,
      identifications: null,
      itemsCount: 0,
      searchMethod: API.IDENTIFICATION_SEARCH,
      filters: filters(),
      options: options(),
      tableLabels: [
        'TableIdentificationId',
        'TableCreatedDate',
        'TableCompanyName',
        'TableFullName',
        'TableEmail',
        'TableReviewStatus',
        'TableRequestStatus'
      ],
      itemsHistoryCount: null,
      isOldTitle: true,
      interval: null,
      newIdentificationsCount: null,
      oldTitle: DefaultTitle,
      monitoringData: {
        identificationToken: null,
        monitoringStatusSelected: null,
        selectedMonitoringIndex: null
      }
    }
  },

  computed: {
    countryWithStateList() {
      return [
        COUNTRIES_WITH_STATE.USA,
        COUNTRIES_WITH_STATE.CAN,
        COUNTRIES_WITH_STATE.MEX
      ]
    },

    ...mapGetters([getters.companies]),

    isMultiCompany() {
      return this.companies.length > 1
    },

    countriesOptions() {
      return mapOptions(['XXX', ...this.nationalities])
    },

    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities
    },

    fullSearchText() {
      if (this.openFullSearch) return 'HideDetailedSearchText'
      else return 'DetailedSearchText'
    },

    documentTypes() {
      const { documentTypes } = this.$store.getters
      return mapOptions(documentTypes)
    },

    isSuperAdmin() {
      return true
    },

    exportOptions() {
      return mapOptions(['PDF', 'CSV'])
    },

    hasMonitoring() {
      const { selectedCompany } = Store.getters
      const currentCompany = this.$store.state.settings.companies.find(
        (company) => selectedCompany === company.id
      )

      return currentCompany.security.products.includes(
        PRODUCTS.NATURAL_ENTITY_MONITORING
      )
    },

    ...mapGetters([getters.selectedCompany]),

    companyId() {
      const { selectedCompany } = this
      return selectedCompany || null
    },

    hasThirdPartyExport() {
      return this.identifications?.some(
        (item) => item?.exportStatus?.thirdPartyExport
      )
    },

    listLabels() {
      const list = [...this.tableLabels]

      if (this.hasThirdPartyExport) {
        list.push('Export Status')
      }

      if (this.hasMonitoring) {
        list.push('Monitoring')
      }

      list.push(null)
      return list
    },

    rejectionReasons() {
      const { rejectionReasons } = this.$store.getters
      return mapOptions(rejectionReasons)
    },

    stateOptionList() {
      const { countryWithStateList } = this
      const { country } = this.filters
      if (!country) return
      if (!countryWithStateList.includes(country)) return

      let stateList
      if (country === COUNTRIES_WITH_STATE.USA) stateList = usaStates
      else if (country === COUNTRIES_WITH_STATE.CAN) stateList = canStates
      else if (country === COUNTRIES_WITH_STATE.MEX) stateList = mexStates

      return stateList?.map((item) => ({
        key: item,
        value: `${country}_${item}`
      }))
    }
  },

  watch: {
    itemsCount(newValue) {
      this.newIdentificationsCount = newValue - this.itemsHistoryCount
      if (this.itemsHistoryCount !== null && this.newIdentificationsCount > 0) {
        this.$notify({
          type: 'info-with-sound',
          message: 'InfoNewIdentification',
          messageData: { messageData: this.newIdentificationsCount }
        })
        clearInterval(this.interval)
        this.interval = setInterval(this.changeTitle, 700)
        document.addEventListener('mouseover', this.clearInt)
      }
      this.itemsHistoryCount = newValue
    },

    filters: {
      deep: true,
      handler() {
        this.itemsHistoryCount = null
      }
    },

    'filters.country'() {
      this.filters.state = null
    }
  },

  async created() {
    const { createFilters } = this
    await createFilters()
    this.isReady = true
  },

  methods: {
    handleRejectionReasonsSelect(value) {
      this.filters.rejectionReasons = value
    },

    changeMonitoringStatus(value, index) {
      const status = value
        ? MONITORING_STATUS.IS_MONITORED
        : MONITORING_STATUS.NOT_MONITORED

      this.$set(
        this.identifications[index].monitoringData,
        'monitoringStatus',
        status
      )
    },

    hideToggle(requestStatus, reviewStatus) {
      return !(
        ((requestStatus === REQUEST_STATUS.PASSED_AUTO &&
          reviewStatus === REVIEW_STATUS.CROSS_CHECKED) ||
          (requestStatus === REQUEST_STATUS.PASSED_MANUAL &&
            reviewStatus === REVIEW_STATUS.CROSS_CHECKED)) &&
        this.hasMonitoring
      )
    },

    getMonitoringStatus(status) {
      return status === MONITORING_STATUS.IS_MONITORED
    },

    changeMonitoring(row, index) {
      const { monitoringData } = this
      this.$set(
        monitoringData,
        'monitoringStatus',
        this.getMonitoringStatus(row.monitoringData.monitoringStatus)
      )
      this.$set(monitoringData, 'index', index)
      this.$set(monitoringData, 'identificationId', row.token)
      this.$set(monitoringData, 'monitoringId', row.monitoringData.id)
      this.$openPopUp()
    },

    copyIdentificationId(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },

    classList(item) {
      const { isSuperAdmin } = this
      const list = []

      if (item.requestStatus === 'PENDING') {
        list.push('status-pending')
      } else if (item.requestStatus === 'REJECTED') {
        list.push('status-rejected')
      }

      if (isSuperAdmin && this.isTimeOver(item.finished, item.crossChecked)) {
        list.push('not-in-time')
      } else if (!item.crossChecked) {
        list.push('not-checked')
      }

      return list
    },

    clickAllIdentification() {
      if (this.identifications) {
        this.identifications.forEach((item) => {
          const routeData = this.$router.resolve({
            name: 'IdentificationDetails',
            params: { identificationId: item.token }
          })
          window.open(routeData.href, '_blank')
        })
      }
    },

    changeTitle() {
      const newTitle = `${this.$t('InfoNewIdentification', {
        messageData: this.newIdentificationsCount
      })}`
      document.title = this.isOldTitle ? this.oldTitle : newTitle
      this.isOldTitle = !this.isOldTitle
    },

    clearInt() {
      clearInterval(this.interval)
      document.title = this.oldTitle
      document.removeEventListener('mouseover', this.clearInt)
    },

    async createFilters() {
      const { filters, options, getFilters } = this
      options.dateOptions = createDateFilters()
      if (options.dateOptions.length) {
        filters.date = options.dateOptions[0].key
      }
      const response = await getFilters()
      if (response) {
        options.reviewStatusOptions = mapOptions(response.reviewStatuses)
        options.requestStatusOptions = mapOptions(response.requestStatuses)
      }
    },

    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      const { list, total } = data
      this.identifications = list
      this.count = null
      this.itemsCount = total
      this.isLoading = false
    },

    getFullName(item) {
      const { firstname, lastname } = item
      return [firstname, lastname].filter((value) => value).join(' ')
    },

    createPayload(filters) {
      return {
        companyId: this.companyId,
        from: new Date(`${filters.date?.[0]}T00:00`).toISOString(),
        to: new Date(`${filters.date?.[1]}T24:00`).toISOString(),
        email: (filters.email && filters.email.trim()) || undefined,
        reviewStatus: filters.reviewStatus || undefined,
        allCompanies: filters.allCompanies || undefined,
        requestStatus: filters.requestStatus || undefined,
        rejectionReasons: filters.rejectionReasons || undefined,
        fullname: filters.fullname || undefined,
        personalCode: filters.personalCode || undefined,
        country: filters.country || undefined,
        state: filters.state || undefined,
        documentType: filters.documentType || undefined,
        identificationId: filters.identificationId || undefined
      }
    },

    isTimeOver(finishedDate, crossCheckedDate) {
      if (finishedDate && crossCheckedDate) {
        const firstDate = new Date(finishedDate)
        const secondDate = new Date(crossCheckedDate)
        if (secondDate < firstDate) {
          secondDate.setDate(secondDate.getDate() + 1)
        }

        const diff = secondDate - firstDate
        const diffMinutes = Math.floor(diff / 1000 / 60)
        if (diffMinutes > 3) return true
        else return false
      }
    },

    async exportList(value) {
      if (value === 'PDF') {
        this.exportPdfFormat()
      } else if (value === 'CSV') {
        await this.exportCsvFormat()
      } else if (value === 'XLS') {
        await this.exportCsvFormat()
      }
    },

    async exportPdfFormat() {
      try {
        this.isDownloading = true

        const payload = {
          ...this.createPayload(this.filters),
          page: 1,
          pageSize: 5000,
          allCompanies: false
        }

        const identifications = await Api.post(
          API.IDENTIFICATION_SEARCH,
          payload
        )

        const name = this.$store.getters.sessionData.profile.name
        const data = {
          tableLabels: [
            'TableIdentificationId',
            'TableDate',
            'TableCompanyName',
            'TableFullName',
            'TableReviewStatus',
            'TableRequestStatus'
          ].map((item) => this.$t(item)),
          tableRows: identifications.list.map((item) => [
            item.token.replace(/(.{1,20})/g, '$1 \n') || '',
            item.finished ? item.finished.replace('T', ' ') || '' : null,
            item.companyName || '',
            this.getFullName(item).replace(/(.{1,20})/g, '$1 \n') || '',
            this.$t(item.reviewStatus) || '',
            this.$t(item.requestStatus) || ''
          ]),
          filters: {
            TableDate: this.filters.date,
            TableEmail: this.filters.email,
            TableReviewStatus: this.filters.requestStatus,
            TableRequestStatus: this.filters.reviewStatus
          }
        }
        const exportFile = new exportPdf(data, name)
        await exportFile.exportTable()
        this.isDownloading = false
      } catch (error) {
        this.isDownloading = false
        this.showError()
      }
    },

    async exportCsvFormat() {
      const { filters, isReady, createPayload } = this
      if (!isReady || !filters.date) return

      try {
        this.isDownloading = true
        const payload = createPayload(filters)
        const blob = await Api.getBlobWithParams(
          '/identifications/download/csv',
          {
            ...payload,
            allCompanies: false
          }
        )
        downloadBlob(blob)
      } catch (error) {
        this.showError()
      }
      this.isDownloading = false
    },

    async getFilters() {
      this.isLoading = true
      try {
        const data = await Api.get('/identifications/settings')
        return data
      } catch (error) {
        this.showError()
      } finally {
        this.isLoading = false
      }
      return null
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.margin-right-3 {
  margin-right: 3rem;
}

.margin-1 {
  margin: 0 1rem;
}

.hidden-button {
  display: none;
}

.table-message {
  text-align: center;
  background-color: #fffbf6;
  margin-top: -0.5rem;
}

.cell-identification-id-desktop {
  max-width: 12vw;
}

.identification-id-desktop {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-header {
  margin-bottom: 4rem;
}

.flex-flow-wrap {
  flex-flow: row wrap;
}

.all-search-inputs {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 1rem;
}

.all-search-inputs > * {
  display: inline-block;
  width: 200px;
}

.td-link {
  width: 18rem;
}

.td-link > * {
  margin-bottom: 0.5rem;
  min-width: 6rem;
}

.td-link > *:first-child {
  margin-right: 1rem;
}

.fixed-header .flex-flow-wrap {
  flex-flow: row nowrap;
}

.fixed-header .flex-flow-wrap > * {
  width: 210px;
}

.full-name {
  text-transform: uppercase;
}

.fullsearh-link {
  color: var(--white);
  cursor: pointer;
  margin: 0.25rem 1rem 0.5rem 0;
}

.fullsearh-link:hover {
  text-decoration: underline;
}

.open-fullsearh-link {
  width: 100%;
}

.filter-small-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.fixed-header .filter-small-block {
  position: absolute;
  bottom: -2.75rem;
  left: 0;
  text-align: left;
  margin-bottom: 1.5rem;
}

.fixed-header .fullsearh-link {
  left: 2rem;
  right: auto;
  color: var(--dark-br);
  bottom: 0.75rem;
}

.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

.fixed-header .all-search-inputs > *::placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-webkit-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *::-moz-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-ms-input-placeholder {
  color: var(--dark-br);
}

.fixed-header .all-search-inputs > *:-moz-placeholder {
  color: var(--dark-br);
}

.filters-block {
  width: 100%;
}

@media all and (max-width: 992px) {
  .flex-flow-wrap .filter-small-block {
    display: block;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
  }

  .filter-small-block > * {
    display: inline-block;
  }

  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }

  .all-search-inputs {
    flex-flow: row wrap;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    max-width: 45%;
    width: 45%;
  }
}

@media (max-width: 678px) {
  .all-search-inputs {
    flex-direction: column;
  }

  .all-search-inputs > *,
  .flex-flow-wrap > *,
  .header-content .filters-block .m-input-icon.m-input-icon--wide {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
<style>
.identification-page .filter-small-block .m-checkbox--state-brand {
  margin-top: 0;
  margin-bottom: 0.25rem;
}

.all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
  width: 400px;
}

.header-content .filters-block .m-input-icon.m-input-icon--wide {
  width: 200px;
}

.fixed-header .m-subheader-search__title,
.fixed-header .ui-toggle--header {
  visibility: hidden;
}

.fixed-header .header-content {
  position: fixed;
  top: 3.75rem;
  z-index: 50;
  left: 110px;
  background: var(--white);
  text-align: right;
  flex-wrap: nowrap;
  width: calc(100% - 110px);
  padding: 1.75rem 80px 2rem 30px;
  height: auto;
  box-shadow: 2px 3px 3px 0 var(--light-br-h-gray);
}

.fixed-header .header-content .filters-list .flex-flow-wrap > * {
  width: 200px;
}

.fixed-header .header-content .filters-block {
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
}

.fixed-header .header-content .filters-list {
  flex-wrap: nowrap;
}

.fixed-header .header-content .filters-list label {
  display: none;
}

.fixed-header .header-content .filters-list .ui-dropdown button,
.fixed-header .header-content .filters-list input {
  border-color: var(--dark-br);
  color: var(--dark-br);
  height: 42px;
}

.fixed-header .header-content .filters-list textarea {
  border-color: var(--dark-br);
  color: var(--dark-br);
}

.fixed-header .header-content .filters-list input::placeholder,
.fixed-header .header-content .filters-list textarea::placeholder {
  color: var(--dark-br);
}

.fixed-header .ui-button.m-subheader-search__submit-btn {
  border: 1px solid var(--brand);
  height: 42px;
}

@media (max-width: 1024px) {
  .fixed-header .header-content {
    visibility: hidden;
  }
}

@media (max-width: 992px) {
  .all-search-inputs .countries-dropdown .dropdown-box > .dropdown-menu {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .identification-page .m-subheader-search .m-input-icon {
    margin-right: 0;
  }
}
</style>

<style>
.identification-page .not-in-time td.review-status,
.identification-page .not-in-time .review-status .tr-value {
  font-style: italic;
  color: red;
}

.identification-page .not-checked td.review-status,
.identification-page .not-checked .review-status .tr-value {
  font-weight: 700;
}
</style>
