var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.labels
    ? _c(
        "div",
        { staticClass: "header-tabs" },
        [
          _vm._l(_vm.labels, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tab-item",
                class: {
                  active: _vm.selected === index,
                  "has-status": item.status != null,
                },
                on: {
                  click: function ($event) {
                    return _vm.onChange(index)
                  },
                },
              },
              [
                _vm._v("\n    " + _vm._s(item.label) + "\n    "),
                item.status != null
                  ? _c("span", { class: item.status ? "success" : "failed" })
                  : _vm._e(),
              ]
            )
          }),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "tabs-loader" },
                [_c("ui-loader", { attrs: { size: 1.5, center: "" } })],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }