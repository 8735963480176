var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-container",
    { staticClass: "video-call-page" },
    [
      _c(
        "ui-card",
        { attrs: { title: "CallTitle", multicolumn: _vm.isReady } },
        [
          _vm.isReady
            ? _c(
                "ui-widget",
                {
                  attrs: { slot: "body-left", "no-spacing": "" },
                  slot: "body-left",
                },
                [
                  _c("ui-toggle", {
                    attrs: {
                      label: _vm.isAvailable ? "Online" : "offline",
                      value: _vm.isAvailable,
                      disabled: _vm.isCallAccepted,
                    },
                    on: { change: _vm.workerStatusChange },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isReady
            ? _c(
                "ui-widget",
                {
                  attrs: { slot: "body-right", "no-spacing": "" },
                  slot: "body-right",
                },
                [
                  _c("div", { staticClass: "call-count" }, [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("CallTotalRequests")) +
                          ": " +
                          _vm._s(_vm.callsCount) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              )
            : _c("ui-loader", { attrs: { center: "" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ui-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasReservation,
              expression: "hasReservation",
            },
          ],
          attrs: { multicolumn: "" },
        },
        [
          _c(
            "ui-widget",
            {
              attrs: { slot: "body-left", "no-spacing": "" },
              slot: "body-left",
            },
            [
              _vm.isCallAccepted
                ? [
                    _vm.testCompleted
                      ? [
                          _vm.isCallAccepted
                            ? _c(
                                "tabs-group",
                                {
                                  attrs: {
                                    "tab-index": _vm.cameraTabIndex,
                                    labels: _vm.cameraTabLabels,
                                    indicators: _vm.indicators,
                                  },
                                  on: { change: _vm.cameraTabChanged },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "camera-box",
                                      class: {
                                        "tab-hidden": _vm.isCameraHidden,
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "remote-camera",
                                        attrs: { id: "remote-camera" },
                                      }),
                                      _vm._v(" "),
                                      _vm.room
                                        ? _c("div", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.cameraTabIndex === 0,
                                                expression:
                                                  "cameraTabIndex === 0",
                                              },
                                            ],
                                            staticClass: "local-camera",
                                            attrs: { id: "local-camera" },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("photo-capture-box", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.cameraTabIndex === 1,
                                        expression: "cameraTabIndex === 1",
                                      },
                                    ],
                                    attrs: {
                                      value: _vm.media.face,
                                      label: "CallCaptureFace",
                                      disabled: _vm.media.isFaceLoading,
                                      selector: _vm.remoteSelector,
                                    },
                                    on: { change: _vm.faceChanged },
                                  }),
                                  _vm._v(" "),
                                  _c("photo-capture-box", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.cameraTabIndex === 2,
                                        expression: "cameraTabIndex === 2",
                                      },
                                    ],
                                    attrs: {
                                      value: _vm.media.documentFront,
                                      label: _vm.buttonLabels.front,
                                      disabled:
                                        _vm.media.isDocumentFrontLoading,
                                      selector: _vm.remoteSelector,
                                    },
                                    on: { change: _vm.documentFrontChanged },
                                  }),
                                  _vm._v(" "),
                                  _c("photo-capture-box", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.cameraTabIndex === 3,
                                        expression: "cameraTabIndex === 3",
                                      },
                                    ],
                                    attrs: {
                                      value: _vm.media.documentBack,
                                      label: _vm.buttonLabels.back,
                                      disabled: _vm.media.isDocumentBackLoading,
                                      selector: _vm.remoteSelector,
                                    },
                                    on: { change: _vm.documentBackChanged },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                "ui-button",
                                {
                                  staticClass: "btn-outline-brand",
                                  attrs: { small: "", bordered: "" },
                                  on: { click: _vm.finishCall },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("CallEnd")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "ui-button",
                                {
                                  staticClass: "btn-brand",
                                  attrs: {
                                    small: "",
                                    disabled:
                                      !_vm.hasPhotos || _vm.verifyLoading,
                                  },
                                  on: { click: _vm.comparePhotos },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("CallProcessPhoto")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("ui-dropdown", {
                                attrs: {
                                  value: _vm.documentType,
                                  placeholder: "Document type",
                                  options: _vm.options,
                                },
                                on: { input: _vm.documentChanged },
                              }),
                              _vm._v(" "),
                              _vm.isReconnectRequired
                                ? _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-info",
                                      attrs: { small: "" },
                                      on: { click: _vm.reconnectAgent },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("CallReconnect")) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : _c("ui-loader", { attrs: { center: "" } }),
                  ]
                : _c(
                    "div",
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "btn-brand",
                          attrs: { small: "" },
                          on: { click: _vm.acceptCall },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("CallAccept")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _vm.ids
                ? _c("div", { staticClass: "mt-4" }, [
                    _vm.ids.identificationId
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "pr-1" }, [_vm._v("Id:")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "btn-link m-0",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick(
                                    _vm.ids.identificationId
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.ids.identificationId) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ids.taskId
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("div", { staticClass: "pr-1" }, [
                            _vm._v("Call Id:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "btn-link m-0",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick(_vm.ids.taskId)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.ids.taskId) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ui-widget",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isConnected && _vm.isCallAccepted,
                  expression: "isConnected && isCallAccepted",
                },
              ],
              attrs: { slot: "body-right", "no-spacing": "" },
              slot: "body-right",
            },
            [
              _c(
                "tabs-group",
                {
                  staticClass: "details-tab-group",
                  attrs: {
                    "tab-index": _vm.detailsTabIndex,
                    labels: _vm.detailsTabLabels,
                  },
                  on: { change: _vm.detailsTabChanged },
                },
                [
                  _c("iframe", {
                    ref: "form",
                    class: { "tab-hidden": _vm.detailsTabIndex !== 0 },
                    attrs: { src: _vm.formSource },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.detailsTabIndex === 1,
                          expression: "detailsTabIndex === 1",
                        },
                      ],
                      staticClass: "validation-content",
                    },
                    [
                      _vm.faceMatchScore
                        ? _c(
                            "div",
                            { staticClass: "content-box m-widget1" },
                            [
                              _c(
                                "details-box",
                                { staticClass: "rules-box" },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(_vm.$t("FaceMatchEvaluation"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("details-item", {
                                    attrs: {
                                      label: "SCORES_PERSON_TO_DOCUMENT",
                                      value: _vm.faceMatchScore,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.rules
                        ? _c("rule-list", {
                            staticClass: "m-widget1",
                            attrs: {
                              title: "Validation rules",
                              data: _vm.rules,
                            },
                          })
                        : _c("h5", { staticClass: "m-widget1" }, [
                            _vm._v("Not validated"),
                          ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.detailsTabIndex === 2,
                          expression: "detailsTabIndex === 2",
                        },
                      ],
                      staticClass: "comments-content",
                    },
                    [_c("comments", { attrs: { comments: _vm.comments } })],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.ids && _vm.ids.identificationId
                ? _c(
                    "div",
                    { staticStyle: { height: "160px" } },
                    [
                      _c("hr"),
                      _vm._v(" "),
                      _c("comment-input", {
                        attrs: {
                          "identification-id": _vm.ids.identificationId,
                        },
                        on: { created: _vm.onCommentCreated },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }