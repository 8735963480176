var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot-password-wrapper" }, [
    _vm._m(0),
    _vm._v(" "),
    !_vm.isSuccess
      ? _c("div", { staticClass: "forgot-password" }, [
          _c("div", { staticClass: "forgot-password__head" }, [
            _c("h3", { staticClass: "forgot-password__heading-title" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("AppForgotPassword")) + "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "forgot-password__form" },
            [
              _c("ui-input", {
                attrs: {
                  type: "email",
                  placeholder: "DetailsEmail",
                  value: _vm.email,
                  error: _vm.error,
                },
                on: { input: _vm.handleError },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "forgot-password__actions" },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "btn-brand mx-auto",
                      attrs: { disabled: _vm.isLoading || _vm.email === "" },
                      on: { click: _vm.handleSubmit },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("AppSubmit")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", [
          _c("i", { staticClass: "fas fa-check" }),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$t("AppForgotPasswordSuccess")))]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "forgot-password__logo" }, [
      _c("img", {
        attrs: { src: require("@src/assets/images/ondato-logo.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }