<template>
  <div class="mrz-list">
    <div v-for="fieldName in MRZ_FIELDS" :key="fieldName" class="mrz-item">
      <ui-input
        v-if="editEnabled"
        v-model="fieldValues[fieldName]"
        type="text"
        :value="fieldValues[fieldName]"
        one-line
      />

      <div
        v-if="!editEnabled && isMrzLineNotEmpty(fieldName)"
        class="mrz-value"
      >
        {{ fieldValues[fieldName].toUpperCase() }}
      </div>

      <div v-if="isSymbolCountVisible(fieldName)" class="mrz-symbol-count">
        {{ fieldValues[fieldName].length }}
      </div>
    </div>
  </div>
</template>

<script>
export const MRZ_FIELDS = {
  MRZ_1: 'mrzLine1',
  MRZ_2: 'mrzLine2',
  MRZ_3: 'mrzLine3'
}

export default {
  props: {
    editEnabled: { type: Boolean, default: false },
    fieldValues: { type: Object, default: null },
    actionInProgress: { type: Boolean, default: false },
    documentUpdating: { type: Boolean, default: false },
    mrzLinesCount: { type: Object, default: null }
  },

  data() {
    return {
      MRZ_FIELDS
    }
  },

  methods: {
    isMrzLineNotEmpty(fieldName) {
      return this.mrzLinesCount[fieldName] > 0
    },

    isSymbolCountVisible(fieldName) {
      return (
        this.editEnabled ||
        (!this.editEnabled && this.isMrzLineNotEmpty(fieldName))
      )
    }
  }
}
</script>

<style>
.mrz-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.mrz-value {
  font-family: var(--mrz-font-family);
  font-weight: 400;
  letter-spacing: 0.0875rem;
  color: var(--dark-font);
  overflow-wrap: anywhere;
}

.mrz-symbol-count {
  width: 30px;
  text-align: right;
}

.mrz-item .form-control.m-input {
  text-align: left;
  text-transform: uppercase;
  font-family: var(--mrz-font-family);
  font-weight: 400;
  letter-spacing: 0.0875rem;
}
</style>
