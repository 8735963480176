var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-container",
    [
      _c(
        "ui-card",
        { attrs: { title: "CompanyReports" } },
        [
          _c(
            "ui-button",
            {
              staticClass: "btn-link tools-div",
              attrs: { slot: "tools" },
              on: { click: _vm.handleAddReportClick },
              slot: "tools",
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("AddNewReport")))]),
              _vm._v(" "),
              _c("i", { staticClass: "la la-plus" }),
            ]
          ),
          _vm._v(" "),
          _c(
            "ui-table",
            {
              attrs: {
                loading: _vm.loading,
                labels: _vm.tableLabels,
                data: _vm.listItems,
              },
            },
            _vm._l(_vm.listItems, function (item, index) {
              return _c("company-report-column", {
                key: index,
                attrs: { item: item },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("ui-page-selector", {
            attrs: {
              "active-page": _vm.page,
              "items-count": _vm.total,
              "max-on-page": _vm.pageSize,
              disabled: _vm.loading,
            },
            on: { change: _vm.pageChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        { attrs: { "full-size": "" } },
        [
          _c("company-report-create-form", {
            on: { listUpdateEvent: _vm.handleReload },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }