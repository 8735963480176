var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-block" }, [
    _c("div", [_vm._v(_vm._s(_vm.$t(_vm.label)) + ":")]),
    _vm._v(" "),
    _c("div", { staticClass: "orange-small-text" }, [
      _vm._v("\n    " + _vm._s(_vm.value) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }