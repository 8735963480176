var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-with-header", class: { "fixed-height": _vm.fixed } },
    [
      !_vm.noHeader
        ? _c("div", { staticClass: "sticky-header" }, [_vm._t("header")], 2)
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }