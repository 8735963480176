var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasRules
    ? _c(
        "div",
        { staticClass: "content-box" },
        [
          _c("h5", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
          _vm._v(" "),
          _vm.addButton
            ? _c(
                "ui-button",
                {
                  staticClass: "btn btn-danger",
                  attrs: {
                    disabled: _vm.isLoading,
                    small: "",
                    low: "",
                    "small-text": "",
                  },
                  on: { click: _vm.clickReprocess },
                },
                [
                  _vm._v("\n    " + _vm._s(_vm.$t("Reprocess")) + "\n    "),
                  _vm.isLoading
                    ? _c("span", [
                        _vm._v(
                          " (" +
                            _vm._s(_vm.time + " " + _vm.$t("Second")) +
                            ") "
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.data, function (item, index) {
            return _c("rule-item", {
              key: index,
              attrs: {
                name: item.name,
                "no-status": _vm.noStatus,
                status: item.status,
                reason: item.failReason,
                reasons: item.failReasons,
                action: item.action,
                type: _vm.type,
              },
            })
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }