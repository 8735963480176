<template>
  <div
    class="ui-input form-group m-form__group"
    :class="{
      'has-error': error,
      'ui-touched': istouched,
      'ui-untouched': !istouched,
      'type-password': type === 'password'
    }"
  >
    <div class="input-box">
      <label
        v-if="label"
        class="col-form-label"
        :class="{ 'col-lg-5': !multiline }"
      >
        {{ $t(label) }}
      </label>
      <textarea
        v-if="
          type !== 'password' &&
          type !== 'email' &&
          type !== 'header-multi-select'
        "
        class="form-control m-input"
        :rows="rows"
        :class="{
          'col-lg-7': label && !multiline,
          'with-overflow': multilineOverflow
        }"
        :placeholder="$t(placeholder)"
        :type="passwordType || type"
        :value="value"
        :disabled="disabled"
        :maxlength="maxLength"
        @input="inputValue"
        @keydown="keydown"
        @keypress="$emit('keypress', $event.target.value)"
        @change="$emit('change', $event.target.value)"
        @blur="lostFocus"
        @click="$emit('click', $event.target.value)"
      />
      <input
        v-else
        class="form-control m-input"
        :class="{
          'col-lg-7': label && !multiline,
          'text-overflow-ellipses': textOverflowEllipses
        }"
        :placeholder="$t(placeholder)"
        :type="passwordType || type"
        :value="value"
        :disabled="disabled"
        :maxlength="maxLength"
        @input="inputValue"
        @keydown="keydown"
        @keypress="$emit('keypress', $event.target.value)"
        @change="$emit('change', $event.target.value)"
        @blur="lostFocus"
        @click="$emit('click', $event.target.value)"
      />
      <a
        v-if="type === 'password'"
        class="password-show"
        @mousedown="showPassword"
        @touchstart="showPassword"
        @mouseup="hidePassword"
        @touchend="hidePassword"
      >
        <i class="la la-eye" />
      </a>
    </div>
    <span
      class="error-message"
      :class="{ 'error-offset': label && !multiline }"
    >
      {{ $t(error, { minLength }) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ui-input',

  props: {
    type: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined },
    label: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    error: { type: String, default: undefined },
    minLength: { type: Number, default: 3 },
    maxLength: { type: Number, default: undefined },
    rows: { type: Number, default: 1 },
    disabled: Boolean,
    hasIcon: Boolean,
    multiline: Boolean,
    multilineOverflow: { type: Boolean, default: false },
    oneLine: Boolean,
    textOverflowEllipses: Boolean
  },

  data() {
    return {
      istouched: false,
      passwordType: null
    }
  },

  mounted() {
    this.setInputHeight()
  },

  methods: {
    keydown(event) {
      if (event.key === 'Enter' && this.oneLine) {
        event.preventDefault()
      }
    },

    lostFocus() {
      if (!this.istouched && !this.multilineOverflow) {
        this.istouched = true
      }
      this.$emit('blur')
    },

    showPassword() {
      this.passwordType = 'text'
    },

    hidePassword() {
      this.passwordType = null
    },

    setInputHeight() {
      const el = this.$el.querySelector('.m-input')
      if (!this.oneLine) {
        if (el.scrollHeight > 52) {
          el.style.minHeight = 'auto'
          el.style.minHeight = el.scrollHeight + 'px'
        } else {
          el.style.minHeight = '42px'
        }
      } else {
        el.rows = '1'
        el.wrap = 'off'
      }
    },

    inputValue(event) {
      this.$emit('input', event.target.value)
      if (!this.multilineOverflow) {
        this.setInputHeight()
      }
    }
  }
}
</script>

<style scoped>
.text-overflow-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-input {
  flex-direction: column;
}

textarea.m-input {
  min-height: 42px;
}

textarea.with-overflow {
  height: 250px;
  overflow: auto;
}

input.m-input {
  height: 42px;
}

.m-input {
  overflow: hidden;
  resize: none;
  font-size: 0.9rem;
  line-height: 1.25;
  font-family: 'Poppins', sans-serif;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.input-box {
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ui-input,
.input-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.ui-input.form-group {
  margin-bottom: 0;
}

.ui-input.no-border.form-group.m-form__group > .input-box input.form-control {
  border-bottom: none;
}

.type-password .form-control {
  padding-right: 3.5rem;
}

.password-show {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  width: 3rem;
  display: flex;
}

.password-show:hover {
  cursor: pointer;
  background-color: var(--light);
}

.password-show:active {
  opacity: 0.8;
}

.password-show > i {
  font-size: 1.75rem;
  margin: auto;
  color: var(--gray);
}

.error-message {
  text-align: left;
  align-self: flex-start;
}

@media (min-width: 992px) {
  .col-form-label.col-lg-5 {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }

  .error-offset {
    margin-left: 41.66667%;
  }
}
</style>

<style>
.el-date-editor .el-input__inner {
  height: 42px;
}
</style>
