<template>
  <div>
    <pop-up @close="closePopUp">
      <user-confirmation />
    </pop-up>
  </div>
</template>

<script>
import UserConfirmation from '@src/components/partials/UserConfirmation'

export default {
  components: {
    UserConfirmation
  },

  created() {
    this.$openPopUp()
  },

  methods: {
    closePopUp() {
      this.$router.replace({ name: 'Landing' })
    }
  }
}
</script>
