var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ui-header", { attrs: { title: "CheckSettingHeader" } }),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c("ui-card", [
            _c("div", [
              _c("h3", [_vm._v(_vm._s(_vm.$t("CheckSettingBeforeHeader")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-row" }, [
              _c("div", { staticClass: "flex-basic-70" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("CheckSettingBeforeDescription")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex-basic-30" },
                [_c("question-box", { attrs: { questions: _vm.questions } })],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "ui-card",
            { attrs: { title: "CheckSettingMethodHeader" } },
            [_c("checking-methods")],
            1
          ),
          _vm._v(" "),
          _c(
            "ui-card",
            { attrs: { title: "CheckSettingCountriesTitle" } },
            [_c("regional-settings")],
            1
          ),
          _vm._v(" "),
          _c(
            "ui-card",
            { attrs: { title: "CheckDetailedCountrySettingsTitle" } },
            [_c("country-settings")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }