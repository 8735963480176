<template>
  <router-view @changePage="changePage" />
</template>

<script>
export default {
  methods: {
    changePage(value, filters) {
      this.$set(filters, 'page', value)
    }
  }
}
</script>
