<template>
  <div v-if="!isReady" class="loader-style">
    <ui-loader :size="4" center />
  </div>
  <div v-else-if="data" class="ultimate-beneficial-owner">
    <ui-widget title="KybTimeTitle" :title-value="renewTime">
      <details-item
        label="DetailsCompanyName"
        :value="data.name ? data.name : mainCompanyName"
      >
        <div>
          <router-link
            :to="{ name: 'SanctionsSearch', query: sanctionsSearchQuery }"
            target="_blank"
            @click.native="
              (event) => {
                handleClick({
                  event,
                  name: data.name
                })
              }
            "
          >
            {{ $t('DetailsScreenSanctions') }}
            <i class="fas fa-external-link-square-alt"></i>
          </router-link>
        </div>
      </details-item>
      <details-item label="DetailsCompanyCode" :value="data.code" />
      <div v-for="(owner, index) in allOwners" :key="index">
        <p class="level-title">
          {{ $t('DetailsLevelOwnerCompany', { level: owner.level }) }}
        </p>

        <div>
          <details-item
            v-for="(details, key) in filterOwner(owner)"
            :key="key"
            :label="$t(getLabel(key))"
            :value="
              getDetails(details, key, owner)
                ? getDetails(details, key, owner)
                : '-'
            "
          >
            <div
              v-if="
                key === 'companyName' || (key === 'firstName' && owner.lastName)
              "
            >
              <router-link
                :to="{ name: 'SanctionsSearch', query: sanctionsSearchQuery }"
                target="_blank"
                @click.native="
                  (event) => {
                    const nameResolved =
                      key === 'firstName'
                        ? `${owner[key]} ${owner.lastName}`
                        : owner[key]
                    handleClick({
                      event,
                      name: nameResolved
                    })
                  }
                "
              >
                {{ $t('DetailsScreenSanctions') }}
                <i class="fas fa-external-link-square-alt"></i>
              </router-link>
            </div>
          </details-item>
        </div>
      </div>
    </ui-widget>
    <ui-widget title="RelationshipScheme" class="tree-chart-wrapper">
      <template v-if="treeData">
        <TreeChart :json="treeData" />
        <ui-button
          v-if="isLoadMoreVisible"
          class="btn-brand btn-more"
          low
          small
          data-html2canvas-ignore
          @click="loadMore"
        >
          {{ $t('DetailsLoadMore') }}
        </ui-button>
        <ui-button
          class="btn-brand btn-export"
          icon="la la-download"
          low
          small
          data-html2canvas-ignore
          @click="exportStructure"
        >
          {{ $t('AppExportStructure') }}
        </ui-button>
      </template>
      <template v-else>
        <img
          class="icon"
          :src="require('@src/assets/images/icons/file-slash.svg')"
        />
        <p v-if="companyCountryCode.toUpperCase() === uboLoadMoreListIso2.LT">
          {{ $t('UBONotFoundLt') }}
        </p>
        <p v-else>{{ $t('UBONotFound') }}</p>
      </template>
    </ui-widget>
  </div>
  <div v-else-if="searchMessage" class="search-message-style">
    {{ $t(searchMessage) }}
  </div>
</template>

<script>
import TreeChart from 'vue-tree-chart'
import html2canvas from 'html2canvas'
import { mapGetters } from 'vuex'
import { notificationType } from '@src/components/notification'
import DetailsItem from '@src/components/partials/DetailsItem'
import {
  dateToString,
  downloadUrl,
  getCountryISOCode,
  deepClone
} from '@src/scripts/helpers'
import {
  pageTypeEnum,
  iso2Iso3Counties,
  uboLoadMoreListIso2,
  uboLoadMoreList
} from '@src/scripts/enums'
import { EXPORT_STRUCTURE } from '@src/scripts/constants'
import { API } from '@src/scripts/enums'
import Api from '@src/scripts/api'
import getters from '@src/store/getters'

export default {
  components: {
    TreeChart,
    DetailsItem
  },

  props: {
    companyCode: { type: String, default: undefined },
    companyCountryCode: { type: String, default: undefined },
    renewTime: { type: Object, default: undefined },
    mainCompanyName: { type: String, default: undefined }
  },

  data() {
    return {
      isReady: false,
      isLoading: false,
      data: null,
      searchMessage: null,
      isLoadMoreVisible: false,
      currentPepName: '',
      ownersList: [],
      treeData: null,
      level: 0,
      allOwners: [],
      uboLoadMoreListIso2
    }
  },

  computed: {
    ...mapGetters([getters.havePermission]),

    filteredData() {
      return {
        ...this.data,
        levels: this.data.levels.filter((level) => level.owners.length)
      }
    },

    sanctionsSearchQuery() {
      return { searchTerm: this.currentPepName }
    },
    hasSanctionSearchAccess() {
      return this.havePermission(pageTypeEnum.sanctionsScreening)
    }
  },

  created() {
    this.getOwners()
  },

  methods: {
    hasOwners(item) {
      const { owners } = item
      return owners && owners.length
    },
    getAllOwners(data) {
      if (data.length) {
        this.level++
        data.map((owner) => {
          this.allOwners.push({ ...owner, level: this.level })
          this.getAllOwners(owner.owners)
        })
      }
    },
    createTreeData(ownerData) {
      if (ownerData && ownerData.owners && ownerData.owners.length > 0) {
        const treeData = { name: null, children: [] }
        const data = JSON.parse(JSON.stringify(ownerData))
        treeData.name = data.companyName
          ? data.companyName
          : this.mainCompanyName
        treeData.children = this.mapTreeData(data.owners)

        return treeData
      }
      return null
    },
    mapTreeData(data) {
      const children = []
      data.forEach((owner) => {
        if (owner.firstName && owner.lastName) {
          children.push({
            name: `${owner.firstName} ${owner.lastName}`,
            code: owner.subjectCode,
            children: owner.owners.length
              ? this.mapTreeData(owner.owners)
              : null
          })
        } else if (owner.companyName) {
          children.push({
            name: owner.companyName,
            code: owner.subjectCode,
            children: owner.owners.length
              ? this.mapTreeData(owner.owners)
              : null
          })
        }
      })

      return children
    },
    async getOwners() {
      try {
        const { data } = await Api.get(API.KYB_GENERIC_UBO, {
          companyCode: this.companyCode,
          country: this.companyCountryCode
        })
        this.data = deepClone(data)
        this.getAllOwners(data.owners)
        this.treeData = this.createTreeData(data)
        this.isLoadMoreVisible = data.owners.some(
          ({ countryCode }) =>
            (countryCode === uboLoadMoreList.EST ||
              countryCode === uboLoadMoreList.LVA) &&
            this.companyCountryCode.toUpperCase() === uboLoadMoreListIso2.LT
        )
      } catch (e) {
        this.searchMessage = 'KybUltimateBeneficialOwnerNotFound'
      }
      this.isReady = true
    },

    updateTreeData(data, parentCode) {
      const trData = this.treeData
      trData.children.filter((child) =>
        child.code === parentCode
          ? (child.children = this.mapTreeData(data))
          : child
      )
      this.treeData = JSON.parse(JSON.stringify(trData))
    },

    getDetails(details, key, data) {
      if (key === 'relTypeEn') return this.$t(details)
      else if (key === 'countryName')
        return this.$t(
          `Country.${
            data.countryCode.length === 2
              ? data.countryCode
              : getCountryISOCode(data.countryCode)
          }`
        )
      else if (key === 'dateRegisteredFrom') return dateToString(details)
      else if (key === 'owners') return data.owners.length ? data.owners : null
      else return details
    },

    getLevel(level) {
      if (level === 1) return 'DetailsOwnerCompany'
      else if (level > 1) return 'DetailsLevelOwnerCompany'
    },

    getLabel(key) {
      const name = key[0].toUpperCase() + key.slice(1)
      return `Details${name}`
    },

    loadMore() {
      this.isLoadMoreVisible = false

      this.ownersList = this.data.owners.map(
        ({ countryCode, subjectCode }) => ({ countryCode, subjectCode })
      )

      this.ownersList.forEach(async ({ countryCode, subjectCode }) => {
        try {
          const { data } = await Api.get(API.KYB_GENERIC_UBO, {
            companyCode: subjectCode,
            country: iso2Iso3Counties[countryCode]
          })

          this.getAllOwners(data)
          this.updateTreeData(data.owners, subjectCode)
          this.updateCompanyData(data)
        } catch (e) {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
        }
      })
    },

    updateCompanyData(data) {
      this.getAllOwners(data.owners)
      this.data.owners = this.data.owners.concat(data.owners)
    },

    fetchOwnerData(ownersList) {
      return ownersList.forEach(async ({ countryCode, subjectCode }) => {
        try {
          const { data } = await Api.get(API.KYB_GENERIC_UBO, {
            companyCode: subjectCode,
            country: iso2Iso3Counties[countryCode]
          })
          return data
        } catch {
          this.$notify({
            title: 'ErrorUnknownTitle',
            message: 'ErrorUnknownDescription'
          })
        }
      })
    },

    exportStructure() {
      const { FORMAT, QUALITY, FILENAME } = EXPORT_STRUCTURE
      const exportEl = document.querySelector('.tree-chart-wrapper')

      function convertToImage(canvas) {
        const imageData = canvas.toDataURL(FORMAT, QUALITY)
        downloadUrl({ fileUrl: imageData, fileName: FILENAME })
      }

      html2canvas(exportEl, {
        scrollY: -window.scrollY
      }).then(convertToImage)
    },

    checkNoSanctionSearchPermission(event) {
      if (!this.hasSanctionSearchAccess) {
        event.preventDefault()
        event.stopPropagation()
        this.$notify({
          title: 'DetailsScreenSanctionsPermissionRequired',
          message: 'DetailsScreenSanctionsPermissionRequest',
          type: notificationType.info
        })
      }
    },

    handleClick({ event, name }) {
      this.currentPepName = name
      this.checkNoSanctionSearchPermission(event)
    },

    filterOwner(owner) {
      delete owner.level
      delete owner.owners
      return owner
    }
  }
}
</script>

<style scoped>
.icon {
  width: 3.8rem;
  padding: 1.2rem 0;
  fill: #575962;
}

.level-title {
  font-size: 1rem;
  padding: 2rem 2.2rem 0.85rem;
  margin: 0;
  font-weight: 600;
}

.loader-style {
  padding: 5rem 0;
}

.search-message-style {
  padding: 5rem 5rem;
}

.ultimate-beneficial-owner {
  display: flex;
  justify-content: space-between;
}

.ultimate-beneficial-owner > div {
  width: 45%;
}

.tree-chart-wrapper {
  display: table;
  text-align: center;
}

.btn-more {
  position: relative;
  top: -20px;
  z-index: 1;
  margin: 0.5rem;
}

.btn-export {
  position: relative;
  top: -20px;
  z-index: 1;
  margin: 0.5rem;
}

.block-line {
  border-bottom: 2px solid rgb(61, 57, 57);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
</style>

<style>
.tree-chart-wrapper table {
  margin: auto;
  width: 100%;
}

.tree-chart-wrapper .node,
.tree-chart-wrapper .node .person {
  width: auto;
}

.tree-chart-wrapper .node .person .name {
  height: auto;
  line-height: 1.5rem;
}

.tree-chart-wrapper .node .person .avat {
  border: 0;
}

.tree-chart-wrapper .node .person .avat img {
  display: none;
}

.tree-chart-wrapper .node .person .avat::before {
  content: '\F155';
  font-family: 'Flaticon', sans-serif;
  font-size: 40px;
  color: var(--brand);
}

.tree-chart-wrapper .extend_handle {
  padding-left: 1rem;
}
</style>
