import PopUp from '@src/components/popup/PopUp'

export const popUpData = {
  isLoading: false,
  isVisible: false,
  background: null
}

export default {
  install: (Vue) => {
    Vue.component('pop-up', PopUp)

    Vue.prototype.$openPopUp = () => {
      document.querySelector('#app').classList.add('overflow-hidden')
      if (!popUpData.isVisible && !popUpData.isLoading) {
        popUpData.isLoading = true
        popUpData.isVisible = true
        popUpData.isLoading = false
      }
    }

    Vue.prototype.$closePopUp = () => {
      document.querySelector('#app').classList.remove('overflow-hidden')
      if (popUpData.isVisible) {
        popUpData.isVisible = false
        popUpData.background = null
      }
    }
  }
}
