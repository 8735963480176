<template>
  <div>
    <ui-header
      title="AdminUsersTitle"
      :method="searchMethod"
      disable-search-on-load
      :filters="filters"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.email"
        type="email"
        placeholder="HeaderEmailPlaceholder"
        icon="la-envelope"
        medium
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.status"
        :options="options.statusOptions"
        placeholder="HeaderStatusPlaceHolder"
        all
        small
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.emailStatus"
        :options="options.emailStatusOptions"
        all
        medium
        placeholder="HeaderEmailStatusPlaceholder"
        :disabled="isLoading"
      />
      <ui-header-input
        v-model="filters.fullName"
        placeholder="TableFullName"
        medium
        :disabled="isLoading"
      />
      <ui-multi-header-dropdown
        v-if="isSuperAdmin"
        :options="options.roles"
        medium
        has-search
        multi-values-translation-end="Role"
        placeholder="HeaderRolePlaceholder"
        :disabled="isLoading"
        @dropdownMultiSelected="handleRoleSelect"
      />
      <!--      <ui-checkbox-->
      <!--        v-model="filters.allCompanies"-->
      <!--        label="IdentificationsAllCompanies"-->
      <!--        isheader-->
      <!--      />-->
    </ui-header>
    <ui-container>
      <ui-card title="AppSearchResults" :count="usersCount" class="user-page">
        <ui-button
          slot="tools"
          class="btn-link tools-div"
          :disabled="!companyId"
          @click="openUserForm"
        >
          {{ $t('AdminAddUser') }}
          <i class="la la-plus" />
        </ui-button>
        <template v-if="!isLoading">
          <ui-table :labels="tableLabels" :data="model" class="hide-mobile">
            <tr
              v-for="(row, index) in model"
              :key="index"
              class="m-table__row"
              :class="{ 'status-pending': row.status === 'StatusDisabled' }"
            >
              <td class="fixed-small">
                {{ index + 1 }}
              </td>
              <td>{{ row.email }}</td>
              <td>{{ row.fullName }}</td>
              <td class="m--font-success">
                {{ $t(row.status) }}
              </td>
              <td>{{ $t(row.emailStatus) }}</td>
              <td>
                <ui-link
                  class="btn-brand"
                  :to="{
                    name: 'AdminUserDetails',
                    params: { userId: row.userId }
                  }"
                  small-text
                >
                  {{ $t('TableView') }}
                </ui-link>
              </td>
            </tr>
          </ui-table>
        </template>
        <ui-loader v-else :size="4" center />
        <template v-if="model">
          <div
            v-for="(row, index) in model"
            :key="index"
            class="response-table show-mobile"
            :class="{ 'status-pending': row.status === 'StatusDisabled' }"
          >
            <responsive-table-tr title="TableEmail" :value="row.email" />
            <responsive-table-tr title="TableFullName" :value="row.fullName" />
            <responsive-table-tr title="TableStatus" :value="row.status" />
            <responsive-table-tr
              title="TableEmailStatus"
              :value="row.emailStatus"
            />
            <div class="m--margin-top-10">
              <ui-link
                class="btn-brand big-link"
                :to="{
                  name: 'AdminUserDetails',
                  params: { userId: row.userId }
                }"
                small-text
              >
                {{ $t('TableView') }}
              </ui-link>
            </div>
          </div>
        </template>
        <ui-page-selector
          :active-page="filters.page"
          :items-count="usersCount"
          :max-on-page="filters.pageSize"
          @change="pageChanged"
        />
      </ui-card>
    </ui-container>
    <pop-up>
      <new-user v-show="companyId" />
    </pop-up>
  </div>
</template>

<script>
import NewUser from '@src/components/partials/NewUser'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import ResponsiveTableTr from '@src/components/partials/ResponsiveTableTr'
import Api from '@src/scripts/api'
import { API, ROLES } from '@src/scripts/enums'

const defaultUserOptions = () => ({
  statusOptions: [
    { key: 'Active', value: 'StatusActive' },
    { key: 'Disabled', value: 'StatusDisabled' }
  ],
  emailStatusOptions: [
    { key: 'Confirmed', value: 'StatusConfirmed' },
    { key: 'NotConfirmed', value: 'StatusNotConfirmed' }
  ],
  roles: null
})

export default {
  components: {
    NewUser,
    ResponsiveTableTr
  },

  data() {
    return {
      isLoading: false,
      usersCount: 0,
      userList: null,
      isSuperAdmin: undefined,
      filters: {
        email: null,
        status: null, //'Active',
        emailStatus: null,
        page: null,
        pageSize: 30,
        fullName: null,
        allCompanies: true,
        userRoles: []
      },
      rolesList: null,
      options: defaultUserOptions(),
      searchMethod: '/users/search',
      tableLabels: [
        'TableNr',
        'TableEmail',
        'TableFullName',
        'TableStatus',
        'TableEmailStatus',
        null
      ]
    }
  },

  computed: {
    ...mapGetters([getters.user, getters.selectedCompany, getters.userPages]),

    companyId() {
      const { user, selectedCompany } = this
      if (!user) return null
      return selectedCompany || null
    },

    model() {
      const { userList } = this
      if (!userList || !userList.length) return null
      const result = []

      for (const user of userList) {
        result.push({
          userId: user.userId,
          email: user.email,
          fullName: `${user.firstName || ''} ${user.lastName || ''}`,
          status: user.userEnabled ? 'StatusActive' : 'StatusDisabled',
          emailStatus: user.emailConfirmed
            ? 'StatusConfirmed'
            : 'StatusNotConfirmed'
        })
      }

      return result
    }
  },

  created() {
    if (!this.options.roles || this.isSuperAdmin === undefined) {
      this.getUserInfo()
    }
  },

  methods: {
    handleRoleSelect(roles) {
      this.filters.userRoles = roles
    },
    async getUserInfo() {
      this.isLoading = true
      try {
        const data = await Api.get(API.USER_SETTINGS)
        const { roles } = await Api.get(`${API.USER_DETAILS}${this.user.id}`)

        this.isSuperAdmin = roles.some(
          ({ value }) => value === ROLES.SUPPER_ADMIN
        )
        this.options.roles = data.roles.map((role) => ({
          key: this.$t(role.value),
          value: `${this.$t(role.value)}Role`
        }))
        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    },
    pageChanged(value) {
      const { filters } = this
      filters.page = value
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      const { list, total } = data
      this.userList = list
      this.usersCount = total
      this.isLoading = false
    },

    openUserForm() {
      this.$openPopUp()
    }
  }
}
</script>

<style scoped>
.response-table {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.italic-text {
  font-style: italic;
  color: red;
}

.margin-right {
  margin-right: 1rem;
}

.show-mobile {
  display: none;
}

@media all and (max-width: 992px) {
  .show-mobile {
    display: block;
  }

  .show-mobile table {
    border: 0;
  }

  .show-mobile tr td table {
    width: 100%;
    table-layout: fixed;
  }

  .show-mobile tr td {
    border: 0;
  }

  .show-mobile tr.m-table__row > td {
    border-bottom: 1px solid rgb(235, 237, 242);
  }

  .hide-mobile {
    display: none;
  }
}

@media (max-width: 400px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>

<style>
@media (max-width: 400px) {
  .user-page.m-portlet.m-portlet--responsive-mobile
    .m-portlet__head
    .m-portlet__head-tools {
    width: auto;
  }

  .user-page.m-portlet.m-portlet--responsive-mobile
    .m-portlet__head
    .m-portlet__head-tools
    > * {
    width: auto;
  }
}
</style>
