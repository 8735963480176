<template>
  <div
    class="scroll-box"
    :class="{ visible }"
    @mouseenter="showScroll = true"
    @mouseleave="showScroll = false"
  >
    <div class="scroll-content" :class="{ 'scroll-show': showScroll }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean
  },

  data() {
    return {
      showScroll: false
    }
  }
}
</script>
<style scoped>
.scroll-box {
  height: 100%;
  position: relative;
}

.scroll-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  -ms-overflow-style: auto;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  margin-top: -30px;
  padding-top: 30px;
  margin-bottom: -30px;
}

.scroll-show {
  scrollbar-color: var(--dark-br) var(--light);
}

::-webkit-scrollbar {
  width: 10px;
}

.scroll-show::-webkit-scrollbar-track {
  background: var(--light);
}

.scroll-show::-webkit-scrollbar-thumb {
  background: var(--dark-br);
}

.scroll-show::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

.visible .scroll-content {
  overflow-y: visible;
  position: relative;
}
</style>

<style>
.col-xl-8 .scroll-content .flex-row > :last-child {
  padding-right: 0;
}

.col-xl-8 .scroll-content .flex-row > :first-child {
  padding-left: 0;
}

.fixed-height .m-content .row .col-lg-8 {
  padding-right: 5px;
  padding-left: 5px;
}

@media (max-width: 1199px) {
  .scroll-box .scroll-content {
    margin-bottom: -55px;
  }

  .col-xl-8 .scroll-content .flex-row > :first-child {
    padding-right: 0;
  }

  .col-xl-8 .scroll-content .flex-row > :last-child {
    padding-left: 0;
  }
}

@media (min-width: 568px) and (max-width: 767px) {
  .scroll-box .scroll-content {
    position: relative;
    overflow: visible;
    margin-bottom: 0;
  }

  .fixed-height .m-content .row .col-xl-8 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .fixed-height .m-content .col-xl-6 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 568px) {
  .scroll-box .scroll-content {
    position: relative;
    overflow: visible;
    margin-bottom: 0;
  }

  .fixed-height .m-content .row .col-xl-8 {
    padding-left: 0;
    padding-right: 0;
  }

  .fixed-height .m-content .col-xl-6 {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
