var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-list-timeline m-list-timeline--skin-light" },
    [
      _c(
        "div",
        {
          staticClass: "m-list-timeline__items",
          class: { "single-item": _vm.items.length < 2 },
        },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "m-list-timeline__item" },
            [
              _c("span", { staticClass: "m-list-timeline__badge" }),
              _vm._v(" "),
              _vm._l(item, function (ref, key) {
                var type = ref.type
                var content = ref.content
                return [
                  type === "time"
                    ? _c(
                        "span",
                        { key: key, staticClass: "m-list-timeline__time" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(content) + "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        { key: key, staticClass: "m-list-timeline__text" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(content) + "\n        "
                          ),
                        ]
                      ),
                ]
              }),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }