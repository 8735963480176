var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-section identifications-table transparent-bg ui-table" },
    [
      _c("div", { staticClass: "m-section__content" }, [
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table m-table" }, [
            _c("thead", [
              _c(
                "tr",
                _vm._l(_vm.labels, function (label, index) {
                  return _c("th", { key: index }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t(label)) +
                        "\n            "
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm.isLoading
                  ? _c("tr", { staticClass: "m-table__row" }, [
                      _c(
                        "td",
                        {
                          staticClass: "loader-box",
                          attrs: { colspan: _vm.columnsCount },
                        },
                        [_c("ui-loader", { attrs: { size: 3, center: "" } })],
                        1
                      ),
                    ])
                  : _vm.hasData && !_vm.isLoading
                  ? _vm._t("default")
                  : !_vm.hasData && !_vm.isLoading
                  ? _c("tr", { staticClass: "m-table__row" }, [
                      _c(
                        "td",
                        {
                          staticClass: "table-message",
                          attrs: { colspan: _vm.columnsCount },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t(_vm.noDataText)) +
                              "\n            "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }