var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "details-item" }, [
    _c("span", { staticClass: "details-label" }, [
      _vm._v("\n    " + _vm._s(_vm.$t(_vm.label)) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "details-value", class: _vm.classList },
      [
        _vm.options && _vm.editable
          ? _c("ui-dropdown", {
              attrs: {
                placeholder: _vm.placeholder,
                value: _vm.value,
                "has-search": _vm.hasSearch,
                error: _vm.error,
                options: _vm.options,
                "key-value-dropdown": _vm.keyValueDropdown,
                disabled: _vm.disabled || !_vm.editable,
              },
              on: { input: _vm.valueChanged },
            })
          : _vm.datePicker && _vm.editable
          ? _c("ui-date-picker", {
              attrs: {
                error: _vm.error,
                placeholder: _vm.placeholder,
                value: _vm.value,
              },
              on: { input: _vm.valueChanged },
            })
          : _vm.editable
          ? _c("ui-input", {
              attrs: {
                placeholder: _vm.$t(_vm.placeholder),
                "one-line": _vm.oneLine,
                disabled: _vm.disabled,
                "min-length": _vm.minLength,
                "max-length": _vm.maxLength,
                type: _vm.type,
                value: _vm.value,
                error: _vm.error,
              },
              on: {
                change: _vm.valueChanged,
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            })
          : _vm.hyperlink
          ? _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editable,
                    expression: "!editable",
                  },
                ],
                staticClass: "dropdown-readonly",
                attrs: { href: _vm.hyperlinkUrl, target: "_blank" },
              },
              [_vm._v("\n      " + _vm._s(_vm.value) + "\n    ")]
            )
          : !_vm.hidden
          ? _c(
              "span",
              { staticClass: "dropdown-readonly" },
              [
                _vm._v("\n      " + _vm._s(_vm.itemText) + "\n      "),
                _vm._t("default"),
              ],
              2
            )
          : _c("hidden-details", { attrs: { value: _vm.itemText } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }