<template>
  <div>
    <div v-if="loading">
      <ui-loader :size="2.5" center />
    </div>
    <div
      v-else-if="data"
      class="activity-details"
      :class="{ 'single-item': data.length === 1 }"
    >
      <div v-for="(item, index) in data" :key="index" class="logs-item">
        <span v-for="(content, key) in item" :key="key">
          {{ content }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Array, default: null },
    loading: Boolean
  }
}
</script>

<style scoped>
.activity-details {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 1.5rem;
}

.activity-details::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  border-left: 1px solid #b5b5b5;
}

.single-item::before {
  content: none;
}

.logs-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  min-height: 3rem;
  position: relative;
}

.logs-item::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -1.5rem;
  width: 7px;
  height: 7px;
  background-color: #b5b5b5;
  border-radius: 50%;
  transform: translateY(-50%);
}

.logs-item > *:first-child {
  width: 30%;
}

.logs-item > *:not(:first-child) {
  width: 15%;
}

.logs-item:first-child::before,
.logs-item:last-child::before {
  content: '';
  position: absolute;
  left: -1.5rem;
  border-left: 1px solid #fff;
  transform: translateX(3px);
}

.logs-item:first-child::before {
  top: 0;
  bottom: 50%;
  transform: translateX(3px);
}

.logs-item:last-child::before {
  top: 50%;
  bottom: 0;
  transform: translateX(3px);
}

.logs-item + .logs-item {
  margin-top: 0.5rem;
}

@media (max-width: 568px) {
  .logs-item {
    flex-direction: column;
    align-items: start;
  }

  .logs-item + .logs-item {
    margin-top: 1rem;
  }
}
</style>
