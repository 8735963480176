<template>
  <router-view :key="routeId" />
</template>

<script>
import { uniqueKey } from '@src/scripts/helpers'

export default {
  data() {
    return {
      routeId: null
    }
  },

  watch: {
    $route() {
      this.routeId = uniqueKey()
    }
  }
}
</script>
