<template>
  <div
    class="m-portlet m-portlet--responsive-mobile"
    :class="{
      'm-portlet--head-lg': large,
      'm-portlet--brandalt m-portlet--head-solid-bg': dark
    }"
  >
    <div v-if="title" class="m-portlet__head" @click="$emit('click')">
      <div class="m-portlet__head-wrapper">
        <div class="m-portlet__head-caption">
          <div v-if="title" class="m-portlet__head-title">
            <span v-if="icon" class="m-portlet__head-icon">
              <i :class="icon" />
            </span>
            <template v-if="expandable">
              <button
                v-if="bodyExpanded"
                class="btn-expand btn-expand-expanded ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm"
                @click="toggleExpandButton"
              >
                <i class="fas fa-caret-up" />
              </button>
              <button
                v-else
                class="btn-expand ui-button btn m-btn btn-brand m-btn--wide m-btn--md btn-sm"
                @click="toggleExpandButton"
              >
                <i class="fas fa-caret-down" />
              </button>
            </template>
            <h3 class="m-portlet__head-text">
              {{ $t(title) }}
            </h3>
            <span v-if="count" class="subheader">
              {{ count }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="hasTools" class="m-portlet__head-tools">
        <ui-toggle
          v-if="withMonitoringToggle && isMonitoringAvailable"
          class="monitoring-toggle"
          :label="$t('Monitoring')"
          :value="isMonitoring"
          @change="monitoringChanged"
        />
        <slot name="tools" />
      </div>
    </div>
    <div v-if="expandable && !bodyExpanded" class="m-portlet__body">
      <p>{{ $t('ClickToExpandAndSeeMoreData') }}</p>
    </div>
    <template v-else>
      <div v-if="hasDefaultContent && !multicolumn" class="m-portlet__body">
        <slot />
      </div>
      <div v-else class="m-portlet__body m-portlet__body--no-padding">
        <div class="row m-row--no-padding m-row--col-separator-xl">
          <div v-if="!multicolumn" class="col-xl-12">
            <slot name="body" />
          </div>
          <template v-else>
            <div class="col-xl-6">
              <slot name="body-left" />
            </div>
            <div class="col-xl-6">
              <slot name="body-right" />
            </div>
          </template>
        </div>
      </div>
      <div v-if="hasFooter" class="m-portlet__foot">
        <slot name="footer" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import Api from '@src/scripts/api'
import { pageTypeEnum, API } from '@src/scripts/enums'

export default {
  name: 'ui-card',

  props: {
    title: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    count: { type: Number, default: undefined },
    multicolumn: Boolean,
    large: Boolean,
    dark: Boolean,
    expandable: Boolean,
    expanded: Boolean,
    withMonitoringToggle: { type: Boolean, required: false },
    companyCode: { type: String, required: false, default: undefined },
    monitoringToggleValue: { type: Boolean, required: false, default: false },
    monitoringToggleChanged: {
      type: Function,
      required: false,
      default: undefined
    }
  },

  data() {
    return {
      isMonitoring: false,
      bodyExpanded: this.expanded || false
    }
  },

  computed: {
    ...mapGetters([getters.userPages]),

    isMonitoringAvailable() {
      const { userPages } = this
      return userPages.includes(
        pageTypeEnum.legalEntityMonitoringEvents ||
          pageTypeEnum.legalEntityMonitoringSetup
      )
    },

    hasDefaultContent() {
      return !!this.$slots.default
    },

    hasTools() {
      return !!this.$slots.tools
    },

    hasFooter() {
      return !!this.$slots.footer
    }
  },

  created() {
    this.isMonitoring = this.monitoringToggleValue
  },

  methods: {
    toggleExpandButton() {
      let bodyExpanded = this.bodyExpanded
      this.bodyExpanded = !bodyExpanded
    },
    async monitoringChanged(isMonitoring) {
      this.monitoringToggleChanged(isMonitoring)
      try {
        if (isMonitoring) {
          await Api.post(API.MONITORING_COMPANIES, {
            companyCodes: [this.companyCode],
            country: 'Lt'
          })
        } else {
          await Api.del(API.MONITORING_COMPANIES, {
            companyCodes: [this.companyCode],
            country: 'Lt'
          })
        }
      } catch (error) {
        return null
      }
    }
  }
}
</script>

<style scoped>
.subheader {
  font-size: 0.9rem;
  line-height: 1.5;
  border-left: 1px solid #e2e5ec;
  color: #898b96;
  margin-left: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.btn.m-btn--wide.btn-sm.btn-expand {
  padding: 0.5rem 0.8rem;
  font-size: 0.6rem;
  margin-right: 0.5rem;
}

.monitoring-toggle {
  margin-right: 2rem;
}

@media (max-width: 568px) {
  .subheader {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
</style>
