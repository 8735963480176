var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-input form-group m-form__group",
      class: {
        "has-error": _vm.error,
        "ui-touched": _vm.istouched,
        "ui-untouched": !_vm.istouched,
        "type-password": _vm.type === "password",
      },
    },
    [
      _c("div", { staticClass: "input-box" }, [
        _vm.label
          ? _c(
              "label",
              {
                staticClass: "col-form-label",
                class: { "col-lg-5": !_vm.multiline },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t(_vm.label)) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.type !== "password" &&
        _vm.type !== "email" &&
        _vm.type !== "header-multi-select"
          ? _c("textarea", {
              staticClass: "form-control m-input",
              class: {
                "col-lg-7": _vm.label && !_vm.multiline,
                "with-overflow": _vm.multilineOverflow,
              },
              attrs: {
                rows: _vm.rows,
                placeholder: _vm.$t(_vm.placeholder),
                type: _vm.passwordType || _vm.type,
                disabled: _vm.disabled,
                maxlength: _vm.maxLength,
              },
              domProps: { value: _vm.value },
              on: {
                input: _vm.inputValue,
                keydown: _vm.keydown,
                keypress: function ($event) {
                  return _vm.$emit("keypress", $event.target.value)
                },
                change: function ($event) {
                  return _vm.$emit("change", $event.target.value)
                },
                blur: _vm.lostFocus,
                click: function ($event) {
                  return _vm.$emit("click", $event.target.value)
                },
              },
            })
          : _c("input", {
              staticClass: "form-control m-input",
              class: {
                "col-lg-7": _vm.label && !_vm.multiline,
                "text-overflow-ellipses": _vm.textOverflowEllipses,
              },
              attrs: {
                placeholder: _vm.$t(_vm.placeholder),
                type: _vm.passwordType || _vm.type,
                disabled: _vm.disabled,
                maxlength: _vm.maxLength,
              },
              domProps: { value: _vm.value },
              on: {
                input: _vm.inputValue,
                keydown: _vm.keydown,
                keypress: function ($event) {
                  return _vm.$emit("keypress", $event.target.value)
                },
                change: function ($event) {
                  return _vm.$emit("change", $event.target.value)
                },
                blur: _vm.lostFocus,
                click: function ($event) {
                  return _vm.$emit("click", $event.target.value)
                },
              },
            }),
        _vm._v(" "),
        _vm.type === "password"
          ? _c(
              "a",
              {
                staticClass: "password-show",
                on: {
                  mousedown: _vm.showPassword,
                  touchstart: _vm.showPassword,
                  mouseup: _vm.hidePassword,
                  touchend: _vm.hidePassword,
                },
              },
              [_c("i", { staticClass: "la la-eye" })]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "error-message",
          class: { "error-offset": _vm.label && !_vm.multiline },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t(_vm.error, { minLength: _vm.minLength })) +
              "\n  "
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }