<template>
  <li
    class="m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    :class="{ 'dropdown-open': isOpened }"
    @keydown.enter="clickEnter"
  >
    <a class="m-nav__link m-dropdown__toggle select-btn" @click="open">
      <span class="btn-text-box">
        {{ selectedValue }}
      </span>
    </a>
    <div
      id="dropdown-menu"
      class="m-dropdown__wrapper"
      :class="{ 'is-opened': isOpened }"
      @keyup="keyup"
    >
      <span class="m-dropdown__arrow" :style="styles" />
      <div class="m-dropdown__inner">
        <div class="m-dropdown__body">
          <div class="filter-wrapper search-box">
            <ui-input
              ref="companyName"
              placeholder="AdminCompaniesTitle"
              :value="dropDownvalue"
              @blur="lostFocus"
              @input="changeInput"
              @click="clickSearch"
            />
          </div>
          <a
            v-for="(item, itemIndex) in searchOptions"
            :key="itemIndex"
            class="dropdown-item"
            :tabindex="itemIndex"
            :class="{ selected: selectedIndex == itemIndex }"
            @click="select(item.id)"
          >
            <span class="bs-ok-default check-mark" />
            <span class="text">
              {{ item.name }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    placeholder: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined },
    options: { type: Array, default: undefined },
    index: { type: Number, default: undefined }
  },

  data() {
    return {
      isOpened: false,
      searchOptions: null,
      isSearch: false,
      dropDownvalue: null,
      countryIndex: false
    }
  },

  computed: {
    styles() {
      const { index } = this
      if (index) {
        const rightMargin = 52 * index + 4.5
        return { right: `${rightMargin}px` }
      }
      return null
    },

    selectedValue() {
      const { value, placeholder, options } = this
      if (!options || !options.length || !value) return placeholder
      const selected = options.find((item) => {
        return item.id === value
      })
      return (selected && selected.name) || placeholder
    },

    selectedIndex() {
      const { searchOptions, value } = this
      if (!searchOptions || !value) return null

      for (const index in searchOptions) {
        const item = searchOptions[index].id
        if (item === value) return index
      }
      return null
    }
  },

  watch: {
    selectedValue(newValue) {
      if (this.isOpened) this.dropDownvalue = newValue
    }
  },

  created() {
    this.searchOptions = this.options
  },

  methods: {
    clickEnter(e) {
      e.preventDefault()
      if (
        this.searchOptions[this.selectedIndex] &&
        this.searchOptions[this.selectedIndex].id
      ) {
        this.select(this.searchOptions[this.selectedIndex].id)
      }
    },

    keyup(event) {
      if (event.key === 'ArrowDown') {
        this.countryIndex =
          this.searchOptions.length > 1 && this.selectedIndex
            ? this.selectedIndex * 1 + 1
            : 0
        if (this.countryIndex < this.searchOptions.length) {
          this.select(this.searchOptions[this.countryIndex].id, true)
        }
      } else if (event.key === 'ArrowUp') {
        this.countryIndex =
          this.searchOptions.length > 1 ? this.selectedIndex * 1 - 1 : 0
        if (
          this.countryIndex >= 0 &&
          this.countryIndex < this.searchOptions.length
        ) {
          this.select(this.searchOptions[this.countryIndex].id, true)
        }
      }
      if (this.countryIndex === 0) {
        this.$refs.companyName.$el.querySelector('input').focus()
      }
    },

    lostFocus() {
      this.isSearch = false
    },

    select(newValue, dontClose) {
      const { value } = this
      if (value !== newValue) {
        this.$emit('change', newValue)
      }
      this.optionFocus()
      this.dropDownvalue = this.selectedValue
      if (!dontClose) {
        this.dropDownvalue = null
        this.isSearch = false
        this.close()
      }
    },

    optionFocus() {
      const openDiv = this.$el.querySelector(
        '.dropdown-open .m-dropdown__inner'
      )
      if (openDiv.querySelector('a.selected')) {
        openDiv.querySelector('a.selected').focus()
      }
    },

    open() {
      if (!this.dropDownvalue) this.searchOptions = this.options
      document.body.addEventListener('click', this.clickOutside)
      setTimeout(
        () => this.$refs.companyName.$el.querySelector('.m-input').focus(),
        0
      )
    },

    close() {
      if (
        document.querySelector('.is-opened') &&
        this.isOpened &&
        !this.isSearch
      ) {
        document.querySelectorAll('#dropdown-menu').forEach((item) => {
          item.classList.remove('is-opened')
        })
        this.isOpened = false
        document.body.removeEventListener('click', this.clickOutside)
      } else {
        this.isOpened = true
      }
    },

    clickOutside() {
      this.close()
    },

    changeInput(value) {
      this.isOpened = true
      const input = value.toLocaleLowerCase().replace(/\s/g, '')
      const array = this.options.filter((item) =>
        item.name.toLocaleLowerCase().replace(' ', '').includes(input)
      )
      this.searchOptions = array.length > 0 ? array : ''
      this.dropDownvalue = value
    },

    clickSearch() {
      this.isSearch = true
    }
  }
}
</script>

<style scoped>
.filter-wrapper {
  position: fixed;
  background-color: var(--white);
  top: 0.75rem;
  padding: 1.5rem 0.5rem 1rem;
  width: 92%;
  z-index: 100;
}

.filter-wrapper .ui-input {
  margin: 0;
}

.m-nav__item:hover {
  cursor: pointer;
}

.m-dropdown__wrapper {
  z-index: 101;
  cursor: default;
  display: none;
}

.m-dropdown__wrapper.is-opened {
  display: block;
}

.m-nav__item.dropdown-open > .m-dropdown__wrapper {
  display: block;
  -webkit-animation: m-dropdown-fade-in 0.3s ease 1,
    m-dropdown-move-up 0.3s ease-out 1;
  animation: m-dropdown-fade-in 0.3s ease 1, m-dropdown-move-up 0.3s ease-out 1;
}

.m-dropdown__wrapper .m-dropdown__arrow {
  left: auto;
  right: 4.5px;
}

.m-dropdown__wrapper .m-dropdown__body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 5.75rem;
}

.m-dropdown__wrapper .m-dropdown__body li {
  cursor: initial;
}

.m-nav__link.select-btn {
  display: flex;
}

.select-btn .btn-text-box {
  width: auto;
  height: 3rem;
  border-radius: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #e8e7f4;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  margin-top: auto;
  margin-bottom: auto;
}

.select-btn .btn-text-box::after {
  font-family: 'LineAwesome', sans-serif;
  content: '\F110';
  font-size: 0.8rem;
  vertical-align: middle;
  position: absolute;
  margin-left: 0.255rem;
  margin-right: 0.255rem;
  right: 1rem;
}

.select-btn .btn-text-box:hover {
  background-color: var(--brand);
  border-color: var(--brand);
  color: var(--white);
}

.m-dropdown__body > .dropdown-item {
  position: relative;
  cursor: pointer;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-right: 3rem;
}

.m-dropdown__body > .dropdown-item.selected {
  background: rgb(36, 77, 80);
  color: #fff;
}

.m-dropdown__body > .selected .check-mark {
  font-family: 'LineAwesome', sans-serif;
  font-size: 0.85rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.15rem;
  display: inline-block;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

.m-dropdown__body > .selected .check-mark::before {
  content: '';
}

.m-dropdown__body > .dropdown-item:hover {
  background: #f4f5f8;
}

.m-dropdown__body > .dropdown-item.selected:hover {
  background: rgba(36, 77, 80, 0.9);
}

.search-box {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  top: 10px;
  height: 5.75rem;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

@media (min-width: 1025px) {
  .m-dropdown.m-dropdown--medium .m-dropdown__wrapper {
    min-width: 15rem;
    width: auto;
  }
}
</style>
