<template>
  <div class="m-widget1">
    <div class="m-widget__header" :class="{ 'top-spacing': topSpacing }">
      <h5 v-if="title" class="m-widget__title">
        {{ $t(title, titleValue) }}
      </h5>
      <span v-if="description" class="m-widget14__desc">
        {{ $t(description) }}
      </span>
    </div>
    <div
      v-if="hasBody"
      class="m-widget1__item"
      :class="{ 'no-spacing': noSpacing }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-widget',

  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    titleValue: { type: Object, default: undefined },
    topSpacing: Boolean,
    noSpacing: Boolean
  },

  computed: {
    hasBody() {
      return !!this.$slots.default
    }
  }
}
</script>

<style scoped>
.vertical-group.m-widget1 + .m-widget1 {
  padding-top: 0;
}

.top-spacing {
  min-height: 2rem;
}

@media (max-width: 568px) {
  .m-widget1 {
    padding: 1rem 1.5rem;
  }
}

.m-widget1 .m-widget1__item.no-spacing {
  padding: 0;
}
</style>
