<template>
  <ui-card title="AppSearchResults">
    <div slot="tools">
      <div class="display-flex">
        <ui-loader v-if="isDownloading" class="mr-1" :size="1" center />
        <ui-dropdown
          :options="exportOptions"
          placeholder="AppExport"
          class="margin-right"
          :disabled="isDownloading"
          small
          light-border
          @input="exportList"
        />
      </div>
    </div>
    <search-state
      v-if="!ready"
      :loading="loading"
      :empty="empty"
      :error="error"
      :loading-message="$t('App.Loading')"
      :empty-message="$t('Kyb.Search.Empty')"
      :error-message="$t('Error.Fail')"
    />
    <div v-else>
      <search-item
        v-for="(item, index) in identifications"
        :key="`d_${index}`"
        :data="item"
        :label="label"
      />
    </div>
  </ui-card>
</template>

<script>
import SearchState from '@src/components/partials/refactor/layouts/SearchState'
import SearchItem from '@src/components/partials/refactor/partials/kyb/SearchItem'
import { mapState } from 'vuex'
import Api from '@src/scripts/api'
import { kybReviewStatuses, searchStatuses } from '@src/scripts/enums'
import { exportPdf } from '@src/scripts/exportPdf'
import { dateToString, downloadBlob } from '@src/scripts/helpers'

export function mapOptions(list, values) {
  if (values) {
    return (
      list &&
      list.map((item, index) => {
        return {
          key: item,
          value: values[index]
        }
      })
    )
  } else {
    return (
      list &&
      list.map((item) => {
        return {
          key: item,
          value: item
        }
      })
    )
  }
}

export default {
  components: {
    SearchState,
    SearchItem
  },

  data() {
    return {
      isDownloading: false
    }
  },

  computed: {
    ...mapState({
      state: (state) => state.kyb.search.state,
      total: (state) => state.kyb.search.total,
      identifications: (state) => state.kyb.search.identifications,
      page: (state) => state.kyb.search.page,
      limit: (state) => state.kyb.search.limit,
      payloads: (state) => state.kyb.search.payload
    }),

    loading: function () {
      return this.state === searchStatuses.loading
    },

    ready() {
      return this.state === searchStatuses.ready && !!this.identifications
    },

    empty() {
      return this.state === searchStatuses.ready && !this.identifications
    },

    error() {
      return this.state === searchStatuses.error
    },

    exportOptions() {
      return mapOptions(['PDF', 'CSV'])
    },

    label() {
      return {
        date: this.$t('Kyb.List.Date'),
        legalEntity: {
          name: this.$t('Kyb.List.LegalEntity.Name'),
          code: this.$t('Kyb.List.LegalEntity.Code')
        },
        status: this.$t('Kyb.List.Status'),
        count: this.$t('Kyb.List.Count')
      }
    }
  },

  methods: {
    async exportList(value) {
      if (value === 'PDF') {
        await this.exportPdfFormat()
      } else if (value === 'CSV') {
        await this.exportCsvFormat()
      }
    },

    createBody() {
      const limit = 1000
      const { payloads, page } = this
      const filters = payloads

      const body = {
        limit,
        offset: +page * limit,
        orderBy: 'CreatedUtc',
        orderDirection: 'Desc',
        createdUtc: {
          from: new Date(`${filters.date?.[0]}T00:00`).toISOString(),
          to: new Date(`${filters.date?.[1]}T24:00`).toISOString()
        }
      }

      if (filters.companyName || filters.companyCode) {
        body.legalEntity = {}

        if (filters.companyName) {
          body.legalEntity.names = [filters.companyName]
        }
        if (filters.companyCode) {
          body.legalEntity.codes = [filters.companyCode]
        }
      }

      if (
        filters.reviewStatus &&
        filters.reviewStatus !== kybReviewStatuses.all
      ) {
        body.statuses = [filters.reviewStatus]
      }

      if (filters.identificationId) {
        body.ids = [filters.identificationId]
      }

      return body
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    async exportCsvFormat() {
      try {
        this.isDownloading = true

        const body = this.createBody()

        const result = await Api.post('/kybidentifications/search', body)

        const rows = [
          [
            this.$t('Kyb.List.Date'),
            this.$t('Kyb.List.LegalEntity.Name'),
            this.$t('Kyb.List.LegalEntity.Code'),
            this.$t('Kyb.List.Status'),
            this.$t('Kyb.List.Count')
          ],
          ...result.identifications.map((item) => [
            item.createdUtc.replace('T', ' ') ?? '',
            `"${item.legalEntity.name ?? ''}"`,
            item.legalEntity.code ?? '',
            item.status,
            `${item.representativeCount} / ${item.verifiedRepresentativeCount}`
          ])
        ]

        const csvContent = rows.map((row) => row.join(';')).join('\n')

        downloadBlob({
          file: new Blob([csvContent]),
          fileName: `OndatoRequests[${dateToString(new Date(), true)}].csv`
        })
      } catch (error) {
        this.showError()
      }
      this.isDownloading = false
    },

    async exportPdfFormat() {
      try {
        this.isDownloading = true

        const body = this.createBody()

        const result = await Api.post('/kybidentifications/search', body)

        const name = this.$store.getters.sessionData.profile.name
        const data = {
          tableLabels: [
            'Kyb.List.Date',
            'Kyb.List.LegalEntity.Name',
            'Kyb.List.LegalEntity.Code',
            'Kyb.List.Status',
            'Kyb.List.Count'
          ].map((item) => this.$t(item)),
          tableRows: result.identifications.map((item) => [
            item.createdUtc.replace('T', ' ') ?? '',
            item.legalEntity.name ?? '',
            item.legalEntity.code ?? '',
            item.status,
            `${item.representativeCount} / ${item.verifiedRepresentativeCount}`
          ]),
          filters: {
            'Kyb.Search.Date': this.payloads.date,
            'Kyb.Search.CompanyName': this.payloads.companyName,
            'Kyb.Search.CompanyCode': this.payloads.companyCode,
            'Kyb.Search.ReviewStatus': this.payloads.reviewStatus,
            'Kyb.Search.IdentificationId': this.payloads.identificationId
          }
        }

        const exportFile = new exportPdf(data, name)
        await exportFile.exportTable()
        this.isDownloading = false
      } catch (error) {
        this.isDownloading = false
        this.showError()
      }
    }
  }
}
</script>
