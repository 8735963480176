var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mrz-container" },
    [
      _c("div", { staticClass: "mrz-title" }, [
        _c("h5", [
          _vm._v("\n      " + _vm._s(_vm.$t("Mrz.Title")) + "\n      "),
          _vm.isMrzVerified
            ? _c("i", { staticClass: "fas fa-check-circle m--font-success" })
            : _c("i", { staticClass: "fas fa-circle-xmark m--font-danger" }),
        ]),
      ]),
      _vm._v(" "),
      _vm.hasMrzRule
        ? _c("mrz-lines-container", {
            attrs: {
              "field-values": _vm.values,
              "edit-enabled": _vm.editEnabled,
            },
          })
        : _c("div", [_vm._v(_vm._s(_vm.$t("Mrz.DoesNotExist")))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }