<template>
  <div v-if="!isLoading">
    <div class="kyb-details-page">
      <ui-loader v-if="!isReady" :size="4" center />
      <ui-card
        v-else-if="revision"
        icon="la la-institution"
        :title="revision.name"
        :with-monitoring-toggle="countryCode === 'Lt'"
        :monitoring-toggle-value="initialMonitoringState"
        :monitoring-toggle-changed="handleMonitoringToggleChange"
        :company-code="companyCode"
      >
        <ui-button
          slot="tools"
          class="btn-metal"
          icon="la la-download"
          small
          :disabled="!isReady"
          @click="showStatements"
        >
          {{ $t('AppStatements') }}
        </ui-button>
        <tabs-group
          slot="body"
          :tab-index="activeTabIndex"
          :labels="enabledTabLabels"
          @change="tabChanged"
        >
          <company-no-info
            v-if="tabDisabledList.includes(activeTab.key) && !isLt"
          />
          <company-general-info
            v-if="activeTab.key === 'general'"
            :data="generalInformation"
          />
          <company-financial-info
            v-else-if="activeTab.key === 'financial' && isLt"
            :data="financialInformation"
          />
          <company-courts
            v-else-if="activeTab.key === 'courts' && isLt"
            :company-code="companyCode"
          />
          <company-transport
            v-else-if="activeTab.key === 'transport' && isLt"
            :company-code="companyCode"
          />
          <company-seizures
            v-else-if="activeTab.key === 'seizures' && isLt"
            :company-code="companyCode"
          />
          <company-business-relations
            v-else-if="activeTab.key === 'relations' && isLt"
          />
          <ultimate-beneficial-owner
            v-else-if="activeTab.key === 'ubo'"
            :company-code="companyCode"
            :renew-time="titleValue"
            :search-message="searchMessage"
            :company-country-code="countryCode"
            :main-company-name="revision ? revision.name : undefined"
          />
        </tabs-group>
      </ui-card>
      <span v-else>
        {{ $t(searchMessage) }}
      </span>
    </div>
    <pop-up>
      <kyb-statements
        :company-code="companyCode"
        :company-country-code="countryCode ? countryCode.toUpperCase() : null"
      />
    </pop-up>
  </div>
  <ui-loader v-else :size="4" center />
</template>

<script>
import UltimateBeneficialOwner from '@src/components/partials/UltimateBeneficialOwner'
import CompanyNoInfo from '@src/components/partials/CompanyNoInfo'
import CompanyFinancialInfo from '@src/components/partials/CompanyFinancialInfo'
import CompanyGeneralInfo from '@src/components/partials/CompanyGeneralInfo'
import CompanyBusinessRelations from '@src/components/partials/CompanyBusinessRelations'
import CompanyCourts from '@src/components/partials/CompanyCourts'
import CompanyTransport from '@src/components/partials/CompanyTransport'
import KybStatements from '@src/components/partials/KybStatements'
import TabsGroup from '@src/components/partials/TabsGroup'
import { dateToString } from '@src/scripts/helpers'
import Api from '@src/scripts/api'
import CompanySeizures from '@src/components/partials/CompanySeizures'
import { pageTypeEnum, API } from '@src/scripts/enums'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'

export default {
  components: {
    CompanySeizures,
    CompanyFinancialInfo,
    CompanyGeneralInfo,
    CompanyBusinessRelations,
    CompanyCourts,
    CompanyTransport,
    KybStatements,
    TabsGroup,
    UltimateBeneficialOwner,
    CompanyNoInfo
  },

  props: {
    isLoading: Boolean
  },

  data() {
    return {
      initialMonitoringState: false,
      isReady: false,
      searchMessage: null,
      revision: null,
      titleValue: {
        time: dateToString(new Date())
      },
      searchMethod: '/kyb/details',
      activeTabIndex: 0,
      ultimateBeneficialOwnerData: null
    }
  },

  computed: {
    ...mapGetters([getters.userPages]),
    isLt() {
      return this.countryCode === 'Lt'
    },
    tabDisabledList() {
      return ['financial', 'courts', 'transport', 'seizures', 'relations']
    },
    tabs() {
      return {
        general: {
          label: 'KybTabGeneral'
        },
        financial: {
          label: 'KybTabFinancial',
          icon:
            !this.financialInformation || !this.isLt ? 'cloud-slash.svg' : null
        },
        courts: {
          label: 'KybTabCourts',
          icon: !this.isLt ? 'cloud-slash.svg' : null,
          disabled: !this.userPages?.includes(pageTypeEnum.kybCourts)
        },
        transport: {
          label: 'KybTabTransport',
          icon: !this.isLt ? 'cloud-slash.svg' : null,
          disabled: !this.userPages?.includes(pageTypeEnum.kybTransport)
        },
        seizures: {
          label: 'KybTabSeizures',
          icon: !this.isLt ? 'cloud-slash.svg' : null,
          disabled: !this.userPages?.includes(pageTypeEnum.kybSeizures)
        },
        relations: {
          label: 'KybTabSpider',
          icon: !this.isLt ? 'cloud-slash.svg' : null,
          disabled: !this.userPages?.includes(pageTypeEnum.kybRelations)
        },
        ubo: {
          label: 'KybUltimateBeneficialOwner',
          disabled: !this.userPages?.includes(pageTypeEnum.kybUbo)
        }
      }
    },

    isMonitoringAvailable() {
      const { userPages } = this
      return userPages.includes(
        pageTypeEnum.legalEntityMonitoringEvents ||
          pageTypeEnum.legalEntityMonitoringSetup
      )
    },

    enabledTabs() {
      return Object.entries(this.tabs).reduce((enabledTabs, [key, tab]) => {
        if (!tab.disabled) enabledTabs.push({ ...tab, key })
        return enabledTabs
      }, [])
    },

    activeTab() {
      return this.enabledTabs[this.activeTabIndex]
    },

    enabledTabLabels() {
      return this.enabledTabs.map(({ label, icon }) =>
        icon ? { label, icon } : label
      )
    },

    generalInformation() {
      const { revision, titleValue } = this
      if (!revision) return {}
      const { financialIndicators, prescoreIndicators, ...data } = revision
      data.titleValue = titleValue
      return data
    },

    companyCode() {
      return this.$route.params?.companyCode?.toString() ?? null
    },

    countryCode() {
      return this.$route.params?.countryCode?.toString() ?? null
    },

    financialInformation() {
      const { revision, titleValue } = this
      if (!revision || !this.isLt) return {}
      const { financialIndicators, companyValue } = revision
      return financialIndicators
        ? {
            indicators: financialIndicators.reverse(),
            companyValue,
            titleValue
          }
        : null
    }
  },

  mounted() {
    if (!this.companyCode || !this.countryCode) {
      this.$router.push({ name: 'KybCompanyList' })
      this.$notify({
        title: 'CompanyCodeOrCountryCodeError',
        message: 'CompanyCodeOrCountryCodeError'
      })
    }
  },

  async created() {
    const { isMonitoringAvailable } = this
    const currentCompany = isMonitoringAvailable
      ? await this.fetchMonitoringStatus()
      : null
    this.initialMonitoringState = !!currentCompany

    const companyInfo = await this.getRevision()

    if (companyInfo) {
      this.revision = {
        ...companyInfo,
        ...(currentCompany &&
          !currentCompany.removedAt && { added: currentCompany.addedAt })
      }
    }

    this.isReady = true
  },

  methods: {
    handleMonitoringToggleChange(value) {
      this.revision = {
        ...this.revision,
        initialDate: value ? new Date().toLocaleString() : null
      }
    },

    async fetchMonitoringStatus() {
      try {
        return await Api.get(`${API.MONITORING_COMPANIES}/${this.companyCode}`)
      } catch (error) {
        return null
      }
    },

    async getRevision() {
      const payload = {
        Country: this.countryCode,
        CompanyCode: this.companyCode
      }
      try {
        const financialIndicators = await Api.get(
          '/kyb/financial-indicators',
          payload
        )
        const companyInfo = await Api.get('/kyb/general-information', payload)

        return {
          ...companyInfo.data,
          financialIndicators: financialIndicators.data
            ? [...financialIndicators.data]
            : []
        }
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
        this.$router.push({ name: 'KybCompanyList' })
        return null
      }
    },

    tabChanged(activeTabIndex) {
      this.activeTabIndex = activeTabIndex
    },

    showStatements() {
      this.$openPopUp()
    }
  }
}
</script>
<style>
@media (max-width: 678px) {
  .kyb-details-page .m-portlet__body .m-widget1 {
    padding: 1rem;
  }

  .kyb-details-page .m-widget1 .m-widget1 {
    padding: 0;
  }

  .kyb-details-page .ui-table td,
  .kyb-details-page .ui-table th {
    font-size: 1rem;
    padding: 0.25rem;
  }

  .kyb-details-page .m-portlet__head-tools {
    width: 100%;
  }

  .kyb-details-page .m-portlet__head-tools .btn-metal {
    justify-content: center;
  }

  @media (max-width: 400px) {
    .kyb-details-page
      .m-portlet.m-portlet--responsive-mobile
      .m-portlet__head
      .m-portlet__head-tools {
      width: 100%;
    }

    .kyb-details-page .m-portlet__head-tools .btn-metal {
      justify-content: center;
      width: 100%;
    }

    .chart-data-item-mobile .ui-table th,
    .chart-data-item-mobile .ui-table td {
      padding: 0 0.2rem 0 0;
      font-size: 0.9rem;
    }
  }
}
</style>
