<template>
  <div>
    <div v-if="isLoading">
      <ui-loader :size="4" center />
    </div>
    <ui-card v-if="!isLoading" title="Dashboard.Call.RealTime.Title">
      <details-item-orange
        label="Dashboard.Call.RealTime.Waiting"
        :value="videoCallsWaitingInQueue"
      />
      <details-item-orange
        label="Dashboard.Call.RealTime.LongestWaiting"
        :value="longestTimeUserWaitingInVideoCallQueue"
      />
      <details-item-orange
        v-if="showNumberOfFormsBeingFilled"
        label="Dashboard.Call.RealTime.ActiveForms"
        :value="numberOfFormsBeingFilled"
      />
    </ui-card>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import DetailsItemOrange from '@src/components/partials/DetailsItemOrange'

export default {
  components: {
    DetailsItemOrange
  },
  props: {
    getTime: { type: Function, required: true },
    autoRefresh: Boolean,
    refreshInterval: { type: Number, default: undefined }
  },
  data() {
    return {
      isLoading: false,
      callData: null,
      refreshSettings: {
        isAuto: false,
        interval: 20000,
        timer: null
      }
    }
  },
  computed: {
    videoCallsWaitingInQueue() {
      return this.callData?.videoCallsWaitingInQueue
    },
    longestTimeUserWaitingInVideoCallQueue() {
      return this.getTime(this.callData?.longestTimeUserWaitingInVideoCallQueue)
    },
    numberOfFormsBeingFilled() {
      return this.callData?.numberOfFormsBeingFilled
    },
    showNumberOfFormsBeingFilled() {
      return this.callData?.numberOfFormsBeingFilled != null
    }
  },
  watch: {
    autoRefresh(value) {
      if (value) {
        this.enableRefresh()
      } else {
        this.disableRefresh()
      }
    }
  },
  mounted() {
    const { refreshSettings, refreshInterval } = this
    if (refreshInterval) {
      refreshSettings.interval = refreshInterval
    }

    if (this.autoRefresh) {
      this.enableRefresh()
    } else {
      this.disableRefresh()
    }
  },
  beforeDestroy() {
    this.disableRefresh()
  },

  methods: {
    enableRefresh() {
      const { refreshSettings } = this
      if (!refreshSettings.isAuto) {
        this.getCallData()
        refreshSettings.isAuto = true
        refreshSettings.timer = setInterval(() => {
          this.getCallData()
        }, refreshSettings.interval)
      }
    },

    disableRefresh() {
      const { refreshSettings } = this
      if (refreshSettings.isAuto) {
        refreshSettings.isAuto = false
        clearInterval(refreshSettings.timer)
        refreshSettings.timer = null
      }
    },

    async getCallData() {
      this.isLoading = true
      try {
        this.callData = await Api.post('/dashboard/videocall', {})
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
