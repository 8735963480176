var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-card",
    { staticClass: "flex-row toggle-settings-box" },
    [
      _c("div", [
        _c("h5", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.description))]),
      ]),
      _vm._v(" "),
      _c("ui-toggle", {
        attrs: { value: _vm.value, disabled: _vm.disabled },
        on: { change: _vm.changeToggle },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }