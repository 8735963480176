var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "box-with-header",
    [
      !_vm.hideButtons
        ? _c(
            "div",
            {
              staticClass: "header-box",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _vm.declarationOptions
                ? _c("ui-dropdown", {
                    staticClass: "margin-right",
                    attrs: {
                      options: _vm.declarationOptions,
                      placeholder: "AppDeclarations",
                      small: "",
                      "light-border": "",
                    },
                    on: { input: _vm.exportDeclarations },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("ui-dropdown", {
                attrs: {
                  options: _vm.exportOptions,
                  placeholder: "AppExport",
                  small: "",
                  metal: "",
                },
                on: { input: _vm.exportList },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ui-container",
        { staticClass: "sanctions-details-page" },
        [
          _vm.hideButtons
            ? _c(
                "div",
                {
                  staticClass: "loader-absolute",
                  class: { "white-bg": _vm.$route.params.export },
                },
                [_c("ui-loader", { attrs: { size: 4, center: "" } })],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "col-md-12 col-lg-12" },
                [_c("ui-loader", { attrs: { size: 3, center: "" } })],
                1
              )
            : [
                _c("p", { staticClass: "searchedInText" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("SearchedIn")) +
                      " " +
                      _vm._s(_vm.$route.params.fuzzinessPercent) +
                      "%\n      "
                  ),
                ]),
                _vm._v(" "),
                !_vm.sanctionData
                  ? _c("ui-card", [
                      _c("p", [_vm._v(_vm._s(_vm.$t("SanctionDataNotFound")))]),
                    ])
                  : [
                      _c(
                        "ui-card",
                        {
                          staticClass: "doc-export-block",
                          attrs: { title: "KeyInformation", multicolumn: "" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "body-left" }, slot: "body-left" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "details-box",
                                    [
                                      _vm.sanctionData.fullname
                                        ? _c("details-item-simple-text", {
                                            attrs: {
                                              label: "DetailsName",
                                              value: _vm.sanctionData.fullname,
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.sanctionData.dob
                                        ? _c("details-item-simple-text", {
                                            attrs: {
                                              label: "DetailsDOB",
                                              value: _vm.sanctionData.dob,
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.sanctionData.source_notes
                                        ? _c("details-item-with-tags", {
                                            attrs: {
                                              label: "DetailsSources",
                                              values:
                                                _vm.sanctionData.source_notes,
                                              "no-style-tabs": "",
                                              "ul-list": "",
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.sanctionData.types
                                        ? _c("details-item-with-tags", {
                                            attrs: {
                                              label: "DetailsListingType",
                                              values: _vm.sanctionData.types,
                                              "no-style-tabs": "",
                                              "ul-list": "",
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              attrs: { slot: "body-right" },
                              slot: "body-right",
                            },
                            [
                              _vm.sanctionData.images &&
                              _vm.sanctionData.images.length > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "col-12 media-export-block",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "sanction-image",
                                        attrs: {
                                          src:
                                            _vm.sanctionData.images[0]
                                              .public_url,
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.sanctionData.fields &&
                      _vm.sanctionData.fields.length > 0
                        ? _c(
                            "ui-card",
                            {
                              staticClass: "doc-export-block",
                              attrs: { title: "AdditionalInformation" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "details-box",
                                    [
                                      _vm.sanctionData.aka &&
                                      _vm.sanctionData.aka.length > 0
                                        ? _c("details-item-with-tags", {
                                            attrs: {
                                              label: "AKA",
                                              values: _vm.sanctionData.aka,
                                              "no-style-tabs": "",
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.sanctionData.associates &&
                                      _vm.sanctionData.associates.length > 0
                                        ? _c("details-item-with-tags", {
                                            attrs: {
                                              label: _vm.$t(
                                                "DetailsAssociates"
                                              ),
                                              values:
                                                _vm.sanctionData.associates,
                                              "no-style-tabs": "",
                                              "is-inner": "",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._l(_vm.sanctionData.fields, function (
                                        field,
                                        index
                                      ) {
                                        return _c("details-item-simple-text", {
                                          key: index,
                                          attrs: {
                                            label: _vm.validFieldName(
                                              field.name
                                            ),
                                            value: field.value,
                                            "is-inner": "",
                                          },
                                        })
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.sanctionData.medias &&
                      _vm.sanctionData.medias.length > 0
                        ? _c(
                            "ui-card",
                            {
                              staticClass: "add-export-block",
                              attrs: { title: "AdverseMedia" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _c(
                                    "details-box",
                                    _vm._l(_vm.sanctionData.medias, function (
                                      media,
                                      index
                                    ) {
                                      return _c("details-item-media-row", {
                                        key: index,
                                        attrs: {
                                          value: media,
                                          icon: "far fa-newspaper",
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.sanctionData.medias &&
                      _vm.sanctionData.medias.legth > 0
                        ? _c(
                            "ui-card",
                            {
                              staticClass: "add-export-block",
                              attrs: {
                                title: "MediaSources",
                                expandable: "",
                                multicolumn: "",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  attrs: { slot: "body-left" },
                                  slot: "body-left",
                                },
                                [
                                  _vm._l(_vm.sanctionData.medias, function (
                                    media,
                                    index
                                  ) {
                                    return [
                                      !(index % 2)
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "col-12 details-item with-values inner-page-style",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "details-label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("DataSource")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "details-value",
                                                  },
                                                  [
                                                    media.pdf_url
                                                      ? [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  media.pdf_url,
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "PDFDownload"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.domainName(
                                                                    media.url
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !media.pdf_url
                                                      ? [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.domainName(
                                                                    media.url
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  attrs: { slot: "body-right" },
                                  slot: "body-right",
                                },
                                [
                                  _vm._l(_vm.sanctionData.medias, function (
                                    media,
                                    index
                                  ) {
                                    return [
                                      index % 2
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "col-12 details-item with-values inner-page-style",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "details-label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t("DataSource")
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "details-value",
                                                  },
                                                  [
                                                    media.pdf_url
                                                      ? [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  media.pdf_url,
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "PDFDownload"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.domainName(
                                                                    media.url
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !media.pdf_url
                                                      ? [
                                                          _c("span", [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.domainName(
                                                                    media.url
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]),
                                                        ]
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }