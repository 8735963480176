var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-frame",
    [
      _c(
        "ui-card",
        { attrs: { title: "CompanyProcedures.Title" } },
        [
          !_vm.proceduresList
            ? _c(
                "ui-button",
                {
                  staticClass: "btn-link",
                  attrs: { slot: "tools" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "CreateProcedures" })
                    },
                  },
                  slot: "tools",
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("CompanyProcedures.Buttons.Create")) +
                      "\n      "
                  ),
                  _c("i", { staticClass: "la la-plus" }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isReady
            ? _c("search-state", {
                attrs: {
                  loading: _vm.isLoading,
                  empty: _vm.isEmpty,
                  error: _vm.isError,
                  "loading-message": _vm.$t("App.Loading"),
                  "empty-message": _vm.$t(
                    "CompanyProcedures.EmptyState.Message"
                  ),
                  "empty-sub-message": _vm.$t(
                    "CompanyProcedures.EmptyState.SubMessage"
                  ),
                  "error-message": _vm.$t("Error.Fail"),
                },
              })
            : _c(
                "div",
                _vm._l(_vm.proceduresList, function (item, index) {
                  return _c("procedure-item", {
                    key: "procedure_" + index,
                    attrs: {
                      data: item,
                      "current-id": _vm.currentId,
                      "version-id": item.id,
                      "is-current-procedure":
                        _vm.currentProcedureVersionId === item.id,
                      label: _vm.label,
                    },
                    on: { "open-popup": _vm.handlePopup },
                  })
                }),
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        [
          _c("show-template", {
            attrs: { id: _vm.currentView, "version-id": _vm.versionId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }