<template>
  <div class="ui-drop-down" :class="{ narrow: narrow, center: center }">
    <label v-if="label" class="col-form-label">
      {{ $t(label) }}
    </label>
    <div class="value-box">
      <select
        ref="dropDown"
        :value="value"
        :disabled="disabled"
        @input="valueSelected"
      >
        <option :hidden="!nullable" selected :value="undefined">-</option>
        <slot />
      </select>
      <span class="drop-down-label">
        {{ labelTranslation }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-drop-down',

  props: {
    label: { type: String, default: undefined },
    valueDescription: { type: String, default: undefined },
    value: { type: [String, Number], default: undefined },
    disabled: Boolean,
    nullable: Boolean,
    narrow: Boolean,
    center: Boolean
  },

  data() {
    return {
      isLoading: false,
      valueText: null
    }
  },

  computed: {
    labelTranslation() {
      const { valueText, valueDescription } = this
      return valueDescription ?? valueText
    }
  },

  watch: {
    value() {
      this.getSelectionTitle()
    }
  },

  mounted() {
    this.getSelectionTitle()
  },

  methods: {
    getSelectionTitle() {
      const { customLabel, value } = this
      if (customLabel) this.valueText = customLabel

      let optionText
      for (const item of this.$refs.dropDown.childNodes) {
        if (item._value !== value) continue

        optionText = item.text
        break
      }

      this.valueText = optionText || value
    },

    valueSelected({ target }) {
      const { value } = this
      if (target.value === value) return

      this.$emit('input', target.value)
    }
  }
}
</script>

<style scoped>
.value-box {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 0;
  height: 42px;
}

select,
.drop-down-label {
  height: 100%;
  width: 100%;
  min-width: 42px;
  border-radius: 4px;
}

select {
  border: 1px solid transparent;
  opacity: 0;
  padding: 0;
}

.drop-down-label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  color: #606266;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  pointer-events: none;
}

select:focus {
  outline: none;
}

select:hover {
  cursor: pointer;
}

.narrow {
  width: auto;
}

.narrow select,
.narrow .drop-down-label {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.center .drop-down-label {
  justify-content: center;
}

select:hover + .drop-down-label {
  border-color: #c0c4cc;
}

select:disabled + .drop-down-label {
  opacity: 0.5;
  background-color: #ebeff7;
}
</style>
