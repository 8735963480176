var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-input m-input-icon m-input-icon--right",
      class: _vm.wide
        ? "m-input-icon--wide"
        : _vm.medium
        ? "m-input-icon--medium"
        : "m-input-icon--fixed",
    },
    [
      _c("ui-input", {
        class: { "m-input-date": _vm.type === "date" },
        attrs: {
          "small-text": "",
          icon: _vm.icon,
          type: _vm.type,
          value: _vm.value,
          disabled: _vm.disabled,
          "one-line": "",
          placeholder: _vm.placeholder,
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
        },
      }),
      _vm._v(" "),
      _vm.icon
        ? _c("span", { staticClass: "input-icon" }, [
            _c("i", { staticClass: "la", class: _vm.icon }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }