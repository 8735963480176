var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide", mode: "out-in" } }, [
    _vm.data.isVisible
      ? _c(
          "div",
          { staticClass: "pop-up", class: { "full-size": _vm.fullSize } },
          [
            _c("div", { staticClass: "pop-up-bg" }, [
              _c("div", { attrs: { id: "blah" } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pop-up-content" }, [
              _c(
                "div",
                {
                  staticClass: "content",
                  class: { "no-padding": _vm.noPadding },
                },
                [
                  _vm.popUpTitle
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center pop-up-header",
                          },
                          [
                            _c("div", [_vm._v(_vm._s(_vm.popUpTitle))]),
                            _vm._v(" "),
                            _c("a", {
                              staticClass: "close close-title",
                              on: { click: _vm.onClose },
                            }),
                          ]
                        ),
                      ]
                    : [
                        _c("a", {
                          staticClass: "close",
                          on: { click: _vm.onClose },
                        }),
                      ],
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "overflow-hidden": _vm.overflowHidden,
                        "overflow-unset": _vm.overflowUnset,
                      },
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                2
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }