var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "comments-title" }, [
        _vm._v(_vm._s(_vm.$t("VideoCallComments.Comments"))),
      ]),
      _vm._v(" "),
      _vm._l(_vm.comments, function (comment) {
        return _c(
          "div",
          { key: "comment-" + comment.createdAt, staticClass: "comment" },
          [
            _c("div", { staticClass: "comment-header" }, [
              _c("div", { staticClass: "comment-date" }, [
                _vm._v(_vm._s(_vm._f("date")(comment.createdAt, true))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "comment-author" }, [
                _vm._v(_vm._s(comment.email)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "comment-content" }, [
              _vm._v(_vm._s(comment.comment)),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }