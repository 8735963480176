<template>
  <div class="wrapper">
    <div ref="maps" class="info-maps" />
    <ui-button v-show="!showMap" class="maps-btn btn-brand" @click="initMaps">
      {{ $t('DetailsShowAddress') }}
    </ui-button>
  </div>
</template>

<script>
import GoogleMaps from 'google-maps'

export default {
  props: {
    address: { type: String, required: true }
  },

  data() {
    return {
      showMap: false
    }
  },

  beforeDestroy() {
    GoogleMaps.release()
  },

  methods: {
    initMaps() {
      this.showMap = true
      GoogleMaps.KEY = 'AIzaSyBv6kGtdvwLsDI_E7SY5iR7LmyisrNECEc'
      GoogleMaps.VERSION = '3.36'
      GoogleMaps.LIBRARIES = ['geometry', 'places']
      GoogleMaps.LANGUAGE = 'en'
      GoogleMaps.REGION = 'LT'
      GoogleMaps.load(this.loadMaps)
    },

    loadMaps(google) {
      const { address } = this
      const geocoder = new google.maps.Geocoder()

      const map = new google.maps.Map(this.$refs.maps, {
        disableDefaultUI: true,
        zoom: 16
      })

      if (geocoder) {
        geocoder.geocode({ address: address }, (results, status) =>
          this.getMapLocation(results, status, google, map)
        )
      }
    },

    getMapLocation(results, status, google, map) {
      if (status !== google.maps.GeocoderStatus.OK) return
      const location = results[0].geometry.location
      map.setCenter(location)
      new google.maps.Marker({ position: location, map })
    }
  }
}
</script>

<style scoped>
.wrapper {
  position: relative;
}
.info-maps {
  padding-top: 50%;
  background: url('~@src/assets/images/blurry-map-preview.png');
  background-size: cover;
}
.maps-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
</style>
