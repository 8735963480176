<template>
  <div class="editable-list">
    <div class="display-flex">
      <ui-dropdown
        v-if="editEnabled"
        :all="all"
        :has-search="hasSearch"
        :multi-select="multiSelect"
        :placeholder="addLabel"
        :options="availableOptions"
        :get-items-on-select="getItemsOnSelect"
        class="dropdown-margin"
        :disabled="disabled"
        @input="addItem"
        @selectAll="selectAll"
        @deleteAll="deleteAll"
        @getItemsOnSelect="addItem"
      />
      <ui-button
        v-if="multiSelect && editEnabled"
        class="delete-button btn btn-danger"
        type="button"
        @click="deleteAll"
      >
        <i class="la la-close" /> {{ $t('RemoveAllCompanies') }}
      </ui-button>
    </div>
    <editable-list-item
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :edit-enabled="editEnabled"
      :disabled="disabled"
      @remove="removeItem"
    />
    <span v-if="!items || !items.length" class="error-message">
      {{ $t(emptyMessage) }}
    </span>
  </div>
</template>

<script>
import EditableListItem from '@src/components/partials/EditableListItem'

export default {
  components: {
    EditableListItem
  },

  props: {
    items: { type: Array, default: undefined },
    options: { type: Array, default: undefined },
    addText: { type: String, default: undefined },
    emptyText: { type: String, default: undefined },
    editEnabled: Boolean,
    getItemsOnSelect: Boolean,
    disabled: Boolean,
    multiSelect: Boolean,
    all: Boolean,
    hasSearch: Boolean
  },

  computed: {
    addLabel() {
      const { addText } = this
      return addText || 'TableAdd'
    },

    emptyMessage() {
      const { emptyText } = this
      return emptyText || 'TableNoData'
    },

    availableOptions() {
      const { options, items } = this
      if (!options) return
      if (!items.length) return options
      const itemKeys = items.map((item) => item.key)
      return options.filter((item) => !itemKeys.includes(item.key))
    }
  },

  methods: {
    addItem(key, value) {
      const { items } = this
      items.push({ key, value })
    },

    selectAll() {
      const itemKeys = this.items.map((item) => item.key)
      this.items.push(
        ...this.options.filter((item) => !itemKeys.includes(item.key))
      )
    },

    deleteAll() {
      this.$emit('deleteAll')
    },

    removeItem(value) {
      const { items } = this
      const itemIndex = items.findIndex((item) => {
        return item.key === value
      })
      items.splice(itemIndex, 1)
    }
  }
}
</script>

<style scoped>
.editable-list {
  display: flex;
  flex-direction: column;
}

.editable-list > .display-flex {
  flex-wrap: wrap;
}

.editable-list > .list-item + .list-item {
  border-top: 1px solid #f4f5f8;
}

.editable-list > .error-message {
  font-size: 1rem;
  padding: 1rem 2rem;
  display: block;
}

.ui-dropdown {
  margin: 0.5rem 1rem 0.5rem 2rem;
}
</style>

<style>
.editable-list > div > .ui-dropdown > button {
  background-color: transparent;
  border: 1px solid var(--brand);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.editable-list > .display-flex > button.delete-button.ui-button {
  background: var(--danger);
  border: 0;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 2rem;
  margin-bottom: 1.5rem;
}
</style>
