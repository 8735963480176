var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_vm._t("header"), _vm._v(" "), _c("ui-container", [_vm._t("default")], 2)],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }