var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoring-setup" },
    [
      _c("h5", [_vm._v(_vm._s(_vm.$t("KycMonitoringSetup")))]),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", [_c("ui-loader", { attrs: { center: "", size: 2 } })], 1)
        : _vm.messageShow
        ? _c(
            "div",
            [
              _c("div", { staticClass: "message" }, [
                _vm.ignoredRegistries && _vm.ignoredRegistries.length > 0
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("AppIgnoredRegistries")) +
                          ": " +
                          _vm._s(_vm.ignoredRegistries) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.assignedRegistries && _vm.assignedRegistries.length > 0
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("AppAssignedRegistries")) +
                          ": " +
                          _vm._s(_vm.assignedRegistries) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorMessage
                  ? _c("p", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(_vm.errorMessage)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "ui-button",
                {
                  staticClass: "btn-brand",
                  attrs: { small: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("OK")]
              ),
            ],
            1
          )
        : !_vm.data.monitoringStatus
        ? _c(
            "ui-form",
            { staticClass: "monitoring-setup-form" },
            [
              _c(
                "div",
                _vm._l(_vm.selectedRegistries, function (registry, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "dropdowns-wrapper" },
                    [
                      _c("ui-dropdown", {
                        attrs: {
                          value: registry.registry,
                          title: _vm.registriesErrorMessage(registry.registry),
                          options: _vm.registryOptions,
                          placeholder: "AppSelectRegistry",
                          disabled: _vm.isLoading,
                          "light-border": "",
                          "has-search": "",
                          error: _vm.errors[key].registry,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.changeRegistry($event, key)
                          },
                        },
                      }),
                      _vm._v(" "),
                      registry.registry
                        ? _c("ui-dropdown", {
                            attrs: {
                              value: registry.interval,
                              options: _vm.intervalOptions,
                              placeholder: "AppInterval",
                              disabled: _vm.isLoading,
                              "light-border": "",
                              "has-search": "",
                              error: _vm.errors[key].interval,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.changeInterval($event, key)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "delete-registries" }, [
                        _vm.selectedRegistries.length > 1
                          ? _c(
                              "p",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRegistries(key)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("AppRemove")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.canAddRegisties
                ? _c(
                    "ui-button",
                    {
                      staticClass: "btn-link margin-bottom-2",
                      on: { click: _vm.addAnotherRegistry },
                    },
                    [
                      _c("span", { staticClass: "text-color-green" }, [
                        _vm._v(
                          "\n        + " +
                            _vm._s(_vm.$t("AppAddAnotherRegistry")) +
                            "\n      "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "save-button" },
                [
                  _c(
                    "ui-button",
                    {
                      staticClass: "btn-brand",
                      attrs: { type: "submit" },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("AppSave")) + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("ui-form", [
            _c("p", { staticClass: "message" }, [
              _vm._v(_vm._s(_vm.$t("WantDeleteUserMonitoringList"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "btn-danger",
                    attrs: { type: "submit", small: "" },
                    on: { click: _vm.deleteUser },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("AppDelete")) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ui-button",
                  {
                    staticClass: "btn-success",
                    attrs: { small: "" },
                    on: { click: _vm.cancel },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("AppCancel")) + "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }