var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isReady
    ? _c(
        "div",
        { staticClass: "loader-style" },
        [_c("ui-loader", { attrs: { size: 4, center: "" } })],
        1
      )
    : _vm.data
    ? _c(
        "div",
        { staticClass: "ultimate-beneficial-owner" },
        [
          _c(
            "ui-widget",
            { attrs: { title: "KybTimeTitle", "title-value": _vm.renewTime } },
            [
              _c(
                "details-item",
                {
                  attrs: {
                    label: "DetailsCompanyName",
                    value: _vm.data.name ? _vm.data.name : _vm.mainCompanyName,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "SanctionsSearch",
                              query: _vm.sanctionsSearchQuery,
                            },
                            target: "_blank",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return (function (event) {
                                _vm.handleClick({
                                  event: event,
                                  name: _vm.data.name,
                                })
                              })($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("DetailsScreenSanctions")) +
                              "\n          "
                          ),
                          _c("i", {
                            staticClass: "fas fa-external-link-square-alt",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("details-item", {
                attrs: { label: "DetailsCompanyCode", value: _vm.data.code },
              }),
              _vm._v(" "),
              _vm._l(_vm.allOwners, function (owner, index) {
                return _c("div", { key: index }, [
                  _c("p", { staticClass: "level-title" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("DetailsLevelOwnerCompany", {
                            level: owner.level,
                          })
                        ) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    _vm._l(_vm.filterOwner(owner), function (details, key) {
                      return _c(
                        "details-item",
                        {
                          key: key,
                          attrs: {
                            label: _vm.$t(_vm.getLabel(key)),
                            value: _vm.getDetails(details, key, owner)
                              ? _vm.getDetails(details, key, owner)
                              : "-",
                          },
                        },
                        [
                          key === "companyName" ||
                          (key === "firstName" && owner.lastName)
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "SanctionsSearch",
                                          query: _vm.sanctionsSearchQuery,
                                        },
                                        target: "_blank",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return (function (event) {
                                            var nameResolved =
                                              key === "firstName"
                                                ? owner[key] +
                                                  " " +
                                                  owner.lastName
                                                : owner[key]
                                            _vm.handleClick({
                                              event: event,
                                              name: nameResolved,
                                            })
                                          })($event)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("DetailsScreenSanctions")
                                          ) +
                                          "\n              "
                                      ),
                                      _c("i", {
                                        staticClass:
                                          "fas fa-external-link-square-alt",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  ),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ui-widget",
            {
              staticClass: "tree-chart-wrapper",
              attrs: { title: "RelationshipScheme" },
            },
            [
              _vm.treeData
                ? [
                    _c("TreeChart", { attrs: { json: _vm.treeData } }),
                    _vm._v(" "),
                    _vm.isLoadMoreVisible
                      ? _c(
                          "ui-button",
                          {
                            staticClass: "btn-brand btn-more",
                            attrs: {
                              low: "",
                              small: "",
                              "data-html2canvas-ignore": "",
                            },
                            on: { click: _vm.loadMore },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("DetailsLoadMore")) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "ui-button",
                      {
                        staticClass: "btn-brand btn-export",
                        attrs: {
                          icon: "la la-download",
                          low: "",
                          small: "",
                          "data-html2canvas-ignore": "",
                        },
                        on: { click: _vm.exportStructure },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("AppExportStructure")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                : [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: require("@src/assets/images/icons/file-slash.svg"),
                      },
                    }),
                    _vm._v(" "),
                    _vm.companyCountryCode.toUpperCase() ===
                    _vm.uboLoadMoreListIso2.LT
                      ? _c("p", [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("UBONotFoundLt")) +
                              "\n      "
                          ),
                        ])
                      : _c("p", [_vm._v(_vm._s(_vm.$t("UBONotFound")))]),
                  ],
            ],
            2
          ),
        ],
        1
      )
    : _vm.searchMessage
    ? _c("div", { staticClass: "search-message-style" }, [
        _vm._v("\n  " + _vm._s(_vm.$t(_vm.searchMessage)) + "\n"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }