<template>
  <div class="shared-database-page">
    <ui-header
      title="SharedDatabaseHeader"
      :method="searchMethod"
      :description="description"
      :filters="filters"
      :disabled="isLoading"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.database"
        placeholder="HeaderDatabasePlaceholder"
        medium
        :disabled="isLoading"
      />
    </ui-header>
    <ui-container v-if="databaseList">
      <div class="flex-row">
        <div class="flex-column flex-grow">
          <ui-card
            title="Person document information"
            icon="flaticon-user"
            dark
          >
            <details-box v-if="userDetails">
              <details-item
                :value="userDetails.firstName"
                label="DetailsFirstName"
              />
              <details-item
                :value="userDetails.lastName"
                label="DetailsLastName"
              />
              <details-item
                :value="userDetails.code"
                label="DetailsPersonCode"
              />
              <details-item
                :value="userDetails.type"
                label="DetailsDocumentType"
              />
              <details-item
                :value="userDetails.number"
                label="DetailsDocumentNumber"
              />
              <details-item
                :value="userDetails.expireDate"
                label="DetailsExpireDate"
              />
            </details-box>
            <div v-else>
              {{ $t('TableNoData') }}
            </div>
          </ui-card>
        </div>
        <div class="flex-column flex-grow">
          <ui-card title="Source of information" icon="flaticon-route" dark>
            <details-box v-if="courceList">
              <details-item
                :value="courceList.date"
                label="DetailsPublishDate"
              />
              <details-item
                :value="courceList.publisher"
                label="DetailsPublisher"
              />
              <details-item
                :value="courceList.number"
                label="DetailPublisherRegNumber"
              />
              <details-item
                :value="courceList.cource"
                label="DetailsSourceIdentification"
              />
              <details-item
                :value="courceList.type"
                label="DetailsTypeIdentification"
              />
              <details-item :value="courceList.id" label="DetailsUniqueID" />
            </details-box>
            <div v-else>
              {{ $t('TableNoData') }}
            </div>
          </ui-card>
        </div>
        <div class="flex-column flex-grow">
          <ui-card
            title="Ondato verifications"
            icon="flaticon-interface-7"
            dark
          >
            <details-box v-if="verificationsList">
              <details-item
                :value="`${verificationsList.sanction.item} (${verificationsList.sanction.data})`"
                label="DetailsSanctionsListCheck"
              />
              <details-item
                :value="`${verificationsList.pep.item} (${verificationsList.pep.data})`"
                label="DetailsPEPCheck"
              />
              <details-item
                :value="`${verificationsList.lostStolen.item} (${verificationsList.lostStolen.data})`"
                label="DetailsLostStolenCheck"
              />
            </details-box>
            <div v-else>
              {{ $t('TableNoData') }}
            </div>
          </ui-card>
        </div>
      </div>
      <div class="justify-center">
        <ui-button class="btn-brand">
          <i class="la la-download" />
          {{ $t('TableDownload') }}
        </ui-button>
      </div>
    </ui-container>
  </div>
</template>

<script>
import DetailsItem from '@src/components/partials/DetailsItem'
import DetailsBox from '@src/components/partials/DetailsBox'
export default {
  components: {
    DetailsItem,
    DetailsBox
  },

  data() {
    return {
      isLoading: false,
      usersCount: 0,
      databaseList: null,
      filters: {
        database: null
      },
      searchMethod: '/users/search', // pakeisti
      defaultFilters: null,
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc in lorem porta, gravida turpis in, volutpat lectus. Pellentesque eget erat id orci ornare tempus. Curabitur a quam elementum augue condimentum convallis. Vestibulum sem sem, dictum sed nibh at, tristique euismod urna. Suspendisse iaculis mauris lacus, pharetra semper sapien malesuada et. Curabitur tempor dapibus dui, non euismod justo aliquam nec.'
    }
  },

  computed: {
    userDetails() {
      const { userDetails } = this.databaseList
      if (!userDetails) return
      return userDetails
    },

    courceList() {
      const { courceList } = this.databaseList
      if (!courceList) return
      return this.databaseList.courceList
    },

    verificationsList() {
      const { verificationsList } = this.databaseList
      if (!verificationsList) return
      return this.databaseList.verificationsList
    }
  },

  methods: {
    startSearch() {
      this.isLoading = true
    },

    finishSearch() {
      this.isLoading = false
      // const { list, total } = data
      // this.userList = list
      // this.usersCount = total
      this.databaseList = {
        database: 'Ondato',
        userDetails: {
          firstName: 'Katia',
          lastName: 'Mys',
          code: '49012071343',
          type: 'passport',
          number: '123123',
          expireDate: '2026-10-10'
        },
        courceList: {
          date: '2018-08-01',
          publisher: 'UAB "Bendras Finansavimas"',
          number: '303259527',
          cource: 'Finpass',
          type: 'Video ID',
          id: 'bd96ec57731d46f0b6546b961657e268'
        },
        verificationsList: {
          sanction: { item: 'Pass', data: '2018-08-01' },
          pep: { item: 'Pass', data: '2018-08-01' },
          lostStolen: { item: 'Pass', data: '2018-08-01' }
        }
      }
      this.usersCount = 1
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 35rem;
}

.flex-grow {
  flex-grow: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.785rem;
  margin: 0 0.785rem;
}

.flex-column .m-portlet {
  width: 100%;
  height: 100%;
  word-wrap: break-word;
}

.justify-center {
  display: flex;
  justify-content: center;
}
</style>

<style>
.shared-database-page .details-value.text-value {
  margin-left: 0;
}

.shared-database-page .details-item {
  flex-direction: column;
  padding-left: 0;
}

.shared-database-page .details-item span {
  padding-left: 0;
}

.shared-database-page .dropdown-readonly {
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
}

.shared-database-page .details-label {
  padding-bottom: 0;
}
</style>
