<template>
  <div class="details-item media-item">
    <span class="details-icon">
      <i v-if="icon" :class="icon" />
    </span>
    <div v-if="value" class="details-value">
      <h4>
        <a :href="value.url" target="_blank">
          {{ value.title }}
        </a>
      </h4>
      <p>{{ value.snippet }}</p>
      <span class="p-date">
        <strong>{{ $t('Published') }}</strong>
        {{ normalDate(value.date) }}
      </span>
    </div>
  </div>
</template>

<script>
import { dateToString } from '@src/scripts/helpers'

export default {
  props: {
    value: { type: Object, default: undefined },
    icon: { type: String, default: undefined }
  },

  methods: {
    normalDate(dateString) {
      return dateToString(new Date(dateString), true)
    }
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.media-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ebedf2;
  width: 100%;
}

.media-item:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.details-value {
  margin-left: auto;
}

.media-item .details-icon {
  width: 180px;
  text-align: center;
  vertical-align: middle;
}

.media-item .details-value {
  width: calc(100% - 180px);
}

.media-item .details-icon i {
  font-size: 8rem;
}

@media (max-width: 678px) {
  .details-item.media-item .details-icon {
    display: none;
  }

  .media-item .details-value {
    width: 100%;
    padding: 1rem;
  }
}
</style>
