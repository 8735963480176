<template>
  <div class="comment-input">
    <ui-input
      v-model="comment"
      multiline
      multiline-overflow
      type="text"
      :max-length="maxLength"
      :disabled="loading"
      :rows="5"
      :placeholder="$t('VideoCallComments.Placeholder')"
    />
    <div class="comment-input-footer">
      <div>{{ $t('VideoCallComments.CharLeft', { left: leftChars }) }}</div>
      <div>
        <ui-button
          class="btn-brand submit-btn"
          :disabled="isValid || loading"
          @click="handleSubmit"
        >
          {{ $t('VideoCallComments.Save') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { notificationType } from '@src/components/notification'

export default {
  components: {},
  props: {
    identificationId: { type: String, default: null }
  },
  data: function () {
    return {
      loading: false,
      maxLength: 3000,
      comment: ''
    }
  },
  computed: {
    isValid() {
      return this.comment.trim().length === 0
    },
    leftChars() {
      return this.maxLength - this.comment.length
    }
  },
  methods: {
    async handleSubmit() {
      const response = await Api.post(
        `/call/identification/${this.identificationId}/comment`,
        { comment: this.comment }
      )

      this.comment = ''

      this.$emit('created', response)

      this.$notify({
        title: 'VideoCallComments.CommentCreated',
        type: notificationType.infoTitle
      })
    }
  }
}
</script>

<style>
.comment-input .comment-input-footer {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.comment-input textarea.m-input.with-overflow {
  height: 92px;
  min-height: 92px;
}
</style>
