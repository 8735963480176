<template>
  <ui-form @submit="saveChanges">
    <ui-widget class="col-md-6" title="">
      <details-item
        label="KycStartUrl"
        :value="revision.startUrl"
        :editable="editEnabled"
        @change="startUrlChanged"
      />
      <details-item
        label="KycRedirectUrl"
        :value="revision.redirectUrl"
        :editable="editEnabled"
        @change="redirectUrlChanged"
      />
      <details-item
        v-if="revision.label.includes('Document')"
        label="KycAllowCamera"
        :value="revision.allowCamera"
        :editable="editEnabled"
        @change="allowCameraChanged"
      />
      <details-item
        label="KycPageTitle"
        :value="revision.pageTitle"
        :editable="editEnabled"
        @change="pageTitleChanged"
      />
      <details-item
        label="KycActionLogUrl"
        :value="revision.actionLogUrl"
        :editable="editEnabled"
        @change="actionLogUrlChanged"
      />
      <details-item
        label="KycActionLogLanguage"
        placeholder="changeLang"
        :value="revision.actionLogLanguage"
        :editable="editEnabled"
        :options="lanugageOptions"
        @change="actionLogLanguageChanged"
      />
      <ui-tag-group
        label="KycPendingIdentificationEmailAddress"
        placeholder="DetailsNewTag"
        :editable="editEnabled"
        :tags="revision.pendingIdentificationEmailAddress"
        @removeTag="deleteEmail"
        @addTag="addEmail"
      />
      <details-item
        label="KycSuccessfulIdentificationEmailAddress"
        :value="revision.successfulIdentificationEmailAddress"
        :editable="editEnabled"
        @change="successfulIdentificationEmailAddressChanged"
      />
      <details-item
        v-if="revision.label.includes('Form')"
        label="KycFormTypeId"
        :options="revision.formTypeIdOptions"
        :value="revision.formTypeId"
        placeholder="KycFormTypeId"
        :editable="editEnabled"
        @change="formTypeIdChanged"
      />
      <edit-footer
        class="footer"
        :edit-enabled="editEnabled"
        @edit="enableDetails"
      />
    </ui-widget>
  </ui-form>
</template>

<script>
import DetailsItem from '@src/components/partials/DetailsItem'
import EditFooter from '@src/components/partials/EditFooter'

export default {
  components: {
    DetailsItem,
    EditFooter
  },

  props: {
    data: { type: Object, default: Object },
    editable: Boolean
  },

  data() {
    return {
      editEnabled: this.editable,
      revision: null
    }
  },

  computed: {
    lanugageOptions() {
      const options = [
        {
          key: 'lt',
          value: 'LT'
        },
        {
          key: 'en',
          value: 'EN'
        }
      ]
      return options
    }
  },

  watch: {
    editEnabled() {
      this.$emit('change', this.editEnabled)
      if (this.editEnabled) {
        const { data } = this
        this.revision = Object.assign({}, data)
      }
    }
  },

  created() {
    const { data } = this
    this.revision = Object.assign({}, data)
  },

  methods: {
    deleteEmail(value) {
      this.revision.pendingIdentificationEmailAddress = this.revision.pendingIdentificationEmailAddress.filter(
        (item) => item !== value
      )
    },

    addEmail(value) {
      if (value && value.length > 0)
        this.revision.pendingIdentificationEmailAddress.push(value)
    },

    enableDetails() {
      this.$set(this, 'editEnabled', true)
    },

    saveChanges() {
      this.$set(this, 'editEnabled', false)
      this.$emit('submit', this.revision)
    },

    startUrlChanged(value) {
      this.$set(this.revision, 'startUrl', value)
    },

    redirectUrlChanged(value) {
      this.$set(this.revision, 'redirectUrl', value)
    },

    allowCameraChanged(value) {
      this.$set(this.revision, 'allowCamera', value)
    },

    pageTitleChanged(value) {
      this.$set(this.revision, 'pageTitle', value)
    },

    actionLogUrlChanged(value) {
      this.$set(this.revision, 'actionLogUrl', value)
    },

    actionLogLanguageChanged(value) {
      this.$set(this.revision, 'actionLogLanguage', value)
    },

    pendingIdentificationEmailAddressChanged(value) {
      this.$set(this.revision, 'pendingIdentificationEmailAddress', value)
      if (value && value.length > 0) this.tags.push(value)
    },

    successfulIdentificationEmailAddressChanged(value) {
      this.$set(this.revision, 'successfulIdentificationEmailAddress', value)
    },

    formTypeIdChanged(value) {
      this.$set(this.revision, 'formTypeId', value)
    }
  }
}
</script>

<style scoped>
.right-block {
  max-width: 40rem;
}

.info-maps {
  padding-top: 50%;
}

.footer {
  margin-left: 2.2rem;
  margin-top: 1rem;
}
</style>
