<template>
  <div class="search-state">
    <template v-if="loading">
      <ui-loader :size="4" />
      <p v-if="loadingMessage">{{ loadingMessage }}</p>
    </template>
    <template v-else-if="empty">
      <i class="fa fa-file" />
      <div v-if="emptyMessage" class="empty-message">{{ emptyMessage }}</div>
      <div v-if="emptySubMessage" class="sub-message">
        {{ emptySubMessage }}
      </div>
    </template>
    <template v-else-if="error">
      <i class="fa fa-exclamation-circle" />
      <p v-if="errorMessage">{{ errorMessage }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    empty: Boolean,
    error: Boolean,
    loadingMessage: { type: String, default: null },
    emptyMessage: { type: String, default: null },
    emptySubMessage: { type: String, default: null },
    errorMessage: { type: String, default: null }
  }
}
</script>

<style scoped>
.search-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-state .fa {
  font-size: 4rem;
}

.fa-file {
  color: #a5a5a5;
}

.fa-exclamation-circle {
  color: var(--danger);
}

p {
  margin-top: 1.5rem;
}

.empty-message {
  font-weight: bold;
  font-size: 1.25rem;
  margin-top: 0.5rem;
}
.sub-message {
  margin-top: 0.5rem;
  font-size: 1.25rem;
}
</style>
