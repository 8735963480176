<template>
  <div class="m-section identifications-table transparent-bg ui-table">
    <div class="m-section__content">
      <div class="table-responsive">
        <table class="table m-table">
          <thead>
            <tr>
              <th v-for="(label, index) in labels" :key="index">
                {{ $t(label) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isLoading" class="m-table__row">
              <td class="loader-box" :colspan="columnsCount">
                <ui-loader :size="3" center />
              </td>
            </tr>
            <slot v-else-if="hasData && !isLoading" />
            <tr v-else-if="!hasData && !isLoading" class="m-table__row">
              <td class="table-message" :colspan="columnsCount">
                {{ $t(noDataText) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ui-table',

  props: {
    labels: { type: Array, default: undefined },
    emptyText: { type: String, default: undefined },
    data: { type: Array, default: undefined }
  },

  data() {
    return {
      hasData: true,
      mutationObserver: null,
      isLoading: true
    }
  },

  computed: {
    columnsCount() {
      const { labels } = this
      return labels && labels.length
    },

    noDataText() {
      const { emptyText } = this
      return emptyText || 'TableNoData'
    }
  },

  watch: {
    data(newValue) {
      if (newValue && newValue.length > 0) {
        this.hasData = !!newValue
      } else this.hasData = false
    }
  },

  created() {
    if (this.data && this.data.length > 0) {
      this.hasData = !!this.data
    } else this.hasData = false
    this.isLoading = false
  }
}
</script>

<style scoped>
.ui-table {
  width: 100%;
}

.ui-table thead > tr > th {
  border-top: 0;
}

.ui-table td {
  vertical-align: middle;
}

.ui-table td.fixed-width {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 16rem;
}

.ui-table td > .btn {
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
}

.ui-table td.table-message {
  text-align: center;
  background-color: #fffbf6;
}

@media (max-width: 1350px) {
  .ui-table td.fixed-width {
    max-width: 8.5rem;
  }
}

@media (max-width: 1100px) {
  .ui-table td {
    padding: 0.5rem;
  }
}
</style>

<style>
.table-overflow .table-responsive {
  overflow-x: auto;
}
</style>
