var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "resources-card" }, [
    _vm.icon
      ? _c("i", { staticClass: "resources-icon", class: _vm.icon })
      : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c("div", { staticClass: "resources-title" }, [
        _vm._v("\n      " + _vm._s(_vm.$t(_vm.title)) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "resources-description" }, [
        _vm._v("\n      " + _vm._s(_vm.$t(_vm.description)) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }