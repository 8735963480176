var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "DashboardTitle",
            method: _vm.searchMethod,
            filters: _vm.filters,
            disabled: _vm.isLoading,
            "auto-refresh": _vm.hasRealTimeStatistics,
            "has-body": _vm.hasRealTimeStatistics,
            "refresh-interval": 300000,
            "company-change": "",
          },
          on: {
            toggleRefresh: _vm.toggleRefresh,
            startSearch: _vm.startSearch,
            finishSearch: _vm.finishSearch,
          },
        },
        [
          _vm.hasRealTimeStatistics
            ? _c("div", { staticClass: "filters-block" }, [
                _c(
                  "div",
                  { staticClass: "display-flex flex-flow-wrap" },
                  [
                    _c("ui-header-dropdown", {
                      attrs: {
                        options: _vm.options.dateOptions,
                        placeholder: "HeaderDatePlaceHolder",
                        wide: "",
                        disabled: _vm.isLoading,
                      },
                      model: {
                        value: _vm.filters.date,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "date", $$v)
                        },
                        expression: "filters.date",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("ui-container", [
        _vm.isLoading
          ? _c("div", [_c("ui-loader", { attrs: { size: 4, center: "" } })], 1)
          : _vm._e(),
        _vm._v(" "),
        _vm.data && !_vm.isLoading
          ? _c(
              "div",
              { staticClass: "dashboard-cards" },
              [
                _c(
                  "ui-card",
                  { attrs: { title: "MainInfo" } },
                  [
                    _c("details-item-orange", {
                      attrs: {
                        label: "AllClients",
                        value: _vm.data.totalIdentificationsCount,
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "NewClients7Day",
                        value: _vm.data.newidentificationsCount,
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "DocQuantityExpirins2Month",
                        value: _vm.data.expiringDocsCount,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.callsStatistics
                  ? _c("dashboard-video-call", {
                      attrs: {
                        "get-time": _vm.getTime,
                        "auto-refresh": _vm.enabledAutoRefresh,
                        "refresh-interval": 30000,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ui-card",
                  { attrs: { title: "ChrossCheckStatistics" } },
                  [
                    _c("details-item-orange", {
                      attrs: {
                        label: "CrosscheckTimeMean",
                        value: _vm.getTime(_vm.data.crosscheckTimeMean),
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "CrosscheckTimeMedian",
                        value: _vm.getTime(_vm.data.crosscheckTimeMedian),
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "LongestCrossCheck7Day",
                        value: _vm.getTime(_vm.data.crosscheckTimeLongest),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.callsStatistics
                  ? _c(
                      "ui-card",
                      { attrs: { title: "Dashboard.Call.History.Title" } },
                      [
                        _c("details-item-orange", {
                          attrs: {
                            label: "Dashboard.Call.History.Total",
                            value: _vm.callsStatistics.totalCount,
                          },
                        }),
                        _vm._v(" "),
                        _c("details-item-orange", {
                          attrs: {
                            label: "Dashboard.Call.History.Answered",
                            value: _vm.callsStatistics.answeredCount,
                          },
                        }),
                        _vm._v(" "),
                        _c("details-item-orange", {
                          attrs: {
                            label: "Dashboard.Call.History.Missed",
                            value: _vm.callsStatistics.missedCount,
                          },
                        }),
                        _vm._v(" "),
                        _c("details-item-orange", {
                          attrs: {
                            label: "Dashboard.Call.History.AverageQueueTime",
                            value: _vm.getTime(
                              _vm.callsStatistics.averageQueueTime
                            ),
                          },
                        }),
                        _vm._v(" "),
                        _c("details-item-orange", {
                          attrs: {
                            label: "Dashboard.Call.History.TwoMinPercentage",
                            value: _vm._f("percentage")(
                              _vm.callsStatistics.twoMinPercentage
                            ),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ui-card",
                  { attrs: { title: "RejectedIdentification7Day" } },
                  [
                    _c("div", { staticClass: "orange-big-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.rejectedIdentificationsCount) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.data.rejectedIdentificationsReasonsWithCounts,
                      function (detail, key) {
                        return _c("details-item-orange", {
                          key: key,
                          attrs: { label: detail.reason, value: detail.count },
                        })
                      }
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "ui-card",
                  { attrs: { title: "UnfinishedIdentificationAmount7day" } },
                  [
                    _c("div", { staticClass: "orange-big-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.data.unfinishedIdentificationsTotalCount) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "UnfinishedIdentificationNoFiles",
                        value:
                          _vm.data
                            .unfinishedIdentificationsWithouthAnyFilesCount,
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "UnfinishedIdentificationOnlyFace",
                        value:
                          _vm.data.unfinishedIdentificationsWithOnlyFaceCount,
                      },
                    }),
                    _vm._v(" "),
                    _c("details-item-orange", {
                      attrs: {
                        label: "UnfinishedIdentificationFaceAndDoc",
                        value:
                          _vm.data
                            .unfinishedIdentificationsWithFaceAndDocumentCount,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }