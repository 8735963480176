<template>
  <div class="resources-card">
    <i v-if="icon" class="resources-icon" :class="icon" />
    <div>
      <div class="resources-title">
        {{ $t(title) }}
      </div>
      <div class="resources-description">
        {{ $t(description) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: undefined },
    title: { type: String, default: undefined },
    description: { type: [String, Number], default: undefined }
  }
}
</script>

<style scoped>
.resources-card {
  border: 1px solid var(--light-br-gray);
  padding: 1.5rem 3.1rem;
  display: flex;
}

.resources-card + .resources-card {
  border-top: none;
}

.resources-icon {
  font-size: 2.5rem;
  margin-right: 1.2rem;
}

.resources-title {
  color: var(--dark-font);
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.resources-description {
  font-size: 1.1rem;
}

@media (max-width: 568px) {
  .resources-card {
    padding: 1rem;
  }
}
</style>
