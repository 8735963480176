var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "m-widget1" }, [
    _c(
      "div",
      {
        staticClass: "m-widget__header",
        class: { "top-spacing": _vm.topSpacing },
      },
      [
        _vm.title
          ? _c("h5", { staticClass: "m-widget__title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t(_vm.title, _vm.titleValue)) +
                  "\n    "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c("span", { staticClass: "m-widget14__desc" }, [
              _vm._v("\n      " + _vm._s(_vm.$t(_vm.description)) + "\n    "),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.hasBody
      ? _c(
          "div",
          {
            staticClass: "m-widget1__item",
            class: { "no-spacing": _vm.noSpacing },
          },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }