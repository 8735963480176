var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-container",
        [
          _c(
            "identification-header",
            [
              _c("template", { slot: "left-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "card-header-tabs-btn",
                    class: [
                      _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                        ? "active"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.tabChange(
                          _vm.IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("IdentificationDashboard.ActiveUsers")) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "card-header-tabs-btn",
                    class: [
                      _vm.activeTab ===
                      _vm.IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
                        ? "active"
                        : "",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.tabChange(
                          _vm.IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("IdentificationDashboard.ByCompany")) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ui-card",
            { staticClass: "card-body" },
            [
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : [
                    _c(
                      "div",
                      { staticClass: "reload-btn" },
                      [
                        _c(
                          "ui-button",
                          {
                            staticClass: "btn-brand",
                            attrs: { low: "", small: "" },
                            on: { click: _vm.fetchData },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("IdentificationDashboard.ReloadList")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.activeTab ===
                    _vm.IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                      ? _c(
                          "div",
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-6" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row mb-4 align-items-start no-gutters",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "col-6" },
                                      [
                                        _c("ui-input", {
                                          attrs: {
                                            placeholder: "Id",
                                            error: _vm.userFilterError,
                                            type: "text",
                                          },
                                          model: {
                                            value: _vm.userFilterModel,
                                            callback: function ($$v) {
                                              _vm.userFilterModel = $$v
                                            },
                                            expression: "userFilterModel",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3" },
                                      [
                                        _c("ui-dropdown", {
                                          staticClass: "mx-2 w-100",
                                          attrs: {
                                            options: _vm.dateOptions,
                                            small: "",
                                          },
                                          model: {
                                            value: _vm.userDateModel,
                                            callback: function ($$v) {
                                              _vm.userDateModel = $$v
                                            },
                                            expression: "userDateModel",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3" },
                                      [
                                        _c(
                                          "ui-button",
                                          {
                                            staticClass:
                                              "ml-3 btn-brand filter-button",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleUserFilter(
                                                  "users"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "IdentificationDashboard.Filter"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            Object.keys(_vm.identifications.byEmail).length
                              ? _c(
                                  "dash-board-table",
                                  [
                                    _c("template", { slot: "table-header" }, [
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "IdentificationDashboard.Email"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { attrs: { scope: "col" } }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "IdentificationDashboard.IdentificationCount"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-center",
                                          attrs: { scope: "col" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "IdentificationDashboard.Summary"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "template",
                                      { slot: "table-body" },
                                      _vm._l(
                                        _vm.identifications.byEmail,
                                        function (identification, index) {
                                          return _c("tr", { key: index }, [
                                            _c("td", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    index === "undefined"
                                                      ? _vm.$t("ApprovedInBO")
                                                      : index
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(identification.length)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              { staticClass: "text-center" },
                                              [
                                                index !== "undefined"
                                                  ? _c(
                                                      "ui-button",
                                                      {
                                                        staticClass:
                                                          "btn-brand",
                                                        attrs: {
                                                          low: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openSummery(
                                                              identification[0]
                                                                .assignedUserId,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "IdentificationDashboard.Open"
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  2
                                )
                              : _c("div", { staticClass: "p-4 text-center" }, [
                                  _c("h4", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "CompanyProduct.CountryNotFound"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeTab ===
                    _vm.IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
                      ? _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row mb-4 align-items-start no-gutters",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c("ui-input", {
                                        attrs: {
                                          placeholder: "Id",
                                          error: _vm.companyError,
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.companyFilterModel,
                                          callback: function ($$v) {
                                            _vm.companyFilterModel = $$v
                                          },
                                          expression: "companyFilterModel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c("ui-dropdown", {
                                        staticClass: "mx-2 w-100",
                                        attrs: {
                                          options: _vm.dateOptions,
                                          small: "",
                                        },
                                        model: {
                                          value: _vm.companyDateModel,
                                          callback: function ($$v) {
                                            _vm.companyDateModel = $$v
                                          },
                                          expression: "companyDateModel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-3" },
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass:
                                            "ml-3 btn-brand filter-button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUserFilter(
                                                "company"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t(
                                                  "IdentificationDashboard.Filter"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                Object.keys(_vm.identifications.byCompany)
                                  .length
                                  ? _c(
                                      "dash-board-table",
                                      [
                                        _c(
                                          "template",
                                          { slot: "table-header" },
                                          [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Company"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.IdentificationCount"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "text-center",
                                                attrs: { scope: "col" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "IdentificationDashboard.Summary"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "template",
                                          { slot: "table-body" },
                                          _vm._l(
                                            _vm.identifications.byCompany,
                                            function (identification, index) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(index) +
                                                      "\n                    "
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      identification.length
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "ui-button",
                                                      {
                                                        staticClass:
                                                          "btn-brand",
                                                        attrs: {
                                                          low: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openSummery(
                                                              identification[0]
                                                                .applicationId,
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "IdentificationDashboard.Open"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      2
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "p-4 text-center" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "CompanyProduct.CountryNotFound"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "pop-up",
        {
          attrs: {
            "pop-up-title":
              _vm.$t("IdentificationDashboard.IdLogs") +
              " " +
              _vm.userIdentificationsFullName,
            "no-padding": "",
            "full-size": "",
          },
        },
        [
          _c("user-identifications", {
            attrs: {
              "user-id": _vm.userIdentificationsId,
              "user-name": _vm.userIdentificationsFullName,
              type:
                _vm.activeTab === _vm.IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                  ? _vm.IDENTIFICATION_DASHBOARD_TABS.BY_USER_TAB
                  : _vm.IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }