var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { staticClass: "statements" },
        [
          _vm.companyCountryCode === _vm.uboLoadMoreListIso2.EE ||
          _vm.companyCountryCode === _vm.uboLoadMoreListIso2.ES ||
          _vm.companyCountryCode === _vm.uboLoadMoreListIso2.LV
            ? [
                _c("span", { staticClass: "export-count" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("KybNumberOfDownloads")) +
                      "\n      " +
                      _vm._s(_vm.shareholdersDownloadCount) +
                      "\n    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ui-widget",
                  { attrs: { title: "KybBusinessRegistryTitle" } },
                  [
                    _c("div", { staticClass: "export-item" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("KybBusinessRegistryExtract"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c(
                            "ui-button",
                            {
                              staticClass: "btn-brand button-width",
                              attrs: { small: "", low: "" },
                              on: { click: _vm.downloadPdf },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("AppExport")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            : [
                _vm.companyReportCount
                  ? _c("span", { staticClass: "export-count" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("CompanyStatementsCount")) +
                          ": " +
                          _vm._s(_vm.companyReportCount) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.financialReportCount &&
                _vm.$route.name !== "LegalEntityMonitoringEvents"
                  ? _c("span", { staticClass: "export-count" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("FinancialStatementsCount")) +
                          ": " +
                          _vm._s(_vm.financialReportCount) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.scorifyStatementsCount &&
                _vm.$route.name !== "LegalEntityMonitoringEvents"
                  ? _c("span", { staticClass: "export-count" }, [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("ScorifyStatementsCount")) +
                          ": " +
                          _vm._s(_vm.scorifyStatementsCount) +
                          "\n    "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.enabledRegistryItems.length > 0
                  ? _c(
                      "ui-widget",
                      { attrs: { title: "KybBusinessRegistry" } },
                      _vm._l(_vm.enabledRegistryItems, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "export-item",
                            class: {
                              "export-item--has-date": item.lastDownloadDate,
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                            _vm._v(" "),
                            _c(
                              "span",
                              [
                                _c(
                                  "ui-button",
                                  {
                                    staticClass: "btn-brand button-width",
                                    attrs: { small: "", low: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownload(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t(item.buttonTitle)) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                item.lastDownloadDate
                                  ? _c(
                                      "div",
                                      { staticClass: "export-item__date" },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t("DashboardViewed") +
                                                " " +
                                                item.lastDownloadDate
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasStatementAccess &&
                _vm.$route.name !== "LegalEntityMonitoringEvents"
                  ? _c(
                      "ui-widget",
                      { attrs: { title: "KybFinancialStatements" } },
                      _vm._l(_vm.statements, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "export-item" },
                          [
                            _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                            _vm._v(" "),
                            item.buttonTitle !== "AppExport"
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "ui-button",
                                      {
                                        staticClass: "btn-brand button-width",
                                        attrs: { small: "", low: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.LoginKyb(item.url)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t(item.buttonTitle)) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", { staticClass: "icon-block" }, [
                                  _c("i", {
                                    staticClass:
                                      "flaticon-file-pdf-file-format-symbol",
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportStatement(
                                          item,
                                          "pdf",
                                          item.url + "/pdf"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass:
                                      "flaticon-file-xlsx-file-format-extension",
                                    on: {
                                      click: function ($event) {
                                        return _vm.exportStatement(
                                          item,
                                          "xlsx",
                                          item.url + "/xlsx"
                                        )
                                      },
                                    },
                                  }),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.noOtherDocuments
                  ? _c(
                      "ui-widget",
                      { attrs: { title: "KybOtherDocuments" } },
                      [
                        _vm.hasScorifyAccess
                          ? _c("div", { staticClass: "export-item" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("KybExportScorifyStatement"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand button-width",
                                      attrs: { small: "", low: "" },
                                      on: { click: _vm.downloadScorify },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("AppExport")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.extraStatements, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "export-item" },
                            [
                              _c("span", [_vm._v(_vm._s(_vm.$t(item.title)))]),
                              _vm._v(" "),
                              item.buttonTitle !== "AppExport"
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn-brand button-width",
                                          attrs: { small: "", low: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.LoginKyb(item.url)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(_vm.$t(item.buttonTitle)) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", { staticClass: "icon-block" }, [
                                    _c("i", {
                                      staticClass:
                                        "flaticon-file-pdf-file-format-symbol",
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportStatement(
                                            item,
                                            "pdf",
                                            item.url + "/pdf"
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "flaticon-file-xlsx-file-format-extension",
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportStatement(
                                            item,
                                            "xlsx",
                                            item.url + "/xlsx"
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
        ],
        2
      )
    : _c(
        "div",
        { staticClass: "loader" },
        [_c("ui-loader", { attrs: { size: 3, center: "" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }