export const usaStates = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
]

export const canStates = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'NT',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT'
]

export const mexStates = [
  'AG',
  'BN',
  'BS',
  'CH',
  'CI',
  'CL',
  'CP',
  'CS',
  'DF',
  'DG',
  'GE',
  'GJ',
  'HD',
  'JA',
  'MC',
  'MR',
  'MX',
  'NA',
  'NL',
  'OA',
  'PU',
  'QE',
  'QI',
  'SI',
  'SL',
  'SO',
  'TA',
  'TB',
  'TL',
  'VC',
  'YU',
  'ZA'
]
