var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass:
        "m--font-brand m-checkbox--state-brand m-checkbox--margin-right-15 m-checkbox m-checkbox--square ui-checkbox",
      class: {
        "m-checkbox--disabled": _vm.disabled,
      },
    },
    [
      _c("input", {
        class: { checked: _vm.isChecked },
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.isChecked },
        on: { input: _vm.updateInput },
      }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.$t(_vm.label)))]),
      _vm._v(" "),
      _c("span"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }