<template>
  <div class="seon-email">
    <h4>{{ $t('Seon.Email.Header') }}</h4>
    <div v-if="isReady">
      <template v-if="data">
        <Group>
          <h5>{{ data.email }}</h5>
        </Group>
        <Group v-if="appliedRules">
          <h5>{{ $t('Seon.Email.AppliedRules') }}</h5>
          <GroupItem
            v-for="item in appliedRules"
            :key="item.id"
            :name="item.id"
            :value="item.name"
          />
        </Group>
        <Group>
          <GroupItem
            :name="$t('Seon.Email.Deliverable')"
            :value="(data.deliverable && $t('App.Yes')) || $t('App.No')"
          />
        </Group>
        <Group v-if="breachDetails">
          <h5>{{ $t('Seon.Email.DataBreaches.Label') }}</h5>
          <GroupItem
            :name="$t('Seon.Email.DataBreaches.Count')"
            :value="breachDetails.number_of_breaches"
          />
          <GroupItem
            :name="$t('Seon.Email.DataBreaches.First')"
            :value="breachDetails.first_breach || $t('App.None')"
          />
          <GroupItem
            v-for="item in breachDetails.breaches"
            :key="item.name"
            :name="item.name"
            :value="item.date"
          />
        </Group>
        <Group v-if="onlineProfiles">
          <h5>{{ $t('Seon.Email.OnlineProfiles') }}</h5>
          <GroupItem
            v-for="item in onlineProfiles"
            :key="item.name"
            :name="item.name"
            :value="(item.value && $t('App.Yes')) || $t('App.No')"
          />
        </Group>
        <Group v-if="domain">
          <h5>{{ $t('Seon.Email.Domain.Label') }}</h5>
          <GroupItem
            :name="$t('Seon.Email.Domain.Registered')"
            :value="(domain.registered && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.CreationDate')"
            :value="
              (domain.created && dateTimeHandler(domain.created)) ||
              $t('App.None')
            "
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.UpdateDate')"
            :value="
              (domain.updated && dateTimeHandler(domain.updated)) ||
              $t('App.None')
            "
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.ExpireDate')"
            :value="
              (domain.expires && dateTimeHandler(domain.expires)) ||
              $t('App.None')
            "
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.Name')"
            :value="domain.registrar_name || $t('App.None')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.Owner')"
            :value="domain.registered_to || $t('App.None')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.Dmarc')"
            :value="(domain.dmarc_enforced && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.Spf')"
            :value="(domain.spf_strict && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.ValidMx')"
            :value="(domain.valid_mx && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.AcceptAll')"
            :value="(domain.accept_all && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.Tld')"
            :value="(domain.suspicious_tld && $t('App.Yes')) || $t('App.No')"
          />
          <GroupItem
            :name="$t('Seon.Email.Domain.WebExists')"
            :value="(domain.website_exists && $t('App.Yes')) || $t('App.No')"
          />
        </Group>
      </template>
      <p v-else>{{ $t('Seon.Email.Error') }}</p>
    </div>
    <ui-loader v-else center />
  </div>
</template>

<script>
import Group from '@src/components/partials/refactor/layouts/Group'
import GroupItem from '@src/components/partials/refactor/layouts/GroupItem'
import Api from '@src/scripts/api'
import { dateToString } from '@src/scripts/helpers'

export default {
  components: {
    Group,
    GroupItem
  },

  props: {
    fileId: { type: String, default: null }
  },

  data() {
    return {
      isReady: false,
      data: null
    }
  },

  computed: {
    appliedRules() {
      return (
        (this.data?.applied_rules?.length && this.data.applied_rules) || null
      )
    },

    breachDetails() {
      return this.data?.breach_details || null
    },

    onlineProfiles() {
      if (!this.data?.account_details) return null

      const list = Object.entries(this.data.account_details)
        .map(([key, value]) => ({
          name: key,
          value: !!value.registered
        }))
        .filter((item) => item.value)

      return (list.length && list) || null
    },

    domain() {
      return this.data?.domain_details || null
    }
  },

  async mounted() {
    await this.loadData()
  },

  methods: {
    async loadData() {
      const { fileId } = this
      const path = `/data-hub/seon/files/${fileId}/email-details`

      try {
        this.data = await Api.get(path)
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      this.isReady = true
    },

    dateTimeHandler(value) {
      return dateToString(value, true)
    }
  }
}
</script>

<style scoped>
.seon-email {
  width: 90vw;
  max-height: 85vh;
  max-width: 50rem;
}

h5 {
  font-family: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
}
</style>
