<template>
  <ui-form :key="submitCount" @submit="saveChanges">
    <ui-widget class="checking-methods">
      <div>
        {{ $t('CheckSettingMethodDescription') }}
      </div>
      <div class="flex-row m--margin-top-30">
        <toggle-settings-box
          title="MRZ zone check"
          :value="settings.mrzZone"
          description="MRZ zone check description"
          :disabled="!details.editEnabled"
          @changeToggle="mrzZoneChange"
        />
        <toggle-settings-box
          title="Lost & Stolen database check"
          :value="settings.database"
          description="Lost & Stolen database check description"
          :disabled="!details.editEnabled"
          @changeToggle="databaseChange"
        />
        <toggle-settings-box
          title="Sanction list check"
          :value="settings.sanction"
          description="Sanction list check description"
          :disabled="!details.editEnabled"
          @changeToggle="sanctionChange"
        />
        <toggle-settings-box
          title="PEP list check"
          :value="settings.pepList"
          description="PEP list check description"
          :disabled="!details.editEnabled"
          @changeToggle="pepListChange"
        />
        <toggle-settings-box
          title="Liveness check"
          :value="settings.liveNess"
          description="Liveness check description"
          :disabled="!details.editEnabled"
          @changeToggle="liveNessChange"
        />
      </div>
      <div class="tools-buttons">
        <edit-footer
          :edit-enabled="details.editEnabled"
          @edit="enableDetailsEdit"
        />
        <ui-button
          v-if="details.editEnabled"
          small
          class="btn btn-metal m--margin-left-10"
          @click="clickCancel"
        >
          <i class="la la-close" />
          <span> {{ $t('AppCancel') }} </span>
        </ui-button>
      </div>
    </ui-widget>
  </ui-form>
</template>

<script>
import EditFooter from '@src/components/partials/EditFooter'
import ToggleSettingsBox from '@src/components/partials/ToggleSettingsBox'

export default {
  components: {
    EditFooter,
    ToggleSettingsBox
  },

  data() {
    return {
      details: {
        editEnabled: false
      },
      settings: null,
      defaultSettings: null,
      submitCount: 0
    }
  },

  created() {
    this.settings = this.getSettings()
    this.defaultSettings = this.getSettings()
  },

  methods: {
    enableDetailsEdit() {
      this.details.editEnabled = true
    },

    clickCancel() {
      this.settings = Object.assign({}, this.defaultSettings)
      this.details.editEnabled = false
    },

    saveChanges() {
      if (!this.details.editEnabled) {
        this.submitCount++
      } else {
        this.defaultSettings = Object.assign({}, this.settings)
        this.details.editEnabled = false
      }
    },

    getSettings() {
      return {
        mrzZone: true,
        database: true,
        sanction: true,
        pepList: true,
        liveNess: true
      }
    },

    mrzZoneChange(value) {
      this.settings.mrzZone = value
    },

    databaseChange(value) {
      this.settings.database = value
    },

    sanctionChange(value) {
      this.settings.sanction = value
    },

    pepListChange(value) {
      this.settings.pepList = value
    },

    liveNessChange(value) {
      this.settings.liveNess = value
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-basis: 48%;
}

.flex-row .ui-toggle {
  display: inline-block;
}

.tools-buttons {
  display: flex;
  flex-wrap: wrap;
  height: 3.4rem;
  justify-content: flex-end;
  margin-top: 1.15rem;
}

@media (max-width: 800px) {
  .flex-row {
    flex-basis: 100%;
  }
}
</style>

<style>
.checking-methods .m-portlet__body {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
