<template>
  <div class="reset-password">
    <img src="~@src/assets/images/ondato-logo.svg" />
    <h1>{{ $t('AppResetPassword') }}</h1>

    <div class="reset-password__input">
      <ui-input
        v-model="password"
        :disabled="isLoading"
        type="password"
        placeholder="AppPassword"
      />
    </div>

    <div class="reset-password__input">
      <ui-input
        v-model="rePassword"
        :disabled="isLoading"
        type="password"
        placeholder="AppRepeatPassword"
      />
    </div>

    <div class="error-box">
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ $t(error) }}
        </li>
      </ul>
    </div>

    <div class="button-group">
      <ui-button
        class="btn btn-brand"
        :disabled="isLoading"
        @click="handleSubmit"
      >
        {{ $t('AppSubmit') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import { signIn } from '@src/scripts/identity'
import { passwordValidator } from '@src/scripts/validators'
import { API } from '@src/scripts/enums'

export default {
  data: () => ({
    password: '',
    rePassword: '',
    isLoading: false,
    errors: []
  }),
  methods: {
    handleSubmit() {
      this.errors = []
      if (this.validatePassword()) {
        this.resetPassword({
          email: this.$route.query.email,
          password: this.password,
          token: this.$route.query.token.split(' ').join('+')
        })
      }
    },
    validatePassword() {
      const password = this.password
      const PASSWORD_LENGTH = 6

      if (password.length < PASSWORD_LENGTH) {
        this.errors.push('AppErrorPasswordToShort')
      }

      if (passwordValidator(password)) {
        this.errors.push(passwordValidator(password))
      }

      if (password !== this.rePassword) {
        this.errors.push('AppErrorPasswordIdentical')
      }

      return !this.errors.length
    },

    resetPassword(payload) {
      fetch(`${BaseUrl.api}${API.RESET_PASSWORD}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
        .then((data) => {
          this.isLoading = false

          if (data.ok) {
            signIn()
          } else {
            this.errors.push('ErrorUnknownDescription')
          }
        })
        .catch(() => {
          this.errors.push('ErrorUnknownDescription')
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>
.reset-password .button-group {
  text-align: right;
  margin: 3rem 0 0 0;
}

.reset-password .error-box {
  margin: 0;
}

.reset-password .error-box ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

.error-box ul li {
  color: var(--danger);
  padding: 1rem 0 0 0;
}

.reset-password {
  max-width: 30rem;
  width: 100%;
  min-height: 33rem;
  border-radius: 1rem;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  overflow: hidden;
  border: 1px solid var(--medium-brand);
  box-shadow: 0 0.25rem 2rem var(--shadow-brand);
  background: var(--white);
}

img {
  width: 15.38rem;
}

.reset-password > * {
  margin-left: auto;
  margin-right: auto;
}

.reset-password .reset-password__input {
  margin-top: 1rem;
  width: 100%;
}

.reset-password h1 {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--dark);
  margin-top: 2rem;
  margin-bottom: 0;
}
</style>
