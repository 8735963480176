<template>
  <div
    class="details-item"
    :class="{ 'with-values': values, 'inner-page-style': isInner }"
  >
    <span class="details-label">
      {{ $t(label) }}
    </span>
    <div
      v-if="values"
      class="details-value"
      :class="{ 'no-style-tags': noStyleTabs }"
    >
      <ul v-if="ulList" class="list-in-details">
        <li v-for="(value, index) in values" :key="index">
          {{ $t(value) }}
        </li>
      </ul>
      <template v-if="!ulList">
        <span v-for="(value, index) in values" :key="index" class="tag">
          {{ $t(value) }}
          <span v-if="noStyleTabs && index != Object.keys(values).length - 1">
            ,
          </span>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined },
    values: { type: Array, default: undefined },
    noStyleTabs: Boolean,
    ulList: Boolean,
    isInner: Boolean
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-value {
  margin-left: auto;
}

.details-label {
  padding: 0.85rem 1.15rem;
}

.list-in-details {
  padding-left: 1.2rem;
}

.details-item.with-values {
  width: 100%;
}

.details-item.with-values .details-label {
  max-width: 115px;
  box-sizing: border-box;
}

.details-item.with-values .details-value {
  width: calc(100% - 115px);
  box-sizing: border-box;
  padding-top: 0.85rem;
}

.details-item.with-values.inner-page-style .details-label {
  max-width: 180px;
}

.details-item.with-values.inner-page-style .details-value {
  width: calc(100% - 180px);
}

.details-value .tag {
  padding: 0.1rem 0.5rem;
  border: 1px solid var(--brand);
  border-radius: 4px;
  color: var(--brand);
  margin: 0.3rem;
  display: inline-block;
}

.details-value.no-style-tags .tag {
  border: none;
  color: #575962;
  padding: 0;
  margin: 0 0.5rem 0 0;
}

@media (max-width: 678px) {
  .details-value.no-style-tags .tag {
    display: block;
    line-break: auto;
  }
}
</style>
