<template>
  <div>
    <ui-container>
      <identification-header>
        <template slot="left-content">
          <div
            class="card-header-tabs-btn"
            :class="[
              activeTab === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
                ? 'active'
                : ''
            ]"
            @click="tabChange(IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB)"
          >
            {{ $t('IdentificationDashboard.ActiveUsers') }}
          </div>
          <div
            class="card-header-tabs-btn"
            :class="[
              activeTab ===
              IDENTIFICATION_DASHBOARD_TABS.PENDING_IDENTIFICATIONS_TAB
                ? 'active'
                : ''
            ]"
            @click="
              tabChange(
                IDENTIFICATION_DASHBOARD_TABS.PENDING_IDENTIFICATIONS_TAB
              )
            "
          >
            {{ $t('IdentificationDashboard.PendingIdentifications') }}
          </div>
        </template>
        <template slot="right-content">
          <div class="d-flex align-items-center">
            <span>{{ activeSpecialistsCount }}</span>
            <p>{{ $t('IdentificationDashboard.TotalActiveSpecialists') }}</p>
          </div>
          <div class="d-flex align-items-center">
            <span>{{ pendingIdentifications }}</span>
            <p>
              {{ $t('IdentificationDashboard.TotalPendingIdentifications') }}
            </p>
          </div>
        </template>
      </identification-header>
      <ui-card class="card-body">
        <ui-loader v-if="isLoading" :size="4" center />
        <template v-else>
          <div class="reload-btn">
            <ui-button class="btn-brand" low small @click="reloadList">
              {{ $t('IdentificationDashboard.ReloadList') }}
            </ui-button>
          </div>
          <div
            v-if="activeTab === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB"
          >
            <div class="row">
              <div class="col-5">
                <div class="d-flex mb-4 align-items-start">
                  <ui-input
                    v-model="userFilterModel"
                    :placeholder="'id'"
                    :error="error"
                    type="text"
                  />
                  <ui-button class="ml-3 btn-brand" @click="handleFilter">
                    {{ $t('IdentificationDashboard.Filter') }}
                  </ui-button>
                </div>
              </div>
            </div>
            <dash-board-table v-if="activeSpecialists.length">
              <template slot="table-header">
                <th scope="col">{{ $t('IdentificationDashboard.Email') }}</th>
                <th scope="col">
                  {{ $t('IdentificationDashboard.LoginTime') }}
                </th>
                <th scope="col" class="text-center">
                  {{ $t('IdentificationDashboard.Summary') }}
                </th>
              </template>

              <template slot="table-body">
                <tr
                  v-for="activeSpecialist in activeSpecialists"
                  :key="activeSpecialist.userId"
                >
                  <td>
                    <span class="green-dot"></span>
                    {{ activeSpecialist.email }}
                  </td>
                  <td>{{ dateToString(activeSpecialist.createdUtc, true) }}</td>
                  <td class="text-center">
                    <ui-button
                      class="btn-brand"
                      low
                      small
                      @click="
                        openSummery(
                          activeSpecialist.userId,
                          activeSpecialist.email
                        )
                      "
                    >
                      {{ $t('IdentificationDashboard.Open') }}
                    </ui-button>
                  </td>
                </tr>
              </template>
            </dash-board-table>

            <div v-else class="p-4">
              <h4>{{ $t('IdentificationDashboard.NoActiveUsersFound') }}</h4>
            </div>
          </div>
          <div
            v-if="
              activeTab ===
              IDENTIFICATION_DASHBOARD_TABS.PENDING_IDENTIFICATIONS_TAB
            "
          >
            <div v-if="identifications.length" class="row">
              <div class="col-12 col-md-8">
                <h4 class="table-title">
                  {{
                    $t(
                      'IdentificationDashboard.CurrentPendingIdentificationsStatus'
                    )
                  }}
                </h4>
                <dash-board-table>
                  <template slot="table-header">
                    <th scope="col">
                      {{ $t('IdentificationDashboard.IdentID') }}
                    </th>
                    <th scope="col">
                      {{ $t('IdentificationDashboard.Company') }}
                    </th>
                    <th scope="col">
                      {{ $t('IdentificationDashboard.Name') }}
                    </th>
                    <th scope="col">
                      {{ $t('IdentificationDashboard.Status') }}
                    </th>
                    <th scope="col" class="text-center">
                      {{ $t('IdentificationDashboard.TimeToDeadline') }}
                    </th>
                  </template>

                  <template slot="table-body">
                    <tr
                      v-for="(identification, index) in identifications"
                      :key="index"
                    >
                      <td>
                        <span
                          class="copy-btn"
                          @click="
                            copyIdentificationId(
                              identification.identificationId
                            )
                          "
                        >
                          {{ $t('IdentificationDashboard.Copy') }}
                        </span>
                      </td>
                      <td>{{ identification.companyName }}</td>
                      <td>{{ identification.assignedUserEmail }}</td>
                      <td>{{ identification.status }}</td>
                      <td class="text-center">
                        <span
                          :class="
                            getDeadLine(
                              `${identification.finishDeadlineUtc}Z`
                            ) <= 0
                              ? 'expired'
                              : ''
                          "
                        >
                          {{
                            Math.round(
                              getDeadLine(
                                `${identification.finishDeadlineUtc}Z`
                              )
                            )
                          }}
                          {{ $t('IdentificationDashboard.Sec') }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </dash-board-table>
              </div>

              <div class="col-12 col-md-4">
                <h4 class="table-title">
                  {{
                    $t(
                      'IdentificationDashboard.CurrentIdentificationsGroupedByCompanies'
                    )
                  }}
                </h4>
                <dash-board-table>
                  <template slot="table-header">
                    <th scope="col">
                      {{ $t('IdentificationDashboard.Company') }}
                    </th>
                    <th scope="col">
                      {{ $t('IdentificationDashboard.IdentificationCount') }}
                    </th>
                  </template>

                  <template slot="table-body">
                    <tr
                      v-for="(company, index) in identificationsByCompany"
                      :key="index"
                    >
                      <td>{{ index }}</td>
                      <td>{{ company.length }}</td>
                    </tr>
                  </template>
                </dash-board-table>
              </div>
            </div>
            <div v-else class="p-4 text-center">
              <h4>
                {{
                  $t('IdentificationDashboard.NoPendingIdentificationsFound')
                }}
              </h4>
            </div>
          </div>
        </template>
      </ui-card>
      <pop-up
        :pop-up-title="`${$t(
          'IdentificationDashboard.IdLogs'
        )} ${userIdentificationsFullName}`"
        no-padding
        full-size
      >
        <user-identifications
          :user-id="userIdentificationsId"
          :user-name="userIdentificationsFullName"
          :type="
            activeTab === IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB ||
            activeTab ===
              IDENTIFICATION_DASHBOARD_TABS.PENDING_IDENTIFICATIONS_TAB
              ? IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
              : IDENTIFICATION_DASHBOARD_TABS.BY_COMPANY_TAB
          "
        />
      </pop-up>
    </ui-container>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import UiCard from '@src/components/ui/UiCard'
import IdentificationHeader from '@src/components/pages/identification-dashboard/IdentificationHeader'
import {
  IDENTIFICATION_DASHBOARD_TABS,
  IDENTIFICATION_STATUS,
  API
} from '@src/scripts/enums'
import DashBoardTable from '@src/components/pages/identification-dashboard/DashBoardTable'
import UserIdentifications from '@src/components/pages/identification-dashboard/UserIdentifications'
import { dateToString } from '@src/scripts/helpers'
import { copy } from '@src/scripts/clipboard'
import { notificationType } from '@src/components/notification'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const apiSettings = {
  limit: 1000,
  offset: 0
}

export default {
  components: {
    DashBoardTable,
    IdentificationHeader,
    UiCard,
    UserIdentifications
  },
  data: () => ({
    IDENTIFICATION_DASHBOARD_TABS,
    userFilterModel: '',
    error: '',
    hasError: false,
    isLoading: false,
    activeSpecialistsCount: 0,
    userIdentificationsId: null,
    userIdentificationsFullName: null,
    activeSpecialists: null,
    pendingIdentifications: 0,
    identifications: [],
    identificationsByCompany: null,
    activeTab: IDENTIFICATION_DASHBOARD_TABS.ACTIVE_USERS_TAB
  }),
  created() {
    this.fetchData()
  },
  methods: {
    dateToString,
    async fetchData() {
      this.isLoading = true
      try {
        this.activeSpecialists = await this.getActiveUsers()
        this.identifications = await this.getPendingIdentifications()
        this.isLoading = false
      } catch {
        this.isLoading = false
      }
    },
    reloadList() {
      this.fetchData()
    },
    async getPendingIdentifications() {
      const identifications = await Api.post(
        API.REALTIME_IDENTIFICATIONS(apiSettings.limit, apiSettings.offset),
        {
          statuses: [
            IDENTIFICATION_STATUS.PENDING,
            IDENTIFICATION_STATUS.IN_PROGRESS
          ]
        }
      )
      this.pendingIdentifications = identifications.length

      this.identificationsByCompany = identifications.reduce(
        (groups, item) => ({
          ...groups,
          [item.companyName]: [...(groups[item.companyName] || []), item]
        }),
        {}
      )

      return identifications
    },
    async getActiveUsers() {
      const now = new Date()
      try {
        const activeUsers = await Api.post(
          API.REALTIME_IDENT_USERS(apiSettings.limit, apiSettings.offset),
          {
            from: dateToString(
              new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
              true
            ),
            to: dateToString(now, true)
          }
        )
        this.activeSpecialistsCount = activeUsers.length
        return activeUsers
      } catch {
        return null
      }
    },
    async getIdentificationsById(id) {
      try {
        return await Api.post(
          API.REALTIME_IDENTIFICATIONS(apiSettings.limit, apiSettings.offset),
          {
            userIds: [id],
            statuses: [
              IDENTIFICATION_STATUS.PENDING,
              IDENTIFICATION_STATUS.IN_PROGRESS
            ]
          }
        )
      } catch {
        return []
      }
    },
    openSummery(id, fullName) {
      this.userIdentificationsId = id
      this.userIdentificationsFullName = fullName
      this.$openPopUp()
    },
    tabChange(tabId) {
      this.activeTab = tabId !== this.activeTab ? tabId : this.activeTab
    },
    getDeadLine(end) {
      const date1 = dayjs().utc()
      const date2 = dayjs(end).utc()

      return date2.diff(date1, 'seconds')
    },

    copyIdentificationId(id) {
      copy(id)

      this.$notify({
        title: 'CopiedToClipboard',
        message: id,
        type: notificationType.info
      })
    },
    isGuide(stringToTest) {
      return new RegExp(
        '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
        'i'
      ).test(stringToTest)
    },
    async handleFilter() {
      this.error = ''
      if (this.isGuide(this.userFilterModel)) {
        this.isLoading = true
        try {
          this.activeSpecialists = await Api.post(
            API.REALTIME_IDENT_USERS(apiSettings.limit, apiSettings.offset),
            {
              userIds: [this.userFilterModel]
            }
          )
          this.isLoading = false
        } catch {
          this.isLoading = false
        }
      } else {
        this.error = 'InvalidIdFormat'
        this.$notify({
          title: 'InvalidIdFormat',
          message: '',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>
.expired {
  color: var(--danger);
}
.copy-btn {
  cursor: pointer;
  text-decoration: underline;
}
.green-dot {
  display: inline-block;
  margin-right: 1rem;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  background: #84bd70;
}

.reload-btn {
  text-align: right;
  margin-bottom: 1rem;
}
.table-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.462rem;
}

.card-body {
  padding: 1.846rem 1.538rem;
}

.card-header-tabs-btn {
  padding: 0.846rem;
  font-size: 1.077rem;
  color: var(--gray);
  background: #ebebeb;
  border: 1px solid #c4c4c4;
  text-align: center;
  cursor: pointer;
  min-width: 194px;
}

.card-header-tabs-btn.active {
  background: var(--white);
  border-color: var(--brand);
  color: var(--brand);
}

.card-header .card-header-content div {
  margin: 0 0.8075rem;
}

.card-header .card-header-content span {
  font-size: 1.846rem;
  color: var(--black);
  font-weight: 600;
  margin-right: 1.385rem;
}

.card-header .card-header-content p {
  color: var(--black);
  font-size: 1.077rem;
  margin: 0;
}

/deep/ .m-portlet .m-portlet__body {
  padding: 0;
}
</style>
