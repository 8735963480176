<template>
  <div>
    <div class="actions">
      <ui-toggle
        :label="$t('Kyb.Representative.ExternallyVerified')"
        :value="data.isExternallyVerified"
        :disabled="disabled"
        prevent
        @change="externallyVerifiedChange"
      />
      <template v-if="data.kycIdentificationId">
        <ui-link
          class="btn-brand"
          :to="{
            name: 'IdentificationDetails',
            params: { identificationId: data.kycIdentificationId }
          }"
          target="_blank"
          small
        >
          {{ $t('App.Open') }}
        </ui-link>
        <ui-button
          class="btn-silver"
          :disabled="disabled"
          small
          @click="exportPdf"
        >
          {{ $t('App.Export') }}
        </ui-button>
      </template>
    </div>
    <details-box>
      <details-item :label="$t('Kyb.Representative.Id')" :value="data.id" />
      <details-item
        :label="$t('Kyb.Representative.Name')"
        :value="data.registration.fullName"
      />
      <details-item
        :label="$t('Kyb.Representative.Code')"
        :value="data.registration.personalCode"
      />
      <details-item
        :label="$t('Kyb.Representative.KycStatus')"
        :value="$t(`Kyb.RepresentativeStatuses.${status}`)"
      />
    </details-box>
    <identification
      v-if="data.kycIdentificationId"
      :id="data.kycIdentificationId"
      class="identification-box"
      disable-actions
      full-size
    />
  </div>
</template>

<script>
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import UiToggle from '@src/components/ui/UiToggle'
import Identification from '@src/components/pages/identifications/kyc/DetailsPage'
import Api from '@src/scripts/api'
import UiButton from '@src/components/ui/UiButton'
import { dateToString, downloadBlob } from '@src/scripts/helpers'
import { representativeStatuses } from '@src/scripts/enums'

export default {
  components: {
    UiButton,
    UiToggle,
    DetailsBox,
    DetailsItem,
    Identification
  },

  props: {
    data: { type: Object, default: null },
    loading: Boolean
  },

  data() {
    return {
      isLoading: false
    }
  },

  computed: {
    disabled() {
      return this.isLoading || this.loading
    },

    language() {
      return localStorage.getItem('language') || 'en'
    },

    status() {
      const { isExternallyVerified, kycIdentificationStatus } = this.data

      if (isExternallyVerified) return representativeStatuses.externallyVerified
      return kycIdentificationStatus
    }
  },

  methods: {
    async externallyVerifiedChange(value) {
      const { id } = this.data
      this.isLoading = true

      try {
        await Api.put(`/kybrepresentatives/${id}/externallyverified`, {
          isExternallyVerified: value
        })
        this.$emit('update')
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      this.isLoading = false
    },

    async exportPdf() {
      const {
        language,
        data: { kycIdentificationId }
      } = this

      this.isLoading = true

      try {
        const pdfName = `OndatoRequests[${dateToString(new Date(), true)}].pdf`
        const blob = await Api.getBaseUrlBlob(
          `/identifications/reports/${kycIdentificationId}/pdf/${language}`
        )
        downloadBlob({ file: blob, fileName: pdfName })
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
.identification-box {
  margin-top: 1.5rem;
}

.actions {
  display: flex;
  margin-bottom: 1.5rem;
}

.actions > .ui-toggle {
  margin-right: auto;
}

.actions > .ui-button + .ui-button {
  margin-left: 1rem;
}
</style>
