var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "loader" },
            [_c("ui-loader", { attrs: { size: 4, center: "" } })],
            1
          )
        : [
            _c("h2", [_vm._v(_vm._s(_vm.$t("CompanyReportForm.Title")))]),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("CompanyReportForm.CompanyFormStepOne"))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("p", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("CompanyReportForm.SelectCountry"))),
                ]),
                _vm._v(" "),
                _c("ui-dropdown", {
                  attrs: {
                    "has-search": "",
                    options: _vm.countries,
                    placeholder: "CompanyReportForm.CountrySelectPlaceholder",
                  },
                  on: { input: _vm.handleCountrySelect },
                  model: {
                    value: _vm.country,
                    callback: function ($$v) {
                      _vm.country = $$v
                    },
                    expression: "country",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("p", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("CompanyReportForm.ReportType"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.$t("CompanyReportForm.FullReport"))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block radio-block" },
              [
                _c("p", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("CompanyReportForm.DeliveryTimePeriod"))
                  ),
                ]),
                _vm._v(" "),
                _c("ui-radio", {
                  attrs: {
                    label: _vm.$t("CompanyReportForm.Fastest"),
                    value: "Fastest",
                    name: "deliveryTime",
                  },
                  on: { checkRadio: _vm.handleToggleChange },
                }),
                _vm._v(" "),
                _c("ui-radio", {
                  attrs: {
                    label: _vm.$t("CompanyReportForm.Fast"),
                    value: "Fast",
                    name: "deliveryTime",
                    checked: "",
                  },
                  on: { checkRadio: _vm.handleToggleChange },
                }),
                _vm._v(" "),
                _c("ui-radio", {
                  attrs: {
                    label: _vm.$t("CompanyReportForm.Normal"),
                    value: "Normal",
                    name: "deliveryTime",
                  },
                  on: { checkRadio: _vm.handleToggleChange },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "block" }, [
              _c("p", [
                _vm._v(_vm._s(_vm.$t("CompanyReportForm.CompanyFormStepTwo"))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block input-block" },
              [
                _c("ui-input", {
                  attrs: {
                    multiline: "",
                    label: _vm.$t("CompanyReportForm.EnterCompanyName"),
                    placeholder: _vm.$t(
                      "CompanyReportForm.CompanyNamePlaceholder"
                    ),
                    type: "text",
                  },
                  model: {
                    value: _vm.model.companyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "companyName", $$v)
                    },
                    expression: "model.companyName",
                  },
                }),
                _vm._v(" "),
                _c("ui-input", {
                  attrs: {
                    multiline: "",
                    label: _vm.$t("CompanyReportForm.EnterCompanyCode"),
                    placeholder: _vm.$t(
                      "CompanyReportForm.CompanyCodePlaceholder"
                    ),
                    type: "text",
                  },
                  model: {
                    value: _vm.model.companyCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "companyCode", $$v)
                    },
                    expression: "model.companyCode",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block button-block" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "btn btn-brand",
                    attrs: { disabled: !_vm.isValid },
                    on: { click: _vm.handleSubmit },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("CompanyReportForm.SubmitBtn")) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }