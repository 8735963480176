<template>
  <tr v-if="values" class="chart-data-item-mobile">
    <td>
      <div class="position-relative">
        <b>{{ $t(title) }}</b>
        <i v-if="description" class="indicator-info la la-info-circle">
          <div class="indicator-info-details">
            <span>{{ $t(title) }}</span>
            <p>
              {{ $t(description) }}
            </p>
          </div>
        </i>
      </div>
      <div v-for="(label, index) in labels" :key="index">
        <span>{{ label }}: </span>
        <span>{{ values[index] }}</span>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    selector: { type: String, default: undefined },
    data: { type: Array, default: undefined },
    labels: { type: Array, default: undefined }
  },

  computed: {
    values() {
      const { data, selector } = this
      if (!data || !data.length || !selector) return

      const valueList = data.map((item) => {
        return item[selector] || null
      })

      const validValue = valueList.find((item) => item !== null)
      return validValue ? valueList : null
    }
  }
}
</script>

<style scoped>
.position-relative {
  position: relative;
}

.indicator-info {
  font-size: 1.1rem;
  vertical-align: baseline;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
}

.indicator-info-details {
  position: absolute;
  left: 0;
  right: 0.5rem;
  bottom: 100%;
  width: 100%;
  display: none;
  -webkit-box-shadow: 0 3px 10px var(--shadow);
  box-shadow: 0 3px 10px var(--shadow);
  background-color: var(--white);
  color: var(--dark-font);
  padding: 1rem;
  border-left: 0.4rem solid var(--brand);
  font-size: 0.9rem;
  z-index: 10000;
}

.indicator-info-details > p {
  margin-top: 1rem;
  margin-bottom: 0;
}

.indicator-info:hover {
  color: var(--brand);
  box-shadow: 0 2px 6px var(--brand);
}

.indicator-info:hover .indicator-info-details {
  display: block;
}

.show-mobile {
  display: none;
}
</style>

<style>
@media (max-width: 568px) {
  .chart-data-item-mobile .m-section {
    margin: 0;
  }
}
</style>
