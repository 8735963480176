var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoring-list-page" },
    [
      _c(
        "ui-header",
        {
          class: { "fixed-header": _vm.scrollDown },
          attrs: {
            title: "App.SubMenu.MonitoringList",
            method: _vm.searchMethod,
            filters: _vm.filters,
            "update-revision": _vm.updateRevision,
            "reset-form": "",
            "method-get": "",
            "no-extra-payload": "",
            disabled: _vm.isLoading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("div", { staticClass: "filters-block" }, [
            _c(
              "div",
              { staticClass: "display-flex flex-flow-wrap" },
              [
                _c("ui-header-dropdown", {
                  attrs: {
                    options: _vm.options.dateOptions,
                    placeholder: "HeaderDatePlaceHolder",
                    wide: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "date", $$v)
                    },
                    expression: "filters.date",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "TableFullName",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  on: { input: _vm.handleNameFilter },
                  model: {
                    value: _vm.fullName,
                    callback: function ($$v) {
                      _vm.fullName = $$v
                    },
                    expression: "fullName",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "DetailsPersonCode",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.personalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "personalCode", $$v)
                    },
                    expression: "filters.personalCode",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  staticClass: "countries-dropdown",
                  attrs: {
                    all: "",
                    "has-search": "",
                    "key-value-dropdown": "",
                    options: _vm.countriesOptions,
                    medium: "",
                    placeholder: "DetailsCountry",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "country", $$v)
                    },
                    expression: "filters.country",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  attrs: {
                    all: "",
                    options: _vm.documentTypes,
                    medium: "",
                    placeholder: "DetailsDocumentType",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.documentType,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "documentType", $$v)
                    },
                    expression: "filters.documentType",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "DetailsDocumentNumber",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.documentNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "documentNumber", $$v)
                    },
                    expression: "filters.documentNumber",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "DetailsIdentificationId",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.identificationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "identificationId", $$v)
                    },
                    expression: "filters.identificationId",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { title: "AppSearchResults", count: _vm.itemsCount } },
            [
              !_vm.isLoading && _vm.identifications
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "export-table hide-mobile",
                        attrs: {
                          labels: _vm.listLabels,
                          data: _vm.identifications,
                          "empty-text": "TableNoIdentifications",
                        },
                      },
                      [
                        _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "m-table__row" },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "cell-identification-id-desktop",
                                  attrs: { title: row.referenceId },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "identification-id-desktop",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyIdentificationId(
                                            row.referenceId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            row.referenceId
                                              ? row.referenceId
                                              : "-"
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm._f("date")(row.addedAt, true))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { attrs: { title: _vm.getFullName(row) } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.getFullName(row)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.personalCode },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.personalCode) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.country },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.country) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.documentType },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t(row.documentType)) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.documentNumber },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.documentNumber) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("ui-toggle", {
                                    attrs: { prevent: "", value: true },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeMonitoring(row, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  row.referenceId
                                    ? _c(
                                        "ui-link",
                                        {
                                          staticClass: "btn-brand",
                                          attrs: {
                                            to: {
                                              name: "IdentificationDetails",
                                              params: {
                                                identificationId: row.referenceId.replaceAll(
                                                  "-",
                                                  ""
                                                ),
                                              },
                                            },
                                            "small-text": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.identifications && _vm.identifications.length > 0
                      ? _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableIdentificationId",
                                  value: row.identificationId,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableDate",
                                  value: _vm._f("date")(row.addedAt, true),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableFullName",
                                  value: _vm.getFullName(row),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsPersonCode",
                                  value: row.personalCode,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "responsive-table-tr",
                                { attrs: { title: "DetailsCountry" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row.country) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsDocumentType",
                                  value: row.documentType,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsDocumentNumber",
                                  value: row.documentNumber,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "responsive-table-tr",
                                { attrs: { title: "Monitoring" } },
                                [
                                  _c("ui-toggle", {
                                    attrs: { prevent: "", value: true },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeMonitoring(row, index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10 margin-1" },
                                [
                                  row.referenceId
                                    ? _c(
                                        "ui-link",
                                        {
                                          staticClass: "btn-brand big-link",
                                          attrs: {
                                            to: {
                                              name: "IdentificationDetails",
                                              params: {
                                                identificationId: row.referenceId.replaceAll(
                                                  "-",
                                                  ""
                                                ),
                                              },
                                            },
                                            "small-text": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("TableNoIdentifications")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm._e(),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.filters.page,
                  "items-count": _vm.itemsCount,
                  "max-on-page": _vm.filters.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            { attrs: { "full-size": "" } },
            [
              _c("monitoring-setup", {
                attrs: { data: _vm.monitoringData },
                on: { change: _vm.changeMonitoringStatus },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }