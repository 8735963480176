<template>
  <footer class="m-grid__item m-footer">
    <div
      class="m-container m-container--fluid m-container--full-height m-page__container"
    >
      <div
        class="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop"
      >
        <div
          class="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last"
        >
          <span class="m-footer__copyright"> {{ year }} &copy; Ondato </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>
