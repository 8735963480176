var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-frame",
    [
      _c(
        "list-header",
        {
          attrs: { slot: "header", title: _vm.$t("Kyb.Search.Title") },
          slot: "header",
        },
        [_c("search")],
        1
      ),
      _vm._v(" "),
      _c("identifications"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }