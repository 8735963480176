<template>
  <div class="mrz-container">
    <div class="mrz-title">
      <h5>
        {{ $t(`Mrz.Title`) }}
        <i v-if="isMrzVerified" class="fas fa-check-circle m--font-success"></i>
        <i v-else class="fas fa-circle-xmark m--font-danger"></i>
      </h5>
    </div>

    <mrz-lines-container
      v-if="hasMrzRule"
      :field-values="values"
      :edit-enabled="editEnabled"
    />
    <div v-else>{{ $t(`Mrz.DoesNotExist`) }}</div>
  </div>
</template>

<script>
export const MRZ_FIELDS = {
  MRZ_1: 'mrzLine1',
  MRZ_2: 'mrzLine2',
  MRZ_3: 'mrzLine3'
}

import MrzLinesContainer from './MrzLinesContainer'

export default {
  components: {
    MrzLinesContainer
  },
  props: {
    values: { type: Object, default: null },
    editEnabled: { type: Boolean, default: false },
    identification: { type: Object, default: undefined }
  },

  computed: {
    hasMrzRule() {
      const mrzRule =
        (this.identification?.rules ?? []).find(
          (rule) => rule.name === 'MRZ_VERIFIED'
        ) ?? null

      return mrzRule !== null
    },

    isMrzVerified() {
      const mrzRule =
        (this.identification?.rules ?? []).find(
          (rule) => rule.name === 'MRZ_VERIFIED'
        ) ?? null
      if (mrzRule === null) {
        return true
      }

      return mrzRule.status === 'SUCCESS'
    }
  }
}
</script>

<style>
.mrz-container {
  border-top: 1px solid #f4f5f8;
  padding-top: 12px;
  padding-left: 2.2rem;
  padding-right: 1.05rem;
  margin-bottom: 8px;
}
</style>
