<template>
  <div
    class="ui-loader"
    :class="{ 'loader-center': center, 'loader-small': small }"
    :style="loaderSize"
  />
</template>

<script>
export default {
  name: 'ui-loader',

  props: {
    size: { type: Number, default: undefined },
    center: Boolean
  },

  computed: {
    loaderSize() {
      const { size } = this
      const sizeValue = `${size || 3.5}rem`
      return {
        width: sizeValue,
        height: sizeValue
      }
    },

    small() {
      const { size } = this
      return size < 3
    }
  }
}
</script>

<style scoped>
.ui-loader {
  position: relative;
}

.ui-loader.loader-center {
  margin: auto;
}

.ui-loader::before {
  content: '';
  height: 100%;
  width: 100%;
  border: 6px solid var(--brand);
  border-bottom-color: var(--secondary);
  position: absolute;
  border-radius: 30%;
  animation: loader-spin 1.5s infinite ease-in-out;
}

.loader-small::before {
  border-width: 3px;
}

@-moz-keyframes loader-spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
