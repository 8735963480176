var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "time-box" }, [
    _vm.label
      ? _c("span", { staticClass: "selector-label" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "selector-group" },
      [
        _c("time-selector", {
          attrs: {
            value: _vm.selectedValue.from,
            nullable: _vm.nullable,
            disabled: _vm.disabled,
          },
          on: { change: _vm.fromChange },
        }),
        _vm._v(" "),
        _c("time-selector", {
          attrs: {
            value: _vm.selectedValue.to,
            nullable: _vm.nullable,
            disabled: _vm.disabled,
          },
          on: { change: _vm.toChange },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }