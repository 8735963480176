import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { getNotification } from '../components/notification'
import actions from '@src/store/actions'

import ProtectedApp from '@src/components/ProtectedApp'
import EmptyRouter from '@src/components/EmptyRouter'
import LoginPage from '@src/components/pages/LoginPage'
import NewUserPage from '@src/components/pages/NewUserPage'
import LandingPage from '@src/components/pages/LandingPage'
import DashboardPage from '@src/components/pages/DashboardPage'
import SanctionsPage from '@src/components/pages/sanctions/SanctionsPage'
import SanctionDetailsPage from '@src/components/pages/sanctions/SanctionDetailsPage'
import IdentificationsPage from '@src/components/pages/identifications/kyc/IdentificationsPage'
import IdentificationDetailsPage from '@src/components/pages/identifications/kyc/DetailsPage'
import SharedDatabasePage from '@src/components/pages/SharedDatabasePage'
import UserSettingsPage from '@src/components/pages/settings/UserSettingsPage'
import BillingSettingsPage from '@src/components/pages/settings/BillingSettingsPage'
import CheckSettingsPage from '@src/components/pages/settings/CheckSettingsPage'
import KycSettingsPage from '@src/components/pages/settings/kycSettingsPage'
import CreateProcedures from '@src/components/pages/companies/CreateProcedures'
import UpdateProcedures from '@src/components/pages/companies/UpdateProcedures'
import ResetPassword from '@src/components/pages/ResetPassword'

import VideoCall from '@src/components/pages/call/VideoCall'
import CallSettings from '@src/components/pages/call/CallSettings'
import AdminUsersPage from '@src/components/pages/admin/UsersPage'
import AgentLinkListPage from '@src/components/pages/agent-link/AgentLinkListPage'
import AdminUserDetailsPage from '@src/components/pages/admin/UserDetailsPage'
import IdentificationDashboard from '@src/components/pages/identification-dashboard/IdentificationDashboard'
import FinishedIdentifications from '@src/components/pages/identification-dashboard/FinishedIdentifications'
import CurrentIdentifications from '@src/components/pages/identification-dashboard/CurrentIdentifications'

// import AdminCompaniesPage from '@src/components/pages/admin/CompaniesPage'
// import AdminCompanyDetailsPage from '@src/components/pages/admin/CompanyDetailsPage'
import KybPage from '@src/components/pages/kyb/KybPage'
import KybPageDetails from '@src/components/pages/kyb/DetailsPage'
import CompanyList from '@src/components/pages/kyb/CompanyList'
import LegalEntityMonitoringEvents from '@src/components/pages/kyb/monitoring/LegalEntityMonitoringEvents/LegalEntityMonitoringEvents'
import LegalEntityMonitoringSetup from '@src/components/pages/kyb/monitoring/LegalEntityMonitoringSetup/LegalEntityMonitoringSetup'
import CompanyReportsPage from '@src/components/pages/kyb/CompanyReportsPage'
import Psd2Page from '@src/components/pages/psd2/Psd2Page'
import MyAppsPage from '@src/components/pages/psd2/MyAppsPage'
import PsdDashboardPage from '@src/components/pages/psd2/PsdDashboardPage'
import PsdDocumentsPage from '@src/components/pages/psd2/PsdDocumentsPage'
// import AdminUserActivity from '@src/components/pages/admin/UserActivityPage'
import WebhooksPage from '@src/components/pages/identifications/WebhooksPage'
import ManualCheckPage from '@src/components/pages/ManualCheckPage'
import MonitoringEventsPage from '@src/components/pages/identifications/MonitoringEventsPage'
import MonitoringListPage from '@src/components/pages/identifications/MonitoringListPage'
import StoplistPage from '@src/components/pages/StoplistPage'
import SecureMeetingsPage from '@src/components/pages/meetings/SecureMeetingsPage'
import KybIdentifications from '@src/components/pages/identifications/kyb/IdentificationsList'
import KybIdentificationsDetails from '@src/components/pages/identifications/kyb/IdentificationDetails'
import CompanyProcedures from '@src/components/pages/identifications/companyProcedures/CompanyProcedures'
import Status from '@src/components/pages/Status'
import Store from '@src/store'
import { PRODUCTS, pageTypeEnum } from '@src/scripts/enums'

Vue.use(Router)

export const routes = [
  { path: '/', name: 'Landing', component: LandingPage },
  {
    path: '/status',
    name: Status,
    component: Status
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/app',
    name: 'App',
    component: ProtectedApp,
    children: [
      {
        path: 'admin',
        name: 'Admin',
        redirect: { name: 'AdminUsers' },
        component: EmptyRouter,
        children: [
          {
            path: 'users/:searchHash?',
            name: 'AdminUsers',
            component: AdminUsersPage
          },
          {
            path: 'users/details/:userId',
            name: 'AdminUserDetails',
            component: AdminUserDetailsPage
          }
          // { path: 'companies/:searchHash?', name: 'AdminCompanies', component: AdminCompaniesPage },
          // { path: 'user-activity/:searchHash?', name: 'AdminUserActivity', component: AdminUserActivity },
          // { path: 'companies/details/:companyId', name: 'AdminCompanyDetails', component: AdminCompanyDetailsPage }
        ]
      },
      { path: 'agentLink', name: 'AgentUrls', component: AgentLinkListPage },
      { path: 'manualCheck', name: 'ManualCheck', component: ManualCheckPage },
      {
        path: 'stoplist',
        name: 'StopList',
        component: StoplistPage
      },
      {
        path: 'monitoring-events',
        name: 'naturalEntityMonitoringEvents',
        component: MonitoringEventsPage
      },
      {
        path: 'monitoring-setup',
        name: 'naturalEntityMonitoringList',
        component: MonitoringListPage
      },
      {
        path: 'dashboard/:searchHash?',
        name: 'Dashboard',
        component: DashboardPage
      },
      {
        path: 'psd2',
        name: 'Psd2',
        component: Psd2Page,
        children: [
          { path: 'my-apps', name: 'PsdMyApps', component: MyAppsPage },
          {
            path: 'dashboard',
            name: 'PsdDashboard',
            component: PsdDashboardPage
          },
          {
            path: 'documents',
            name: 'PsdDocuments',
            component: PsdDocumentsPage
          }
        ]
      },
      {
        path: 'identifications',
        name: 'identificationsSearch',
        redirect: { name: 'Identifications' },
        component: EmptyRouter,
        children: [
          {
            path: 'kyc',
            name: 'Identifications',
            component: IdentificationsPage
          },
          {
            path: 'kyc/details/:identificationId/:export?',
            name: 'IdentificationDetails',
            component: IdentificationDetailsPage,
            meta: { hideFooter: true }
          },
          {
            path: 'kyb',
            name: 'KybIdentifications',
            component: KybIdentifications
          },
          {
            path: 'kyb/details',
            name: 'KybIdentificationsDetails',
            component: KybIdentificationsDetails
          },
          {
            path: 'details/:identificationId/:export?',
            redirect: { name: 'IdentificationDetails' }
          }
        ]
      },
      {
        path: 'secure-meetings',
        name: 'SecureMeetings',
        component: SecureMeetingsPage
      },
      {
        path: 'webhookHistory',
        name: 'Webhook',
        redirect: { name: 'WebhookHistory' },
        component: EmptyRouter,
        children: [
          {
            path: ':searchHash?',
            name: 'WebhookHistory',
            component: WebhooksPage
          }
        ]
      },
      {
        path: 'company-procedures',
        name: 'CompanyProcedures',
        component: CompanyProcedures
      },
      {
        path: 'create-procedures',
        name: 'CreateProcedures',
        component: CreateProcedures
      },
      {
        path: 'update-procedures',
        name: 'UpdateProcedures',
        component: UpdateProcedures
      },
      {
        path: 'shared-database/:searchHash?',
        name: 'SharedDatabase',
        component: SharedDatabasePage
      },
      {
        path: 'sanctions',
        name: 'Sanctions',
        redirect: { name: 'SanctionsSearch' },
        component: EmptyRouter,
        children: [
          {
            path: ':searchHash?',
            name: 'SanctionsSearch',
            component: SanctionsPage
          },
          {
            path: 'details/:sanctionId/:export?/:fuzzinessPercent?',
            name: 'SanctionDetails',
            component: SanctionDetailsPage
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        redirect: { name: 'UserSettings' },
        component: EmptyRouter,
        children: [
          { path: 'user', name: 'UserSettings', component: UserSettingsPage },
          {
            path: 'billing',
            name: 'BillingSettings',
            component: BillingSettingsPage
          },
          {
            path: 'check',
            name: 'CheckSettings',
            component: CheckSettingsPage
          },
          { path: 'kyc', name: 'KycSettings', component: KycSettingsPage }
        ]
      },
      {
        path: 'call',
        name: 'Call',
        redirect: { name: 'VideoCall' },
        component: EmptyRouter,
        children: [
          { path: 'video', name: 'VideoCall', component: VideoCall },
          { path: 'settings', name: 'CallSettings', component: CallSettings }
        ]
      },
      {
        path: 'kyb',
        name: 'Kyb',
        component: KybPage,
        redirect: { name: 'KybCompanyList' },
        children: [
          {
            path: 'company-search',
            name: 'KybCompanyList',
            component: CompanyList
          },
          {
            path: ':companyCode/details/:countryCode',
            name: 'KybPageDetails',
            component: KybPageDetails
          },
          {
            path: 'monitoring-events',
            name: 'LegalEntityMonitoringEvents',
            component: LegalEntityMonitoringEvents
          },
          {
            path: 'monitoring-setup',
            name: 'LegalEntityMonitoringSetup',
            component: LegalEntityMonitoringSetup
          },
          {
            path: 'company-reports',
            name: 'CompanyReportsPage',
            component: CompanyReportsPage
          }
        ]
      },
      {
        path: 'identification-dashboard',
        name: 'IdentificationDashboard',
        component: IdentificationDashboard,
        redirect: { name: 'CurrentIdentifications' },
        children: [
          {
            path: 'finished-identifications',
            name: 'FinishedIdentifications',
            component: FinishedIdentifications
          },
          {
            path: 'current-identifications',
            name: 'CurrentIdentifications',
            component: CurrentIdentifications
          }
        ]
      }
    ]
  },
  { path: '/new-user', name: 'NewUser', component: NewUserPage },
  { path: '/login', name: 'Login', component: LoginPage },
  { path: '**', redirect: { name: 'Landing' } }
]

export const router = new Router({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  const title = to.meta.title || DefaultTitle
  if (document.title !== title) document.title = title
  if (!store.getters.inProgress) next()
  else {
    next(false)
    getNotification({
      type: 'warning',
      title: 'warningSaveChanges',
      message: 'warningSaveChangesMessage',
      callback: () => {
        next()
        store.dispatch(actions.updateProgress, false)
      }
    })
  }

  if (Store.state.settings.companies) {
    const { selectedCompany } = Store.getters
    const currentCompany = Store.state.settings.companies.find(
      (company) => selectedCompany === company.id
    )

    if (
      to.name.toLowerCase() ===
        pageTypeEnum.naturalEntityMonitoringList.toLowerCase() ||
      to.name.toLowerCase() ===
        pageTypeEnum.naturalEntityMonitoringEvents.toLowerCase()
    ) {
      if (
        currentCompany.security.products.includes(
          PRODUCTS.NATURAL_ENTITY_MONITORING
        )
      ) {
        next()
      } else {
        next({
          name: 'identificationsSearch'
        })
      }
    }
  }
})
