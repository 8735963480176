<template>
  <div class="hidden-details">
    <span class="show-button" @mouseover="onHover">
      {{ $t('HiddenDetails.ShowButton') }}
      <i class="far fa-eye" />
    </span>
    <div class="content-box" :style="position">
      <div class="message-box">
        {{ value }}
        <div class="message-arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, default: undefined }
  },

  data() {
    return {
      position: null
    }
  },

  methods: {
    onHover() {
      const { x, y } = this.$el.getBoundingClientRect()
      const { clientWidth } = this.$el

      this.position = {
        top: `${y}px`,
        left: `${x + clientWidth + 30}px`
      }
    }
  }
}
</script>

<style scoped>
.hidden-details {
  display: block;
  position: relative;
}

span {
  font-family: sans-serif;
  font-size: 110%;
  font-weight: 400;
  line-height: 1.25;
  padding: 0 1.15rem;
  text-align: right;
}

.content-box {
  display: none;
  position: fixed;
  padding-bottom: 0.5rem;
  transform: translate(-100%, -100%);
  z-index: 100;
}

.message-box {
  background-color: var(--white);
  padding: 0.5rem 1rem;
  border: 1px solid var(--brand);
  border-radius: 0.5rem;
  position: relative;
}

.message-arrow {
  position: absolute;
  top: 100%;
  left: 84%;
  z-index: 5;
  padding: 1.25%;
  background-color: var(--white);
  border-right: 1px solid var(--brand);
  border-bottom: 1px solid var(--brand);
  border-top-left-radius: 50%;
  border-bottom-right-radius: 0.25rem;
  transform: rotate(45deg) translate(-32%, -32%);
}

.show-button {
  color: var(--info);
}

.show-button:hover {
  color: var(--brand);
  cursor: pointer;
}

.hidden-details:hover .content-box {
  display: block;
}

@media (max-width: 568px) {
  .hidden-details {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0.25rem 0.5rem;
  }
}
</style>
