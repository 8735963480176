var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.value,
        expression: "value",
      },
    ],
    attrs: {
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      cols: _vm.cols,
      rows: _vm.rows,
    },
    domProps: { value: _vm.value },
    on: {
      input: [
        function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.value = $event.target.value
        },
        function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }