<template>
  <div class="m-topbar m-stack m-stack--ver m-stack--general">
    <div class="m-stack__item m-topbar__nav-wrapper">
      <ul class="m-topbar__nav m-nav m-nav--inline nav-reverse">
        <item-selector
          :placeholder="$t('AppSelectCompany')"
          :value="companyDetails.id"
          :options="companies"
          :index="1"
          @change="companyChanged"
        />
        <drop-down icon="flaticon-user-ok" dark>
          <div slot="header" class="m-card-user m-card-user--skin-light">
            <div class="m-card-user__pic">
              <div
                v-if="companyDetails.logoUrl"
                class="companies-logo"
                :style="companiesLogo"
              />
            </div>
            <div class="m-card-user__details">
              <span class="m-card-user__name">
                {{ companyDetails.name }}
              </span>
              <span v-if="user.firstName || user.lastName" class="user-name">
                {{ user.firstName }} {{ user.lastName }}
              </span>
              <span class="user-name">
                <a
                  v-if="user.email"
                  class="m-card-user__email m-link user-name"
                  :href="`mailto:${user.email}?Subject=`"
                >
                  {{ user.email }}
                </a>
              </span>
              <span class="user-name">
                <a
                  v-if="user.phone"
                  class="m-card-user__email m-link user-name"
                  :href="`tel:${user.phone}`"
                >
                  {{ user.phone }}
                </a>
              </span>
              <span v-if="user.position" class="user-name">
                {{ user.position }}
              </span>
              <ui-dropdown
                class="m--margin-top-10 language-options"
                brand
                low
                small-text
                small
                :placeholder="$t('changeLang')"
                :options="languageOptions"
                :value="currentLang"
                @input="languageValueChanged"
              />
            </div>
          </div>
          <div class="m-dropdown__content">
            <ul class="m-nav m-nav--skin-light">
              <li class="m-nav__separator m-nav__separator--fit" />
              <li class="m-nav__item">
                <a
                  class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                  @click="signOutHandler"
                >
                  {{ $t('AppLogOut') }}
                </a>
              </li>
            </ul>
          </div>
        </drop-down>
        <notifications-bar icon="fa fa-info" />
      </ul>
    </div>
  </div>
</template>

<script>
import DropDown from '@src/components/partials/header/DropDown'
import ItemSelector from '@src/components/partials/header/ItemSelector'
import actions from '@src/store/actions'
import { signOut } from '@src/scripts/identity'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import NotificationsBar from '@src/components/partials/header/NotificationsBar'

export default {
  components: {
    DropDown,
    ItemSelector,
    NotificationsBar
  },

  data() {
    return {
      currentLang: localStorage.getItem('language') || 'en'
    }
  },

  computed: {
    ...mapGetters([getters.user, getters.selectedCompany]),

    languageOptions() {
      const options = [
        {
          key: 'lt',
          value: 'LanguageLt'
        },
        {
          key: 'en',
          value: 'LanguageEn'
        },
        {
          key: 'ua',
          value: 'LanguageUa'
        }
      ]
      return options
    },

    companies() {
      const { companies } = this.$store.getters
      if (!companies) return null
      return [...companies]
    },

    gettersCompanyId() {
      return this.$store.getters.selectedCompany
    },

    selectedCompanyId() {
      const { user, gettersCompanyId } = this
      if (gettersCompanyId) return gettersCompanyId
      else if (user.selectedCompanyId) return user.selectedCompanyId
      else return user.defaultCompanyId
    },

    companyDetails() {
      const { companies } = this
      if (!companies) return {}
      const company = companies.find((item) => {
        return item.id === this.selectedCompanyId
      })
      return company || {}
    },

    companiesLogo() {
      if (!this.companyDetails.logoUrl) return
      return {
        backgroundImage: `url(${this.companyDetails.logoUrl})`
      }
    }
  },

  watch: {
    gettersCompanyId(value) {
      return (this.user.selectedCompanyId = value)
    },

    companyDetails: {
      handler(value) {
        if (value.id) {
          this.$store.dispatch(actions.updateCompany, value.id)
        }
      },
      deep: true
    }
  },

  methods: {
    languageValueChanged(value) {
      this.$changeLang(value)
      sessionStorage.setItem('currentLang', value)
      this.currentLang = value
      this.$emit('clickButton')
    },

    companyChanged(value) {
      const { user } = this.$store.getters
      const { settings } = this.$store.state

      const updatedsettings = {
        ...settings,
        user: {
          ...user,
          selectedCompanyId: value
        }
      }

      this.$store.dispatch(actions.updateSettings, updatedsettings)
      this.$store.dispatch(actions.updateCompany, value)
      this.$emit('clickButton')
    },

    signOutHandler() {
      signOut()
    }
  }
}
</script>

<style scoped>
ul.nav-reverse {
  display: flex;
  position: relative;
}

ul.nav-reverse > li {
  margin-left: 3px;
  position: relative;
}

.user-name {
  display: block;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  color: var(--dark-font);
  margin-top: 0.25rem;
}

.companies-logo {
  width: 50px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.m-card-user .m-card-user__pic {
  min-width: 50px;
}

@media (max-width: 678px) {
  .m-card-user__pic {
    display: none;
  }
}
</style>

<style>
.m-topbar .m-topbar__nav .language-options.ui-dropdown .dropdown-menu {
  min-width: 7.65rem;
}

.m-topbar .m-topbar__nav .language-options.ui-dropdown ul li a.dropdown-item {
  padding: 0.2rem 1rem;
  font-size: 0.875rem;
}
</style>
