<template>
  <ui-form class="new-user" @submit="createUser">
    <h2>{{ $t('AdminNewUser') }}</h2>
    <ui-input
      type="email"
      class="email-input"
      placeholder="AdminEmail"
      :value="form.email"
      :error="errors.email"
      :disabled="isLoading"
      :max-length="255"
      @change="emailChanged"
    />
    <transition name="show">
      <div v-if="!inviteActive" class="show-content">
        <ui-input
          type="password"
          placeholder="AppPassword"
          :value="form.password"
          :error="errors.password"
          :disabled="isLoading"
          :min-length="8"
          :max-length="20"
          @input="passwordChanged"
        />
      </div>
    </transition>
    <ui-toggle
      label="AdminSendInvitation"
      :value="inviteActive"
      @change="modeChanged"
    />
    <ui-button
      class="btn-brand"
      :disabled="isLoading"
      :loading="isLoading"
      small
    >
      {{ $t('AdminCreateUser') }}
    </ui-button>
  </ui-form>
</template>

<script>
import Api from '@src/scripts/api'
import {
  requiredValidator,
  minLengthValidator,
  emailValidator,
  passwordValidator,
  validateForm
} from '@src/scripts/validators'

export default {
  data() {
    return {
      isLoading: false,
      inviteActive: true,
      form: {},
      errors: {
        email: 'ErrorFieldRequired'
      }
    }
  },

  methods: {
    modeChanged(value) {
      this.inviteActive = value
      this.passwordChanged(null, !value)
    },

    emailChanged(value) {
      const { form, errors } = this
      errors.email = requiredValidator(value)
      if (!errors.email) errors.email = emailValidator(value)
      this.$set(form, 'email', value)
    },

    passwordChanged(value, validate = true) {
      const { form, errors } = this
      if (validate) {
        errors.password = requiredValidator(value)
        if (!errors.password) errors.password = minLengthValidator(value, 8)
        if (!errors.password) errors.password = passwordValidator(value)
      } else {
        errors.password = null
      }
      this.$set(form, 'password', value)
    },

    async createUser() {
      const { errors } = this
      if (!validateForm(errors)) return
      this.isLoading = true
      try {
        const {
          form: { email, password },
          inviteActive
        } = this
        const { error, userId } = await Api.post('/users/create', {
          email: email.trim(),
          password: password || undefined,
          isSentInvitation: inviteActive
        })
        if (error) {
          this.showError()
        } else {
          this.$closePopUp()
          this.$router.push({ name: 'AdminUserDetails', params: { userId } })
        }
      } catch (error) {
        this.showError()
      } finally {
        this.isLoading = false
      }
    },

    showError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    }
  }
}
</script>

<style scoped>
.new-user {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 18rem;
}

.new-user > h2 {
  color: var(--dark-font);
}

.ui-input .email-input {
  margin-bottom: 0.5rem;
}

.ui-toggle {
  margin-top: 0.5rem;
}

.show-content {
  margin-bottom: 0;
  max-height: 7rem;
}

.ui-button {
  margin-top: 1rem;
}

.show-enter-active,
.show-leave-active {
  transition-duration: 0.25s;
  transition-property: max-height, transform, opacity;
  transition-timing-function: ease;
}

.show-enter {
  max-height: 0;
  transform: translateY(-3rem);
  opacity: 0;
}

.show-leave-active {
  max-height: 0;
  transform: translateY(-3rem);
  opacity: 0;
}
</style>

<style>
.m-portlet .m-portlet__head .m-portlet__head-tools .new-user > .btn {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
