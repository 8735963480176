<template>
  <div v-if="confirmationDetails" class="user-confirmation">
    <h2 v-if="isSuccess">
      {{ $t('AdminNewUserSuccessHeader') }}
    </h2>
    <h2 v-else>
      {{ $t('AdminNewUserHeader') }}
    </h2>
    <div v-if="isCompleted" class="confirmation-box">
      <p :class="{ 'error-message': !isSuccess }">
        {{ $t(completeMessage, { email: confirmationDetails.email }) }}
      </p>
      <ui-button
        class="btn-brand col-lg-6 mx-auto"
        :disabled="isLoading"
        @click="redirectToLogin"
      >
        {{ $t('AppLogin') }}
      </ui-button>
    </div>
    <ui-form
      v-else-if="confirmationDetails.isInvitation"
      class="confirmation-box"
      @submit="createUserPassword"
    >
      <p>{{ $t('AdminInvitationMessage') }}</p>
      <ui-input
        type="password"
        label="DetailsNewPassword"
        placeholder="AppPassword"
        :max-length="20"
        :min-length="8"
        :error="passwordError"
        :value="password"
        @change="passwordChanged"
      />
      <ui-button
        class="btn-brand"
        :disabled="isLoading"
        :loading="isLoading"
        small
      >
        {{ $t('AdminCreatePassword') }}
      </ui-button>
    </ui-form>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { signIn } from '@src/scripts/identity'
import { parseBase64Object } from '@src/scripts/helpers'
import {
  passwordValidator,
  requiredValidator,
  minLengthValidator
} from '@src/scripts/validators'

export default {
  data() {
    return {
      revision: null,
      isCompleted: false,
      isSuccess: false,
      isLoading: false,
      password: null,
      passwordError: 'ErrorFieldRequired',
      completeMessage: null
    }
  },

  computed: {
    confirmationDetails() {
      const query = this.$route.query && this.$route.query.q
      if (!query) return
      return parseBase64Object(query)
    },

    successMessage() {
      const {
        confirmationDetails: { isInvitation }
      } = this
      if (isInvitation) {
        return 'AdminInvitationSuccess'
      }
      return 'AdminConfirmationSuccess'
    }
  },

  created() {
    const { confirmationDetails } = this
    if (confirmationDetails && !confirmationDetails.isInvitation) {
      this.confirmUser()
    }
  },

  methods: {
    redirectToLogin() {
      signIn()
    },

    passwordChanged(value) {
      this.passwordError = requiredValidator(value)
      if (!this.passwordError) {
        this.passwordError =
          minLengthValidator(value, 8) || passwordValidator(value)
      }
      this.password = value
    },

    createUserPassword() {
      if (this.passwordError) return
      this.confirmUser()
    },

    async confirmUser() {
      this.isLoading = true
      try {
        const {
          password,
          successMessage,
          confirmationDetails: { userId, token }
        } = this
        const { error } = await Api.post('/users/confirm', {
          userId,
          token,
          password: password || undefined
        })

        if (error) {
          this.completeMessage = error.message
        } else {
          this.isSuccess = true
          this.completeMessage = successMessage
        }
      } catch (error) {
        this.completeMessage = 'ServiceError'
      }
      this.isLoading = false
      this.isCompleted = true
    }
  }
}
</script>

<style scoped>
.user-confirmation {
  max-width: 30rem;
}

.user-confirmation h2 {
  text-align: center;
}

.user-confirmation p {
  white-space: pre-line;
}

.user-confirmation .error-message {
  font-size: inherit;
}

.user-confirmation .ui-button {
  margin-top: 1rem;
}

.user-confirmation .confirmation-box {
  display: flex;
  flex-flow: column wrap;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.user-confirmation button {
  width: 20rem;
  align-self: center;
}
</style>
