var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-form",
    {
      staticClass: "header-form",
      on: {
        submit: function ($event) {
          return _vm.$emit("submit", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "inputs" }, [_vm._t("inputs")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "actions" }, [_vm._t("actions")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }