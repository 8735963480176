<template>
  <box-with-header class="call-settings">
    <div v-if="isReady" slot="header" class="header-box">
      <ui-button class="btn-brand" small @click="saveSettings">
        {{ $t('Call.Settings.Save') }}
      </ui-button>
    </div>
    <ui-container>
      <template v-if="isReady">
        <ui-card title="Call.Settings.WorkingHours.Title" multicolumn>
          <ui-widget
            slot="body-left"
            class="default-time"
            title="Call.Settings.WorkingHours.Daily"
          >
            <template v-if="defaultTime">
              <time-box
                v-for="(value, key, index) in defaultTime"
                :key="index"
                :label="$t(`Call.Settings.WorkingHours.${dayNames[index]}`)"
                :value="value"
                nullable
                @change="(time) => defaultTimeChanged(key, time)"
              />
            </template>
          </ui-widget>
          <ui-widget
            slot="body-right"
            title="Call.Settings.WorkingHours.Exceptions"
          >
            <div class="exception-input">
              <el-date-picker
                type="date"
                value-format="yyyy-MM-dd"
                :editable="false"
                :clearable="false"
                :value="exception.date"
                @input="exceptionDateChange"
              />
              <div class="checkbox">
                <ui-checkbox
                  label="Call.Settings.WorkingHours.WorkingDay"
                  :value="exception.isWorkingDay"
                  @input="exceptionWorkingFlagChanged"
                />
              </div>
              <time-box
                :value="exception.time"
                :disabled="!exception.isWorkingDay"
                @change="exceptionTimeChanged"
              />
              <ui-button class="btn-brand" small @click="addException">
                {{ $t('Call.Settings.WorkingHours.Add') }}
              </ui-button>
            </div>
            <div class="exception-list">
              <div
                v-for="item in orderedExceptions"
                :key="item.key"
                class="exception-item"
                :class="{ expired: item.expired }"
              >
                <span>
                  <strong>{{ item.key }}</strong>
                  {{ exceptionValue(item.value) }}
                </span>
                <ui-button
                  class="btn-metal"
                  small
                  @click="() => removeException(item.key)"
                >
                  {{ $t('Call.Settings.WorkingHours.Remove') }}
                </ui-button>
              </div>
            </div>
          </ui-widget>
        </ui-card>
        <ui-card title="Call.Settings.WorkingHours.Message.Title" multicolumn>
          <ui-widget slot="body-left" class="default-time">
            <ui-drop-down
              :label="$t('Call.Settings.WorkingHours.Message.Language')"
              value-description=""
              narrow
              @input="addMessage"
            >
              <option
                v-for="item in availableLanguages"
                :key="item.key"
                :value="item.key"
              >
                {{ item.value }}
              </option>
            </ui-drop-down>
          </ui-widget>
          <ui-widget slot="body-right">
            <div
              v-for="(value, key, index) in messageList"
              :key="key"
              class="message-box"
            >
              <ui-input
                :label="`Language.${key}`"
                placeholder="Call.Settings.WorkingHours.Message.Placeholder"
                :value="value"
                :max-length="256"
                :rows="3"
                multiline
                @input="(val) => messageChanged(key, val)"
              />
              <button
                v-if="index > 0"
                type="button"
                class="message-button"
                @click="() => removeMessage(key)"
              />
            </div>
          </ui-widget>
        </ui-card>
      </template>
      <ui-card v-else>
        <ui-loader center />
      </ui-card>
    </ui-container>
  </box-with-header>
</template>

<script>
import TimeBox from '@src/components/partials/TimeBox'
import BoxWithHeader from '@src/components/partials/BoxWithHeader'
import { notificationType } from '@src/components/notification'
import Api from '@src/scripts/api'

export default {
  components: {
    TimeBox,
    BoxWithHeader
  },

  data() {
    return {
      isReady: false,
      defaultTime: null,
      exceptionList: null,
      messageList: null,
      exception: this.defaultExceptionModel(),
      dayNames: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      languageList: ['LT', 'RU', 'ET', 'LV', 'BG']
    }
  },

  computed: {
    availableLanguages() {
      const { languageList, messageList } = this

      const messageLanguages = Object.keys(messageList)
      const filteredLanguages = languageList.filter(
        (item) => !messageLanguages.includes(item)
      )

      const resultList = filteredLanguages.map((item) => ({
        key: item,
        value: this.$t(`Language.${item}`)
      }))

      resultList.sort(({ value: v1 }, { value: v2 }) => v1.localeCompare(v2))
      return resultList
    },

    orderedExceptions() {
      const { exceptionList } = this
      if (!exceptionList) return

      const resultList = []
      for (const key in exceptionList) {
        const value = exceptionList[key]

        let expired = false
        if (new Date(key).getTime() < Date.now()) {
          expired = true
        }

        resultList.push({
          key,
          value,
          expired
        })
      }

      if (resultList.length > 1) {
        resultList.sort(({ key: k1 }, { key: k2 }) => -k1.localeCompare(k2))
      }

      return resultList
    }
  },

  async created() {
    const timeSettings = await this.getTimeSettings()

    this.defaultTime = {
      ...this.defaultTimeModel(),
      ...timeSettings?.defaults
    }

    this.exceptionList = { ...timeSettings?.exceptions }

    this.messageList = {
      EN: '',
      ...timeSettings?.messages
    }

    this.isReady = true
  },

  methods: {
    defaultTimeChanged(property, value) {
      this.defaultTime[property] = value
    },

    exceptionDateChange(value) {
      const { exceptionList } = this

      if (Object.keys(exceptionList).includes(value)) {
        this.exception.date = null

        return this.$notify({
          title: 'Call.Settings.WorkingHours.Error.CantSelect',
          message: 'Call.Settings.WorkingHours.Error.DateInUse',
          type: notificationType.info
        })
      }

      const newDate = new Date(value)
      const now = new Date()
      now.setUTCDate(now.getUTCDate() - 1)

      if (newDate < now) {
        this.exception.date = null

        return this.$notify({
          title: 'Call.Settings.WorkingHours.Error.CantSelect',
          message: 'Call.Settings.WorkingHours.Error.UnreachableDate',
          type: notificationType.info
        })
      }

      this.exception.date = value
    },

    exceptionWorkingFlagChanged(value) {
      this.exception.isWorkingDay = value
      if (!value) this.exception.time = null
    },

    exceptionTimeChanged(value) {
      this.exception.time = value
    },

    messageChanged(key, value) {
      this.messageList[key] = value
    },

    addMessage(property) {
      this.$set(this.messageList, property, '')
    },

    removeMessage(property) {
      delete this.messageList[property]

      this.messageList = {
        ...this.messageList
      }
    },

    addException() {
      const { date, time, isWorkingDay } = this.exception
      if (!date || (isWorkingDay && !time)) {
        return this.$notify({
          title: 'Call.Settings.WorkingHours.Error.CantAdd',
          message: 'Call.Settings.WorkingHours.Error.NotFilled'
        })
      }

      this.$set(this.exceptionList, date, time)
      this.exception = this.defaultExceptionModel()
    },

    removeException(key) {
      delete this.exceptionList[key]

      this.exceptionList = {
        ...this.exceptionList
      }
    },

    exceptionValue(value) {
      if (!value) return this.$t('Call.Settings.WorkingHours.NotWorking')

      const fromLabel = this.$t('Call.Settings.WorkingHours.From')
      const toLabel = this.$t('Call.Settings.WorkingHours.To')

      return `${fromLabel}
        ${this.normalizeNumber(value.from.hours)}
        : ${this.normalizeNumber(value.from.minutes)},
        ${toLabel}
        ${this.normalizeNumber(value.to.hours)}
        : ${this.normalizeNumber(value.to.minutes)}`
    },

    normalizeNumber(number) {
      return ('0' + number).slice(-2)
    },

    normalizeMessages(messageList) {
      if (!messageList) return undefined

      const result = {}
      for (const key in messageList) {
        const value = messageList[key]?.trim()
        if (!value) continue

        result[key] = value
      }

      if (!Object.keys(result).length) return undefined
      return result
    },

    async saveSettings() {
      const { defaultTime, exceptionList, messageList } = this

      const payload = {
        timeZone: 2,
        defaults: defaultTime,
        exceptions: exceptionList,
        messages: this.normalizeMessages(messageList)
      }

      try {
        await Api.post('/call/settings', payload)

        this.$notify({
          title: 'Call.Settings.WorkingHours.Completed',
          type: notificationType.info
        })
      } catch (error) {
        this.notifyError()
      }
    },

    async getTimeSettings() {
      try {
        const response = await Api.get('/call/settings')
        if (response) return response

        this.notifyError()
      } catch (error) {
        this.notifyError()
      }
    },

    notifyError() {
      this.$notify({
        title: 'ErrorUnknownTitle',
        message: 'ErrorUnknownDescription'
      })
    },

    defaultTimeModel() {
      return { 0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null }
    },

    defaultExceptionModel() {
      return { date: null, isWorkingDay: false, time: null }
    }
  }
}
</script>

<style scoped>
.time-box {
  max-width: 50rem;
}

.exception-input {
  display: flex;
  flex-wrap: wrap;
}

.exception-input > * {
  margin: 0.5rem 1.5rem 0.5rem 0;
}

.el-date-editor {
  width: 12rem;
}

.checkbox {
  display: flex;
}

.checkbox .ui-checkbox {
  margin-top: auto;
  margin-bottom: auto;
}

.header-box {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.header-box .ui-button {
  margin-left: auto;
}

.default-time .time-box {
  margin-top: 1rem;
}

.exception-list {
  height: 100%;
  margin-top: 2.5rem;
}

.exception-item {
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dcdfe6;
  padding: 0.5rem 1.5rem;
}

.exception-item > :first-child {
  margin-right: 1rem;
}

strong {
  margin-right: 1.5rem;
}

.expired {
  background-color: #ebeff7;
}

.ui-drop-down {
  max-width: 30rem;
}

.message-box {
  position: relative;
}

.message-button {
  position: absolute;
  top: 1rem;
  right: 0;
  height: 2rem;
  width: 2rem;
  background: none;
  border: none;
}

.message-button:hover {
  cursor: pointer;
}

.message-button::before,
.message-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  border-left: 1px solid var(--gray);
  height: 60%;
}

.message-button::before {
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.message-button::after {
  transform: translate(-50%, -50%) rotateZ(-45deg);
}

.message-button:hover::before,
.message-button:hover::after {
  border-color: var(--brand);
}
</style>
