<template>
  <div v-show="isVisible" class="m-scroll-top" @click="onClick">
    <i class="la la-arrow-up" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      previousPosition: null
    }
  },

  created() {
    document.addEventListener('scroll', this.show)
  },

  methods: {
    onClick() {
      this.scrollToTop()
    },

    scrollToTop() {
      const { scrollToTop } = this
      const elementTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (
        elementTop > 0 &&
        (!this.previousPosition || this.previousPosition >= elementTop)
      ) {
        window.requestAnimationFrame(scrollToTop)
        window.cancelAnimationFrame(scrollToTop)
        this.previousPosition = (elementTop - elementTop / 16) << 0
        window.scrollTo(0, this.previousPosition)
      } else {
        this.previousPosition = null
      }
    },

    show() {
      const elementTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (elementTop > 300) {
        if (!this.isVisible) {
          this.isVisible = true
        }
      } else if (this.isVisible) {
        this.isVisible = false
      }
    }
  }
}
</script>
