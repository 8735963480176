<template>
  <div class="m-subheader-search">
    <h2 class="m-subheader-search__title">
      {{ $t(title) }}
    </h2>
    <p v-if="description" class="color-white">
      {{ $t(description) }}
    </p>
    <ui-form class="m-form">
      <div class="m-grid m-grid--ver-desktop m-grid--desktop header-content">
        <div class="m-grid__item filters-list">
          <slot />
        </div>
      </div>
    </ui-form>
  </div>
</template>

<script>
export default {
  name: 'ui-header-box',

  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined }
  }
}
</script>
<style scoped>
.filters-list {
  display: flex;
  flex-wrap: wrap;
}
</style>
