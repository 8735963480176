var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", [_c("ui-loader", { attrs: { center: "" } })], 1)
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? [
            _vm.sanctions.length
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.sanctions, function (sanction) {
                    return _c(
                      "div",
                      {
                        key: sanction.id,
                        staticClass:
                          "col-md-6 col-lg-6 col-xl-4 santions-list-item",
                      },
                      [
                        _c(
                          "ui-card",
                          { attrs: { title: sanction.entity_type } },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("details-item-full-width", {
                                  attrs: { label: sanction.fullname },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                sanction.types && sanction.types.length
                                  ? _c("details-item-with-tags", {
                                      attrs: {
                                        label: "DetailsMatched",
                                        values: sanction.types,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                sanction.match_types &&
                                sanction.match_types.length
                                  ? _c("details-item-with-tags", {
                                      attrs: {
                                        label: "DetailsRelevance",
                                        values: sanction.match_types,
                                        "no-style-tabs": "",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            sanction.associates && sanction.associates.length
                              ? _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("details-item-with-tags", {
                                      attrs: {
                                        label: "DetailsAssociates",
                                        values: sanction.associates,
                                        "no-style-tabs": "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                sanction.countries && sanction.countries.length
                                  ? _c("details-item-with-tags", {
                                      attrs: {
                                        label: "DetailsCountries",
                                        values: sanction.countries,
                                        "no-style-tabs": "",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                sanction.dob
                                  ? _c("details-item-simple-text", {
                                      attrs: {
                                        label: "DetailsDOB",
                                        value: sanction.dob,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "absolute-button",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRedirect()
                                  },
                                },
                              },
                              [
                                _c(
                                  "ui-link",
                                  {
                                    staticClass:
                                      "ui-button btn m-btn btn-brand big-link m-btn--wide m-btn--md m-btn--mt15",
                                    attrs: {
                                      to: {
                                        name: "SanctionDetails",
                                        params: {
                                          sanctionId: sanction.id,
                                          fuzzinessPercent:
                                            _vm.finalFuzzinessPercent,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("Details")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ui-link",
                                  {
                                    staticClass:
                                      "ui-button btn m-btn btn-metal big-link m-btn--wide m-btn--md m-btn--mt15",
                                    attrs: {
                                      to: {
                                        name: "SanctionDetails",
                                        params: {
                                          sanctionId: sanction.id,
                                          fuzzinessPercent:
                                            _vm.finalFuzzinessPercent,
                                          export: true,
                                        },
                                      },
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("AppExport")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "no-results table-message col-12" }, [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("TableNoData")) + "\n      "
                    ),
                  ]),
                ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }