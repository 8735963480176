var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isNoMrzFound && !_vm.editEnabled
        ? _c("div", [_vm._v(_vm._s(_vm.$t("Mrz.FailedToRead")))])
        : _vm._e(),
      _vm._v(" "),
      _c("mrz-lines-list", {
        attrs: {
          "edit-enabled": _vm.editEnabled,
          "field-values": _vm.fieldValues,
          "action-in-progress": false,
          "document-updating": false,
          "mrz-lines-count": _vm.mrzLinesCount,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }