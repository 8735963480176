<template>
  <div class="form-container">
    <div v-if="isLoading" class="loader">
      <ui-loader :size="4" center />
    </div>
    <template v-else>
      <h2>{{ $t('CompanyReportForm.Title') }}</h2>
      <div class="block">
        <p>{{ $t('CompanyReportForm.CompanyFormStepOne') }}</p>
      </div>
      <div class="block">
        <p class="label">{{ $t('CompanyReportForm.SelectCountry') }}</p>
        <ui-dropdown
          v-model="country"
          has-search
          :options="countries"
          placeholder="CompanyReportForm.CountrySelectPlaceholder"
          @input="handleCountrySelect"
        />
      </div>
      <div class="block">
        <p class="label">{{ $t('CompanyReportForm.ReportType') }}</p>
        <div class="value">{{ $t('CompanyReportForm.FullReport') }}</div>
      </div>
      <div class="block radio-block">
        <p class="label">{{ $t('CompanyReportForm.DeliveryTimePeriod') }}</p>
        <ui-radio
          :label="$t('CompanyReportForm.Fastest')"
          :value="'Fastest'"
          name="deliveryTime"
          @checkRadio="handleToggleChange"
        />
        <ui-radio
          :label="$t('CompanyReportForm.Fast')"
          :value="'Fast'"
          name="deliveryTime"
          checked
          @checkRadio="handleToggleChange"
        />
        <ui-radio
          :label="$t('CompanyReportForm.Normal')"
          :value="'Normal'"
          name="deliveryTime"
          @checkRadio="handleToggleChange"
        />
      </div>
      <div class="block">
        <p>{{ $t('CompanyReportForm.CompanyFormStepTwo') }}</p>
      </div>
      <div class="block input-block">
        <ui-input
          v-model="model.companyName"
          multiline
          :label="$t('CompanyReportForm.EnterCompanyName')"
          :placeholder="$t('CompanyReportForm.CompanyNamePlaceholder')"
          type="text"
        />
        <ui-input
          v-model="model.companyCode"
          multiline
          :label="$t('CompanyReportForm.EnterCompanyCode')"
          :placeholder="$t('CompanyReportForm.CompanyCodePlaceholder')"
          type="text"
        />
      </div>

      <div class="block button-block">
        <ui-button
          class="btn btn-brand"
          :disabled="!isValid"
          @click="handleSubmit"
        >
          {{ $t('CompanyReportForm.SubmitBtn') }}
        </ui-button>
      </div>
    </template>
  </div>
</template>
<script>
import { mapOptions } from '@src/scripts/helpers'
import Api from '@src/scripts/api'
import { countriesIso3toIso2 } from '@src/scripts/countriesIso3toIso2'
import { notificationType } from '@src/components/notification'

export default {
  data: () => ({
    isValid: false,
    isLoading: false,
    country: '',
    model: {
      selectedCountry: '',
      reportType: 'full_report',
      deliveryTime: 'Fast',
      companyName: '',
      companyCode: ''
    }
  }),
  computed: {
    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities.map((item) => item).sort()
    },

    countries() {
      return mapOptions(this.nationalities)
    }
  },
  watch: {
    'model.selectedCountry'() {
      this.isValid = this.validateFields()
    },
    'model.companyName'() {
      this.isValid = this.validateFields()
    },
    'model.companyCode'() {
      this.isValid = this.validateFields()
    }
  },
  methods: {
    resetForm() {
      this.model = {
        selectedCountry: '',
        reportType: 'full_report',
        deliveryTime: 'fastest',
        companyName: '',
        companyCode: ''
      }
    },
    async handleSubmit() {
      console.log(this.model.deliveryTime)

      this.isLoading = true
      try {
        await Api.post('/company-report-orders', {
          countryCode: this.model.selectedCountry,
          code: this.model.companyCode,
          name: this.model.companyName,
          deliveryTerm: this.model.deliveryTime,
          vat: '',
          address: ''
        })
        this.resetForm()
        this.isLoading = false
        this.$emit('listUpdateEvent')
        this.$closePopUp()
        this.$notify({
          type: notificationType.info,
          title: this.$t('AppReportIsOrdered')
        })
      } catch {
        this.isLoading = false
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'AppTryAgain'
        })
      }
    },
    validateFields() {
      return (
        this.model.selectedCountry !== '' &&
        this.model.companyName !== '' &&
        this.model.companyCode !== ''
      )
    },
    handleToggleChange(value) {
      this.model.deliveryTime = value
    },
    handleCountrySelect(value) {
      this.model.selectedCountry = countriesIso3toIso2[value]
    }
  }
}
</script>

<style scoped>
.form-container {
  max-width: 100%;
  width: 528px;
  font-family: 'Poppins', sans-serif;
}

.loader {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container h2 {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.form-container p {
  color: #000;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
}

.value {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

p.label {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.input-block /deep/ label {
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 4px;
}

.input-block /deep/ .ui-input {
  margin-bottom: 24px;
}

.input-block /deep/ textarea::placeholder {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #cacaca;
}

.block {
  margin-bottom: 16px;
}

.radio-block /deep/ label .m-radio {
  font-size: 12px;
  line-height: 18px;
  color: #000;
}

.radio-block /deep/ label {
  padding: 8px 0 0 0;
  margin-bottom: 0;
  font-family: 'Poppins', sans-serif;
}

.block /deep/ .ui-dropdown {
  max-width: 257px;
}

.block /deep/ .ui-dropdown textarea {
  border: 1px solid #9595aa;
  border-radius: 5px;
}

.button-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block /deep/ .dropdown-box > .dropdown-menu {
  max-height: 200px;
  border: 1px solid #9595aa;
}
</style>
