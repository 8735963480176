var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-settings-page" },
    [
      _c(
        "ui-form",
        { key: _vm.submitCount, on: { submit: _vm.saveChanges } },
        [
          _c(
            "box-with-header",
            [
              _c(
                "div",
                {
                  staticClass: "header-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    { staticClass: "tools-buttons" },
                    [
                      _c("edit-header", {
                        attrs: { "edit-enabled": _vm.details.editEnabled },
                        on: { edit: _vm.enableDetailsEdit },
                      }),
                      _vm._v(" "),
                      _vm.details.editEnabled
                        ? _c(
                            "ui-button",
                            {
                              staticClass: "btn btn-metal m--margin-left-10",
                              attrs: { small: "" },
                              on: { click: _vm.clickCancel },
                            },
                            [
                              _c("i", { staticClass: "la la-close" }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t("AppCancel")) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("ui-header", { attrs: { title: "UserSettings" } }),
              _vm._v(" "),
              _c(
                "ui-container",
                [
                  !_vm.isLoading
                    ? [
                        _c(
                          "ui-card",
                          { attrs: { large: "", multicolumn: "" } },
                          [
                            _c(
                              "ui-widget",
                              {
                                attrs: { slot: "body-left", title: "User" },
                                slot: "body-left",
                              },
                              [
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsFirstName",
                                    placeholder: _vm.placeholder.firstName,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.user.errors.firstName,
                                    value: _vm.userDetails.firstName,
                                    "max-length": 50,
                                  },
                                  on: { change: _vm.firstNameChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsLastName",
                                    placeholder: _vm.placeholder.lastName,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.user.errors.lastName,
                                    value: _vm.userDetails.lastName,
                                    "max-length": 50,
                                  },
                                  on: { change: _vm.lastNameChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsEmail",
                                    placeholder: _vm.placeholder.email,
                                    "max-length": 255,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.user.errors.email,
                                    value: _vm.userDetails.email,
                                  },
                                  on: { change: _vm.emailChanged },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.details.editEnabled
                              ? _c(
                                  "ui-widget",
                                  {
                                    attrs: {
                                      slot: "body-right",
                                      "top-spacing": "",
                                    },
                                    slot: "body-right",
                                  },
                                  [
                                    _c("details-item", {
                                      attrs: {
                                        type: "password",
                                        label: "DetailsNewPassword",
                                        "min-length": 8,
                                        "max-length": 50,
                                        placeholder: _vm.placeholder.password,
                                        editable: _vm.details.editEnabled,
                                        large: "",
                                        error: _vm.user.errors.password,
                                        value: _vm.userDetails.password,
                                      },
                                      on: { change: _vm.passwordChanged },
                                    }),
                                    _vm._v(" "),
                                    _c("details-item", {
                                      attrs: {
                                        type: "password",
                                        label: "DetailsRepeatNewPassword",
                                        "min-length": 8,
                                        "max-length": 50,
                                        placeholder:
                                          _vm.placeholder.repeatPassword,
                                        editable: _vm.details.editEnabled,
                                        large: "",
                                        error: _vm.user.errors.repeatPassword,
                                        value: _vm.userDetails.repeatPassword,
                                      },
                                      on: { change: _vm.repeatPasswordChanged },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "ui-card",
                          { attrs: { large: "", multicolumn: "" } },
                          [
                            _c(
                              "ui-widget",
                              {
                                attrs: {
                                  slot: "body-left",
                                  title: "Company information",
                                },
                                slot: "body-left",
                              },
                              [
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsCompanyName",
                                    placeholder: _vm.placeholder.companyName,
                                    "max-length": 50,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.company.errors.companyName,
                                    value: _vm.companyDetails.companyName,
                                  },
                                  on: { change: _vm.companyNameChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsAdress",
                                    placeholder: _vm.placeholder.adressCity,
                                    "max-length": 250,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.company.errors.adressCity,
                                    value: _vm.companyDetails.adressCity,
                                  },
                                  on: { change: _vm.companyAdressCityChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    placeholder: _vm.placeholder.adressStreet,
                                    "max-length": 250,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.company.errors.adressStreet,
                                    value: _vm.companyDetails.adressStreet,
                                  },
                                  on: {
                                    change: _vm.companyAdressStreetChanged,
                                  },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsZipCode",
                                    placeholder: _vm.placeholder.zip,
                                    "max-length": 20,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.company.errors.zip,
                                    value: _vm.companyDetails.zip,
                                  },
                                  on: { change: _vm.companyZipChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsCountry",
                                    placeholder: _vm.placeholder.country,
                                    options: _vm.countries,
                                    editable: _vm.details.editEnabled,
                                    value: _vm.companyDetails.country,
                                    large: "",
                                  },
                                  on: { change: _vm.selectCountry },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "ui-widget",
                              {
                                staticClass: "padding",
                                attrs: {
                                  slot: "body-right",
                                  title: "Billing address",
                                },
                                slot: "body-right",
                              },
                              [
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsCompanyName",
                                    placeholder: _vm.placeholder.companyName,
                                    "max-length": 50,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error:
                                      _vm.billingCompany.errors.companyName,
                                    value:
                                      _vm.billingCompanyDetails.companyName,
                                  },
                                  on: { change: _vm.billingCompanyNameChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsAdress",
                                    placeholder: _vm.placeholder.adressCity,
                                    "max-length": 250,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.billingCompany.errors.adressCity,
                                    value: _vm.billingCompanyDetails.adressCity,
                                  },
                                  on: {
                                    change: _vm.billingCompanyAdressCityChanged,
                                  },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    placeholder: _vm.placeholder.adressStreet,
                                    "max-length": 250,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error:
                                      _vm.billingCompany.errors.adressStreet,
                                    value:
                                      _vm.billingCompanyDetails.adressStreet,
                                  },
                                  on: {
                                    change:
                                      _vm.billingCompanyAdressStreetChanged,
                                  },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsZipCode",
                                    placeholder: _vm.placeholder.zip,
                                    "max-length": 20,
                                    editable: _vm.details.editEnabled,
                                    large: "",
                                    error: _vm.billingCompany.errors.zip,
                                    value: _vm.billingCompanyDetails.zip,
                                  },
                                  on: { change: _vm.billingCompanyZipChanged },
                                }),
                                _vm._v(" "),
                                _c("details-item", {
                                  attrs: {
                                    label: "DetailsCountry",
                                    placeholder: _vm.placeholder.country,
                                    options: _vm.countries,
                                    editable: _vm.details.editEnabled,
                                    value: _vm.billingCompanyDetails.country,
                                    large: "",
                                  },
                                  on: { change: _vm.billingSelectCountry },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _c("ui-loader", { attrs: { size: 4, center: "" } }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }