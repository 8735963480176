const eventTypesEnum = {
  name: 'eventTypes',
  enum: {
    formReady: 'FORM_READY',
    formUpdate: 'FORM_UPDATE',
    formComplete: 'FORM_COMPLETE',
    formClientDetails: 'FORM_CLIENT_DETAILS',
    formPdfSignature: 'FORM_PDF_SIGNATURE',
    formSign: 'FORM_SIGN',
    formLoad: 'FORM_LOAD',
    formUpdateStatus: 'FORM_UPDATE_STATUS',
    formCompleteStatus: 'FORM_COMPLETE_STATUS',
    formClientDetailsStatus: 'FORM_CLIENT_DETAILS_STATUS',
    formPdfSignatureStatus: 'FORM_PDF_SIGNATURE_STATUS',
    formSignStatus: 'FORM_SIGN_STATUS',
    formSignCompleteStatus: 'FORM_SIGN_COMPLETE_STATUS',
    formLanguageChange: 'FORM_LANGUAGE_CHANGE',
    formSessionEnd: 'FORM_SESSION_END',
    formLoaded: 'FORM_LOADED',
    formLog: 'FORM_LOG',
    formSessionRestart: 'FORM_SESSION_RESTART',
    formSessionRestartStatus: 'FORM_SESSION_RESTART_STATUS',
    formCall: 'FORM_CALL',
    formEditState: 'FORM_EDIT_STATE',
    formFileDownload: 'FORM_FILE_DOWNLOAD'
  }
}

const enumHandler = {
  get(target, key) {
    if (target.enum[key]) return target.enum[key]
    throw `No key: ${key} in ${target.name}.`
  },

  set(target, key) {
    throw `Can't edit ${target.name}. Key: ${key}.`
  }
}

export const eventTypes = new Proxy(eventTypesEnum, enumHandler)

export default class {
  constructor(params) {
    this.state = {
      allowedOrigin: null,
      isSecure: false,
      element: null,
      listeners: []
    }

    this.receiveMessage = this.receiveMessage.bind(this)

    if (params) {
      this.state.allowedOrigin = params.allowedOrigin
      this.state.element =
        (params.target && params.target.contentWindow) || window.parent
    } else {
      this.state.element = window.parent
      this.state.isSecure = true
    }

    window.addEventListener('message', this.receiveMessage, false)
  }

  addListener(name, listener) {
    if (!name || !listener) throw 'No listener settings.'

    this.state.listeners.push({
      name,
      listener
    })
  }

  triggerSuccess(name, data) {
    const details = { data: data || true }
    this.triggerEvent(name, details)
  }

  triggerError(name, error) {
    const details = { error: error || true }
    this.triggerEvent(name, details)
  }

  triggerEvent(name, details) {
    if (!this.state.isSecure) return
    this.state.element.postMessage({ name, details }, '*')
  }

  clearListeners() {
    window.removeEventListener('message', this.receiveMessage, false)

    this.state.listeners = []
    this.state.isSecure = false
  }

  receiveMessage(event) {
    if (this.state.allowedOrigin && this.state.allowedOrigin !== event.origin)
      return
    else if (!this.state.isSecure) this.state.isSecure = true

    const data = event.data
    if (!data || !data.name || !data.details) return

    const listenerDetails = this.state.listeners.find(
      (item) => item.name === data.name
    )
    if (!listenerDetails) return

    listenerDetails.listener(data.details)
  }
}
