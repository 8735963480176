<template>
  <div class="details-item">
    <h4>
      {{ $t(label) }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: undefined }
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-item h4 {
  padding: 0 1.15rem 0.85rem;
  margin: 0;
  color: var(--brand);
}
</style>
