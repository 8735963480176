var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "m-nav__item m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light",
      class: { "dropdown-open": _vm.isOpened },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.clickEnter($event)
        },
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "m-nav__link m-dropdown__toggle select-btn",
          on: { click: _vm.open },
        },
        [
          _c("span", { staticClass: "btn-text-box" }, [
            _vm._v("\n      " + _vm._s(_vm.selectedValue) + "\n    "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "m-dropdown__wrapper",
          class: { "is-opened": _vm.isOpened },
          attrs: { id: "dropdown-menu" },
          on: { keyup: _vm.keyup },
        },
        [
          _c("span", { staticClass: "m-dropdown__arrow", style: _vm.styles }),
          _vm._v(" "),
          _c("div", { staticClass: "m-dropdown__inner" }, [
            _c(
              "div",
              { staticClass: "m-dropdown__body" },
              [
                _c(
                  "div",
                  { staticClass: "filter-wrapper search-box" },
                  [
                    _c("ui-input", {
                      ref: "companyName",
                      attrs: {
                        placeholder: "AdminCompaniesTitle",
                        value: _vm.dropDownvalue,
                      },
                      on: {
                        blur: _vm.lostFocus,
                        input: _vm.changeInput,
                        click: _vm.clickSearch,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.searchOptions, function (item, itemIndex) {
                  return _c(
                    "a",
                    {
                      key: itemIndex,
                      staticClass: "dropdown-item",
                      class: { selected: _vm.selectedIndex == itemIndex },
                      attrs: { tabindex: itemIndex },
                      on: {
                        click: function ($event) {
                          return _vm.select(item.id)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "bs-ok-default check-mark" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "text" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.name) + "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }