<template>
  <div>
    <ui-header
      title="SanctionsTitle"
      :method="searchMethod"
      :filters="filters"
      :required-filters="requiredFilters"
      :disabled="isLoading || filters.searchTerm === ''"
      :disable-search-on-load="!$route.query.searchTerm"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <ui-header-input
        v-model="filters.searchTerm"
        placeholder="HeaderNameSurnameLegalNamePlaceholder"
        icon="la-bank"
        wide
        :disabled="isLoading || showNotification"
      />
      <ui-header-input
        v-model="filters.birthYear"
        placeholder="HeaderDOBPlaceholder"
        medium
        :disabled="isLoading || showNotification"
      />
      <ui-header-dropdown
        v-model="filters.fuzziness"
        :options="options"
        small
        placeholder="HeaderFuzzynessPlaceholder"
        :disabled="showNotification"
      />
    </ui-header>
    <ui-container>
      <sanction-notification v-if="showNotification" />
      <div v-else class="row">
        <div v-if="isLoading" class="col-md-12 col-lg-12">
          <ui-loader :size="3" center />
        </div>
        <template v-else>
          <div v-if="clickedSearch" class="details-sanctions-search-title">
            <div class="doc-export-block">
              <h4>{{ $t('SanctionSearchTopBarTitle') }} "{{ searchName }}"</h4>
              <p>
                {{ $t('SanctionSearchTopBarMatches') }}:
                <strong>{{ itemsCount }}</strong>
                {{ $t('SanctionSearchTopBarSorted') }}
              </p>
              <p>
                {{ $t('SearchedIn') }}
                {{ finalFuzzinessPercent || filters.fuzziness * 100 }}%
              </p>
            </div>
            <div class="button-max-height">
              <ui-dropdown
                :options="exportOptions"
                placeholder="AppExport"
                class="margin-right"
                small
                metal
                @input="exportList"
              />
            </div>
          </div>
          <div class="col-md-12 col-lg-12 santions-list">
            <div class="row">
              <div
                v-for="sanction in sanctions"
                :key="sanction.id"
                class="col-md-6 col-lg-6 col-xl-3 santions-list-item"
              >
                <ui-card :title="sanction.entity_type">
                  <div class="row">
                    <details-item-full-width :label="sanction.fullname" />
                  </div>
                  <div class="row">
                    <details-item-with-tags
                      v-if="sanction.types && sanction.types.length > 0"
                      label="DetailsMatched"
                      :values="sanction.types"
                    />
                  </div>
                  <div class="row">
                    <details-item-with-tags
                      v-if="
                        sanction.match_types && sanction.match_types.length > 0
                      "
                      label="DetailsRelevance"
                      :values="sanction.match_types"
                      no-style-tabs
                    />
                  </div>
                  <div
                    v-if="sanction.associates && sanction.associates.length > 0"
                    class="row"
                  >
                    <details-item-with-tags
                      label="DetailsAssociates"
                      :values="sanction.associates"
                      no-style-tabs
                    />
                  </div>
                  <div class="row">
                    <details-item-with-tags
                      v-if="sanction.countries && sanction.countries.length > 0"
                      label="DetailsCountries"
                      :values="sanction.countries"
                      no-style-tabs
                    />
                  </div>
                  <div class="row">
                    <details-item-simple-text
                      v-if="sanction.dob"
                      label="DetailsDOB"
                      :value="sanction.dob"
                    />
                  </div>
                  <div class="absolute-button">
                    <ui-link
                      :to="{
                        name: 'SanctionDetails',
                        params: {
                          sanctionId: sanction.id,
                          fuzzinessPercent: finalFuzzinessPercent
                        }
                      }"
                      class="ui-button btn m-btn btn-brand big-link m-btn--wide m-btn--md m-btn--mt15"
                    >
                      {{ $t('Details') }}
                    </ui-link>
                    <ui-link
                      :to="{
                        name: 'SanctionDetails',
                        params: {
                          sanctionId: sanction.id,
                          fuzzinessPercent: finalFuzzinessPercent,
                          export: true
                        }
                      }"
                      class="ui-button btn m-btn btn-metal big-link m-btn--wide m-btn--md m-btn--mt15"
                      target="_blank"
                    >
                      {{ $t('AppExport') }}
                    </ui-link>
                  </div>
                </ui-card>
              </div>
            </div>
            <p
              v-if="itemsCount === 0 && searchName && searchName.length > 0"
              class="text-center doc-export-block2"
            >
              {{ $t('PersonNotFoundSanction') }}
            </p>
          </div>
        </template>
      </div>
    </ui-container>
  </div>
</template>

<script>
import DetailsItemWithTags from '@src/components/partials/DetailsItemWithTags'
import DetailsItemFullWidth from '@src/components/partials/DetailsItemFullWidth'
import DetailsItemSimpleText from '@src/components/partials/DetailsItemSimpleText'
import actions from '@src/store/actions'
import { mapOptions } from '@src/scripts/helpers'
import { exportPdf } from '@src/scripts/exportPdf'
import SanctionNotification from './SanctionNotification'

const filters = () => ({
  searchTerm: '',
  birthYear: null,
  fuzziness: 0.5
})

export default {
  components: {
    DetailsItemWithTags,
    DetailsItemFullWidth,
    DetailsItemSimpleText,
    SanctionNotification
  },

  data({ fuzzynessOptions }) {
    return {
      isReady: false,
      isLoading: false,
      isDownloading: false,
      sanctions: null,
      itemsCount: 0,
      searchMethod: '/kyb/screening',
      requiredFilters: ['searchTerm', 'fuzziness'],
      filters: filters(),
      options: fuzzynessOptions(),
      searchName: null,
      clickedSearch: false,
      finalFuzzinessPercent: 0
    }
  },

  computed: {
    exportOptions() {
      return mapOptions(['PDF'])
    },

    showNotification() {
      return true
    }
  },

  created() {
    this.initQueryFilter()
    this.finalFuzzinessPercent = this.filters.fuzziness * 100
  },

  methods: {
    initQueryFilter() {
      const { searchTerm, birthYear, fuzziness } = {
        ...this.filters,
        ...this.$route.query
      }
      if (!searchTerm) return
      this.filters = { searchTerm, birthYear, fuzziness }
      this.$store.dispatch(actions.updateHistory, {
        key: this.$route.name,
        value: this.filters
      })
    },

    startSearch() {
      this.isLoading = true
      this.finalFuzzinessPercent = this.filters.fuzziness * 100
    },

    finishSearch(data) {
      this.isLoading = false
      switch (true) {
        case data.content !== undefined:
          this.searchTerm = data.content.data.search_term
          this.sanctions = this.sanctionsDataMapping(data.content.data.hits)
          this.itemsCount = data.content.data.total_matches
          break
        case data.length > 0 && data[0].searchTerm === this.filters.searchTerm:
          this.sanctions = data
          this.itemsCount = data.length
          break
        default:
          this.sanctions = data
          this.itemsCount = 0
      }
      this.searchName = this.filters.searchTerm
      if (!this.filters.searchTerm) this.clickedSearch = false
      else this.clickedSearch = true
    },

    async pdfDownload() {
      const header = {
        resultTitle: `${this.$t('SanctionSearchTopBarTitle')} “${
          this.filters.searchTerm
        }”`,
        date: this.filters.birthYear
          ? `${this.$t('HeaderDOBPlaceholder')} “${this.filters.birthYear}”`
          : null,
        matches: `${this.$t('SanctionSearchTopBarMatches')} ${
          this.itemsCount
        } ${this.$t('SanctionSearchTopBarSorted')}`,
        searchedIn: `${this.$t('SanctionsExportSerachedIn')}`,
        fuzziness: `${this.$t('HeaderFuzzyness')}: ${
          this.finalFuzzinessPercent || this.filters.fuzziness * 100
        } %`
      }

      const name = this.$store.getters.sessionData.profile.name
      const data = [{ selector: '.pdf__export-all' }]
      const exportFile = new exportPdf(data, name, 'details')

      try {
        await exportFile.getPdfColumn(this.sanctions, header)
      } catch {
        //null
      }
    },

    exportList(value) {
      if (this.itemsCount) {
        this.pdfDownload()
      } else {
        if (value === 'PDF') {
          this.exportPdf()
        } else if (value === 'CSV') {
          //
        } else if (value === 'XLS') {
          //
        }
      }
    },
    async exportPdf() {
      this.hideButtons = true
      const name = this.$store.getters.sessionData.profile.name
      const data = [
        { selector: '.searched-in-text' },
        { selector: '.doc-export-block' },
        { selector: '.doc-export-block2' }
      ]
      const exportFile = new exportPdf(data, name, 'details')
      await exportFile.exportPdf()
      this.hideButtons = false
    },

    sanctionsDataMapping(sanctions) {
      const cleanSanctions = []
      if (sanctions.length > 0) {
        for (let x = 0; x < sanctions.length; x++) {
          const santion = this.mappedData(sanctions[x])
          cleanSanctions.push(santion)
        }
        this.$store.dispatch(actions.updateSantions, cleanSanctions)
      }
      return cleanSanctions
    },

    mappedData(santion) {
      const countries = this.cleanCountries(
        (santion.doc.fields ?? []).filter((field) => field.name === 'Country')
      )
      const dob = this.dateOfBirthLong(
        (santion.doc.fields ?? []).filter(
          (field) => field.tag === 'date_of_birth'
        )
      )
      const source_notes = this.sourceNotes(
        santion.doc.sources,
        santion.doc.source_notes
      )
      const associates = this.associates(santion.doc.associates)
      const aka = this.associates(santion.doc.aka)
      const images = this.images(santion.doc)
      return {
        id: santion.doc.id,
        match_types: santion.match_types,
        entity_type: santion.doc.entity_type,
        countries,
        fullname: santion.doc.name,
        types: santion.doc.types,
        dob: dob,
        assets: santion.doc.assets,
        medias: santion.doc.media,
        source_notes: source_notes,
        associates: associates || undefined,
        aka: aka || undefined,
        fields: santion.doc.fields,
        images,
        searchTerm: this.searchTerm
      }
    },

    images(doc) {
      let images = []
      if (doc.assets !== undefined) {
        images = doc.assets.filter((field) => field.type === 'picture')
      }
      return images
    },

    associates(aka) {
      let associates = []
      if (aka && aka.length > 0) {
        for (let x = 0; x < aka.length; x++) {
          associates.push(aka[x].name)
        }
      }
      return associates
    },

    sourceNotes(sources, source_notes) {
      let sourceNotes = []
      if (sources.length > 0) {
        for (let x = 0; x < sources.length; x++) {
          if (source_notes[sources[x]]) {
            sourceNotes.push(source_notes[sources[x]].name)
          }
        }
      }
      return sourceNotes
    },

    cleanCountries(countries) {
      let cleanCountries = []
      if (countries.length > 0) {
        for (let z = 0; z < countries.length; z++) {
          cleanCountries.push(countries[z].value)
        }
      }
      return cleanCountries
    },

    dateOfBirthLong(dateOfBirth) {
      let age = ''
      let dob = ''
      if (dateOfBirth.length > 0) {
        age = this.calculateAge(dateOfBirth[0].value)
        dob =
          dateOfBirth[0].value +
          ' (' +
          this.$t('age') +
          ': ' +
          age +
          ' ' +
          this.$t('YearsOld') +
          ')'
      }
      return dob
    },

    arrayTwoDymetions(sanctions) {
      let transformedSanctions = []
      if (sanctions.length > 0) {
        while (sanctions.length) {
          transformedSanctions.push(sanctions.splice(0, 4))
        }
      }
      return transformedSanctions
    },

    calculateAge(birthday) {
      let newDate = new Date(birthday)
      let ageDifMs = Date.now() - newDate.getTime()
      let ageDate = new Date(ageDifMs)
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },

    fuzzynessOptions() {
      const options = []
      for (let x = 10; x <= 100; x += 10) {
        options.push({
          key: x / 100,
          value: `${x}%`
        })
      }
      return options
    }
  }
}
</script>
<style scoped>
.button-max-height {
  max-height: 3.07rem;
}

.details-sanctions-search-title {
  background: #fff;
  width: calc(100% + 30px);
  margin: -30px -15px 30px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.details-sanctions-search-title h4,
.details-sanctions-search-title p {
  margin: 0;
}

.santions-list .m-portlet {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  padding-bottom: 4.2rem;
}

.santions-list .m-portlet .absolute-button {
  position: absolute;
  bottom: 2.2rem;
  left: 2.2rem;
}

.santions-list .santions-list-item {
  margin-bottom: 2.2rem;
}

.text-center {
  text-align: center;
}

@media (max-width: 568px) {
  .santions-list .m-portlet .absolute-button {
    position: relative;
    bottom: -1rem;
    left: 0;
    text-align: center;
  }

  .details-sanctions-search-title {
    flex-direction: column;
  }

  .button-max-height {
    margin: 1rem auto 0;
  }

  .santions-list,
  .santions-list .santions-list-item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 410px) {
  .big-link {
    width: 100%;
    display: block;
    margin-bottom: 0.5rem;
  }
}
</style>
<style>
.santions-list .m-portlet .m-portlet__body {
  padding: 2.2rem;
}

@media (max-width: 568px) {
  .santions-list .m-portlet .m-portlet__body {
    padding: 1rem;
  }
}
</style>
