import html2canvas from 'html2canvas'
import { dateToString, blobToDataUrl } from '@src/scripts/helpers'
import { i18n } from '@src/scripts/resources'
import Api from '@src/scripts/api'
import * as pdfMake from 'pdfmake/build/pdfmake'

async function assignFonts() {
  const Arial = `/${Hash}/static/fonts/Arial.ttf`
  const Fontello = `/${Hash}/static/fonts/fontello.ttf`
  const getArialBlob = await Api.getBlob(Arial)
  const getFontelloBlob = await Api.getBlob(Fontello)
  const getArial = await blobToDataUrl(getArialBlob)
  const getFontello = await blobToDataUrl(getFontelloBlob)

  window.pdfMake.vfs = {
    'Arial.ttf': getArial
      .replace('data:font/ttf;base64,', '')
      .replace('data:application/octet-stream;base64,', ''),
    'fontello.ttf': getFontello
      .replace('data:font/ttf;base64,', '')
      .replace('data:application/octet-stream;base64,', '')
  }
  window.pdfMake.fonts = {
    Arial: {
      normal: 'Arial.ttf',
      bold: 'Arial.ttf',
      italics: 'Arial.ttf',
      bolditalics: 'Arial.ttf'
    },
    Fontello: {
      normal: 'fontello.ttf',
      bold: 'fontello.ttf',
      italics: 'fontello.ttf',
      bolditalics: 'fontello.ttf'
    }
  }
}

export class exportPdf {
  constructor(details, name, table) {
    this.details = details
    this.name = name
    this.table = table
  }

  async exportPdf() {
    await assignFonts()
    if (this.table === 'details') {
      await this.getDetails(this.details)
    } else {
      const imagesList = []
      for (const data of this.details) {
        const array = await this.exportFile(data)
        imagesList.push(array)
      }
      const docDefinition = {
        header: await this.getHeader(),
        defaultStyle: { alignment: 'center', font: 'Arial' },
        pageOrientation: 'landscape',
        content: [imagesList],
        footer: (currentPage, pageCount) =>
          this.getFooterText(currentPage, pageCount)
      }
      pdfMake
        .createPdf(docDefinition)
        .download(`OndatoRequests[${dateToString(new Date(), true)}]`)
    }
  }

  async exportTable() {
    await assignFonts()
    this.getTablePdf(this.details)
  }

  async getPdfColumn(data, textHeader) {
    const chunks = []
    for (let i = 0; i < data.length; i += 2) {
      chunks.push(data.slice(i, i + 2))
    }

    const contentColumns = chunks.map((chunk) => ({
      columns: chunk.map((column) => ({
        width: '*',
        margin: [0, 0, 20, 15],
        unbreakable: true,
        table: {
          headerRows: 1,
          widths: ['100%'],
          body: mapBody(column)
        }
      }))
    }))

    await assignFonts()
    const docTableDefinition = {
      header: await this.getHeader(),
      defaultStyle: { alignment: 'left', color: '#575962', font: 'Arial' },
      styles: {},
      pageMargins: [40, 60, 40, 60],
      content: [
        { text: textHeader.resultTitle },
        textHeader.date && { text: textHeader.date },
        { text: textHeader.matches },
        { text: textHeader.searchedIn },
        { text: textHeader.fuzziness },
        ...contentColumns
      ],
      footer: (currentPage, pageCount) =>
        this.getFooterText(currentPage, pageCount)
    }

    function mapBody(items) {
      const body = []

      body.push([{ text: `${items.fullname}`, color: '#ff5a28' }])

      if (items.types && items.types.length) {
        body.push([
          { text: `${i18n.t('DetailsMatched')} ${formatType(items.types)}` }
        ])
      }

      if (items.match_types && items.match_types.length) {
        body.push([
          {
            text: `${i18n.t('DetailsRelevance')} ${formatType(
              items.match_types
            )}`
          }
        ])
      }

      if (items.associates && items.associates.length) {
        body.push([
          {
            text: `${i18n.t('DetailsAssociates')} ${formatType(
              items.associates
            )}`
          }
        ])
      }

      if (items.countries && items.countries.length) {
        body.push([
          {
            text: `${i18n.t('DetailsCountries')} ${formatType(items.countries)}`
          }
        ])
      }

      return body
    }

    function formatType(types) {
      return types.map((type) => `${i18n.t(type)}`)
    }

    pdfMake
      .createPdf(docTableDefinition)
      .download(`OndatoRequests[${dateToString(new Date(), true)}]`)
  }

  async getPdf(array) {
    const docTableDefinition = {
      header: await this.getHeader(),
      defaultStyle: { alignment: 'center', color: '#575962', font: 'Arial' },
      styles: { icon: { font: 'Fontello' } },
      content: array.map((item) => {
        const itemmm = item.list.filter((list) => list.tr)
        return {
          margin: [10, 10, 10, 10],
          alignment: 'justify',
          table: {
            // heights: [20],
            widths: ['*', '*'],
            body: [
              [
                item.h3.length > 0
                  ? {
                      colSpan: 2,
                      fillColor: '#dddddd',
                      border: [false, false, false, false],
                      margin: [0, 3, 0, 3],
                      alignment: 'center',
                      text: item.h3,
                      pageBreak: item.pageBreak
                    }
                  : {
                      colSpan: 2,
                      border: [false, false, false, false],
                      text: '',
                      alignment: 'center'
                    },
                ''
              ],
              ...item.list.map((list) => {
                if (list.h5)
                  return [
                    {
                      colSpan: 2,
                      fillColor: '#eeeeee',
                      border: [false, false, false, false],
                      margin: [0, 0, 0, 0],
                      alignment: 'center',
                      text: list.h5 || ''
                    },
                    ''
                  ]
                if (list.h4)
                  return [
                    {
                      colSpan: 2,
                      color: '#FF5A28',
                      fontSize: 14,
                      margin: [0, 0, 0, 0],
                      border: [false, false, false, false],
                      text: list.h4 || ''
                    },
                    ''
                  ]
                else
                  return [
                    {
                      colSpan: 2,
                      border: [false, false, false, false],
                      margin: [0, 3, 0, 3],
                      text: list
                    },
                    ''
                  ]
              }),
              itemmm && itemmm.length > 0
                ? [
                    {
                      colSpan: 2,
                      border: [false, false, false, false],
                      fontSize: 10,
                      margin: [0, 0, 0, 0],
                      table: {
                        body: itemmm.map((item) => item.tr)
                      }
                    },
                    ''
                  ]
                : [
                    { border: [false, false, false, false], text: '' },
                    { border: [false, false, false, false], text: '' }
                  ],
              ...item.image.map((img) => img || '')
            ]
          }
        }
      }),
      footer: (currentPage, pageCount) =>
        this.getFooterText(currentPage, pageCount)
    }
    pdfMake
      .createPdf(docTableDefinition)
      .download(`OndatoRequests[${dateToString(new Date(), true)}]`)
  }

  async getDetails(details) {
    const tables = []
    for (let detail of details) {
      if (detail.type !== 'media') {
        if (
          document.querySelector(`${detail.selector} .m-portlet`) ||
          document.querySelector(`${detail.selector}.m-portlet`)
        ) {
          document
            .querySelectorAll(`${detail.selector} .m-portlet`)
            .forEach((item) => {
              tables.push(this.getPortlet(item))
            })
          document
            .querySelectorAll(`${detail.selector}.m-portlet`)
            .forEach((item) => {
              tables.push(this.getPortlet(item))
            })
        } else {
          console.log('...')
          if (document.querySelector(detail.selector)) {
            const table = {
              h3: [],
              list: [document.querySelector(detail.selector).innerText],
              image: []
            }
            tables.push(table)
          }
        }
      } else {
        const image = await this.exportMediaFile(detail)
        const h3 = detail.bigImage
          ? i18n.t('ProofOfAddress')
          : i18n.t('IdentificationMedia')
        const table = { h3, pageBreak: 'before', list: [], image: image }
        tables.push(table)
      }
    }
    this.getPdf(tables)
  }

  getPortlet(item) {
    this.table = { h3: [], list: [], image: [] }
    if (item.querySelector('.m-portlet__head-title'))
      this.table.h3.push(item.querySelector('.m-portlet__head-title').innerText)
    if (item.querySelector('.m-portlet__body')) {
      if (item.querySelector('.content-box')) {
        item.querySelectorAll('.content-box').forEach((status) => {
          if (status.querySelectorAll('.rule-item').length > 0) {
            this.getRuleItem(status)
          } else if (status.querySelectorAll('.status-item'))
            status
              .querySelectorAll('.status-item')
              .forEach((el) =>
                this.table.list.push(el.innerText.replace(/\n/g, ': '))
              )
          if (status.querySelectorAll('.details-box')) {
            if (status.querySelector('.details-box h5')) {
              this.table.list.push({
                h5: status.querySelector('.details-box h5').innerText
              })
            }
            status
              .querySelectorAll('.details-box .details-item')
              .forEach((el) => {
                this.table.list.push(el.innerText.replace(/\n/g, ': '))
              })
          }
        })
      } else if (item.querySelector('.details-box')) {
        item.querySelectorAll('.details-box').forEach((detail) => {
          if (detail.querySelector('h5')) {
            this.table.list.push({ h5: detail.querySelector('h5').innerText })
          }
          detail.querySelectorAll('.details-item').forEach((el) => {
            if (el.querySelector('h4')) {
              this.table.list.push({ h4: el.querySelector('h4').innerText })
              this.table.list.push(
                el.innerText
                  .replace(/\n/g, ' ')
                  .replace(el.querySelector('h4').innerText, '')
              )
            } else {
              this.table.list.push(el.innerText.replace(/\n/g, ': '))
            }
          })
        })
      } else if (item.querySelector('.m-widget1'))
        item
          .querySelectorAll('.m-widget1')
          .forEach((el) => this.table.list.push(el.innerText))
      else {
        this.table.list.push(item.querySelector('.m-portlet__body').innerText)
      }
      if (item.querySelector('.ui-table')) {
        if (item.querySelector('.ui-table').parentElement.querySelector('h5')) {
          this.table.list.push({
            h5: item
              .querySelector('.ui-table')
              .parentElement.querySelector('h5').innerText
          })
        }
        this.tablesText(item.querySelector('.ui-table'))
      }
    } else {
      this.table.list.push(item.querySelector('.m-portlet').innerText)
    }
    return this.table
  }

  tablesText(item) {
    item.querySelectorAll('tr').forEach((el) => {
      const tr = []
      if (el.querySelectorAll('th'))
        el.querySelectorAll('th').forEach((item) => tr.push(item.innerText))
      if (el.querySelectorAll('td'))
        el.querySelectorAll('td').forEach((item) => tr.push(item.innerText))
      this.table.list.push({ tr: tr })
    })
  }

  getRuleItem(status) {
    this.table.list.push({ h5: status.querySelector('h5').innerText })
    status.querySelectorAll('.rule-item').forEach((el) => {
      if (el.querySelector('.flaticon-interface-7'))
        this.table.list.push([{ text: ' ', style: 'icon' }, el.innerText])
      else if (el.querySelector('.la-times-circle'))
        this.table.list.push([{ text: ' ', style: 'icon' }, el.innerText])
      else if (el.querySelector('.flaticon-warning-2'))
        this.table.list.push([{ text: ' ', style: 'icon' }, el.innerText])
      else this.table.list.push(el.innerText)
    })
  }

  async getTablePdf(details) {
    const docTableDefinition = {
      header: await this.getHeader(),
      unbreakable: true,
      pageOrientation: details.pageOrientation
        ? details.pageOrientation
        : 'portrait',
      defaultStyle: { font: 'Arial' },
      widths: ['*', '*', '*', '*', '*', 50],
      content: [
        this.getFilters(details),
        {
          table: {
            body: this.getTableData(details)
          }
        }
      ],
      footer: (currentPage, pageCount) =>
        this.getFooterText(currentPage, pageCount)
    }
    pdfMake
      .createPdf(docTableDefinition)
      .download(`OndatoRequests[${dateToString(new Date(), true)}]`)
  }

  getTableData(details) {
    this.labels = details.tableLabels.filter((item) => item)
    return [
      this.labels.map((item) => {
        return {
          text: item,
          noWrap: false,
          alignment: 'justify',
          fillColor: '#eeeeee'
        }
      }),
      ...details.tableRows
    ]
  }

  getFilters(details) {
    let filter = []
    for (let label in details.filters) {
      if (i18n.t(details.filters[label]))
        filter.push({
          text: `${i18n.t(label)}: ${i18n.t(details.filters[label])}`,
          alignment: 'left'
        })
    }
    this.filters = {
      columns: [
        { text: `${i18n.t('SearchCriteria')}:`, alignment: 'left', width: 100 },
        filter
      ],
      margin: [0, 0, 0, 20]
    }
    return this.filters
  }

  async getHeader() {
    this.logoBlob = await Api.getBlob(require('@src/assets/images/logo.png'))
    const logoImg = await blobToDataUrl(this.logoBlob)
    return {
      image: logoImg,
      fillColor: '#FF5A28;',
      fit: [70, 15],
      margin: [10, 10, 0, 0],
      alignment: 'left'
    }
  }

  getFooterText(currentPage, pageCount) {
    if (currentPage === pageCount)
      return {
        text: `Exported ${dateToString(new Date(), true)} ${this.name}`,
        color: 'gray',
        fontSize: 12
      }
  }

  async getImagesList(details) {
    const imagesList = document.querySelectorAll(details.selector)
    this.images = []
    for (const img of imagesList) {
      const canvasImg = await html2canvas(img, { useCORS: true })
      const image = canvasImg.toDataURL('image/png', 1.0)
      this.images.push(image)
    }
    return this.images
  }

  async getImgList(details) {
    const imagesList = document.querySelectorAll(
      `${details.selector} .image-box`
    )
    const mediaList = document.querySelectorAll(
      `${details.selector} .play-button`
    )
    const img = document.querySelectorAll(`${details.selector} .sanction-image`)
    const images = []
    if (mediaList.length > 0) images.push(...(await this.getImages(mediaList)))
    if (imagesList.length > 0 && details.bigImage)
      images.push(...(await this.getBigImages(imagesList)))
    else if (imagesList.length > 0)
      images.push(...(await this.getImages(imagesList)))
    if (img.length > 0) images.push(...(await this.getImages(img)))
    return images
  }

  async getImages(imagesList) {
    if (imagesList) {
      const images = []
      for (const img of imagesList) {
        // if (img.style.backgroundImage.indexOf('application/octet-stream') >= 0 || img.style.backgroundImage.indexOf('image/jpg') >= 0 || img.style.backgroundImage.indexOf('image/png') >= 0) {
        //   this.image = img.style.backgroundImage.slice(4, -1).replace(/"/g, '')
        //   console.log(this.image)
        // } else {
        const canvasImg = await html2canvas(img, { useCORS: true })
        this.image = canvasImg.toDataURL('image/png', 1.0)
        // }
        images.push(this.image)
      }
      return images
    }
  }

  getBigImages(imagesList) {
    if (imagesList) {
      const images = []
      for (const img of imagesList) {
        if (
          img.style.backgroundImage.indexOf('application/octet-stream') >= 0 ||
          img.style.backgroundImage.indexOf('image/jpeg') >= 0 ||
          img.style.backgroundImage.indexOf('image/jpg') >= 0 ||
          img.style.backgroundImage.indexOf('image/png') >= 0
        ) {
          this.image = img.style.backgroundImage.slice(4, -1).replace(/"/g, '')
        }
        images.push(this.image)
      }
      return images
    }
  }

  async exportShortFile(details) {
    const images = await this.getImagesList(details)
    const columnsCount = Math.floor(700 / details.size[0])
    const margin = (700 - columnsCount * details.size[0]) / columnsCount / 2
    const imagesArray = []
    let i = 0
    images.forEach((img) => {
      imagesArray.push([])
      imagesArray[i].push({
        margin: [margin, 10, margin, 5],
        image: img,
        fit: details.size
      })
      if (i !== columnsCount - 1) i++
      else i = 0
    })
    const data = {
      columns: imagesArray.map((img) => img)
    }
    return data
  }

  async exportMediaFile(details) {
    const images = await this.getImgList(details)
    const rowsCount = Math.ceil(images.length / 2)
    const imagesArray = []
    const fit = details.bigImage ? [500, 450] : [250, 200]
    let i = 0
    if (!details.bigImage) {
      if (images.length % 2 !== 0) images.push('')
      images.forEach((img) => {
        if (imagesArray.length < rowsCount) imagesArray.push([])
        if (img.length > 0)
          imagesArray[i].push({
            image: img,
            fit,
            border: [false, false, false, false]
          })
        else
          imagesArray[i].push({
            text: img,
            border: [false, false, false, false]
          })
        if (i !== rowsCount - 1) i++
        else i = 0
      })
    } else {
      images.forEach((img) => {
        imagesArray.push([])
        if (img)
          imagesArray[i].push(
            {
              colSpan: 2,
              image: img,
              margin: [0, 20, 0, 20],
              fit,
              border: [false, false, false, false]
            },
            ''
          )
        i++
      })
    }
    const data = imagesArray
    return data
  }

  async exportLongFile(details) {
    const content = []
    const imagesList = document.querySelector(details.selector)
    const image = await html2canvas(imagesList, { useCORS: true })
    const imagesWidth = imagesList.offsetWidth
    const imagesHeight = this.getPages(imagesList)
    let startY = 0
    for (let i = 0; i < imagesHeight.length; i++) {
      if (i !== 0) startY += imagesHeight[i - 1]
      else startY = 0
      window.onePageCanvas = document.createElement('canvas')
      window.onePageCanvas.setAttribute('width', imagesWidth)
      window.onePageCanvas.setAttribute('height', imagesHeight[i])
      const ctx = window.onePageCanvas.getContext('2d')
      ctx.drawImage(
        image,
        0,
        startY,
        imagesWidth,
        imagesHeight[i],
        0,
        0,
        imagesWidth,
        imagesHeight[i]
      )
      const canvasDataURL = window.onePageCanvas.toDataURL('image/png', 1.0)
      content.push({ image: canvasDataURL, fit: details.size })
    }
    return content
  }

  async exportFile(details) {
    if (document.querySelector(details.selector).offsetHeight > 811) {
      return await this.exportLongFile(details)
    } else {
      return await this.exportShortFile(details)
    }
  }

  getStyle(selector, value) {
    this.style = window.getComputedStyle(selector, null).getPropertyValue(value)
    return this.style
  }

  getMargin(selector) {
    const getMarginTop = this.getStyle(selector, 'margin-top')
    const getMarginBottom = this.getStyle(selector, 'margin-bottom')
    const margin = parseFloat(getMarginTop) + parseFloat(getMarginBottom)
    return margin
  }

  getSelectorsHeight(selector, pages = []) {
    const pagesHeight = 794
    const getPaddingTop = this.getStyle(selector, 'padding-top')
    const getPaddingBottom = this.getStyle(selector, 'padding-Bottom')
    if (selector.offsetHeight < pagesHeight) {
      pages.push(selector.offsetHeight + this.getMargin(selector))
    } else {
      for (const child of selector.children) {
        if (child.offsetHeight < pagesHeight) {
          pages.push(
            parseFloat(getPaddingTop) +
              child.offsetHeight +
              this.getMargin(child) +
              this.getMargin(child.firstElementChild) +
              parseFloat(getPaddingBottom)
          )
        } else this.getSelectorsHeight(child, pages)
      }
      pages.push(parseFloat(getPaddingBottom))
    }
    return pages
  }

  getPages(selector) {
    const selectorsHeight = this.getSelectorsHeight(selector)
    const heightsArray = []
    let pageHeight = 0
    selectorsHeight.forEach((height) => {
      if (pageHeight < 794) {
        pageHeight = pageHeight + height
      } else {
        heightsArray.push(pageHeight)
        pageHeight = height
      }
    })
    if (pageHeight > 0) {
      heightsArray.push(pageHeight)
      return heightsArray
    }
    return heightsArray
  }
}
