<template>
  <div class="row regional-table">
    <div class="flex-row flex-basis-50">
      <div class="country-desc flex-basis-40">
        <h3>{{ regions.title }}</h3>
        <span v-for="(description, key) in regions.description" :key="key">
          {{ description }}
        </span>
      </div>
      <div class="flex-basis-40 align-center">
        <img :src="require(`@src/assets/images/${regions.imgSrc}`)" />
      </div>
    </div>
    <div class="flex-row flex-basis-50">
      <div class="flex-basis-18">
        <div class="mobile-switch title">
          {{ $t('CheckSettingPassport') }}
        </div>
        <ui-toggle
          :value="regions.passport"
          :disabled="disabled"
          @change="passportChanged"
        />
      </div>
      <div class="flex-basis-18">
        <div class="mobile-switch title">
          {{ $t('CheckSettingId') }}
        </div>
        <ui-toggle
          :value="regions.id"
          :disabled="disabled"
          @change="idChanged"
        />
      </div>
      <div class="flex-basis-18">
        <div class="mobile-switch title">
          {{ $t('CheckSettingDriving') }}
        </div>
        <ui-toggle
          :value="regions.driving"
          :disabled="disabled"
          @change="drivingChanged"
        />
      </div>
      <div class="flex-basis-18">
        <div class="mobile-switch title">
          {{ $t('CheckSettingResidence') }}
        </div>
        <ui-toggle
          :value="regions.residence"
          :disabled="disabled"
          @change="residenceChanged"
        />
      </div>
      <div class="flex-basis-18">
        <div class="mobile-switch title">
          {{ $t('CheckSettingOther') }}
        </div>
        <ui-toggle
          :value="regions.other"
          :disabled="disabled"
          @change="otherChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    regions: { type: Object, default: undefined },
    disabled: Boolean
  },

  data() {
    return {
      details: {
        editEnabled: false
      }
    }
  },

  methods: {
    passportChanged(value) {
      this.$emit('passportChanged', value)
    },

    idChanged(value) {
      this.$emit('idChanged', value)
    },

    drivingChanged(value) {
      this.$emit('drivingChanged', value)
    },

    residenceChanged(value) {
      this.$emit('residenceChanged', value)
    },

    otherChanged(value) {
      this.$emit('otherChanged', value)
    }
  }
}
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-18 {
  flex-basis: 18%;
  padding: 1.3rem 0;
}

.align-center {
  text-align: center;
}

.country-desc {
  min-width: 16.5rem;
}
</style>

<style>
@media (max-width: 800px) {
  .mobile-switch {
    min-height: 3rem;
    padding-right: 1rem;
  }

  .flex-row.flex-basis-50 {
    flex-basis: 100%;
  }
}

@media (max-width: 500px) {
  .flex-basis-40.align-center {
    flex-basis: 55%;
    margin: 1rem 0;
  }

  .country-desc {
    text-align: center;
  }

  .flex-row {
    justify-content: center;
  }
}
</style>
