<template>
  <div class="time-selector">
    <ui-drop-down
      :value="comparedValues.hours"
      :disabled="disabled"
      :nullable="nullable"
      narrow
      center
      @input="hoursChanged"
    >
      <option v-for="item in hours" :key="item.key" :value="item.key">
        {{ item.value }}
      </option>
    </ui-drop-down>
    <span class="separator">:</span>
    <ui-drop-down
      :value="comparedValues.minutes"
      :disabled="disabled"
      :nullable="nullable"
      narrow
      center
      @input="munutesChanged"
    >
      <option v-for="item in minutes" :key="item.key" :value="item.key">
        {{ item.value }}
      </option>
    </ui-drop-down>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: undefined },
    nullable: Boolean,
    disabled: Boolean
  },

  computed: {
    hidden() {
      return !this.nullable
    },

    comparedValues() {
      const { value } = this
      return value || {}
    },

    hours() {
      return Array.from(Array(24), (_, index) => ({
        key: index,
        value: index < 10 ? `0${index}` : index.toString()
      }))
    },

    minutes() {
      return Array.from(Array(12), (_, index) => {
        const minutes = index * 5
        return {
          key: minutes,
          value: minutes < 10 ? `0${minutes}` : minutes.toString()
        }
      })
    }
  },

  methods: {
    hoursChanged(newValue) {
      let returnValue = null

      if (newValue) {
        returnValue = {
          hours: Number.parseInt(newValue),
          minutes: (this.value && this.value.minutes) ?? this.minutes[0].key
        }
      }

      this.$emit('change', returnValue)
    },

    munutesChanged(newValue) {
      let returnValue = null

      if (newValue) {
        returnValue = {
          hours: (this.value && this.value.hours) ?? this.hours[0].key,
          minutes: Number.parseInt(newValue)
        }
      }

      this.$emit('change', returnValue)
    }
  }
}
</script>

<style scoped>
.time-selector {
  display: flex;
  align-items: center;
}

.separator {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
</style>
