<template>
  <div class="details-item">
    <rule-item :name="name" :status="status" />
    <slot />
  </div>
</template>

<script>
import RuleItem from '@src/components/partials/RuleItem'

export default {
  components: {
    RuleItem
  },

  props: {
    name: { type: String, default: undefined },
    status: { type: String, default: undefined }
  }
}
</script>

<style scoped>
.details-item {
  display: flex;
  justify-content: space-between;
  padding: 0 1.05rem;
}

.details-item > .rule-item {
  height: calc(2.95rem + 3px + 0.4rem);
  padding: 1rem 1.15rem;
}

.details-item > .ui-toggle {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
