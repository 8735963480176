var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-container" }, [
    _c(
      "div",
      [
        _c(
          "ui-header",
          {
            attrs: {
              method: _vm.searchMethod,
              filters: _vm.filters,
              "reset-form": "",
              disabled: _vm.isLoading,
              "date-keep-format": "",
            },
            on: {
              startSearch: _vm.startSearch,
              finishSearch: _vm.finishSearch,
            },
          },
          [
            _c("div", { staticClass: "filters-block" }, [
              _c(
                "div",
                { staticClass: "display-flex flex-flow-wrap" },
                [
                  _c("ui-header-dropdown", {
                    attrs: {
                      options: _vm.dateOptions,
                      placeholder: "HeaderDatePlaceHolder",
                      wide: "",
                      disabled: _vm.isLoading,
                    },
                    model: {
                      value: _vm.filters.date,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "date", $$v)
                      },
                      expression: "filters.date",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { slot: "right-side" }, slot: "right-side" }, [
              _c(
                "div",
                { staticClass: "export-btn", on: { click: _vm.exportData } },
                [_vm._v("Export")]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "p-4" },
            [_c("ui-loader", { attrs: { size: 4, center: "" } })],
            1
          )
        : _c("div", [
            _vm.identifications.length
              ? _c(
                  "div",
                  [
                    _c(
                      "dash-board-table",
                      { attrs: { "header-type": "" } },
                      [
                        _c("template", { slot: "table-header" }, [
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("IdentificationDashboard.Time"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { attrs: { scope: "col" } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.type ===
                                    _vm.IDENTIFICATION_DASHBOARD_TABS
                                      .ACTIVE_USERS_TAB
                                    ? _vm.$t("IdentificationDashboard.Company")
                                    : _vm.$t(
                                        "IdentificationDashboard.KycSpecialist"
                                      )
                                ) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "text-center",
                              attrs: { scope: "col" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "IdentificationDashboard.Identification"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "template",
                          { slot: "table-body" },
                          _vm._l(_vm.identifications, function (
                            identification,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.dateToString(
                                        identification.finishDeadlineUtc,
                                        true
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.type ===
                                        _vm.IDENTIFICATION_DASHBOARD_TABS
                                          .ACTIVE_USERS_TAB
                                        ? identification.companyName
                                        : _vm.getUserName(identification)
                                    ) +
                                    "\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-brand",
                                      attrs: { low: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClick(
                                            identification.identificationId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "IdentificationDashboard.Open"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          }),
                          0
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("ui-page-selector", {
                      attrs: {
                        "active-page": _vm.filters.page,
                        "items-count": _vm.itemsCount,
                        "max-on-page": _vm.filters.pageSize,
                        disabled: _vm.isLoading,
                      },
                      on: { change: _vm.pageChanged },
                    }),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center p-4",
                    },
                    [_c("h4", [_vm._v(_vm._s(_vm.$t("Kyb.Search.Empty")))])]
                  ),
                ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }