var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.confirmationDetails
    ? _c(
        "div",
        { staticClass: "user-confirmation" },
        [
          _vm.isSuccess
            ? _c("h2", [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("AdminNewUserSuccessHeader")) +
                    "\n  "
                ),
              ])
            : _c("h2", [
                _vm._v(
                  "\n    " + _vm._s(_vm.$t("AdminNewUserHeader")) + "\n  "
                ),
              ]),
          _vm._v(" "),
          _vm.isCompleted
            ? _c(
                "div",
                { staticClass: "confirmation-box" },
                [
                  _c("p", { class: { "error-message": !_vm.isSuccess } }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$t(_vm.completeMessage, {
                            email: _vm.confirmationDetails.email,
                          })
                        ) +
                        "\n    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ui-button",
                    {
                      staticClass: "btn-brand col-lg-6 mx-auto",
                      attrs: { disabled: _vm.isLoading },
                      on: { click: _vm.redirectToLogin },
                    },
                    [_vm._v("\n      " + _vm._s(_vm.$t("AppLogin")) + "\n    ")]
                  ),
                ],
                1
              )
            : _vm.confirmationDetails.isInvitation
            ? _c(
                "ui-form",
                {
                  staticClass: "confirmation-box",
                  on: { submit: _vm.createUserPassword },
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.$t("AdminInvitationMessage")))]),
                  _vm._v(" "),
                  _c("ui-input", {
                    attrs: {
                      type: "password",
                      label: "DetailsNewPassword",
                      placeholder: "AppPassword",
                      "max-length": 20,
                      "min-length": 8,
                      error: _vm.passwordError,
                      value: _vm.password,
                    },
                    on: { change: _vm.passwordChanged },
                  }),
                  _vm._v(" "),
                  _c(
                    "ui-button",
                    {
                      staticClass: "btn-brand",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$t("AdminCreatePassword")) +
                          "\n    "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }