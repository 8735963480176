var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "document-modal-body" }, [
      _vm.isLoading
        ? _c("div", [_c("ui-loader", { attrs: { size: 2.5, center: "" } })], 1)
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && !_vm.addFileStep
        ? _c(
            "div",
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("DocumentStepOne")))]),
              _vm._v(" "),
              _c("ui-input", {
                staticClass: "fixed-width",
                attrs: { error: _vm.error, value: _vm.value },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading && _vm.addFileStep
        ? _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.$t("DocumentStepTwo")))]),
            _vm._v(" "),
            !_vm.file
              ? _c(
                  "label",
                  {
                    staticClass: "custom-file-upload",
                    on: { change: _vm.handleFileUpload },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$t("DocumentSelectFile")))]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "file",
                      attrs: {
                        type: "file",
                        accept: ".jpg, .jpeg, .png, .gif, .doc, .pdf",
                      },
                      on: { change: _vm.handleFileUpload },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.file
              ? _c("div", [
                  _c("div", { staticClass: "file-name" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: require("@src/assets/images/doc.svg") },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.file.name) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "document-file-status" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("DocumentStatus")) +
                        ": " +
                        _vm._s(_vm.$t("DocumentStatusDone")) +
                        "\n        "
                    ),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "document-modal-footer" },
      [
        !_vm.addFileStep
          ? _c(
              "ui-button",
              {
                staticClass: "btn-brand mx-auto",
                attrs: { disabled: _vm.isLoading || !_vm.value },
                on: { click: _vm.handleSubmit },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("DocumentContinue")) + "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.addFileStep
          ? _c(
              "ui-button",
              {
                staticClass: "btn-brand mx-auto",
                attrs: { disabled: !_vm.file || _vm.isLoading },
                on: { click: _vm.handleFileSubmit },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("DocumentFinish")) + "\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }