export default async () => {
  const state = {
    constraint: null,
    tracks: {
      videoTrack: null,
      audioTrack: null
    },
    cameraSettings: {
      hasCamera: false,
      hasMicrophone: false,
      hasCameraPermissions: false,
      hasMicrophonePermissions: false,
      hasAllowedConstraint: false,
      getMediaTracks,
      stopMediaTracks
    }
  }

  await loadSettings(state.cameraSettings)
  return state.cameraSettings

  async function loadSettings(settings) {
    await checkPermissions(settings)

    if (
      settings.hasCamera &&
      settings.hasMicrophone &&
      (!settings.hasCameraPermissions || !settings.hasMicrophonePermissions) &&
      (await allowMediaPermissions())
    ) {
      settings.hasCameraPermissions = true
      settings.hasMicrophonePermissions = true
    }

    if (
      settings.hasCamera &&
      settings.hasMicrophone &&
      settings.hasCameraPermissions &&
      settings.hasMicrophonePermissions
    ) {
      state.constraint = await getCameraConstraint()
      settings.hasAllowedConstraint = !!state.constraint
    }
  }

  async function checkPermissions(checkResult) {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    if (!mediaDevices || !mediaDevices.length) return checkResult

    for (const device of mediaDevices) {
      if (device.kind === 'videoinput') {
        checkResult.hasCamera = true
        if (device.label) {
          checkResult.hasCameraPermissions = true
        }
      } else if (device.kind === 'audioinput') {
        checkResult.hasMicrophone = true
        if (device.label) {
          checkResult.hasMicrophonePermissions = true
        }
      }

      if (checkResult.hasCamera && checkResult.hasMicrophone) {
        break
      }
    }

    return checkResult
  }

  async function allowMediaPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true
      })

      stopTracks(stream.getVideoTracks())
      stopTracks(stream.getAudioTracks())

      return true
    } catch (error) {
      return false
    }
  }

  async function getCameraConstraint() {
    const availableConstraints = [
      { width: { exact: 1920 }, height: { exact: 1080 } },
      { width: { exact: 1280 }, height: { exact: 720 } },
      { width: { exact: 640 }, height: { exact: 360 } },
      { width: { exact: 640 }, height: { exact: 480 } }
    ]

    for (const constraint of availableConstraints) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: constraint
        })
        stopTracks(stream.getVideoTracks())
        return constraint
      } catch (error) {
        // no need handle
      }
    }

    return undefined
  }

  async function getMediaTracks() {
    const { tracks, constraint } = state
    if (!constraint || (tracks.videoTrack && tracks.audioTrack)) return tracks

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: constraint
      })
      tracks.videoTrack = stream.getVideoTracks()[0]
      tracks.audioTrack = stream.getAudioTracks()[0]
    } catch (error) {
      console.error("Can't access camera device")
    }

    return tracks
  }

  function stopMediaTracks() {
    const { tracks } = state
    if (tracks.videoTrack) {
      tracks.videoTrack.stop()
      tracks.videoTrack = null
    }

    if (tracks.audioTrack) {
      tracks.audioTrack.stop()
      tracks.audioTrack = null
    }
  }

  function stopTracks(trackList) {
    if (!trackList) return
    for (const track of trackList) {
      track.stop()
    }
  }
}
