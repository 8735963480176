<template>
  <div class="template-container">
    <ui-loader v-if="isLoading" :size="4" center />
    <div v-else>
      <h2>{{ $t('DetailsCompanyName') }} {{ getCompanyName.name }}</h2>
      <div>
        <h3 v-if="data.acceptableDocuments.length">
          {{ $t('CompanyProduct.Form.DocumentType.Title') }}
        </h3>
        <ul>
          <li
            v-for="(document, index) in data.acceptableDocuments"
            :key="`document-${index}`"
          >
            <div class="item">
              <div class="check-wrap"><i class="fas fa-check"></i></div>
              <p>
                {{
                  $t(
                    `CompanyProduct.Form.DocumentType.ChosenLabels.${document.documentType}`
                  )
                }}
              </p>
            </div>
            <ul>
              <li
                v-for="(item, itemIndex) in document.items"
                :key="`item-${itemIndex}`"
              >
                {{ item.countryIso2 }}

                <ul class="sub-ul">
                  <li
                    v-for="(rule, ruleIndex) in item.applicableRules"
                    :key="`iso2-${ruleIndex}`"
                    class="sub-item"
                  >
                    {{
                      $t(
                        `CompanyProduct.Form.DocumentType.ChosenLabels.${rule}`
                      )
                    }}
                  </li>
                </ul>

                <p
                  v-if="
                    item.additionalInfo && item.additionalInfo.comment !== ''
                  "
                  class="local-comment"
                >
                  {{ trimText(item.additionalInfo.comment) }}
                </p>
              </li>
              <li
                v-for="(rule, ruleIndex) in document.applicableRules"
                :key="`rule-${ruleIndex}`"
              >
                {{
                  $t(`CompanyProduct.Form.DocumentType.ChosenLabels.${rule}`)
                }}

                <div
                  v-if="
                    rule === APPLICATION_RULE.ONLY_SPECIFIC_COUNTIES_ACCEPTED
                  "
                  class="country-list"
                >
                  <strong
                    v-for="(countries, countiesIndex) in document.countriesIso2"
                    :key="`iso2-${countiesIndex}`"
                    class="country-item"
                  >
                    {{ $t(`Country.${countries}`) }}
                  </strong>
                </div>
              </li>
            </ul>
            <p
              v-if="document.additionalInfo && document.additionalInfo.comment"
              class="additional-registration-rule mr-3"
            >
              {{ trimText(document.additionalInfo.comment) }}
            </p>
          </li>
        </ul>
      </div>

      <div>
        <h3>
          {{ $t('CompanyProduct.Form.SelfieSettings.Title') }}
        </h3>
        <ul>
          <li>
            <div v-for="type in data.selfieTypes" :key="type" class="item">
              <div class="check-wrap"><i class="fas fa-check"></i></div>
              <p>
                {{
                  $t(`CompanyProduct.Form.SelfieSettings.ChosenLabels.${type}`)
                }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="data.importants.length">
        <h3>{{ $t('CompanyProduct.Form.SelectApplication.Title') }}</h3>
        <ul>
          <li
            v-for="(importants, index) in data.importants"
            :key="`rule-${index}`"
          >
            <div class="item">
              <div class="check-wrap"><i class="fas fa-check"></i></div>
              <p>
                {{
                  $t(
                    `CompanyProduct.Form.SelectApplication.ChosenLabels.${importants.rule}`
                  )
                }}
              </p>
            </div>
            <div
              v-if="
                importants.rule ===
                IMPORTANT_RULE.ADDITIONAL_REGISTRATION_VALIDATION_REQUIRED
              "
            >
              <span class="white-space-pre additional-registration-rule">{{
                importants.additionalRegistrationValidationRule
              }}</span>
            </div>
            <div
              v-if="
                importants.rule === IMPORTANT_RULE.HAS_PROHIBITED_COUNTRIES &&
                importants.countriesIso2.length
              "
              class="country-list"
            >
              <strong
                v-for="(countries, countiesIndex) in importants.countriesIso2"
                :key="`iso2-${countiesIndex}`"
                class="country-item"
              >
                {{ $t(`Country.${countries}`) }}
              </strong>
            </div>

            <div
              v-if="
                importants.rule ===
                  IMPORTANT_RULE.HAS_PROHIBITED_NATIONALITIES &&
                importants.countriesIso2.length
              "
              class="country-list"
            >
              <strong
                v-for="(countries, countiesIndex) in importants.countriesIso2"
                :key="`iso2-${countiesIndex}`"
                class="country-item"
              >
                {{ $t(`Country.${countries}`) }}
              </strong>
            </div>
          </li>
        </ul>
      </div>

      <div v-if="data.comment">
        <h3>{{ $t('CompanyProduct.Comment') }}</h3>
        <p class="white-space-pre">{{ data.comment }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { mapGetters } from 'vuex'
import getters from '@src/store/getters'
import { APPLICATION_RULE, IMPORTANT_RULE } from '@src/scripts/enums'
import Store from '@src/store'

export default {
  props: {
    id: {
      type: String,
      default: null
    },
    versionId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    data: {},
    APPLICATION_RULE,
    IMPORTANT_RULE
  }),
  computed: {
    ...mapGetters([getters.companies]),
    getCompanyName() {
      const settings = this.$store.state.settings
      const { selectedCompany } = Store.getters

      return settings.companies.find(
        (company) => selectedCompany === company.id
      )
    }
  },
  watch: {
    versionId: () => this.setData()
  },
  created() {
    this.setData()
  },
  methods: {
    trimText(text) {
      return text.trim()
    },
    async setData() {
      this.data = null
      try {
        this.isLoading = true
        const data = await Api.get(
          `/identification-procedures/${this.id}?versionId=${this.versionId}`
        )
        this.isLoading = false
        this.data = data
      } catch {
        this.isLoading = false
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    }
  }
}
</script>

<style scoped>
.sub-ul {
  padding-left: 1.5rem;
}

.country-list {
  background: var(--light);
  padding: 1rem;
  max-height: 8rem;
  overflow: scroll;
}

.item + .country-list {
  margin-left: 1.538rem;
}

.additional-registration-rule {
  display: block;
  background: var(--light);
  margin-left: 1.538rem;
}

.white-space-pre {
  white-space: pre;
}

.local-comment {
  margin-top: 0.5rem;
  padding: 1rem;
  margin-right: 0;
  display: block;
  background: var(--light);
}

.country-item {
  display: block;
  font-size: 1rem;
  font-weight: 700;
  font-family: sans-serif;
}

.template-container {
  width: 26rem;
}

h2 {
  font-weight: bold;
  font-size: 1.538rem;
  line-height: 1.538rem;
  margin-bottom: 1.154rem;
  color: var(--dark);
}

h3 {
  font-weight: bold;
  font-size: 1.308rem;
  line-height: 1.308rem;
  margin-bottom: 1.308rem;
  color: var(--dark);
}

li,
p {
  color: var(--dark);
  font-size: 1rem;
  margin: 0;
}

ul li {
  margin-bottom: 1rem;
}

ul {
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
}

ul > li .item {
  display: flex;
  align-items: center;
}

.check-wrap {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dark);
  border-radius: 50%;
  margin-right: 0.615rem;
}

li i {
  font-size: 0.615rem;
}

ul > li > ul {
  list-style: disc;
  padding-left: 3rem;
}

ul > li > ul li {
  display: list-item;
}

.mr-3 {
  margin-left: 3rem;
  padding: 1rem;
}
</style>
