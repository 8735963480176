var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scroll-box",
      class: { visible: _vm.visible },
      on: {
        mouseenter: function ($event) {
          _vm.showScroll = true
        },
        mouseleave: function ($event) {
          _vm.showScroll = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "scroll-content",
          class: { "scroll-show": _vm.showScroll },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }