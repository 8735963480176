<template>
  <div class="loader-component">
    <div v-if="!response" class="loader-content">
      <div class="ui-loader-wrapper">
        <ui-loader :size="4" center />
      </div>
      <h5>{{ $t(title) }}</h5>
      <p>{{ $t(description) }}</p>
    </div>
    <div v-else class="response">
      <div><i class="fa fa-check green-icon" /></div>
      <h5>{{ $t(response) }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: undefined },
    description: { type: String, default: undefined },
    response: { type: String, default: undefined }
  }
}
</script>
<style scoped>
.loader-component {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
}

.loader-content {
  margin: auto;
}

.ui-loader-wrapper {
  text-align: left;
  padding-bottom: 2rem;
}

.response {
  margin: auto;
}

.green-icon {
  color: var(--success);
  border: 3px solid var(--success);
  padding: 1rem;
  border-radius: 50%;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
</style>
