<template>
  <li
    class="m-nav__item m-topbar__user-profile m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
    :class="{ 'dropdown-open': isOpened }"
  >
    <a class="m-nav__link m-dropdown__toggle" @click="open">
      <span
        v-if="notOpened || newEvent"
        :class="{ 'm-badge--danger': newEvent }"
        class="m-nav__link-badge m-badge m-badge--dot-small"
      />
      <span class="m-nav__link-icon" :class="{ 'm-topbar__usericon': dark }">
        <span class="m-nav__link-icon-wrapper">
          <i :class="icon" />
        </span>
      </span>
    </a>
    <div
      id="m-dropdown__wrapper"
      class="m-dropdown__wrapper"
      :class="{ 'is-opened-dropdown': isOpened }"
    >
      <span class="m-dropdown__arrow" :style="styles" />
      <div class="m-dropdown__inner">
        <div class="m-dropdown__header m--align-center">
          <slot name="header" />
        </div>
        <div class="m-dropdown__body">
          <slot />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: undefined },
    index: { type: Number, default: undefined },
    dark: Boolean,
    notOpened: Boolean,
    newEvent: Boolean
  },

  data() {
    return {
      isOpened: false
    }
  },

  computed: {
    styles() {
      const { index } = this
      if (index) {
        const rightMargin = 52 * index + 4.5
        return { right: `${rightMargin}px` }
      }
      return null
    }
  },

  methods: {
    clickOutside(e) {
      this.close(e)
    },

    open() {
      document.body.addEventListener('click', this.clickOutside)
    },

    close(e) {
      if (
        e.target.className.indexOf(
          'ui-button btn m-btn dropdown-toggle btn-sm btn-low btn-brand'
        ) !== -1 ||
        e.target.className.indexOf('btn-content') !== -1 ||
        (e.target.className.indexOf('btn-text') !== -1 &&
          e.target.className.indexOf('btn-text-box') === -1)
      ) {
        this.isOpened = true
      } else if (
        document.querySelector('.is-opened-dropdown') &&
        this.isOpened
      ) {
        document.querySelectorAll('#m-dropdown__wrapper').forEach((item) => {
          item.classList.remove('is-opened-dropdown')
        })
        this.isOpened = false
        document.body.removeEventListener('click', this.clickOutside)
      } else {
        this.isOpened = true
      }
    }
  }
}
</script>

<style scoped>
#m-dropdown__wrapper.is-opened-dropdown {
  display: block;
}

.m-nav__item:hover {
  cursor: pointer;
}

.m-dropdown__wrapper {
  z-index: 101;
  cursor: default;
}

.m-nav__item.dropdown-open > .m-dropdown__wrapper {
  display: none;
  -webkit-animation: m-dropdown-fade-in 0.3s ease 1,
    m-dropdown-move-up 0.3s ease-out 1;
  animation: m-dropdown-fade-in 0.3s ease 1, m-dropdown-move-up 0.3s ease-out 1;
}

.m-dropdown__wrapper .m-dropdown__arrow {
  left: auto;
  right: 4.5px;
}

.m-dropdown__wrapper .m-dropdown__body li {
  cursor: initial;
}

@media (max-width: 678px) {
  .m-dropdown__wrapper .m-dropdown__arrow {
    display: none;
  }
}
</style>

<style>
.m-nav__item .m-dropdown__wrapper .m-dropdown__inner {
  max-height: 400px;
  overflow: auto;
}
</style>
