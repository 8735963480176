<template>
  <div class="upload-photo-box">
    <div v-if="!photo" class="border-box">
      <i class="la la-upload upload-icon" />
      <p v-if="description" class="description-text">
        {{ $t(description) }}
      </p>
      <input
        type="file"
        class="input-file"
        accept=".jpg, .jpeg, .png, .gif"
        @change="addPhoto"
      />
    </div>
    <div v-else>
      <div
        :style="{ 'background-image': 'url(' + photo + ')' }"
        class="photo-box"
      >
        <div class="delete-button" @click="$emit('deletePhoto')">x</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    description: { type: String, default: undefined },
    photo: { type: String, default: undefined }
  },

  methods: {
    addPhoto() {
      this.$emit('addPhoto')
    }
  }
}
</script>

<style scoped>
.upload-photo-box {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 2rem;
  margin-top: 1rem;
}

.border-box {
  border: 2px solid var(--dark-br);
  border-radius: 5px;
  margin: 0 auto 1rem;
  max-width: 20rem;
  min-height: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;
}

.border-box:hover {
  border: 2px solid var(--brand);
}

.input-file {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-icon {
  font-size: 2rem;
  color: var(--dark-br);
  text-align: center;
  border: 2px solid var(--dark-br);
  border-radius: 50%;
  padding: 1rem;
  margin-top: 3rem;
  transition: border-color 0.2s, color 0.2s ease-in-out;
}

.border-box:hover .upload-icon {
  color: var(--brand);
  border: 2px solid var(--brand);
}

.description-text {
  color: var(--dark-br);
  margin-top: 1rem;
  transition: color 0.2s ease-in-out;
}

.border-box:hover .description-text {
  color: var(--brand);
}

.photo-box {
  position: relative;
  max-width: 20rem;
  min-height: 12rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: 0 auto 1rem;
  box-shadow: 0 0 5px 0 var(--light-br-h-gray) inset;
}

.delete-button {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--red);
  border-radius: 50%;
  right: -0.5rem;
  top: -0.5rem;
  color: var(--white);
  text-align: center;
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.13rem;
}
</style>
