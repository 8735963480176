var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ui-header",
        {
          attrs: {
            title: "KybCompanySearch",
            method: _vm.searchMethod,
            "method-get": "",
            "no-extra-payload": "",
            filters: _vm.filters,
            disabled: _vm.isLoading,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("ui-header-input", {
            attrs: {
              placeholder: "HeaderCompanyCodePlaceholder",
              icon: "la-institution",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.CompanyCode,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "CompanyCode", $$v)
              },
              expression: "filters.CompanyCode",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: _vm.monitoringEventsDateOptions,
              placeholder: "HeaderDatePlaceHolder",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.date,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "date", $$v)
              },
              expression: "filters.date",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: [
                { key: "", value: "DetailsChangeType" },
                { key: "CompanyName", value: "Kyb.Search.CompanyName" },
                { key: "CompanyStatus", value: "DetailsCompanyStatus" },
                { key: "VatNumber", value: "DetailsVatNumber" },
                { key: "Ceo", value: "DetailsCompanyHead" },
                { key: "Shareholder", value: "Shareholder" },
                { key: "Address", value: "DetailsAddress" },
              ],
              placeholder: "DetailsChangeType",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.ChangeType,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "ChangeType", $$v)
              },
              expression: "filters.ChangeType",
            },
          }),
          _vm._v(" "),
          _c("ui-header-dropdown", {
            attrs: {
              options: [
                { key: "", value: "KybMonitoringAllChanges" },
                { key: "Reviewed", value: "KybMonitoringViewedChanges" },
                { key: "NotReviewed", value: "KybMonitoringNotViewedChanges" },
              ],
              placeholder: "KybMonitoringAllChanges",
              wide: "",
              disabled: _vm.isLoading,
            },
            model: {
              value: _vm.filters.ReviewStatus,
              callback: function ($$v) {
                _vm.$set(_vm.filters, "ReviewStatus", $$v)
              },
              expression: "filters.ReviewStatus",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { icon: "la la-institution" } },
            [
              !_vm.isLoading
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass: "hide-mobile",
                        attrs: {
                          labels: _vm.tableLabels,
                          data: _vm.events,
                          loading: _vm.isLoading,
                          "empty-text": "AppNoEventsFound",
                        },
                      },
                      [
                        _vm.events
                          ? _vm._l(_vm.events, function (row, index) {
                              return _c(
                                "tr",
                                {
                                  key: index,
                                  staticClass: "m-table__row",
                                  class: {
                                    "status-pending":
                                      row.requestStatus === "Pending",
                                    "status-rejected":
                                      row.requestStatus === "Rejected",
                                  },
                                },
                                [
                                  _c("td", [_vm._v(_vm._s(row.companyName))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.companyCode))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.changeType))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.previousValue))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.newValue))]),
                                  _vm._v(" "),
                                  _c("td", [_vm._v(_vm._s(row.localDate))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("ui-toggle", {
                                        attrs: {
                                          value: row.isReviewed,
                                          disabled: row.isReviewed,
                                        },
                                        on: {
                                          change: function (value) {
                                            return _vm.toggleIsViewed(
                                              value,
                                              row.companyCode,
                                              row.eventId
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn-metal",
                                          attrs: {
                                            slot: "tools",
                                            icon: "la la-download",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showStatements(
                                                row.companyCode
                                              )
                                            },
                                          },
                                          slot: "tools",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("AppStatements")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.events
                      ? _vm._l(_vm.events, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsCompanyName",
                                  value: row.companyName,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsCompanyCode",
                                  value: row.companyCode,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsChangeType",
                                  value: row.changeType,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "AppPreviosValue",
                                  value: row.previousValue,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "AppNewValue",
                                  value: row.newValue,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsChangeDate",
                                  value: row.localDate,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10" },
                                [
                                  _c("ui-toggle", {
                                    attrs: {
                                      value: row.isReviewed,
                                      disabled: row.isReviewed,
                                    },
                                    on: {
                                      change: function (value) {
                                        return _vm.toggleIsViewed(
                                          value,
                                          row.companyCode,
                                          row.eventId
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn-metal",
                                      attrs: {
                                        slot: "tools",
                                        icon: "la la-download",
                                        small: "",
                                      },
                                      on: {
                                        click: function () {
                                          _vm.showStatements(row.companyCode)
                                        },
                                      },
                                      slot: "tools",
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("AppStatements")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("AppNoEventsFound")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _c("ui-loader", { attrs: { size: 4, center: "" } }),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.pageSelectorData.page,
                  "items-count": _vm.pageSelectorData.count,
                  "max-on-page": _vm.pageSelectorData.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            [
              _c("kyb-statements", {
                attrs: {
                  "company-code": _vm.companyCode,
                  "no-other-documents": "",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }