<template>
  <div class="group-item">
    <span>{{ name }}</span>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, default: null },
    value: { type: [String, Number], default: null }
  }
}
</script>

<style scoped>
.group-item {
  display: grid;
  grid-template-columns: 25% 1fr;
  grid-gap: 2rem;
  line-height: 1.25;
  padding-right: 1rem;
}

.group-item + .group-item {
  margin-top: 1rem;
}

span {
  display: flex;
  align-items: center;
}
</style>
