var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs-group" }, [
    _c(
      "div",
      { staticClass: "tabs-list" },
      _vm._l(_vm.labels, function (tab, index) {
        return _c(
          "a",
          {
            key: index,
            staticClass: "tab-item",
            class: [{ active: index === _vm.tabIndex }, _vm.indicator(index)],
            on: {
              click: function ($event) {
                return _vm.changeTab(index)
              },
            },
          },
          [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(
                    tab === Object(tab) ? _vm.$t(tab.label) : _vm.$t(tab)
                  ) +
                  "\n        "
              ),
              tab.icon
                ? _c("img", {
                    staticClass: "tab-icon",
                    attrs: {
                      src: require("@src/assets/images/icons/" + tab.icon),
                    },
                  })
                : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }