<template>
  <div class="dashboard-page">
    <ui-header
      title="DashboardTitle"
      :method="searchMethod"
      :filters="filters"
      :disabled="isLoading"
      :auto-refresh="hasRealTimeStatistics"
      :has-body="hasRealTimeStatistics"
      :refresh-interval="300000"
      company-change
      @toggleRefresh="toggleRefresh"
      @startSearch="startSearch"
      @finishSearch="finishSearch"
    >
      <div v-if="hasRealTimeStatistics" class="filters-block">
        <div class="display-flex flex-flow-wrap">
          <ui-header-dropdown
            v-model="filters.date"
            :options="options.dateOptions"
            placeholder="HeaderDatePlaceHolder"
            wide
            :disabled="isLoading"
          />
        </div>
      </div>
    </ui-header>
    <ui-container>
      <div v-if="isLoading">
        <ui-loader :size="4" center />
      </div>
      <div v-if="data && !isLoading" class="dashboard-cards">
        <ui-card title="MainInfo">
          <details-item-orange
            label="AllClients"
            :value="data.totalIdentificationsCount"
          />
          <details-item-orange
            label="NewClients7Day"
            :value="data.newidentificationsCount"
          />
          <details-item-orange
            label="DocQuantityExpirins2Month"
            :value="data.expiringDocsCount"
          />
        </ui-card>
        <dashboard-video-call
          v-if="callsStatistics"
          :get-time="getTime"
          :auto-refresh="enabledAutoRefresh"
          :refresh-interval="30000"
        />
        <ui-card title="ChrossCheckStatistics">
          <details-item-orange
            label="CrosscheckTimeMean"
            :value="getTime(data.crosscheckTimeMean)"
          />
          <details-item-orange
            label="CrosscheckTimeMedian"
            :value="getTime(data.crosscheckTimeMedian)"
          />
          <details-item-orange
            label="LongestCrossCheck7Day"
            :value="getTime(data.crosscheckTimeLongest)"
          />
        </ui-card>
        <ui-card v-if="callsStatistics" title="Dashboard.Call.History.Title">
          <details-item-orange
            label="Dashboard.Call.History.Total"
            :value="callsStatistics.totalCount"
          />
          <details-item-orange
            label="Dashboard.Call.History.Answered"
            :value="callsStatistics.answeredCount"
          />
          <details-item-orange
            label="Dashboard.Call.History.Missed"
            :value="callsStatistics.missedCount"
          />
          <details-item-orange
            label="Dashboard.Call.History.AverageQueueTime"
            :value="getTime(callsStatistics.averageQueueTime)"
          />
          <details-item-orange
            label="Dashboard.Call.History.TwoMinPercentage"
            :value="callsStatistics.twoMinPercentage | percentage"
          />
        </ui-card>
        <ui-card title="RejectedIdentification7Day">
          <div class="orange-big-text">
            {{ data.rejectedIdentificationsCount }}
          </div>
          <details-item-orange
            v-for="(detail,
            key) in data.rejectedIdentificationsReasonsWithCounts"
            :key="key"
            :label="detail.reason"
            :value="detail.count"
          />
        </ui-card>
        <ui-card title="UnfinishedIdentificationAmount7day">
          <div class="orange-big-text">
            {{ data.unfinishedIdentificationsTotalCount }}
          </div>
          <details-item-orange
            label="UnfinishedIdentificationNoFiles"
            :value="data.unfinishedIdentificationsWithouthAnyFilesCount"
          />
          <details-item-orange
            label="UnfinishedIdentificationOnlyFace"
            :value="data.unfinishedIdentificationsWithOnlyFaceCount"
          />
          <details-item-orange
            label="UnfinishedIdentificationFaceAndDoc"
            :value="data.unfinishedIdentificationsWithFaceAndDocumentCount"
          />
        </ui-card>
      </div>
    </ui-container>
  </div>
</template>

<script>
import DetailsItemOrange from '@src/components/partials/DetailsItemOrange'
import DashboardVideoCall from '@src/components/partials/DashboardVideoCall'
import { createDateFilters } from '@src/scripts/helpers'

export default {
  components: {
    DashboardVideoCall,
    DetailsItemOrange
  },

  filters: {
    percentage(value) {
      if (value == null) return '-'
      return `${value.toFixed(2)} %`
    }
  },

  data() {
    return {
      isLoading: true,
      searchMethod: '/dashboard',
      data: null,
      filters: {
        date: null
      },
      options: {
        dateOptions: null
      },
      enabledAutoRefresh: false
    }
  },

  computed: {
    companyId() {
      const { user } = this.$store.getters
      if (!user) return null
      return user.selectedCompanyId || null
    },

    callsStatistics() {
      return this.data?.callsStatistics
    },

    hasRealTimeStatistics() {
      return !!this.callsStatistics
    }
  },

  created() {
    this.createFilters()
  },

  methods: {
    createFilters() {
      const { filters, options } = this
      options.dateOptions = createDateFilters({ lastHour: true })
      if (options.dateOptions.length) {
        filters.date = options.dateOptions[0].key
      }
    },

    startSearch() {
      this.isLoading = true
    },

    finishSearch(data) {
      this.data = data
      this.isLoading = false
    },

    toggleRefresh(value) {
      this.enabledAutoRefresh = value
    },

    getTime(time) {
      if (!time) return '-'

      const dayInSeconds = 86400
      const daysCount = Math.floor(time / dayInSeconds)
      if (daysCount === 1) return `1 ${this.$t('App.Time.Day')}`
      else if (daysCount > 1) return `${daysCount} ${this.$t('App.Time.Days')}`

      const timeParts = []
      let quotient = Math.floor(time)
      let remainder

      do {
        remainder = quotient % 60
        quotient = Math.floor(quotient / 60)

        timeParts.unshift(remainder)

        if (quotient) {
          if (remainder < 10) timeParts.unshift('0')
          timeParts.unshift(':')
        }
      } while (quotient)

      let formatedTime = timeParts.join('')
      if (formatedTime.length > 2) return formatedTime

      if (formatedTime.length === 1) {
        return `0:0${formatedTime}`
      } else {
        return `0:${formatedTime}`
      }
    }
  }
}
</script>

<style scoped>
.dashboard-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.dashboard-cards > * {
  width: 48%;
}

.orange-big-text {
  color: var(--brand);
  font-size: 4rem;
  text-align: center;
  font-weight: bold;
}

@media all and (max-width: 767px) {
  .dashboard-cards > * {
    width: 100%;
  }
}
</style>
