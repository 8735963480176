var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-topbar m-stack m-stack--ver m-stack--general" },
    [
      _c("div", { staticClass: "m-stack__item m-topbar__nav-wrapper" }, [
        _c(
          "ul",
          { staticClass: "m-topbar__nav m-nav m-nav--inline nav-reverse" },
          [
            _c("item-selector", {
              attrs: {
                placeholder: _vm.$t("AppSelectCompany"),
                value: _vm.companyDetails.id,
                options: _vm.companies,
                index: 1,
              },
              on: { change: _vm.companyChanged },
            }),
            _vm._v(" "),
            _c("drop-down", { attrs: { icon: "flaticon-user-ok", dark: "" } }, [
              _c(
                "div",
                {
                  staticClass: "m-card-user m-card-user--skin-light",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("div", { staticClass: "m-card-user__pic" }, [
                    _vm.companyDetails.logoUrl
                      ? _c("div", {
                          staticClass: "companies-logo",
                          style: _vm.companiesLogo,
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "m-card-user__details" },
                    [
                      _c("span", { staticClass: "m-card-user__name" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.companyDetails.name) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.user.firstName || _vm.user.lastName
                        ? _c("span", { staticClass: "user-name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.firstName) +
                                " " +
                                _vm._s(_vm.user.lastName) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "user-name" }, [
                        _vm.user.email
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "m-card-user__email m-link user-name",
                                attrs: {
                                  href:
                                    "mailto:" + _vm.user.email + "?Subject=",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.user.email) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "user-name" }, [
                        _vm.user.phone
                          ? _c(
                              "a",
                              {
                                staticClass:
                                  "m-card-user__email m-link user-name",
                                attrs: { href: "tel:" + _vm.user.phone },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.user.phone) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm.user.position
                        ? _c("span", { staticClass: "user-name" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.user.position) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("ui-dropdown", {
                        staticClass: "m--margin-top-10 language-options",
                        attrs: {
                          brand: "",
                          low: "",
                          "small-text": "",
                          small: "",
                          placeholder: _vm.$t("changeLang"),
                          options: _vm.languageOptions,
                          value: _vm.currentLang,
                        },
                        on: { input: _vm.languageValueChanged },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "m-dropdown__content" }, [
                _c("ul", { staticClass: "m-nav m-nav--skin-light" }, [
                  _c("li", {
                    staticClass: "m-nav__separator m-nav__separator--fit",
                  }),
                  _vm._v(" "),
                  _c("li", { staticClass: "m-nav__item" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder",
                        on: { click: _vm.signOutHandler },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("AppLogOut")) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("notifications-bar", { attrs: { icon: "fa fa-info" } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }