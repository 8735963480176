var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-item" }, [
    _c("strong", [_vm._v(_vm._s(_vm.label.date))]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm._f("date")(_vm.data.createdUtc)))]),
    _vm._v(" "),
    _c("strong", [_vm._v(_vm._s(_vm.label.legalEntity.name))]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.data.legalEntity.name))]),
    _vm._v(" "),
    _c("strong", { staticClass: "center" }, [
      _vm._v(_vm._s(_vm.label.legalEntity.code)),
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "center" }, [
      _vm._v(_vm._s(_vm.data.legalEntity.code)),
    ]),
    _vm._v(" "),
    _c("strong", { staticClass: "center" }, [_vm._v(_vm._s(_vm.label.status))]),
    _vm._v(" "),
    _c("span", { staticClass: "center" }, [_vm._v(_vm._s(_vm.data.status))]),
    _vm._v(" "),
    _c("strong", { staticClass: "center" }, [_vm._v(_vm._s(_vm.label.count))]),
    _vm._v(" "),
    _c("span", { staticClass: "center" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.data.representativeCount) +
          " / " +
          _vm._s(_vm.data.verifiedRepresentativeCount) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        !_vm.noAction
          ? _c(
              "ui-link",
              {
                staticClass: "btn-brand big-link",
                attrs: {
                  to: {
                    name: "KybIdentificationsDetails",
                    query: { id: _vm.data.id },
                  },
                  "small-text": "",
                },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("TableView")) + "\n    ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }