<template>
  <label
    class="m--font-brand m-checkbox--state-brand m-checkbox--margin-right-15 m-checkbox m-checkbox--square ui-checkbox"
    :class="{
      'm-checkbox--disabled': disabled
    }"
  >
    <input
      type="checkbox"
      :class="{ checked: isChecked }"
      :disabled="disabled"
      :value="value"
      :checked="isChecked"
      @input="updateInput"
    />
    <div>{{ $t(label) }}</div>
    <span />
  </label>
</template>

<script>
export default {
  name: 'ui-new-checkbox',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    value: { type: String, default: '' },
    disabled: Boolean,
    modelValue: { type: [String, Array], default: '' },
    label: { type: String, required: true },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false }
  },
  computed: {
    isChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      }
      return this.modelValue === this.trueValue
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          newValue.splice(newValue.indexOf(this.value), 1)
        }
        this.$emit('change', newValue)
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
      }
    }
  }
}
</script>

<style scoped>
.ui-checkbox {
  margin-top: 0.538rem;
  margin-bottom: 0.538rem;
}

.ui-checkbox > * {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.ui-checkbox > span {
  top: 0;
  bottom: 0;
  margin-top: 1px;
}

.ui-checkbox > div {
  padding-top: 2px;
  line-height: 18px;
}

.m-checkbox--margin-right-15 {
  margin-right: 15px;
}

.ui-checkbox > input:checked ~ span::after {
  display: none;
}

.ui-checkbox > input.checked ~ span::after {
  display: block;
}

.m-checkbox.m-checkbox--state-brand.m-checkbox--header-style > input ~ span {
  background: transparent;
}

.m-checkbox.m-checkbox--state-brand.m-checkbox--header-style
  > input:checked
  ~ span {
  border-color: var(--white);
}
</style>
