<template>
  <div v-if="data" class="details">
    <div class="actions">
      <ui-button class="btn-danger" :disabled="disabled" small @click="reject">
        {{ $t('App.Reject') }}
      </ui-button>
      <ui-button
        class="btn-success"
        :disabled="disabled"
        small
        @click="approve"
      >
        {{ $t('App.Approve') }}
      </ui-button>
      <ui-dropdown
        :options="exportOptions"
        placeholder="AppExport"
        class="margin-right"
        small
        metal
        @input="exportList"
      />
    </div>
    <details-box>
      <details-item
        :label="$t('Kyb.Company.Status')"
        :value="$t(`Kyb.ReviewStatuses.${data.status}`)"
      />
      <details-item
        :label="$t('Kyb.Company.Date')"
        :value="data.createdUtc | date"
      />
      <details-item :label="$t('Kyb.Company.Id')" :value="data.id" />
    </details-box>
    <div class="company-row">
      <div class="column">
        <client-form
          v-if="renderForm && !data.isIdvKybFlow"
          :id="data.verificationFormId.replaceAll('-', '')"
          class="client-form"
          :type="registrationForm.type"
          :data="registrationForm.data"
          :setup="registrationForm.setup"
          high
        />
        <client-form-kyb
          v-if="renderForm && data.isIdvKybFlow"
          class="client-form"
          :access-token="data.idvFormApiAccessToken"
          :form-ids="data.idvFormIds"
        />
      </div>
      <div class="column document-wrapper">
        <div class="document-container">
          <documents @canApprove="setDisable" />
        </div>
      </div>
    </div>
    <activity-logs
      class="activity-logs"
      :data="activityLogsUi"
      :loading="isLogsLoading"
    />
  </div>
</template>

<script>
import DetailsBox from '@src/components/partials/DetailsBox'
import DetailsItem from '@src/components/partials/DetailsItem'
import ClientForm from '@src/components/partials/refactor/layouts/ClientForm'
import ClientFormKyb from '@src/components/partials/refactor/layouts/ClientFormKyb.vue'
import ActivityLogs from '@src/components/partials/refactor/layouts/ActivityLogs'
import Api from '@src/scripts/api'
import {
  dateFilter,
  dateToString,
  downloadBlob,
  downloadBlobs
} from '@src/scripts/helpers'
import { mapOptions } from '@src/scripts/helpers'
import Documents from '@src/components/partials/refactor/partials/kyb/Documents'
import { notificationType } from '@src/components/notification'

export default {
  filters: {
    date(value) {
      return dateFilter(value)
    }
  },

  components: {
    Documents,
    DetailsBox,
    DetailsItem,
    ClientForm,
    ActivityLogs,
    ClientFormKyb
  },

  props: {
    data: { type: Object, default: null },
    loading: Boolean
  },

  data() {
    return {
      isLoading: false,
      isLogsLoading: false,
      activityLogs: null,
      needApprove: 0,
      renderForm: true
    }
  },

  computed: {
    exportOptions() {
      const array = ['FORM']
      const {
        esignatureFileUrl,
        isIdvKybFlow,
        idvDocumentSignatureIds
      } = this.data

      if (
        esignatureFileUrl ||
        (isIdvKybFlow && idvDocumentSignatureIds.length > 0)
      )
        array.push('SIGNED')

      return mapOptions(array)
    },
    disabled() {
      return this.isLoading || this.loading
    },

    registrationForm() {
      const { registrationForm, formSetting } = this.data
      const model = JSON.parse(registrationForm)

      return {
        type: model.formType,
        data: model.customData,
        setup: formSetting
      }
    },

    language() {
      return localStorage.getItem('language') || 'en'
    },

    activityLogsUi() {
      return this.activityLogs?.map(
        ({ name, date, email, publisher, isOndatoUser, userId }) => [
          isOndatoUser
            ? `${this.$t(
                `Enum.IdentificationLogs.OndatoSpecialist.${name}`
              )} (${userId})`
            : this.$t(`Enum.IdentificationLogs.${name}`),
          this.getPublisher(publisher),
          !isOndatoUser ? email : '',
          dateToString(date, true)
        ]
      )
    }
  },

  watch: {
    '$i18n.locale'() {
      this.forceRerender()
    }
  },

  created() {
    this.loadActivityLogs()
  },

  methods: {
    forceRerender() {
      this.renderForm = false
      this.$nextTick(() => {
        this.renderForm = true
      })
    },

    setDisable(value) {
      this.needApprove = !!value
    },
    async exportList(value) {
      switch (value) {
        case 'FORM': {
          await this.exportForm()
          break
        }
        case 'SIGNED': {
          await this.exportSignedKyb()
          break
        }
        default: {
          break
        }
      }
    },

    approve() {
      const { id } = this.data
      this.makeAction(`/kybidentifications/${id}/approve`)
    },

    reject() {
      const { id } = this.data
      this.makeAction(`/kybidentifications/${id}/reject`)
    },

    async loadActivityLogs() {
      const { id } = this.data
      if (!id) return false

      this.isLogsLoading = true

      try {
        const { activityLogs } = await Api.get(
          `/kybidentifications/${id}/activity-logs`
        )
        if (!activityLogs) return false

        this.activityLogs = activityLogs
        this.isLogsLoading = false

        return true
      } catch (error) {
        this.notifyError()
        this.isLogsLoading = false

        return false
      }
    },

    async makeAction(method) {
      this.isLoading = true
      try {
        await Api.put(method, {})
        this.$emit('update')

        this.$notify({
          type: notificationType.info,
          title: this.$t('AppSuccess'),
          message: this.$t('AppActionWasSuccessful')
        })
      } catch (e) {
        this.$notify({
          title: this.$t('Error.Fail'),
          message: this.$t('AppDocumentError')
        })
      }

      this.isLoading = false
    },

    getFileName() {
      return `OndatoBusinessRequests[${dateToString(new Date(), true)}]`
    },

    async exportKybForms() {
      const {
        language,
        data: { idvFormIds }
      } = this

      const contentItems = await Promise.all(
        idvFormIds.map(async (formId) => {
          const blob = await Api.getBaseUrlBlob(
            `/kybidentifications/forms/${formId}/report/${language}`
          )

          return blob
        })
      )

      await downloadBlobs(
        contentItems,
        (index) => {
          if (typeof index !== 'undefined') {
            return `${this.getFileName()}-${index}.pdf`
          }

          return `${this.getFileName()}.pdf`
        },
        `${this.getFileName()}.zip`
      )
    },

    async exportOldForm() {
      const {
        language,
        data: { id }
      } = this

      const pdfName = `${this.getFileName()}.pdf`

      const blob = await Api.getBaseUrlBlob(
        `/kybidentifications/${id}/verificationForm/report/${language}`
      )

      downloadBlob({ file: blob, fileName: pdfName })
    },

    async exportForm() {
      const {
        data: { isIdvKybFlow }
      } = this

      this.isLoading = true

      try {
        if (isIdvKybFlow) {
          return await this.exportKybForms()
        }

        return await this.exportOldForm()
      } catch (error) {
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      } finally {
        this.isLoading = false
      }
    },

    async exportSignedKyb() {
      const {
        data: { isIdvKybFlow, idvDocumentSignatureIds, esignatureFileUrl }
      } = this

      if (!isIdvKybFlow) {
        const url = esignatureFileUrl
        if (url) window.location.href = url
        return
      }

      const contentItems = await Promise.all(
        idvDocumentSignatureIds.map(async (formId) => {
          const blob = await Api.getBaseUrlBlob(
            `/kybidentifications/document-signatures/${formId}/signed-document`
          )

          return blob
        })
      )

      await downloadBlobs(
        contentItems,
        (index) => {
          if (typeof index !== 'undefined') {
            return `${this.getFileName()}-${index}.pdf`
          }

          return `${this.getFileName()}.pdf`
        },
        `${this.getFileName()}.zip`
      )
    },

    getPublisher(name) {
      if (name === 'BoApi') return 'Backoffice'
      if (name === 'ArbitriumApi') return 'Arbitrium'
      if (name === 'IdvApi') return 'Idv'

      return name
    }
  }
}
</script>

<style scoped>
.details {
  display: block;
}

.document-container {
  height: 80vh;
  overflow: scroll;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.actions > button + button {
  margin-left: 1rem;
  margin-right: 1rem;
}

.client-form,
.activity-logs,
.document-wrapper {
  margin-top: 3rem;
}

.company-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 100%;
}
</style>
