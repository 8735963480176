<template>
  <div
    class="header-input m-input-icon m-input-icon--right"
    :class="
      wide
        ? 'm-input-icon--wide'
        : medium
        ? 'm-input-icon--medium'
        : 'm-input-icon--fixed'
    "
  >
    <ui-input
      :class="{ 'm-input-date': type === 'date' }"
      small-text
      :icon="icon"
      :type="type"
      :value="value"
      :disabled="disabled"
      one-line
      :placeholder="placeholder"
      @input="$emit('input', $event)"
    />
    <span v-if="icon" class="input-icon">
      <i class="la" :class="icon" />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ui-header-input',

  props: {
    value: { type: String, default: undefined },
    type: { type: String, default: 'text' },
    placeholder: { type: String, default: undefined },
    icon: { type: String, default: undefined },
    onfocus: { type: String, default: undefined },
    wide: Boolean,
    medium: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
.header-input .m-input-date {
  padding: 0.84rem 1.5rem;
}

.input-icon {
  width: 3.2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
}

.input-icon > i {
  font-size: 1.125rem;
  margin: auto;
  display: block;
  color: var(--white);
}
</style>

<style>
.header-input textarea {
  max-height: 42px;
}

.header-input .ui-input.form-group.m-form__group {
  padding: 0;
}

.header-input input:disabled,
.header-input textarea:disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
</style>
