<template>
  <div class="documents-container">
    <div class="documents-header">
      <h3>{{ $t('DocumentTitle') }}</h3>
      <ui-button class="btn-brand" @click="handleFile">
        {{ $t('DocumentAddFile') }}
      </ui-button>
    </div>
    <div class="documents-body" @reloadDocument="handleReload">
      <div v-if="isLoading"><ui-loader :size="2.5" center /></div>
      <div v-if="!isLoading">
        <div v-for="(documentItem, index) in documentList" :key="index">
          <DocumentItem
            :id="documentItem.id"
            :application-id="documentItem.applicationId"
            :created-utc="documentItem.createdUtc"
            :has-file="documentItem.hasFile"
            :identification-id="documentItem.identificationId"
            :last-action-utc="documentItem.lastActionUtc"
            :title="documentItem.title"
            :status="documentItem.status"
            :purpose="documentItem.purpose"
            @openDocumentModal="openDocumentModal(documentItem.id)"
            @reloadDocument="handleReload"
          />
        </div>
      </div>
      <div v-if="!documentList.length" class="warning">
        <div class="warning-message">{{ $t('DocumentErrorEmpty') }}</div>
      </div>
    </div>

    <pop-up :pop-up-title="$t('DocumentModalTitle')" @close="handleReload">
      <DocumentModal
        :id="$route.query.id"
        :modal-props="modalProps"
        @reloadDocument="handleReload"
      />
    </pop-up>
  </div>
</template>

<script>
import DocumentModal from '@src/components/partials/refactor/partials/kyb/DocumentModal'
import DocumentItem from '@src/components/partials/refactor/partials/kyb/DocumentItem'
import Api from '@src/scripts/api'

export default {
  components: { DocumentModal, DocumentItem },
  data: () => ({
    documentList: [],
    isLoading: false,
    modalProps: null,
    needReload: false
  }),
  watch: {
    needReload(values) {
      this.modalProps = null
      if (values) {
        this.needReload = false
        this.getList()
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleReload() {
      this.needReload = true
    },
    async getList() {
      try {
        this.isLoading = true
        this.documentList = await Api.post('/kyb-documents/filter', {
          ids: [],
          identificationIds: [this.$route.query.id]
        })

        this.$emit(
          'canApprove',
          this.documentList.filter(({ status }) => status === 'NeedReview')
            .length
        )
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.$notify({
          title: 'ErrorUnknownTitle',
          message: 'ErrorUnknownDescription'
        })
      }
    },
    openDocumentModal(id) {
      this.modalProps = {
        documentId: id,
        addFileStep: true
      }
      this.$openPopUp()
    },
    handleFile() {
      this.$openPopUp()
    }
  }
}
</script>

<style scoped>
.documents-container {
  padding: 2rem;
}

.documents-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8eaf0;
  padding-bottom: 5px;
}

.warning {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.warning-message {
  background: #f4516c;
  color: #fff;
  padding: 4px 20px;
  border-radius: 37px;
}

h3 {
  color: #575962;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
/deep/ .pop-up-header {
  padding: 0;
}

/deep/ .pop-up-header div {
  font-family: 'metropolisbold', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
</style>
