<template>
  <div class="group">
    <ui-loader v-if="loading" center />
    <div v-else-if="error" class="error">
      <b>{{ $t('RegistryHubSeizures.loadError') }}</b>
    </div>
    <div v-else>
      <b>{{ $t('RegistryHubSeizures.seizuresSummary') }}:</b>
      <div class="group">
        <div v-for="(value, key) in seizuresSummary" :key="key">
          <b>{{ $t(`RegistryHubSeizures.${key}`) }}:</b> {{ value }}
        </div>
      </div>

      <b>{{ $t('RegistryHubSeizures.seizuresDetails') }}:</b>
      <div v-if="!hasSeizures" class="group">
        <b>{{ $t('RegistryHubSeizures.noSeizures') }}</b>
      </div>
      <div
        v-for="({ isActive, ...detail }, index) in seizuresDetails"
        :key="index"
        class="group"
      >
        <div v-for="(value, key) in detail" :key="key">
          <b>{{ $t(`RegistryHubSeizures.${key}`) }}:</b> {{ value }}
        </div>
        <div>
          <b>{{ $t(`RegistryHubSeizures.isActive`) }}:</b>
          {{
            isActive
              ? $t(`RegistryHubSeizures.isActiveYes`)
              : $t(`RegistryHubSeizures.isActiveNo`)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@src/scripts/api'
import { dateToString } from '@src/scripts/helpers'

export default {
  props: {
    companyCode: { type: String, required: true }
  },
  data() {
    return {
      seizures: null,
      loading: false,
      error: null
    }
  },
  computed: {
    seizuresSummary() {
      const summary = this.seizures?.seizuresSummary
      return {
        ...summary,
        lastUnregistrationDate: dateToString(summary?.lastUnregistrationDate)
      }
    },
    seizuresDetails() {
      return this.seizures?.seizuresDetails?.map(
        ({ registrationDate, unregistrationDate, ...detail }) => ({
          ...detail,
          registrationDate: dateToString(registrationDate),
          unregistrationDate: dateToString(unregistrationDate)
        })
      )
    },
    hasSeizures() {
      return !!this.seizuresDetails?.length
    }
  },
  watch: {
    companyCode: {
      handler() {
        this.getSeizuresData()
      },
      immediate: true
    }
  },
  methods: {
    async getSeizuresData() {
      this.loading = true
      this.seizures = null
      this.error = null
      try {
        const data = await Api.get(
          `/data-hub/companies/${this.companyCode}/seizures`
        )
        this.seizures = data?.data?.seizures
      } catch (error) {
        this.error = error.message
        this.$notify({
          title: 'RegistryHubSeizures.loadError',
          message: error.message
        })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.group {
  margin: 1rem 0 1rem 1rem;
}
.error {
  color: var(--danger);
}
</style>
