var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "m-nav__item m-topbar__user-profile m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light",
      class: { "dropdown-open": _vm.isOpened },
    },
    [
      _c(
        "a",
        {
          staticClass: "m-nav__link m-dropdown__toggle",
          on: { click: _vm.open },
        },
        [
          _vm.notOpened || _vm.newEvent
            ? _c("span", {
                staticClass: "m-nav__link-badge m-badge m-badge--dot-small",
                class: { "m-badge--danger": _vm.newEvent },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "m-nav__link-icon",
              class: { "m-topbar__usericon": _vm.dark },
            },
            [
              _c("span", { staticClass: "m-nav__link-icon-wrapper" }, [
                _c("i", { class: _vm.icon }),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "m-dropdown__wrapper",
          class: { "is-opened-dropdown": _vm.isOpened },
          attrs: { id: "m-dropdown__wrapper" },
        },
        [
          _c("span", { staticClass: "m-dropdown__arrow", style: _vm.styles }),
          _vm._v(" "),
          _c("div", { staticClass: "m-dropdown__inner" }, [
            _c(
              "div",
              { staticClass: "m-dropdown__header m--align-center" },
              [_vm._t("header")],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "m-dropdown__body" },
              [_vm._t("default")],
              2
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }