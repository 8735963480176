var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c("div", { staticClass: "results-block identification-photo" }, [
        _c("div", { staticClass: "media-box" }, [
          _c("video", {
            ref: "videoBox",
            attrs: { preload: "", muted: "", controls: "", src: _vm.src },
            domProps: { muted: true },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }