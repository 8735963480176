var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ui-card",
    { attrs: { title: "SharableMeetingWhitelist" } },
    [
      _vm._l(_vm.whitelist, function (ref) {
        var id = ref.id
        var personalCode = ref.personalCode
        var isSaving = ref.isSaving
        return _c(
          "div",
          { key: id, staticClass: "item" },
          [
            isSaving
              ? _c("ui-loader", { attrs: { center: "", size: 1 } })
              : _c(
                  "button",
                  {
                    staticClass: "remove",
                    on: {
                      click: function ($event) {
                        return _vm.remove(id)
                      },
                    },
                  },
                  [_vm._v("−")]
                ),
            _vm._v(" "),
            _c("ui-input", {
              attrs: { id: id, value: personalCode },
              on: {
                input: function ($event) {
                  return _vm.onInput({ id: id, personalCode: $event })
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.lastSave
        ? _c("div", { staticClass: "last-save" }, [
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm.$t("SharableMeetingSavedAt", { time: _vm.lastSave })
                ) +
                "\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          ref: "newPersonalCodeForm",
          staticClass: "add-new-form",
          attrs: { slot: "footer" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.addNew($event)
            },
          },
          slot: "footer",
        },
        [
          _c("ui-input", {
            model: {
              value: _vm.newPersonalCode,
              callback: function ($$v) {
                _vm.newPersonalCode = $$v
              },
              expression: "newPersonalCode",
            },
          }),
          _vm._v(" "),
          _c(
            "ui-button",
            { staticClass: "btn-metal", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("SharableMeetingWhitelistAddNew")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }