<template>
  <ui-card title="SharableMeetingUrl">
    <div class="url-item">
      <ui-loader v-if="isSaving" :size="1" center />
      <div v-else />
      <ui-input :value="redirectUrl" @input="onInput" />
    </div>
    <div v-if="lastSave" class="last-save">
      {{ $t('SharableMeetingSavedAt', { time: lastSave }) }}
    </div>
  </ui-card>
</template>

<script>
export default {
  props: {
    redirectUrl: { type: String, required: true },
    isSaving: { type: Boolean, required: true },
    lastSave: { type: String, default: null }
  },
  methods: {
    onInput(event) {
      this.$emit('redirectUrlUpdate', event)
    }
  }
}
</script>

<style scoped>
.url-item {
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
}
.last-save {
  margin-top: 1rem;
  margin-left: 2rem;
}
</style>
