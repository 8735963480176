var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "batch-list-modal" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("KybMonitoringBatchUploadTitle")))]),
      _vm._v(" "),
      _c("p", { staticClass: "fixed-width" }, [
        _c("b", [_vm._v(_vm._s(_vm.$t("KybMonitoringBatchUploadStep1")))]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "batch-list-radio-container" },
        [
          _c("ui-radio", {
            attrs: {
              label: _vm.$t("KybMonitoringBatchUploadAdd"),
              value: true,
              name: "batchUploadDeleteRadio",
              checked: "",
            },
            on: { checkRadio: _vm.handleToggleChange },
          }),
          _vm._v(" "),
          _c("ui-radio", {
            attrs: {
              label: _vm.$t("KybMonitoringBatchUploadRemove"),
              value: false,
              name: "batchUploadDeleteRadio",
            },
            on: { checkRadio: _vm.handleToggleChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("p", { staticClass: "fixed-width" }, [
        _c("b", [_vm._v(_vm._s(_vm.$t("KybMonitoringBatchUploadStep2")))]),
      ]),
      _vm._v(" "),
      _c("ui-input", {
        staticClass: "codes fixed-width",
        attrs: {
          placeholder: "10000000 \n20000000 \n30000000",
          rows: 10,
          value: _vm.companyCodesStr,
          multiline: "",
          "multiline-overflow": "",
        },
        on: { input: _vm.handleInputChange },
      }),
      _vm._v(" "),
      _c(
        "ui-button",
        {
          staticClass: "btn-brand submit-btn",
          attrs: { disabled: !_vm.companyCodesArr.length || _vm.isLoading },
          on: {
            click: function () {
              _vm.handleSubmit(_vm.companyCodesArr)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.buttonTitleResolved) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.isLoading
        ? _c("loader", {
            attrs: {
              title: "KybCompaniesAreBeingUploaded",
              description: "KybNotifiedWhenCompaniesWillBeAdded",
              response: _vm.response,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }