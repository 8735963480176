var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", { staticClass: "m-table__row" }, [
    _c(
      "td",
      {
        staticClass: "fixed-width id-column td",
        attrs: { title: _vm.item.id },
      },
      [_vm._v("\n    " + _vm._s(_vm.item.orderId) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "fixed-width td",
        attrs: { title: _vm.item.addedByEmail },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.item.addedByEmail ? _vm.item.addedByEmail : "-") +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "fixed-width td", attrs: { title: _vm.item.countryCode } },
      [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.item.countryCode
                ? _vm.$t("Country." + _vm.item.countryCode)
                : "-"
            ) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "fixed-width td", attrs: { title: _vm.item.startDate } },
      [
        _vm._v(
          "\n    " + _vm._s(_vm.dateToString(_vm.item.createdAt, true)) + "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      {
        staticClass: "fixed-width td",
        attrs: { title: _vm.item.deliveryTerm },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.item.deliveryTerm
                ? _vm.mapDeliveryDate(_vm.item.deliveryTerm)
                : "-"
            ) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "fixed-width td", attrs: { title: _vm.item.companyName } },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.item.companyName ? _vm.item.companyName : "-") +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "td",
      { staticClass: "fixed-width td", attrs: { title: _vm.item.status } },
      [
        _vm.item.status === "Finished" || _vm.item.status === "Updated"
          ? _c(
              "ui-button",
              {
                staticClass: "btn btn-brand",
                attrs: { small: "", disabled: _vm.isLoading },
                on: {
                  click: function ($event) {
                    return _vm.handleDownload(_vm.item.orderId)
                  },
                },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("CompanyReportTable.Status.Download")) +
                    "\n    "
                ),
              ]
            )
          : [
              _c(
                "span",
                {
                  class: { "error-class": _vm.hasErrorStatus(_vm.item.status) },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.handleStatus(_vm.item.status)) +
                      "\n      "
                  ),
                ]
              ),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }