<template>
  <div class="search-item">
    <strong>{{ label.status }}</strong>
    <span>
      <strong v-if="isCurrentProcedure">{{
        $t('CompanyProcedures.Statuses.Current')
      }}</strong>
      <span v-else>{{ $t('CompanyProcedures.Statuses.Past') }}</span>
    </span>
    <strong>{{ label.changeDate }}</strong>
    <span>{{ formattedDate }}</span>
    <strong class="center">{{ label.view }}</strong>
    <span class="center">
      <div>
        <ui-button
          class="btn-brand big-link"
          small-text
          @click="handlePopup(currentId, versionId)"
        >
          {{ $t('CompanyProcedures.Buttons.View') }}
        </ui-button>
      </div>
    </span>
    <strong class="center">{{ label.edit }}</strong>
    <span class="center">
      <div>
        <ui-link
          :to="{
            name: 'UpdateProcedures',
            query: { id: currentId, versionId: versionId }
          }"
          class="btn-metal big-link"
          small-text
        >
          {{
            isCurrentProcedure
              ? $t('CompanyProcedures.Buttons.Update')
              : $t('CompanyProcedures.Buttons.UseAsTemplate')
          }}
        </ui-link>
      </div>
    </span>
  </div>
</template>

<script>
import { dateToString } from '@src/scripts/helpers'

export default {
  props: {
    data: { type: Object, default: null },
    currentId: { type: String, default: null },
    isCurrentProcedure: { type: Boolean, default: false },
    label: { type: Object, default: null },
    versionId: { type: String, default: null }
  },

  computed: {
    formattedDate: function () {
      return dateToString(this.data.dateCreated)
    }
  },
  methods: {
    handlePopup(id, versionId) {
      this.$emit('open-popup', { id, versionId })
    }
  }
}
</script>

<style scoped>
.search-item {
  display: grid;
  grid-gap: 0.75rem;
  align-items: center;
}

.ui-button {
  width: 100%;
}

@media screen and (max-width: 799px) {
  .ui-button.btn-brand,
  .ui-button.btn-metal {
    max-width: 9.5rem;
  }

  .search-item {
    grid-template-columns: 0.6fr 1fr;
  }

  .search-item > strong {
    grid-column: 1;
  }

  .search-item > span {
    grid-column: 2;
  }

  .search-item > div {
    grid-column: 2;
    grid-row: 1 / 4;
  }

  .search-item + .search-item {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }
}

@media screen and (min-width: 800px) {
  .ui-button.btn-brand {
    width: auto;
  }

  .ui-button.btn-metal {
    min-width: 10.5rem;
    white-space: nowrap;
  }

  .search-item {
    grid-template-columns: 0.3fr 2fr 0.2fr 0.3fr;
  }

  .center {
    text-align: center;
  }

  .search-item > strong {
    margin-bottom: 0.75rem;
  }

  .search-item > span,
  .search-item > div {
    grid-row: 2;
  }

  .search-item + .search-item {
    margin-top: 0.75rem;
    border-top: 1px solid #eee;
  }

  .search-item + .search-item > strong {
    display: none;
  }
}
</style>
