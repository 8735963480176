var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitoring-events-page" },
    [
      _c(
        "ui-header",
        {
          class: { "fixed-header": _vm.scrollDown },
          attrs: {
            title: "App.SubMenu.MonitoringEvents",
            method: _vm.searchMethod,
            filters: _vm.filters,
            "reset-form": "",
            "method-get": "",
            "no-extra-payload": "",
            disabled: _vm.isLoading,
            "update-revision": _vm.updateRevision,
          },
          on: { startSearch: _vm.startSearch, finishSearch: _vm.finishSearch },
        },
        [
          _c("div", { staticClass: "filters-block" }, [
            _c(
              "div",
              { staticClass: "display-flex flex-flow-wrap" },
              [
                _c("ui-header-dropdown", {
                  attrs: {
                    options: _vm.options.dateOptions,
                    placeholder: "HeaderDatePlaceHolder",
                    wide: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "date", $$v)
                    },
                    expression: "filters.date",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "TableFullName",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.fullName,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "fullName", $$v)
                    },
                    expression: "filters.fullName",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-input", {
                  attrs: {
                    type: "text",
                    placeholder: "DetailsPersonCode",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.personalCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "personalCode", $$v)
                    },
                    expression: "filters.personalCode",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  attrs: {
                    all: "",
                    "has-search": "",
                    options: _vm.options.eventsOptions,
                    placeholder: "DetailsEventType",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  model: {
                    value: _vm.filters.eventType,
                    callback: function ($$v) {
                      _vm.$set(_vm.filters, "eventType", $$v)
                    },
                    expression: "filters.eventType",
                  },
                }),
                _vm._v(" "),
                _c("ui-header-dropdown", {
                  attrs: {
                    all: "",
                    options: _vm.options.viewedOptions,
                    placeholder: "AppViewed",
                    medium: "",
                    disabled: _vm.isLoading,
                  },
                  on: { input: _vm.viewFilterChange },
                  model: {
                    value: _vm.viewFilter,
                    callback: function ($$v) {
                      _vm.viewFilter = $$v
                    },
                    expression: "viewFilter",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "ui-container",
        [
          _c(
            "ui-card",
            { attrs: { title: "AppSearchResults", count: _vm.itemsCount } },
            [
              !_vm.isLoading && _vm.identifications
                ? [
                    _c(
                      "ui-table",
                      {
                        staticClass:
                          "export-table hide-mobile monitoring-table",
                        attrs: {
                          labels: _vm.listLabels,
                          data: _vm.identifications,
                          "empty-text": "TableNoIdentifications",
                        },
                      },
                      [
                        _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "tr",
                            { key: index, staticClass: "m-table__row" },
                            [
                              _c(
                                "td",
                                {
                                  staticClass: "td-fullname",
                                  attrs: { title: _vm.getFullName(row) },
                                },
                                [
                                  _c("p", [_vm._v(_vm._s(row.firstName))]),
                                  _vm._v(" "),
                                  _c("p", [_vm._v(_vm._s(row.lastName))]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.personalCode },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.personalCode) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm._f("date")(row.changeDate, true))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.eventType },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t("EventType." + row.eventType)
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.previousValue },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.previousValue) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.currentValue },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(row.currentValue) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: row.viewed },
                                },
                                [
                                  _c("ui-toggle", {
                                    attrs: {
                                      prevent: "",
                                      value: row.viewed,
                                      disabled: row.viewed,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeViewed(
                                          row.id,
                                          row.viewed
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "cell-identification-id-desktop",
                                  attrs: { title: row.personReferenceId },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "identification-id-desktop",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyIdentificationId(
                                            row.personReferenceId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row.personReferenceId) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticClass: "td-link" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn btn-brand",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.stopMonitoring(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("AppStopMonitoring")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  row.personReferenceId
                                    ? _c(
                                        "ui-link",
                                        {
                                          staticClass: "btn-brand",
                                          attrs: {
                                            to: {
                                              name: "IdentificationDetails",
                                              params: {
                                                identificationId: row.personReferenceId.replaceAll(
                                                  "-",
                                                  ""
                                                ),
                                              },
                                            },
                                            "small-text": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.eventType === "OndatoMatchCount" ||
                                  row.eventType === "ExternalMatchCount"
                                    ? _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn btn-brand",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkResults(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("AppCheckResults")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.identifications && _vm.identifications.length > 0
                      ? _vm._l(_vm.identifications, function (row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "response-table show-mobile",
                            },
                            [
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableFullName",
                                  value: _vm.getFullName(row),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "DetailsPersonCode",
                                  value: row.personalCode,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableChangeDate",
                                  value: _vm._f("date")(row.changeDate, true),
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                staticClass: "fixed-width",
                                attrs: {
                                  title: "DetailsEventType",
                                  value: "EventType." + row.eventType,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                staticClass: "fixed-width",
                                attrs: {
                                  title: "AppPreviosValue",
                                  value: row.previousValue,
                                },
                              }),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                staticClass: "fixed-width",
                                attrs: {
                                  title: "AppNewValue",
                                  value: row.currentValue,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "responsive-table-tr",
                                {
                                  staticClass: "fixed-width",
                                  attrs: { title: "AppViewed" },
                                },
                                [
                                  _c("ui-toggle", {
                                    attrs: {
                                      prevent: "",
                                      value: row.viewed,
                                      disabled: row.viewed,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeViewed(
                                          row.id,
                                          row.viewed
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("responsive-table-tr", {
                                attrs: {
                                  title: "TableIdentificationId",
                                  value: row.personReferenceId,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m--margin-top-10 margin-1" },
                                [
                                  _c(
                                    "ui-button",
                                    {
                                      staticClass: "btn btn-brand",
                                      on: {
                                        click: function ($event) {
                                          return _vm.stopMonitoring(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("AppStopMonitoring")) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  row.personReferenceId
                                    ? _c(
                                        "ui-link",
                                        {
                                          staticClass: "btn-brand big-link",
                                          attrs: {
                                            to: {
                                              name: "IdentificationDetails",
                                              params: {
                                                identificationId: row.personReferenceId.replaceAll(
                                                  "-",
                                                  ""
                                                ),
                                              },
                                            },
                                            "small-text": "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("TableView")) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.eventType === "OndatoMatchCount" ||
                                  row.eventType === "ExternalMatchCount"
                                    ? _c(
                                        "ui-button",
                                        {
                                          staticClass: "btn btn-brand",
                                          attrs: { small: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.checkResults(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("AppCheckResults")
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _c(
                          "div",
                          { staticClass: "table-message show-mobile" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("TableNoIdentifications")) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading
                ? _c("ui-loader", { attrs: { size: 4, center: "" } })
                : _vm._e(),
              _vm._v(" "),
              _c("ui-page-selector", {
                attrs: {
                  "active-page": _vm.filters.page,
                  "items-count": _vm.itemsCount,
                  "max-on-page": _vm.filters.pageSize,
                  disabled: _vm.isLoading,
                },
                on: { change: _vm.pageChanged },
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "pop-up",
            [
              _vm.popUpType === "monitoring"
                ? _c("monitoring-setup", {
                    attrs: { data: _vm.monitoringData },
                    on: { change: _vm.updateData },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.popUpType === "results"
                ? _c("check-results", { attrs: { id: _vm.resultId } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }