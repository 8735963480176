<template>
  <div class="list-item">
    <a
      v-if="editEnabled"
      :class="{ 'item-disabled': disabled }"
      @click="$emit('remove', item.key)"
    >
      -
    </a>
    <span>{{ $t(item.value) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object, default: undefined },
    editEnabled: Boolean,
    disabled: Boolean
  }
}
</script>

<style scoped>
.list-item {
  display: flex;
  padding: 1rem 2.2rem;
}

.list-item > a {
  width: 1.155rem;
  height: 1.155rem;
  border-radius: 50%;
  display: block;
  background-color: var(--danger);
  color: var(--white);
  text-align: center;
  line-height: 1.155rem;
  margin: auto 2rem auto 0;
}

.list-item > a:hover {
  color: var(--white);
  cursor: pointer;
  opacity: 0.9;
}

.list-item > a.item-disabled {
  opacity: 0.8;
  pointer-events: none;
}
</style>
