var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-item" }, [
    _c("strong", [_vm._v(_vm._s(_vm.label.status))]),
    _vm._v(" "),
    _c("span", [
      _vm.isCurrentProcedure
        ? _c("strong", [
            _vm._v(_vm._s(_vm.$t("CompanyProcedures.Statuses.Current"))),
          ])
        : _c("span", [
            _vm._v(_vm._s(_vm.$t("CompanyProcedures.Statuses.Past"))),
          ]),
    ]),
    _vm._v(" "),
    _c("strong", [_vm._v(_vm._s(_vm.label.changeDate))]),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.formattedDate))]),
    _vm._v(" "),
    _c("strong", { staticClass: "center" }, [_vm._v(_vm._s(_vm.label.view))]),
    _vm._v(" "),
    _c("span", { staticClass: "center" }, [
      _c(
        "div",
        [
          _c(
            "ui-button",
            {
              staticClass: "btn-brand big-link",
              attrs: { "small-text": "" },
              on: {
                click: function ($event) {
                  return _vm.handlePopup(_vm.currentId, _vm.versionId)
                },
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CompanyProcedures.Buttons.View")) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("strong", { staticClass: "center" }, [_vm._v(_vm._s(_vm.label.edit))]),
    _vm._v(" "),
    _c("span", { staticClass: "center" }, [
      _c(
        "div",
        [
          _c(
            "ui-link",
            {
              staticClass: "btn-metal big-link",
              attrs: {
                to: {
                  name: "UpdateProcedures",
                  query: { id: _vm.currentId, versionId: _vm.versionId },
                },
                "small-text": "",
              },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.isCurrentProcedure
                      ? _vm.$t("CompanyProcedures.Buttons.Update")
                      : _vm.$t("CompanyProcedures.Buttons.UseAsTemplate")
                  ) +
                  "\n      "
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }