var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ui-card", { staticClass: "card-header" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-between" },
      [
        _c("div", { staticClass: "d-flex" }, [_vm._t("left-content")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex card-header-content" },
          [_vm._t("right-content")],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }