<template>
  <div>
    <ui-header-box
      title="AppManualCheck"
      :filters="filters"
      disable-search-on-load
    >
      <ui-header-dropdown
        v-model="filters.documentType"
        :options="documentTypes"
        medium
        placeholder="DetailsDocumentType"
        :disabled="isLoading"
      />
      <ui-header-dropdown
        v-model="filters.country"
        has-search
        class="countries-dropdown"
        key-value-dropdown
        :options="countriesOptions"
        medium
        placeholder="DetailsCountry"
        :disabled="isLoading"
      />
      <div>
        <ui-button
          class="m-subheader-search__submit-btn"
          :disabled="disabledProceed"
          @click="clickProceed"
        >
          {{ $t('AppProceed') }}
        </ui-button>
      </div>
    </ui-header-box>
    <ui-container v-if="!isLoading && !requestResult">
      <ui-card
        v-if="clickedProceed"
        :multicolumn="!isPassport"
        title="UploadDocumentPhoto"
      >
        <div slot="tools">
          <ui-button
            class="btn-brand"
            :disabled="disabledVerify"
            @click="clickVerify"
          >
            {{ $t('AppVerify') }}
          </ui-button>
        </div>
        <div v-if="!isPassport" slot="body-left">
          <p class="photo-title">
            {{ $t('APPFRONT') }}
          </p>
          <upload-photo-box
            description="UploadFrontSideDocument"
            :photo="filters.frontSide"
            @addPhoto="addFrontSide"
            @deletePhoto="filters.frontSide = null"
          />
        </div>
        <div :slot="isPassport ? 'body' : 'body-right'">
          <p class="photo-title">
            {{ $t('APPBACK') }}
          </p>
          <upload-photo-box
            description="UploadBackSideDocument"
            :photo="filters.backSide"
            @addPhoto="addBackSide"
            @deletePhoto="filters.backSide = null"
          />
        </div>
      </ui-card>
    </ui-container>
    <ui-container v-else-if="!isLoading && requestResult" class="align-center">
      <div>
        <ui-card v-if="successMessage">
          <p class="success-text">
            {{ $t(successMessage) }}
          </p>
          <ui-button class="btn-brand" @click="viewIdentification">
            {{ $t('TableView') }}
          </ui-button>
        </ui-card>
        <ui-card v-else-if="errorMessage">
          <p class="error-text">
            {{ $t(errorMessage) }}
          </p>
          <ui-button class="btn-danger" @click="clickBack">
            {{ $t('AppGoBack') }}
          </ui-button>
        </ui-card>
      </div>
    </ui-container>
    <ui-container v-else>
      <ui-loader :size="4" center />
    </ui-container>
  </div>
</template>

<script>
import { mapOptions } from '@src/scripts/helpers'
import UploadPhotoBox from '@src/components/partials/UploadPhotoBox'
import { blobToDataUrl, dataUrlToBlob } from '@src/scripts/helpers'
import Api from '@src/scripts/api'
import { DOCUMENT_TYPES } from '@src/scripts/enums'

export default {
  components: {
    UploadPhotoBox
  },

  data() {
    return {
      filters: {
        country: null,
        documentType: null,
        frontSide: null,
        backSide: null
      },
      isLoading: false,
      errorMessage: null,
      successMessage: null,
      clickedProceed: false,
      requestResult: false,
      identificationId: null
    }
  },

  computed: {
    isPassport() {
      return this.filters.documentType === DOCUMENT_TYPES.PASSPORT.toUpperCase()
    },
    nationalities() {
      const { nationalities } = this.$store.getters
      return nationalities
    },

    countriesOptions() {
      return mapOptions(this.nationalities)
    },

    documentTypes() {
      const { documentTypes } = this.$store.getters
      return mapOptions(documentTypes)
    },

    disabledProceed() {
      const { country, documentType } = this.filters
      if (country && documentType) return false
      else return true
    },

    disabledVerify() {
      const { frontSide, backSide } = this.filters
      if (frontSide && backSide && !this.isPassport) return false
      if (backSide && this.isPassport) return false
      else return true
    }
  },

  methods: {
    clickBack() {
      this.errorMessage = null
      this.successMessage = null
      this.requestResult = false
    },

    clickProceed() {
      this.clickedProceed = true
      this.requestResult = false
      this.$set(this.filters, 'frontSide', null)
      this.$set(this.filters, 'backSide', null)
    },

    async addFrontSide() {
      const input = event.target
      this.$set(this.filters, 'frontSide', await blobToDataUrl(input.files[0]))
    },

    async addBackSide() {
      const input = event.target
      this.$set(this.filters, 'backSide', await blobToDataUrl(input.files[0]))
    },

    async clickVerify() {
      this.requestResult = true
      this.isLoading = true
      const identificationId = await this.verify()
      if (identificationId) {
        this.successMessage = 'VerificationSuccessful'
        this.identificationId = identificationId
      } else {
        this.errorMessage = 'UploadPhotoErrorText'
      }
      this.isLoading = false
    },

    async verify() {
      try {
        const {
          data: { result: { identificationId } = {} } = {}
        } = await Api.post('/manual-check/identification/start', {})
        if (!identificationId) return
        const { frontSide, backSide } = this.filters
        if (!this.isPassport) {
          if (
            !(await this.uploadDocument(identificationId, frontSide, 'FRONT'))
          )
            return
        }
        if (!(await this.uploadDocument(identificationId, backSide, 'BACK')))
          return
        const { data: verifyData } = await Api.post(
          `/manual-check/identification/${identificationId}/verify`,
          {}
        )
        if (!verifyData) return

        const { error } = await Api.post(
          `/manual-check/identification/${identificationId}/complete`,
          {}
        )
        if (error) return

        return identificationId
      } catch (error) {
        // empty
      }
    },

    async uploadDocument(identificationId, fileUrl, side) {
      const { documentType } = this.filters

      const file = dataUrlToBlob(fileUrl)

      const document = new FormData()
      document.append('identificationId', identificationId)
      document.append('file', file, 'file.jpg')
      document.append('sideType', side)
      document.append('documentType', documentType)

      const { data: { result = {} } = {} } = await Api.postFormData(
        '/manual-check/media/document',
        document
      )
      if (!result.success) return false
      return true
    },

    viewIdentification() {
      this.$router.push({ name: 'Identifications' })
    }
  }
}
</script>
<style scoped>
.error-text {
  color: var(--red);
}

.success-text {
  font-size: 1.25rem;
  color: var(--success);
}

.align-center {
  text-align: center;
}

.photo-title {
  text-align: center;
  margin-top: 1rem;
  text-transform: uppercase;
  color: var(--brand);
}
</style>
